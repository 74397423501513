import { Row, Typography } from "antd";
import React from "react";

export const NotFound: React.FC = () => {
  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Typography>Page Not Found</Typography>
    </Row>
  );
};
