import { green, red, yellow } from "@ant-design/colors";
import {
  CheckCircleFilled,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FileFilled,
  StopOutlined,
  VideoCameraOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  CalendarType,
  getAppointmentNameFromBilling,
  IAppointment,
  ICalendarEvent,
  IClient,
  IClientFile,
  ICreateCompletedAppointmentEndpointRequest,
  INote,
  isPayerAuthRequired,
  isPayerAuthValid,
  IUser,
  UserPermission,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Button, Card, message, Popover, Row, Space, Tag, Tooltip, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { BsFillShieldSlashFill } from "react-icons/bs";
import { IoSchoolOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { DISPLAY_TIME_FORMAT } from "../../consts";
import { getAppointmentLocationText } from "../../helpers/appointments";
import { isCancelledAppointment } from "../../helpers/appointments";
import { isClientOverlapping, isTherapistOverlapping } from "../../helpers/calendar";
import { getBillingCodeColor } from "../../helpers/colors";
import { userHasPermission } from "../../helpers/userPermissions";
import { isMatchingContext } from "../../pages/Calendar";
import * as FirestoreService from "../../services/firestore";
import { useUserClinics } from "../UserClinicsProvider";
import { AttendeeTag } from "./AttendeeTag";
import { CancelAppointmentModal } from "./CancelAppointmentModal";
import { ConfirmDeleteAppointmentModal } from "./ConfirmDeleteAppointmentModal";
import { EditAppointmentModal } from "./EditAppointmentModal";
import { NotesTabs } from "./NotesTabs";

const { Text, Title } = Typography;

interface IProps {
  client: IClient;
  appointment: IAppointment;
  users: IUser[];
  calendarType: CalendarType;
  forceOpen: boolean;
  isCalendarProcessing: boolean;
  setIsMatchNotesDrawerOpen: (isOpen: boolean) => void;
  refreshAppointments: () => Promise<void>;
  refreshAll: () => Promise<void>;
  setSelectedAppointment: (appointment: IAppointment) => void;
  setSelectedClient: (client: IClient) => void;
  addAppointmentMissingNote: (appointment: IAppointment) => void;
  clientFile?: IClientFile;
  scheduleConflicts?: ICalendarEvent[];
  style?: React.CSSProperties;
  onNotesChange?: (notes: INote[]) => void;
  onActionsChange?: (actions: any) => void;
}

export const AppointmentCard: React.FC<IProps> = ({
  client,
  appointment,
  users,
  calendarType,
  setIsMatchNotesDrawerOpen,
  refreshAppointments,
  refreshAll,
  forceOpen,
  setSelectedAppointment,
  setSelectedClient,
  isCalendarProcessing,
  addAppointmentMissingNote,
  clientFile,
  scheduleConflicts,
  style,
  onNotesChange,
  onActionsChange,
}: IProps) => {
  const { user, clinic } = useUserClinics();
  const isMatching = useContext(isMatchingContext);
  const [ref, setRef] = useState<any>();

  //General states
  const [open, setOpen] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [isUnmatching, setIsUnmatching] = useState<boolean>(false);

  //Notes states
  const [notes, setNotes] = useState<INote[]>([]);
  const [unapprovedNoteCount, setUnapprovedNoteCount] = useState<number>(0);

  //Schedule conflicts
  const [scheduleConflictMessage, setScheduleConflictMessage] =
    useState<string>("Schedule conflict");

  useEffect(() => {
    if (scheduleConflicts && scheduleConflicts.length > 0) {
      const therapistOverlapping = isTherapistOverlapping(appointment, scheduleConflicts);
      const clientOverlapping = isClientOverlapping(appointment, scheduleConflicts);
      if (therapistOverlapping && clientOverlapping) {
        setScheduleConflictMessage("Client & therapist schedule conflict");
      } else if (therapistOverlapping) {
        setScheduleConflictMessage("Therapist schedule conflict");
      } else if (clientOverlapping) {
        setScheduleConflictMessage("Client schedule conflict");
      }
    }
  }, [scheduleConflicts]);

  //Delete appointments modal
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState<boolean>(false);
  const hideConfirmDeleteModal = () => {
    setIsConfirmDeleteModalOpen(false);
  };
  const confirmDelete = () => {
    setIsConfirmDeleteModalOpen(true);
  };

  //Edit appointments modal
  const [isEditAppointmentModalOpen, setIsEditAppointmentModalOpen] = useState<boolean>(false);
  const hideEditAppointmentModal = () => {
    setIsEditAppointmentModalOpen(false);
  };

  //Cancel appointments modal
  const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] = useState<boolean>(false);
  const hideCancelAppointmentModal = () => {
    setIsCancelAppointmentModalOpen(false);
  };
  const handleCancelAppointment = () => {
    setIsCancelAppointmentModalOpen(true);
  };

  //Hover effects
  const [displayCompletionEffect, setDisplayCompletionEffect] = useState<boolean>(false);
  const showCompletionEffectValue = (appointmentValue: string, noteValue: string) => {
    if (displayCompletionEffect && appointmentValue !== noteValue) {
      return noteValue;
    } else {
      return appointmentValue;
    }
  };
  const showCompletionEffectStyle = (appointmentValue: string, noteValue: string) => {
    if (displayCompletionEffect && appointmentValue !== noteValue) {
      return {
        color: COLORS.PRIMARY,
      };
    } else {
      return {};
    }
  };

  //First load
  useEffect(() => {
    if (!_.isEmpty(user) && !_.isEmpty(client) && !_.isEmpty(appointment)) {
      fetchNotes(forceOpen).catch(() => {});
    }
  }, [user, appointment, client]);

  const hidePopover = () => {
    setDisplaySplitEffectNote({} as INote);
    setSelectedAppointment({} as IAppointment);
    setSelectedClient({} as IClient);
    setOpen(false);
    return fetchNotes(true);
  };

  //Loading on cards
  const [sentRefreshCallback, setSentRefreshCallback] = useState<boolean>(false);
  useEffect(() => {
    if (sentRefreshCallback && !isCalendarProcessing) {
      setSentRefreshCallback(false);
    }
  }, [isCalendarProcessing]);
  useEffect(() => {
    if (isMatching.id == appointment.id) {
      setSentRefreshCallback(true);
    }
  }, [isMatching]);
  const handleSubRefresh = () => {
    setSentRefreshCallback(true);
    return refreshAppointments();
  };

  const handlePopoverOpenChange = async (newOpen: boolean) => {
    if (!isConfirmDeleteModalOpen) {
      if (newOpen) {
        setSelectedAppointment(appointment);
        setSelectedClient(client);
        await fetchNotes(true);
        setOpen(true);
      } else {
        await hidePopover();
      }
    }
  };

  useEffect(() => {
    if (forceOpen && !_.isEmpty(client) && !_.isEmpty(appointment)) {
      handlePopoverOpenChange(true).catch(() => {});
    }
  }, [forceOpen, client, appointment]);

  //Scroll to ref on forceOpen page load
  useEffect(() => {
    if (forceOpen && ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [forceOpen, ref]);

  //Display effect of split, hacky but saves a tonne of refactor
  const [displaySplitEffectNote, setDisplaySplitEffectNote] = useState<INote>({} as INote);

  useEffect(() => {
    if (!_.isEmpty(displaySplitEffectNote) && !_.isEmpty(appointment)) {
      const clone = ref.current.cloneNode(true);
      clone.setAttribute("id", "split-clone");
      clone.style.boxShadow = `0 1px 10px -2px ${COLORS.PRIMARY}, 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)`;

      //Delete extra approved counts
      clone.firstChild.children[0].children[1].removeChild(
        clone.firstChild.children[0].children[1].children[0]
      );

      //Location
      if (
        clone.firstChild.children[3].textContent !==
        getAppointmentLocationText(displaySplitEffectNote.location)
      ) {
        clone.firstChild.children[3].style.color = COLORS.PRIMARY;
      }
      clone.firstChild.children[3].textContent = getAppointmentLocationText(
        displaySplitEffectNote.location
      );

      //Time
      const noteTime = `${moment(displaySplitEffectNote?.startMs).format(
        DISPLAY_TIME_FORMAT
      )} — ${moment(displaySplitEffectNote?.endMs).format(DISPLAY_TIME_FORMAT)}`;
      if (clone.firstChild.children[5].textContent !== noteTime) {
        clone.firstChild.children[5].style.color = COLORS.PRIMARY;
      }
      clone.firstChild.children[5].textContent = noteTime;

      ref.current.parentNode.appendChild(clone);
    } else {
      const clone = document.getElementById("split-clone");
      clone?.parentNode?.removeChild(clone);
    }
  }, [displaySplitEffectNote]);

  const handleUnmatchNote = async (id: string, appointmentId: string) => {
    setIsUnmatching(true);
    setSentRefreshCallback(true);
    await FirestoreService.updateNote({
      id,
      appointmentId: "",
      manualOverride: false,
    });
    //update the appointment with the note id
    await FirestoreService.updateAppointment({
      id: appointmentId,
      noteId: "",
    });
    await refreshAll();
    await hidePopover();
    setIsUnmatching(false);
  };

  const handleReloadNote = async (id: string) => {
    setIsCancelling(true);

    setSentRefreshCallback(true);
    void message.info("Queuing Note Reload");
    await FirestoreService.reloadNoteById(id);
    void message.success("Reloaded Note");
    await refreshAll();
    await hidePopover();
    setIsCancelling(false);
  };

  const handleApproveNote = async (id: string) => {
    setIsCancelling(true);
    setSentRefreshCallback(true);

    void message.info("Queuing Note Approval");

    const note = notes.find((note) => note.id == id);
    const motivityUUID = note?.motivityUUID;

    let result;

    if (motivityUUID) {
      result = await FirestoreService.approveNoteWebSocket(motivityUUID);
    } else {
      result = await FirestoreService.approveNote(id);
    }

    if (result === true) {
      if (motivityUUID) {
        await FirestoreService.updateNote({
          id,
          approved: true,
        });
      }
      void message.success("Approved note successfully");
    } else {
      void message.error("Failed to approve note");
    }

    await refreshAll();
    await hidePopover();
    setIsCancelling(false);
  };

  const handleCompleteNote = async (id: string) => {
    setIsCancelling(true);

    //get note
    const note = notes.find((note) => note.id === id);

    //if note end or start is not within appointments end or start by 12 hours
    if (
      moment(note?.startMs) > moment(appointment.startMs).add(12, "hours") ||
      moment(note?.endMs) < moment(appointment.endMs).subtract(12, "hours")
    ) {
      void message.error(
        "Cannot complete appointment if note is not within appointment by 12 hours"
      );
      setIsCancelling(false);
      return;
    }

    setSentRefreshCallback(true);
    const request: ICreateCompletedAppointmentEndpointRequest = { noteId: id };
    const result = await FirestoreService.createCompletedAppointment(request);
    if (!_.isEmpty(result)) {
      void message.success("Completed appointment successfully");
    } else {
      void message.error("Failed to complete appointment");
    }
    await refreshAppointments();
    await hidePopover();
    setIsCancelling(false);
  };

  const handleSplitAppointment = async (note: INote, appointment: IAppointment) => {
    setIsCancelling(true);

    setSentRefreshCallback(true);
    try {
      await FirestoreService.splitAppointmentByNoteAndAppointment(note, appointment);
      void message.success("Split appointment successfully");
    } catch (error) {
      void message.error("Failed to split appointment");
    }
    await refreshAppointments();
    await hidePopover();
    setIsCancelling(false);
  };

  //fetch notes
  const fetchNotes = async (force = false) => {
    const notesResult = await FirestoreService.getAllNotesByAppointmentId(
      appointment.id,
      appointment.clinicId,
      force
    );
    setUnapprovedNoteCount(notesResult.filter((note) => note.approved === false).length);

    if (onNotesChange && !_.isEqual(notes, notesResult)) onNotesChange(notesResult);
    setNotes(notesResult);
    //Missing Notes
    if (
      notesResult.length == 0 &&
      moment() > moment(appointment.endMs) &&
      !isCancelledAppointment(appointment)
    ) {
      addAppointmentMissingNote(appointment);
    }
  };

  useEffect(() => {
    setRef(React.createRef());
    if (onActionsChange) {
      onActionsChange({
        approveNote: handleApproveNote,
        completedAppointment: handleCompleteNote,
      });
    }
  }, []);

  const popoverContent = () => {
    return (
      <div style={{ width: 300 }}>
        <Row justify="end" style={{ marginBottom: 5 }}>
          <Space size={17}>
            {
              <Space size={0}>
                {notes.length === 0 && !userHasPermission(user, UserPermission.RBT) && (
                  <Tooltip placement="bottom" title="Edit appointment">
                    <Button
                      type="text"
                      size="small"
                      onClick={async () => {
                        await hidePopover();
                        setIsEditAppointmentModalOpen(true);
                      }}
                      style={{ paddingTop: 1 }}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                )}
                {notes.length === 0 && (
                  <Tooltip
                    placement="bottom"
                    title={
                      userHasPermission(user, UserPermission.RBT)
                        ? "Request cancellation"
                        : "Cancel appointment"
                    }
                  >
                    <Button
                      type="text"
                      size="small"
                      onClick={handleCancelAppointment}
                      loading={isCancelling}
                    >
                      <StopOutlined />
                    </Button>
                  </Tooltip>
                )}
                {notes.length === 0 && !userHasPermission(user, UserPermission.RBT) && (
                  <Tooltip placement="bottom" title="Delete appointment">
                    <Button type="text" size="small" onClick={confirmDelete}>
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                )}
              </Space>
            }
            <Tooltip placement="bottom" title="Close">
              <Button type="text" size="small" onClick={hidePopover}>
                <CloseOutlined />
              </Button>
            </Tooltip>
          </Space>
        </Row>
        <Row>
          <Title level={4} style={{ marginBottom: 0 }}>
            {client && !_.isEmpty(client) ? (
              <Link to={`../client-profile/${client.id}`}>
                <IoSchoolOutline style={{ fontSize: 18, marginBottom: -1, marginRight: 3 }} />
                {client.alias}
              </Link>
            ) : (
              appointment.summary
            )}
          </Title>
        </Row>
        <Row>
          <Text>
            {getAppointmentNameFromBilling(
              clinic.address.state,
              appointment.billingCode,
              appointment.modifiers
            )}
          </Text>
          <Text type="secondary" style={{ marginLeft: 7 }}>{`${appointment.billingCode} ${
            appointment.modifiers?.join(" ") || ""
          }`}</Text>
        </Row>
        <Row>
          <Text
            style={showCompletionEffectStyle(
              `${moment(appointment.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
                appointment.endMs
              ).format(DISPLAY_TIME_FORMAT)}`,

              `${moment(notes[0]?.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
                notes[0]?.endMs
              ).format(DISPLAY_TIME_FORMAT)}`
            )}
          >
            {showCompletionEffectValue(
              `${moment(appointment.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
                appointment.endMs
              ).format(DISPLAY_TIME_FORMAT)}`,

              `${moment(notes[0]?.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
                notes[0]?.endMs
              ).format(DISPLAY_TIME_FORMAT)}`
            )}
          </Text>
        </Row>
        {appointment.meetsLink ? (
          <Row align="middle" style={{ marginTop: 8 }}>
            <Tooltip title="Join Google Meet">
              <Button
                type="primary"
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <Link
                  style={showCompletionEffectStyle(
                    getAppointmentLocationText(appointment.location),
                    getAppointmentLocationText(notes[0]?.location)
                  )}
                  to={appointment.meetsLink}
                  target="_blank"
                >
                  <VideoCameraOutlined style={{ marginRight: 3 }} />
                  {showCompletionEffectValue(
                    getAppointmentLocationText(appointment.location),
                    getAppointmentLocationText(notes[0]?.location)
                  )}
                </Link>
              </Button>
            </Tooltip>
          </Row>
        ) : (
          <Row align="middle">
            <Text
              style={showCompletionEffectStyle(
                getAppointmentLocationText(appointment.location),
                getAppointmentLocationText(notes[0]?.location)
              )}
            >
              {showCompletionEffectValue(
                getAppointmentLocationText(appointment.location),
                getAppointmentLocationText(notes[0]?.location)
              )}
            </Text>
          </Row>
        )}
        <Row style={{ marginTop: 20 }}>
          <Text strong>Guests</Text>
        </Row>
        {appointment.attendees.map((attendee) => (
          <AttendeeTag
            key={attendee.email}
            email={attendee.email}
            status={attendee.status}
            maxWidth={200}
          />
        ))}
        {appointment.description && (
          <>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Description</Text>
            </Row>
            <Row>
              <Text>{appointment.description}</Text>
            </Row>
          </>
        )}
        {!userHasPermission(user, UserPermission.RBT) &&
          (notes.length > 0 ? (
            <NotesTabs
              notes={notes}
              appointment={appointment}
              isLoading={isUnmatching}
              handleUnmatchNote={handleUnmatchNote}
              handleReloadNote={handleReloadNote}
              handleApproveNote={handleApproveNote}
              handleCompleteNote={handleCompleteNote}
              handleSplitAppointment={handleSplitAppointment}
              setDisplayCompletionEffect={setDisplayCompletionEffect}
              setDisplaySplitEffectNote={setDisplaySplitEffectNote}
              openMatchNotesDrawer={() => {
                setIsMatchNotesDrawerOpen(true);
              }}
              hidePopover={hidePopover}
            />
          ) : (
            <Button
              type="primary"
              style={{ marginTop: 15 }}
              onClick={() => setIsMatchNotesDrawerOpen(true)}
            >
              Browse un-linked notes
            </Button>
          ))}
      </div>
    );
  };

  const requiresAuth = isPayerAuthRequired(
    clinic.address.state,
    clientFile?.payers.primary?.payerId || "",
    appointment.billingCode,
    appointment.modifiers
  );

  const isAuthorized =
    !requiresAuth || isPayerAuthValid(clientFile?.payers.primary ?? null, appointment.startMs);

  return (
    <>
      <Popover
        trigger="click"
        placement="left"
        open={open}
        onOpenChange={handlePopoverOpenChange}
        content={popoverContent}
        zIndex={500}
        showArrow={false}
        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      >
        <Card
          ref={ref}
          hoverable
          loading={sentRefreshCallback}
          style={{
            marginBottom: 5,
            boxShadow: open
              ? "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)"
              : undefined,
            borderColor:
              scheduleConflicts && scheduleConflicts.length > 0 ? yellow.primary : undefined,
            ...style,
          }}
          bodyStyle={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 3,
          }}
        >
          <Row justify="space-between" style={{ width: "100%", height: 24 }}>
            <Text strong>
              {`${client && !_.isEmpty(client) ? client.alias : appointment.summary}`}
            </Text>
            <Row align="middle">
              {scheduleConflicts && scheduleConflicts.length > 0 && (
                <Tooltip title={scheduleConflictMessage}>
                  <WarningFilled
                    style={{
                      marginRight: 4,
                      marginBottom: 1,
                      fontSize: 17,
                      color: yellow.primary,
                    }}
                  />
                </Tooltip>
              )}
              {!userHasPermission(user, UserPermission.RBT) &&
                (notes.length > 0 ? (
                  unapprovedNoteCount > 0 ? (
                    <Tooltip
                      title={`${unapprovedNoteCount} unapproved note${
                        unapprovedNoteCount > 1 ? "s" : ""
                      }`}
                      className="status-icon"
                    >
                      <Text
                        style={{
                          position: "relative",
                          left: 12,
                          bottom: 2,
                          color: "white",
                          fontSize: 12,
                        }}
                        strong
                      >
                        {unapprovedNoteCount}
                      </Text>
                      <FileFilled
                        style={{
                          marginRight: 4,
                          fontSize: 18,
                          color: COLORS.BLUE,
                        }}
                      />
                    </Tooltip>
                  ) : (
                    //Approved Notes
                    <Tooltip
                      title={`${notes.length} approved note${notes.length > 1 ? "s" : ""}`}
                      className="status-icon"
                    >
                      {notes.length > 1 && (
                        <Text
                          style={{
                            position: "relative",
                            left: -5,
                            bottom: 2,
                            color: green.primary,
                            fontSize: 12,
                          }}
                          strong
                        >
                          {notes.length}
                        </Text>
                      )}
                      <CheckCircleFilled
                        style={{
                          marginRight: 4,
                          fontSize: 18,
                          color: green.primary,
                        }}
                      />
                    </Tooltip>
                  )
                ) : (
                  moment() > moment(appointment.endMs) && (
                    //Missing Notes
                    <Tooltip title="Session notes missing" className="status-icon">
                      <ExclamationCircleFilled
                        style={{
                          marginRight: 4,
                          fontSize: 16,
                          color: red.primary,
                        }}
                      />
                    </Tooltip>
                  )
                ))}
              {!isAuthorized && (
                <Tooltip title="Auth expired">
                  <BsFillShieldSlashFill
                    style={{
                      marginRight: 4,
                      fontSize: 15,
                      color: red.primary,
                    }}
                  />
                </Tooltip>
              )}
            </Row>
          </Row>
          <Tag
            color={getBillingCodeColor(appointment.billingCode, appointment.modifiers)}
            style={{ marginTop: 2, marginBottom: 2 }}
          >
            {getAppointmentNameFromBilling(
              clinic.address.state,
              appointment.billingCode,
              appointment.modifiers
            )}
          </Tag>
          <br />
          <Text
            style={showCompletionEffectStyle(
              getAppointmentLocationText(appointment.location),
              getAppointmentLocationText(notes[0]?.location)
            )}
          >
            {appointment.meetsLink && <VideoCameraOutlined style={{ marginRight: 3 }} />}
            {showCompletionEffectValue(
              getAppointmentLocationText(appointment.location),
              getAppointmentLocationText(notes[0]?.location)
            )}
          </Text>
          <br />
          <Text
            style={showCompletionEffectStyle(
              `${moment(appointment.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
                appointment.endMs
              ).format(DISPLAY_TIME_FORMAT)}`,
              `${moment(notes[0]?.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
                notes[0]?.endMs
              ).format(DISPLAY_TIME_FORMAT)}`
            )}
          >
            {showCompletionEffectValue(
              `${moment(appointment.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
                appointment.endMs
              ).format(DISPLAY_TIME_FORMAT)}`,
              `${moment(notes[0]?.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
                notes[0]?.endMs
              ).format(DISPLAY_TIME_FORMAT)}`
            )}
          </Text>
          <br />
          {calendarType === CalendarType.CLIENTS && (
            <>
              {appointment.attendees.slice(0, 2).map((attendee) => (
                <AttendeeTag key={attendee.email} email={attendee.email} status={attendee.status} />
              ))}
              <br />
              {appointment.attendees.slice(2).length > 0 && (
                <Text style={{ fontSize: 12 }}>
                  {`And ${appointment.attendees.slice(2).length} other guest${
                    appointment.attendees.slice(2).length > 1 ? "s" : ""
                  }`}
                </Text>
              )}
            </>
          )}
        </Card>
      </Popover>
      <EditAppointmentModal
        key={appointment.id}
        appointment={appointment}
        users={users}
        isOpen={isEditAppointmentModalOpen}
        hideModal={hideEditAppointmentModal}
        refreshCallback={handleSubRefresh}
      />
      <ConfirmDeleteAppointmentModal
        appointment={appointment}
        isVisible={isConfirmDeleteModalOpen}
        hideModal={hideConfirmDeleteModal}
        refreshCallback={handleSubRefresh}
        hidePopover={hidePopover}
      />
      <CancelAppointmentModal
        refreshCallback={handleSubRefresh}
        hideModal={hideCancelAppointmentModal}
        isVisible={isCancelAppointmentModalOpen}
        appointment={appointment}
        client={client}
      />
    </>
  );
};
