import { IAvailability } from "@finni-health/shared";
import { EditAvailabilityTable } from "@finni-health/ui";
import { Col, Form, InputNumber, Row } from "antd";

export const ClientAvailabilityForm = () => {
  const formInstance = Form.useFormInstance();

  const availability = Form.useWatch(["clientAvailability", "availability"], formInstance);

  const onAvailabilityChange = (availability: IAvailability) => {
    formInstance.setFieldValue(["clientAvailability", "availability"], availability);
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Recommended therapy hours"
            name={["clientAvailability", "recommendedHours"]}
          >
            <InputNumber tabIndex={1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Minimum therapy hours" name={["clientAvailability", "minimumHours"]}>
            <InputNumber tabIndex={1} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <EditAvailabilityTable
          onAvailabilityChange={onAvailabilityChange}
          initialAvailability={availability}
        />
      </Row>
    </>
  );
};
