import {
  ActiveStatus,
  IClientFile,
  IntakeStatus,
  PrequalifiedStatus,
  USStateCode,
} from "@finni-health/shared";
import { Col, Collapse, Divider, Form, Input, Row, Select, Switch, Typography } from "antd";

import { isPrequalifiedStatus } from "../../helpers/isPrequalifiedStatus";
import { IntakeStatusTag } from "../Intake/IntakeStatusTag";
import { PhoneNumberInput } from "../PhoneNumberInput";

const { Text } = Typography;
const { TextArea } = Input;

interface IProps {
  clientFile: IClientFile;
}

export const BasicInfoForm: React.FC<IProps> = ({ clientFile }: IProps) => {
  return (
    <>
      <Row gutter={24}>
        <Col span={9}>
          <Form.Item label="Status" name={["clientFile", "intakeStatus"]}>
            <Select showSearch optionFilterProp="key">
              {Object.keys(
                isPrequalifiedStatus(clientFile.intakeStatus) ? PrequalifiedStatus : ActiveStatus
              ).map((status) => (
                <Select.Option key={status} value={status}>
                  <IntakeStatusTag
                    status={status as IntakeStatus}
                    style={{ maxWidth: 200, position: "relative" }}
                  />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Row>
            <Form.Item label="Hot Lead" name={["clientFile", "isHot"]} valuePropName="checked">
              <Switch checkedChildren={<Text>{"🔥"}</Text>} />
            </Form.Item>
          </Row>
        </Col>
        <Col span={12}>
          <Form.Item label="Status Notes" name={["clientFile", "intakeStatusNotes"]}>
            <TextArea autoSize={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="First Name"
            name={["guardian", "firstName"]}
            rules={[{ required: true, message: "First Name is required" }]}
          >
            <Input required tabIndex={1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Last Name"
            name={["guardian", "lastName"]}
            rules={[{ required: true, message: "Last Name is required" }]}
          >
            <Input required tabIndex={2} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Guardian Address</Divider>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Address Line 1"
            name={["clientFile", "address", "line1"]}
            rules={[{ required: true, message: "Address Line 1 is required" }]}
          >
            <Input tabIndex={3} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Address Line 2" name={["clientFile", "address", "line2"]}>
            <Input tabIndex={4} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            label="City"
            name={["clientFile", "address", "city"]}
            rules={[{ required: true, message: "City is required" }]}
          >
            <Input tabIndex={5} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="State"
            name={["clientFile", "address", "state"]}
            rules={[{ required: true, message: "State is required" }]}
          >
            <Select showSearch tabIndex={6}>
              {Object.values(USStateCode).map((state) => (
                <Select.Option value={state} key={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Country" name={["clientFile", "address", "country"]}>
            <Input disabled={true} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Zip Code"
            name={["clientFile", "address", "zipCode"]}
            rules={[{ required: true, message: "Zip Code is required" }]}
          >
            <Input tabIndex={7} maxLength={10} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Contact Information</Divider>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Email" name={["guardian", "email"]}>
            <Input required tabIndex={9} type="email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Primary Phone Number" name={["guardian", "phoneNumber", "primary"]}>
            <PhoneNumberInput />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end" gutter={24} style={{ marginTop: -15 }}>
        <Col span={12}>
          <Collapse ghost>
            <Collapse.Panel header="Additional Phone Numbers" key="1">
              <Form.Item label="Home Phone Number" name={["guardian", "phoneNumber", "home"]}>
                <PhoneNumberInput />
              </Form.Item>
              <Form.Item label="Work Phone Number" name={["guardian", "phoneNumber", "work"]}>
                <PhoneNumberInput />
              </Form.Item>
              <Form.Item
                label="Cell Phone Number"
                name={["guardian", "phoneNumber", "cell"]}
                style={{ marginBottom: 0 }}
              >
                <PhoneNumberInput />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};
