import { AppointmentLocation, IAppointment, Weekday } from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Badge, Card, Row, Select, TimePicker, Typography } from "antd";
import moment from "moment";
import React from "react";

import { DISPLAY_TIME_FORMAT } from "../../consts";
import { getAppointmentLocationText } from "../../helpers/appointments";
import {
  availabilityToColor,
  getTherapistsByAvailability,
  IUserWithAvailability,
} from "../../helpers/schedules";
import { IGhostBlock } from "./types";

const { Text } = Typography;

interface IProps {
  therapists: IUserWithAvailability[];
  appointment: IGhostBlock;
  appointments: IAppointment[];
  weekday: Weekday;
}

export const GhostBlock: React.FC<IProps> = ({
  therapists,
  appointment,
  appointments,
  weekday,
}: IProps) => {
  const sortedTherapists = getTherapistsByAvailability(
    therapists,
    appointments,
    appointment as any as IAppointment,
    weekday
  );

  return (
    <Card
      size="small"
      bodyStyle={{ paddingTop: 12, paddingBottom: 7 }}
      style={{
        marginBottom: 3,
        borderColor: COLORS.NEUTRAL_ORANGE,
      }}
    >
      <>
        <Row style={{ width: "100%", marginBottom: 5 }}>
          <Select
            allowClear
            showSearch
            placeholder="Therapist"
            optionFilterProp="key"
            style={{ width: "100%" }}
            suffixIcon={null}
            value={therapists.find((therapist) => appointment.userEmail === therapist.email)?.email}
            disabled={true}
          >
            {sortedTherapists.map((therapist) => (
              <Select.Option
                key={`${therapist.firstName} ${therapist.lastName}`}
                value={therapist.email}
              >
                <Badge
                  color={availabilityToColor(therapist.available)}
                  text={<Text disabled>{`${therapist.firstName} ${therapist.lastName}`}</Text>}
                />
              </Select.Option>
            ))}
          </Select>
        </Row>
        <Row style={{ width: "100%", marginBottom: 5 }}>
          <TimePicker
            hideDisabledOptions
            placeholder="Start"
            format={DISPLAY_TIME_FORMAT}
            minuteStep={15}
            use12Hours
            showNow={false}
            value={moment(appointment.startMs)}
            style={{ width: "100%" }}
            disabled={true}
          />
        </Row>
        <Row style={{ width: "100%", marginBottom: 5 }}>
          <TimePicker
            hideDisabledOptions
            placeholder="End"
            format={DISPLAY_TIME_FORMAT}
            minuteStep={15}
            use12Hours
            showNow={false}
            value={moment(appointment.endMs)}
            style={{ width: "100%" }}
            disabled={true}
          />
        </Row>
        <Row style={{ width: "100%", marginBottom: 5 }}>
          <Select
            placeholder="Location"
            style={{ width: "100%", textAlign: "left" }}
            suffixIcon={null}
            value={appointment.location}
            disabled={true}
          >
            {Object.values(AppointmentLocation).map((location) => (
              <Select.Option key={location} value={location}>
                {getAppointmentLocationText(location)}
              </Select.Option>
            ))}
          </Select>
        </Row>
      </>
    </Card>
  );
};
