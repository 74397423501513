import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { PrivateRoute } from "./components/PrivateRoute";
import { UserClinicsProvider } from "./components/UserClinicsProvider";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { SignUpFlow } from "./pages/SignUpFlow";

function App() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "bogus");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route
          path="*"
          element={
            <UserClinicsProvider>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUpFlow />} />
                <Route
                  path="/:clinicName?/*"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </UserClinicsProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
