import { capitalizeFirstLetter } from "@finni-health/shared";

export const getRejectionReasonText = (reason?: string | null) => {
  if (reason === undefined || reason === null) {
    return "";
  }

  return reason
    .split("_")
    .map((substring: string) => capitalizeFirstLetter(substring.toLowerCase()))
    .join(" ");
};
