import "../css/NotesPopover.css";

import { red } from "@ant-design/colors";
import {
  CheckCircleFilled,
  CheckOutlined,
  CheckSquareFilled,
  CloseSquareFilled,
  DisconnectOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
  ReloadOutlined,
  SplitCellsOutlined,
} from "@ant-design/icons";
import {
  BillingCode,
  getAppointmentNameFromBilling,
  IAppointment,
  INote,
  Modifier,
  UserPermission,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Button, Col, Descriptions, Divider, Row, Tabs, Tooltip, Typography } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import moment from "moment";
import React from "react";
import { FaStamp } from "react-icons/fa";
import { IoSchoolOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, MOTIVITY_FORMS_URL } from "../../consts";
import { getAppointmentLocationText } from "../../helpers/appointments";
import { isNoteApprovable, noteRequiresParentSignature } from "../../helpers/appointments";
import { useUserClinics } from "../UserClinicsProvider";

const { Paragraph } = Typography;

interface IProps {
  notes: INote[];
  appointment: IAppointment;
  isLoading: boolean;
  handleUnmatchNote: (id: string, appointmentId: string) => Promise<void>;
  handleReloadNote: (id: string) => Promise<void>;
  handleApproveNote: (id: string) => Promise<void>;
  handleCompleteNote: (id: string) => Promise<void>;
  handleSplitAppointment: (note: INote, appointment: IAppointment) => Promise<void>;
  setDisplayCompletionEffect: (value: boolean) => void;
  setDisplaySplitEffectNote: (note: INote) => void;
  openMatchNotesDrawer: () => void;
  hidePopover: () => void;
}

export const NotesTabs: React.FC<IProps> = ({
  notes,
  appointment,
  isLoading,
  handleUnmatchNote,
  handleReloadNote,
  handleApproveNote,
  handleCompleteNote,
  handleSplitAppointment,
  setDisplayCompletionEffect,
  setDisplaySplitEffectNote,
  openMatchNotesDrawer,
  hidePopover,
}: IProps) => {
  const { user, clinic } = useUserClinics();

  const getTabContent = () => {
    const tabContent = notes.map((note: INote) => {
      if (user?.permissions?.includes(UserPermission.ADMIN) || user?.id == note.userId) {
        return {
          label: (
            <>
              {note.approved == true && (
                <Tooltip title={note.approved && "Approved"}>
                  <CheckCircleFilled style={{ marginRight: 3, color: COLORS.GREEN }} />
                </Tooltip>
              )}
              {moment(note.startMs).format(DISPLAY_TIME_FORMAT)}
            </>
          ),
          key: note.id,
          children: (
            <Col>
              <Row style={{ maxHeight: 400, overflowY: "auto" }}>
                <Row
                  style={{ marginBottom: 5, width: "100%" }}
                  align="bottom"
                  justify="space-between"
                >
                  <div>
                    <Link
                      to={`${MOTIVITY_FORMS_URL + note.id}`}
                      target="_blank"
                      style={{ fontWeight: "bold" }}
                    >
                      {note.noteType}
                    </Link>
                  </div>
                  <div>
                    <Tooltip title="Reload this note from Motivity">
                      <Button
                        size="small"
                        loading={isLoading}
                        onClick={() => handleReloadNote(note.id)}
                      >
                        <ReloadOutlined />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Un-link note from this session">
                      <Button
                        size="small"
                        loading={isLoading}
                        onClick={() => handleUnmatchNote(note.id, note.appointmentId)}
                      >
                        <DisconnectOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                </Row>
                <Descriptions labelStyle={{ fontWeight: 600 }} column={1}>
                  <DescriptionsItem label="Session">
                    {getAppointmentNameFromBilling(
                      clinic.address.state,
                      note.billingCode as BillingCode,
                      note.modifiers as Modifier[]
                    )}
                  </DescriptionsItem>
                  <DescriptionsItem label="Client">
                    <Link to={`../client-profile/${note.clientId}`}>
                      <IoSchoolOutline
                        style={{
                          fontSize: 18,
                          marginBottom: -3,
                          marginRight: 3,
                        }}
                      />
                      {note.clientFullName}
                    </Link>
                  </DescriptionsItem>
                  <DescriptionsItem label="Provider">{note.providerFullName}</DescriptionsItem>
                  <DescriptionsItem label="Date">
                    {moment(note.startMs).format(DISPLAY_DATE_FORMAT)}
                  </DescriptionsItem>
                  <DescriptionsItem label="Start">
                    {moment(note.startMs).format(DISPLAY_TIME_FORMAT)}
                  </DescriptionsItem>
                  <DescriptionsItem label="End">
                    {moment(note.endMs).format(DISPLAY_TIME_FORMAT)}
                  </DescriptionsItem>
                  <DescriptionsItem label="Location">
                    {getAppointmentLocationText(note.location)}
                  </DescriptionsItem>
                  <DescriptionsItem label="Provider Signed">
                    {note.providerSignedMs ? (
                      <CheckSquareFilled style={{ color: COLORS.GREEN }} />
                    ) : (
                      <CloseSquareFilled style={{ color: COLORS.RED }} />
                    )}
                  </DescriptionsItem>
                  {noteRequiresParentSignature(note) && (
                    <DescriptionsItem label="Client Signed">
                      {note.clientSignedMs ? (
                        <CheckSquareFilled style={{ color: COLORS.GREEN }} />
                      ) : (
                        <CloseSquareFilled style={{ color: COLORS.RED }} />
                      )}
                    </DescriptionsItem>
                  )}
                  <DescriptionsItem label="Narrative">
                    <Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: "more",
                      }}
                    >
                      {note.narrative}
                    </Paragraph>
                  </DescriptionsItem>
                </Descriptions>
              </Row>
              {note.approved === false ? (
                <>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  <Row justify="end">
                    <Button
                      type={isNoteApprovable(note) ? "primary" : "default"}
                      style={{ padding: "0px 10px 0px 10px" }}
                      onClick={() => {
                        hidePopover();
                        return handleApproveNote(note.id);
                      }}
                    >
                      {isNoteApprovable(note) ? (
                        <CheckOutlined />
                      ) : (
                        <ExclamationCircleFilled
                          style={{
                            color: red.primary,
                          }}
                        />
                      )}
                      {`${note.motivityUUID ? "Fast Approve" : "Approve"}`}
                    </Button>
                  </Row>
                </>
              ) : notes.length == 1 ? (
                <>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  <Row justify="end">
                    <Button
                      type="primary"
                      style={{ padding: "0px 10px 0px 10px" }}
                      onClick={() => {
                        hidePopover();
                        return handleCompleteNote(note.id);
                      }}
                      onMouseEnter={() => setDisplayCompletionEffect(true)}
                      onMouseLeave={() => setDisplayCompletionEffect(false)}
                    >
                      <FaStamp
                        style={{
                          marginRight: 7,
                          marginBottom: -1,
                          fontSize: 14,
                        }}
                      />
                      Complete Appointment
                    </Button>
                  </Row>
                </>
              ) : (
                notes.length > 1 && (
                  <>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Row justify="end">
                      <Button
                        type="primary"
                        style={{ padding: "0px 10px 0px 10px" }}
                        onClick={() => {
                          hidePopover();
                          return handleSplitAppointment(note, appointment);
                        }}
                        onMouseEnter={() => setDisplaySplitEffectNote(note)}
                        onMouseLeave={() => setDisplaySplitEffectNote({} as INote)}
                      >
                        <SplitCellsOutlined />
                        Split note into new appointment
                      </Button>
                    </Row>
                  </>
                )
              )}
            </Col>
          ),
        };
      }
    });

    return tabContent.filter((item) => item !== undefined) as {
      label: JSX.Element;
      key: string;
      children: JSX.Element;
    }[];
  };

  return (
    <Tabs
      size="small"
      style={{ marginTop: 15 }}
      items={getTabContent()}
      tabBarExtraContent={
        <Tooltip title="Link another note">
          <Button size="small" onClick={openMatchNotesDrawer}>
            <PlusOutlined />
          </Button>
        </Tooltip>
      }
    />
  );
};
