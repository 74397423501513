import { SCA_SUFFIX_TEXT } from "../../consts";
import {
  BillingCode,
  BillingLevel,
  IAppointment,
  IAppointmentType,
  ICompletedAppointment,
  IPayer,
  IStatePayersConfig,
  USStateCode,
} from "../../types";
import { generateAddress, getBaseBillingModifiers } from "./helpers";

export const VIRGINIA_APPOINTMENTS: IAppointmentType = {};

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  billingLevel: BillingLevel
) => {
  return getBaseBillingModifiers(appointment, payer, billingLevel);
};

export const VIRGINIA_PAYERS: IStatePayersConfig = [
  {
    name: "Tricare East",
    payerId: "TREST",
    contractAddress: generateAddress(
      "1408 Shady Tree Way",
      "",
      "Chesapeake",
      "23323-2942",
      USStateCode.VA
    ),
    billingAddress: generateAddress(
      "1408 Shady Tree Way",
      "",
      "Chesapeake",
      "23323-2942",
      USStateCode.VA
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 4663,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1764,
          BCBA: 3906,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 4663,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 4495,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Blue Cross Blue Shield Anthem",
    payerId: "00423",
    contractAddress: generateAddress(
      "1408 Shady Tree Way",
      "",
      "Chesapeake",
      "23323-2942",
      USStateCode.VA
    ),
    billingAddress: generateAddress(
      "1408 Shady Tree Way",
      "",
      "Chesapeake",
      "23323-2942",
      USStateCode.VA
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 9162,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1619,
          BCBA: 2637,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3238,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2104,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Optima Health Community Care",
    payerId: "5415M",
    contractAddress: generateAddress(
      "1408 Shady Tree Way",
      "",
      "Chesapeake",
      "23323-2942",
      USStateCode.VA
    ),
    billingAddress: generateAddress(
      "1408 Shady Tree Way",
      "",
      "Chesapeake",
      "23323-2942",
      USStateCode.VA
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 0,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 0,
          BCBA: 0,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 0,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 0,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Blue Cross Blue Shield (Single Case Agreement)",
    payerId: `00423${SCA_SUFFIX_TEXT}`,
    contractAddress: generateAddress(
      "1408 Shady Tree Way",
      "",
      "Chesapeake",
      "23323-2942",
      USStateCode.VA
    ),
    billingAddress: generateAddress(
      "1408 Shady Tree Way",
      "",
      "Chesapeake",
      "23323-2942",
      USStateCode.VA
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 4663,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1764,
          BCBA: 3906,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 4663,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 4495,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
];
