import moment from "moment";

export const DEBOUNCE_TIME = 500;

/* eslint-disable no-control-regex */
export const ERROR_MESSAGE = "Something went wrong, please try again or contact #product on Slack";

export const ERROR_MESSAGE_APPOINTMENTS =
  "Something went wrong with appointments. Please contact @gideon/#product on Slack";

export enum APPOINTMENT_DELETE_TYPES {
  THIS = "THIS",
  THIS_AND_FUTURE = "THIS_AND_FUTURE",
  ALL = "ALL",
}

export enum APPOINTMENT_EDIT_TYPES {
  THIS = "THIS",
  ALL = "ALL",
}

// constant auths
export const AUTHORIZED_97156 = 4;

export const ANTD_COLORS_TO_HEX: Record<string, string> = {
  magenta: "#eb2f96",
  purple: "#722ed1",
  volcano: "#fa541c",
  orange: "#fa8c16",
  blue: "#1890ff",
  lime: "#a0d911",
  green: "#52c41a",
  cyan: "#13c2c2",
  gold: "#faad14",
  geekblue: "2f54eb",
  red: "#f5222d",
};

// datetime
export const TIME_FORMAT = "HH:mm:ss Z";
export const DISPLAY_TIME_FORMAT = "h:mma";
export const DB_DATE_FORMAT = "YYYY-MM-DD";
export const DISPLAY_DATE_FORMAT = "MM/DD/YYYY";
export const DISPLAY_DATETIME_FORMAT = "MM/DD/YYYY [at] h:mma";

export const HOURS = Array.from(Array(24).keys());
export const AM_HOURS = HOURS.slice(0, 12);
export const PM_HOURS = HOURS.slice(12);

// links
export const MOTIVITY_FORMS_URL =
  "https://app.motivity.net/orgs/-1Fu5VnIJ0OWP3NyAflEWg/psn/ppsn/snote/";

//templates
export const CSV_HEADER_TEMPLATE =
  "firstName,lastName,dateOfBirth,primaryPhoneNumber,homePhoneNumber,workPhoneNumber,cellPhoneNumber,sex,addressLine1,addressLine2,city,zipCode,province,country,email,medicalHistory,additionalNotes";

//regex
export const VALID_EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const VALID_FINNI_EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@finnihealth.com/;
export const VALID_PHONE_NUMBER_REGEX = /^(\+?\d{1,2}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const VALID_PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*-_=+;:'",<.>?])[A-Za-z\d`~!@#$%^&*-_=+;:'",<.>?]{8,}$/;

export const VALID_ZIP_CODE_REGEX = /^\d{5}(?:[-]\d{4})?$/;

//placeholders
export const FIRST_NAME_PLACEHOLDER = "";
export const MIDDLE_NAME_PLACEHOLDER = "";
export const LAST_NAME_PLACEHOLDER = "";
export const DATE_PLACEHOLDER = "YYYY-MM-DD";
export const EMAIL_PLACEHOLDER = "";
export const ADDRESS_PLACEHOLDER = "";
export const CITY_PLACEHOLDER = "";
export const PHONE_NUMBER_PLACEHOLDER = "";
export const MEDICAL_HISTORY_PLACEHOLDER = "";
export const ADDITIONAL_NOTES_PLACEHOLDER = "";
export const SEX_PLACEHOLDER = "";
export const PASSWORD_PLACEHOLDER = "";
export const CLINIC_ACCESS_CODE_PLACEHOLDER = "";
export const ZIP_CODE_PLACEHOLDER = "";
export const SERVICE_NAME_PLACEHOLDER = "";
export const SERVICE_DESCRIPTION_PLACEHOLDER = "";

export const START_OF_COMPLETION_TRACKING = moment("2022-12-14");

//SignUp Flow states
export const enum SignUpStates {
  //Start
  CHECK_INVITES,

  //Basic states
  ENTER_INVITE_CODE,
  INVITE_FOUND,

  // Joining existing practice
  ENTER_PERSONAL_PHONE_NUMBER,
  ENTER_PERSONAL_ADDRESS,
  ENTER_PREFERRED_TRANSPORT,
  COMPLETE,
}

export const US_TIMEZONES: Record<string, string> = {
  EST: "America/New_York",
  CST: "America/Chicago",
  MST: "America/Denver",
  PST: "America/Los_Angeles",
};
