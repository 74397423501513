import {
  BillingCode,
  BillingLevel,
  IAppointment,
  IAppointmentType,
  ICompletedAppointment,
  IPayer,
  IStatePayersConfig,
  USStateCode,
} from "../../types";
import { generateAddress, getBaseBillingModifiers } from "./helpers";

export const GEORGIA_APPOINTMENTS: IAppointmentType = {};

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  billingLevel: BillingLevel
) => {
  return getBaseBillingModifiers(appointment, payer, billingLevel);
};

export const GEORGIA_PAYERS: IStatePayersConfig = [
  {
    name: "Tricare East",
    payerId: "TREST",
    contractAddress: generateAddress(
      "2942 Lynda Ln",
      "",
      "Columbus",
      "31906 - 1361",
      USStateCode.GA
    ),
    billingAddress: generateAddress(
      "2942 Lynda Ln",
      "",
      "Columbus",
      "31906 - 1361",
      USStateCode.GA
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3897,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 2030,
          BCBA: 3897,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3897,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3125,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
];
