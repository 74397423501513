import {
  capitalizeFirstLetter,
  IAppointment,
  ICalendarEvent,
  IClient,
  IClientDetails,
  INote,
  IUser,
  UserPermission,
} from "@finni-health/shared";
import { Row, Space, Spin, Statistic, Tabs } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ListView } from "../components/Calendar/ListView";
import { MatchNotesDrawer } from "../components/Calendar/MatchNotesDrawer";
import { useUserClinics } from "../components/UserClinicsProvider";
import { userHasPermission } from "../helpers/userPermissions";
import * as FirestoreService from "../services/firestore";

export enum APPROVALS_TABS {
  MISSING_NOTES = "Missing Notes",
  APPOINTMENTS = "Appointments",
  INDIRECTS = "Indirects",
  CANCELLATIONS = "Cancellations",
}

export const Approvals: React.FC = () => {
  const { user } = useUserClinics();
  const { tab } = useParams<{ tab: APPROVALS_TABS }>();
  const now = moment();

  //general states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAlertTab, setSelectedAlertTab] = useState<APPROVALS_TABS>(
    APPROVALS_TABS.MISSING_NOTES
  );

  //url
  useEffect(() => {
    const { pathname } = location;
    const idx = pathname.indexOf("approvals");
    const link =
      pathname.slice(0, idx + "approvals".length) +
      "/" +
      selectedAlertTab.replace(" ", "-").toLowerCase();
    window.history.replaceState(null, "Mission Control - Finni Health", link);
  }, [selectedAlertTab]);

  //clinic users & clients
  const [clinicUsers, setClinicUsers] = useState<IUser[]>([]);
  const [clientsDetails, setClientsDetails] = useState<Record<string, IClientDetails>>({});

  //eventsOverride for listView
  const [eventsOverride, setEventsOverride] = useState<ICalendarEvent[]>([]);

  //events
  const [missingNoteAppointments, setMissingNoteAppointments] = useState<ICalendarEvent[]>([]);
  const [approvableAppointments, setApprovableAppointments] = useState<ICalendarEvent[]>([]);
  const [pendingIndirects, setPendingIndirects] = useState<ICalendarEvent[]>([]);
  const [pendingCancellations, setPendingCancellations] = useState<ICalendarEvent[]>([]);

  // Match notes drawer
  const [selectedEvent, setSelectedEvent] = useState<ICalendarEvent>();
  const [selectedClient, setSelectedClient] = useState<IClient>();
  const [unmatchedNotes, setUnmatchedNotes] = useState<INote[]>([]);
  const [isMatchNotesDrawerOpen, setIsMatchNotesDrawerOpen] = useState<boolean>(false);
  const hideMatchNotesDrawer = () => {
    setIsMatchNotesDrawerOpen(false);
  };

  const tabPaneValues = {
    [APPROVALS_TABS.APPOINTMENTS]: approvableAppointments.length,
    [APPROVALS_TABS.CANCELLATIONS]: pendingCancellations.length,
    [APPROVALS_TABS.INDIRECTS]: pendingIndirects.length,
    [APPROVALS_TABS.MISSING_NOTES]: missingNoteAppointments.length,
  };

  useEffect(() => {
    fetchData().catch(() => {});
  }, [user]);

  useEffect(() => {
    if (!isLoading) {
      handleTabChange();
    }
  }, [selectedAlertTab, isLoading]);

  const handleTabChange = () => {
    switch (selectedAlertTab) {
      case APPROVALS_TABS.MISSING_NOTES:
        return setEventsOverride(missingNoteAppointments);
      case APPROVALS_TABS.APPOINTMENTS:
        return setEventsOverride(approvableAppointments);
      case APPROVALS_TABS.INDIRECTS:
        return setEventsOverride(pendingIndirects);
      case APPROVALS_TABS.CANCELLATIONS:
        return setEventsOverride(pendingCancellations);
    }
  };

  const refresh = async () => {
    await fetchData(false);
  };

  const fetchClinicUsers = async () => {
    const users = await FirestoreService.getAllUsersForClinic(user.clinicId);

    const clinicUsers = users.filter((clinicUser) =>
      userHasPermission(user, UserPermission.RBT)
        ? user.id == clinicUser.id
        : [UserPermission.BCBA, UserPermission.RBT].some((permission) =>
            userHasPermission(clinicUser, permission)
          )
    );
    setClinicUsers(clinicUsers);
    return clinicUsers;
  };

  const fetchClientFiles = async () => {
    const clientDetails = await FirestoreService.getClientsDetailsByClinicId(user.clinicId);
    const clientDetailsMap = _.keyBy(clientDetails, (clientDetails) => clientDetails.client.id);

    setClientsDetails(clientDetailsMap);
    return clientDetailsMap;
  };

  const fetchApprovalAppointments = async () => {
    const approvalAppointments = await FirestoreService.getAllApprovalsByClinicId(user.clinicId);

    setApprovableAppointments(approvalAppointments.approvableAppointments);
    setMissingNoteAppointments(approvalAppointments.missingNoteAppointments);
    setPendingIndirects(approvalAppointments.pendingIndirects);
    setPendingCancellations(approvalAppointments.pendingCancellations);
    return approvableAppointments;
  };

  const fetchUnmatchedNotes = async () => {
    let unmatchedNotes = await FirestoreService.getAllUnmatchedNotesByClinicId(user.clinicId);
    if (userHasPermission(user, UserPermission.RBT)) {
      unmatchedNotes = unmatchedNotes.filter((note) => note.userId === user.id);
    }
    setUnmatchedNotes(unmatchedNotes);
    return unmatchedNotes;
  };

  const fetchData = async (showLoading = true) => {
    if (showLoading) {
      setIsLoading(true);
    }

    await Promise.all([
      fetchApprovalAppointments(),
      fetchClinicUsers(),
      fetchClientFiles(),
      fetchUnmatchedNotes(),
    ]);

    handleTabChange();

    setIsLoading(false);
  };

  return (
    <div style={{ marginTop: 5 }}>
      <Row>
        <Tabs
          style={{ width: "100%" }}
          defaultActiveKey={tab ? tab : selectedAlertTab.valueOf()}
          onChange={(key) => {
            setSelectedAlertTab(key as APPROVALS_TABS);
          }}
          items={Object.values(APPROVALS_TABS).map((tab) => ({
            key: tab,
            label: (
              <Statistic
                title={capitalizeFirstLetter(tab)}
                value={tabPaneValues[tab]}
                loading={isLoading}
              />
            ),
          }))}
        ></Tabs>
      </Row>
      <Space direction="vertical" style={{ width: "100%" }}>
        {isLoading ? (
          <Row justify="center" align="middle" style={{ height: 400 }}>
            <Spin size="large" />
          </Row>
        ) : (
          !_.isEmpty(eventsOverride) && (
            <ListView
              clinicUsers={clinicUsers}
              clientsDetails={clientsDetails}
              notes={[] as INote[]}
              calWeek={now}
              searchString={""}
              showIncompleteAppointments={false}
              setSelectedEvent={setSelectedEvent}
              setSelectedClient={setSelectedClient}
              setIsMatchNotesDrawerOpen={setIsMatchNotesDrawerOpen}
              eventsOverride={eventsOverride}
              refreshAll={refresh}
              extendedView={true}
            />
          )
        )}
      </Space>
      <MatchNotesDrawer
        appointment={selectedEvent as IAppointment}
        client={selectedClient}
        unmatchedNotes={unmatchedNotes}
        isOpen={isMatchNotesDrawerOpen}
        hideDrawer={hideMatchNotesDrawer}
        refreshCallback={refresh}
        selectedFilters={[]}
        setSelectedFilters={() => {}}
        setMatchingAppointment={() => {}}
        setSelectedEvent={setSelectedEvent}
        setSelectedClient={setSelectedClient}
      />
    </div>
  );
};
