import { CountryCode, IUpdateClinicEndpointRequest, USStateCode } from "@finni-health/shared";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Tag,
} from "antd";
import { Timestamp } from "firebase/firestore";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { VALID_EMAIL_REGEX, VALID_ZIP_CODE_REGEX } from "../../consts";
import * as FirestoreService from "../../services/firestore";
import { PhoneNumberInput } from "../PhoneNumberInput";
import { useUserClinics } from "../UserClinicsProvider";

interface IEditClinicFormValues {
  name: string;
  displayName: string;
  accessCode: string;
  phoneNumber: string;
  email: string;
  serviceAreas: string[];
  line1: string;
  line2: string;
  city: string;
  state: USStateCode;
  zipCode: string;
  country: CountryCode;
}

export const EditClinicForm: React.FC = () => {
  const { clinic } = useUserClinics();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [form] = Form.useForm<IEditClinicFormValues>();
  const serviceAreas = Form.useWatch("serviceAreas", form);

  const handleUpdateClinic = async () => {
    setIsSaving(true);

    const values = form.getFieldsValue();

    const updateClinicRequest: IUpdateClinicEndpointRequest = {
      id: clinic.id,
      name: values.name,
      displayName: values.displayName,
      accessCode: values.accessCode,
      phoneNumber: values.phoneNumber || "",
      email: values.email || "",
      serviceAreas: values.serviceAreas,
      address: {
        line1: values.line1 || "",
        line2: values.line2 || "",
        city: values.city || "",
        state: values.state || "",
        zipCode: values.zipCode || "",
        country: values.country,
      },
    };

    try {
      await FirestoreService.updateClinic(updateClinicRequest);
      void message.success(`Changed saved`);
    } catch (err) {
      void message.error(
        "Couldn't save changes, please try again or contact #product on Slack for help"
      );
      console.error(err);
    }

    setIsSaving(false);
  };

  useEffect(() => {
    console.log(clinic);
    if (!_.isEmpty(clinic)) {
      setIsLoading(false);
      form.resetFields();
    }
  }, [clinic]);

  useEffect(() => {
    if (!_.isEmpty(serviceAreas)) {
      const newServiceAreas = serviceAreas.filter((zipCode) => {
        if (zipCode.length !== 5) {
          void message.warn("Please enter a valid 5 digit Zip code");
          return false;
        } else {
          return true;
        }
      });
      form.setFieldValue("serviceAreas", newServiceAreas);
    }
  }, [serviceAreas]);

  return (
    <>
      {isLoading ? (
        <Row justify="center" align="middle" style={{ height: 484.84 }}>
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <Form layout="vertical" labelCol={{ span: 24 }} onFinish={handleUpdateClinic} form={form}>
            <Col style={{ width: "100%" }}>
              <Row justify="space-between" style={{ marginBottom: 20 }}>
                <Space size="large">
                  <Statistic
                    title="Active Since"
                    value={`${moment((clinic.createdAt as Timestamp).toDate()).format(
                      "MMMM DD, YYYY"
                    )}`}
                  />
                </Space>
                <Button loading={isSaving} type="primary" htmlType="submit">
                  Save Changes
                </Button>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Divider orientation="left">Clinic Information</Divider>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Clinic Name"
                    name="name"
                    initialValue={clinic.name}
                    rules={[
                      {
                        required: true,
                        message: "Please input your clinic's Name",
                      },
                    ]}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Display Name"
                    name="displayName"
                    initialValue={clinic.displayName}
                    rules={[
                      {
                        required: true,
                        message: "Please input your clinic's Display Name",
                      },
                    ]}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Clinic Access Code"
                    name="accessCode"
                    initialValue={clinic.accessCode}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Clinic Access Code",
                      },
                    ]}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    initialValue={clinic.phoneNumber}
                  >
                    <PhoneNumberInput disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    initialValue={clinic.email}
                    rules={[
                      {
                        //valid email regex
                        pattern: VALID_EMAIL_REGEX,
                        message: "Please provide a valid Email, or leave this field blank",
                      },
                    ]}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider orientation="left">Address</Divider>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Address Line 1"
                    name="line1"
                    initialValue={clinic.address?.line1}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Address Line 2"
                    name="line2"
                    initialValue={clinic.address?.line2}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label="City" name="city" initialValue={clinic.address?.city}>
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="State" name="state" initialValue={clinic.address?.state}>
                    <Select showSearch disabled={isSaving}>
                      {Object.values(USStateCode).map((state) => (
                        <Select.Option value={state} key={state}>
                          {state}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Zip Code"
                    name="zipCode"
                    initialValue={clinic.address?.zipCode}
                    rules={[
                      {
                        pattern: VALID_ZIP_CODE_REGEX,
                        message: "Please enter a valid zip code",
                      },
                    ]}
                  >
                    <Input disabled={isSaving} maxLength={10} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Country" name="country" initialValue={CountryCode.US}>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider orientation="left">Service Areas</Divider>
              <Row style={{ width: "100%" }}>
                <Form.Item
                  style={{ width: "100%" }}
                  label="Zip Codes"
                  name="serviceAreas"
                  initialValue={clinic.serviceAreas}
                >
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    tagRender={(props: any) => (
                      <Tag
                        closable={true}
                        onClose={() => {
                          const newServiceAreas = _.cloneDeep(serviceAreas);
                          newServiceAreas.splice(serviceAreas.indexOf(props.value), 1);
                          form.setFieldValue("serviceAreas", newServiceAreas);
                        }}
                      >
                        {props.value}
                      </Tag>
                    )}
                    disabled={isSaving}
                  >
                    {clinic.serviceAreas.map((zipCode) => (
                      <Select.Option key={zipCode} value={zipCode}>
                        {zipCode}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
            </Col>
          </Form>
        </>
      )}
    </>
  );
};
