import { IClientFile } from "@finni-health/shared";
import { Anchor, Button, Col, Form, message, Row, Typography } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";

import { ERROR_MESSAGE } from "../../consts";
import * as FirestoreService from "../../services/firestore";
import { ClientDocumentsFolders } from "../EditClientFileModal/ClientDocumentsFolders";
import { ClientBehaviorsSection } from "./ClientBehaviorsSection";
import { ClientMedicalHistorySection } from "./ClientMedicalHistorySection";
import { ClientTherapyPreferencesSection } from "./ClientTherapyPreferencesSection";

const { Link } = Anchor;
const { Title } = Typography;

interface Props {
  clientFile: IClientFile;
  refreshCallback: () => Promise<void>;
}

export const ClientIntakeForm = ({ clientFile, refreshCallback }: Props) => {
  const [tempClientFile, setTempClientFile] = useState<IClientFile>({} as IClientFile);
  const [isEditingDisabled, setIsEditingDisabled] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setTempClientFile(_.cloneDeep(clientFile));
  }, [clientFile]);

  const updateTempClientFile = (source: Partial<IClientFile>) => {
    const merged = _.merge(_.cloneDeep(tempClientFile), source);
    setTempClientFile(merged);
  };

  const handleCancelEditing = async () => {
    setIsSaving(true);
    await refreshCallback();
    setIsEditingDisabled(true);
    setIsSaving(false);
  };

  const handleSaveClientFile = async () => {
    setIsSaving(true);
    try {
      const { scheduleNotes, ...clientFilePayload } = tempClientFile;
      await FirestoreService.updateClientFile(clientFilePayload);
      await refreshCallback();
      setIsEditingDisabled(true);
      void message.success("Changes saved");
    } catch (err) {
      void message.error(ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  return (
    <Row gutter={24}>
      <Col span={20}>
        <Form layout="vertical" labelCol={{ span: 24 }}>
          <Title id="documents" level={2} style={{ marginTop: 20 }}>
            Documents
          </Title>
          <ClientDocumentsFolders
            isEditingDisabled={isEditingDisabled}
            clientFileId={clientFile.id}
            documents={tempClientFile.documents}
            onDocumentsChange={(documents) => {
              updateTempClientFile({ documents });
            }}
          />
          <ClientTherapyPreferencesSection
            isEditingDisabled={isEditingDisabled}
            tempClientFile={tempClientFile}
            updateTempClientFile={updateTempClientFile}
            setTempClientFile={setTempClientFile}
          />
          <ClientBehaviorsSection
            isEditingDisabled={isEditingDisabled}
            tempClientFile={tempClientFile}
            updateTempClientFile={updateTempClientFile}
            setTempClientFile={setTempClientFile}
          />
          <ClientMedicalHistorySection
            isEditingDisabled={isEditingDisabled}
            tempClientFile={tempClientFile}
            updateTempClientFile={updateTempClientFile}
            setTempClientFile={setTempClientFile}
          />
        </Form>
      </Col>
      <Col span={4}>
        <div style={{ position: "sticky", top: 0 }}>
          <Anchor>
            {isEditingDisabled && (
              <Button
                type="primary"
                loading={isSaving}
                style={{ marginLeft: 16, marginBottom: 10, marginTop: 10 }}
                disabled={!isEditingDisabled}
                onClick={() => {
                  setIsEditingDisabled(false);
                }}
              >
                Edit Intake
              </Button>
            )}
            {!isEditingDisabled && (
              <Row>
                <Button
                  disabled={isSaving}
                  style={{ marginLeft: 16, marginBottom: 10, marginTop: 10 }}
                  onClick={handleCancelEditing}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  loading={isSaving}
                  disabled={_.isEqual(tempClientFile, clientFile)}
                  style={{ marginLeft: 16, marginBottom: 10, marginTop: 10 }}
                  onClick={handleSaveClientFile}
                >
                  Save
                </Button>
              </Row>
            )}
            <Link href="#documents" title="Documents" />
            <Link href="#therapyPreferences" title="Therapy Preferences">
              <Link href="#primaryTherapyAddress" title="Primary Therapy Address" />
              <Link href="#language" title="Language" />
              <Link href="#genderPreference" title="Gender Preference" />
              <Link href="#availability" title="Availability" />
            </Link>
            <Link href="#behaviors" title="Behaviors">
              <Link href="#selfHarm" title="Self Harm" />
              <Link href="#aggression" title="Aggression" />
              <Link href="#runAway" title="Run Away" />
              <Link href="#destroyProperty" title="Destroy Property" />
              <Link href="#flopOnGround" title="Flop On Ground" />
              <Link href="#takeOffClothes" title="Take Off Clothes" />
              <Link href="#goals" title="Goals" />
            </Link>
            <Link href="#medicalHistory" title="Medical History">
              <Link href="#diagnosesAndMedications" title="Diagnoses/Medications" />
              <Link href="#family" title="Family" />
              <Link href="#services" title="Services" />
              <Link href="#impairments" title="Impairments" />
            </Link>
          </Anchor>
        </div>
      </Col>
    </Row>
  );
};
