import { Row, Spin } from "antd";
import React from "react";

export const Loading: React.FC = () => {
  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Spin size="large" />
    </Row>
  );
};
