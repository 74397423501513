import {
  AttendeeStatus,
  IIndirect,
  IndirectRejectionReason,
  IndirectStatus,
  IUpdateIndirectEndpointRequest,
} from "@finni-health/shared";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { useState } from "react";

import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from "../../consts";
import { getRejectionReasonText } from "../../helpers/rejectionReason";
import * as FirestoreService from "../../services/firestore";

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  refreshCallback: () => void;
  hideModal: () => void;
  isOpen: boolean;
  indirect: IIndirect;
}

interface IRejectIndirectFormValues {
  reason: IndirectRejectionReason;
  notes: string;
}

export const RejectIndirectModal = ({ refreshCallback, hideModal, isOpen, indirect }: Props) => {
  const [form] = Form.useForm<IRejectIndirectFormValues>();

  const [isRejecting, setIsRejecting] = useState<boolean>(false);

  const saveRejection = async () => {
    setIsRejecting(true);

    try {
      const values = form.getFieldsValue();
      const request: IUpdateIndirectEndpointRequest = {
        id: indirect.id,
        attendees: indirect.attendees.map((attendee) => ({
          email: attendee.email,
          status: AttendeeStatus.DECLINED,
        })),
        status: IndirectStatus.REJECTED,
        rejectionReason: values.reason,
        rejectionNotes: values.notes ? values.notes : "",
      };

      await FirestoreService.updateIndirect(request);
      void message.success("Indirect rejected");

      form.resetFields();
      refreshCallback();
      hideModal();
    } catch (err) {
      void message.error("Failed to reject indirect");
      console.error(err);
    }
    setIsRejecting(false);
  };

  const hideModalIfNotSaving = () => {
    if (!isRejecting) {
      hideModal();
    }
  };

  return (
    <Modal
      title={`Reject Indirect`}
      closable={!isRejecting}
      footer={null}
      destroyOnClose={true}
      onCancel={hideModalIfNotSaving}
      open={isOpen}
      width={500}
      bodyStyle={{ paddingLeft: 40, paddingRight: 40 }}
    >
      <Card
        id="indirectCard"
        style={{ marginBottom: 15, marginTop: 8 }}
        bodyStyle={{
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <Row>
          <Col span={14}>
            <Row justify="space-between" style={{ width: "100%", height: 24 }}>
              <Text strong>{indirect.summary}</Text>
            </Row>
            <Text>{`${moment(indirect.startMs).format(DISPLAY_DATE_FORMAT)} `}</Text>
            <Text>{`${moment(indirect.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
              indirect.endMs
            ).format(DISPLAY_TIME_FORMAT)}`}</Text>
          </Col>
          <Col span={10}>
            <Text strong>Guests</Text>
            <Row>
              {indirect.attendees.slice(0, 2).map((attendee) => (
                <Tag key={attendee.email} style={{ marginBottom: 2, fontSize: 11 }}>
                  <Badge
                    status={
                      attendee.status === AttendeeStatus.ACCEPTED
                        ? "success"
                        : attendee.status === AttendeeStatus.DECLINED
                        ? "error"
                        : attendee.status === AttendeeStatus.TENTATIVE
                        ? "warning"
                        : "default"
                    }
                    style={{ marginRight: 3 }}
                  />
                  {attendee.email}
                </Tag>
              ))}
              <br />
              {indirect.attendees.slice(2).length > 0 && (
                <Text style={{ fontSize: 12 }}>
                  {`And ${indirect.attendees.slice(2).length} other guest${
                    indirect.attendees.slice(2).length > 1 ? "s" : ""
                  }`}
                </Text>
              )}
            </Row>
          </Col>
        </Row>
      </Card>

      <Form
        form={form}
        layout="vertical"
        labelWrap={false}
        labelCol={{ span: 24 }}
        onFinish={saveRejection}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Please select a rejection reason",
                },
              ]}
            >
              <Select
                placeholder="Select a reason"
                style={{ width: "100%" }}
                onChange={(value) => {
                  form.setFieldValue("reason", value);
                }}
              >
                {Object.entries(IndirectRejectionReason).map(([key, value]) => (
                  <Select.Option key={key} value={key}>{`${getRejectionReasonText(
                    value
                  )}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="notes">
              <TextArea
                placeholder="Short description (optional)"
                allowClear
                autoSize={{ minRows: 2 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={26}>
          <Col span={24}>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={isRejecting}
              style={{ float: "right" }}
            >
              Reject Indirect
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
