import { DeleteOutlined, StopOutlined } from "@ant-design/icons";
import {
  AppointmentLocation,
  AttendeeStatus,
  BillingCode,
  IAppointment,
  ICalendarCreateAppointmentEndpointRequest,
  IClientFile,
  isPayerAuthRequired,
  isPayerAuthValid,
  UserPermission,
  Weekday,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import {
  Badge,
  Button,
  Card,
  Col,
  message,
  Row,
  Select,
  Skeleton,
  TimePicker,
  Tooltip,
} from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { BsFillShieldSlashFill } from "react-icons/bs";
import { RiSaveFill } from "react-icons/ri";

import { DISPLAY_TIME_FORMAT } from "../../consts";
import { addUntilRrule, getAppointmentLocationText } from "../../helpers/appointments";
import {
  availabilityToColor,
  getTherapistsByAvailability,
  IUserWithAvailability,
} from "../../helpers/schedules";
import { userHasPermission } from "../../helpers/userPermissions";
import * as FirestoreService from "../../services/firestore";
import { ConfirmDeleteAppointmentModal } from "../Calendar/ConfirmDeleteAppointmentModal";
import { useUserClinics } from "../UserClinicsProvider";

interface IProps {
  therapists: IUserWithAvailability[];
  appointment: IAppointment;
  clientFile: IClientFile;
  refreshCallback: () => Promise<IAppointment[]>;
  isNavigating: boolean;
  appointments: IAppointment[];
  weekday: Weekday;
}

export const ScheduleBlock: React.FC<IProps> = ({
  therapists,
  appointment,
  clientFile,
  refreshCallback,
  isNavigating,
  appointments,
  weekday,
}: IProps) => {
  const { user, clinic } = useUserClinics();

  const [tempAppointment, setTempAppointment] = useState<IAppointment>({} as IAppointment);

  //Delete appointments modal
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState<boolean>(false);
  const hideConfirmDeleteModal = () => {
    setIsConfirmDeleteModalOpen(false);
  };
  const confirmDelete = () => {
    setIsConfirmDeleteModalOpen(true);
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!_.isEmpty(appointment)) {
      setTempAppointment(appointment);
      setIsLoading(false);
    }
  }, [appointment]);

  const handleDiscardChanges = () => {
    setTempAppointment(appointment);
  };

  const handleSave = async () => {
    if (tempAppointment.startMs > tempAppointment.endMs) {
      return void message.error("Start time must be before end time");
    }

    setIsLoading(true);
    // Get the main instance containing RRULE and let it end at this instance
    const mainAppointment = await FirestoreService.getAppointmentById({
      id: appointment.id.split("_")[0],
    });
    // If this is the first instance, just delete it and replace
    if (mainAppointment.startMs === appointment.startMs) {
      await FirestoreService.deleteAppointment({ id: mainAppointment.id });
    } else {
      const appointmentToEnd = addUntilRrule(mainAppointment, appointment);
      await FirestoreService.updateAppointment(appointmentToEnd);
    }

    // Create new appointment containing the changes to start today
    const originalStart = moment(appointment.startMs);
    const startDatetime = moment(tempAppointment.startMs)
      .set({
        year: originalStart.year(),
        month: originalStart.month(),
        date: originalStart.date(),
      })
      .day(originalStart.format("dddd"));
    const endDatetime = moment(tempAppointment.endMs)
      .set({
        year: originalStart.year(),
        month: originalStart.month(),
        date: originalStart.date(),
      })
      .day(originalStart.format("dddd"));

    // End time rolled over to the next day in UTC
    if (endDatetime.isBefore(startDatetime)) {
      endDatetime.add(1, "days");
    }

    const startMs = startDatetime.valueOf();
    const endMs = endDatetime.valueOf();

    const createAppointmentRequest: ICalendarCreateAppointmentEndpointRequest = {
      clinicId: tempAppointment.clinicId,
      clientId: tempAppointment.clientId,
      attendeeEmails: _.isEmpty(tempAppointment.attendees)
        ? []
        : [tempAppointment.attendees[0].email],
      billingCode: BillingCode.CODE_97153,
      modifiers: [],
      location: tempAppointment.location,
      summary: tempAppointment.summary,
      description: "Ongoing session created by Mission Control.",
      startMs,
      endMs,
      timeZone: moment.tz.guess(),
      noteId: tempAppointment.noteId,
      rrule: mainAppointment.rrule,
    };

    await FirestoreService.createAppointment(createAppointmentRequest);
    await refreshCallback();
    void message.success("Changes saved");
    setIsLoading(false);
  };

  const handleSelectTherapist = (email: string) => {
    const newAppointment = _.cloneDeep(tempAppointment);
    newAppointment.attendees = [{ email, status: AttendeeStatus.ACCEPTED }];
    setTempAppointment(newAppointment);
  };

  const handleTimeSelect = (key: string) => {
    return (datetime: Moment | null) => {
      if (datetime) {
        const newAppointment = _.cloneDeep(tempAppointment);
        _.set(newAppointment, key, datetime.valueOf());
        setTempAppointment(newAppointment);
      }
    };
  };

  const handleSelectLocation = (location: AppointmentLocation) => {
    const newAppointment = _.cloneDeep(tempAppointment);
    setTempAppointment({ ...newAppointment, location });
  };

  const sortedTherapists = getTherapistsByAvailability(
    therapists,
    appointments,
    tempAppointment,
    weekday
  );

  const requiresAuth = isPayerAuthRequired(
    clinic.address.state,
    clientFile.payers.primary?.payerId || "",
    appointment.billingCode,
    appointment.modifiers
  );

  const isAppointmentAuthorized =
    !requiresAuth || isPayerAuthValid(clientFile.payers.primary ?? null, tempAppointment.startMs);

  return (
    <Card
      size="small"
      bodyStyle={{ paddingTop: 5, paddingBottom: 7 }}
      style={{
        marginBottom: 3,
        borderColor: !_.isEqual(appointment, tempAppointment) ? COLORS.PRIMARY : undefined,
      }}
    >
      {isLoading || isNavigating ? (
        <Row justify="center" align="middle" style={{ height: 135 }}>
          <Skeleton />
        </Row>
      ) : (
        <>
          {!userHasPermission(user, UserPermission.RBT) && (
            <Row justify="space-between">
              {!_.isEqual(appointment, tempAppointment) ? (
                <>
                  <Tooltip title="Discard Changes">
                    <Button
                      icon={<StopOutlined style={{ fontSize: 12 }} />}
                      size="small"
                      type="text"
                      onClick={handleDiscardChanges}
                    />
                  </Tooltip>
                  <Row align="middle">
                    <Col>
                      <Tooltip title="Save">
                        <Button
                          icon={
                            <RiSaveFill
                              style={{
                                position: "relative",
                                top: 2,
                                fontSize: 16,
                                color: COLORS.PRIMARY,
                              }}
                            />
                          }
                          size="small"
                          type="text"
                          onClick={handleSave}
                        />
                      </Tooltip>
                    </Col>
                    {!isAppointmentAuthorized && userHasPermission(user, UserPermission.ADMIN) && (
                      <Col>
                        <Tooltip title="Auth expired">
                          <BsFillShieldSlashFill
                            style={{
                              position: "relative",
                              top: 1,
                              fontSize: 14,
                              color: COLORS.DARK_YELLOW,
                            }}
                          />
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                </>
              ) : (
                <>
                  <Tooltip title="Delete">
                    <Button
                      icon={<DeleteOutlined style={{ fontSize: 12 }} />}
                      size="small"
                      type="text"
                      onClick={confirmDelete}
                    />
                  </Tooltip>
                  <Row align="middle">
                    {!isAppointmentAuthorized && (
                      <Col>
                        <Tooltip title="Auth expired">
                          <BsFillShieldSlashFill
                            style={{
                              position: "relative",
                              top: 1,
                              fontSize: 14,
                              color: COLORS.DARK_YELLOW,
                            }}
                          />
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                </>
              )}
            </Row>
          )}
          <Row style={{ width: "100%", marginBottom: 5 }}>
            <Select
              allowClear
              showSearch
              placeholder="Therapist"
              optionFilterProp="key"
              style={{ width: "100%" }}
              suffixIcon={null}
              defaultValue={
                therapists.find((therapist) =>
                  tempAppointment.attendees.find(
                    (attendee: any) => attendee.email === therapist.email
                  )
                )?.email
              }
              onSelect={handleSelectTherapist}
              disabled={userHasPermission(user, UserPermission.RBT)}
            >
              {sortedTherapists.map((therapist) => (
                <Select.Option
                  key={`${therapist.firstName} ${therapist.lastName}`}
                  value={therapist.email}
                >
                  <Badge
                    color={availabilityToColor(therapist.available)}
                    text={`${therapist.firstName} ${therapist.lastName}`}
                  />
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Row style={{ width: "100%", marginBottom: 5 }}>
            <TimePicker
              hideDisabledOptions
              placeholder="Start"
              format={DISPLAY_TIME_FORMAT}
              minuteStep={15}
              use12Hours
              showNow={false}
              value={moment(tempAppointment.startMs)}
              style={{ width: "100%" }}
              onSelect={handleTimeSelect("startMs")}
              onChange={handleTimeSelect("startMs")}
              disabled={userHasPermission(user, UserPermission.RBT)}
            />
          </Row>
          <Row style={{ width: "100%", marginBottom: 5 }}>
            <TimePicker
              hideDisabledOptions
              placeholder="End"
              format={DISPLAY_TIME_FORMAT}
              minuteStep={15}
              use12Hours
              showNow={false}
              value={moment(tempAppointment.endMs)}
              style={{ width: "100%" }}
              onSelect={handleTimeSelect("endMs")}
              onChange={handleTimeSelect("endMs")}
              disabled={userHasPermission(user, UserPermission.RBT)}
            />
          </Row>
          <Row style={{ width: "100%", marginBottom: 5 }}>
            <Select
              placeholder="Location"
              style={{ width: "100%", textAlign: "left" }}
              suffixIcon={null}
              value={tempAppointment.location}
              onSelect={handleSelectLocation}
              disabled={userHasPermission(user, UserPermission.RBT)}
            >
              {Object.values(AppointmentLocation).map((location) => (
                <Select.Option key={location} value={location}>
                  {getAppointmentLocationText(location)}
                </Select.Option>
              ))}
            </Select>
          </Row>
        </>
      )}
      <ConfirmDeleteAppointmentModal
        appointment={appointment}
        isVisible={isConfirmDeleteModalOpen}
        hideModal={hideConfirmDeleteModal}
        refreshCallback={refreshCallback}
      />
    </Card>
  );
};
