import {
  BillingLevel,
  CountryCode,
  IAddress,
  IAppointment,
  ICompletedAppointment,
  IPayer,
  Modifier,
  USStateCode,
} from "../../types";

export const sortModifiers = (modifiers: Modifier[]) => {
  return modifiers.sort((a, b) =>
    Object.values(Modifier).indexOf(a) > Object.values(Modifier).indexOf(b) ? 1 : -1
  );
};

/**
 * Gets the billing modifiers of a given payer with other modifiers that are common to all payers
 * This function should only be called by getBillingModifiers.
 *
 * @param appointment a completed appointment object
 * @param payer the payer object
 * @param billingLevel billing level of the appointment
 * @returns the billing modifiers
 */
export const getBaseBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  billingLevel: BillingLevel
) => {
  const modifiers = new Set(appointment.modifiers);

  if (payer.modifiers[billingLevel]) {
    modifiers.add(payer.modifiers[billingLevel] as Modifier);
  }

  if (payer.locationModifiers[appointment.location] !== undefined) {
    modifiers.add(payer.locationModifiers[appointment.location] as Modifier);
  }

  return sortModifiers(Array.from(modifiers));
};

export const generateAddress = (
  line1: string,
  line2: string,
  city: string,
  zipCode: string,
  state: USStateCode
) => {
  return {
    line1,
    line2,
    city,
    state: state,
    country: CountryCode.US,
    zipCode,
  } as IAddress;
};
