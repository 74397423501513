import { SCA_SUFFIX_TEXT } from "../../consts";
import {
  AppointmentLocation,
  AppointmentType,
  BillingCode,
  BillingLevel,
  IAppointment,
  IAppointmentType,
  ICompletedAppointment,
  IPayer,
  IStatePayersConfig,
  Modifier,
  USStateCode,
} from "../../types";
import { generateAddress, getBaseBillingModifiers } from "./helpers";

export const COLORADO_APPOINTMENTS: IAppointmentType = {
  [AppointmentType.UPDATED_ASSESSMENT]: {
    billingCode: BillingCode.CODE_97151,
    modifiers: [Modifier.TJ],
  },
};

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  billingLevel: BillingLevel
) => {
  return getBaseBillingModifiers(appointment, payer, billingLevel);
};

export const COLORADO_PAYERS: IStatePayersConfig = [
  {
    name: "Tricare West",
    payerId: "99726",
    contractAddress: generateAddress(
      "378 Mount Bross Ave",
      "",
      "Severance",
      "80550-4862",
      USStateCode.CO
    ),
    billingAddress: generateAddress(
      "378 Mount Bross Ave",
      "",
      "Severance",
      "80550-4862",
      USStateCode.CO
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {
      [AppointmentLocation.TELEHEALTH]: Modifier.MOD_95,
    },
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3673,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1815,
          BCBA: 3125,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3163,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3125,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Blue Cross Blue Shield Anthem",
    payerId: "00660",
    contractAddress: generateAddress(
      "378 Mount Bross Ave",
      "",
      "Severance",
      "80550-4862",
      USStateCode.CO
    ),
    billingAddress: generateAddress(
      "378 Mount Bross Ave",
      "",
      "Severance",
      "80550-4862",
      USStateCode.CO
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 9162,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1619,
          BCBA: 2637,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3238,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2104,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Colorado Health First (Medicaid)",
    payerId: "COCHA",
    contractAddress: generateAddress(
      "378 Mount Bross Ave",
      "",
      "Severance",
      "80550-4862",
      USStateCode.CO
    ),
    billingAddress: generateAddress(
      "378 Mount Bross Ave",
      "",
      "Severance",
      "80550-4862",
      USStateCode.CO
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        [Modifier.TJ]: {
          authRequired: true,
          unitSize: 15,
          rates: {
            BCBA: 3883,
          },
        },
        "": {
          authRequired: true,
          unitSize: null,
          rates: {
            BCBA: 33094,
          },
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 1439,
          RBT: 1439,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2245,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Blue Cross Blue Shield (Single Case Agreement)",
    payerId: `00050${SCA_SUFFIX_TEXT}`,
    contractAddress: generateAddress(
      "378 Mount Bross Ave",
      "",
      "Severance",
      "80550-4862",
      USStateCode.CO
    ),
    billingAddress: generateAddress(
      "378 Mount Bross Ave",
      "",
      "Severance",
      "80550-4862",
      USStateCode.CO
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3883,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 1439,
          RBT: 1439,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2245,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
];
