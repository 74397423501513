import { IAvailability, IUserAvailability, UserPermission } from "@finni-health/shared";
import { EditAvailabilityTable } from "@finni-health/ui";
import { Button, Col, Form, message, Modal, Row, Typography } from "antd";
import { useEffect, useState } from "react";

import { areAvailabilityDaysEmpty } from "../../helpers/workingHours";
import * as FirestoreService from "../../services/firestore";
import { useUserClinics } from "../UserClinicsProvider";

const { Text, Title } = Typography;

export const SetWorkingHoursModal = () => {
  const { user, refresh } = useUserClinics();

  // Local states
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [userAvailability, setUserAvailability] = useState<IUserAvailability>(
    {} as IUserAvailability
  );
  const [tempAvailability, setTempAvailability] = useState<IAvailability>({} as IAvailability);

  // Functions
  const getUserAvailability = async () => {
    const userAvailability = await FirestoreService.getUserAvailabilityByUserId({
      userId: user.id,
      clinicId: user.clinicId,
    });
    setUserAvailability(userAvailability);
  };

  const saveAvailability = async () => {
    setIsSaving(true);

    try {
      await FirestoreService.updateUserAvailability({
        id: userAvailability.id,
        userId: user.id,
        availability: tempAvailability,
        clinicId: user.clinicId,
      });
      await message.success(`Availability successfully updated`);
      await refresh();
    } catch (error) {
      console.error(error);
      await message.error(
        "Oops! Something went wrong when saving your profile or availability, please check that all required fields are filled and correct"
      );
    }

    setIsSaving(false);
  };

  const onCloseModal = async () => {
    await saveAvailability();
    setIsOpen(false);
  };

  // useEffects
  useEffect(() => {
    void getUserAvailability();
  }, [user]);

  useEffect(() => {
    if (
      userAvailability.availability &&
      areAvailabilityDaysEmpty(userAvailability.availability) &&
      !hasMounted &&
      user.permissions?.includes(UserPermission.RBT)
    ) {
      setIsOpen(true);
      setHasMounted(true);
    }
  }, [user, userAvailability]);

  return (
    <Modal
      open={isOpen}
      footer={[
        <Button key="1" type="primary" onClick={onCloseModal} loading={isSaving}>
          Save
        </Button>,
      ]}
      width={800}
      style={{ top: "50px" }}
      onCancel={onCloseModal}
      destroyOnClose={true}
    >
      <Form layout="vertical" labelCol={{ span: 24 }}>
        <Row gutter={24}>
          <Col span={24}>
            <Title level={4} style={{ marginTop: 10 }}>
              Please tell us your working availability
            </Title>
            <Text>
              This will help us book your client sessions and understand if you are available for
              substitutions and/or new clients. Please be as accurate as possible, future changes
              will require approval from your BCBA.
            </Text>
            <EditAvailabilityTable
              isEditingDisabled={false}
              onAvailabilityChange={setTempAvailability}
              initialAvailability={userAvailability.availability}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
