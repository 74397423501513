import "../css/peachi.css";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { CountryCode, IInvite, TransportMode, USStateCode } from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Button, Col, message, Row, Typography } from "antd";
import { getAuth, signOut } from "firebase/auth";
import _ from "lodash";
import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { EnterInviteCode } from "../components/SignUpFlow/EnterInviteCode";
import { EnterPersonalAddress } from "../components/SignUpFlow/EnterPersonalAddress";
import { EnterPhoneNumber } from "../components/SignUpFlow/EnterPhoneNumber";
//inquire flow components
import { InviteFound } from "../components/SignUpFlow/InviteFound";
import { SelectPreferredTransport } from "../components/SignUpFlow/SelectPreferredTransportMode";
import { useUserClinics } from "../components/UserClinicsProvider";
import { SignUpStates } from "../consts";
import { isScreenMobile } from "../helpers/is-screen-mobile";
import app from "../services/firebase";
import * as FirestoreService from "../services/firestore";
import { Loading } from "./Loading";

const { Text } = Typography;

export interface IUserSignupForm {
  clinicName: string;
  inviteId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: Moment;
  isFullTime: boolean;
  line1: string;
  line2: string;
  city: string;
  state: USStateCode;
  zipCode: string;
  preferredTransport: TransportMode;
}

export const SignUpFlow: React.FC = () => {
  const { user, clinic } = useUserClinics();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [inquiryStatus, setInquiryStatus] = useState<SignUpStates>(SignUpStates.CHECK_INVITES);

  const [inquiry, setInquiry] = useState<IUserSignupForm>({
    clinicName: "",
    inviteId: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    dateOfBirth: "" as any,
    isFullTime: true,
    line1: "",
    line2: "",
    city: "",
    state: "" as any,
    zipCode: "",
    preferredTransport: TransportMode.DRIVING,
  });

  const [isMobile, setIsMobile] = useState(isScreenMobile());
  const [invites, setInvites] = useState<IInvite[]>([]);

  const [inviteSelectedIndex, setInviteSelectedIndex] = useState<number>(0);

  const fetchData = async () => {
    const invites = await FirestoreService.getInvites();
    setInvites(invites);
    if (!_.isEmpty(invites)) {
      setInquiryStatus(SignUpStates.INVITE_FOUND);
    } else {
      setInquiryStatus(SignUpStates.ENTER_INVITE_CODE);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user.firstName) {
      window.addEventListener("resize", () => {
        setIsMobile(isScreenMobile());
      });
      try {
        updateInquiry({
          firstName: user.firstName,
          lastName: user.lastName,
        });
      } catch (err) {
        void message.error(`Failed to fetch data`);
      }
      fetchData().catch(() => {});
    }
  }, [user]);

  useEffect(() => {
    if (!_.isEmpty(clinic) && inquiryStatus !== SignUpStates.COMPLETE) {
      window.location.replace(window.location.origin);
    }
  }, [clinic]);

  const handleLogoClick = () => {
    //log out user
    signOut(getAuth(app))
      .then(() => {
        console.log("Logged out");
      })
      .catch((error) => {
        console.log(error);
      });
    //navigate back
    navigate("/", { replace: true });
  };

  const createUser = async () => {
    setIsLoading(true);

    try {
      await FirestoreService.updateUser({
        firstName: inquiry.firstName,
        lastName: inquiry.lastName,
        id: user.id,
        hasSignedUp: true,
        address: {
          line1: inquiry.line1 || "",
          line2: inquiry.line2 || "",
          city: inquiry.city || "",
          state: inquiry.state || "",
          zipCode: inquiry.zipCode || "",
          country: CountryCode.US,
        },
        phoneNumber: inquiry.phoneNumber,
        preferredTransport: inquiry.preferredTransport,
      });
      await FirestoreService.deleteInvite({
        id: invites[inviteSelectedIndex].id,
      });
      void message.success(`Welcome to Finni, we're excited to have you here!`);
      window.location.replace(window.location.origin);
    } catch (err) {
      void message.error("Couldn't create user, please try again");
      console.error(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (inquiryStatus === SignUpStates.COMPLETE) {
      createUser().catch(() => {});
    }
  }, [inquiryStatus]);

  const updateInquiry = (source: any) => {
    const merged = _.merge(_.cloneDeep(inquiry), source);
    setInquiry(merged);
  };

  const getSignUpFormComponent = () => {
    switch (inquiryStatus) {
      case SignUpStates.INVITE_FOUND:
        return (
          <InviteFound
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
            invite={invites[inviteSelectedIndex]}
            user={user}
          />
        );

      case SignUpStates.ENTER_INVITE_CODE:
        return (
          <EnterInviteCode
            setInquiryStatus={setInquiryStatus}
            setInviteSelectedIndex={setInviteSelectedIndex}
            invites={invites}
          />
        );

      case SignUpStates.ENTER_PERSONAL_PHONE_NUMBER:
        return (
          <EnterPhoneNumber
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
            invite={invites[inviteSelectedIndex]}
          />
        );

      case SignUpStates.ENTER_PERSONAL_ADDRESS:
        return (
          <EnterPersonalAddress
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
            invite={invites[inviteSelectedIndex]}
          />
        );

      case SignUpStates.ENTER_PREFERRED_TRANSPORT:
        return (
          <SelectPreferredTransport
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
            invite={invites[inviteSelectedIndex]}
          />
        );
    }
  };

  return (
    <Row
      style={{
        backgroundColor: "#fbf7f0",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Col span={24}>
        <Row
          style={{
            width: "100%",
            marginTop: 30,
            paddingBottom: 30,
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
          justify="center"
          align="middle"
        >
          {inquiryStatus > 2 && inquiryStatus <= 4 && (
            <Button
              onClick={() => {
                setInquiryStatus(inquiryStatus - 1);
              }}
              style={{ height: 40, position: "absolute", left: 20 }}
              type="text"
            >
              <ArrowLeftOutlined />
            </Button>
          )}
          <Col>
            <a
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "start",
                width: "100%",
              }}
              onClick={handleLogoClick}
            >
              <Text
                style={{
                  fontSize: 36,
                  color: COLORS.PRIMARY,
                  fontFamily: "peachi",
                }}
              >
                {`Finni `}
              </Text>
            </a>
          </Col>
        </Row>
        <div
          style={{
            background: COLORS.PRIMARY,
            marginBottom: isMobile ? 25 : 100,
            height: 3,
            transition: "all 0.4s",
            width:
              inquiryStatus === SignUpStates.CHECK_INVITES ||
              inquiryStatus === SignUpStates.INVITE_FOUND ||
              SignUpStates.ENTER_INVITE_CODE
                ? "20%"
                : inquiryStatus === SignUpStates.ENTER_PERSONAL_PHONE_NUMBER
                ? "40%"
                : inquiryStatus === SignUpStates.ENTER_PERSONAL_ADDRESS
                ? "60%"
                : inquiryStatus === SignUpStates.ENTER_PREFERRED_TRANSPORT ||
                  inquiryStatus === SignUpStates.COMPLETE
                ? "80%"
                : 0,
          }}
        />
        <Row
          style={{
            width: "100%",
            marginTop: 30,
            marginBottom: 100,
            textAlign: "center",
            paddingLeft: isMobile ? 10 : 0,
            paddingRight: isMobile ? 10 : 0,
          }}
          justify="center"
        >
          {isLoading || SignUpStates.CHECK_INVITES ? (
            <Loading />
          ) : (
            <Col>{getSignUpFormComponent()}</Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
