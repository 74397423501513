import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FrownOutlined,
  MehOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { IUser } from "@finni-health/shared";
import { Col, Divider, Popover, Row, Statistic, Typography } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import CountUp from "react-countup";
import { BsStars } from "react-icons/bs";

const { Text, Paragraph } = Typography;

const STYLES = {
  79: {
    primary: "#E21C3D",
    secondary: "#E21C3D",
    text: "Impact Needs Improvement",
    icon: (
      <FrownOutlined
        style={{
          marginTop: 4,
          color: "#E21C3D",
        }}
        twoToneColor={"E21C3D"}
      />
    ),
  },
  84: {
    primary: "#FDB81E",
    secondary: "#FDB81E",
    text: "Good Impact",
    icon: (
      <MehOutlined
        style={{
          marginTop: 4,
          color: "#FDB81E",
        }}
        twoToneColor={"#FDB81E"}
      />
    ),
  },
  94: {
    primary: "#48A463",
    secondary: "#48A463",
    text: "Great Impact",
    icon: (
      <SmileOutlined
        style={{
          marginTop: 4,
          color: "#48A463",
        }}
        twoToneColor={"#48A463"}
      />
    ),
  },
  100: {
    primary: "#8e78f0",
    secondary: "#111111",
    text: "Exceptional Impact",
    icon: (
      <BsStars
        style={{
          marginTop: 4,
          color: "#8e78f0",
        }}
      />
    ),
  },
};

const displayImpactScore = (score: number) => {
  return (score / 1000000000).toFixed(2);
};

interface IProps {
  user: IUser;
  displayAsStatistic?: boolean;
  disablePopover?: boolean;
  disableTitle?: boolean;
  disableCountUp?: boolean;
  [props: string]: any;
}

export const ImpactCard: React.FC<IProps> = ({ ...params }) => {
  const [cookies, setCookie] = useCookies(["learner-impact"]);

  //input params
  const { user, displayAsStatistic, disablePopover, disableTitle, disableCountUp } = params;

  //counter
  const [currentImpactScore, setCurrentImpactScore] = useState(user?.stats?.impactScore || 0);
  const [currentImpactBasisPoints, setCurrentImpactBasisPoints] = useState(
    user?.stats?.impactBasisPoints || 10000
  );
  const [lastImpactBasisPoints, setLastImpactBasisPoints] = useState(
    user?.stats?.previousImpactBasisPoints || 10000
  );

  //range
  const range = (_.findKey(STYLES, (color, key) => {
    return currentImpactBasisPoints / 100 <= parseInt(key);
  }) || 95) as unknown as keyof typeof STYLES;

  useEffect(() => {
    //set score update interval
    const secTimer = setInterval(() => {
      const timeElapsedSinceCompute = new Date().valueOf() - (user?.stats?.calculatedAtMs || 0);
      const newScore =
        (user?.stats?.impactScore || 0) +
        timeElapsedSinceCompute * (user?.stats?.impactBasisPoints || 10000);
      setCurrentImpactScore(newScore);
    }, 500);

    //get old basis points from cookie
    const cookieScore = cookies["learner-impact"];
    if (cookieScore && cookieScore !== currentImpactBasisPoints) {
      //save new basis points to cookie
      setCookie("learner-impact", currentImpactBasisPoints, {
        path: "/",
        maxAge: 60 * 60 * 24 * 7,
      });
    }

    return () => clearInterval(secTimer);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(user?.stats)) {
      setCurrentImpactBasisPoints(user?.stats?.impactBasisPoints);
      setLastImpactBasisPoints(user?.stats?.previousImpactBasisPoints);
    }
  }, [user]);

  return (
    <Popover
      content={
        !disablePopover && (
          <div style={{ width: 200 }}>
            <Row
              style={{
                justifyContent: "space-between",
              }}
            >
              <Col>
                <Statistic
                  title={"This Week"}
                  value={`${currentImpactBasisPoints / 100}%`}
                  prefix={
                    currentImpactBasisPoints >= lastImpactBasisPoints ? (
                      currentImpactBasisPoints !== lastImpactBasisPoints && <ArrowUpOutlined />
                    ) : (
                      <ArrowDownOutlined />
                    )
                  }
                  valueStyle={{
                    color:
                      currentImpactBasisPoints >= lastImpactBasisPoints ? "#3f8600" : "#cf1322",
                  }}
                />
              </Col>
              <Col>
                <Statistic
                  title={"Last Week"}
                  value={`${lastImpactBasisPoints / 100}%`}
                  valueStyle={{
                    color: "grey",
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Statistic
                title={"Total Impact"}
                value={`${displayImpactScore(currentImpactScore)}`}
              />
            </Row>
            <Row
              style={{
                fontSize: 11,
                color: "rgba(0, 0, 0, 0.45)",
                marginTop: 2,
              }}
            >{`(Total Impact ≈ Learner Impact × Time)`}</Row>
            <Divider />
            <div>
              <strong>{`Learner Impact`}</strong>
              {` is a way for us to reward the
                positive impact that you are having on your learners.`}
              <Paragraph
                type="secondary"
                ellipsis={{
                  rows: 0,
                  expandable: true,
                  symbol: "More info",
                }}
                style={{ color: "black" }}
              >
                <br />
                {`By focusing on increasing your Learner Impact, you can feel
                  confident that you are making a meaningful difference in the
                  lives of the learners you work with. This score is a
                  reflection of the progress they are making and the positive
                  changes that you are helping to facilitate.`}
                <br />
                <br />
                {`It is calculated using a combination of factors including
                  completed sessions, indirect sessions and cancellations that
                  all affect learning outcomes.`}
              </Paragraph>
              {Array.from(Object.entries(STYLES)).map(([key, style], index, array) => (
                <Row
                  key={index}
                  style={{
                    justifyContent: "space-around",
                    paddingLeft: 40,
                    paddingRight: 40,
                  }}
                >
                  <Col>
                    {`${index === 0 ? 0 : parseInt(array[index - 1][0]) + 1} - ${key}`.padEnd(
                      8,
                      String.fromCharCode(10240)
                    )}
                  </Col>
                  <Col>{style.icon}</Col>
                </Row>
              ))}
            </div>
          </div>
        )
      }
    >
      {displayAsStatistic ? (
        <Statistic
          title={disableTitle ? "" : "Learner Impact"}
          value={`${currentImpactBasisPoints / 100}%`}
          prefix={STYLES[range].icon}
          valueStyle={{
            color: STYLES[range].primary,
          }}
          style={{
            transition: "box-shadow 0.3s, border-color 0.3s",
            cursor: "pointer",
          }}
        />
      ) : (
        <div style={{ cursor: "pointer" }}>
          {!disableTitle && <Text style={{ fontSize: 14 }}>{`Learner Impact:`}</Text>}
          <CountUp
            style={{
              marginLeft: 8,
              marginRight: 6,
              color: STYLES[range].primary,
              fontSize: 14,
              transition: "box-shadow 0.3s, border-color 0.3s",
            }}
            decimals={2}
            start={disableCountUp ? currentImpactBasisPoints / 100 : 0}
            end={currentImpactBasisPoints / 100}
            suffix={"%"}
          />
          {STYLES[range].icon}
        </div>
      )}
    </Popover>
  );
};
