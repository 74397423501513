import "../css/ListView.css";

import { DownOutlined, FilterOutlined, UpOutlined } from "@ant-design/icons";
import {
  ActiveStatus,
  AppointmentLocation,
  BillingCode,
  CalendarEventType,
  capitalizeFirstLetter,
  EventType,
  getAppointmentNameFromBilling,
  IAppointment,
  ICalendarEvent,
  ICalendarEventExtended,
  ICalendarEventExtendedByDay,
  IClient,
  IClientDetails,
  ICompletedAppointment,
  IIndirect,
  INote,
  isPayerAuthRequired,
  isPayerAuthValid,
  IUser,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Cascader, Col, Divider, Row, Typography } from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import * as momentTz from "moment-timezone";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { DB_DATE_FORMAT } from "../../consts";
import { getAppointmentLocationText, isCancelledAppointment } from "../../helpers/appointments";
import * as FirestoreService from "../../services/firestore";
import { useUserClinics } from "../UserClinicsProvider";
import { ListViewItem } from "./ListViewItem";

const { Text } = Typography;

const CALENDAR_HEADER_HEIGHT = 210;

export const CalendarEventColors = {
  [CalendarEventType.APPOINTMENT]: "#7F7F7F",
  [CalendarEventType.APPOINTMENT_HIDDEN]: "#FFFFFF",
  [CalendarEventType.COMPLETED]: COLORS.GREEN,
  [CalendarEventType.CANCELLED]: COLORS.RED,
  [CalendarEventType.INDIRECT]: COLORS.LIGHT_ORANGE,
};

const formatMilliseconds = (milliseconds: number) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);

  const hours = totalHours;
  const minutes = totalMinutes % 60;

  let formattedTime = "";

  formattedTime += hours + "h:";

  if (minutes < 10) {
    formattedTime += "0";
  }
  formattedTime += minutes + "m";

  return formattedTime;
};

const applyFilters = (eventExtended, filters) => {
  const { types, locations, services, clients, therapists } = filters || {};

  const isTherapistFiltered =
    eventExtended.type === CalendarEventType.APPOINTMENT &&
    eventExtended.event.attendees.length === 0;

  const isHiddenAppointment = eventExtended.type === CalendarEventType.APPOINTMENT_HIDDEN;

  const isFilteredType =
    types?.length > 0 && !types.map((type) => type.split("_")[0]).includes(eventExtended.type);

  let isFilteredCancellation = false;
  if (eventExtended.type === CalendarEventType.CANCELLED) {
    const isClientCancelled = (
      eventExtended.event as ICompletedAppointment
    ).cancellationReason?.includes("CLIENT");
    const isTherapistCancelled = (
      eventExtended.event as ICompletedAppointment
    ).cancellationReason?.includes("THERAPIST");
    const isOtherCancellation = !isClientCancelled && !isTherapistCancelled;
    const cancellationFilters = types?.filter((type) => type.includes(CalendarEventType.CANCELLED));

    if (cancellationFilters?.length > 0) {
      if (cancellationFilters.includes(CalendarEventType.CANCELLED + "_client")) {
        if (!isClientCancelled) {
          isFilteredCancellation = true;
        }
      }
      if (cancellationFilters.includes(CalendarEventType.CANCELLED + "_therapist")) {
        if (!isTherapistCancelled) {
          isFilteredCancellation = true;
        }
      }
      if (cancellationFilters.includes(CalendarEventType.CANCELLED + "_other")) {
        if (!isOtherCancellation) {
          isFilteredCancellation = true;
        }
      }
    }
  }

  const isFilteredLocation =
    locations?.length > 0 && !locations.includes((eventExtended.event as IAppointment).location);

  const isFilteredService =
    services?.length > 0 && !services.includes((eventExtended.event as IAppointment).billingCode);

  const isFilteredClient =
    clients?.length > 0 && !clients.includes((eventExtended.event as IAppointment).clientId);

  const isFilteredTherapist =
    therapists?.length > 0 &&
    !therapists.some((therapistId) => eventExtended?.users.find((user) => user.id === therapistId));

  return (
    isHiddenAppointment ||
    isFilteredType ||
    isFilteredLocation ||
    isFilteredService ||
    isFilteredClient ||
    isFilteredTherapist ||
    isFilteredCancellation ||
    isTherapistFiltered
  );
};

const getDayDividerText = (
  date: string,
  selectedEventsExtendedByDay: ICalendarEventExtendedByDay | undefined,
  eventsExtended: ICalendarEventExtended[],
  filters: any
) => {
  const filteredSelectedEventsExtendedByDay = selectedEventsExtendedByDay
    ?.get(date)
    ?.filter((eventExtended) => !applyFilters(eventExtended, filters));

  const hasSelected = filteredSelectedEventsExtendedByDay?.length || 0 > 0;

  const filteredEventsExtended = eventsExtended.filter(
    (eventExtended) => !applyFilters(eventExtended, filters)
  );

  return `${
    (hasSelected ? filteredSelectedEventsExtendedByDay?.length : filteredEventsExtended?.length) ||
    0
  } ${
    hasSelected
      ? "selected"
      : "appointment" + ((filteredEventsExtended?.length || 0) !== 1 ? "s" : "")
  } ${formatMilliseconds(
    hasSelected
      ? filteredSelectedEventsExtendedByDay?.reduce(
          (acc, eventExtended) =>
            acc + moment(eventExtended.event.endMs).diff(moment(eventExtended.event.startMs)),
          0 as any
        )
      : filteredEventsExtended.reduce(
          (acc, eventExtended) =>
            acc + moment(eventExtended.event.endMs).diff(moment(eventExtended.event.startMs)),
          0 as any
        )
  )} total`;
};

//prevent re-rendering of list items
let localSelectedEvent: ICalendarEvent;

interface IProps {
  clinicUsers: IUser[];
  clientsDetails: Record<string, IClientDetails>;
  notes: INote[];
  calWeek: Moment;
  searchString: string;
  showIncompleteAppointments: boolean;
  setSelectedEvent: (event: ICalendarEvent) => void;
  setSelectedClient: (client: IClient) => void;
  setIsMatchNotesDrawerOpen: (isOpen: boolean) => void;
  selectedFilters?: any;
  eventsOverride?: ICalendarEvent[];
  refreshAll: () => void;
  extendedView?: boolean;
}

export const ListView = memo(
  function ListView({
    clinicUsers,
    clientsDetails,
    notes,
    calWeek,
    setSelectedEvent,
    setSelectedClient,
    setIsMatchNotesDrawerOpen,
    selectedFilters,
    eventsOverride,
    refreshAll,
    extendedView,
  }: IProps) {
    //contexts
    const { user, clinic } = useUserClinics();

    //calendar events
    const [calendarEventsExtended, setCalendarEventsExtended] = useState<ICalendarEventExtended[]>(
      []
    );
    const [calendarEventsExtendedByDay, setCalendarEventsExtendedByDay] =
      useState<ICalendarEventExtendedByDay>();

    //selected events
    const [selectedEventsExtended, setSelectedExtendedEvents] = useState<ICalendarEventExtended[]>(
      []
    );
    const [selectedEventsExtendedByDay, setSelectedExtendedEventsByDay] =
      useState<ICalendarEventExtendedByDay>();

    //collapsed/uncollapsed days
    const [isDayHidden, setIsDayVisible] = useState<Map<string, boolean>>(new Map());

    //filters
    const [filters, setFilters] = useState<any>(!_.isEmpty(selectedFilters) ? selectedFilters : {});

    //helpers
    const getCalendarEventType = useCallback(
      (event: ICalendarEvent): CalendarEventType => {
        if (event.eventType === EventType.COMPLETED && isCancelledAppointment(event)) {
          return CalendarEventType.CANCELLED;
        } else {
          return event.eventType as any as CalendarEventType;
        }
      },
      [event]
    );

    useEffect(() => {
      handleScroll();
    }, [calendarEventsExtendedByDay]);

    const convertToCalendarExtendedEvent = (events: ICalendarEvent[]): ICalendarEventExtended[] => {
      return events.map((event: ICalendarEvent) => {
        const users: IUser[] = (event as any)?.userIds
          ? (
              (event as ICompletedAppointment).userIds.map((userId) =>
                clinicUsers.find((u: IUser) => u.id === userId)
              )! as IUser[]
            ).filter((user) => user !== undefined)
          : (
              (event as IAppointment | IIndirect).attendees.map((attendee) =>
                clinicUsers.find((u: IUser) => u.email === attendee.email)
              )! as IUser[]
            ).filter((user) => user !== undefined);

        const clientDetails = clientsDetails[(event as any)?.clientId];

        const appointmentNotes: INote[] = notes.filter((note) => note.appointmentId === event.id);

        const type: CalendarEventType = getCalendarEventType(event);

        const calendarEventExtended: ICalendarEventExtended = {
          event,
          users,
          date: moment(event.startMs).format(DB_DATE_FORMAT),
          type,
          ...(clientDetails && { clientDetails }),
          ...(appointmentNotes.length > 0 && { notes: appointmentNotes }),
        };

        return calendarEventExtended;
      });
    };

    const convertToByDay = (events: ICalendarEventExtended[]): ICalendarEventExtendedByDay => {
      return events.reduce((acc, event) => {
        if (acc.has(event.date)) {
          acc.get(event.date)?.push(event);
        } else {
          acc.set(event.date, [event]);
        }
        return acc;
      }, new Map() as Map<string, ICalendarEventExtended[]>);
    };

    const createEventsByDayMapping = (calendarEventsExtended: ICalendarEventExtended[]) => {
      //sort by start time
      calendarEventsExtended.sort((a, b) => a.event.startMs - b.event.startMs);

      //remove appointments if completed appointment present
      calendarEventsExtended = calendarEventsExtended.map((eventExtended) => {
        if (eventExtended.type === CalendarEventType.APPOINTMENT) {
          const appointment = eventExtended.event as IAppointment;

          //find completed appointment
          const completedAppointment = calendarEventsExtended.find(
            (e) =>
              (e.type === CalendarEventType.COMPLETED || e.type === CalendarEventType.CANCELLED) &&
              e.event.id === appointment.id
          )?.event as ICompletedAppointment;

          //if completed appointment exists, remove appointment
          if (completedAppointment) {
            eventExtended.type = CalendarEventType.APPOINTMENT_HIDDEN;
          }
        }
        return eventExtended;
      });

      //remove any duplicates using event id & event type
      const uniqueEvents = _.uniqBy(calendarEventsExtended, (e) => e.event.id + e.type);

      //group by day, ordered map, YYYY-MM-DD
      const uniqueEventsByDay = convertToByDay(uniqueEvents);

      //set state
      setCalendarEventsExtended(uniqueEvents);
      setCalendarEventsExtendedByDay(uniqueEventsByDay);
    };

    //loads new events when week changes, up or down
    const loadAppointmentsForWeek = async (
      week: Moment,
      localCalendarEventsExtended?: ICalendarEventExtended[]
    ) => {
      if (!_.isEmpty(eventsOverride)) {
        //create mapping by day
        createEventsByDayMapping(
          convertToCalendarExtendedEvent(eventsOverride as ICalendarEvent[])
        );
      } else {
        const [newNotes, appointments, indirects, completedAppointments] = await Promise.all([
          FirestoreService.getNotesForRangeAndClinic(
            moment().year(week.year()).week(week.week()).startOf("week").add(-1, "day").valueOf(),
            moment().year(week.year()).week(week.week()).endOf("week").add(1, "day").valueOf(),
            user.clinicId
          ),
          FirestoreService.getAppointmentsForClinicIdAndWeek({
            clinicId: user.clinicId,
            week: week.week(),
            year: week.year(),
            timeZone: momentTz.tz.guess(),
          }),
          FirestoreService.getIndirectsForWeekAndClinicId({
            week: week.week(),
            year: week.year(),
            clinicId: user.clinicId,
            timeZone: momentTz.tz.guess(),
          }),
          FirestoreService.getCompletedAppointmentByClinicAndRange({
            clinicId: user.clinicId,
            startMs: week.startOf("week").valueOf(),
            endMs: week.endOf("week").valueOf(),
          }),
        ]);

        //append new notes
        notes = _.uniqBy([...notes, ...newNotes], (note) => note.id);

        const newExtendedEvents = convertToCalendarExtendedEvent([
          ...completedAppointments,
          ...appointments,
          ...indirects,
        ]);

        //combine old and new events
        let appendedEvents: ICalendarEventExtended[] = [];
        if (!_.isEmpty(localCalendarEventsExtended)) {
          appendedEvents = [...localCalendarEventsExtended!, ...newExtendedEvents];
        } else {
          appendedEvents = [...calendarEventsExtended, ...newExtendedEvents];
        }

        //create mapping by day
        createEventsByDayMapping(appendedEvents);
      }

      handleScroll();
    };

    //first load
    const filterOptions = useMemo(() => {
      if (!_.isEmpty(clinicUsers) && !_.isEmpty(clientsDetails)) {
        //set relative time thresholds
        moment.relativeTimeThreshold("m", 60);

        //set filter options
        return [
          {
            label: "Clients",
            value: "clients",
            children: Object.values(clientsDetails)
              .reduce((acc: IClient[], clientDetails: IClientDetails) => {
                const { client, clientFile } = clientDetails;
                const shouldKeep =
                  calendarEventsExtended.find(
                    (eventExtended) => (eventExtended as any).event?.clientId === client.id
                  ) || (clientFile?.intakeStatus as any) === ActiveStatus.ACTIVE;
                if (shouldKeep) {
                  acc.push(client);
                }

                return acc;
              }, [] as IClient[])
              .map((client) => ({
                value: client.id,
                label:
                  client.alias +
                  " - " +
                  [
                    client.firstName,
                    // client.client.middleName,
                    client.lastName,
                  ].join(" "),
              }))
              .sort((a, b) => a.label.localeCompare(b.label)),
          },
          {
            label: "Therapists",
            value: "therapists",
            children: clinicUsers.map((user) => ({
              value: user.id,
              label: [
                user.firstName,
                // user.client.middleName,
                user.lastName,
              ].join(" "),
            })),
          },
          {
            label: "Types",
            value: "types",
            children: Object.values(CalendarEventType)
              .filter(
                (eventType) =>
                  eventType !== CalendarEventType.APPOINTMENT_HIDDEN &&
                  eventType !== CalendarEventType.CANCELLED
              )
              .map((eventType) => ({
                value: eventType,
                label: (
                  <Text style={{ color: CalendarEventColors[eventType] }}>
                    {capitalizeFirstLetter(eventType.toLowerCase())}
                  </Text>
                ),
              }))
              .concat({
                value: CalendarEventType.CANCELLED,
                label: (
                  <Text
                    style={{
                      color: CalendarEventColors[CalendarEventType.CANCELLED],
                    }}
                  >
                    {capitalizeFirstLetter(CalendarEventType.CANCELLED.toLowerCase())}
                  </Text>
                ),
                children: [
                  {
                    value: (CalendarEventType.CANCELLED + "_client") as any,
                    label: <Text style={{ color: CalendarEventColors.CANCELLED }}>Client</Text>,
                  },
                  {
                    value: (CalendarEventType.CANCELLED + "_therapist") as any,
                    label: <Text style={{ color: CalendarEventColors.CANCELLED }}>Therapist</Text>,
                  },
                  {
                    value: (CalendarEventType.CANCELLED + "_other") as any,
                    label: <Text style={{ color: CalendarEventColors.CANCELLED }}>Other</Text>,
                  },
                ],
              } as any),
          },
          {
            label: "Locations",
            value: "locations",
            children: Object.values(AppointmentLocation).map((location) => ({
              value: location,
              label: getAppointmentLocationText(location),
            })),
          },
          {
            label: "Services",
            value: "services",
            children: Object.values(BillingCode).map((billingCode) => ({
              value: billingCode,
              label:
                billingCode +
                (getAppointmentNameFromBilling(clinic.address.state, billingCode, [])
                  ? " - " + getAppointmentNameFromBilling(clinic.address.state, billingCode, [])
                  : ""),
            })),
          },
        ];
      }
    }, [clientsDetails, clinicUsers]);

    //load new events when week changes
    useEffect(() => {
      loadAppointmentsForWeek(calWeek)
        .then(() => {
          handleScroll();
        })
        .catch(() => {});
    }, [calWeek]);

    //on clinic jump, empty states
    useEffect(() => {
      setCalendarEventsExtendedByDay(new Map());
      setSelectedExtendedEventsByDay(new Map());
      setSelectedExtendedEvents([]);
      setCalendarEventsExtended([]);

      handleScroll();
    }, [clinic]);

    //on succesful empty, load week for new clinic
    useEffect(() => {
      if (_.isEmpty(calendarEventsExtended)) {
        loadAppointmentsForWeek(calWeek).catch(() => {});
      }
    }, [calendarEventsExtended]);

    const handleRefresh = async (event?: ICalendarEvent) => {
      //get week from the last local selected event :fingerscrossed:
      const week = moment(localSelectedEvent?.startMs);

      //fetch this week
      if (week) {
        if (event) {
          const eventToRemove = calendarEventsExtended.find(
            (e) => e.event.id === event?.id && e.type === CalendarEventType.COMPLETED
          );
          //remove event from events extended
          const localCalendarEventsExtended = calendarEventsExtended.filter(
            (e) => !_.isEqual(e.event, eventToRemove?.event)
          );
          await loadAppointmentsForWeek(week, localCalendarEventsExtended);
        } else {
          await loadAppointmentsForWeek(week);
        }
      }

      refreshAll();
    };

    const handleFilters = (values: any) => {
      //converts cascader values to object
      const filterObject = filterOptions
        ?.reduce((acc, option) => {
          acc.push(option.value);
          return acc;
        }, [] as any[])
        .reduce((acc, option) => {
          acc[option] = values
            .filter((v: any) => v.includes(option))
            .map((v: any) => v[v.length - 1])
            .filter((v: any) => v);
          return acc;
        }, {} as any);

      setFilters(filterObject);
    };

    const handleSelectedEvent = useCallback((event: ICalendarEvent) => {
      localSelectedEvent = event;
      setTimeout(() => {
        setSelectedEvent(event);
      }, 100);
    }, []);

    const handleSelectedClient = useCallback((client: IClient) => {
      setTimeout(() => {
        setSelectedClient(client);
      }, 100);
    }, []);

    const handleMatchNotesDrawerOpen = (isOpen: boolean) => {
      setIsMatchNotesDrawerOpen(isOpen);
    };

    const handleScroll = () => {
      //set latest date at top
      document.getElementById("mainDate")!.parentElement!.parentElement!.style.display = "flex";
      document.getElementById("mainDate")!.parentElement!.style.display = "flex";
      document.getElementById("mainDate")!.style.display = "flex";

      //get latest date
      const latestDate = Array.from(document.getElementsByClassName("subdivider"))!.reduce(
        (acc, el) => {
          if (
            acc === null ||
            (el.getBoundingClientRect().top < CALENDAR_HEADER_HEIGHT &&
              el.getBoundingClientRect().top > acc.getBoundingClientRect().top)
          ) {
            acc = el;
          }
          return acc;
        },
        null as Element | null
      );

      //set latest date text to main date
      document.getElementById("mainDate")!.innerHTML = String(latestDate?.innerHTML || "");
    };

    const handlePreviousDay = () => {
      //scroll to previous day (- 1)
      const subdividers = Array.from(document.getElementsByClassName("subdivider"));

      let latestDate = subdividers.reduce((acc, el) => {
        if (
          acc === null ||
          (el.getBoundingClientRect().top < CALENDAR_HEADER_HEIGHT &&
            el.getBoundingClientRect().top > acc.getBoundingClientRect().top)
        ) {
          acc = el;
        }
        return acc;
      }, null as Element | null);

      latestDate = subdividers[subdividers.indexOf(latestDate!) - 1 || 0];

      if (latestDate) {
        latestDate?.closest(".ant-row")?.nextElementSibling?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };

    const handleNextDay = () => {
      //scroll to next day (+ 1)
      const subdividers = Array.from(document.getElementsByClassName("subdivider"));

      let latestDate = subdividers.reduce((acc, el) => {
        if (
          acc === null ||
          (el.getBoundingClientRect().top < CALENDAR_HEADER_HEIGHT &&
            el.getBoundingClientRect().top > acc.getBoundingClientRect().top)
        ) {
          acc = el;
        }
        return acc;
      }, null as Element | null);

      latestDate = subdividers[subdividers.indexOf(latestDate!) + 1];

      if (latestDate) {
        latestDate?.closest(".ant-row")?.nextElementSibling?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };

    const handleCheckboxChange = (e: any, eventExtended: ICalendarEventExtended) => {
      if (e.target.checked) {
        const tempEventsExtended = [...selectedEventsExtended, eventExtended];
        setSelectedExtendedEvents(tempEventsExtended);
        setSelectedExtendedEventsByDay(convertToByDay(tempEventsExtended));
      } else {
        const tempEventsExtended = selectedEventsExtended.filter((item) => item !== eventExtended);
        setSelectedExtendedEvents(tempEventsExtended);
        setSelectedExtendedEventsByDay(convertToByDay(tempEventsExtended));
      }
    };

    useEffect(() => {
      handleScroll();
    }, [selectedEventsExtended, filters]);

    return (
      <div
        className="list-view"
        style={{ width: "100%", height: `calc(100% - ${CALENDAR_HEADER_HEIGHT + 40}px)` }}
      >
        <Divider
          orientation="left"
          style={{
            display: "none",
            marginLeft: -16,
            width: "100%",
          }}
        >
          <>
            <Col>
              <UpOutlined
                onClick={handlePreviousDay}
                style={{ marginRight: 2, padding: 5, borderRadius: 10 }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#fafafa";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "white";
                }}
              />
              <DownOutlined
                onClick={handleNextDay}
                style={{ padding: 5, borderRadius: 10 }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#fafafa";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "white";
                }}
              />
            </Col>
            <div style={{ marginLeft: 20 }} id="mainDate"></div>

            <div style={{ marginLeft: 26, position: "relative" }}>
              <FilterOutlined
                style={{
                  position: "absolute",
                  left: 10,
                  top: 8,
                  zIndex: 1,
                  color: "#7F7F7F",
                }}
              />
              <Cascader
                style={{
                  minWidth: 200,
                  boxSizing: "border-box",
                }}
                className="filter-cascader"
                options={filterOptions}
                onChange={handleFilters}
                multiple
                maxTagCount="responsive"
                placeholder="Filters"
              />
            </div>
          </>
        </Divider>

        <Col
          style={{
            width: "100%",
            height: `calc(100vh - ${CALENDAR_HEADER_HEIGHT + 10}px)`,
            overflow: "scroll",
          }}
          onScroll={handleScroll}
        >
          {!_.isEmpty(calendarEventsExtendedByDay) &&
            Array.from(calendarEventsExtendedByDay!.entries()).map(([date, eventsExtended]) => {
              return (
                <>
                  {
                    <Row>
                      <Col>
                        <DownOutlined
                          style={{
                            color: "#afafaf",
                            position: "relative",
                            top: !isDayHidden.get(date) ? 18 : 20,
                            rotate: !isDayHidden.get(date) ? "180deg" : "0deg",
                            marginRight: 5,
                            transition: "all 0.3s ease",
                          }}
                          onClick={() => {
                            setIsDayVisible(new Map(isDayHidden.set(date, !isDayHidden.get(date))));
                          }}
                        />
                      </Col>
                      <Col>
                        <Divider className={"subdivider"} orientation="left">
                          <Col>
                            <Row>{moment(date).format("dddd, MMM Do")}</Row>
                            <Row style={{ color: "grey", fontSize: 12 }}>
                              {getDayDividerText(
                                date,
                                selectedEventsExtendedByDay,
                                eventsExtended,
                                filters
                              )}
                            </Row>
                          </Col>
                        </Divider>
                      </Col>
                    </Row>
                  }
                  {eventsExtended.map((eventExtended: ICalendarEventExtended, index) => {
                    const { event, clientDetails, notes, type, date, users } = eventExtended;

                    //check if isDayHidden
                    if (isDayHidden.get(date)) return null;

                    //filters
                    if (applyFilters(eventExtended, filters)) return null;

                    //appointment card props
                    let isAuthorized;
                    if (type === CalendarEventType.APPOINTMENT) {
                      const requiresAuth = isPayerAuthRequired(
                        clinic.address.state,
                        clientDetails?.clientFile?.payers.primary?.payerId || "",
                        (event as IAppointment).billingCode,
                        (event as IAppointment).modifiers
                      );

                      isAuthorized =
                        !requiresAuth ||
                        isPayerAuthValid(
                          clientDetails?.clientFile?.payers.primary ?? null,
                          (event as IAppointment).startMs
                        );
                    }

                    return (
                      <ListViewItem
                        key={index}
                        type={type}
                        clinicUsers={clinicUsers}
                        clientDetails={clientDetails}
                        event={event}
                        eventExtended={eventExtended}
                        handleSelectedEvent={handleSelectedEvent}
                        handleRefresh={handleRefresh}
                        eventsExtended={eventsExtended}
                        handleMatchNotesDrawerOpen={handleMatchNotesDrawerOpen}
                        handleSelectedClient={handleSelectedClient}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedEventsExtended={selectedEventsExtended}
                        notes={notes}
                        isAuthorized={isAuthorized}
                        clinic={clinic}
                        users={users}
                        extendedView={extendedView}
                      />
                    );
                  })}
                </>
              );
            })}
        </Col>
      </div>
    );
  },
  (prevProps, nextProps) => {
    //minimize re-renders
    return (
      _.isEqual(prevProps.clinicUsers, nextProps.clinicUsers) &&
      _.isEqual(prevProps.clientsDetails, nextProps.clientsDetails) &&
      _.isEqual(prevProps.notes, nextProps.notes) &&
      prevProps.calWeek.week() === nextProps.calWeek.week() &&
      prevProps.searchString === nextProps.searchString &&
      prevProps.refreshAll === nextProps.refreshAll
    );
  }
);
