import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  AttendeeStatus,
  BillingCode,
  getAppointmentNameFromBilling,
  getInitials,
  IAppointment,
  IClient,
  INote,
  IUser,
  Modifier,
} from "@finni-health/shared";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  List,
  message,
  Popconfirm,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { RiExternalLinkLine } from "react-icons/ri";
import { Link } from "react-router-dom";

import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, MOTIVITY_FORMS_URL } from "../../consts";
import { getAppointmentLocationText } from "../../helpers/appointments";
import { getBillingCodeColor } from "../../helpers/colors";
import * as FirestoreService from "../../services/firestore";
import { useUserClinics } from "../UserClinicsProvider";

const { Text, Title, Paragraph } = Typography;

const FILTER_OPTIONS = [
  { label: "Date", value: "date" },
  { label: "Client", value: "client" },
  { label: "Provider", value: "provider" },
  { label: "Type", value: "type" },
  { label: "Location", value: "location" },
];

interface IProps {
  appointment?: IAppointment;
  client?: IClient;
  unmatchedNotes: INote[];
  isOpen: boolean;
  hideDrawer: () => void;
  refreshCallback: () => Promise<void>;
  selectedFilters: CheckboxValueType[];
  setSelectedFilters: (filters: CheckboxValueType[]) => void;
  setMatchingAppointment: (setMatchingAppointment: IAppointment) => void;
  setSelectedEvent: (event: IAppointment) => void;
  setSelectedClient: (client: IClient) => void;
}

export const MatchNotesDrawer = ({
  appointment,
  client,
  unmatchedNotes,
  isOpen,
  hideDrawer,
  refreshCallback,
  selectedFilters,
  setSelectedFilters,
  setMatchingAppointment,
  setSelectedEvent,
  setSelectedClient,
}: IProps) => {
  const { clinic, user } = useUserClinics();

  const [selectedNotes, setSelectedNotes] = useState<INote[]>([]);
  const [filteredUnmatchedNotes, setFilteredUnmatchedNotes] = useState<INote[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>();
  const [users, setUsers] = useState<IUser[]>([]);

  const loadUsers = async () => {
    setUsers(await FirestoreService.getAllUsersForClinic(user.clinicId));
  };

  const handleClose = () => {
    setSelectedNotes([]);
    setSelectedEvent({} as IAppointment);
    setSelectedClient({} as IClient);
    hideDrawer();
  };

  const handleSelectNote = (note: INote) => {
    const newSelectedNotes = _.cloneDeep(selectedNotes);
    if (!newSelectedNotes.find((selectedNote) => selectedNote.id === note.id)) {
      newSelectedNotes.push(note);
    } else {
      newSelectedNotes.splice(
        newSelectedNotes.findIndex((selectedNote) => selectedNote.id === note.id),
        1
      );
    }
    setSelectedNotes(newSelectedNotes);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setMatchingAppointment(appointment!);
    const results = await Promise.all(
      selectedNotes.map(async (note) => {
        if (
          !_.isEmpty(appointment) &&
          Math.abs(note.startMs - (appointment?.startMs || 0)) < 43200000
        ) {
          await FirestoreService.updateNote({
            id: note.id,
            appointmentId: appointment.id,
            manualOverride: true,
          });
          //update the appointment with the note id
          await FirestoreService.updateAppointment({
            id: appointment.id,
            noteId: note.id,
          });
          return true;
        } else {
          return false;
        }
      })
    );
    if (results.some((result) => result === false)) {
      void message.error("Failed to link to appointment as dates do not match");
    } else {
      void message.success("Linked to appointment successfully");
    }
    await refreshCallback();
    handleClose();
    setIsSaving(false);
    setMatchingAppointment({} as IAppointment);
  };

  const handleDeleteNote = async (note: INote) => {
    setIsSaving(true);
    await FirestoreService.deleteNoteById(note.id);
    await refreshCallback();
    setIsSaving(false);
  };

  useEffect(() => {
    if (!_.isEmpty(unmatchedNotes)) {
      if (!_.isEmpty(selectedFilters)) {
        filterNotes(selectedFilters);
      } else {
        setFilteredUnmatchedNotes(unmatchedNotes);
      }
      loadUsers().catch(() => {});
    }
    if (appointment && client) {
      setSelectedEvent(appointment);
      setSelectedClient(client);
    }
  }, [appointment, client, unmatchedNotes]);

  const onCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedFilters(checkedValues);
    if (appointment && client) {
      setSelectedEvent(appointment);
      setSelectedClient(client);
    }
  };

  useEffect(() => {
    filterNotes(selectedFilters);
  }, [selectedFilters]);

  const filterNotes = (checkedValues: CheckboxValueType[]) => {
    if (appointment) {
      const newFilteredUnmatchedNotes = unmatchedNotes.filter((note) => {
        let shouldKeep = true;
        if (checkedValues.includes("client")) {
          shouldKeep = shouldKeep && note.clientId === client?.id;
        }
        if (checkedValues.includes("provider") && client && appointment) {
          const userEmail = users.find((user) => user.id === note.userId)?.email;
          if (userEmail) {
            shouldKeep =
              shouldKeep && appointment.attendees.some((attendee) => attendee.email == userEmail);
          }
        }
        if (checkedValues.includes("type")) {
          shouldKeep =
            shouldKeep &&
            getAppointmentNameFromBilling(
              clinic.address.state,
              note.billingCode as BillingCode,
              note.modifiers as Modifier[]
            ) ==
              getAppointmentNameFromBilling(
                clinic.address.state,
                appointment?.billingCode,
                appointment?.modifiers
              );
        }
        if (checkedValues.includes("location")) {
          shouldKeep = shouldKeep && note.location === appointment?.location;
        }
        return shouldKeep;
      });
      if (checkedValues.includes("date"))
        newFilteredUnmatchedNotes.sort(
          (a, b) =>
            Math.abs(appointment?.startMs - a.startMs) - Math.abs(appointment?.startMs - b.startMs)
        );
      setFilteredUnmatchedNotes(newFilteredUnmatchedNotes);
    }
  };

  return user?.clinicId ? (
    <Drawer
      closable={false}
      zIndex={9999}
      onClose={handleClose}
      open={isOpen}
      width={500}
      footer={
        !_.isEmpty(appointment) && (
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type={"primary"}
              disabled={_.isEmpty(selectedNotes) || _.isEmpty(appointment)}
              onClick={handleSave}
              loading={isSaving}
            >
              Save
            </Button>
          </Space>
        )
      }
    >
      {appointment && client && !_.isEmpty(appointment) && !_.isEmpty(client) && (
        <Card
          style={{ marginBottom: 30 }}
          bodyStyle={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <Row>
            <Col span={14}>
              <Row justify="space-between" style={{ width: "100%", height: 24 }}>
                <Text strong>{client?.alias}</Text>
              </Row>
              <Text>{`${moment(appointment?.startMs).format(DISPLAY_DATE_FORMAT)} `}</Text>
              <Text>{`${moment(appointment?.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
                appointment?.endMs
              ).format(DISPLAY_TIME_FORMAT)}`}</Text>
              <br />
              <Row>
                <Tag
                  color={getBillingCodeColor(appointment?.billingCode, appointment?.modifiers)}
                  style={{ marginTop: 2, marginBottom: 2 }}
                >
                  {getAppointmentNameFromBilling(
                    clinic.address.state,
                    appointment?.billingCode,
                    appointment?.modifiers
                  )}
                </Tag>
                <Text style={{ marginTop: 2, marginBottom: 2 }}>
                  {getAppointmentLocationText(appointment?.location)}
                </Text>
              </Row>
            </Col>
            <Col span={10}>
              <Text strong>Guests</Text>
              <Row>
                {appointment?.attendees.slice(0, 2).map((attendee) => (
                  <Tag key={attendee.email} style={{ marginBottom: 2, fontSize: 11 }}>
                    <Badge
                      status={
                        attendee.status === AttendeeStatus.ACCEPTED
                          ? "success"
                          : attendee.status === AttendeeStatus.DECLINED
                          ? "error"
                          : attendee.status === AttendeeStatus.TENTATIVE
                          ? "warning"
                          : "default"
                      }
                      style={{ marginRight: 3 }}
                    />
                    {attendee.email}
                  </Tag>
                ))}
                <br />
                {appointment?.attendees.slice(2).length > 0 && (
                  <Text style={{ fontSize: 12 }}>
                    {`And ${appointment?.attendees.slice(2).length} other guest${
                      appointment?.attendees.slice(2).length > 1 ? "s" : ""
                    }`}
                  </Text>
                )}
              </Row>
            </Col>
          </Row>
        </Card>
      )}
      <Row align="middle" style={{ marginBottom: 5, marginTop: 20 }}>
        <Tooltip
          zIndex={9999}
          placement="right"
          title={
            <>
              <Text style={{ color: "white" }}>
                Attach an un-linked Motivity note to this session. If notes for this session do not
                exist, contact the therapist to verify that notes were completed.
              </Text>
              <br />
              <br />
              <Text style={{ color: "white" }}>
                If all else fails, contact #product on Slack for help.
              </Text>
            </>
          }
        >
          <Title level={5} style={{ marginBottom: 0, display: "flex" }}>
            {`${!_.isEmpty(appointment) ? "Select Notes" : "Unmatched Notes"}`}
            <InfoCircleOutlined style={{ marginLeft: 5, marginTop: 4 }} />
            <Text style={{ marginLeft: 12, fontSize: 15, fontWeight: "lighter" }}>
              (Total: {filteredUnmatchedNotes.length})
            </Text>
          </Title>
        </Tooltip>
      </Row>
      {!_.isEmpty(appointment) && (
        <Row>
          <Text>Filter:</Text>
          <div>
            <Checkbox.Group
              options={FILTER_OPTIONS}
              value={selectedFilters}
              defaultValue={[]}
              onChange={onCheckboxChange}
              style={{
                marginLeft: 8,
                display: "flex",
                flexDirection: "row",
                flexBasis: 0,
                flexGrow: 1,
                justifyContent: "flex-end",
                width: "100%",
              }}
            />
          </div>
        </Row>
      )}
      <Row
        style={{
          width: "100%",
          height: "calc(100% - 200px)",
          overflowY: "auto",
        }}
      >
        <List
          style={{ width: "100%" }}
          itemLayout="horizontal"
          dataSource={filteredUnmatchedNotes}
          renderItem={(note: INote) => (
            <List.Item>
              <Card style={{ width: "100%" }}>
                <List.Item.Meta
                  avatar={
                    <Checkbox
                      onClick={() => handleSelectNote(note)}
                      checked={!!selectedNotes.find((selectedNote) => selectedNote.id === note.id)}
                    />
                  }
                  title={
                    <div style={{ display: "flex" }}>
                      {getInitials(
                        note.clientFullName.split(" ")[0] || "",
                        note.clientFullName.split(" ")[1] || ""
                      )}{" "}
                      <Tag style={{ marginBottom: 2, marginLeft: 5, fontSize: 10 }}>
                        {note.providerFullName}
                      </Tag>
                      <Tooltip title="Delete note" zIndex={9999} placement="left">
                        <Popconfirm
                          title={`Are you sure you want to delete this note?`}
                          onConfirm={() => handleDeleteNote(note)}
                          okText="Yes"
                          cancelText="No"
                          placement="left"
                          zIndex={9999}
                        >
                          <Button
                            type="text"
                            size="small"
                            style={{
                              marginLeft: "auto",
                              marginRight: 10,
                              marginTop: -2,
                              fontSize: 11,
                            }}
                          >
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                      <Tooltip title="Open this note in Motivity" zIndex={9999} placement="left">
                        <Link to={`${MOTIVITY_FORMS_URL + note.id}`} target="_blank">
                          <RiExternalLinkLine />
                        </Link>
                      </Tooltip>
                    </div>
                  }
                  description={
                    <>
                      <Row>
                        <Text>
                          {`${moment(note.startMs).format(DISPLAY_DATE_FORMAT)} ${moment(
                            note.startMs
                          ).format(DISPLAY_TIME_FORMAT)} — ${moment(note.endMs).format(
                            DISPLAY_TIME_FORMAT
                          )}`}
                        </Text>
                      </Row>
                      <Row style={{ marginBottom: 3 }}>
                        <Tag
                          color={getBillingCodeColor(
                            note.billingCode as BillingCode,
                            note.modifiers as Modifier[]
                          )}
                          style={{ marginTop: 2, marginBottom: 2 }}
                        >
                          {getAppointmentNameFromBilling(
                            clinic.address.state,
                            note.billingCode as BillingCode,
                            note.modifiers as Modifier[]
                          )}
                        </Tag>
                        <Text
                          style={{ marginTop: 2, marginBottom: 2 }}
                        >{`${getAppointmentLocationText(note.location)}`}</Text>
                      </Row>
                      <Paragraph
                        type="secondary"
                        ellipsis={{
                          rows: 2,
                          expandable: true,
                          symbol: "more",
                        }}
                      >
                        {note.narrative}
                      </Paragraph>
                    </>
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      </Row>
    </Drawer>
  ) : null;
};
