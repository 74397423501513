import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  ActiveStatus,
  AppointmentLocation,
  AttendeeStatus,
  getAppointmentNameFromBilling,
  getAppointmentsByPayer,
  IAppointment,
  ICalendarUpdateAppointmentEndpointRequest,
  IClientGuardianDetails,
  IUser,
  UserPermission,
  Weekday,
} from "@finni-health/shared";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";

import {
  AM_HOURS,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  ERROR_MESSAGE_APPOINTMENTS,
} from "../../consts";
import { getAppointmentLocationText, IAppointmentOptions } from "../../helpers/appointments";
import { ILocalAppointment } from "../../pages/SchedulePlayground";
import * as FirestoreService from "../../services/firestore";
import { StatusTagText } from "../Intake/IntakeStatusTag";
import { useUserClinics } from "../UserClinicsProvider";

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  appointment: IAppointment;
  users: IUser[];
  hideModal: () => void;
  recurring?: boolean;
  onSaveAppointment: (
    appt: IAppointment,
    request: ICalendarUpdateAppointmentEndpointRequest
  ) => void;
  clientGuardianDetails: IClientGuardianDetails[];
  onDeleteAppointment: (appt: IAppointment) => void;
}

export interface IEditAppointmentFormValues {
  clientId: string;
  apptName: string;
  date: Moment;
  start: Moment;
  end: Moment;
  location: AppointmentLocation;
  attendeeEmails: string[];
  description: string;
  recurring: boolean;
}

export const EditAppointmentPopoverContent = ({
  appointment,
  users,
  hideModal,
  recurring,
  onSaveAppointment,
  clientGuardianDetails,
  onDeleteAppointment,
}: Props) => {
  const { clinic, user } = useUserClinics();

  const [form] = Form.useForm<IEditAppointmentFormValues>();
  const clientId = Form.useWatch("clientId", form);
  const apptName = Form.useWatch("apptName", form);
  const start = Form.useWatch("start", form);
  const end = Form.useWatch("end", form);
  const date = Form.useWatch("date", form);
  const location = Form.useWatch("location", form);
  const createMeet = Form.useWatch<boolean | undefined>("createMeet", form);

  const [selectedClientGuardianDetails, setSelectedClientGuardianDetails] =
    useState<IClientGuardianDetails>({} as IClientGuardianDetails);
  const [availableAppointments, setAvailableAppointments] = useState<IAppointmentOptions>(
    {} as IAppointmentOptions
  );
  const [isLoadingAvailableAppointments, setIsLoadingAvailableAppointments] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const isAppointmentDirty = () => {
    return (
      form.isFieldsTouched() ||
      start?.format(DISPLAY_TIME_FORMAT) !==
        moment(appointment.startMs).format(DISPLAY_TIME_FORMAT) ||
      end?.format(DISPLAY_TIME_FORMAT) !== moment(appointment.endMs).format(DISPLAY_TIME_FORMAT) ||
      date?.format(DISPLAY_DATE_FORMAT) !== moment(appointment.endMs).format(DISPLAY_DATE_FORMAT)
    );
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      setApptNameFromAppointment();
    }
  }, [user, appointment]);

  useEffect(() => {
    const cgd = clientGuardianDetails.find((cwcf) => cwcf.client.id === clientId);

    if (cgd) {
      selectClientGuardianDetails(cgd);
    }
  }, [clientId, clientGuardianDetails]);

  useEffect(() => {
    if (location === AppointmentLocation.TELEHEALTH) {
      form.setFieldValue(
        "createMeet",
        !!appointment.meetsLink || appointment.location !== AppointmentLocation.TELEHEALTH
      );
    }
  }, [location]);

  const setApptNameFromAppointment = () => {
    if (_.isEmpty(appointment)) {
      return;
    }

    form.setFieldValue(
      "apptName",
      getAppointmentNameFromBilling(
        clinic.address.state,
        appointment.billingCode,
        appointment.modifiers
      )
    );
  };

  const selectClientGuardianDetails = (cgd: IClientGuardianDetails) => {
    setIsLoadingAvailableAppointments(true);

    try {
      const availableAppointments = getAppointmentsByPayer(
        clinic.address.state,
        cgd.clientFile.payers.primary?.payerId || ""
      );

      setApptNameFromAppointment();
      setAvailableAppointments(availableAppointments);
    } catch (err) {
      console.error(`Could not find payer with ID ${cgd.clientFile.payers.primary?.payerId}`);
      form.setFieldValue("apptName", undefined);
      setAvailableAppointments({} as IAppointmentOptions);
    }

    setSelectedClientGuardianDetails(cgd);
    setIsLoadingAvailableAppointments(false);
  };

  const getDisabledEndTimeHours = () => {
    let hours: number[] = [];
    if (start.hour() >= 12) {
      hours = [...AM_HOURS];
    }
    for (let i = hours.length; i < start.hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledEndTimeMinutes = (selectedHour: number) => {
    const minutes: number[] = [];
    if (selectedHour === start.hour()) {
      for (let i = 0; i <= start.minute(); i += 15) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const handleUpdateAppointment = async (appointmentId: string) => {
    setIsSaving(true);
    try {
      const values = form.getFieldsValue();

      let date;
      if (appointment.id.includes("_") && !appointmentId.includes("_")) {
        const originalAppt = await FirestoreService.getAppointmentById({
          id: appointmentId,
        });
        date = {
          year: moment(originalAppt.startMs).year(),
          month: moment(originalAppt.startMs).month(),
          date: moment(originalAppt.startMs).date(),
        };
      } else {
        date = {
          year: values.date.year(),
          month: values.date.month(),
          date: values.date.date(),
        };
      }

      const updateAppointmentRequest: ICalendarUpdateAppointmentEndpointRequest = {
        id: appointmentId,
        clinicId: user.clinicId,
        clientId: values.clientId,
        attendees: values.attendeeEmails
          .map((email) => email.trim())
          .filter((email) => email)
          .map((email) => ({
            email,
            status:
              appointment.attendees.find((attendee) => attendee.email === email)?.status ||
              AttendeeStatus.NEEDS_ACTION,
          })),
        billingCode: availableAppointments[values.apptName].billingCode,
        modifiers: availableAppointments[values.apptName].modifiers,
        location: values.location,
        summary: getAppointmentSummary(),
        description: values.description,
        startMs: values.start.set(date).valueOf(),
        endMs: values.end.set(date).valueOf(),
        meetsLink: appointment.meetsLink,
        createMeet,
      };

      onSaveAppointment(appointment, {
        ...updateAppointmentRequest,
        rrule: values.recurring
          ? `RRULE:FREQ=WEEKLY;BYDAY=${Object.values(Weekday)
              [moment(updateAppointmentRequest.startMs).day()].slice(0, 2)
              .toUpperCase()};`
          : undefined,
      });
      form.resetFields();
      hideModal();
    } catch (err) {
      void message.error(ERROR_MESSAGE_APPOINTMENTS);
      console.error(err);
    }
    setIsSaving(false);
  };

  const getAppointmentSummary = () => {
    return `${
      !_.isEmpty(selectedClientGuardianDetails)
        ? `${selectedClientGuardianDetails.client.alias} `
        : ""
    }${!_.isEmpty(apptName) ? apptName : "appointment"} ${
      location === AppointmentLocation.TELEHEALTH
        ? "(remote)"
        : location === AppointmentLocation.OFFICE
        ? "(office)"
        : "(in-person)"
    }`;
  };

  return (
    <>
      <Row justify="end">
        <Col span={4}>
          <Button
            key="submit"
            type="text"
            onClick={() => {
              hideModal();
            }}
            disabled={isSaving}
            style={{
              float: "right",
              marginTop: -10,
              marginRight: -10,
              background: "white",
              padding: 0,
            }}
          >
            <CloseOutlined />
          </Button>
        </Col>
      </Row>
      <Row justify="start">
        <Col
          style={{
            marginTop: -10,
            marginBottom: 10,
            float: "left",
          }}
        >
          <Text
            strong
            style={{
              fontSize: "12pt",
            }}
          >{`Edit ${getAppointmentSummary()}`}</Text>
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        labelWrap={false}
        labelCol={{ span: 24 }}
        onFinish={() => handleUpdateAppointment(appointment.id)}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="clientId"
              initialValue={appointment.clientId}
              rules={[{ required: true, message: "Please select a client" }]}
            >
              <Select placeholder="Client" showSearch allowClear optionFilterProp="key">
                {clientGuardianDetails
                  .sort((a, b) =>
                    (Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                      Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus)) ||
                    (!Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                      !Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus))
                      ? a.client.firstName.localeCompare(b.client.firstName)
                      : Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                        !Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus)
                      ? -1
                      : 1
                  )
                  .map((cwcf) => (
                    <Select.Option
                      key={`${StatusTagText[cwcf.clientFile.intakeStatus]} ${cwcf.client.alias}${
                        cwcf.client.firstName
                      }${cwcf.client.lastName}`}
                      value={cwcf.client.id}
                    >
                      <Row>
                        <Text type="secondary" style={{ marginRight: 5 }}>
                          {cwcf.client.alias}
                        </Text>
                        <Text strong>{`${cwcf.client.firstName} ${cwcf.client.lastName}`}</Text>
                      </Row>
                      <Row style={{ marginTop: -7 }}>
                        <Text type="secondary" style={{ fontSize: 9 }}>
                          {StatusTagText[cwcf.clientFile.intakeStatus]}
                        </Text>
                      </Row>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="attendeeEmails"
              initialValue={appointment.attendees.map((attendee) => attendee.email)}
              rules={[{ required: true, message: "Please add guests" }]}
            >
              <Select placeholder="Guests" mode="tags" optionFilterProp="key" allowClear>
                {users
                  .sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map((user) => (
                    <Select.Option
                      key={`${
                        user.permissions.includes(UserPermission.BCBA)
                          ? UserPermission.BCBA
                          : UserPermission.RBT
                      } ${user.firstName}${user.lastName}${user.email}`}
                      value={user.email}
                    >
                      <Row>
                        <Text type="secondary" style={{ marginRight: 5 }}>
                          {user.permissions.includes(UserPermission.BCBA)
                            ? UserPermission.BCBA
                            : UserPermission.RBT}
                        </Text>
                        <Text strong>{`${user.firstName} ${user.lastName}`}</Text>
                      </Row>
                      <Row style={{ marginTop: -7 }}>
                        <Text type="secondary" style={{ fontSize: 10 }}>{`${user.email}`}</Text>
                      </Row>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="apptName"
              rules={[{ required: true, message: "Please select a service" }]}
            >
              <Select
                loading={isLoadingAvailableAppointments}
                disabled={isLoadingAvailableAppointments || _.isEmpty(availableAppointments)}
                placeholder={
                  !_.isEmpty(selectedClientGuardianDetails) && _.isEmpty(availableAppointments)
                    ? "No available services"
                    : "Service"
                }
                showSearch
                allowClear
                optionFilterProp="key"
              >
                {Object.keys(availableAppointments).map((apptName) => (
                  <Select.Option
                    key={`${availableAppointments[apptName].billingCode}${availableAppointments[
                      apptName
                    ].modifiers.join("")} ${apptName}`}
                    value={apptName}
                  >
                    <Text type="secondary" style={{ marginRight: 5 }}>
                      {availableAppointments[apptName].billingCode}
                      {availableAppointments[apptName].modifiers.join(" ")}
                    </Text>
                    <Text strong>{apptName}</Text>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="location"
              initialValue={appointment.location}
              rules={[{ required: true, message: "Please select a location" }]}
            >
              <Select placeholder="Location" showSearch allowClear optionFilterProp="key">
                {Object.values(AppointmentLocation).map((location) => (
                  <Select.Option key={`${getAppointmentLocationText(location)}`} value={location}>
                    {getAppointmentLocationText(location)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* allow creation of links only when no prior link exists. 
            We also do not support removing links, so once created, 
            the checkbox will be disabled and set to true. */}
          {location === AppointmentLocation.TELEHEALTH && (
            <Col span={12}>
              <Row>
                <Form.Item name="createMeet">
                  <Switch
                    defaultChecked={
                      !!appointment.meetsLink ||
                      appointment.location !== AppointmentLocation.TELEHEALTH
                    }
                    disabled={!!appointment.meetsLink}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
                <Text style={{ marginTop: 6, marginLeft: 8 }}>Google Meet</Text>
              </Row>
            </Col>
          )}
        </Row>
        <Row gutter={5}>
          <Col span={6}>
            <Form.Item
              name="date"
              initialValue={moment(appointment.startMs)}
              rules={[{ required: true, message: "Please enter a session date" }]}
            >
              <DatePicker
                placeholder="Date"
                style={{ padding: 0 }}
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="start"
              initialValue={moment(appointment.startMs)}
              rules={[{ required: true, message: "Please enter a start time" }]}
            >
              <TimePicker
                placeholder="Start"
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_TIME_FORMAT}
                minuteStep={15}
                onSelect={(time) => {
                  form.setFieldValue("start", time);
                  if (_.isEmpty(end) || end.isBefore(time)) {
                    form.setFieldValue("end", time.clone().add(30, "minutes"));
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="end"
              initialValue={moment(appointment.endMs)}
              rules={[{ required: true, message: "Please enter an end time" }]}
            >
              <TimePicker
                disabled={_.isEmpty(start)}
                placeholder="End"
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_TIME_FORMAT}
                minuteStep={15}
                disabledTime={() => ({
                  disabledHours: getDisabledEndTimeHours,
                  disabledMinutes: getDisabledEndTimeMinutes,
                })}
                onSelect={(time) => {
                  form.setFieldValue("end", time);
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="recurring" initialValue={recurring}>
              <Checkbox
                style={{ marginLeft: "23px" }}
                defaultChecked={recurring}
                disabled={appointment.id.includes("_")}
                onChange={(e) => form.setFieldValue("recurring", e.target.checked)}
              >
                <Typography.Text strong>Repeat</Typography.Text>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="description" initialValue={appointment.description}>
              <TextArea placeholder="Description" allowClear autoSize={{ minRows: 2 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Col span={20}>
            <Button
              key="submit"
              type="ghost"
              onClick={() => {
                onDeleteAppointment(appointment);
                hideModal();
              }}
              disabled={isSaving || (appointment as ILocalAppointment).delete}
              style={{ float: "left", color: "red", borderColor: "red" }}
            >
              {(appointment as ILocalAppointment).delete ? `Deleted` : `Delete Appointment`}
            </Button>
          </Col>
          <Col span={4}>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={isSaving}
              disabled={!isAppointmentDirty()}
              style={{ float: "right" }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
