import { grey, red } from "@ant-design/colors";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { IUser, UserPermission } from "@finni-health/shared";
import {
  Button,
  Descriptions,
  Divider,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { Timestamp } from "firebase/firestore";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DISPLAY_DATE_FORMAT } from "../../consts";
import { renderAddress } from "../../helpers/address";
import { updatePreferredTransport } from "../../helpers/profiles";
import { userHasPermission } from "../../helpers/userPermissions";
import { DEFAULT_SCHEDULE, getScheduleString } from "../../helpers/workingHours";
import * as FirestoreService from "../../services/firestore";
import { EditUserModal } from "../EditUserModal/EditUserModal";
import { useUserClinics } from "../UserClinicsProvider";
import { PreferredTransportSelector } from "../UserProfile/PreferredTransport";
import { PayrollExportModal } from "./PayrollExportModal";

const { Link: LinkText } = Typography;
const { Search } = Input;

export const StaffTable: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useUserClinics();

  const [users, setUsers] = useState<IUser[]>([]);
  const [dataSource, setDataSource] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");

  // Update User Modal
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState<boolean>(false);
  const [editUserModalUser, setEditUserModalUser] = useState<IUser>({} as IUser);
  const hideEditUserModal = () => {
    setIsEditUserModalVisible(false);
  };

  // Payroll Export Modal
  const [isPayrollExportModalVisible, setIsPayrollExportModalVisible] = useState<boolean>(false);
  const hidePayrollExportModalVisible = () => {
    setIsPayrollExportModalVisible(false);
  };

  //change url
  useEffect(() => {
    if (!isEditUserModalVisible) {
      return;
    }

    const { pathname } = location;
    const idx = pathname.indexOf("staff");
    const link =
      pathname.slice(0, idx + "staff".length) +
      "/" +
      (!_.isEmpty(editUserModalUser) ? editUserModalUser.id : "");
    window.history.replaceState(null, "Mission Control - Finni Health", link);
  }, [isEditUserModalVisible, editUserModalUser]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      fetchUsers().catch(() => {});
    }
  }, [user]);

  const fetchUsers = async () => {
    setIsLoading(true);
    let users = await FirestoreService.getAllUsersForClinic(user.clinicId, true);

    users = users.map((user) => {
      const name = `${user.firstName} ${user.lastName}`;
      return {
        ...user,
        name: name,
        key: user.id,
      };
    });

    setUsers(users);
    setDataSource(users);
    setIsLoading(false);

    if (id) {
      const user = users.find((user) => user.id === id);
      if (user) {
        setEditUserModalUser(user);
        setIsEditUserModalVisible(true);
      }
    }
  };

  const removeRowExtras = (row: any) => {
    const cleanedRow: any = { ...row };
    delete cleanedRow.name;
    delete cleanedRow.key;
    return cleanedRow;
  };

  const deleteUser = async (user: IUser) => {
    setIsLoading(true);
    try {
      await FirestoreService.deleteUser({ id: user.id });
      void message.success(`Deleted user ${user.firstName} ${user.lastName}`);
      await fetchUsers();
    } catch (err) {
      void message.error("Couldn't delete user, please try again");
      console.error(err);
    }
  };

  const handleSearch = (searchString: string) => {
    setSearchString(searchString);
    // Split the search string by commas, remove empty results, and trim them
    const searchStrings = searchString
      .toLowerCase()
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s);

    if (!_.isEmpty(searchStrings)) {
      const dataSource = users.filter((user) =>
        searchStrings.some(
          (searchFor) =>
            user.firstName.toLowerCase().includes(searchFor) ||
            user.lastName.toLowerCase().includes(searchFor)
        )
      );

      setDataSource(dataSource);
    } else {
      setDataSource(users);
    }
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "City",
      dataIndex: ["address", "city"],
      sorter: (a: IUser, b: IUser) => a.address?.city?.localeCompare(b.address?.city),
    },
    {
      title: "Hours",
      dataIndex: "isFullTime",
      render: (isFullTime: boolean) =>
        isFullTime ? <Tag color="blue">Full-time</Tag> : <Tag color="orange">Part-time</Tag>,
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      sorter: (a: IUser, b: IUser) => a.permissions.length - b.permissions.length,
      render: (UserPermissions: UserPermission[]) => {
        return UserPermissions.map((permiss, index) => <Tag key={index}>{permiss}</Tag>);
      },
    },
    {
      title: "Employee ID",
      dataIndex: "id",
    },
    {
      width: 15,
      align: "center" as any,
      render: (_text: string, row: IUser) => (
        <Row wrap={false} justify="center" align="middle">
          <Space size="large">
            <Tooltip title="Edit User">
              <LinkText
                onClick={() => {
                  setEditUserModalUser(removeRowExtras(row));
                  setIsEditUserModalVisible(true);
                }}
              >
                <EditOutlined style={{ fontSize: 16 }} />
              </LinkText>
            </Tooltip>
            <Popconfirm
              title={`Are you sure you want to delete user ${row.firstName} ${row.lastName}?`}
              placement="bottomRight"
              disabled={user.id === row.id}
              onConfirm={() => deleteUser(row)}
              okText="Yes"
              cancelText="No"
            >
              <div>
                <Tooltip title="Delete User">
                  <LinkText disabled={user.id === row.id}>
                    <DeleteOutlined
                      style={{
                        color: user.id === row.id ? grey.primary : red.primary,
                        fontSize: 16,
                      }}
                    />
                  </LinkText>
                </Tooltip>
              </div>
            </Popconfirm>
          </Space>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Space
          size="large"
          style={{
            marginBottom: "15px",
            marginLeft: 5,
          }}
        >
          <Statistic loading={isLoading} title="Total Staff" value={dataSource.length} />
          <Statistic
            loading={isLoading}
            title="Full-Time"
            value={dataSource.filter((user) => user.isFullTime).length}
          />
          <Statistic
            loading={isLoading}
            title="Part-Time"
            value={dataSource.filter((user) => !user.isFullTime).length}
          />
          <Divider
            type="vertical"
            style={{
              borderLeft: "1px solid grey",
              height: 50,
              marginRight: 10,
            }}
          />
          <Statistic
            loading={isLoading}
            title="BCBA"
            value={dataSource.filter((user) => userHasPermission(user, UserPermission.BCBA)).length}
          />
          <Statistic
            loading={isLoading}
            title="RBT"
            value={dataSource.filter((user) => userHasPermission(user, UserPermission.RBT)).length}
          />
        </Space>
        <Tooltip title="Generates CSVs of direct and indirect hours for the past 2 weeks">
          <Button
            onClick={() => {
              setIsPayrollExportModalVisible(true);
            }}
          >
            Export Payroll
          </Button>
        </Tooltip>
      </Row>
      <Search
        placeholder="Search by First or Last Name. Seperate multiples by comma"
        allowClear
        value={searchString}
        onChange={(e) => handleSearch(e.target.value)}
        style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
      />
      <Table
        columns={tableColumns}
        dataSource={dataSource}
        loading={isLoading}
        expandable={{
          expandedRowRender: (row: IUser) => (
            <Descriptions bordered column={4} style={{ paddingLeft: 50 }}>
              <Descriptions.Item label="Sex: " span={1}>
                {row.sex}
              </Descriptions.Item>
              <Descriptions.Item label="Date of Birth: " span={1}>
                {row.dateOfBirth}
              </Descriptions.Item>
              <Descriptions.Item label="Created On: " span={2}>
                {moment((row.createdAt as Timestamp).toDate()).format(DISPLAY_DATE_FORMAT)}
              </Descriptions.Item>
              <Descriptions.Item label="Address: " span={1} style={{ whiteSpace: "pre-wrap" }}>
                {renderAddress(row.address)}
              </Descriptions.Item>
              <Descriptions.Item label="Address Notes: " span={3}>
                {row.addressNotes}
              </Descriptions.Item>
              <Descriptions.Item
                label="Working hours: "
                span={5}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {getScheduleString({ ...DEFAULT_SCHEDULE, ...row.schedule })}
              </Descriptions.Item>
              <Descriptions.Item
                label="Preferred Transport: "
                span={1}
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                <PreferredTransportSelector
                  user={row}
                  onChange={(e) =>
                    updatePreferredTransport(e.target.value, row.id, () => {
                      //pass
                    })
                  }
                />
              </Descriptions.Item>
            </Descriptions>
          ),
        }}
      />
      <EditUserModal
        user={editUserModalUser}
        isVisible={isEditUserModalVisible}
        hideModal={hideEditUserModal}
        refreshCallback={fetchUsers}
      />
      <PayrollExportModal
        users={users}
        isVisible={isPayrollExportModalVisible}
        hideModal={hidePayrollExportModalVisible}
      />
    </>
  );
};
