import { Button, Col, Divider, Form, Input, Tabs } from "antd";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import React, { useState } from "react";
import ReactJson from "react-json-view";

import DynamicBackendRequestFields from "../components/Development/DynamicBackendRequestFields";
import DynamicFirestoreRequestFields from "../components/Development/DynamicFirestoreRequestFields";
import * as backend from "../services/backend";
import app from "../services/firebase";

const db = getFirestore(app);

export enum TABLE_TABS {
  BACKEND = "Backend",
  FIRESTORE = "Firestore",
}

export const Development: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [backendResults, setBackendResults] = useState({});
  const [firestoreResults, setFirestoreResults] = useState({});

  const [selectedTab, setSelectedTab] = useState<TABLE_TABS>(TABLE_TABS.BACKEND);

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  const handleBackendRequest = async (values: any) => {
    setIsLoading(true);
    try {
      const req = backend.endpoint(values.request, "post");
      const parameters = values.parameters?.reduce((acc: any, entry: any) => {
        acc[entry.key] = entry.value;
        return acc;
      }, {});
      const res = await req(parameters);
      const json = JSON.parse(JSON.stringify(res, null, 2));
      setBackendResults(typeof json === "object" ? json : { response: res });
    } catch (err: any) {
      setBackendResults({ error: err, message: err.message });
    }
    setIsLoading(false);
  };

  const handleFirestoreRequest = async (values: any) => {
    setIsLoading(true);
    try {
      const filteredRequest = values.parameters.reduce((acc: any, entry: any) => {
        return acc.push(where(entry.key, entry.condition, entry.value));
      }, []);
      const res = await getDocs(query(collection(db, values.collection), ...filteredRequest));
      const docs = res.docs.map((doc: any) => doc.data());
      setFirestoreResults(JSON.parse(JSON.stringify(docs, null, 2)));
    } catch (err: any) {
      setFirestoreResults({ error: err, message: err.message });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Tabs
        type="card"
        defaultActiveKey={selectedTab.valueOf()}
        onChange={(key) => {
          setSelectedTab(key as TABLE_TABS);
        }}
        items={Object.values(TABLE_TABS).map((tab) => ({
          key: tab,
          label: tab,
        }))}
      ></Tabs>
      {selectedTab === TABLE_TABS.BACKEND && (
        <>
          <Form form={form1} onFinish={handleBackendRequest}>
            <Col span={12}>
              <Divider orientation="left">Backend Requests Testing</Divider>
              <div style={{ marginLeft: 20 }}>
                <Form.Item name="request" label="Request URL" rules={[{ required: true }]}>
                  <Input placeholder="/motivity/get"></Input>
                </Form.Item>
                <br />
                <Divider orientation="left">Parameters</Divider>
                <DynamicBackendRequestFields />
                <br />
                <Divider orientation="left">Execution</Divider>
                <ReactJson src={backendResults} />
                <br />
                <br />
                <Form.Item>
                  <Button
                    shape="default"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Execute Request
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Form>
        </>
      )}
      {selectedTab === TABLE_TABS.FIRESTORE && (
        <>
          <Form form={form2} onFinish={handleFirestoreRequest}>
            <Col span={12}>
              <Divider orientation="left">Firestore Requests Testing</Divider>
              <div style={{ marginLeft: 20 }}>
                <Form.Item name="collection" label="Collection" rules={[{ required: true }]}>
                  <Input placeholder="clinics"></Input>
                </Form.Item>
                <br />
                <Divider orientation="left">Conditions</Divider>
                <DynamicFirestoreRequestFields />
                <br />
                <Divider orientation="left">Execution</Divider>
                <ReactJson src={firestoreResults} />
                <br />
                <br />
                <Form.Item>
                  <Button
                    shape="default"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Execute Request
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Form>
        </>
      )}
    </>
  );
};
