import { getAuth } from "firebase/auth";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

import { Loading } from "../pages/Loading";
import app from "../services/firebase";
import { updateUserClinicId } from "../services/firestore";
import { useUserClinics } from "./UserClinicsProvider";

/**
 * Helper component to ensure user is Authenticated before accessing the route.
 */
export const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const { user, clinic, availableClinics, refresh } = useUserClinics();
  const { clinicName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmpty(user)) return;

    // path would be '/'
    if (clinicName === undefined || clinicName === "") {
      const queryParams = location.search;

      const link = "/" + clinic.name + (queryParams !== "" ? "/?" + queryParams : "");

      navigate(link);
      return;
    }

    // path would be either:
    // - /<clinic-name>/* - a clinic name slug (valid / invalid)
    // - /* - legacy URL without clinic name slug
    if (clinicName !== clinic.name) {
      const allowedClinic = availableClinics.find((clinic) => clinic.name === clinicName);

      if (allowedClinic === undefined) {
        const pathName = location.pathname;
        const queryParams = location.search;

        const link = pathName + (queryParams !== "" ? "?" + queryParams : "");
        navigate(`/${clinic.name}${link}`);
        return;
      }

      updateUserClinicId({ clinicId: allowedClinic?.id })
        .then(() => refresh())
        .then(() => setLoading(false))
        .catch(() => {});
      return;
    }
    setLoading(false);
  }, [user, location, clinicName]);

  if (!getAuth(app).currentUser) {
    return <Navigate to="/login" replace />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return children;
};
