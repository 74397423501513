import "../css/PreferredTransport.css";

import { IUser, TransportMode } from "@finni-health/shared";
import { Col, Radio, RadioChangeEvent, Row } from "antd";
import React from "react";
import { IoBicycleSharp, IoCarOutline } from "react-icons/io5";
import { MdDirectionsWalk, MdOutlineDirectionsTransitFilled } from "react-icons/md";

export const PreferredTransportSelector = ({
  user,
  onChange,
  style,
}: {
  user?: IUser;
  onChange: (ev: RadioChangeEvent) => void;
  style?: React.CSSProperties;
}) => {
  return (
    <>
      <Radio.Group
        onChange={onChange}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ...style,
        }}
        defaultValue={user?.preferredTransport}
      >
        <Radio.Button
          value={TransportMode.DRIVING}
          style={{
            borderRadius: "50%",
            padding: 5,
            width: 35,
            height: 35,
            borderLeftWidth: "1px",
          }}
        >
          <Col>
            <Row align="middle" justify="center">
              <IoCarOutline size="20px" />
            </Row>
          </Col>
        </Radio.Button>
        <Radio.Button
          value={TransportMode.TRANSIT}
          style={{
            borderRadius: "50%",
            padding: 5,
            width: 35,
            height: 35,
            borderLeftWidth: "1px",
          }}
        >
          <Col>
            <Row align="middle" justify="center">
              <MdOutlineDirectionsTransitFilled size="20px" />
            </Row>
          </Col>
        </Radio.Button>
        <Radio.Button
          value={TransportMode.WALKING}
          style={{
            borderRadius: "50%",
            padding: 5,
            width: 35,
            height: 35,
            borderLeftWidth: "1px",
          }}
        >
          <Col>
            <Row align="middle" justify="center">
              <MdDirectionsWalk size="20px" />
            </Row>
          </Col>
        </Radio.Button>
        <Radio.Button
          value={TransportMode.BICYCLING}
          style={{
            borderRadius: "50%",
            padding: 5,
            width: 35,
            height: 35,
            borderLeftWidth: "1px",
          }}
        >
          <Col>
            <Row align="middle" justify="center">
              <IoBicycleSharp size="20px" />
            </Row>
          </Col>
        </Radio.Button>
      </Radio.Group>
    </>
  );
};
