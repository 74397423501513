import { ICompletedAppointment, IIndirect, IUser, IUserPayRate } from "@finni-health/shared";
import _ from "lodash";
import moment from "moment";

import { DISPLAY_DATE_FORMAT } from "../consts";
import * as FirestoreService from "../services/firestore";
import { getDirectAndIndirectIntervals, IInterval } from "./appointments";
import { exportCsv, ICsvTranslatorField } from "./csv";

interface IPayrollInterval extends IInterval {
  payRate: number;
}

export interface IUserPayrollInterval {
  email: string;
  intervals: IPayrollInterval[];
}

export interface IPayrollLine {
  email: string;
  date: string;
  totalHours: number;
  totalPay: number;
}

const PAYROLL_CSV_TEMPLATE: ICsvTranslatorField[] = [
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Hours",
    dataIndex: "totalHours",
    render: (totalHours: number) => totalHours.toFixed(2),
  },
  {
    title: "Pays",
    dataIndex: "totalPay",
    render: (totalPay: number) => (totalPay / 100).toFixed(2),
  },
];

export const getPayrollHoursCsv = async (
  users: IUser[],
  indirects: IIndirect[],
  completedAppointments: ICompletedAppointment[]
) => {
  users.sort((a, b) => a.email.localeCompare(b.email));

  const userIntervals = await Promise.all(
    users.map(async (user) => {
      const userCompletedAppts = completedAppointments.filter((appt) =>
        appt.userIds.includes(user.id)
      );
      const userIndirects = indirects.filter((indirect) =>
        indirect.attendees.find((attendee) => attendee.email === user.email)
      );
      const userAppts = [...userCompletedAppts, ...userIndirects];

      return await getPayrollIntervals(userAppts, user);
    })
  );

  const directCsvPayload = sumUserIntervalsByUser(
    userIntervals.map((x) => ({
      email: x.email,
      intervals: x.directPayrollIntervals,
    }))
  );

  const indirectCsvPayload = sumUserIntervalsByUser(
    userIntervals.map((x) => ({
      email: x.email,
      intervals: x.indirectPayrollIntervals,
    }))
  );

  exportCsv("directHours", directCsvPayload, PAYROLL_CSV_TEMPLATE);
  exportCsv("indirectHours", indirectCsvPayload, PAYROLL_CSV_TEMPLATE);
};

export const sumUserIntervalsByUser = (userIntervals: IUserPayrollInterval[]): IPayrollLine[] => {
  const userHours = userIntervals
    .map((interval) => {
      const groupedByDate = _(interval.intervals)
        .groupBy((interval: any) => moment(interval.startMs).format(DISPLAY_DATE_FORMAT))
        .value();

      const result: IPayrollLine[] = [];
      for (const date of Object.keys(groupedByDate)) {
        const intervals = groupedByDate[date];
        const totalMs = intervals.reduce(
          (partialSum, interval) => partialSum + (interval.endMs - interval.startMs),
          0
        );

        const totalPay = intervals.reduce(
          (partialSum, interval) =>
            partialSum + interval.payRate
              ? interval.payRate * ((interval.endMs - interval.startMs) / (3600 * 1000))
              : 0,
          0
        );

        result.push({
          email: interval.email,
          date,
          totalHours: totalMs / (3600 * 1000),
          totalPay,
        });
      }

      return result;
    })
    .flat();

  return userHours;
};

export const getPayrollIntervals = async (
  appts: (ICompletedAppointment | IIndirect)[],
  user: IUser
) => {
  const { directIntervals, indirectIntervals } = getDirectAndIndirectIntervals(appts, user);

  const directPayrollIntervals = await addPayRateToIntervals(
    user,
    "directRateCents",
    directIntervals
  );
  const indirectPayrollIntervals = await addPayRateToIntervals(
    user,
    "indirectRateCents",
    indirectIntervals
  );

  return {
    email: user.email,
    directPayrollIntervals,
    indirectPayrollIntervals,
  };
};

const addPayRateToIntervals = async (
  user: IUser,
  payType: "directRateCents" | "indirectRateCents",
  intervals: IInterval[]
): Promise<IPayrollInterval[]> => {
  const result: IPayrollInterval[] = [];
  for (const interval of intervals) {
    let userPayRate: IUserPayRate;
    try {
      userPayRate = await FirestoreService.getUserPayRatesByUserIdAndEpochMs(
        user.id,
        interval.startMs
      );
    } catch (err) {
      userPayRate = {
        directRateCents: 0,
        indirectRateCents: 0,
        salaryRateCents: 0,
      } as IUserPayRate;
    }

    result.push({ ...interval, payRate: userPayRate[payType] });
  }

  return result;
};
