import { IAvailability, ISchedule, Weekday } from "@finni-health/shared";
import { Timestamp } from "firebase/firestore";
import moment from "moment";

import * as FirestoreService from "../services/firestore";

export type IInterval = { startMs: Timestamp; endMs: Timestamp };

export const DEFAULT_SCHEDULE = Object.values(Weekday).reduce(
  (prev, next) => ({
    ...prev,
    [next]: {
      enabled: false,
      intervals: [],
    },
  }),
  {}
) as ISchedule;

const eventSortComparator = (a: IInterval, b: IInterval) => {
  if (a.startMs.seconds == b.startMs.seconds) {
    return a.endMs.seconds < b.endMs.seconds ? -1 : 1;
  }
  return a.startMs.seconds < b.startMs.seconds ? -1 : 1;
};

export const mergeIntervals = (data: IInterval[]) => {
  data.sort(eventSortComparator);

  let currInterval: IInterval | undefined = undefined;
  const intervals: IInterval[] = [];
  for (const event of data) {
    if (currInterval === undefined) {
      currInterval = { startMs: event.startMs, endMs: event.endMs };
    } else if (event.startMs.seconds > currInterval.endMs.seconds) {
      intervals.push(currInterval);
      currInterval = { startMs: event.startMs, endMs: event.endMs };
    } else if (event.endMs.seconds > currInterval.endMs.seconds) {
      currInterval.endMs = event.endMs;
    }
  }
  if (currInterval) {
    intervals.push(currInterval);
  }
  return intervals;
};

export const validateTimeIInterval = ({ startMs, endMs }: IInterval) =>
  startMs.seconds < endMs.seconds;

const mergeScheduleIIntervals = (schedule: ISchedule) => {
  const mergedSchedule = { ...schedule };
  Object.keys(schedule).map(
    (day) =>
      (mergedSchedule[day as Weekday] = {
        intervals: mergeIntervals(schedule[day as Weekday].intervals as IInterval[]),
        enabled: schedule[day as Weekday].enabled,
      })
  );
  return mergedSchedule;
};

export const requestScheduleUpdate = async (
  newSched: ISchedule,
  userId: string,
  clinicId: string,
  refresh: () => void,
  message?: { error: (arg0: string) => void }
) => {
  try {
    await FirestoreService.sendScheduleUpdateRequest({
      userId,
      schedule: mergeScheduleIIntervals(newSched),
      clinicId,
    });
    refresh();
  } catch (error) {
    console.error(error);
    message?.error("Error creating schedule update request, please try again later.");
  }
};

export const approveScheduleUpdateRequest = async (reqId: string) => {
  try {
    await FirestoreService.approveScheduleUpdateRequest({
      id: reqId,
    });
  } catch (error) {
    console.error(error);
  }
};

export const declineScheduleUpdateRequest = async (reqId: string) => {
  try {
    await FirestoreService.declineScheduleUpdateRequest({
      id: reqId,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getScheduleString = (schedule: ISchedule) => {
  let str = "";
  Object.values(Weekday).map((day) => {
    str += `${day.substring(0, 3).toUpperCase()} : ${
      schedule[day as Weekday].enabled
        ? schedule[day as Weekday].intervals
            .map(
              ({ startMs, endMs }) =>
                `${moment(startMs.seconds * 1000).format("hh:mm A")} - ${moment(
                  endMs.seconds * 1000
                ).format("hh:mm A")}`
            )
            .join(", ")
        : "Unavailable"
    }\n`;
  });
  return str;
};

export const getPendingApprovalScheduleUpdates = async (clinicId: string) => {
  try {
    return await FirestoreService.getAllPendingScheduleUpdateRequests(clinicId);
  } catch (error) {
    console.error(error);
  }
};

export const areAvailabilityDaysEmpty = (availability: IAvailability): boolean => {
  return Object.values(availability).every((day) => day.length === 0);
};
