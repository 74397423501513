import { Button, Modal, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

import birthdayGIF from "../lottie/birthday.gif";
import { useUserClinics } from "./UserClinicsProvider";

const { Text } = Typography;

export const BirthdayModule: React.FC = () => {
  const { user } = useUserClinics();

  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [cookies, setCookie] = useCookies();
  const location = useLocation();

  useEffect(() => {
    if (user?.id) {
      const today = moment();
      const birthdate = moment(user.dateOfBirth);
      const isBirthday =
        birthdate.month() === today.month() &&
        birthdate.date() === today.date() &&
        birthdate.year() !== today.year();

      const hasWished = cookies[`wished_${user.id}`] as boolean;
      if (isBirthday && !hasWished) {
        setShowConfetti(true);
        setIsOpen(true);
      }
    }
  }, [user, location]);

  const onCloseModal = () => {
    setShowConfetti(false);
    setIsOpen(false);
    setCookie(`wished_${user.id}`, true, {
      maxAge: 2 * 24 * 60 * 60,
      path: "/",
    });
  };

  return (
    <>
      {showConfetti && (
        <div className="birthday-module">
          <Modal
            open={isOpen}
            width={600}
            style={{ top: 150 }}
            onCancel={onCloseModal}
            destroyOnClose={true}
            footer={null}
          >
            <Confetti
              width={window.innerWidth * 2}
              height={window.innerHeight}
              style={{
                marginLeft: -window.innerWidth,
                marginTop: -150,
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1>Woah, {user.firstName}!</h1>
              <img src={birthdayGIF} alt="loading..." style={{ width: "60%" }} />
              <Text>
                {`Happy, happy birthday from all of us at Finni! Today, we
                celebrate not just your special day, but also the amazing person
                you are and all the incredible things you've achieved!`}
                <br />
                <br />
                {`As a company focused on empowering our learners, 
                we know the importance of growth, progress, and the
                sense of accomplishment that comes with learning something new.
                On your birthday, we join you in celebrating all the ways you've
                grown, all the things you've achieved, and all the exciting
                things that lie ahead.`}
                <br />
                <br />
                {`We're honored to have you as a part of our community, and we
                can't wait to see all the wonderful things you'll accomplish in
                the coming year. So here's to you, ${user.firstName}! May your
                birthday be filled with love, joy, and all the things that make
                you happiest. And may the coming year be your best one yet, full
                of limitless potential and endless opportunities.`}
                <br />
                <br />
                {`With warmest wishes, the Finni Team`}
                <br />
                <br />
              </Text>
              <Button onClick={onCloseModal} style={{ marginLeft: "auto", marginRight: "auto" }}>
                Bye bye, my birthday cake is calling!
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
