import { CheckCircleTwoTone, ExclamationCircleTwoTone } from "@ant-design/icons";
import {
  AppointmentLocation,
  BillingCode,
  BillingLevel,
  calculateChargeCentsFromUnits,
  getAppointmentNameFromBilling,
  getPayerById,
  IBilledAppointment,
  IClientDetails,
  ICompletedAppointment,
  IPayer,
  IUpdateCompletedAppointmentEndpointRequest,
  IUser,
  Modifier,
  SCA_SUFFIX_TEXT,
  stringToColor,
  UserPermission,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import {
  Badge,
  Button,
  Checkbox,
  InputNumber,
  message,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { IoSchoolOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, ERROR_MESSAGE } from "../../consts";
import { getAppointmentLocationText } from "../../helpers/appointments";
import { validateAllClientFileFields } from "../../helpers/clientFiles";
import { getBillingCodeColor } from "../../helpers/colors";
import { exportCsv } from "../../helpers/csv";
import { BILLING_TABS } from "../../pages/Billing";
import * as FirestoreService from "../../services/firestore";
import { useUserClinics } from "../UserClinicsProvider";
import { JUNIPER_BILLING_CSV_EXPORT_TEMPLATE } from "./juniperCsvTemplate";

const { Text } = Typography;

interface IProps {
  handleCount: (value: number) => void;
  handleTabChange: (value: string) => void;
}

type clientPayerMap = {
  [clientId: string]: IPayer;
};

export const CompletedAppointmentsTable: React.FC<IProps> = ({
  handleTabChange,
  handleCount,
}: IProps) => {
  const { clinic } = useUserClinics();

  const [isMainLoading, setMainLoading] = useState<boolean>(true);
  const [isLoadingExtraData, setIsLoadingExtraData] = useState<boolean>(true);
  const [isFinalizing, setIsFinalizing] = useState<boolean>(false);

  const [clientDetails, setClientDetails] = useState<Record<string, IClientDetails> | null>(null);

  const [appointments, setAppointments] = useState<ICompletedAppointment[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [payers, setPayers] = useState<clientPayerMap>({});
  const [selectedAppointmentIds, setSelectedAppointmentIds] = useState<Set<string>>(new Set());

  useEffect(() => {
    fetchData().catch(() => {});
  }, [clinic]);

  const fetchData = async () => {
    setMainLoading(true);
    const appointments = await FirestoreService.getAllBillableCompletedAppointmentsByClinicId(
      clinic.id
    );
    setAppointments(appointments);
    handleCount(appointments.length);
    setMainLoading(false);
    return fetchExtraData(appointments);
  };

  const fetchExtraData = async (appointments: ICompletedAppointment[]) => {
    const clientIds: Set<string> = new Set();
    const userIds: Set<string> = new Set();

    appointments.forEach((appt) => {
      clientIds.add(appt.clientId);
      userIds.add(appt.renderingUserId);
      userIds.add(appt.billingUserId);
    });

    const [clientsDetails, users] = await Promise.all([
      FirestoreService.getClientsDetailsByClientIds(Array.from(clientIds)),
      FirestoreService.getAllUsersByIds(Array.from(userIds)),
    ]);
    const clientsDetailsMap = _.keyBy(clientsDetails, (clientDetails) => clientDetails.client.id);
    const payers = await Promise.all(
      clientsDetails.map((clientDetails) => {
        const payer = getPayerById(
          clinic.address.state,
          clientDetails.clientFile.payers.primary?.payerId ||
            clientDetails.clientFile.payers.secondary?.payerId ||
            null
        );

        return { [clientDetails.clientFile.clientId]: payer || {} };
      })
    );

    setClientDetails(clientsDetailsMap);
    setUsers(users);
    setPayers(Object.assign({}, ...payers));
    setIsLoadingExtraData(false);
  };

  const handleSelectAppointment = (id: string) => {
    const newSelectedAppointmentIds = _.cloneDeep(selectedAppointmentIds);
    newSelectedAppointmentIds.add(id);
    setSelectedAppointmentIds(newSelectedAppointmentIds);
  };

  const handleUnselectAppointment = (id: string) => {
    const newSelectedAppointmentIds = _.cloneDeep(selectedAppointmentIds);
    newSelectedAppointmentIds.delete(id);
    setSelectedAppointmentIds(newSelectedAppointmentIds);
  };

  const handleSelectAll = (e: any) => {
    const newSelectedAppointmentIds = _.cloneDeep(selectedAppointmentIds);
    if (e.target.checked) {
      appointments.map((appt) => newSelectedAppointmentIds.add(appt.id));
    } else {
      appointments.map((appt) => newSelectedAppointmentIds.delete(appt.id));
    }

    setSelectedAppointmentIds(newSelectedAppointmentIds);
  };

  const handleUpdateCompletedAppointment = async (
    req: IUpdateCompletedAppointmentEndpointRequest
  ) => {
    // duplicate local state for modification
    const apptIndex = appointments.findIndex((appt) => appt.id === req.id);
    const newCompletedAppointments = _.cloneDeep(appointments);
    let newAppt = newCompletedAppointments[apptIndex];

    // get the new charge if any billing fields have changed
    let chargeCents, payer, modifiersInput;
    let billingLevel: BillingLevel | undefined = undefined;
    try {
      const clientDetail = clientDetails![newAppt.clientId];
      payer = getPayerById(
        clinic.address.state,
        clientDetail.clientFile.payers.primary?.payerId ?? null
      );

      // get the billing level
      modifiersInput = req.modifiers || newAppt.modifiers;
      // If BCBA was present at the session, use BCBA billing level, otherwise use RBT
      billingLevel = users
        .filter((user) => newAppt.userIds.includes(user.id))
        .some((user) => user.permissions.includes(UserPermission.BCBA))
        ? BillingLevel.BCBA
        : BillingLevel.RBT;

      if (!billingLevel) {
        throw new Error(`Couldn't derive billingLevel from ${modifiersInput}`);
      }

      chargeCents = calculateChargeCentsFromUnits(
        req.units || newAppt.units,
        req.billingCode || newAppt.billingCode,
        billingLevel,
        payer,
        modifiersInput
      );

      if (payer.payerId.endsWith(SCA_SUFFIX_TEXT)) {
        chargeCents = chargeCents * 2;
      }
    } catch (err) {
      void message.error(
        "An error occured saving units. Please check that codes and modifiers are correct"
      );
      return;
    }

    // create the updated completedAppointment and update state array
    newAppt = { ...newAppt, ...req, chargeCents };

    const billingModifiers = payer.getBillingModifiers(newAppt, payer, billingLevel);
    console.log(`billingModifiers: ${billingModifiers}`);
    newAppt.modifiers = _.union(billingModifiers, modifiersInput);

    newCompletedAppointments[apptIndex] = newAppt;

    // update firestore and local state
    try {
      await FirestoreService.updateCompletedAppointment(newAppt);
      setAppointments(newCompletedAppointments);

      const payerRate = payer.codes[newAppt.billingCode]?.rates[billingLevel];

      if (payerRate === undefined || (newAppt.units > 0 && chargeCents === 0)) {
        void message.warning(
          <>
            <Row>
              <Tag color={stringToColor(payer.name)} style={{ marginLeft: 0, marginRight: 4 }}>
                {payer.name}
              </Tag>
              <Text
                strong
                style={{ marginRight: 3 }}
              >{`${newAppt.billingCode} ${newAppt.modifiers}`}</Text>
              <Text>{"does not have rates configured"}</Text>
            </Row>
            <Row style={{ width: "100%", marginTop: 10 }} justify="center">
              <a href="/payers">
                <Button>Configure here</Button>
              </a>
            </Row>
          </>,
          10
        );
      } else {
        void message.success("Changes saved");
      }
    } catch (err) {
      void message.error(ERROR_MESSAGE);
    }
  };

  const handleFinalize = async () => {
    setIsFinalizing(true);
    try {
      await FirestoreService.batchBill({
        completedAppointmentIds: Array.from(selectedAppointmentIds),
      });
      handleTabChange(BILLING_TABS.FINALIZED);
    } catch (err) {
      void message.error(
        "Failed to finalize, please check that all appointment data are validated."
      );
    }

    await fetchData();
    setIsFinalizing(false);
  };

  const handleFinalizeDryRun = async () => {
    setIsFinalizing(true);
    try {
      const appointments = await FirestoreService.batchBillDryRun({
        completedAppointmentIds: Array.from(selectedAppointmentIds),
      });
      const addedQCodes = appointments
        .map((appt: IBilledAppointment) => {
          if (appt.location === AppointmentLocation.TELEHEALTH) {
            const apptCopy = _.cloneDeep(appt);
            apptCopy.id = apptCopy.id + "q";
            apptCopy.billingCode = "Q3014" as BillingCode;
            apptCopy.chargeCents = 2483;
            apptCopy.units = 1;
            apptCopy.modifiers = [Modifier.MOD_95];

            return [appt, apptCopy];
          } else {
            return appt;
          }
        })
        .flat();

      const csvName = `juniper-billing-dry-run`;
      exportCsv(csvName, addedQCodes, JUNIPER_BILLING_CSV_EXPORT_TEMPLATE);
    } catch (err) {
      void message.error(
        "Failed to finalize, please check that all appointment data are validated."
      );
    }

    setIsFinalizing(false);
  };

  const getTableColumns = () => {
    const tableColumns: ColumnsType<ICompletedAppointment> = [
      {
        title: (
          <Tooltip
            title={`${selectedAppointmentIds.size === appointments.length ? "Des" : "S"}elect all`}
          >
            <Checkbox
              checked={selectedAppointmentIds.size === appointments.length}
              onChange={handleSelectAll}
            />
          </Tooltip>
        ),
        dataIndex: "id",
        width: 50,
        render: (id: string) => {
          return (
            <Checkbox
              checked={selectedAppointmentIds.has(id)}
              onChange={(e) => {
                if (e.target.checked) {
                  handleSelectAppointment(id);
                } else {
                  handleUnselectAppointment(id);
                }
              }}
            />
          );
        },
      },
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "Session Date",
        dataIndex: "startMs",
        shouldCellUpdate: (record, prevRecord) => record.startMs !== prevRecord.startMs,
        sorter: (a, b) => a.startMs - b.startMs,
        render: (_: number, row: ICompletedAppointment) => {
          const start = moment(row.startMs);
          const end = moment(row.endMs);
          const duration = moment.duration(end.diff(start));

          return (
            <>
              <Text strong style={{ marginRight: 10 }}>{`${start.format(
                DISPLAY_DATE_FORMAT
              )}`}</Text>
              <br />
              <Text style={{ marginRight: 10 }}>
                {`${start.format(DISPLAY_TIME_FORMAT)} — ${end.format(DISPLAY_TIME_FORMAT)}`}
              </Text>
              <br />
              <Text>
                {`${Math.floor(duration.asHours())} hours ${duration.asMinutes() % 60} mins`}
              </Text>
            </>
          );
        },
      },
      {
        title: "Client",
        dataIndex: "clientId",
        width: 100,
        render: (clientId: string) => {
          if (!clientDetails) {
            return null;
          }
          const { client, clientFile } = clientDetails[clientId];

          if (!clientFile) return null;

          const clientFileErrors = validateAllClientFileFields(clientFile);

          return (
            <Skeleton loading={isLoadingExtraData} paragraph={false}>
              <Row style={{ marginBottom: 10 }}>
                {clientId ? (
                  <Badge dot count={clientFileErrors.length}>
                    <Tooltip
                      placement="right"
                      title={
                        _.isEmpty(clientFileErrors) ? (
                          "View Client"
                        ) : (
                          <Row>
                            <Text style={{ color: "#FFF" }}>Client file has errors</Text>
                            {clientFileErrors.map((errorMessage, index) => (
                              <Row key={index}>
                                <Text style={{ color: "#FFF" }}>{`• ${errorMessage}`}</Text>
                              </Row>
                            ))}
                          </Row>
                        )
                      }
                    >
                      <Link to={`../client-profile/${clientId}`}>
                        <IoSchoolOutline
                          style={{
                            fontSize: 18,
                            marginBottom: -3,
                            marginRight: 3,
                          }}
                        />
                        {client.alias}
                      </Link>
                    </Tooltip>
                  </Badge>
                ) : (
                  <Tooltip title="Client missing, please contact #product">
                    <ExclamationCircleTwoTone twoToneColor="red" />
                  </Tooltip>
                )}
              </Row>
              <Row style={{ marginTop: 19 }}>
                <Tag>{payers[clientId]?.name}</Tag>
              </Row>
            </Skeleton>
          );
        },
      },
      {
        title: "Rendering Provider",
        dataIndex: "renderingUserId",
        width: 180,
        render: (renderingUserId: string, row: ICompletedAppointment) => {
          const renderingUser = users.find((user) => user.id === renderingUserId);
          return (
            <Skeleton loading={isLoadingExtraData} paragraph={false}>
              {_.isEmpty(renderingUser) && (
                <Tooltip title="Rendering provider missing">
                  <ExclamationCircleTwoTone twoToneColor="red" style={{ marginRight: 5 }} />
                </Tooltip>
              )}
              <Select
                showSearch
                optionFilterProp="children"
                defaultValue={renderingUserId}
                style={{ width: _.isEmpty(renderingUser) ? "85%" : "100%" }}
                onChange={(value) => {
                  return handleUpdateCompletedAppointment({
                    id: row.id,
                    renderingUserId: value,
                  });
                }}
              >
                {users
                  .sort((a, b) => a.lastName.localeCompare(b.lastName))
                  .map((user, index) => (
                    <Select.Option
                      key={index}
                      value={user.id}
                    >{`${user.firstName} ${user.lastName}`}</Select.Option>
                  ))}
              </Select>
            </Skeleton>
          );
        },
      },
      {
        title: "Billing Provider",
        dataIndex: "billingUserId",
        width: 180,
        render: (billingUserId: string, row: ICompletedAppointment) => {
          const billingUser = users.find((user) => user.id === billingUserId);
          return (
            <Skeleton loading={isLoadingExtraData} paragraph={false}>
              {_.isEmpty(billingUser) && (
                <Tooltip title="Billing provider missing">
                  <ExclamationCircleTwoTone twoToneColor="red" style={{ marginRight: 5 }} />
                </Tooltip>
              )}
              <Select
                showSearch
                optionFilterProp="children"
                defaultValue={billingUserId}
                style={{ width: _.isEmpty(billingUser) ? "85%" : "100%" }}
                onChange={(value) => {
                  return handleUpdateCompletedAppointment({
                    id: row.id,
                    billingUserId: value,
                  });
                }}
              >
                {users
                  .filter(
                    (user) =>
                      user.id === billingUserId || user.permissions.includes(UserPermission.BCBA)
                  )
                  .sort((a, b) => a.lastName.localeCompare(b.lastName))
                  .map((user, index) => (
                    <Select.Option
                      key={index}
                      value={user.id}
                    >{`${user.firstName} ${user.lastName}`}</Select.Option>
                  ))}
              </Select>
            </Skeleton>
          );
        },
      },
      {
        title: "Code",
        dataIndex: "billingCode",
        shouldCellUpdate: (record, prevRecord) => record.billingCode !== prevRecord.billingCode,
        render: (billingCode: BillingCode, row: ICompletedAppointment) => {
          return (
            <>
              <Row>
                {_.isEmpty(billingCode) && (
                  <Tooltip title="Billing code missing">
                    <ExclamationCircleTwoTone twoToneColor="red" style={{ marginRight: 5 }} />
                  </Tooltip>
                )}
                <Select
                  showSearch
                  optionFilterProp="children"
                  defaultValue={billingCode}
                  style={{ width: _.isEmpty(billingCode) ? "85%" : "100%" }}
                  onChange={async (value) => {
                    await handleUpdateCompletedAppointment({
                      id: row.id,
                      billingCode: value,
                    });
                  }}
                >
                  {Object.values(BillingCode).map((code, index) => (
                    <Select.Option key={index} value={code}>
                      {code}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
              <Row style={{ marginTop: 7 }}>
                <Tag
                  color={getBillingCodeColor(billingCode, row.modifiers)}
                  style={{ marginTop: 2, marginBottom: 2 }}
                >
                  {getAppointmentNameFromBilling(clinic.address.state, billingCode, row.modifiers)}
                </Tag>
              </Row>
            </>
          );
        },
      },
      {
        title: "Modifiers",
        dataIndex: "modifiers",
        shouldCellUpdate: (record, prevRecord) =>
          !_.isEqual(record.modifiers, prevRecord.modifiers),
        render: (modifiers: string[], row: ICompletedAppointment) => {
          return (
            <>
              <Select
                value={modifiers}
                mode="multiple"
                style={{ width: _.isEmpty(modifiers) ? "80%" : "100%" }}
                onChange={(value) => {
                  return handleUpdateCompletedAppointment({
                    id: row.id,
                    modifiers: value as Modifier[],
                  });
                }}
              >
                {Object.values(Modifier).map((modifier, index) => (
                  <Select.Option key={index} value={modifier}>
                    {modifier}
                  </Select.Option>
                ))}
              </Select>
            </>
          );
        },
      },
      {
        title: "Location",
        dataIndex: "location",
        shouldCellUpdate: (record, prevRecord) => !_.isEqual(record.location, prevRecord.location),
        render: (location: AppointmentLocation, row: ICompletedAppointment) => {
          return (
            <>
              {_.isEmpty(location) && (
                <Tooltip title="Billing code missing">
                  <ExclamationCircleTwoTone twoToneColor="red" style={{ marginRight: 5 }} />
                </Tooltip>
              )}
              <Select
                showSearch
                optionFilterProp="children"
                defaultValue={location}
                style={{ width: _.isEmpty(location) ? "80%" : "100%" }}
                onChange={(value) => {
                  return handleUpdateCompletedAppointment({
                    id: row.id,
                    location: value,
                  });
                }}
              >
                {Object.values(AppointmentLocation)
                  .sort((a, b) => a.localeCompare(b))
                  .map((location, index) => (
                    <Select.Option key={index} value={location}>
                      {getAppointmentLocationText(location)}
                    </Select.Option>
                  ))}
              </Select>
            </>
          );
        },
      },
      {
        title: "Units",
        dataIndex: "units",
        width: 100,
        shouldCellUpdate: (record, prevRecord) => !_.isEqual(record.units, prevRecord.units),
        render: (units: number, row: ICompletedAppointment) => {
          return (
            <>
              {units === 0 && (
                <Tooltip title="Units is 0">
                  <ExclamationCircleTwoTone twoToneColor="red" style={{ marginRight: 5 }} />
                </Tooltip>
              )}
              <InputNumber
                style={{ width: units === 0 ? "70%" : "100%" }}
                defaultValue={units}
                precision={0}
                min={0}
                onChange={(value) => {
                  if (value !== null) {
                    return handleUpdateCompletedAppointment({
                      id: row.id,
                      units: value,
                    });
                  }
                }}
              />
            </>
          );
        },
      },
      {
        width: 100,
        title: "Charge",
        dataIndex: "chargeCents",
        shouldCellUpdate: (record, prevRecord) =>
          !_.isEqual(record.chargeCents, prevRecord.chargeCents),
        render: (chargeCents: number) => {
          return (
            <div style={{ marginTop: 5 }}>
              {chargeCents === 0 && (
                <Tooltip title="Charge is $0.00">
                  <ExclamationCircleTwoTone twoToneColor="red" style={{ marginRight: 5 }} />
                </Tooltip>
              )}
              <Text type={chargeCents === 0 ? "danger" : undefined}>{`$${(
                chargeCents / 100
              ).toFixed(2)}`}</Text>
            </div>
          );
        },
      },
      {
        width: 85,
        title: "Note",
        dataIndex: "noteId",
        shouldCellUpdate: (record, prevRecord) => !_.isEqual(record.noteId, prevRecord.noteId),
        render: (noteId: string, row: ICompletedAppointment) => {
          return (
            <div style={{ marginTop: 5 }}>
              {!_.isEmpty(noteId) ? (
                <Popover
                  content={
                    <Link
                      to={`/calendar/${moment(row.startMs).year()}-${moment(row.startMs).week()}/${
                        row.id
                      }`}
                    >
                      Go to appointment
                    </Link>
                  }
                >
                  <CheckCircleTwoTone twoToneColor={COLORS.GREEN} />
                </Popover>
              ) : (
                <Tooltip title="Note missing, please contact #product">
                  <ExclamationCircleTwoTone twoToneColor="red" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
    ];

    return tableColumns;
  };

  return (
    <>
      <Space style={{ marginBottom: 20 }}>
        <Tooltip
          title={_.isEmpty(selectedAppointmentIds) && "Select pending appointments to finalize"}
          placement="left"
        >
          <Button
            type="primary"
            onClick={handleFinalize}
            loading={isFinalizing}
            disabled={_.isEmpty(selectedAppointmentIds)}
          >
            {`Finalize (${selectedAppointmentIds.size})`}
          </Button>
        </Tooltip>
        <Button
          onClick={handleFinalizeDryRun}
          loading={isFinalizing}
          disabled={_.isEmpty(selectedAppointmentIds)}
        >
          {`Dry Run Finalize (${selectedAppointmentIds.size})`}
        </Button>
      </Space>
      <Table
        sticky
        columns={getTableColumns()}
        dataSource={appointments}
        loading={isMainLoading || isFinalizing}
        pagination={false}
      />
    </>
  );
};
