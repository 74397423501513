import { IUser } from "@finni-health/shared";
import { Button, DatePicker, Modal, Row, Space } from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useState } from "react";

import { DISPLAY_DATE_FORMAT } from "../../consts";
import { getPayrollHoursCsv } from "../../helpers/payroll";
import * as FirestoreService from "../../services/firestore";
import { useUserClinics } from "../UserClinicsProvider";

interface Props {
  users: IUser[];
  isVisible: boolean;
  hideModal: () => void;
}

export const PayrollExportModal = ({ users, isVisible, hideModal }: Props) => {
  const { clinic } = useUserClinics();

  const [dateRange, setDateRange] = useState<[Moment, Moment]>([
    moment().add(-7, "d").startOf("week"),
    moment().add(-7, "d").endOf("week"),
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSetDateRange = (dates: any) => {
    if (!_.isEmpty(dates)) {
      setDateRange(dates);
    }
  };

  const handleHideModal = () => {
    hideModal();
  };

  const handleGetPayrollHours = async () => {
    setIsLoading(true);
    const startMs = dateRange[0].startOf("day").valueOf();
    const endMs = dateRange[1].endOf("day").valueOf();

    const appts = await FirestoreService.getCompletedAppointmentByClinicAndRange({
      clinicId: clinic.id,
      startMs,
      endMs,
    });

    const indirects = await FirestoreService.getIndirectsForClinicIdAndRange(
      clinic.id,
      startMs,
      endMs
    );

    await getPayrollHoursCsv(users, indirects, appts);

    hideModal();
    setIsLoading(false);
  };

  return (
    <Modal
      title={`Export Payroll Data`}
      onCancel={handleHideModal}
      open={isVisible}
      footer={false}
      width={400}
      style={{ top: 100 }}
    >
      <Row style={{ marginBottom: 25, width: "100%" }} justify="center">
        <DatePicker.RangePicker
          value={dateRange}
          onChange={handleSetDateRange}
          format={DISPLAY_DATE_FORMAT}
          ranges={{
            "This week": [moment().startOf("week"), moment().endOf("week")],
            "Last 2 weeks": [
              moment().add(-14, "d").startOf("week"),
              moment().add(-7, "d").endOf("week"),
            ],
            "Last 30 days": [moment().add(-30, "d"), moment()],
          }}
        />
      </Row>
      <Row style={{ width: "100%" }} justify="end">
        <Space>
          <Button onClick={hideModal} disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleGetPayrollHours} type="primary" loading={isLoading}>
            Export CSV
          </Button>
        </Space>
      </Row>
    </Modal>
  );
};
