import {
  AppointmentLocation,
  BillingCode,
  CANCELLED_APPOINTMENT_TEXT,
  capitalizeFirstLetter,
  EventType,
  getAppointmentNameFromBilling,
  getInitialsFromClient,
  IAppointment,
  ICalendarEvent,
  IClient,
  IClinic,
  ICompletedAppointment,
  IIndirect,
  IndirectReason,
  INote,
  IUser,
  Modifier,
  UserPermission,
  Weekday,
} from "@finni-health/shared";
import moment from "moment";

export interface IInterval {
  startMs: number;
  endMs: number;
}

export type IAppointmentOptions = {
  [apptName: string]: { billingCode: BillingCode; modifiers: Modifier[] };
};

export const isCancelledAppointment = (appt: ICalendarEvent) => {
  if (appt.eventType === EventType.COMPLETED) {
    return (appt as ICompletedAppointment).cancelledAt !== undefined;
  }
  return appt.summary.includes(CANCELLED_APPOINTMENT_TEXT);
};

export const getAppointmentSummary = (
  clinic: IClinic,
  client: IClient,
  billingCode: BillingCode,
  location: AppointmentLocation,
  modifiers: Modifier[] = []
) => {
  const initials = getInitialsFromClient(client);
  const appointmentName = getAppointmentNameFromBilling(
    clinic.address.state,
    billingCode,
    modifiers
  );
  const locationName = location === AppointmentLocation.TELEHEALTH ? "(remote)" : "(in-person)";

  return `${initials} ${appointmentName} ${locationName}`;
};

export const getEarliestAppointmentTime = () => {
  const start = moment();
  const remainder = 15 - (start.minute() % 15);
  const earliestAppointmentTime = moment(start).add(remainder, "minutes");

  return earliestAppointmentTime;
};

export const getAppointmentLocationText = (appointmentLocation: AppointmentLocation) => {
  switch (appointmentLocation) {
    case AppointmentLocation.HOME:
      return "Home";
    case AppointmentLocation.OFFICE:
      return "Office";
    case AppointmentLocation.TELEHEALTH:
      return "Telehealth";
    case AppointmentLocation.SCHOOL:
      return "School";
    case AppointmentLocation.OTHER:
      return "Community";
    default:
      return "Unknown";
  }
};

export const getReasonText = (reason: string) => {
  const abbreviations = {
    Rbt: "RBT",
    Bcba: "BCBA",
    Cpr: "CPR",
    Hipaa: "HIPAA",
  };

  let text = reason
    .split("_")
    .map((substring: string) => capitalizeFirstLetter(substring.toLowerCase()))
    .join(" ");

  text = Object.entries(abbreviations).reduce((acc: string, [key, value]: [string, string]) => {
    return acc.replace(key, value);
  }, text);

  return text;
};

export const getIndirectPromptText = (reason: IndirectReason) => {
  switch (reason) {
    case IndirectReason.RBT_TRAINING:
      return "Describe which module(s) were completed";
    case IndirectReason.OTHER_TRAINING:
      return "Describe which training was completed";
    case IndirectReason.SHADOW_SESSION:
      return "Describe what the session was for and who else attended it";
    case IndirectReason.MATERIALS_MAKING:
      return "Describe what materials were made";
    default:
      return "Describe what you plan to accomplish";
  }
};

export const noteRequiresParentSignature = (note: INote) => {
  return ["Therapist Session Note", "Tricare - Therapist Session Note"].includes(note.noteType);
};

export const noteRequiresLibraryData = (note: INote) => {
  return ["Therapist Session Note", "Tricare - Therapist Session Note"].includes(note.noteType);
};

export const isNoteApprovable = (note: INote) => {
  let approvable = note.narrative && note.narrative.length > 0 && note.providerSignedMs > 0;

  if (noteRequiresParentSignature(note)) {
    approvable = approvable && note.clientSignedMs > 0;
  }

  if (noteRequiresLibraryData(note)) {
    approvable =
      approvable &&
      note.behaviorData &&
      note.behaviorData.length > 0 &&
      note.targetData &&
      note.targetData.length > 0;
  }

  return approvable;
};

export const addUntilRrule = (mainAppointment: IAppointment, untilInstance: IAppointment) => {
  // Add new UNTIL rule for 1 minute before the instance start time to delete 'this and all future'
  const untilRule = {
    key: "UNTIL",
    value: moment.utc(untilInstance.startMs).subtract(1, "minute").format("YYYYMMDD[T]HHMMSS[Z]"),
  };

  // Parse existing rules into a map
  const rrulesMap = parseRRule(mainAppointment.rrule || "");

  // Replace or add the new UNTIL rule
  const untilRuleIndex = rrulesMap.findIndex((rrule) => rrule.key === "UNTIL");
  if (untilRuleIndex !== -1) {
    rrulesMap[untilRuleIndex] = untilRule;
  } else {
    rrulesMap.push(untilRule);
  }

  return {
    ...mainAppointment,
    rrule: `RRULE:${rrulesMap.map((rrule) => `${rrule.key}=${rrule.value}`).join(";")}`,
  };
};

export const getByDayRrule = (mainAppointment: IAppointment, day: Weekday) => {
  const byDayRule = {
    key: "BYDAY",
    value: day.toUpperCase().substring(0, 2),
  };

  // Parse existing rules into a map
  const rrulesMap = parseRRule(mainAppointment.rrule || "");

  // Replace the rrule day if appointment weekday is changed
  const byDayRuleIndex = rrulesMap.findIndex((rrule) => rrule.key === "BYDAY");
  if (byDayRuleIndex) {
    rrulesMap[byDayRuleIndex] = byDayRule;
  } else {
    rrulesMap.push(byDayRule);
  }

  return `RRULE:${rrulesMap.map((rrule) => `${rrule.key}=${rrule.value}`).join(";")}`;
};

export const parseRRule = (rrule: string) => {
  // Parse existing rules into a map
  const rrules = rrule.slice(6).split(";") || [];
  const rrulesMap = rrules?.map((rrule) => {
    const keyValue = rrule.split("=");
    return { key: keyValue[0], value: keyValue[1] };
  });

  return rrulesMap;
};

export const removeIntervalOverlaps = (intervals: IInterval[]): IInterval[] => {
  intervals.sort(eventSortComparator);

  let currInterval: IInterval | undefined = undefined;
  const result: IInterval[] = [];
  for (const event of intervals) {
    if (currInterval === undefined) {
      currInterval = {
        startMs: event.startMs,
        endMs: event.endMs,
      };
    } else if (event.startMs > currInterval.endMs) {
      // Close the old interval and open a new one
      result.push(currInterval);
      currInterval = {
        startMs: event.startMs,
        endMs: event.endMs,
      };
    } else if (event.endMs > currInterval.endMs) {
      // Event startMs <= currInterval.endMs lengthen the interval
      currInterval.endMs = event.endMs;
    }
  }
  if (currInterval) {
    result.push(currInterval);
  }
  return result;
};

/**
 * Flatten a list of intervals into a list of points
 *
 * @param intervals the list of intervals to flatten
 * @returns the list of points
 */
const flattenIntervals = (intervals: IInterval[]) => {
  return intervals.reduce(
    (agg: number[], interval: IInterval) => agg.concat([interval.startMs, interval.endMs]),
    []
  );
};

/**
 * Unflatten a list of points into a list of intervals
 *
 * @param endpoints the list of points to unflatten
 * @returns the list of intervals
 */
const unflattenIntervals = (endpoints: number[]) => {
  const intervals: IInterval[] = [];
  for (let i = 0; i < endpoints.length - 1; i += 2) {
    const startMs = endpoints[i];
    const endMs = endpoints[i + 1];
    intervals.push({ startMs, endMs });
  }
  return intervals;
};

/**
 * Returns the difference of two lists of intervals
 *
 * (Note that the endpoints of each interval list are sorted because we
 * require that the intervals not overlap.)
 *
 * Simultaneously loop over the endpoints of both arguments, in order.
 * For each endpoint discovered, decide whether it is in the result or not.
 * If the result currently has an odd number of endpoints and the new
 * endpoint is not in the result, add it to the result; similarly, if
 * the result currently has an even number of endpoints and the new
 * endpoint is in the result, add it to the result. At the end of this
 * operation, the result is a list of endpoints, alternating between
 * interval start and interval end.
 *
 *
 * @param a the list of intervals to subtract from
 * @param b the list of intervals to subtract
 * @returns intervals in a that are not in b
 */
export const intervalDiff = (a: IInterval[], b: IInterval[]) => {
  if (!b.length) {
    return a;
  }

  const aEndpoints = flattenIntervals(a);
  const bEndpoints = flattenIntervals(b);

  const sentinel =
    Math.max(aEndpoints[aEndpoints.length - 1], bEndpoints[bEndpoints.length - 1]) + 1;
  aEndpoints.push(sentinel);
  bEndpoints.push(sentinel);

  let aIndex = 0;
  let bIndex = 0;

  const res: number[] = [];

  let scan = Math.min(aEndpoints[0], bEndpoints[0]);
  while (scan < sentinel) {
    const inA = !(+(scan < aEndpoints[aIndex]) ^ aIndex % 2);
    const inB = !(+(scan < bEndpoints[bIndex]) ^ bIndex % 2);
    const inRes = inA && !inB;

    if (+inRes ^ res.length % 2) res.push(scan);
    if (scan === aEndpoints[aIndex]) aIndex++;
    if (scan === bEndpoints[bIndex]) bIndex++;
    scan = Math.min(aEndpoints[aIndex], bEndpoints[bIndex]);
  }

  return unflattenIntervals(res);
};

/**
 * Get the direct and indirect intervals for a user, removing overlaps,
 * And ensuring that indirects which overlap with a direct are adjusted
 * such that the overlap is removed.
 *
 * @param appts an array of GCal appointments, completed appointments, or indirects
 * @param user the user that these appointments belong to
 * @returns an object with direct and indirect intervals
 */
export const getDirectAndIndirectIntervals = (
  appts: (IAppointment | ICompletedAppointment | IIndirect)[],
  user: IUser,
  ignoreCancellations = false
) => {
  let directIntervals: IInterval[] = [];
  let indirectIntervals: IInterval[] = [];

  appts.sort(eventSortComparator);

  for (let appt of appts) {
    appt = appt as ICompletedAppointment;

    if (appt.eventType === EventType.INDIRECT) {
      // if indirect
      indirectIntervals.push({
        startMs: (appt as any).startMs,
        endMs: (appt as any).endMs,
      });
    } else if (ignoreCancellations || !isCancelledAppointment(appt)) {
      // if not cancelled completedAppointment
      if (user.permissions?.includes(UserPermission.BCBA)) {
        directIntervals.push({
          startMs: appt.startMs,
          endMs: appt.endMs,
        });
      } else if ([BillingCode.CODE_T1026, BillingCode.CODE_97155].includes(appt.billingCode)) {
        indirectIntervals.push({
          startMs: appt.startMs,
          endMs: appt.endMs,
        });
      } else {
        directIntervals.push({
          startMs: appt.startMs,
          endMs: appt.endMs,
        });
      }
    }
  }

  directIntervals = removeIntervalOverlaps(directIntervals);
  indirectIntervals = removeIntervalOverlaps(indirectIntervals);

  indirectIntervals = intervalDiff(indirectIntervals, directIntervals);

  return { directIntervals, indirectIntervals };
};

/**
 * Sort by end times and then start times to get all the appointments in an increasing time order
 */
const eventSortComparator = (
  a: { startMs: number; endMs: number },
  b: { startMs: number; endMs: number }
) => {
  if (a.startMs == b.startMs) {
    return a.endMs < b.endMs ? -1 : 1;
  }
  return a.startMs < b.startMs ? -1 : 1;
};
