import {
  AttendeeStatus,
  BillingCode,
  IAppointment,
  IApprovals,
  IApproveCancelledCompletedAppointmentEndpointRequest,
  IBatchBillEndpointRequest,
  IBilledAppointment,
  ICalendarCreateAppointmentEndpointRequest,
  ICalendarDeleteAppointmentEndpointRequest,
  ICalendarGetAppointmentEndpointRequest,
  ICalendarGetAppointmentsForClinicIdAndWeekEndpointRequest,
  ICalendarGetAppointmentsForUserAndRangeEndpointRequest,
  ICalendarUpdateAppointmentEndpointRequest,
  IClient,
  IClientDetails,
  IClientFile,
  IClientGuardianDetails,
  IClinic,
  ICompletedAppointment,
  IConvertInquiryEndpointRequest,
  ICreateCancelledCompletedAppointmentEndpointRequest,
  ICreateClientEndpointRequest,
  ICreateCompletedAppointmentEndpointRequest,
  ICreateIndirectEndpointRequest,
  ICreateInquiryEndpointRequest,
  ICreateInviteRequest,
  ICreateUserEndpointRequest,
  ICreateUserPayRateEndpointRequest,
  IDeleteCompletedAppointmentEndpointRequest,
  IDeleteIndirectEndpointRequest,
  IDeleteInviteEndpointRequest,
  IDeleteUserEndpointRequest,
  IDeleteUserPayRateEndpointRequest,
  IGetBilledAppointmentsForClinicAndRangeEndpointRequest,
  IGetCompletedAppointmentsForClinicAndRangeEndpointRequest,
  IGetCompletedAppointmentsForClinicClientAndRangeEndpointRequest,
  IGetIndirectsForWeekAndClinicIdEndpointRequest,
  IGetLatestUserAvailabilityByUserIdEndpointRequest,
  IGuardian,
  IIndirect,
  IInquiry,
  IInvite,
  IMarkExportedBilledAppointmentsEndpointRequest,
  INote,
  IRejectCancelledCompletedAppointmentEndpointRequest,
  IUpdateClientAvailabilityEndpointRequest,
  IUpdateClientEndpointRequest,
  IUpdateClientFileEndpointRequest,
  IUpdateClinicEndpointRequest,
  IUpdateCompletedAppointmentEndpointRequest,
  IUpdateGuardianEndpointRequest,
  IUpdateIndirectEndpointRequest,
  IUpdateInquiryEndpointRequest,
  IUpdateInviteEndpointRequest,
  IUpdateUserAvailabilityEndpointRequest,
  IUpdateUserClinicIdEndpointRequest,
  IUpdateUserEndpointRequest,
  IUpdateUserPayRateEndpointRequest,
  IUpdateWorkingHoursRequest,
  IUser,
  IUserPayRate,
  MASTER_CLINIC_ID,
  Modifier,
} from "@finni-health/shared";
import { UserPermission } from "@finni-health/shared";
import {
  collection,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import _ from "lodash";
import moment from "moment";

import * as backend from "./backend";
import app from "./firebase";
import { cacheManager, handleResponse } from "./middleware";

export const db = getFirestore(app);

export const ENDPOINTS = {
  getCreateAppointmentEndpoint: () => backend.endpoint("/appointments/create", "post"),
  getGetAppointmentByIdEndpoint: () => backend.endpoint("/appointments/get", "post"),
  getGetAppointmentsForWeekEndpoint: () => backend.endpoint("/appointments/get-for-week", "post"),
  getGetAppointmentsForUserRangeEndpoint: () =>
    backend.endpoint("/appointments/get-for-user-range", "post"),
  getUpdateAppointmentEndpoint: () => backend.endpoint("/appointments/update", "post"),
  getDeleteAppointmentEndpoint: () => backend.endpoint("/appointments/delete", "post"),
  getCreateClientEndpoint: () => backend.endpoint("/clients/create", "post"),
  getUpdateClientEndpoint: () => backend.endpoint("/clients/update", "post"),
  getUpdateClinicEndpoint: () => backend.endpoint("/clinics/update", "post"),
  getGetAllClinicsEndpoint: () => backend.endpoint("/clinics/get-all", "post"),
  getGetClinicByAccessCodeEndpoint: () => backend.endpoint("/clinics/getByAccessCode", "post"),
  getBatchBillEndpoint: () => backend.endpoint("/billing/batch-bill", "post"),
  getBatchBillDryRunEndpoint: () => backend.endpoint("/billing/batch-bill-dry-run", "post"),
  getCreateIndirectEndpoint: () => backend.endpoint("/indirects/create", "post"),
  getUpdateIndirectEndpoint: () => backend.endpoint("/indirects/update", "post"),
  getGetIndirectEndpoint: () => backend.endpoint("/indirects/get", "post"),
  getGetIndirectForWeekAndClinicIdEndpoint: () =>
    backend.endpoint("/indirects/get-for-week-and-clinic-id", "post"),
  getDeleteIndirectEndpoint: () => backend.endpoint("/indirects/delete", "post"),
  getCreateInquiryEndpoint: () => backend.endpoint("/inquiries/create", "post"),
  getUpdateInquiryEndpoint: () => backend.endpoint("/inquiries/update", "post"),
  getDeleteInquiryEndpoint: () => backend.endpoint("/inquiries/delete", "post"),
  getValidateGuardianEmailEndpoint: () => backend.endpoint("/guardians/validate-email", "post"),
  getUpdateGuardianEndpoint: () => backend.endpoint("/guardians/update", "post"),
  getGuardianClientDetailsEndpoint: () =>
    backend.endpoint("/guardians/get-with-client-details", "post"),
  getUpdateClientFileEndpoint: () => backend.endpoint("/client-files/update", "post"),
  getConvertToPrequalifiedGuardianEndpoint: () =>
    backend.endpoint("/intake/convert-to-prequalified-guardian", "post"),
  getCreateUserEndpoint: () => backend.endpoint("/users/create", "post"),
  getUpdateUserEndpoint: () => backend.endpoint("/users/update", "post"),
  getUpdateUserClinicIdEndpoint: () => backend.endpoint("/users/update-clinic-id", "post"),
  getDeleteUserEndpoint: () => backend.endpoint("/users/delete", "post"),
  getApproveNoteEndpoint: () => backend.endpoint("/motivity/put", "put"),
  getUpdateNoteEndpoint: () => backend.endpoint("/notes/update", "post"),
  getDeleteNoteEndpoint: () => backend.endpoint("/notes/delete", "post"),
  getMarkExportedBilledAppointmentEndpoint: () =>
    backend.endpoint("/billed-appointments/mark-exported", "post"),
  getCreateCompletedAppointmentEndpoint: () =>
    backend.endpoint("/completed-appointments/create", "post"),
  getCreateCancelledCompletedAppointmentEndpoint: () =>
    backend.endpoint("/completed-appointments/create-cancelled", "post"),
  getGetForClinicAndRangeCompletedAppointments: () =>
    backend.endpoint("/completed-appointments/get-for-clinic-and-range", "post"),
  getGetForClinicClientAndRangeCompletedAppointments: () =>
    backend.endpoint("/completed-appointments/get-for-clinic-client-and-range", "post"),
  getUpdateCompletedAppointmentEndpoint: () =>
    backend.endpoint("/completed-appointments/update", "post"),
  getDeleteCompletedAppointmentEndpoint: () =>
    backend.endpoint("/completed-appointments/delete", "post"),
  getGetMotivityNoteEndpoint: () => backend.endpoint("/motivity/get", "post"),
  getCreateInviteEndpoint: () => backend.endpoint("/invites/create", "post"),
  getUpdateInviteEndpoint: () => backend.endpoint("/invites/update", "post"),
  getDeleteInviteEndpoint: () => backend.endpoint("/invites/delete", "post"),
  getInvitesByAuthEmail: () => backend.endpoint("/invites/get", "post"),
  getApproveNoteWebSocketEndpoint: () => backend.endpoint("/motivity/putUUID", "put"),
  getCreateUpdateWorkingHoursRequest: () =>
    backend.endpoint("/update-working-hours/create", "post"),
  getApproveUpdateWorkingHoursRequest: () =>
    backend.endpoint("/update-working-hours/approve", "post"),
  getDenyUpdateWorkingHoursRequest: () => backend.endpoint("/update-working-hours/deny", "post"),
  getCreateUserPayRateEndpoint: () => backend.endpoint("/user-pay-rates/create", "post"),
  getUpdateUserPayRateEndpoint: () => backend.endpoint("/user-pay-rates/update", "post"),
  getDeleteUserPayRateEndpoint: () => backend.endpoint("/user-pay-rates/delete", "post"),
  getCreateMotivityClientRequest: () => backend.endpoint("/motivity/createClient", "post"),
  getApproveCancelledCompletedAppointmentEndpoint: () =>
    backend.endpoint("/completed-appointments/accept-cancellation", "post"),
  getRejectCancelledCompletedAppointmentEndpoint: () =>
    backend.endpoint("/completed-appointments/reject-cancellation", "post"),
  getClientDetailsEndpoint: () => backend.endpoint("/client-details", "post"),
  getClientGuardianDetailsEndpoint: () => backend.endpoint("/client-guardian-details", "post"),
  getApprovalsEndpoint: () => backend.endpoint("/approvals", "post"),
  getGetClientAvailabilityByClientFileIdEndpoint: () =>
    backend.endpoint("/client-availabilities/get-latest-by-client-file-id", "post"),
  getUpdateClientAvailabilityEndpoint: () =>
    backend.endpoint("/client-availabilities/update", "post"),
  getGetUserAvailabilityByUserIdEndpoint: () =>
    backend.endpoint("/user-availabilities/get-latest-by-user-id", "post"),
  getUpdateUserAvailabilityEndpoint: () => backend.endpoint("/user-availabilities/update", "post"),
};

export const createInquiry = async (req: ICreateInquiryEndpointRequest) => {
  try {
    const createInquiry = ENDPOINTS.getCreateInquiryEndpoint();
    const result = await createInquiry(req);

    return handleResponse(result.id);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateInquiry = async (req: IUpdateInquiryEndpointRequest) => {
  try {
    const updateInquiry = ENDPOINTS.getUpdateInquiryEndpoint();
    const result = await updateInquiry(req);

    return handleResponse(result.id);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInquiryById = async (id: string): Promise<IInquiry> => {
  try {
    const result = await getDoc(doc(db, "inquiries", id));
    const inquiry = result.data() as IInquiry;
    if (!inquiry) return Promise.reject("Inquiry not found");
    if (inquiry?.deletedAt) return Promise.reject("Inquiry has been deleted");
    return handleResponse(inquiry);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createUser = async (req: ICreateUserEndpointRequest) => {
  try {
    const createUser = ENDPOINTS.getCreateUserEndpoint();
    await createUser(req);
    return true;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUser = async (req: IUpdateUserEndpointRequest) => {
  try {
    const updateUser = ENDPOINTS.getUpdateUserEndpoint();
    await updateUser(req);
    return true;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUserClinicId = async (req: IUpdateUserClinicIdEndpointRequest) => {
  try {
    const updateUserClinicId = ENDPOINTS.getUpdateUserClinicIdEndpoint();
    await updateUserClinicId(req);
    return true;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteUser = async (req: IDeleteUserEndpointRequest) => {
  try {
    const deleteUser = ENDPOINTS.getDeleteUserEndpoint();
    await deleteUser(req);
    return true;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserById = async (uid: string) => {
  try {
    const result = await getDoc(doc(db, "users", uid));

    const user = result.data() as IUser;

    return handleResponse(user);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserAvailabilityByUserId = async (
  req: IGetLatestUserAvailabilityByUserIdEndpointRequest
) => {
  try {
    const getUserAvailabilityByUserId = ENDPOINTS.getGetUserAvailabilityByUserIdEndpoint();
    const result = await getUserAvailabilityByUserId({
      userId: req.userId,
      clinicId: req.clinicId,
    });

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUserAvailability = async (req: IUpdateUserAvailabilityEndpointRequest) => {
  try {
    const updateUserAvailability = ENDPOINTS.getUpdateUserAvailabilityEndpoint();
    const result = await updateUserAvailability(req);

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createUserPayRate = async (req: ICreateUserPayRateEndpointRequest) => {
  try {
    const createUserPayRate = ENDPOINTS.getCreateUserPayRateEndpoint();
    return handleResponse(await createUserPayRate(req));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUserPayRate = async (req: IUpdateUserPayRateEndpointRequest) => {
  try {
    const updateUserPayRate = ENDPOINTS.getUpdateUserPayRateEndpoint();
    return handleResponse(await updateUserPayRate(req));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteUserPayRate = async (req: IDeleteUserPayRateEndpointRequest) => {
  try {
    const deleteUserPayRate = ENDPOINTS.getDeleteUserPayRateEndpoint();
    return handleResponse(await deleteUserPayRate(req));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserPayRatesByUserIdAndClinicId = async (userId: string, clinicId: string) => {
  try {
    const result = await getDocs(
      query(
        collection(doc(db, "users", userId), "user-pay-rates"),
        where("clinicId", "==", clinicId)
      )
    );

    let userPayRates: IUserPayRate[] = [];
    result.forEach((doc) => userPayRates.push(doc.data() as IUserPayRate));
    userPayRates = userPayRates.filter((userPayRate) => !userPayRate?.deletedAt);

    return handleResponse(userPayRates);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserPayRatesByUserIdAndEpochMs = async (userId: string, epochMs: number) => {
  try {
    const result = await getDocs(
      query(
        collection(doc(db, "users", userId), "user-pay-rates"),
        where("startMs", "<=", epochMs),
        orderBy("startMs", "desc"),
        limit(1)
      )
    );

    let userPayRates: IUserPayRate[] = [];
    result.forEach((doc) => userPayRates.push(doc.data() as IUserPayRate));
    userPayRates = userPayRates.filter((userPayRate) => !userPayRate?.deletedAt);

    if (userPayRates.length !== 1) {
      return Promise.reject(
        `Unexpected number of userPayRates (${userPayRates.length}) found for userId (${userId}) and epochMs (${epochMs})`
      );
    }

    return handleResponse(userPayRates[0]);
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAllUsersByIds = async (userIds: string[]) => {
  try {
    const usersCollection = collection(db, "users");
    const batches: Promise<IUser[]>[] = [];

    while (userIds.length) {
      // firestore limits batches to 10
      const batch = userIds.splice(0, 10);

      batches.push(
        getDocs(query(usersCollection, where("id", "in", batch))).then((results) =>
          results.docs.map((doc) => doc.data() as IUser)
        )
      );
    }

    let users = (await Promise.all(batches)).flat();

    users = users.filter((user) => !user?.deletedAt);

    return handleResponse(users);
  } catch (err) {
    console.log(err);
    return Promise.reject(`No users found for ids [${userIds}]`);
  }
};

export const getAllUsersForClinic = async (clinicId: string, force = false) => {
  try {
    return handleResponse(
      await cacheManager(
        "getAllUsersForClinic",
        [clinicId],
        force ? 0 : 15 * 60 * 1000,
        async () => {
          const result = await getDocs(
            query(collection(db, "users"), where("clinicId", "==", clinicId))
          );
          let users: IUser[] = [];
          result.forEach((doc) => users.push(doc.data() as IUser));

          users = users.filter((user) => !user?.deletedAt);
          if (users.length === 0) {
            return Promise.reject(`No users found for clinic (${clinicId})`);
          }
          return users;
        }
      )
    ) as IUser[];
  } catch {
    return Promise.reject("No users found for clinic");
  }
};

export const getClinicById = async (id: string) => {
  try {
    const getClinicReq = await getDoc(doc(db, "clinics", id));
    const clinic = getClinicReq.data() as IClinic;
    if (!clinic) return Promise.reject("Clinic not found");
    if (clinic?.deletedAt) return Promise.reject("Clinic has been deleted");
    return handleResponse(clinic);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAllClinics = async () => {
  try {
    const getAllClinics = ENDPOINTS.getGetAllClinicsEndpoint();
    const result = await getAllClinics({});
    return handleResponse(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getClinicByAccessCode = async (accessCode: string) => {
  try {
    const getClinicByAccessCode = ENDPOINTS.getGetClinicByAccessCodeEndpoint();
    const clinic = await getClinicByAccessCode({ accessCode });

    if (!clinic || _.isEmpty(clinic)) {
      return Promise.reject(`No clinic found with access code ${accessCode}`);
    }

    return handleResponse(clinic);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateClinic = async (req: IUpdateClinicEndpointRequest) => {
  try {
    const updateClinic = ENDPOINTS.getUpdateClinicEndpoint();
    const result = await updateClinic(req);

    return handleResponse(result.id);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createClient = async (req: ICreateClientEndpointRequest) => {
  try {
    const createClient = ENDPOINTS.getCreateClientEndpoint();
    const result = await createClient(req);

    return handleResponse(result.id);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateClient = async (req: IUpdateClientEndpointRequest) => {
  try {
    const updateClient = ENDPOINTS.getUpdateClientEndpoint();
    const result = await updateClient(req);

    return handleResponse(result.id);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createMotivityClient = async (
  alias: string,
  fullName: string,
  dateOfBirth: string
) => {
  try {
    const createMotivityClient = ENDPOINTS.getCreateMotivityClientRequest();
    const result = await createMotivityClient({ alias, fullName, dateOfBirth });

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const isGuardianEmailAvailable = async (email: string) => {
  try {
    const validateEmailEndpoint = ENDPOINTS.getValidateGuardianEmailEndpoint();
    const validateRes = await validateEmailEndpoint({ email });

    return handleResponse(validateRes.emailAvailable);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateGuardian = async (req: IUpdateGuardianEndpointRequest) => {
  try {
    const updateGuardian = ENDPOINTS.getUpdateGuardianEndpoint();
    const result = await updateGuardian(req);

    return handleResponse(result.id);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getGuardianClientDetailsByGuardianId = async (guardianId: string) => {
  try {
    const getGuardianClientDetails = ENDPOINTS.getGuardianClientDetailsEndpoint();
    const result = await getGuardianClientDetails({ guardianId });

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getGuardianClientDetailsByClinicId = async (clinicId: string) => {
  try {
    const getGuardianClientDetails = ENDPOINTS.getGuardianClientDetailsEndpoint();
    const result = await getGuardianClientDetails({ clinicId });

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getGuardianById = async (id: string): Promise<IGuardian> => {
  try {
    const result = await getDoc(doc(db, "guardians", id));
    const guardian = result.data() as IGuardian;
    if (guardian?.deletedAt) return Promise.reject("Guardian has been deleted");
    if (!guardian) return Promise.reject("Guardian not found");
    return handleResponse(guardian);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllGuardiansForClinic = async (
  clinicId: string,
  force = false
): Promise<IGuardian[]> => {
  try {
    return handleResponse(
      await cacheManager(
        "getAllGuardiansForClinic",
        [clinicId],
        force ? 0 : 15 * 60 * 1000,
        async () => {
          const result = await getDocs(
            query(
              collection(db, "guardians"),
              where("clinicId", "==", clinicId),
              orderBy("createdAt", "desc")
            )
          );
          let guardians: IGuardian[] = [];
          result.forEach((doc) => guardians.push(doc.data() as IGuardian));

          guardians = guardians.filter((guardian) => !guardian?.deletedAt);
          return guardians;
        }
      )
    ) as IGuardian[];
  } catch {
    return Promise.reject("Unable to retrieve guardians for clinic");
  }
};

export const getAllInquiriesForClinic = async (
  clinicId: string,
  state?: string
): Promise<IInquiry[]> => {
  try {
    const clinicInquiries = await getDocs(
      query(collection(db, "inquiries"), where("clinicId", "==", clinicId))
    );

    // keep old inquiries without state and potential new ones that could come up
    const outOfStateUnsupportedInquiries = await getDocs(
      query(
        collection(db, "inquiries"),
        where("clinicId", "==", MASTER_CLINIC_ID),
        where("address.state", "==", "")
      )
    );

    const inStateUnsupportedInquiries = await getDocs(
      query(
        collection(db, "inquiries"),
        where("clinicId", "==", MASTER_CLINIC_ID),
        where("address.state", "==", state)
      )
    );

    let inquiries = [
      ...clinicInquiries.docs,
      ...inStateUnsupportedInquiries.docs,
      ...outOfStateUnsupportedInquiries.docs,
    ].map((docSnapshot: DocumentSnapshot) => docSnapshot.data()) as IInquiry[];

    inquiries = inquiries.filter((inquiry) => !inquiry?.deletedAt);
    inquiries.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return handleResponse(inquiries);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteInquiry = async (id: string): Promise<string> => {
  try {
    const deleteInquiry = ENDPOINTS.getDeleteInquiryEndpoint();
    const result = deleteInquiry({ id });

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

//TODO: route to backend
export const deleteGuardian = async (guardianId: string): Promise<string> => {
  try {
    const ref = doc(db, "guardians", guardianId);

    await updateDoc(ref, {
      updatedAt: serverTimestamp(),
      deletedAt: serverTimestamp(),
    });

    return handleResponse(guardianId);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAppointment = async (
  req: ICalendarCreateAppointmentEndpointRequest
): Promise<string> => {
  try {
    const createAppointment = ENDPOINTS.getCreateAppointmentEndpoint();
    const appointmentId = await createAppointment(req);
    return handleResponse(appointmentId);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAppointmentById = async (
  req: ICalendarGetAppointmentEndpointRequest
): Promise<IAppointment> => {
  try {
    const getAppointmentById = ENDPOINTS.getGetAppointmentByIdEndpoint();
    const appointment = await getAppointmentById(req);

    return handleResponse(appointment);
  } catch (error) {
    return Promise.reject(error);
  }
};

//TODO: Cache this
export const getAppointmentsForClinicIdAndWeek = async (
  req: ICalendarGetAppointmentsForClinicIdAndWeekEndpointRequest
): Promise<IAppointment[]> => {
  try {
    const getAppointmentsForWeek = ENDPOINTS.getGetAppointmentsForWeekEndpoint();
    const appointments = await getAppointmentsForWeek(req);

    return handleResponse(appointments);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAppointmentsForUserAndRange = async (
  req: ICalendarGetAppointmentsForUserAndRangeEndpointRequest
): Promise<IAppointment[]> => {
  try {
    const getAppointmentsForUserAndRange = ENDPOINTS.getGetAppointmentsForUserRangeEndpoint();
    const appointments = await getAppointmentsForUserAndRange(req);

    return handleResponse(appointments);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAppointment = async (req: ICalendarUpdateAppointmentEndpointRequest) => {
  try {
    const updateAppointment = ENDPOINTS.getUpdateAppointmentEndpoint();
    await updateAppointment(req);
    return true;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAppointment = async (req: ICalendarDeleteAppointmentEndpointRequest) => {
  try {
    const deleteAppointment = ENDPOINTS.getDeleteAppointmentEndpoint();
    await deleteAppointment(req);
    return true;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const convertToPrequalifiedGuardian = async (req: IConvertInquiryEndpointRequest) => {
  try {
    const convertToPrequalifiedGuardian = ENDPOINTS.getConvertToPrequalifiedGuardianEndpoint();
    await convertToPrequalifiedGuardian({ req });
    return true;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientDetailsByClientId = async (clientId: string) => {
  const getClientDetails = ENDPOINTS.getClientDetailsEndpoint();
  const clientDetails = await getClientDetails({ clientId });

  return handleResponse<IClientDetails>(clientDetails);
};

export const getClientsDetailsByClientIds = async (clientIds: string[]) => {
  const getClientDetails = ENDPOINTS.getClientDetailsEndpoint();
  const clientDetails = await getClientDetails({ clientIds });

  return handleResponse<IClientDetails[]>(clientDetails);
};

export const getClientsDetailsByClinicId = async (clinicId: string, force = false) => {
  const getClientDetails = ENDPOINTS.getClientDetailsEndpoint();
  const clientDetails = await cacheManager(
    "getClientsDetailsByClinicId",
    [clinicId],
    force ? 0 : 10 * 60 * 1000,
    async () => {
      return await getClientDetails({ clinicId });
    }
  );
  return handleResponse<IClientDetails[]>(clientDetails);
};

export const getClientDetailsByGuardianId = async (guardianId: string) => {
  const getClientDetails = ENDPOINTS.getClientDetailsEndpoint();
  const clientDetails = await getClientDetails({ guardianId });

  return handleResponse<IClientDetails>(clientDetails);
};

export const getClientsGuardiansDetailsByClinicId = async (clinicId: string, force = false) => {
  const getClientGuardianDetails = ENDPOINTS.getClientGuardianDetailsEndpoint();
  const clientGuardianDetails = await cacheManager(
    "getClientsGuardiansDetailsByClinicId",
    [clinicId],
    force ? 0 : 10 * 60 * 1000,
    async () => {
      return await getClientGuardianDetails({ clinicId });
    }
  );
  return handleResponse<IClientGuardianDetails[]>(clientGuardianDetails);
};

export const getClientGuardianDetailsByClientId = async (clientId: string) => {
  const getClientGuardianDetails = ENDPOINTS.getClientGuardianDetailsEndpoint();
  const clientGuardianDetails = await getClientGuardianDetails({ clientId });
  return handleResponse<IClientGuardianDetails>(clientGuardianDetails);
};

export const updateClientFile = async (req: IUpdateClientFileEndpointRequest) => {
  try {
    const updateClientFile = ENDPOINTS.getUpdateClientFileEndpoint();
    const result = await updateClientFile(req);

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientAvailabilityByClientFileId = async (
  clientFileId: string,
  clinicId: string
) => {
  try {
    const getClientAvailabilityByClientFileId =
      ENDPOINTS.getGetClientAvailabilityByClientFileIdEndpoint();
    const result = await getClientAvailabilityByClientFileId({ clientFileId, clinicId });

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateClientAvailability = async (req: IUpdateClientAvailabilityEndpointRequest) => {
  try {
    const updateClientAvailability = ENDPOINTS.getUpdateClientAvailabilityEndpoint();
    const result = await updateClientAvailability(req);

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNoteById = async (id: string): Promise<INote> => {
  try {
    const result = await getDoc(doc(db, "notes", id));
    const note = result.data() as INote;

    return handleResponse(note);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Micro-cache for notes
let notesStore: INote[] = [];
export const getNotesForRangeAndClinic = async (
  startMs: number,
  endMs: number,
  clinicId: string,
  force = false
) => {
  return handleResponse(
    await cacheManager(
      "getNotesForRangeAndClinic",
      [startMs, endMs, clinicId],
      force ? 0 : 10 * 60 * 1000,
      async () => {
        const result = await getDocs(
          query(
            collection(db, "notes"),
            where("clinicId", "==", clinicId),
            where("startMs", ">=", startMs),
            where("startMs", "<=", endMs)
          )
        );

        const notes = result.docs.reduce((acc, doc) => {
          const note = doc.data() as INote;
          if (!note?.deletedAt) {
            acc.push(note);
          }
          return acc;
        }, [] as Array<INote>);

        //also store into notesStore
        if (force) notesStore = [];
        const notesClone = _.cloneDeep(notesStore);
        notesClone.push(...notes);
        _.reverse(notesClone);
        notesStore = _.uniqBy(notesClone, "id");

        return notes;
      }
    )
  ) as INote[];
};

export const getAllNotesByAppointmentId = async (
  id: string,
  clinicId: string,
  force = false
): Promise<INote[]> => {
  return handleResponse(
    await cacheManager(
      "getAllNotesByAppointmentId",
      [id, clinicId],
      force ? 0 : 10 * 60 * 1000,
      async () => {
        //return from noteStore if possible
        if (!force) {
          const notesInCache = notesStore.filter((note) => note.appointmentId === id);
          if (notesInCache.length > 0) return notesInCache;
        }

        const result = await getDocs(
          query(
            collection(db, "notes"),
            where("clinicId", "==", clinicId),
            where("appointmentId", "==", id),
            orderBy("startMs", "asc")
          )
        );

        const notes = result.docs.reduce((acc, doc) => {
          const note = doc.data() as INote;
          if (!note?.deletedAt) {
            acc.push(note);
          }
          return acc;
        }, [] as Array<INote>);

        return notes;
      }
    )
  ) as INote[];
};

export const getAllUnmatchedNotesByClinicId = async (clinicId: string): Promise<INote[]> => {
  try {
    const result = await getDocs(
      query(
        collection(db, "notes"),
        where("clinicId", "==", clinicId),
        where("appointmentId", "==", ""),
        orderBy("startMs", "asc")
      )
    );

    const notes = result.docs.reduce((acc, doc) => {
      const note = doc.data() as INote;
      if (!note?.deletedAt) {
        acc.push(note);
      }
      return acc;
    }, [] as Array<INote>);

    return handleResponse(notes);
  } catch (error) {
    return Promise.reject(`Error getting notes ${error}`);
  }
};

export const approveNote = async (noteId: string) => {
  try {
    const approveNote = ENDPOINTS.getApproveNoteEndpoint();
    const result = await approveNote({ noteId });

    return handleResponse(result == "success" ? true : false);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const approveNoteWebSocket = async (motivityUUID: string) => {
  try {
    const approveNoteWebSocket = ENDPOINTS.getApproveNoteWebSocketEndpoint();
    const result = await approveNoteWebSocket({ motivityUUID });

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateNote = async (req: any) => {
  try {
    const updateNote = ENDPOINTS.getUpdateNoteEndpoint();
    const result = await updateNote(req);

    return handleResponse(result == "success" ? true : false);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createIndirect = async (req: ICreateIndirectEndpointRequest) => {
  try {
    const createIndirect = ENDPOINTS.getCreateIndirectEndpoint();
    const result = await createIndirect(req);

    return handleResponse(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

//TODO: Cache this
export const getIndirectsForWeekAndClinicId = async (
  req: IGetIndirectsForWeekAndClinicIdEndpointRequest
): Promise<IIndirect[]> => {
  try {
    const getIndirectsForWeekAndClinicId = ENDPOINTS.getGetIndirectForWeekAndClinicIdEndpoint();
    const indirects = await getIndirectsForWeekAndClinicId(req);

    return handleResponse(indirects);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getIndirectsForClinicIdAndRange = async (
  clinicId: string,
  startMs: number,
  endMs: number
): Promise<IIndirect[]> => {
  const result = await getDocs(
    query(
      collection(db, "indirects"),
      where("clinicId", "==", clinicId),
      where("startMs", ">=", startMs),
      where("startMs", "<=", endMs)
    )
  );

  let indirects: IIndirect[] = [];
  result.forEach((doc) => indirects.push(doc.data() as IIndirect));
  indirects = indirects.filter((indirect) => !indirect?.deletedAt);

  return indirects || [];
};

export const deleteIndirect = async (req: IDeleteIndirectEndpointRequest) => {
  try {
    const deleteIndirect = ENDPOINTS.getDeleteIndirectEndpoint();
    const result = await deleteIndirect(req);

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateIndirect = async (req: IUpdateIndirectEndpointRequest) => {
  try {
    const updateIndirect = ENDPOINTS.getUpdateIndirectEndpoint();
    const result = await updateIndirect(req);

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const markExportedBilledAppointment = async (
  req: IMarkExportedBilledAppointmentsEndpointRequest
) => {
  try {
    const markExportedBilledAppointment = ENDPOINTS.getMarkExportedBilledAppointmentEndpoint();
    const result = await markExportedBilledAppointment(req);

    return handleResponse(result.id);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createCompletedAppointment = async (
  req: ICreateCompletedAppointmentEndpointRequest
): Promise<string> => {
  try {
    const createCompletedAppointment = ENDPOINTS.getCreateCompletedAppointmentEndpoint();
    const result = await createCompletedAppointment(req);

    return handleResponse(result.id);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createCancelledCompletedAppointment = async (
  req: ICreateCancelledCompletedAppointmentEndpointRequest
): Promise<string> => {
  try {
    const createCancelledCompletedAppointment =
      ENDPOINTS.getCreateCancelledCompletedAppointmentEndpoint();
    const result = await createCancelledCompletedAppointment(req);

    return handleResponse(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const approveCancelledCompletedAppointment = async (
  req: IApproveCancelledCompletedAppointmentEndpointRequest
): Promise<string> => {
  try {
    const approveCancelledCompletedAppointment =
      ENDPOINTS.getApproveCancelledCompletedAppointmentEndpoint();
    const result = await approveCancelledCompletedAppointment(req);

    return handleResponse(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const rejectCancelledCompletedAppointment = async (
  req: IRejectCancelledCompletedAppointmentEndpointRequest
): Promise<string> => {
  try {
    const rejectCancelledCompletedAppointment =
      ENDPOINTS.getRejectCancelledCompletedAppointmentEndpoint();
    const result = await rejectCancelledCompletedAppointment(req);

    return handleResponse(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

//TODO: Cache this
export const getCompletedAppointmentByClinicAndRange = async (
  req: IGetCompletedAppointmentsForClinicAndRangeEndpointRequest
): Promise<ICompletedAppointment[]> => {
  try {
    const getCompletedAppointmentByClinicAndRange =
      ENDPOINTS.getGetForClinicAndRangeCompletedAppointments();
    const result = await getCompletedAppointmentByClinicAndRange(req);

    return handleResponse(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCompletedAppointmentByClinicClientAndRange = async (
  req: IGetCompletedAppointmentsForClinicClientAndRangeEndpointRequest
): Promise<ICompletedAppointment[]> => {
  try {
    const getCompletedAppointments = ENDPOINTS.getGetForClinicClientAndRangeCompletedAppointments();
    const result = await getCompletedAppointments(req);

    return handleResponse(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCompletedAppointmentByClinicAndUser = async (
  userId: string,
  clinicId: string
): Promise<ICompletedAppointment[]> => {
  const result = await getDocs(
    query(
      collection(db, "completed-appointments"),
      where("clinicId", "==", clinicId),
      where("userIds", "array-contains", userId)
    )
  );

  const completedAppointments = result.docs.reduce((acc, doc) => {
    const completedAppointment = doc.data() as ICompletedAppointment;
    if (!completedAppointment?.deletedAt) {
      acc.push(completedAppointment);
    }
    return acc;
  }, [] as Array<ICompletedAppointment>);

  return handleResponse(completedAppointments);
};

export const getCompletedAppointmentByClinicUserAndRange = async (
  userId: string,
  clinicId: string,
  startMs: number,
  endMs: number
): Promise<ICompletedAppointment[]> => {
  const result = await getDocs(
    query(
      collection(db, "completed-appointments"),
      where("clinicId", "==", clinicId),
      where("renderingUserId", "==", userId),
      where("startMs", ">=", startMs),
      where("startMs", "<=", endMs)
    )
  );

  const completedAppointments = result.docs.reduce((acc, doc) => {
    const completedAppointment = doc.data() as ICompletedAppointment;
    if (!completedAppointment?.deletedAt) {
      acc.push(completedAppointment);
    }
    return acc;
  }, [] as Array<ICompletedAppointment>);

  return handleResponse(completedAppointments);
};

export const getIndirectsByClinicUserAndRange = async (
  userEmail: string,
  clinicId: string,
  startMs: number,
  endMs: number
): Promise<IIndirect[]> => {
  const result = await getDocs(
    query(
      collection(db, "indirects"),
      where("clinicId", "==", clinicId),
      where("attendees", "array-contains", {
        email: userEmail,
        status: AttendeeStatus.ACCEPTED,
      }),
      where("startMs", ">=", startMs),
      where("startMs", "<=", endMs)
    )
  );

  const indirects = result.docs.reduce((acc, doc) => {
    const indirect = doc.data() as IIndirect;
    if (!indirect?.deletedAt) {
      acc.push(indirect);
    }
    return acc;
  }, [] as Array<IIndirect>);

  return handleResponse(indirects);
};

export const getAllCompletedAppointmentByClinic = async (
  clinicId: string,
  force = false
): Promise<ICompletedAppointment[]> => {
  try {
    return handleResponse(
      await cacheManager(
        "getAllCompletedAppointmentByClinic",
        [clinicId],
        force ? 0 : 15 * 60 * 1000,
        async () => {
          const result = await getDocs(
            query(collection(db, "completed-appointments"), where("clinicId", "==", clinicId))
          );

          const completedAppointments = result.docs.reduce((acc, doc) => {
            const completedAppointment = doc.data() as ICompletedAppointment;
            if (!completedAppointment?.deletedAt) {
              acc.push(completedAppointment);
            }
            return acc;
          }, [] as Array<ICompletedAppointment>);

          return completedAppointments;
        }
      )
    ) as ICompletedAppointment[];
  } catch (err) {
    console.log(err);
    return Promise.reject("Unable to retrieve all completedAppointments for clinic");
  }
};

export const getAllBillableCompletedAppointmentsByClinicId = async (
  clinicId: string
): Promise<ICompletedAppointment[]> => {
  const result = await getDocs(
    query(
      collection(db, "completed-appointments"),
      where("clinicId", "==", clinicId),
      where("isBilled", "==", false),
      orderBy("startMs", "desc")
    )
  );

  const unbilledCompletedAppointments = result.docs.reduce((acc, doc) => {
    const completedAppointment = doc.data() as ICompletedAppointment;
    if (!completedAppointment?.deletedAt && !completedAppointment?.cancelledAt) {
      acc.push(completedAppointment);
    }
    return acc;
  }, [] as Array<ICompletedAppointment>);

  return handleResponse(unbilledCompletedAppointments);
};

export const updateCompletedAppointment = async (
  req: IUpdateCompletedAppointmentEndpointRequest
): Promise<ICompletedAppointment> => {
  try {
    const updateCompletedAppointment = ENDPOINTS.getUpdateCompletedAppointmentEndpoint();
    const result = await updateCompletedAppointment(req);

    return handleResponse(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteCompletedAppointment = async (
  req: IDeleteCompletedAppointmentEndpointRequest
) => {
  try {
    const deleteCompletedAppointment = ENDPOINTS.getDeleteCompletedAppointmentEndpoint();
    const result = await deleteCompletedAppointment(req);

    return handleResponse(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const splitAppointmentByNoteAndAppointment = async (
  note: INote,
  appointment: IAppointment
): Promise<string> => {
  try {
    //create appointment
    const req: ICalendarCreateAppointmentEndpointRequest = {
      clinicId: note.clinicId,
      clientId: note.clientId,
      attendeeEmails: appointment.attendees.map((a) => a.email),
      billingCode: note.billingCode as BillingCode,
      modifiers: note.modifiers as Modifier[],
      location: note.location,
      summary: appointment.summary,
      description: appointment.description,
      startMs: note.startMs,
      endMs: note.endMs,
      timeZone: moment.tz.guess(), //note.startMs & note.endMs are stored in utc
      noteId: note.id,
      //skip RRule as it's not needed for single appointment
      hasMeets: appointment.meetsLink ? true : false,
    };
    const appointmentId = await createAppointment(req);

    //update note with appointmentId
    await updateNote({
      id: note.id,
      appointmentId: appointmentId,
      manualOverride: true,
    });

    //return newly created appointmentId
    return handleResponse(appointmentId);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const reloadNoteById = async (id: string): Promise<INote> => {
  try {
    const getMotivityNote = ENDPOINTS.getGetMotivityNoteEndpoint();
    const note = await getMotivityNote({ noteIds: [id] });
    return handleResponse(note);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteNoteById = async (id: string) => {
  try {
    const deleteNote = ENDPOINTS.getDeleteNoteEndpoint();
    const result = await deleteNote({ id });

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllBilledAppointmentsByClinicIdAndRange = async (
  req: IGetBilledAppointmentsForClinicAndRangeEndpointRequest
) => {
  try {
    const { clinicId, startMs, endMs } = req;

    if (startMs > endMs) {
      console.error(`startMs (${startMs}) is greater than endMs (${endMs})`);
      return Promise.reject(`startMs (${startMs}) is greater than endMs (${endMs})`);
    }

    const result = await getDocs(
      query(
        collection(db, "billed-appointments"),
        where("clinicId", "==", clinicId),
        where("startMs", ">=", startMs),
        where("startMs", "<=", endMs)
      )
    );
    let billedAppointments: IBilledAppointment[] = [];
    result.forEach((doc) => billedAppointments.push(doc.data() as IBilledAppointment));
    billedAppointments = billedAppointments.filter(
      (billedAppointment) => !billedAppointment?.deletedAt
    );

    return handleResponse(billedAppointments);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const batchBill = async (req: IBatchBillEndpointRequest) => {
  try {
    const batchBill = ENDPOINTS.getBatchBillEndpoint();
    const result = await batchBill(req);

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const batchBillDryRun = async (req: IBatchBillEndpointRequest) => {
  try {
    const batchBillDryRun = ENDPOINTS.getBatchBillDryRunEndpoint();
    const result = await batchBillDryRun(req);

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createInvite = async (req: ICreateInviteRequest) => {
  try {
    const createInvite = ENDPOINTS.getCreateInviteEndpoint();
    const result = await createInvite(req).catch((error) => {
      throw error;
    });

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInvites = async () => {
  try {
    const getInvitesList = ENDPOINTS.getInvitesByAuthEmail();
    const result = await getInvitesList({});

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllInvitesForClinic = async (clinicId: string) => {
  const result = await getDocs(query(collection(db, "invites"), where("clinicId", "==", clinicId)));
  let invites: IInvite[] = [];
  result.forEach((doc) => invites.push(doc.data() as IInvite));

  invites = invites.filter((user) => !user?.deletedAt);

  return handleResponse(invites);
};

export const updateInvite = async (req: IUpdateInviteEndpointRequest) => {
  try {
    const updateInvite = ENDPOINTS.getUpdateInviteEndpoint();
    const result = await updateInvite(req);

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteInvite = async (req: IDeleteInviteEndpointRequest) => {
  try {
    const deleteInvite = ENDPOINTS.getDeleteInviteEndpoint();
    const result = await deleteInvite(req);

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUsersWithExpiredCredentialsForClinic = async (clinicId: string, force = false) => {
  try {
    const users = await getAllUsersForClinic(clinicId, force);
    const now = moment().valueOf();
    const result = users.filter(
      (user) =>
        _.intersection(user.permissions, [UserPermission.BCBA, UserPermission.RBT]).length > 0 &&
        (user.credentials?.some((credential) => now >= credential?.expiryMs) ||
          user.credentials === undefined ||
          user.credentials.length === 0)
    );

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllApprovalsByClinicId = async (clinicId: string, force = false) => {
  const getApprovals = ENDPOINTS.getApprovalsEndpoint();
  const approvalAppointments = await cacheManager(
    "getAllApprovalsByClinicId",
    [clinicId],
    force ? 0 : 10 * 60 * 1000,
    async () => {
      return await getApprovals({ clinicId });
    }
  );
  return handleResponse<IApprovals>(approvalAppointments);
};

export const getUsersWithWarningCredentialsForClinic = async (clinicId: string, force = false) => {
  try {
    const users = await getAllUsersForClinic(clinicId, force);
    const now = moment().valueOf();
    const result = users.filter((user) =>
      user.credentials?.some(
        (credential) =>
          _.intersection(user.permissions, [UserPermission.BCBA, UserPermission.RBT]).length > 0 &&
          credential?.expiryMs > 0 &&
          now >= credential?.expiryWarningOffsetMs &&
          now < credential?.expiryMs
      )
    );

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sendScheduleUpdateRequest = async (payload: any) => {
  try {
    const createUpdateWorkingHoursRequest = ENDPOINTS.getCreateUpdateWorkingHoursRequest();
    const result = await createUpdateWorkingHoursRequest(payload);

    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
};
export async function approveScheduleUpdateRequest(payload: { id: string }) {
  try {
    const approveUpdateWorkingHoursRequest = ENDPOINTS.getApproveUpdateWorkingHoursRequest();
    const result = await approveUpdateWorkingHoursRequest(payload);
    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function declineScheduleUpdateRequest(payload: { id: string }) {
  try {
    const denyUpdateWorkingHoursRequest = ENDPOINTS.getDenyUpdateWorkingHoursRequest();
    const result = await denyUpdateWorkingHoursRequest(payload);
    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllPendingScheduleUpdateRequests(
  clinicId: string
): Promise<IUpdateWorkingHoursRequest[] | undefined> {
  try {
    const result = await getDocs(
      query(collection(db, "update-working-hours"), where("clinicId", "==", clinicId))
    );
    let invites: IUpdateWorkingHoursRequest[] = [];
    result.forEach((doc) => invites.push(doc.data() as IUpdateWorkingHoursRequest));

    invites = invites.filter((user) => !user?.deletedAt && user.approved == undefined);
    return handleResponse(invites);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateCollectionRecord(
  collection: "users" | "guardians" | "clients" | "clientFiles" | "clinics",
  data: IUser | IGuardian | IClient | IClientFile | IClinic
) {
  try {
    if (!data.id) throw new Error("Data is missing id");
    let result;
    switch (collection) {
      case "users":
        result = await updateUser(data as IUpdateUserEndpointRequest);
        break;
      case "guardians":
        result = await updateGuardian(data as IUpdateGuardianEndpointRequest);
        break;
      case "clients":
        result = await updateClient(data as IUpdateClientEndpointRequest);
        break;
      case "clientFiles":
        result = await updateClientFile(data as IUpdateClientFileEndpointRequest);
        break;
      case "clinics":
        result = await updateClinic(data as IUpdateClinicEndpointRequest);
        break;
      default:
        throw new Error("Invalid collection name");
    }
    return handleResponse(result);
  } catch (error) {
    return Promise.reject(error);
  }
}
