import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  getCurrentActiveAuth,
  getPayerById,
  IClient,
  IClientPayer,
  stringToColor,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Card, Col, Divider, Row, Tag, Typography } from "antd";
import moment from "moment";
import React from "react";

import { DISPLAY_DATE_FORMAT } from "../consts";
import { useUserClinics } from "./UserClinicsProvider";

const { Text } = Typography;

const EXPIRY_REMINDER_DAYS = 30;

interface IProps {
  client: IClient;
  clientPayer: IClientPayer;
}

export const ClientPayerCard: React.FC<IProps> = ({ client, clientPayer }: IProps) => {
  const { clinic } = useUserClinics();

  const payer = getPayerById(clinic.address.state, clientPayer.payerId ?? null);
  const currentAuth = getCurrentActiveAuth(clientPayer);
  const daysTillExpiry = currentAuth
    ? Math.round(moment.duration(moment(currentAuth.endDate).diff(moment())).asDays())
    : 0;
  const isExpiring = daysTillExpiry <= EXPIRY_REMINDER_DAYS;

  return (
    <Card size="small" style={{ borderRadius: 10, borderWidth: 5, width: 420, minHeight: 220 }}>
      <Row justify="center">
        <Row justify="space-between" align="middle" style={{ width: "100%", flexWrap: "nowrap" }}>
          <Tag
            color={payer ? stringToColor(payer.name) : "default"}
            style={{ fontSize: 15, lineHeight: 1.4 }}
          >
            {payer ? payer.name : "Unknown Payer"}
          </Tag>
          <Text style={{ fontSize: 15, textAlign: "right" }}>
            <strong>Coverage for: </strong>
            {client.alias}
            <br />
            <strong>DOB: </strong>
            {client.dateOfBirth ? moment(client.dateOfBirth).format(DISPLAY_DATE_FORMAT) : ""}
          </Text>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Row justify="space-between" style={{ width: "100%" }}>
          <Col style={{ textOverflow: "ellipsis" }}>
            <Text>
              <strong>Member Name: </strong>
              {clientPayer.policyHolder?.firstName || ""} {clientPayer.policyHolder?.lastName || ""}
            </Text>
          </Col>
          <Col style={{ textOverflow: "ellipsis" }}>
            <Text>
              <strong>Deductible: </strong>${clientPayer.deductible || 0}
            </Text>
          </Col>
        </Row>
        <Row justify="space-between" style={{ width: "100%" }}>
          <Col style={{ textOverflow: "ellipsis" }}>
            <Text>
              <strong>Member ID: </strong>
              {clientPayer.memberNum || ""}
            </Text>
          </Col>
          <Col style={{ textOverflow: "ellipsis" }}>
            <Text>
              <strong>Copay: </strong>${clientPayer.copay || 0}
            </Text>
          </Col>
        </Row>
        <Row justify="space-between" style={{ width: "100%" }}>
          <Text>
            <strong>Group Number: </strong>
            {clientPayer.groupNum || ""}
          </Text>
        </Row>
      </Row>
      <Row>
        {currentAuth !== null && (
          <>
            <Divider style={{ marginTop: 10, marginBottom: 7 }} />
            {isExpiring && (
              <Col
                style={{
                  height: "100%",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: 10,
                }}
              >
                <ExclamationCircleOutlined
                  style={{
                    fontSize: 16,
                    color: COLORS.RED,
                  }}
                />
              </Col>
            )}
            <Col>
              <Row>
                <Text
                  style={{
                    color: isExpiring ? COLORS.RED : undefined,
                    borderColor: isExpiring ? COLORS.RED : undefined,
                  }}
                >
                  <strong>Authorization Expiry: </strong>
                  {moment(currentAuth.endDate).format(DISPLAY_DATE_FORMAT) || ""}
                </Text>
              </Row>
              <Row>
                <Text
                  style={{
                    color: isExpiring ? COLORS.RED : undefined,
                    borderColor: isExpiring ? COLORS.RED : undefined,
                  }}
                >
                  {daysTillExpiry > 0
                    ? `Expires in ${daysTillExpiry} day${daysTillExpiry === 1 ? "" : "s"}`
                    : `AUTHORIZATION EXPIRED ${Math.abs(daysTillExpiry)} DAY${
                        Math.abs(daysTillExpiry) === 1 ? "" : "S"
                      } AGO`}
                </Text>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </Card>
  );
};
