import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { COLORS } from "@finni-health/ui";
import { Button, Divider, Form, Input } from "antd";

const DynamicBackendRequestFields = () => {
  return (
    <Form.List name="parameters" initialValue={[]}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Form.Item name={[index, "key"]} label="Key" rules={[{ required: true }]}>
                  <Input placeholder="clinicId" />
                </Form.Item>
                <Form.Item label="Value" name={[index, "value"]} rules={[{ required: true }]}>
                  <Input placeholder="ugWUb1mtAb7UPWXZiikx" />
                </Form.Item>
                {fields.length > 0 ? (
                  <Button
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    icon={<MinusCircleOutlined />}
                  >
                    Remove Above Parameter
                  </Button>
                ) : null}
                <Divider />
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%", color: COLORS.PRIMARY }}
              >
                <PlusOutlined /> Add Request Parameter
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default DynamicBackendRequestFields;
