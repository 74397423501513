import { capitalizeFirstLetter, UserPermission } from "@finni-health/shared";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { EditClinicForm } from "../components/ClinicSettings/EditClinicForm";
import { InviteTable } from "../components/ClinicSettings/InviteTable";
import { PendingWorkingHoursUpdatesTable } from "../components/ClinicSettings/PendingWorkingHoursUpdatesTable";
import { StaffTable } from "../components/ClinicSettings/StaffTable";
import { useUserClinics } from "../components/UserClinicsProvider";

export enum TABLE_TABS {
  STAFF = "Staff",
  INVITES = "Invites",
  GENERAL = "General",
  RBT_WORKING_HOURS = "Work-hours",
}

export const ClinicSettings: React.FC = () => {
  const { user } = useUserClinics();
  const { tab } = useParams<{ tab: string }>();

  const location = useLocation();

  const [activeTab, setActiveTab] = useState(
    (capitalizeFirstLetter(tab || "") as TABLE_TABS) || TABLE_TABS.STAFF
  );

  useEffect(() => {
    //get URL
    const url = window.location.pathname;

    //get tab from URL
    const tab = url.split("/")[3];

    //set active tab
    if (tab) {
      setActiveTab(capitalizeFirstLetter(tab) as TABLE_TABS);
    }
  }, [location]);

  //set URL on active tab change
  useEffect(() => {
    const { pathname } = location;
    const idx = pathname.indexOf("settings");
    const link = pathname.slice(0, idx + "settings".length) + "/" + activeTab.toLowerCase();
    window.history.replaceState(null, "Mission Control - Finni Health", link);
  }, [activeTab]);

  return (
    <div
      style={{
        margin: "auto",
      }}
    >
      <Tabs
        activeKey={activeTab}
        defaultActiveKey={tab}
        onChange={(key) => {
          setActiveTab(key as TABLE_TABS);
        }}
        items={[
          {
            label: `General`,
            key: TABLE_TABS.GENERAL,
            children: <EditClinicForm />,
          },
          {
            label: `Staff`,
            key: TABLE_TABS.STAFF,
            children: <StaffTable />,
          },
        ].concat(
          user.permissions.includes(UserPermission.BCBA) ||
            user.permissions.includes(UserPermission.ADMIN) ||
            user.permissions.includes(UserPermission.SUPERADMIN)
            ? [
                {
                  label: `Invites`,
                  key: TABLE_TABS.INVITES,
                  children: <InviteTable />,
                },
                {
                  label: `RBT Working Hours`,
                  key: TABLE_TABS.RBT_WORKING_HOURS,
                  children: <PendingWorkingHoursUpdatesTable />,
                },
              ]
            : []
        )}
      />
    </div>
  );
};
