import { SCA_SUFFIX_TEXT } from "../../consts";
import {
  BillingCode,
  BillingLevel,
  IAppointment,
  IAppointmentType,
  ICompletedAppointment,
  IPayer,
  IStatePayersConfig,
  USStateCode,
} from "../../types";
import { generateAddress, getBaseBillingModifiers } from "./helpers";

export const NEW_YORK_APPOINTMENTS: IAppointmentType = {};

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  billingLevel: BillingLevel
) => {
  return getBaseBillingModifiers(appointment, payer, billingLevel);
};

export const NEW_YORK_PAYERS: IStatePayersConfig = [
  {
    name: "Self Pay",
    payerId: "NY-SELFPAY",
    contractAddress: generateAddress(
      "600 Mamaroneck Ave",
      "Suite 400",
      "Harrison",
      "10528",
      USStateCode.NY
    ),
    billingAddress: generateAddress(
      "600 Mamaroneck Ave",
      "Suite 400",
      "Harrison",
      "10528",
      USStateCode.NY
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: null,
        rates: {
          BCBA: 120000,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1875,
          BCBA: 3000,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3000,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2250,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Blue Cross Blue Shield Empire (Single Case Agreement)",
    payerId: `27514${SCA_SUFFIX_TEXT}`,
    contractAddress: generateAddress(
      "600 Mamaroneck Ave",
      "Suite 400",
      "Harrison",
      "10528",
      USStateCode.NY
    ),
    billingAddress: generateAddress(
      "600 Mamaroneck Ave",
      "Suite 400",
      "Harrison",
      "10528",
      USStateCode.NY
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3125,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1400,
          BCBA: 1400,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2160,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3125,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
];
