import { IClinic, IUser, UserPermission } from "@finni-health/shared";

export const userHasPermission = (user: IUser, permission: UserPermission) => {
  return user?.permissions?.includes(permission) || false;
};

export const shouldShowDashboard = (user: IUser, clinic: IClinic) => {
  return (
    (userHasPermission(user, UserPermission.SUPERADMIN) ||
      userHasPermission(user, UserPermission.OWNER)) && //added exception for Erin until permissions update occurs
    clinic.lookerReportURL
  );
};
