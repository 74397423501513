import { IAddress, TransportMode } from "@finni-health/shared";
import { debounce } from "lodash";

import * as FirestoreService from "../services/firestore";

export const formatAddress = ({ line1, line2, city, state, zipCode, country }: IAddress) => {
  let address = "";
  if (!line1 && !line2) return address;
  if (line1) address += line1;
  if (line2) address += ` ${line2}`;
  if (city && state && zipCode) address += `,\n${city}, ${state} ${zipCode}`;
  if (country) address += `\n${country}`;
  return address;
};

export const updatePreferredTransport = debounce(
  async (preferredTransport: TransportMode, userId, refresh: () => void, message?) => {
    try {
      await FirestoreService.updateUser({
        id: userId,
        preferredTransport,
      });
      refresh();
    } catch (error) {
      console.error(error);
      message?.error(
        "Error updating user, please check that all required fields are filled and correct"
      );
    }
  },
  500
);
