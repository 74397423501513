import "react-big-calendar/lib/css/react-big-calendar.css";

import { UserPermission } from "@finni-health/shared";
import { Layout, notification, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { AppointmentsProvider } from "../components/AppointmentsProvider";
import { BirthdayModule } from "../components/BirthdayModule";
//components
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar";
import { useUserClinics } from "../components/UserClinicsProvider";
import { SetWorkingHoursModal } from "../components/WorkingHours/SetWorkingHoursModal";
import { userHasPermission } from "../helpers/userPermissions";
import { shouldShowDashboard } from "../helpers/userPermissions";
//helpers
import { getPendingApprovalScheduleUpdates } from "../helpers/workingHours";
import { Alerts } from "./Alerts";
import { Approvals } from "./Approvals";
import { Billing } from "./Billing";
import { Calendar } from "./Calendar";
import { ClientProfile } from "./ClientProfile";
//pages
import { Clients } from "./Clients";
import { ClinicSettings } from "./ClinicSettings";
import { Dashboard } from "./Dashboard";
import { Development } from "./Development";
import { GuardianProfile } from "./GuardianProfile";
import { Intake } from "./Intake";
import { Loading } from "./Loading";
import { NotFound } from "./NotFound";
import { SchedulePlayground } from "./SchedulePlayground";
import { Schedules } from "./Schedules";
import { UserProfile } from "./UserProfile";

const { Content } = Layout;

export const Home: React.FC = () => {
  const { user, clinic } = useUserClinics();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(true);

  useEffect(() => {
    checkWorkingHourRequestsAndNotify().catch(() => {});
  }, [user]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactGA.event("page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  const checkWorkingHourRequestsAndNotify = async () => {
    const requests = (await getPendingApprovalScheduleUpdates(user.clinicId)) || [];
    if (requests.length > 0) {
      notification.open({
        key: "newWorkingHoursChanges",
        duration: 0,
        type: "warning",
        message: `Your clinic has pending requests to update RBT working hours`,
        description: (
          <Typography.Text>
            {`There ${
              requests.length == 1
                ? "is 1 request pending for approval. View it"
                : `are ${requests.length} requests pending for approval. View them`
            }  `}
            <a
              onClick={() => {
                navigate("/settings/4");
                notification.close("newWorkingHoursChanges");
              }}
            >
              here
            </a>
          </Typography.Text>
        ),
      });
    }
  };

  const onSidebarCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  function renderContent() {
    const { isLoading } = useUserClinics();

    if (isLoading) {
      return <Loading />;
    }

    return (
      <Content
        style={{
          paddingTop: 10,
          paddingBottom: 0,
          paddingLeft: 15,
          paddingRight: 15,
          minHeight: "fit-content",
          background: "#FFF",
        }}
      >
        <Routes>
          {userHasPermission(user, UserPermission.ADMIN) ? (
            <>
              <Route path="settings/:tab?/:id?" element={<ClinicSettings />} />
              <Route path="alerts" element={<Alerts />} />
              <Route path="approvals/:tab?" element={<Approvals />} />
              {userHasPermission(user, UserPermission.SUPERADMIN) && (
                <Route path="billing" element={<Billing />} />
              )}
              <Route
                path="calendar/:calendarType?/:selectedWeek?/:eventId?"
                element={<Calendar />}
              />
              <Route path="intake/:tab?" element={<Intake />} />
              <Route path="guardian-profile/:guardianId" element={<GuardianProfile />} />
              <Route path="client-profile/:clientId" element={<ClientProfile />} />
              <Route path="clients/:tab?" element={<Clients />} />
              <Route path="dashboard/:tab?" element={<Dashboard />} />
              <Route path="profile" element={<UserProfile />} />
              <Route path="schedules/:selectedWeek?" element={<Schedules />} />
              <Route path="dev" element={<Development />} />
              <Route
                path="schedule-playground"
                element={
                  <AppointmentsProvider>
                    <SchedulePlayground />
                  </AppointmentsProvider>
                }
              />
              <Route
                index
                element={
                  <Navigate to={shouldShowDashboard(user, clinic) ? "dashboard" : "clients"} />
                }
              />
            </>
          ) : (
            <>
              <Route path="profile" element={<UserProfile />} />
              <Route path="schedules/:selectedWeek?" element={<Schedules />} />
              <Route path="calendar/:selectedWeek?/:eventId?" element={<Calendar />} />
              <Route index element={<Calendar />} />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Content>
    );
  }

  return (
    <Layout style={{ height: "100vh" }}>
      <Header />
      <Layout>
        <Sidebar isSidebarCollapsed={isSidebarCollapsed} onSidebarCollapse={onSidebarCollapse} />
        <Layout
          style={{
            overflow: "scroll",
            borderLeft: "1px solid rgba(0, 0, 0, 0.2)",
          }}
        >
          {renderContent()}
          {<SetWorkingHoursModal />}
          {<BirthdayModule />}
        </Layout>
      </Layout>
    </Layout>
  );
};
