import { IPayer, PolicyHolderRelationship, Sex } from "@finni-health/shared";
import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import moment from "moment";

import { DISPLAY_DATE_FORMAT } from "../../consts";
import { PayerAuthSection } from "./PayerAuthSection";
import { PayersSectionPhoto } from "./PayersSectionPhotos";

interface IProps {
  clientFileId: string;
  payers: IPayer[];
  type: "primary" | "secondary";
}

export const PayersSectionForm = ({ clientFileId, payers, type }: IProps) => {
  const formInstance = Form.useFormInstance();

  const photos = Form.useWatch(["clientFile", "payers", type, "photoUrls"], {
    form: formInstance,
    preserve: true,
  });
  const payer = Form.useWatch(["clientFile", "payers", "primary"], {
    form: formInstance,
    preserve: true,
  });

  const handlePhotoUrlChange = (photoUrl: string, index: number) => {
    formInstance.setFieldValue(["clientFile", "payers", type, "photoUrls", index], photoUrl);
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Payer"
            name={["clientFile", "payers", type, "payerId"]}
            rules={[{ required: type === "primary", message: "Payer is required" }]}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {payers.map((payer) => (
                <Select.Option key={payer.payerId}>{payer.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Deductible" name={["clientFile", "payers", type, "deductible"]}>
            <InputNumber prefix="$" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Copay" name={["clientFile", "payers", type, "copay"]}>
            <InputNumber prefix="$" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Member ID" name={["clientFile", "payers", type, "memberNum"]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Group Number" name={["clientFile", "payers", type, "groupNum"]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label="Policy Holder Client Relationship"
            name={["clientFile", "payers", type, "policyHolderRelationship"]}
          >
            <Select tabIndex={3}>
              <Select.Option value={PolicyHolderRelationship.SELF}>
                {PolicyHolderRelationship.SELF}
              </Select.Option>
              ;
              <Select.Option value={PolicyHolderRelationship.CHILD}>
                {PolicyHolderRelationship.CHILD}
              </Select.Option>
              ;
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {payer?.policyHolderRelationship !== PolicyHolderRelationship.SELF && (
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="Policy Holder First Name"
              name={["clientFile", "payers", type, "policyHolder", "firstName"]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Policy Holder Last Name"
              name={["clientFile", "payers", type, "policyHolder", "lastName"]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Policy Holder DOB"
              name={["clientFile", "payers", type, "policyHolder", "dateOfBirth"]}
            >
              <DatePicker
                format={DISPLAY_DATE_FORMAT}
                autoComplete="off"
                disabledDate={(curr) => curr && curr > moment().startOf("day")}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Sex at Birth"
              name={["clientFile", "payers", type, "policyHolder", "sex"]}
            >
              <Select tabIndex={3}>
                <Select.Option value={Sex.FEMALE}>{Sex.FEMALE}</Select.Option>;
                <Select.Option value={Sex.MALE}>{Sex.MALE}</Select.Option>;
                <Select.Option value={Sex.UNKNOWN}>{Sex.UNKNOWN}</Select.Option>;
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col span={24}>
          <PayerAuthSection type={type} />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <PayersSectionPhoto
            clientFileId={clientFileId}
            onPhotoUrlChange={(photoUrl) => handlePhotoUrlChange(photoUrl, 0)}
            payerType={type}
            photoType="front"
            photoUrl={photos?.[0]}
          />
        </Col>
        <Col span={12}>
          <PayersSectionPhoto
            clientFileId={clientFileId}
            onPhotoUrlChange={(photoUrl) => handlePhotoUrlChange(photoUrl, 1)}
            payerType={type}
            photoType="back"
            photoUrl={photos?.[1]}
          />
        </Col>
      </Row>
    </>
  );
};
