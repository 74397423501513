import { IClientGuardianDetails, IGuardianClientDetails } from "@finni-health/shared";
import { Button, Descriptions, Row } from "antd";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { IoSchoolOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { DISPLAY_DATE_FORMAT, DISPLAY_DATETIME_FORMAT } from "../../consts";
import { formatAddress } from "../../helpers/profiles";
import { EditClientFileModal } from "../EditClientFileModal/EditClientFileModal";

interface Props {
  guardianClientDetails: IGuardianClientDetails;
  refreshCallback: () => Promise<void>;
}

export const GuardianDetailsSection = ({ guardianClientDetails, refreshCallback }: Props) => {
  const { guardian, client, clientFile } = guardianClientDetails;
  // Edit Guardian Modal
  const [isEditGuardianModalVisible, setIsEditGuardianModalVisible] = useState<boolean>(false);
  const hideEditGuardianModal = () => {
    setIsEditGuardianModalVisible(false);
  };

  return (
    <div style={{ marginBottom: 25 }}>
      <Row justify="end" style={{ marginBottom: 10 }}>
        <Button type="primary" onClick={() => setIsEditGuardianModalVisible(true)}>
          Edit profile
        </Button>
      </Row>
      <Descriptions bordered column={4} size="small" style={{ marginBottom: 25 }}>
        <Descriptions.Item label="First Name" span={2}>
          {guardian.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name" span={2}>
          {guardian.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Client" span={4}>
          {!_.isEmpty(client) && (
            <Link to={`../client-profile/${client.id}`}>
              <IoSchoolOutline style={{ fontSize: 18, marginBottom: -3, marginRight: 3 }} />
              {client.alias}
            </Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Address" span={4} style={{ whiteSpace: "pre-wrap" }}>
          {clientFile?.address && formatAddress(clientFile.address)}
        </Descriptions.Item>
        <Descriptions.Item label="Guardian email" span={2}>
          {guardian.email}
        </Descriptions.Item>
        <Descriptions.Item label="Guardian phone" span={2}>
          {guardian.phoneNumber?.primary}
        </Descriptions.Item>
        <Descriptions.Item label="Created On" span={2}>
          {moment(guardian.createdAt as unknown as any).format(DISPLAY_DATE_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label="Last Edited" span={2}>
          {moment(guardian.updatedAt as unknown as any).format(DISPLAY_DATETIME_FORMAT)}
        </Descriptions.Item>
      </Descriptions>
      {isEditGuardianModalVisible && (
        <EditClientFileModal
          hideModal={hideEditGuardianModal}
          refreshCallback={refreshCallback}
          clientGuardianDetails={guardianClientDetails as IClientGuardianDetails}
        />
      )}
    </div>
  );
};
