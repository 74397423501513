import {
  BillingCode,
  CANCELLED_APPOINTMENT_TEXT,
  EventType,
  IAppointment,
  ICalendarEvent,
  ICompletedAppointment,
  IEvent,
  IIndirect,
  Modifier,
  WeekDays,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Avatar, Row, Typography } from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import { max, min } from "moment";

const { Text } = Typography;

enum ConflictType {
  CLIENT = "CLIENT",
  ATTENDEE = "ATTENDEE",
  USER = "USER",
}

export const getCalendarDayHeader = (day: WeekDays, selected: Moment) => {
  const selectedDate = selected.clone();
  selectedDate.day(day);

  return moment().format("LL") === selectedDate.format("LL") ? (
    <>
      <Row style={{ width: "100%" }} justify="center">
        <Row style={{ width: "100%" }} justify="center">
          <Text>{selectedDate.format("ddd")}</Text>
        </Row>
        <Row style={{ width: "100%" }} justify="center">
          <Avatar style={{ backgroundColor: COLORS.RED, fontSize: 17 }}>
            {selectedDate.format("D")}
          </Avatar>
        </Row>
      </Row>
    </>
  ) : (
    <Row style={{ width: "100%" }} justify="center">
      <Row style={{ width: "100%" }} justify="center">
        <Text>{selectedDate.format("ddd")}</Text>
      </Row>
      <Row style={{ width: "100%" }} justify="center">
        <Text style={{ fontSize: 17, lineHeight: "32px" }}>{selectedDate.format("D")}</Text>
      </Row>
    </Row>
  );
};

export const datePickerFormat = (selected: Moment) => {
  if (selected.startOf("week").month() === selected.endOf("week").month()) {
    return `${selected.format("MMMM")} ${selected.format("YYYY")}`;
  } else if (selected.startOf("week").year() !== selected.endOf("week").year()) {
    return `${selected.startOf("week").format("MMM")} ${selected.format("YYYY")} — ${selected
      .endOf("week")
      .format("MMM")} ${selected.format("YYYY")}`;
  } else {
    return `${selected.startOf("week").format("MMM")} — ${selected
      .endOf("week")
      .format("MMM")} ${selected.format("YYYY")}`;
  }
};

// For a given ICalendarEvent array, returns an array with all scheduling conflicts
export const findSchedulingConflicts = (events: ICalendarEvent[]) => {
  const conflicts: Set<ICalendarEvent> = new Set();

  const attendeeAppts: (IAppointment | IIndirect)[] = [];
  const clientAppts: (IAppointment | ICompletedAppointment)[] = [];
  const userAppts: ICompletedAppointment[] = [];

  // Sort into different arrays based on event type
  events.forEach((event) => {
    if (
      event.eventType === EventType.COMPLETED &&
      !("cancellationReason" in (event as ICompletedAppointment))
    ) {
      clientAppts.push(event as ICompletedAppointment);
      userAppts.push(event as ICompletedAppointment);
    }

    if (
      (event.eventType === EventType.INDIRECT || event.eventType === EventType.APPOINTMENT) &&
      Array.isArray((event as IEvent).attendees) &&
      events.filter((e) => e.id === event.id).length ==
        1 /* Check for ICompletedAppt not existing */ &&
      event.summary?.search(CANCELLED_APPOINTMENT_TEXT) === -1
    ) {
      if (event.eventType !== EventType.INDIRECT) {
        clientAppts.push(event as IAppointment);
      }
      attendeeAppts.push(event as IAppointment | IIndirect);
    }
  });

  // Check conflicts for each client
  checkConflicts(
    clientAppts,
    (appt, otherAppt) =>
      appt.clientId === otherAppt.clientId &&
      !(
        // Ignore conflicts between Direct Services and Supervision:
        (
          (isDirect(appt) && isSupervision(otherAppt)) ||
          (isSupervision(appt) && isDirect(otherAppt)) ||
          // Ignore conflicts between Direct Services and Parent Training:
          (isDirect(appt) && isParentTraining(otherAppt)) ||
          (isParentTraining(appt) && isDirect(otherAppt)) ||
          // Ignore conflicts between Direct Services and Clinical Management:
          (isDirect(appt) && isClinicalManagement(otherAppt)) ||
          (isClinicalManagement(appt) && isDirect(otherAppt)) ||
          // Ignore conflicts between Direct Services and Initial Assessment:
          (isDirect(appt) && isInitialAssessment(otherAppt)) ||
          (isInitialAssessment(appt) && isDirect(otherAppt))
        )
      ),
    conflicts,
    ConflictType.CLIENT
  );

  // Check conflicts for each attendee
  checkConflicts(
    attendeeAppts,
    (appt, otherAppt) =>
      !(
        // Ignore conflicts between Direct Services and Supervision:
        (
          (isDirect(appt) && isSupervision(otherAppt)) ||
          (isSupervision(appt) && isDirect(otherAppt)) ||
          // Ignore conflicts between Indirects and T1026UD (Direct/Indirect Supervision):
          (isIndirect(appt) && isSupervision(otherAppt)) ||
          (isIndirect(otherAppt) && isSupervision(appt))
        )
      ),
    conflicts,
    ConflictType.ATTENDEE
  );

  // Check conflicts for each user
  checkConflicts(
    userAppts,
    (appt, otherAppt) =>
      !(
        // Ignore conflicts between Direct Services and Supervision:
        (
          (isDirect(appt) && isSupervision(otherAppt)) ||
          (isSupervision(appt) && isDirect(otherAppt))
        )
      ),
    conflicts,
    ConflictType.USER
  );

  // Convert the Set back into an array for the return value
  return Array.from(conflicts);
};

// Helper function to check conflicts
const checkConflicts = (
  appts: ICalendarEvent[],
  condition: (appt: any, otherAppt?: any) => boolean,
  conflicts: Set<ICalendarEvent>,
  conflictType?: ConflictType
) => {
  appts.forEach((appt) => {
    let conflictsForAppt = appts.filter(
      (otherAppt) =>
        appt.id !== otherAppt.id &&
        condition(appt, otherAppt) &&
        doAppointmentsOverlap(appt, otherAppt)
    );

    // Check for conflicts with diff attendees
    if (conflictType !== ConflictType.CLIENT) {
      conflictsForAppt = conflictsForAppt.filter((conflictingAppt) =>
        isTherapistOverlapping(appt, [conflictingAppt])
      );
    }

    if (conflictsForAppt.length > 0) {
      conflicts.add(appt);
    }
  });
};

// For two given appointments, return true if they overlap
export const doAppointmentsOverlap = (appt1: ICalendarEvent, appt2: ICalendarEvent) => {
  const start1 = moment(appt1.startMs);
  const end1 = moment(appt1.endMs);
  const start2 = moment(appt2.startMs);
  const end2 = moment(appt2.endMs);

  return isOverlap(start1, end1, start2, end2);
};

// Helper function to check if two moments overlap
const isOverlap = (
  start1: moment.Moment,
  end1: moment.Moment,
  start2: moment.Moment,
  end2: moment.Moment
) => {
  // Round all moments to the nearest minute
  start1.startOf("minute");
  end1.startOf("minute");
  start2.startOf("minute");
  end2.startOf("minute");

  return max(start1, start2) < min(end1, end2);
};

// For a given appointment and list of scheduling conflicts, return true if appointment is in conflicts
export const findEventInConflicts = (event: ICalendarEvent, conflicts: ICalendarEvent[]) => {
  return conflicts.find((conflict) => conflict.id === event.id) ? conflicts : [];
};

// For a given appointment and list of scheduling conflicts, return true if clientId has overlapping appointments
export const isClientOverlapping = (
  currentAppt: IAppointment | ICompletedAppointment,
  schedulingConflicts: ICalendarEvent[]
) => {
  if (_.isEmpty(currentAppt)) return false;

  const clientAppts = schedulingConflicts.filter(
    (appt) =>
      "clientId" in appt && appt.id !== currentAppt.id && appt.clientId === currentAppt.clientId
  );

  return clientAppts.some(
    (appt) => doAppointmentsOverlap(appt, currentAppt) && appt.id !== currentAppt.id
  );
};

// For a given appointment and list of scheduling conflicts, return true if userId or attendee has overlapping appointments
export const isTherapistOverlapping = (
  currentAppt: ICalendarEvent,
  schedulingConflicts: ICalendarEvent[]
) => {
  const therapistAppts = schedulingConflicts.filter((appt) => {
    let currentApptEmailOrIds: string[] = [];
    let otherApptEmailOrIds: string[] = [];

    if (_.isEmpty(currentAppt) || _.isEmpty(appt)) return false;

    currentApptEmailOrIds = currentAppt?.attendees.map((attendee) => attendee.email) || [];
    otherApptEmailOrIds = appt?.attendees.map((attendee) => attendee.email) || [];

    return currentApptEmailOrIds.some((attendee) =>
      otherApptEmailOrIds.some((currentAttendee) => currentAttendee === attendee)
    );
  });

  return therapistAppts.some(
    (appt) => doAppointmentsOverlap(appt, currentAppt) && appt.id !== currentAppt.id
  );
};

const isSupervision = (appt: IAppointment | ICompletedAppointment) => {
  return (
    appt.billingCode === BillingCode.CODE_97155 ||
    (appt.billingCode === BillingCode.CODE_T1026 && appt.modifiers?.includes(Modifier.UD))
  );
};

const isDirect = (appt: IAppointment | ICompletedAppointment) => {
  return appt.billingCode === BillingCode.CODE_97153;
};

const isParentTraining = (appt: IAppointment | ICompletedAppointment) => {
  return appt.billingCode === BillingCode.CODE_97156;
};

const isClinicalManagement = (appt: IAppointment | ICompletedAppointment) => {
  return appt.billingCode === BillingCode.CODE_T1026 && appt.modifiers?.includes(Modifier.UC);
};

const isIndirect = (appt: ICalendarEvent) => {
  if (appt.summary === undefined) return false;

  return appt.summary?.search("Indirect") !== -1;
};

const isInitialAssessment = (appt: IAppointment | ICompletedAppointment) => {
  return appt.billingCode === BillingCode.CODE_97151;
};
