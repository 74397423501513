import { IClinic, IUser, UserPermission } from "@finni-health/shared";
import * as Sentry from "@sentry/react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { userHasPermission } from "../helpers/userPermissions";
import { Loading } from "../pages/Loading";
import app from "../services/firebase";
import * as FirestoreService from "../services/firestore";
import { spectatorMode } from "../services/middleware";

const AuthContext = React.createContext({} as Context);

interface Context {
  user: IUser;
  clinic: IClinic;
  availableClinics: IClinic[];
  isLoading: boolean;
  refresh: () => Promise<void>;
}

export const UserClinicsProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [context, setContext] = useState({
    user: {},
    clinic: {},
    availableClinics: [] as IClinic[],
    isLoading: true,
  } as Context);

  const fetchContext = async (firebaseUser: User | null) => {
    setContext((prev) => ({
      ...prev,
      isLoading: true,
    }));

    if (!firebaseUser) {
      setContext((prev) => ({
        ...prev,
        isLoading: false,
      }));
      return;
    }

    const uid = spectatorMode || firebaseUser.uid;

    try {
      const user = await FirestoreService.getUserById(uid);
      if (!user.hasSignedUp) {
        throw new Error("User has not signed up");
      }

      const clinic = await FirestoreService.getClinicById(user.clinicId);
      Sentry.setUser({
        email: user.email,
        id: user.id,
        username: user.firstName + " " + user.lastName,
      });

      let availableClinics;
      if (userHasPermission(user, UserPermission.SUPERADMIN)) {
        availableClinics = await FirestoreService.getAllClinics();
      } else {
        availableClinics =
          user.allowedClinicIds && !_.isEmpty(user.allowedClinicIds)
            ? await Promise.all(
                user.allowedClinicIds?.map(
                  async (clinicId) => await FirestoreService.getClinicById(clinicId)
                )
              )
            : [];
      }

      setContext({
        user,
        clinic,
        availableClinics,
        isLoading: false,
        refresh: () => fetchContext(firebaseUser),
      });
    } catch (err) {
      if (uid) {
        //User is firebase authenticated but not in our database yet
        //Set context with user info and redirect for signup ease
        Sentry.setUser({
          email: firebaseUser.email || undefined,
          id: firebaseUser.uid,
          username: firebaseUser.displayName || undefined,
        });
        setContext({
          user: {
            id: uid,
            email: firebaseUser.email || "",
            firstName: firebaseUser.displayName?.split(" ")[0] || "",
            lastName: firebaseUser.displayName?.split(" ")[1] || "",
          } as any,
          clinic: {} as any as IClinic,
          availableClinics: [],
          isLoading: false,
          refresh: () => fetchContext(firebaseUser),
        });
        navigate("/signup");
      } else {
        console.log(`Error setting auth: ${err}`);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(app), (user) => {
      fetchContext(user).catch(() => {});
    });

    return () => unsubscribe();
  }, []);

  if (context.isLoading && _.isEmpty(context.user)) {
    return <Loading />;
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export const useUserClinics = () => {
  return useContext(AuthContext);
};
