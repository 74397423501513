import { FieldValue, Timestamp } from "firebase/firestore";

import { TransportMode } from "./endpoints";

/** ENUMS */
export enum EventType {
  APPOINTMENT = "APPOINTMENT",
  COMPLETED = "COMPLETED",
  BILLED = "BILLED",
  INDIRECT = "INDIRECT",
}

export enum AppointmentLocation {
  HOME = "12",
  OFFICE = "11",
  TELEHEALTH = "2",
  SCHOOL = "3",
  OTHER = "99",
}

export enum AppointmentSource {
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  FIRESTORE = "FIRESTORE",
}

export enum AttendeeStatus {
  NEEDS_ACTION = "needsAction",
  ACCEPTED = "accepted",
  DECLINED = "declined",
  TENTATIVE = "tentative",
}

export enum BillingCode {
  CODE_97151 = "97151",
  CODE_97152 = "97152",
  CODE_97153 = "97153",
  CODE_97154 = "97154",
  CODE_97155 = "97155",
  CODE_97156 = "97156",
  CODE_T1026 = "T1026",
}

export enum Modifier {
  U1 = "U1",
  U3 = "U3",
  UC = "UC",
  UD = "UD",
  U7 = "U7",
  GT = "GT",
  TJ = "TJ",
  MOD_95 = "95",
}

export enum AppointmentType {
  INITIAL_ASSESSMENT = "Initial Assessment",
  UPDATED_ASSESSMENT = "Updated Assessment",
  ADDITIONAL_ASSESSMENT = "Additional Assessment",
  DIRECT_SERVICES = "Direct Services",
  DIRECT_SUPERVISION = "Direct Supervision",
  PARENT_TRAINING = "Parent Training",
  DIRECT_OR_INDIRECT_SUPERVISION = "Direct/Indirect Supervision",
  CLINICAL_MANAGEMENT = "Clinical Management",
}

export enum IndirectStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum CancelledCompletedAppointmentStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum IndirectRejectionReason {
  OVERUSE = "OVERUSE",
  INVALID_USE_CASE = "INVALID_USE_CASE",
  OTHER = "OTHER",
}

export enum BillingLevel {
  RBT = "RBT",
  BCBA = "BCBA",
}

export enum ProviderTaxonomy {
  BCBA = "103K00000X",
  RBT = "106S00000X",
}

export enum UserPermission {
  ADMIN = "ADMIN",
  SUPERADMIN = "SUPERADMIN",
  OWNER = "OWNER",
  BCBA = "BCBA",
  RBT = "RBT",
}

export enum Weekday {
  SUNDAY = "Sunday",
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
}

export enum ClientDocumentType {
  AUTHORIZATION = "AUTHORIZATION",
  ASSESSMENT = "ASSESSMENT",
  DIAGNOSIS_REPORT = "DIAGNOSIS_REPORT",
  OFFICIAL_DIAGNOSIS_REPORT = "OFFICIAL_DIAGNOSIS_REPORT",
  TREATMENT_PLAN = "TREATMENT_PLAN",
  INSURANCE_APPROVAL = "INSURANCE_APPROVAL",
}

export enum MedicalServices {
  ABA_THERAPY = "ABA_THERAPY",
  PSYCHOLOGICAL_SERVICES = "PSYCHOLOGICAL_SERVICES",
  SPEECH_THERAPY = "SPEECH_THERAPY",
  OCCUPATIONAL_THERAPY = "OCCUPATIONAL_THERAPY",
  PHYSICAL_THERAPY = "PHYSICAL_THERAPY",
  PSYCHIATRIC_SERVICES = "PSYCHIATRIC_SERVICES",
  NEUROLOGICAL_SERVICES = "NEUROLOGICAL_SERVICES",
  SPECIAL_EDUCATION = "SPECIAL_EDUCATION",
}

export enum InquiryStatus {
  UNDISCOVERED = "UNDISCOVERED",
  REFERRAL = "REFERRAL",
  IN_PROCESS = "IN_PROCESS",
  AWAITING_RESPONSE = "AWAITING_RESPONSE",
  AWAITING_INSURANCE = "AWAITING_INSURANCE",
  AWAITING_DIAGNOSIS = "AWAITING_DIAGNOSIS",
  UNQUALIFIED = "UNQUALIFIED",
  NO_RESPONSE = "NO_RESPONSE",
}

export enum IntakeStatus {
  UNDISCOVERED = "UNDISCOVERED",
  IN_PROCESS = "IN_PROCESS",
  CONFIRMING_INSURANCE = "CONFIRMING_INSURANCE",
  PENDING_AUTHORIZATION = "PENDING_AUTHORIZATION",
  AWAITING_RESPONSE = "AWAITING_RESPONSE",
  AWAITING_DIAGNOSIS = "AWAITING_DIAGNOSIS",

  INTAKE_ASSESSMENT = "INTAKE_ASSESSMENT",
  IN_PERSON_ASSESSMENT = "IN_PERSON_ASSESSMENT",
  ISP_IN_PROCESS = "ISP_IN_PROCESS",

  ISP_SUBMITTED = "ISP_SUBMITTED",
  ACTIVE = "ACTIVE",
  ON_HOLD = "ON_HOLD",
  UNQUALIFIED = "UNQUALIFIED",
  CHURNED = "CHURNED",
}

export enum IndirectReason {
  MATERIALS_MAKING = "MATERIALS_MAKING",
  MATERIALS_PICKUP = "MATERIALS_PICKUP",

  MEETING = "MEETING",
  SHADOW_SESSION = "SHADOW_SESSION",
  BCBA_INTERNSHIP = "BCBA_INTERNSHIP",

  RBT_TRAINING = "RBT_TRAINING", //List for module number in notes
  CPR_TRAINING = "CPR_TRAINING",
  HIPAA_TRAINING = "HIPAA_TRAINING",
  OTHER_TRAINING = "OTHER_TRAINING", //List training in notes

  PAID_TIME_OFF = "PAID_TIME_OFF",
  SICK_TIME = "SICK_TIME",
  VACATION = "VACATION",
  HOLIDAY = "HOLIDAY",
  COMPANY_EVENT = "COMPANY_EVENT",

  CLIENT_CANCELLATION = "CLIENT_CANCELLATION",
  SCHEDULING_CHANGE = "SCHEDULING_CHANGE",

  OTHER = "OTHER",
}

export enum CancellationReason {
  THERAPIST_SICK = "THERAPIST_SICK",
  THERAPIST_VACATION = "THERAPIST_VACATION",
  THERAPIST_NO_SHOW = "THERAPIST_NO_SHOW",
  THERAPIST_OTHER = "THERAPIST_OTHER",

  CLIENT_SICK = "CLIENT_SICK",
  CLIENT_VACATION = "CLIENT_VACATION",
  CLIENT_NO_SHOW = "CLIENT_NO_SHOW",
  CLIENT_OTHER = "CLIENT_OTHER",

  OTHER_WEATHER = "OTHER_WEATHER",
  OTHER = "OTHER", // e.g. Holiday
}

export enum CancellationRejectionReason {
  MANDATORY_APPOINTMENT = "MANDATORY_APPOINTMENT",
  VACATION_DENIED = "VACATION_DENIED",
  SICKNESS_DENIED = "SICKNESS_DENIED",
  OVERUSE = "OVERUSE",
  INVALID_USE_CASE = "INVALID_USE_CASE",
  OTHER = "OTHER",
}

export enum Credential {
  CPR_TRAINING = "CPR Training",
  RBT_CERTIFICATION = "RBT Certification",
  BCBA_CERTIFICATION = "BCBA Certification",
}

export enum PrequalifiedStatus {
  UNDISCOVERED = IntakeStatus.UNDISCOVERED,
  IN_PROCESS = IntakeStatus.IN_PROCESS,
  CONFIRMING_INSURANCE = IntakeStatus.CONFIRMING_INSURANCE,
  PENDING_AUTHORIZATION = IntakeStatus.PENDING_AUTHORIZATION,
  AWAITING_RESPONSE = IntakeStatus.AWAITING_RESPONSE,
  AWAITING_DIAGNOSIS = IntakeStatus.AWAITING_DIAGNOSIS,
  UNQUALIFIED = IntakeStatus.UNQUALIFIED,
}

export enum ActiveStatus {
  INTAKE_ASSESSMENT = IntakeStatus.INTAKE_ASSESSMENT,
  IN_PERSON_ASSESSMENT = IntakeStatus.IN_PERSON_ASSESSMENT,
  ISP_IN_PROCESS = IntakeStatus.ISP_IN_PROCESS,
  ISP_SUBMITTED = IntakeStatus.ISP_SUBMITTED,
  ACTIVE = IntakeStatus.ACTIVE,
  ON_HOLD = IntakeStatus.ON_HOLD,
  CHURNED = IntakeStatus.CHURNED,
}

export enum PolicyHolderRelationship {
  SELF = "SELF",
  CHILD = "CHILD",
}

export enum Sex {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNKNOWN = "UNKNOWN",
}

export enum IGoals {
  COMMUNICATION_SKILLS = "COMMUNICATION_SKILLS",
  CHALLENGING_BEHAVIOR = "CHALLENGING_BEHAVIOR",
  INDEPENDENCE = "INDEPENDENCE",
  SOCIAL_SKILLS = "SOCIAL_SKILLS",
  FOLLOW_INSTRUCTIONS = "FOLLOW_INSTRUCTIONS",
}

export enum TherapistGender {
  ANY = "ANY",
  FEMALE = "FEMALE",
  MALE = "MALE",
  NON_BINARY = "NON_BINARY",
}

export enum WeekBlock {
  MON_WED_FRI = "monWedFri",
  TUES_THURS = "tuesThurs",
}

export enum DayBlock {
  MORNING = "MORNING",
  MIDDAY = "MIDDAY",
  EVENING = "EVENING",
}

export enum BehaviorSeverity {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  EXTREME = "EXTREME",
}

export enum BehaviorFrequency {
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  DAILY = "DAILY",
  HOURLY = "HOURLY",
  CONSTANTLY = "CONSTANTLY",
}

export enum CountryCode {
  CA = "CA",
  US = "US",
}

export enum USStateCode {
  AL = "AL",
  AK = "AK",
  AZ = "AZ",
  AR = "AR",
  AS = "AS",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DE = "DE",
  DC = "DC",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
}

export enum CalendarType {
  CLIENTS = "clients",
  THERAPISTS = "therapists",
  LIST = "list",
}

export enum WeekDays {
  SUNDAY = "sunday",
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
}

export enum CalendarEventType {
  //extends EventType, TS doesn't allow enum extensions
  APPOINTMENT = EventType.APPOINTMENT,
  COMPLETED = EventType.COMPLETED,
  INDIRECT = EventType.INDIRECT,
  APPOINTMENT_HIDDEN = "APPOINTMENT_HIDDEN",
  CANCELLED = "CANCELLED",
}

/** DB Tables */
export interface IUser {
  id: string;
  motivityUUID?: string;
  slackId?: string;

  clinicId: string;
  allowedClinicIds?: string[];

  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  permissions: UserPermission[];
  address: IAddress;
  addressNotes: string;
  phoneNumber: string;
  sex: Sex;
  dateOfBirth: string;
  isFullTime: boolean;

  npi: string;
  credentials: ICredential[];

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
  schedule: ISchedule;
  hasSetSchedule?: boolean;
  hasSignedUp?: boolean;

  stats?: IUserStats;
  preferredTransport: TransportMode;
}

// IUserPayRate is a document on the user-pay-rates sub-collection on an IUser
// path: users/{userId}/user-pay-rates/{userPayRateId}
export type IUserPayRate = {
  id: string;
  userId: string;
  clinicId: string;

  startMs: number;

  directRateCents: number;
  indirectRateCents: number;
  salaryRateCents: number;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
};

// IUserAvailability is a document on the user-availability sub-collection on an IUser
// path: users/{userId}/user-availability/{userAvailabilityId}
export interface IUserAvailability {
  id: string;
  clinicId: string;

  approved: boolean;

  availability: IAvailability;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface IWeekdaySchedule {
  enabled: boolean;
  intervals: {
    startMs: Timestamp;
    endMs: Timestamp;
  }[];
}

export type ISchedule = {
  [key in Weekday]: IWeekdaySchedule;
};

export interface IUpdateWorkingHoursRequest {
  id: string;
  userId: string;
  clinicId: string;
  schedule: ISchedule;
  approved?: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  deletedAt?: Timestamp;
}

export interface IClinic {
  id: string;

  name: string;
  displayName: string;
  accessCode: string;
  phoneNumber?: string;
  email?: string;
  address: IAddress;
  addressNotes?: string;

  serviceAreas: string[];
  lookerReportURL: string;
  slackId: string;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface IClient {
  id: string;
  motivityUUID: string | null;
  slackId: string | null;

  clinicId: string;
  guardianId: string;

  firstName: string;
  middleName: string;
  lastName: string;
  preferredName: string;
  alias: string;

  dateOfBirth: string;
  sex: Sex;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface IGuardian {
  id: string;

  clinicId: string;

  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phoneNumber: IPhoneNumber;

  tempPassword?: boolean;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface IInquiry {
  id: string;

  clinicId: string;
  assignedUserId?: string;

  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: IPhoneNumber;
  address: IAddress;

  intakeStatus: InquiryStatus;
  intakeStatusNotes?: string;
  isHot: boolean;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface IClientFile {
  id: string;

  clinicId: string;
  clientId: string;
  guardianId: string;
  assignedUserId?: string;
  assignedBcbaId?: string;
  assignedRbtId?: string;

  address: IAddress;
  addressNotes?: string;

  intakeStatus: IntakeStatus;
  intakeStatusNotes?: string;
  isHot: boolean;

  payers: {
    primary?: IClientPayer;
    secondary?: IClientPayer;
  };

  scheduleNotes?: string;

  documents: IClientDocument[];

  therapyPreferences: ITherapyPreferences;
  behaviors: IBehaviors;
  medicalHistory: IMedicalHistory;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

// IClientAvailability is a document on the client-availability subcollection on an IClientFile
// client-files/{clientFileId}/client-availability/{id}
export interface IClientAvailability {
  id: string;
  clinicId: string;

  minimumHours: number;
  recommendedHours: number;

  availability: IAvailability;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export type IAvailability = {
  [key in Weekday]: IWeekdayAvailability[];
};

export type IWeekdayAvailability = {
  startMs: number;
  endMs: number;
};

export interface INote {
  id: string;
  motivityUUID?: string;

  //leave empty if not matched to an appointment to query
  appointmentId: string;
  clientId: string;
  userId: string;
  clinicId: string;

  noteType: string;

  clientFullName: string;
  providerFullName: string;
  dateOfBirth: string;
  narrative: string;
  location: AppointmentLocation;
  targetData: string;
  behaviorData: string;

  //optional to accomodate for unbillable notes, leave empty if unbillable
  billingCode: BillingCode | string;
  modifiers: Modifier[] | string;

  startMs: number;
  endMs: number;

  //will be NaN if not signed/parsed
  providerSignedMs: number;
  clientSignedMs: number;

  approved: boolean;
  manualOverride: boolean; //default to false
  errors: string[];

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface ICompletedAppointment extends IEvent {
  // Fields added due to IEvent extension,
  // Will be copied over from IAppointment
  // - .description
  // - .attendees

  clientId: string;
  userIds: string[];
  renderingUserId: string; // user of highest status in userIds
  billingUserId: string; // can be whoever
  noteId?: string;

  isBilled: boolean;

  chargeCents: number;
  units: number;
  billingCode: BillingCode;
  modifiers: Modifier[];
  location: AppointmentLocation;

  //Cancellation fields
  cancellationStatus?: CancelledCompletedAppointmentStatus;

  cancellationReason?: CancellationReason;
  cancellationNotes?: string;

  cancellationRejectionReason?: CancellationRejectionReason | null;
  cancellationRejectionNotes?: string | null;

  //store expected start/end times if they are different from actual
  expectedStartMs?: number;
  expectedEndMs?: number;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  cancelledAt?: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface IBilledAppointment {
  id: string; // Primary key, same as GCal event ID
  eventType: EventType;

  clinicId: string;

  clinic: IClinic;
  client: IClient;
  guardian: IGuardian;
  clientFile: IClientFile;
  users: IUser[];
  renderingUser: IUser; // user of highest status in userIds
  billingUser: IUser; // user we are billing under
  note: INote;

  payer: IPayer;
  secondaryPayer?: IPayer;

  chargeCents: number;
  units: number;
  billingCode: BillingCode;
  modifiers: Modifier[];
  location: AppointmentLocation;

  summary: string;

  startMs: number;
  endMs: number;

  //store expected start/end times if they are different from actual
  expectedStartMs?: number;
  expectedEndMs?: number;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  exportedAt?: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface IInvite {
  id: string;

  clinicId: string;

  firstName: string;
  lastName: string;
  email: string;
  role: UserPermission;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

export interface IIndirect extends IEvent {
  indirectReason: IndirectReason;
  status: IndirectStatus;
  rejectionReason?: IndirectRejectionReason | null;
  rejectionNotes?: string | null;

  createdAt: FieldValue | Timestamp;
  updatedAt: FieldValue | Timestamp;
  deletedAt?: FieldValue | Timestamp;
}

/** Abstractions */

export interface IEvent {
  id: string;
  eventType: EventType;

  clinicId: string;
  attendees: { email: string; status: AttendeeStatus }[];

  summary: string;
  description: string;
  startMs: number;
  endMs: number;
}

/**
 * Appointment abstraction. The Google Calendar service serializes
 * calendar_v3.Schema$Event objects into IAppointments
 *
 * @param id The event ID from Google Calendar
 */
export interface IAppointment extends IEvent {
  clientId: string;
  noteId: string | null;

  billingCode: BillingCode;
  modifiers: Modifier[];
  location: AppointmentLocation;

  rrule?: string;
  meetsLink?: string;

  source?: AppointmentSource;
}

export interface IAddress {
  line1: string;
  line2?: string;
  city: string;
  state: USStateCode;
  country: CountryCode;
  zipCode: string;
}

export interface IPhoneNumber {
  primary: string;
  home?: string;
  work?: string;
  cell?: string;
}

export interface IClientPayer {
  payerId?: string;

  memberNum?: string;
  groupNum?: string;
  policyHolder?: IContact;
  policyHolderRelationship?: PolicyHolderRelationship;
  deductible?: number;
  copay?: number;
  photoUrls?: string[];
  auth?: IAuthorization;
}

export interface IAuthorization {
  authNumber: string;
  startDate: string;
  endDate: string;
  auths: IAuthCode;
}

export type IAuthCode = {
  [code in BillingCode]?: {
    unitSize: number;
    units: number;
  };
};

export type IPayerRates = {
  [code in BillingCode]?: {
    [modifier in Modifier]?: {
      unitSize: number;
      rateCents: number; // in cents
    };
  };
};

export type IPayerConfig = {
  [state in USStateCode]?: IStatePayersConfig;
};

export type IAppointmentTypeConfig = {
  [state in USStateCode]?: IAppointmentType;
};

export type IStatePayersConfig = IPayer[];

export type IUserStats = {
  //delivered
  completedMinutes: number;
  indirectMinutes: number;

  //billings
  billedMinutes: number;

  //cancellations
  therapistCancelledMinutes: number;
  clientCancelledMinutes: number;
  otherCancelledMinutes: number;

  //impact
  impactBasisPoints: number;
  impactScore: number;

  //progression
  previousImpactBasisPoints: number;

  calculatedAtMs: number;
};

export interface PayerCode {
  authRequired: boolean;
  unitSize: number | null;
  rates: {
    [billingLevel in BillingLevel]?: number;
  };
}

/**
 * A payer's configuration for a single state
 *
 * @param name the display name of the payer
 * @param payerId the federal payer ID.
 * @param modifiers mapping between billingLevel and coded modifier
 * @param locationModifiers mapping between locations and additional coded modifiers
 * @param codes a mapping of all codes in this payer's contract
 * @param codes.authRequired true if auth is required to bill this code, false otherwise
 * @param codes.unitSize size of a unit for this code, in minutes
 * @param codes.rates mapping between billingLevel and the rate IN CENTS
 */
export interface IPayer {
  name: string;
  payerId: string;
  contractAddress: IAddress;
  billingAddress: IAddress;
  modifiers: {
    [billingLevel in BillingLevel]?: string;
  };
  locationModifiers: {
    [location in AppointmentLocation]?: string;
  };
  codes: {
    [code in BillingCode]?:
      | PayerCode
      | {
          [modifier in Modifier | ""]?: PayerCode;
        };
  };
  getBillingModifiers: (
    appointment: IAppointment | ICompletedAppointment,
    payer: IPayer,
    billingLevel: BillingLevel
  ) => Modifier[];
}

export type IAppointmentConfig = {
  billingCode: BillingCode;
  modifiers: Modifier[];
};

export type IAppointmentType = {
  [apptName in AppointmentType]?: IAppointmentConfig;
};

export interface IClientDocument {
  path: string;
  type: ClientDocumentType;
}

export interface IDiagnostician {
  firstName: string;
  lastName: string;
  officeName: string;
  address: IAddress;
  phoneNumber: string;
  email?: string;
}
export interface IPediatrician {
  firstName: string;
  lastName: string;
  officeName: string;
  address: IAddress;
  phoneNumber: string;
  email?: string;
}

export interface ITherapyPreferences {
  primaryLanguage: string;
  preferredLanguage: string;
  preferredTherapistGender: TherapistGender;
  secondaryContact?: IContact;
  availability: ITherapyAvailability;
}

export type ITherapyAvailability = {
  [key in WeekBlock]?: DayBlock[];
};

export interface IContact {
  firstName: string;
  middleName?: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  sex?: Sex;
  address?: IAddress;
}

export interface ISelfHarm {
  severity: BehaviorSeverity;
  frequency: BehaviorFrequency;
  requireMedicalAttn: boolean;
  headDirected: boolean;
}

export interface IAggression {
  severity: BehaviorSeverity;
  frequency: BehaviorFrequency;
  requireMedicalAttn: boolean;
  bite: boolean;
  weapons: boolean;
}

export interface IRunAway {
  severity: BehaviorSeverity;
  frequency: BehaviorFrequency;
  leaveHome: boolean;
}

export interface IDestroyProperty {
  severity: BehaviorSeverity;
  frequency: BehaviorFrequency;
  targetHighValue: boolean;
  targetGlass: boolean;
  structuralDamage: boolean;
}

export interface IFlopOnGround {
  severity: BehaviorSeverity;
  frequency: BehaviorFrequency;
  inHome: boolean;
  outsideHome: boolean;
}

export interface ITakeOffClothes {
  frequency: BehaviorFrequency;
}

export interface IBehaviors {
  selfHarm?: ISelfHarm;
  aggression?: IAggression;
  runAway?: IRunAway;
  destroyProperty?: IDestroyProperty;
  flopOnGround?: IFlopOnGround;
  takeOffClothes?: ITakeOffClothes;
  goals?: IGoals[];
  otherGoals?: string;
}

export interface IMedicalHistory {
  currentServices?: string[];
  pastServices?: string[];
  livingSituation: string;
  existingDiagnosis?: string;
  medication?: string;
  mentalIllness?: string;
  treatmentImpairments?: string[];
  visionHearingImpairment: boolean;
}

export interface ICredential {
  type: Credential;
  identifier: string;
  expiryMs: number;
  expiryWarningOffsetMs: number;
}

export type ICalendarEvent = IAppointment | IIndirect | ICompletedAppointment;

export interface ICalendarRow {
  person: IUser | IClient;
  sunday: ICalendarEvent[];
  monday: ICalendarEvent[];
  tuesday: ICalendarEvent[];
  wednesday: ICalendarEvent[];
  thursday: ICalendarEvent[];
  friday: ICalendarEvent[];
  saturday: ICalendarEvent[];
}

/**
 * This consolidates all client-related information into one object
 * Right now, it consists of:
 * 1. Client
 * 2. ClientFile
 * 3. ClientAvailability
 */
export interface IClientDetails {
  client: IClient;
  clientFile: IClientFile;
  clientAvailability?: IClientAvailability;
}

/**
 * This consolidates all approval appointments information into one object
 * Right now, it consists of:
 * 1. approvableAppointments
 * 2. missingNoteAppointments
 * 3. pendingIndirects
 * 4. pendingCancellations
 */
export interface IApprovals {
  approvableAppointments: IAppointment[];
  missingNoteAppointments: IAppointment[];
  pendingIndirects: IIndirect[];
  pendingCancellations: ICompletedAppointment[];
}

/**
 * This consolidates all client-related information + guardian into one object
 * Note: The client object is not optional
 */
export interface IClientGuardianDetails extends IClientDetails {
  guardian: IGuardian;
}

/**
 * This consolidates all client-related information + guardian into one object
 * Note: The difference between this and `IClientGuardianDetails` is that clients can be optional
 */
export interface IGuardianClientDetails extends Partial<IClientDetails> {
  clientFile: IClientFile;
  guardian: IGuardian;
}

export interface ICalendarEventExtended {
  event: ICalendarEvent;
  users: IUser[];
  clientDetails?: IClientDetails;
  notes?: INote[];
  date: string; //YYYY-MM-DD
  type: CalendarEventType;
}

export type ICalendarEventExtendedByDay = Map<string, ICalendarEventExtended[]>;
