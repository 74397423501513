import { CloseOutlined } from "@ant-design/icons";
import {
  BillingCode,
  capitalizeFirstLetter,
  getCurrentActiveAuth,
  IClientFile,
  ICompletedAppointment,
  Modifier,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Button, Col, DatePicker, Progress, Row, Skeleton, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import React from "react";

import { DISPLAY_DATE_FORMAT } from "../../consts";
import {
  calculateExpectedAndAuthorizedHoursForRange,
  getHoursFromAuthByBillingCode,
} from "../../helpers/schedules";
import * as FirestoreService from "../../services/firestore";

interface IClientAuthSummaryProps {
  clientFile: IClientFile;
  clinicId: string;
  closePopup?: () => void;
}
const { Text } = Typography;

const BILLING_CODE_MODS: { [key in BillingCode]?: Modifier[] } = {
  [BillingCode.CODE_97153]: [],
  [BillingCode.CODE_97155]: [],
  [BillingCode.CODE_97156]: [],
  [BillingCode.CODE_T1026]: [Modifier.UD, Modifier.UC],
};

enum AuthSummaryRange {
  AUTH = "Current Authorization",
  MONTH = "This Month",
  FUTURE = "To End of Authorization",
}

export const ClientAuthSummary = ({
  clientFile,
  clinicId,
  closePopup,
}: IClientAuthSummaryProps) => {
  const currentAuth = getCurrentActiveAuth(clientFile.payers?.primary ?? null);
  const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment]>([
    moment(currentAuth?.startDate),
    moment(currentAuth?.endDate),
  ]);
  const [completedAppts, setCompletedAppts] = useState<ICompletedAppointment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const presetRanges: Record<string, [moment.Moment, moment.Moment]> = {
    [AuthSummaryRange.AUTH]: [moment(currentAuth?.startDate), moment(currentAuth?.endDate)],
    [AuthSummaryRange.MONTH]: [moment().startOf("month"), moment().endOf("month")],
    [AuthSummaryRange.FUTURE]: [moment(), moment(currentAuth?.endDate)],
  };

  const roundToNearestHalf = (num: number) => {
    return Number((Math.round(num * 2) / 2).toFixed(1));
  };

  const fetchData = async () => {
    setIsLoading(true);
    const [start, end] = dateRange;
    const appts = await FirestoreService.getCompletedAppointmentByClinicClientAndRange({
      clinicId,
      clientId: clientFile.clientId,
      startMs: start.valueOf(),
      endMs: end.valueOf(),
    });
    setCompletedAppts(appts);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData().catch(() => {});
  }, [dateRange]);

  const aggregateHoursSummary: Record<
    BillingCode,
    { [key in Modifier]?: { completed: number; cancelled: number } } & {
      completed: number;
      cancelled: number;
    }
  > = completedAppts.reduce((acc, appt: ICompletedAppointment) => {
    const { billingCode, modifiers, startMs, endMs, cancelledAt } = appt;
    const isCancelled = !!cancelledAt;
    if (!acc[billingCode]) acc[billingCode] = { completed: 0, cancelled: 0 };

    const billingGroup = acc[billingCode];
    modifiers.forEach((mod) => {
      if (!billingGroup[mod]) billingGroup[mod] = { completed: 0, cancelled: 0 };
      if (isCancelled) {
        billingGroup[mod].cancelled += (endMs - startMs) / (1000 * 60 * 60);
      } else {
        billingGroup[mod].completed += (endMs - startMs) / (1000 * 60 * 60);
      }
    });
    if (isCancelled) {
      billingGroup.cancelled += (endMs - startMs) / (1000 * 60 * 60);
    } else {
      billingGroup.completed += (endMs - startMs) / (1000 * 60 * 60);
    }

    return acc;
  }, Object.create(null));

  const authHours = currentAuth ? getHoursFromAuthByBillingCode(currentAuth, dateRange) : {};

  const renderStat = (title: string, hours: number, color?: string) => (
    <Col style={{ margin: "0 5px", textAlign: "center" }}>
      <Text style={{ fontSize: 12, color }}>{capitalizeFirstLetter(title)}</Text>
      <div style={{ position: "relative", top: -5 }}>
        <Text style={{ fontSize: 12, fontWeight: "bold", color }}>{hours}</Text>
        <Text style={{ fontSize: 9, fontWeight: "bold", color }}> hours</Text>
      </div>
    </Col>
  );

  const renderProgress = (
    code: BillingCode,
    completed: number,
    expected: number,
    authorized: number,
    cancelled: number,
    missed: number,
    mod?: Modifier
  ) => {
    return (
      <React.Fragment key={`${code}${mod || ""}`}>
        <Text
          style={{
            fontSize: 12,
            fontWeight: "600",
            position: "relative",
            bottom: -7,
          }}
        >
          {`${code} ${mod || ""}`}
        </Text>
        <Progress
          status="active"
          width={450}
          success={{
            percent: (completed * 100) / authorized,
            strokeColor: COLORS.LIGHT_BLUE,
          }}
          percent={(expected * 100) / authorized}
          trailColor={COLORS.GRAY}
          strokeColor={COLORS.LIGHT_ORANGE}
          showInfo={false}
        />
        <Row justify="space-between" style={{ width: "100%", position: "relative", top: -5 }}>
          {renderStat("Completed", roundToNearestHalf(completed))}
          {renderStat("Remaining", roundToNearestHalf(authorized - completed))}
          {renderStat("Expected", roundToNearestHalf(expected))}
          {renderStat("Authorized", roundToNearestHalf(authorized))}
          {renderStat("Cancellations", roundToNearestHalf(cancelled))}
        </Row>
      </React.Fragment>
    );
  };

  const getAuthForCode = (code: BillingCode) => {
    switch (code) {
      case BillingCode.CODE_97153:
        return authHours[code] || 0;
      case BillingCode.CODE_97156:
        return authHours[code] || (authHours[BillingCode.CODE_97153] || 0) * 0.1;
      case BillingCode.CODE_97155:
      case BillingCode.CODE_T1026:
        return authHours[code] || (authHours[BillingCode.CODE_97153] || 0) * 0.1;
      default:
        return 0;
    }
  };

  const renderProgressForCode = (code: BillingCode, modifiers: Modifier[], key: number) => {
    if (!authHours[code] && !(aggregateHoursSummary && aggregateHoursSummary[code])) return null;
    const authorizedHours = getAuthForCode(code);
    if (modifiers.length > 0) {
      return (
        <React.Fragment key={key}>
          {modifiers.map((mod: Modifier) => {
            const completeCancelHours =
              aggregateHoursSummary &&
              aggregateHoursSummary[code] &&
              aggregateHoursSummary[code][mod];
            const [completed, expected, authorized, missedHours, expectedCompletionBeforeTracking] =
              [
                completeCancelHours?.completed || 0,
                ...calculateExpectedAndAuthorizedHoursForRange(
                  completeCancelHours?.completed || 0,
                  authorizedHours,
                  currentAuth ?? null,
                  dateRange,
                  moment()
                ),
              ];
            return renderProgress(
              code,
              completed + expectedCompletionBeforeTracking,
              expected,
              authorized,
              completeCancelHours?.cancelled || 0,
              missedHours,
              mod
            );
          })}
        </React.Fragment>
      );
    } else {
      const [completed, expected, authorized, missedHours, expectedCompletionBeforeTracking] = [
        aggregateHoursSummary[code]?.completed || 0,
        ...calculateExpectedAndAuthorizedHoursForRange(
          aggregateHoursSummary[code]?.completed || 0,
          authorizedHours,
          currentAuth,
          dateRange,
          moment()
        ),
      ];

      return renderProgress(
        code,
        completed + expectedCompletionBeforeTracking,
        expected,
        authorized,
        aggregateHoursSummary[code]?.cancelled || 0,
        missedHours
      );
    }
  };

  const getDateRangeHeaderContent = () => {
    const [start, end] = dateRange;
    for (const [content, [rangeStart, rangeEnd]] of Object.entries(presetRanges)) {
      if (start.isSame(rangeStart, "day") && end.isSame(rangeEnd, "day")) {
        return content;
      }
    }
  };

  return (
    <div style={{ width: 400 }} className="progress">
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <DatePicker.RangePicker
          value={dateRange}
          onChange={(range) =>
            setDateRange([range?.[0] || dateRange[0], range?.[1] || dateRange[1]])
          }
          format={DISPLAY_DATE_FORMAT}
          ranges={presetRanges}
        />
        <div
          style={{
            float: "left",
            marginLeft: "20px",
            marginTop: "-7px",
            position: "absolute",
            background: "white",
            fontSize: "10px",
            padding: "0px 4px",
          }}
        >
          {getDateRangeHeaderContent()}
        </div>
        <Button type="text" size="small" onClick={closePopup}>
          <CloseOutlined />
        </Button>
      </Row>
      {isLoading ? (
        <Skeleton active={true} style={{ height: 350 }} />
      ) : (
        <Row align="middle" style={{ marginBottom: 5, width: "100%" }}>
          {Object.entries(BILLING_CODE_MODS).map(([code, mods], key) =>
            renderProgressForCode(code as BillingCode, mods, key)
          )}
        </Row>
      )}
    </div>
  );
};
