import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { COLORS } from "@finni-health/ui";
import { Button, Divider, Form, Input, Row, Select } from "antd";

import { useUserClinics } from "../UserClinicsProvider";

const DynamicFirestoreRequestFields = () => {
  const { clinic } = useUserClinics();

  return (
    <Form.List name="parameters" initialValue={[]}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Form.Item name={[index, "key"]} label="Field" rules={[{ required: true }]}>
                  <Input placeholder="clinicId" />
                </Form.Item>
                <Form.Item
                  label="Condition"
                  name={[index, "condition"]}
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      { value: "==", label: "==" },
                      { value: "!=", label: "!=" },
                      { value: ">", label: ">" },
                      { value: ">=", label: ">=" },
                      { value: "<", label: "<" },
                      { value: "<=", label: "<=" },
                      { value: "array-contains", label: "array-contains" },
                      {
                        value: "array-contains-any",
                        label: "array-contains-any",
                      },
                      { value: "in", label: "in" },
                      { value: "not-in", label: "not-in" },
                      { value: "disabled", label: "Disabled", disabled: true },
                    ]}
                  ></Select>
                </Form.Item>
                <Form.Item label="Value" name={[index, "value"]} rules={[{ required: true }]}>
                  <Input placeholder="ugWUb1mtAb7UPWXZiikx" />
                </Form.Item>
                {fields.length > 0 ? (
                  <Button
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    icon={<MinusCircleOutlined />}
                  >
                    Remove Above Condition
                  </Button>
                ) : null}
                <Divider />
              </div>
            ))}
            <Row gutter={24}>
              <Form.Item>
                <Button type="dashed" onClick={() => add()} style={{ color: COLORS.PRIMARY }}>
                  <PlusOutlined /> Add Request Condition
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add({ key: "clinicId", value: clinic.id, condition: "==" }, 0)}
                  style={{ marginLeft: 20, color: COLORS.PRIMARY }}
                >
                  <PlusOutlined /> Add clinicId
                </Button>
              </Form.Item>
            </Row>
          </div>
        );
      }}
    </Form.List>
  );
};

export default DynamicFirestoreRequestFields;
