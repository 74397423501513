import { SCA_SUFFIX_TEXT } from "../../consts";
import {
  BillingCode,
  BillingLevel,
  IAppointment,
  IAppointmentType,
  ICompletedAppointment,
  IPayer,
  IStatePayersConfig,
  USStateCode,
} from "../../types";
import { generateAddress, getBaseBillingModifiers } from "./helpers";

export const CONNECTICUT_APPOINTMENTS: IAppointmentType = {};

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  billingLevel: BillingLevel
) => {
  return getBaseBillingModifiers(appointment, payer, billingLevel);
};

export const CONNECTICUT_PAYERS: IStatePayersConfig = [
  {
    name: "Blue Cross Blue Shield (Single Case Agreement)",
    payerId: `00060${SCA_SUFFIX_TEXT}`,
    contractAddress: generateAddress(
      "124 Putney Dr",
      "",
      "West Haven",
      "06516-2931",
      USStateCode.CT
    ),
    billingAddress: generateAddress(
      "124 Putney Dr",
      "",
      "West Haven",
      "06516-2931",
      USStateCode.CT
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2248,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1775,
          BCBA: 1775,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2241,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2247,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
];
