import { IClient } from "../types";

const ANTD_DEFAULT_COLORS = [
  "magenta",
  "purple",
  "volcano",
  "orange",
  "blue",
  "lime",
  "green",
  "cyan",
  "gold",
  "geekblue",
  "red",
];

export const capitalizeName = (str: string) => {
  let nameArray = str.split(" ");
  nameArray = nameArray.map((name: string) => capitalizeFirstLetter(name));
  return nameArray.join(" ");
};

export const capitalizeFirstLetter = (str: string) => {
  return str && str[0].toUpperCase() + str.slice(1).toLocaleLowerCase();
};

export const getInitials = (firstName: string, lastName: string) => {
  return firstName.slice(0, 2) + lastName.slice(0, 2);
};

export const getInitialsFromClient = (client: IClient) => {
  return client && client.firstName.slice(0, 2) + client.lastName.slice(0, 2);
};

export const stringToColor = (s: string) => {
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    hash = s.charCodeAt(i) + ((hash << 5) - hash);
  }

  return ANTD_DEFAULT_COLORS[Math.abs(hash) % ANTD_DEFAULT_COLORS.length];
};

// turn all caps with underscores into title case
export const allCapsSnakeCaseToTitleCase = (type: string) => {
  return type
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
