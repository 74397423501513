import { DeleteOutlined } from "@ant-design/icons";
import { BillingCode } from "@finni-health/shared";
import { Button, Card, Row, Typography } from "antd";

const { Text } = Typography;

interface IProps {
  code: BillingCode;
  units: number;
  unitSize: number;
  deleteCode: (code: BillingCode) => void;
}

export const PayerAuthCodeCard = ({ code, units, unitSize, deleteCode }: IProps) => {
  return (
    <Card
      bodyStyle={{
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <Row justify="space-between" align="middle">
        <Text strong>{code.toUpperCase()}</Text>
        <Button
          icon={<DeleteOutlined />}
          type="text"
          size="small"
          onClick={() => deleteCode(code)}
        />
      </Row>
      <Text>{`${unitSize} mins/unit`}</Text>
      <br />
      <Text>{`${units} units`}</Text>
    </Card>
  );
};
