import { IAvailability, IClient, IClientAvailability, IClientFile } from "@finni-health/shared";
import { CopyLinkToClipboard, EditAvailabilityTable } from "@finni-health/ui";
import { ClientHours } from "@finni-health/ui";
import { Button, Col, Form, Input, message, Row } from "antd";
import { useState } from "react";

import * as FirestoreService from "../../../services/firestore";

interface Props {
  client: IClient;
  clientFile: IClientFile;
  clientAvailability?: IClientAvailability;
  parentPortalLink: string;
  refreshCallback: () => Promise<void>;
}

export const ClientAvailabilityTab = ({
  clientFile,
  clientAvailability,
  parentPortalLink,
  refreshCallback,
}: Props) => {
  const [isEditingDisabled, setIsEditingDisabled] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [inputHours, setInputHours] = useState<number>(0);
  const [tempClientAvailability, setTempClientAvailability] = useState<IClientAvailability>(
    clientAvailability as IClientAvailability
  );
  const [tempAvailability, setTempAvailability] = useState<IAvailability>(
    clientAvailability?.availability as IAvailability
  );

  const handleCancelEditing = async () => {
    setIsSaving(true);
    await refreshCallback();
    setIsEditingDisabled(true);
    setIsSaving(false);
  };

  const handleSaveClientAvailability = async () => {
    setIsSaving(true);
    try {
      const newClientAvailability = {
        ...tempClientAvailability,
        availability: tempAvailability,
        clientFileId: clientFile.id,
        clinicId: clientFile.clinicId,
      };

      await FirestoreService.updateClientAvailability(newClientAvailability);
      await refreshCallback();
      setIsEditingDisabled(true);
      void message.success("Changes saved");
    } catch (err) {
      void message.error("Error saving changes");
    }
    setIsSaving(false);
  };

  return (
    <div style={{ marginBottom: 25 }}>
      <Row justify="end">
        {isEditingDisabled && (
          <Button
            type="primary"
            loading={isSaving}
            style={{ marginLeft: 16, marginBottom: 10, marginTop: 10 }}
            disabled={!isEditingDisabled}
            onClick={() => {
              setIsEditingDisabled(false);
            }}
          >
            Edit Client Availability
          </Button>
        )}
        {!isEditingDisabled && (
          <Row>
            <Button
              disabled={isSaving}
              style={{ marginLeft: 16, marginBottom: 10, marginTop: 10 }}
              onClick={handleCancelEditing}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={isSaving}
              disabled={false}
              style={{ marginLeft: 16, marginBottom: 10, marginTop: 10 }}
              onClick={handleSaveClientAvailability}
            >
              Save
            </Button>
          </Row>
        )}
      </Row>
      <CopyLinkToClipboard
        link={parentPortalLink}
        title="Parent Portal Link"
        message="Parents can input their weekly availability using this link"
      />
      <Row gutter={24}>
        <Col span={12}>
          <Form layout="vertical" labelCol={{ span: 24 }}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Recommended therapy hours">
                  <Input
                    disabled={isEditingDisabled}
                    value={tempClientAvailability?.recommendedHours}
                    onChange={(e) =>
                      setTempClientAvailability((prev) => ({
                        ...prev,
                        recommendedHours: Number(e.target.value),
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Minimum therapy hours">
                  <Input
                    disabled={isEditingDisabled}
                    value={tempClientAvailability?.minimumHours}
                    onChange={(e) =>
                      setTempClientAvailability((prev) => ({
                        ...prev,
                        minimumHours: Number(e.target.value),
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {clientAvailability && (
        <>
          <Row
            justify="center"
            style={{
              marginTop: 40,
            }}
          >
            <ClientHours
              minimumHours={clientAvailability.minimumHours}
              recommendedHours={clientAvailability.recommendedHours}
              inputHours={inputHours}
            />
          </Row>
          <Row justify="center">
            <EditAvailabilityTable
              isEditingDisabled={isEditingDisabled}
              onHoursChange={setInputHours}
              onAvailabilityChange={setTempAvailability}
              initialAvailability={tempAvailability}
            />
          </Row>
        </>
      )}
    </div>
  );
};
