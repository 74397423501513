import "../css/ListViewItem.css";

import { green, red } from "@ant-design/colors";
import {
  CheckCircleFilled,
  CheckOutlined,
  ClockCircleFilled,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FileFilled,
  InfoCircleFilled,
  SplitCellsOutlined,
  StopFilled,
  VideoCameraFilled,
} from "@ant-design/icons";
import {
  AppointmentLocation,
  AttendeeStatus,
  CalendarEventType,
  CalendarType,
  CancelledCompletedAppointmentStatus,
  getAppointmentNameFromBilling,
  IAddress,
  IAppointment,
  ICalendarEvent,
  ICalendarEventExtended,
  IClient,
  IClientDetails,
  IClinic,
  ICompletedAppointment,
  IIndirect,
  IndirectStatus,
  INote,
  IUser,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { memo, useEffect, useMemo, useState } from "react";
import { BsFillShieldSlashFill } from "react-icons/bs";
import { FaSchool, FaStamp } from "react-icons/fa";
import { HiBuildingOffice } from "react-icons/hi2";
import { IoIosHome } from "react-icons/io";
import { IoSchoolSharp } from "react-icons/io5";
import { MdDirectionsOff, MdNaturePeople } from "react-icons/md";
import { RiRocket2Fill } from "react-icons/ri";

import { DISPLAY_TIME_FORMAT } from "../../consts";
import { getAppointmentLocationText } from "../../helpers/appointments";
import {
  isNoteApprovable,
  noteRequiresLibraryData,
  noteRequiresParentSignature,
} from "../../helpers/appointments";
import { getBillingCodeColor } from "../../helpers/colors";
import { formatAddress } from "../../helpers/profiles";
import { AppointmentCard } from "./AppointmentCard";
import { CompletedAppointmentCard } from "./CompletedAppointmentCard";
import { IndirectCard } from "./IndirectCard";
import { CalendarEventColors } from "./ListView";

const { Text, Paragraph } = Typography;

interface IProps {
  type: CalendarEventType;
  clinicUsers: IUser[];
  clientDetails?: IClientDetails;
  event: ICalendarEvent;
  eventExtended: ICalendarEventExtended;
  handleSelectedEvent: (event: ICalendarEvent) => void;
  handleRefresh: (event?: ICalendarEvent) => Promise<void>;
  eventsExtended: ICalendarEventExtended[];
  handleMatchNotesDrawerOpen: (isOpen: boolean) => void;
  handleSelectedClient: (client: IClient) => void;
  handleCheckboxChange: (e: any, eventExtended: ICalendarEventExtended) => void;
  selectedEventsExtended: ICalendarEventExtended[];
  notes?: INote[];
  isAuthorized: any;
  clinic: IClinic;
  users: IUser[];
  extendedView?: boolean;
}

export const ListViewItem: React.FC<IProps> = memo(
  function ListViewItem({
    type,
    clinicUsers,
    clientDetails,
    event,
    eventExtended,
    handleSelectedEvent,
    handleRefresh,
    eventsExtended,
    handleMatchNotesDrawerOpen,
    handleSelectedClient,
    handleCheckboxChange,
    selectedEventsExtended,
    notes,
    isAuthorized,
    clinic,
    users,
    extendedView,
  }: IProps) {
    const APPOINTMENT_CARD_STYLE: React.CSSProperties = {
      visibility: "hidden",
      width: 0,
      height: 0,
      padding: 0,
      marginBottom: 0,
      marginLeft: 450,
    };

    const ICON_STYLE: React.CSSProperties = {
      fontSize: 15,
      marginTop: 5,
      marginLeft: 8,
    };

    const AppointmentLocationIcons = {
      [AppointmentLocation.OFFICE]: <HiBuildingOffice style={ICON_STYLE} />,
      [AppointmentLocation.TELEHEALTH]: <VideoCameraFilled style={ICON_STYLE} />,
      [AppointmentLocation.SCHOOL]: <FaSchool style={ICON_STYLE} />,
      [AppointmentLocation.HOME]: <IoIosHome style={ICON_STYLE} />,
      [AppointmentLocation.OTHER]: <MdNaturePeople style={ICON_STYLE} />,
    };

    const [notesStore, setNotesStore] = useState<INote[]>(notes || []);
    // const [actions, onActionsChange] = useState<any>({});
    let actions;

    const onActionsChange = (onActionsChange) => {
      actions = onActionsChange;
    };

    const unapprovedNoteCount = useMemo(
      () => notesStore.filter((note) => !note.approved).length,
      [notesStore]
    );

    const cardColor = useMemo(() => {
      return type === CalendarEventType.APPOINTMENT && unapprovedNoteCount > 0
        ? COLORS.BLUE
        : CalendarEventColors[type];
    }, [type, unapprovedNoteCount]);

    useEffect(() => {
      setNotesStore(notes || []);
    }, [eventExtended]);

    const AppointmentCards = () =>
      useMemo(() => {
        if (
          [CalendarEventType.COMPLETED, CalendarEventType.CANCELLED].includes(type) &&
          clientDetails
        ) {
          return (
            <CompletedAppointmentCard
              users={clinicUsers}
              client={clientDetails.client}
              completedAppointment={event as ICompletedAppointment}
              forceOpen={false}
              setSelectedEvent={handleSelectedEvent}
              refreshAppointments={() => handleRefresh(event)}
              appointment={
                eventsExtended.find(
                  (e) => e.type === CalendarEventType.APPOINTMENT_HIDDEN && e.event.id === event.id
                )?.event as IAppointment
              }
              isCalendarProcessing={false}
              style={APPOINTMENT_CARD_STYLE}
              onActionsChange={onActionsChange}
            />
          );
        }
        if (type === CalendarEventType.APPOINTMENT && clientDetails) {
          return (
            <AppointmentCard
              client={clientDetails.client}
              appointment={event as IAppointment}
              users={clinicUsers}
              calendarType={CalendarType.LIST}
              setIsMatchNotesDrawerOpen={handleMatchNotesDrawerOpen}
              refreshAppointments={() => handleRefresh(event)}
              refreshAll={handleRefresh}
              forceOpen={false}
              setSelectedAppointment={handleSelectedEvent}
              setSelectedClient={handleSelectedClient}
              isCalendarProcessing={false}
              addAppointmentMissingNote={() => {} /*do nothing, multi-week breaks this*/}
              clientFile={clientDetails.clientFile}
              style={APPOINTMENT_CARD_STYLE}
              onNotesChange={setNotesStore}
              onActionsChange={onActionsChange}
            />
          );
        }
        if (type === CalendarEventType.INDIRECT) {
          return (
            <IndirectCard
              indirect={event as IIndirect}
              forceOpen={false}
              setSelectedEvent={handleSelectedEvent}
              refreshCallback={handleRefresh}
              isCalendarProcessing={false}
              style={APPOINTMENT_CARD_STYLE}
              onActionsChange={onActionsChange}
            />
          );
        }
        return null;
      }, [event]);

    const StatusIcon = () =>
      useMemo(() => {
        switch (type) {
          case CalendarEventType.COMPLETED:
            return (event as ICompletedAppointment).isBilled ? (
              <Tooltip overlayClassName="list-view-item" title={`Billed`}>
                <RiRocket2Fill
                  style={{
                    transform: "rotate(45deg)",
                    marginRight: 4,
                    fontSize: 20,
                    color: COLORS.GREEN,
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip overlayClassName="list-view-item" title={`Completed`}>
                <FaStamp
                  style={{
                    marginLeft: -10,
                    position: "relative",
                    left: 14,
                    bottom: 2,
                    color: "white",
                    fontSize: 10,
                  }}
                />
                <InfoCircleFilled
                  style={{
                    marginTop: 4,
                    marginBottom: -4,
                    marginRight: 4,
                    fontSize: 18,
                    color: COLORS.GREEN,
                  }}
                />
              </Tooltip>
            );

          case CalendarEventType.APPOINTMENT:
            return (
              <Row align="middle">
                {!_.isEmpty(notesStore) ? (
                  unapprovedNoteCount > 0 ? (
                    <Tooltip
                      title={`${unapprovedNoteCount} unapproved note${
                        unapprovedNoteCount > 1 ? "s" : ""
                      }`}
                      className="status-icon"
                    >
                      <Text
                        style={{
                          position: "relative",
                          marginLeft: -6,
                          left: 12,
                          bottom: 2,
                          color: "white",
                          fontSize: 12,
                        }}
                        strong
                      >
                        {unapprovedNoteCount}
                      </Text>
                      <FileFilled
                        style={{
                          marginRight: 4,
                          marginTop: 2,
                          marginBottom: -2,
                          fontSize: 18,
                          color: COLORS.BLUE,
                        }}
                      />
                    </Tooltip>
                  ) : (
                    //Approved Notes
                    <Tooltip
                      title={`${notesStore?.length} approved note${
                        (notesStore?.length || 0) > 1 ? "s" : ""
                      }`}
                      className="status-icon"
                    >
                      {(notesStore?.length || 0) > 1 && (
                        <Text
                          style={{
                            position: "relative",
                            left: -5,
                            bottom: 2,
                            color: COLORS.GREEN,
                            fontSize: 12,
                          }}
                          strong
                        >
                          {notesStore?.length}
                        </Text>
                      )}
                      <CheckCircleFilled
                        style={{
                          marginRight: 4,
                          fontSize: 18,
                          color: COLORS.GREEN,
                        }}
                      />
                    </Tooltip>
                  )
                ) : (
                  moment() > moment(event.endMs) && (
                    //Missing Notes
                    <Tooltip
                      overlayClassName="list-view-item"
                      title="Session notes missing"
                      className="status-icon"
                    >
                      <ExclamationCircleFilled
                        style={{
                          marginTop: -2,
                          marginRight: 4,
                          fontSize: 18,
                          color: COLORS.DARK_YELLOW,
                        }}
                      />
                    </Tooltip>
                  )
                )}
                {!isAuthorized && (
                  <Tooltip overlayClassName="list-view-item" title="Auth expired">
                    <BsFillShieldSlashFill
                      style={{
                        position: "relative",
                        fontSize: 16,
                        color: COLORS.DARK_YELLOW,
                      }}
                    />
                  </Tooltip>
                )}
              </Row>
            );

          case CalendarEventType.CANCELLED:
            return (
              <Tooltip
                title={
                  <Col>
                    <Row>Cancelled Session</Row>
                    <Row>{`Reason: ${(event as ICompletedAppointment).cancellationReason}`}</Row>
                    <Row>
                      {`Description: ${(event as ICompletedAppointment).cancellationNotes}`}
                    </Row>
                  </Col>
                }
                className="status-icon"
                style={{ width: 200 }}
              >
                <StopFilled
                  style={{
                    transform: "rotate(135deg)",
                    color: COLORS.RED,
                    marginRight: 5,
                    fontSize: 16,
                  }}
                />
              </Tooltip>
            );

          case CalendarEventType.INDIRECT:
            return (
              <Tooltip overlayClassName="list-view-item" title="Indirect" className="status-icon">
                <MdDirectionsOff
                  style={{
                    color: cardColor,
                    marginTop: 4,
                    marginRight: 4,
                    fontSize: 18,
                  }}
                />
              </Tooltip>
            );

          default:
            return null;
        }
      }, [event]);

    const LocationIcons = () =>
      useMemo(() => {
        if (type !== CalendarEventType.INDIRECT) {
          const note = notesStore?.[0];
          const noteLocationDiffers =
            !_.isEmpty(note) &&
            note.location !== (event as Exclude<ICalendarEvent, IIndirect>).location;
          return (
            <Row>
              <Tooltip
                title={getAppointmentLocationText(
                  (event as Exclude<ICalendarEvent, IIndirect>).location
                )}
              >
                <Row>
                  <div style={{ color: cardColor }}>
                    {
                      AppointmentLocationIcons[
                        (event as Exclude<ICalendarEvent, IIndirect>).location
                      ]
                    }
                  </div>
                  {extendedView && (
                    <>
                      <div
                        style={{
                          marginTop: 2,
                          marginLeft: noteLocationDiffers ? -14 : 6,
                          textDecoration: noteLocationDiffers ? "line-through" : "none",
                          opacity: noteLocationDiffers ? 0.5 : 1,
                        }}
                      >
                        {`${
                          noteLocationDiffers ? "".padEnd(2, String.fromCharCode(10240)) : ""
                        }${getAppointmentLocationText(
                          (event as Exclude<ICalendarEvent, IIndirect>).location
                        )}`}
                      </div>
                      {noteLocationDiffers && <div>{","}</div>}
                    </>
                  )}
                </Row>
              </Tooltip>
              {extendedView && noteLocationDiffers && <NoteLocationIcons />}
            </Row>
          );
        }
        return null;
      }, [event]);

    const NoteLocationIcons = () =>
      useMemo(() => {
        const note = notesStore?.[0];
        if (note && type !== CalendarEventType.INDIRECT) {
          return (
            <Tooltip
              overlayClassName="list-view-item"
              title={getAppointmentLocationText(note.location)}
            >
              <Row>
                <div style={{ color: cardColor, marginLeft: 3 }}>
                  {AppointmentLocationIcons[note.location]}
                </div>
                {eventExtended && (
                  <div style={{ marginTop: 2, marginLeft: 6 }}>
                    {`${getAppointmentLocationText(note.location)}`}
                  </div>
                )}
              </Row>
            </Tooltip>
          );
        }
        return null;
      }, [event]);

    const BillingTags = () =>
      useMemo(() => {
        if (type !== CalendarEventType.INDIRECT) {
          return (
            <Tooltip
              title={`${(event as Exclude<ICalendarEvent, IIndirect>).billingCode} ${
                (event as Exclude<ICalendarEvent, IIndirect>).modifiers?.join(" ") || ""
              }`}
            >
              <Tag
                color={getBillingCodeColor(
                  (event as Exclude<ICalendarEvent, IIndirect>).billingCode,
                  (event as Exclude<ICalendarEvent, IIndirect>).modifiers
                )}
                style={{
                  marginBottom: -3,
                  marginTop: 3,
                  marginLeft: 4,
                }}
              >
                {getAppointmentNameFromBilling(
                  clinic.address.state,
                  (event as Exclude<ICalendarEvent, IIndirect>).billingCode,
                  (event as Exclude<ICalendarEvent, IIndirect>).modifiers
                )}
              </Tag>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              overlayClassName="list-view-item"
              title={`${(event as IIndirect).description}`}
            >
              <Tag
                color={`${cardColor}26`}
                style={{
                  marginBottom: -3,
                  marginTop: 3,
                  color: cardColor,
                  borderColor: `${cardColor}66`,
                }}
              >
                Indirect
              </Tag>
            </Tooltip>
          );
        }
      }, [event]);

    const AttendeeTags = () =>
      useMemo(() => {
        return (
          <Tooltip overlayClassName="list-view-item" title="Attendees">
            <div style={{ marginLeft: 8 }}>
              {_.uniqBy(
                [
                  ...((event as Exclude<ICalendarEvent, ICompletedAppointment>).attendees || []),
                  ...users.map((user) => ({
                    email: user.email,
                    status: AttendeeStatus.ACCEPTED,
                  })),
                ],
                (attendee) => attendee.email.trim()
              )
                .sort((a, b) => a.email.localeCompare(b.email))
                .map((attendee) => (
                  <Tag
                    key={attendee.email}
                    style={{
                      fontSize: 12,
                      marginTop: 3,
                      marginBottom: -3,
                    }}
                  >
                    <Badge
                      status={
                        attendee.status === AttendeeStatus.ACCEPTED
                          ? "success"
                          : attendee.status === AttendeeStatus.DECLINED
                          ? "error"
                          : attendee.status === AttendeeStatus.TENTATIVE
                          ? "warning"
                          : "default"
                      }
                      style={{ marginRight: 3 }}
                    />
                    {attendee.email}
                  </Tag>
                ))}
            </div>
          </Tooltip>
        );
      }, [event, users]);

    const ClientIcon = () =>
      useMemo(() => {
        if (type !== CalendarEventType.INDIRECT) {
          return (
            <Tooltip
              title={
                <Col>
                  <Row>
                    {`Name: ${clientDetails?.client.firstName} ${clientDetails?.client.lastName}`}
                  </Row>
                  <Row>
                    {`Address: ${formatAddress(
                      clientDetails?.clientFile?.address || ({} as IAddress)
                    )}`}
                  </Row>
                </Col>
              }
            >
              <IoSchoolSharp
                style={{
                  color: cardColor,
                  fontSize: 16,
                  marginBottom: -2,
                  marginLeft: 12,
                  marginRight: 8,
                }}
              />
              <>{clientDetails?.client.alias}</>
            </Tooltip>
          );
        }
        return null;
      }, [event, clientDetails]);

    const AppointmentTimeRange = () =>
      useMemo(() => {
        const note = notesStore?.[0];
        const noteTimeDiffers =
          !_.isEmpty(note) &&
          (!moment(note.startMs).isSame(moment(event.startMs), "minute") ||
            !moment(note.endMs).isSame(moment(event.endMs), "minute"));
        return (
          <>
            <Tooltip
              title={
                //Full date and time with duration
                <>
                  <>{`Date: ${moment(event.startMs).format(
                    `dddd, MMM Do, ${DISPLAY_TIME_FORMAT}`
                  )} to ${moment(event.endMs).format(
                    moment(event.startMs).isSame(moment(event.startMs), "day")
                      ? DISPLAY_TIME_FORMAT
                      : `dddd, MMM Do, ${DISPLAY_TIME_FORMAT}`
                  )}`}</>
                  <br />
                  <>{`Duration: ${moment
                    .duration(moment(event.endMs).diff(moment(event.startMs)))
                    .humanize()}
            `}</>
                  <br />
                  <>{` Start: ${moment(event.startMs).fromNow()}`}</>
                </>
              }
            >
              <ClockCircleFilled
                style={{
                  color: cardColor,
                  fontSize: 14,
                  marginRight: 8,
                  marginTop: 4,
                  marginLeft: 10,
                }}
              />
              <Text
                style={{
                  textDecoration: noteTimeDiffers ? "line-through" : "none",
                  opacity: noteTimeDiffers ? 0.5 : 1,
                }}
              >
                {`${moment(event.startMs).format(DISPLAY_TIME_FORMAT)} - ${moment(
                  event.endMs
                ).format(DISPLAY_TIME_FORMAT)}`}
              </Text>
            </Tooltip>
            {noteTimeDiffers && <NoteTimeRange />}
          </>
        );
      }, [event]);

    const NoteTimeRange = () =>
      useMemo(() => {
        return (
          notesStore?.[0] && (
            <Tooltip
              title={
                //Full date and time with duration
                <>
                  <>{`Date: ${moment(notesStore[0].startMs).format(
                    `dddd, MMM Do, ${DISPLAY_TIME_FORMAT}`
                  )} to ${moment(notesStore[0].endMs).format(
                    moment(notesStore[0].startMs).isSame(moment(notesStore[0].startMs), "day")
                      ? DISPLAY_TIME_FORMAT
                      : `dddd, MMM Do, ${DISPLAY_TIME_FORMAT}`
                  )}`}</>
                  <br />
                  <>{`Duration: ${moment
                    .duration(moment(notesStore[0].endMs).diff(moment(notesStore[0].startMs)))
                    .humanize()}
            `}</>
                  <br />
                  <>{` Start: ${moment(notesStore[0].startMs).fromNow()}`}</>
                </>
              }
            >
              <Text>
                {`${",".padEnd(2, String.fromCharCode(10240))} ${moment(
                  notesStore[0].startMs
                ).format(DISPLAY_TIME_FORMAT)} - ${moment(notesStore[0].endMs).format(
                  DISPLAY_TIME_FORMAT
                )}`}
              </Text>
            </Tooltip>
          )
        );
      }, [event]);

    const TextDescription = () =>
      useMemo(() => {
        if (type === CalendarEventType.INDIRECT) {
          return (
            <Tooltip
              title={
                //Full narrative
                <>{event.description}</>
              }
            >
              <Row>
                <Paragraph
                  ellipsis={{
                    rows: 5,
                    expandable: true,
                    symbol: "more",
                  }}
                  style={{
                    width: "300px",
                    marginBottom: 0,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {event.description}
                </Paragraph>
              </Row>
            </Tooltip>
          );
        } else if (type === CalendarEventType.CANCELLED) {
          return (
            <Tooltip title={<>{(event as ICompletedAppointment).cancellationReason}</>}>
              <Row>
                <Paragraph
                  ellipsis={{
                    rows: 5,
                    expandable: true,
                    symbol: "more",
                  }}
                  style={{
                    width: "300px",
                    marginBottom: 0,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {`${(event as ICompletedAppointment).cancellationReason}: ${
                    (event as ICompletedAppointment).cancellationNotes
                  }`}
                </Paragraph>
              </Row>
            </Tooltip>
          );
        } else {
          return (
            notesStore?.[0] && (
              <Tooltip
                title={
                  //Full narrative
                  <>{notesStore?.[0]?.narrative}</>
                }
              >
                <Row>
                  <Paragraph
                    ellipsis={{
                      rows: 5,
                      expandable: true,
                      symbol: "more",
                    }}
                    style={{
                      width: "300px",
                      marginBottom: 0,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {notesStore?.[0]?.narrative
                      .replace("Session narrative (if any) - ", "")
                      .replace("Any barriers to session: ", "")}
                  </Paragraph>
                </Row>
              </Tooltip>
            )
          );
        }
      }, [eventsExtended]);

    const NoteRequisiteRow = (fulfilled: boolean, label: string, data?: string) =>
      useMemo(() => {
        return (
          <Row>
            <Tooltip
              className="note-requisite-tooltip"
              style={{
                backgroundColor: "white",
                color: "black",
              }}
              title={
                <>
                  {!data && `Required field for ${label}`}
                  {data && (
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                      dangerouslySetInnerHTML={{ __html: data }}
                    />
                  )}
                </>
              }
            >
              {fulfilled ? (
                <CheckCircleFilled
                  style={{
                    color: green.primary,
                    fontSize: 14,
                    marginRight: 8,
                    marginTop: 4,
                    marginLeft: 6,
                  }}
                />
              ) : (
                <ExclamationCircleFilled
                  style={{
                    color: red.primary,
                    fontSize: 14,
                    marginRight: 8,
                    marginTop: 4,
                    marginLeft: 6,
                  }}
                />
              )}
              <Text style={{ color: data ? "#1677FF" : "default" }}>{label}</Text>
            </Tooltip>
          </Row>
        );
      }, [eventsExtended]);

    const NoteRequisites = () =>
      useMemo(() => {
        const note = notesStore?.[0];
        if (note) {
          return (
            <Col style={{ marginLeft: 30 }}>
              {NoteRequisiteRow(!_.isEmpty(note.narrative), "Narrative")}
              {noteRequiresLibraryData(note) &&
                NoteRequisiteRow(!_.isEmpty(note.behaviorData), "Behavior Data", note.behaviorData)}
              {noteRequiresLibraryData(note) &&
                NoteRequisiteRow(!_.isEmpty(note.targetData), "Target Data", note.targetData)}
              {NoteRequisiteRow(note.providerSignedMs > 0, "Provider Signature")}
              {noteRequiresParentSignature(note) &&
                NoteRequisiteRow(note.clientSignedMs > 0, "Parent Signature")}
            </Col>
          );
        }
        return null;
      }, [eventsExtended]);

    const ActionButtons = () =>
      useMemo(() => {
        switch (type) {
          case CalendarEventType.APPOINTMENT:
            if ((!notesStore || notesStore?.length === 0) && moment() > moment(event.endMs)) {
              return (
                <Row>
                  <Button
                    type="ghost"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectedEvent(event);
                      handleSelectedClient(clientDetails!.client);
                      handleMatchNotesDrawerOpen(true);
                    }}
                  >
                    <EditOutlined />
                    {"Attach Note"}
                  </Button>
                </Row>
              );
            } else if (notesStore?.length === 1 && notesStore[0].approved === false) {
              return (
                <Row>
                  <Button
                    type={isNoteApprovable(notesStore[0]) ? "primary" : "default"}
                    onClick={() => {
                      actions.approveNote(notesStore[0]);
                    }}
                  >
                    {isNoteApprovable(notesStore[0]) ? (
                      <CheckOutlined />
                    ) : (
                      <ExclamationCircleFilled
                        style={{
                          color: red.primary,
                        }}
                      />
                    )}
                    {"Approve Note"}
                  </Button>
                </Row>
              );
            } else if (notesStore?.length === 1 && notesStore[0].approved === true) {
              return (
                <Row>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.completedAppointment(notesStore[0]);
                    }}
                  >
                    <FaStamp
                      style={{
                        marginRight: 7,
                        marginBottom: -1,
                        fontSize: 14,
                      }}
                    />
                    Complete Appointment
                  </Button>
                </Row>
              );
            } else if (notesStore && notesStore?.length > 1) {
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => {
                    //Opens card
                  }}
                >
                  <SplitCellsOutlined />
                  Split notes
                </Button>
              </Row>;
            }
            break;
          case CalendarEventType.INDIRECT:
            if ((event as IIndirect).status === IndirectStatus.PENDING) {
              return (
                <Row>
                  <Button
                    type="default"
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.rejectIndirect();
                    }}
                    style={
                      (event as IIndirect).status === IndirectStatus.REJECTED
                        ? {
                            borderColor: red.primary,
                            color: "white",
                            backgroundColor: red.primary,
                          }
                        : {
                            borderColor: red.primary,
                            color: red.primary,
                          }
                    }
                  >
                    <CloseOutlined />
                    {(event as IIndirect).status === IndirectStatus.REJECTED
                      ? "Rejected"
                      : "Reject Indirect"}
                  </Button>
                  <Button
                    type="default"
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.acceptIndirect();
                    }}
                    style={
                      (event as IIndirect).status === IndirectStatus.APPROVED
                        ? {
                            marginLeft: 10,
                            borderColor: green.primary,
                            color: "white",
                            backgroundColor: green.primary,
                          }
                        : {
                            marginLeft: 10,
                            borderColor: green.primary,
                            color: green.primary,
                          }
                    }
                  >
                    <CheckOutlined />
                    {(event as IIndirect).status === IndirectStatus.APPROVED
                      ? "Approved"
                      : "Approve Indirect"}
                  </Button>
                </Row>
              );
            }
            break;
          case CalendarEventType.CANCELLED:
            if (
              (event as ICompletedAppointment).cancellationStatus ===
              CancelledCompletedAppointmentStatus.PENDING
            ) {
              return (
                <Row
                  justify="end"
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Button
                    type="default"
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.rejectCancellation();
                    }}
                    style={{
                      borderColor: red.primary,
                      color: red.primary,
                    }}
                  >
                    <CloseOutlined />
                    {"Reject Cancellation"}
                  </Button>
                  <Button
                    type="default"
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.acceptCancellation();
                    }}
                    style={{
                      marginLeft: 10,
                      borderColor: green.primary,
                      color: green.primary,
                    }}
                  >
                    <CheckOutlined />
                    {"Approve Cancellation"}
                  </Button>
                </Row>
              );
            }
            break;
          default:
            return null;
        }
      }, [event, type, notesStore]);

    return (
      <Card
        style={{
          height: extendedView ? "auto" : "46px",
          marginBottom: 5,
          width: "100%",
          borderLeft: `4px solid ${cardColor}`,
        }}
        bodyStyle={{
          paddingTop: extendedView ? 8 : 0,
          paddingBottom: extendedView ? 8 : 0,
        }}
        hoverable
        onClick={(e) => {
          e.stopPropagation();
          const appointmentCard = e.currentTarget.querySelector(
            ".ant-card.ant-card-bordered.ant-card-hoverable"
          ) as HTMLElement;
          //propogate click to appointment card
          appointmentCard.click();
        }}
      >
        <Row
          style={{
            height: extendedView ? 52 : 46,
            left: 0,
            width: "100%",
          }}
        >
          <Space>
            <Checkbox
              onClick={(e) => {
                //do not propagate click to card
                e.stopPropagation();
              }}
              onChange={(e) => {
                handleCheckboxChange(e, eventExtended);
              }}
              checked={selectedEventsExtended.includes(eventExtended)}
            />
            <div
              style={{
                marginLeft: 15,
                marginRight: 5,
              }}
            >
              <StatusIcon />
            </div>
            <Divider
              type="vertical"
              style={{
                height: 35,
                marginTop: -20,
                marginBottom: -17,
                marginRight: 5,
                marginLeft: 5,
              }}
            />
            <ClientIcon />
            {extendedView ? (
              <>
                <BillingTags />
                <AppointmentTimeRange />
                <LocationIcons />
              </>
            ) : (
              <>
                <AppointmentTimeRange />
                <BillingTags />
                <LocationIcons />
                <AttendeeTags />
              </>
            )}
            <AppointmentCards />
            {extendedView && (
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 8,
                  marginRight: 10,
                  marginBottom: 0,
                }}
              >
                <ActionButtons />
              </div>
            )}
          </Space>
        </Row>
        {extendedView && (
          <div style={{ marginLeft: 38 }}>
            <Row style={{ marginLeft: -8, marginTop: 5, marginBottom: 10 }}>
              <AttendeeTags />
            </Row>
            <Row>
              <Col>
                <TextDescription />
              </Col>
              <Col>
                <NoteRequisites />
              </Col>
            </Row>
          </div>
        )}
      </Card>
    );
  },
  (prevProps, nextProps) => {
    return (
      _.isEqual(prevProps.event, nextProps.event) &&
      _.isEqual(prevProps.eventExtended, nextProps.eventExtended)
    );
  }
);
