import {
  AppointmentLocation,
  AppointmentType,
  BillingCode,
  BillingLevel,
  IAppointment,
  IAppointmentType,
  ICompletedAppointment,
  IPayer,
  IStatePayersConfig,
  Modifier,
  USStateCode,
} from "../../types";
import { generateAddress, getBaseBillingModifiers, sortModifiers } from "./helpers";

export const NEW_MEXICO_APPOINTMENTS: IAppointmentType = {
  [AppointmentType.DIRECT_OR_INDIRECT_SUPERVISION]: {
    billingCode: BillingCode.CODE_T1026,
    modifiers: [Modifier.UD],
  },
  [AppointmentType.CLINICAL_MANAGEMENT]: {
    billingCode: BillingCode.CODE_T1026,
    modifiers: [Modifier.UC],
  },
};

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  billingLevel: BillingLevel
) => {
  const modifiers = new Set(getBaseBillingModifiers(appointment, payer, billingLevel));

  if (
    "billingUserId" in appointment &&
    "renderingUserId" in appointment &&
    appointment.billingUserId !== appointment.renderingUserId
  ) {
    modifiers.add(Modifier.U7);
  }

  return sortModifiers(Array.from(modifiers));
};

export const NEW_MEXICO_PAYERS: IStatePayersConfig = [
  {
    name: "Tricare West",
    payerId: "99726",
    contractAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    billingAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {
      [AppointmentLocation.TELEHEALTH]: Modifier.MOD_95,
    },
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 5418,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3125,
          RBT: 1717,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3238,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3125,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Blue Cross Blue Shield Commercial",
    payerId: "7403",
    contractAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    billingAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {
      [AppointmentLocation.TELEHEALTH]: Modifier.MOD_95,
    },
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 9162,
        },
      },
      [BillingCode.CODE_97152]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 4759,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1619,
          BCBA: 1619,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3238,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2104,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Blue Cross Blue Shield Centennial",
    payerId: "00790",
    contractAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    billingAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    modifiers: {
      RBT: Modifier.U1,
      BCBA: Modifier.U3,
    },
    locationModifiers: {
      [AppointmentLocation.TELEHEALTH]: Modifier.MOD_95,
    },
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: false,
        unitSize: 15,
        rates: {
          BCBA: 9162,
        },
      },
      [BillingCode.CODE_97152]: {
        authRequired: false,
        unitSize: 15,
        rates: {
          BCBA: 4759,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1619,
          BCBA: 2637,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3238,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2104,
        },
      },
      [BillingCode.CODE_T1026]: {
        authRequired: true,
        unitSize: 60,
        rates: {
          BCBA: 11655,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Presbyterian",
    payerId: "01260",
    contractAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    billingAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    modifiers: {
      RBT: Modifier.U1,
      BCBA: Modifier.U3,
    },
    locationModifiers: {
      [AppointmentLocation.TELEHEALTH]: Modifier.MOD_95,
    },
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: false,
        unitSize: 15,
        rates: {
          BCBA: 9162,
        },
      },
      [BillingCode.CODE_97152]: {
        authRequired: false,
        unitSize: 15,
        rates: {
          BCBA: 4759,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1619,
          BCBA: 2637,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3238,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2104,
        },
      },
      [BillingCode.CODE_T1026]: {
        authRequired: true,
        unitSize: 60,
        rates: {
          BCBA: 11655,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Western Sky Community Care",
    payerId: "68069",
    contractAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    billingAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    modifiers: {
      RBT: Modifier.U1,
      BCBA: Modifier.U3,
    },
    locationModifiers: {
      [AppointmentLocation.TELEHEALTH]: Modifier.MOD_95,
    },
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: false,
        unitSize: 15,
        rates: {
          BCBA: 9162,
        },
      },
      [BillingCode.CODE_97152]: {
        authRequired: false,
        unitSize: 15,
        rates: {
          BCBA: 4759,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1619,
          BCBA: 2637,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3238,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2104,
        },
      },
      [BillingCode.CODE_T1026]: {
        authRequired: true,
        unitSize: 60,
        rates: {
          BCBA: 11655,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "Self Pay",
    payerId: "NM-SELFPAY",
    contractAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    billingAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    modifiers: {
      RBT: Modifier.U1,
      BCBA: Modifier.U3,
    },
    locationModifiers: {
      [AppointmentLocation.TELEHEALTH]: Modifier.MOD_95,
    },
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: false,
        unitSize: 15,
        rates: {
          BCBA: 9162,
        },
      },
      [BillingCode.CODE_97152]: {
        authRequired: false,
        unitSize: 15,
        rates: {
          BCBA: 4759,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1619,
          BCBA: 2637,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 3238,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2104,
        },
      },
      [BillingCode.CODE_T1026]: {
        authRequired: true,
        unitSize: 60,
        rates: {
          BCBA: 11655,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "United Healthcare / Optum",
    payerId: "87726",
    contractAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    billingAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    modifiers: {
      RBT: "HM",
      BCBA: "HO",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2540,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1251,
          BCBA: 1418,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 1642,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 1488,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
];
