import { ISchedule, IUpdateWorkingHoursRequest } from "@finni-health/shared";
import { Button, message, Popconfirm, Row, Space, Statistic, Table } from "antd";
import React, { useEffect, useState } from "react";

import { getScheduleString } from "../../helpers/workingHours";
import * as FirestoreService from "../../services/firestore";
import { useUserClinics } from "../UserClinicsProvider";

type ScheduleUpdateRow = IUpdateWorkingHoursRequest & {
  name: string;
  key: string;
  currentSchedule: ISchedule;
};

export const PendingWorkingHoursUpdatesTable: React.FC = () => {
  const { user } = useUserClinics();

  const [pendingRequests, setPendingRequests] = useState<ScheduleUpdateRow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchRequests().catch(() => {});
  }, [user]);

  const fetchRequests = async () => {
    setIsLoading(true);
    const updateReqs = await FirestoreService.getAllPendingScheduleUpdateRequests(user.clinicId);
    const requestsWithUser: ScheduleUpdateRow[] = [];
    await Promise.all(
      (updateReqs || []).map(async (req) => {
        const user = await FirestoreService.getUserById(req.userId);
        const name = `${user.firstName} ${user.lastName}`;
        requestsWithUser.push({
          ...req,
          name,
          key: req.id,
          currentSchedule: user.schedule,
        });
      })
    );
    setPendingRequests(requestsWithUser);
    setIsLoading(false);
  };

  const approveRequest = async (req: ScheduleUpdateRow) => {
    setIsLoading(true);
    try {
      await FirestoreService.approveScheduleUpdateRequest({
        id: req.id,
      });
      void message.success(`Approved schedule change for ${req.name}`);
      await fetchRequests();
    } catch (err) {
      void message.error("Couldn't approve schedule change, please try again");
      console.error(err);
    }
    setIsLoading(false);
  };

  const denyRequest = async (req: ScheduleUpdateRow) => {
    setIsLoading(true);
    try {
      await FirestoreService.declineScheduleUpdateRequest({
        id: req.id,
      });
      void message.success(`Denied schedule change for ${req.name}`);
      await fetchRequests();
    } catch (err) {
      void message.error("Couldn't deny schedule change, please try again");
      console.error(err);
    }
    setIsLoading(false);
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Current Working Hours",
      render: (_text: string, row: ScheduleUpdateRow) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{getScheduleString(row.currentSchedule)}</div>
      ),
    },
    {
      title: "Proposed Change",
      render: (_text: string, row: ScheduleUpdateRow) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{getScheduleString(row.schedule)}</div>
      ),
    },
    {
      width: 15,
      align: "center" as any,
      render: (_text: string, row: ScheduleUpdateRow) => (
        <Row wrap={false} justify="center" align="middle">
          <Space size="small">
            <Popconfirm
              title={`Are you sure you want to approve schedule change for ${row.name}?`}
              placement="bottomRight"
              onConfirm={() => approveRequest(row)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">Approve</Button>
            </Popconfirm>
            <Popconfirm
              title={`Are you sure you want to deny schedule change for ${row.name}?`}
              placement="bottomRight"
              onConfirm={() => denyRequest(row)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="ghost">Deny</Button>
            </Popconfirm>
          </Space>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Space
          size="large"
          style={{
            marginBottom: "15px",
          }}
        >
          <Statistic
            loading={isLoading}
            title="Pending RBT Working Hour Changes"
            value={pendingRequests.length}
          />
        </Space>
      </Row>
      <Table columns={tableColumns} dataSource={pendingRequests} loading={isLoading} />
    </>
  );
};
