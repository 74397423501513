//colors
export const COLORS = {
  PRIMARY: "#ed762f",
  DARK_PURPLE: "#4A3A54",
  LIGHT_PURPLE: "#D1BCE7",
  GREEN: "#06d6a0",
  YELLOW: "#ffd166",
  RED: "#ef476f",
  GRAY: "#dfdfdf",
  LIGHT_GRAY: "#e4e4e4",
  DARK_GRAY: "#bfbfbf",
  BLUE: "#108EE9",
  DARK_YELLOW: "#F46E65",
  NEUTRAL_BLUE: "#CDEDF6",
  NEUTRAL_ORANGE: "#eec1a9",
  STANDARD_GREEN: "green",
  STANDARD_RED: "#FF0000",
  LIGHT_ORANGE: "#ff9e00",
  LIGHT_BLUE: "#0096c7",
};
