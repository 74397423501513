import { IClientGuardianDetails } from "@finni-health/shared";
import { Button, Descriptions, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import { IoHeartCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { DISPLAY_DATE_FORMAT, DISPLAY_DATETIME_FORMAT } from "../../consts";
import { formatAddress } from "../../helpers/profiles";
import {
  EDIT_CLIENT_FILE_MODAL_TABLE_TABS,
  EditClientFileModal,
} from "../EditClientFileModal/EditClientFileModal";

interface Props {
  clientGuardianDetails: IClientGuardianDetails;
  clientRefreshCallback: () => Promise<void>;
}

export const ClientDetailsSection = ({ clientGuardianDetails, clientRefreshCallback }: Props) => {
  // Edit ClientFile Modal
  const [isEditClientFileModalVisible, setIsEditClientFileModalVisible] = useState<boolean>(false);
  const hideEditClientFileModal = () => {
    setIsEditClientFileModalVisible(false);
  };

  return (
    <div style={{ marginBottom: 25 }}>
      <Row justify="end" style={{ marginBottom: 10 }}>
        <Button type="primary" onClick={() => setIsEditClientFileModalVisible(true)}>
          Edit Client Details
        </Button>
      </Row>
      <Descriptions bordered column={4} size="small" style={{ marginBottom: 25 }}>
        <Descriptions.Item label="Alias">{clientGuardianDetails.client.alias}</Descriptions.Item>
        <Descriptions.Item label="First Name">
          {clientGuardianDetails.client.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Middle Name">
          {clientGuardianDetails.client.middleName || ""}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          {clientGuardianDetails.client.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Sex">{clientGuardianDetails.client.sex}</Descriptions.Item>
        <Descriptions.Item label="Date of Birth">
          {moment(clientGuardianDetails.client.dateOfBirth).format(DISPLAY_DATE_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label="Guardian" span={4}>
          <Link to={`../guardian-profile/${clientGuardianDetails.guardian.id}`}>
            <IoHeartCircleOutline style={{ fontSize: 18, marginBottom: -3, marginRight: 2 }} />
            {`${clientGuardianDetails.guardian.firstName} ${clientGuardianDetails.guardian.lastName}`}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Address" span={4} style={{ whiteSpace: "pre-wrap" }}>
          {clientGuardianDetails.clientFile.address &&
            formatAddress(clientGuardianDetails.clientFile.address)}
        </Descriptions.Item>
        <Descriptions.Item label="Guardian email" span={2}>
          {clientGuardianDetails.guardian.email}
        </Descriptions.Item>
        <Descriptions.Item label="Guardian phone" span={2}>
          {clientGuardianDetails.guardian.phoneNumber?.primary}
        </Descriptions.Item>
        <Descriptions.Item label="Created On" span={2}>
          {moment(clientGuardianDetails.client.createdAt as unknown as number).format(
            DISPLAY_DATE_FORMAT
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Last Edited" span={2}>
          {moment(clientGuardianDetails.client.updatedAt as unknown as number).format(
            DISPLAY_DATETIME_FORMAT
          )}
        </Descriptions.Item>
      </Descriptions>
      {isEditClientFileModalVisible && (
        <EditClientFileModal
          hideModal={hideEditClientFileModal}
          clientGuardianDetails={clientGuardianDetails}
          refreshCallback={clientRefreshCallback}
          defaultTab={EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT}
        />
      )}
    </div>
  );
};
