import { EllipsisOutlined } from "@ant-design/icons";
import {
  BillingCode,
  EventType,
  getCurrentActiveAuth,
  IAppointment,
  ICalendarEvent,
  ICalendarRow,
  IClient,
  IClientFile,
  ICompletedAppointment,
  Modifier,
  WeekDays,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Card, Popover, Progress, Row, Tooltip, Typography } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { IoSchoolOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { AUTHORIZED_97156 } from "../../consts";
import { getBillingCodeColor } from "../../helpers/colors";
import { getWeeklyHoursFromAuth } from "../../helpers/schedules";
import { ClientAuthSummary } from "./ClientAuthSummary";

const { Title, Text } = Typography;

interface IProps {
  data: ICalendarRow;
  clientFile: IClientFile;
}

export const ClientSummary: React.FC<IProps> = ({ data, clientFile }: IProps) => {
  const [total97151, setTotal97151] = useState<number>(0);
  const [total97152, setTotal97152] = useState<number>(0);
  const [total97153, setTotal97153] = useState<number>(0);
  const [total97156, setTotal97156] = useState<number>(0);
  const [total97155, setTotal97155] = useState<number>(0);
  const [totalT1026UC, setTotalT1026UC] = useState<number>(0);
  const [totalT1026UD, setTotalT1026UD] = useState<number>(0);
  const [completed97151, setCompleted97151] = useState<number>(0);
  const [completed97152, setCompleted97152] = useState<number>(0);
  const [completed97153, setCompleted97153] = useState<number>(0);
  const [completed97155, setCompleted97155] = useState<number>(0);
  const [completed97156, setCompleted97156] = useState<number>(0);
  const [completedT1026UC, setCompletedT1026UC] = useState<number>(0);
  const [completedT1026UD, setCompletedT1026UD] = useState<number>(0);

  const [isAuthSummaryOpen, setIsAuthSummaryOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = () => {
    //totals
    setTotal97151(getTotalHoursFromCalendar(BillingCode.CODE_97151));
    setTotal97152(getTotalHoursFromCalendar(BillingCode.CODE_97152));
    setTotal97153(getTotalHoursFromCalendar(BillingCode.CODE_97153));
    setTotal97156(getTotalHoursFromCalendar(BillingCode.CODE_97156));
    setTotal97155(getTotalHoursFromCalendar(BillingCode.CODE_97155));
    setTotalT1026UC(getTotalHoursFromCalendar(BillingCode.CODE_T1026, Modifier.UC));
    setTotalT1026UD(getTotalHoursFromCalendar(BillingCode.CODE_T1026, Modifier.UD));

    //completed
    setCompleted97151(getCompletedHoursFromCalendar(BillingCode.CODE_97151));
    setCompleted97152(getCompletedHoursFromCalendar(BillingCode.CODE_97152));
    setCompleted97153(getCompletedHoursFromCalendar(BillingCode.CODE_97153));
    setCompleted97155(getCompletedHoursFromCalendar(BillingCode.CODE_97155));
    setCompleted97156(getCompletedHoursFromCalendar(BillingCode.CODE_97156));
    setCompletedT1026UC(getCompletedHoursFromCalendar(BillingCode.CODE_T1026, Modifier.UC));
    setCompletedT1026UD(getCompletedHoursFromCalendar(BillingCode.CODE_T1026, Modifier.UD));
  };

  const roundToNearestQuarter = (num: number) => {
    return Number((num + 0.25 / 2 - ((num + 0.25 / 2) % 0.25)).toFixed(2));
  };

  const getTotalHoursFromCalendar = (billingCode: BillingCode, modifier?: Modifier) => {
    let totalHours = 0;
    for (const day of Object.values(WeekDays)) {
      data[day]?.map((appt: ICalendarEvent) => {
        if ((appt as any).isBilled === undefined) {
          //Regular Appointment
          appt = appt as IAppointment;
          if (
            // Allow cancelled in Total hours
            appt.billingCode === billingCode &&
            (!modifier || appt.modifiers.includes(modifier)) &&
            data[day].filter((a) => a.id === appt.id).length == 1
          ) {
            totalHours += (appt.endMs - appt.startMs) / (1000 * 60 * 60);
          }
        } else {
          //Completed Appointment
          appt = appt as ICompletedAppointment;
          if (
            // Allow cancelled in Total hours
            appt.billingCode === billingCode &&
            (!modifier || appt.modifiers.includes(modifier))
          ) {
            totalHours += (appt.endMs - appt.startMs) / (1000 * 60 * 60);
          }
        }
      });
    }
    //round to nearest 0.5
    return roundToNearestQuarter(totalHours);
  };

  const getCompletedHoursFromCalendar = (billingCode: BillingCode, modifier?: Modifier) => {
    let completedHours = 0;
    for (const day of Object.values(WeekDays)) {
      data[day]?.map((appt: ICalendarEvent) => {
        const completedAppt = appt as ICompletedAppointment;
        if (
          completedAppt.eventType === EventType.COMPLETED &&
          _.isEmpty(completedAppt.cancelledAt) &&
          completedAppt.billingCode === billingCode &&
          (!modifier || completedAppt.modifiers.includes(modifier))
        ) {
          completedHours += (completedAppt.endMs - completedAppt.startMs) / (1000 * 60 * 60);
        }
      });
    }
    //round to nearest 0.5
    return roundToNearestQuarter(completedHours);
  };

  const currentAuth = getCurrentActiveAuth(clientFile.payers?.primary ?? null);
  const authWeeklyHours = getWeeklyHoursFromAuth(currentAuth, BillingCode.CODE_97153);

  return (
    <Popover
      trigger="click"
      placement="right"
      open={isAuthSummaryOpen}
      onOpenChange={(open) => setIsAuthSummaryOpen(open)}
      content={() => (
        <ClientAuthSummary
          clientFile={clientFile}
          clinicId={data.person.clinicId}
          closePopup={() => setIsAuthSummaryOpen(false)}
        />
      )}
      zIndex={500}
      showArrow={false}
      getPopupContainer={(trigger) => trigger.parentElement || document.body}
      destroyTooltipOnHide={true}
    >
      <Card
        size="small"
        bordered={false}
        style={{
          cursor: "pointer",
          boxShadow: isAuthSummaryOpen
            ? "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)"
            : undefined,
        }}
        hoverable
      >
        <Row justify="space-between">
          <Title level={5}>
            <Link to={`/client-profile/${data.person.id}`} style={{ whiteSpace: "nowrap" }}>
              <IoSchoolOutline
                style={{
                  fontSize: 18,
                  marginBottom: -3,
                  marginRight: 5,
                }}
              />
              {(data.person as IClient).alias}
            </Link>
          </Title>
          <EllipsisOutlined style={{ rotate: "90deg", position: "relative", top: -10 }} />
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Text>{`${data.person.firstName} ${data.person.lastName}`}</Text>
        </Row>
        <>
          {total97151 > 0 && (
            <Row align="middle" style={{ marginBottom: 15 }}>
              <Tooltip
                placement="right"
                title={`${completed97151} completed | ${total97151} scheduled`}
                style={{ whiteSpace: "pre" }}
              >
                <Progress
                  type="dashboard"
                  gapDegree={1}
                  width={50}
                  percent={100}
                  trailColor={COLORS.GRAY}
                  strokeColor={COLORS.BLUE}
                  format={() => (
                    <>
                      <Text>{completed97151}</Text>
                      <br />
                      <Text style={{ fontSize: 10 }}>hours</Text>
                    </>
                  )}
                />
                <Text style={{ marginLeft: 5, fontSize: 12 }}>{BillingCode.CODE_97151}</Text>
              </Tooltip>
            </Row>
          )}
          {total97152 > 0 && (
            <Row align="middle" style={{ marginBottom: 15 }}>
              <Tooltip
                placement="right"
                title={`${completed97152} completed | ${total97152} scheduled`}
                style={{ whiteSpace: "pre" }}
              >
                <Progress
                  type="dashboard"
                  gapDegree={1}
                  width={50}
                  percent={100}
                  trailColor={COLORS.GRAY}
                  strokeColor={COLORS.BLUE}
                  format={() => (
                    <>
                      <Text>{completed97152}</Text>
                      <br />
                      <Text style={{ fontSize: 10 }}>hours</Text>
                    </>
                  )}
                />
                <Text style={{ marginLeft: 5, fontSize: 12 }}>{BillingCode.CODE_97152}</Text>
              </Tooltip>
            </Row>
          )}
          <Row align="middle" style={{ marginBottom: 15 }}>
            <Tooltip
              placement="right"
              title={`${completed97153} completed | ${total97153} scheduled | ${roundToNearestQuarter(
                authWeeklyHours
              )} authorized`}
              style={{ whiteSpace: "pre" }}
            >
              <Progress
                type="dashboard"
                gapDegree={1}
                width={50}
                success={{
                  percent: (completed97153 / roundToNearestQuarter(authWeeklyHours)) * 100,
                  strokeColor: COLORS.GREEN,
                }}
                percent={(total97153 / roundToNearestQuarter(authWeeklyHours)) * 100}
                trailColor={COLORS.GRAY}
                strokeColor={COLORS.BLUE}
                format={() => (
                  <>
                    <Text>{completed97153}</Text>
                    <br />
                    <Text style={{ fontSize: 10 }}>hours</Text>
                  </>
                )}
              />
              <Text style={{ marginLeft: 5, fontSize: 12 }}>{BillingCode.CODE_97153}</Text>
            </Tooltip>
          </Row>
          <Row align="middle" style={{ marginBottom: 15 }}>
            <Tooltip
              placement="right"
              title={`${completed97155} completed | ${total97155} scheduled | ${roundToNearestQuarter(
                authWeeklyHours * 0.1
              )} authorized`}
            >
              <Progress
                type="dashboard"
                gapDegree={1}
                width={50}
                success={{
                  percent: (completed97155 / (authWeeklyHours * 0.1)) * 100,
                  strokeColor: getBillingCodeColor(BillingCode.CODE_97155),
                }}
                percent={(total97155 / (authWeeklyHours * 0.1)) * 100}
                trailColor={COLORS.GRAY}
                strokeColor={COLORS.BLUE}
                format={() => (
                  <>
                    <Text>{completed97155}</Text>
                    <br />
                    <Text style={{ fontSize: 10 }}>hours</Text>
                  </>
                )}
              />
              <Text style={{ marginLeft: 5, fontSize: 12 }}>{BillingCode.CODE_97155}</Text>
            </Tooltip>
          </Row>
          {total97156 > 0 && (
            <Row align="middle" style={{ marginBottom: 15 }}>
              <Tooltip
                placement="right"
                title={`${completed97156} completed | ${total97156} scheduled | ${AUTHORIZED_97156} authorized`}
              >
                <Progress
                  type="dashboard"
                  gapDegree={1}
                  width={50}
                  success={{
                    percent: (completed97156 / AUTHORIZED_97156) * 100,
                    strokeColor: getBillingCodeColor(BillingCode.CODE_97156),
                  }}
                  percent={(total97156 / AUTHORIZED_97156) * 100}
                  trailColor={COLORS.GRAY}
                  strokeColor={COLORS.BLUE}
                  format={() => (
                    <>
                      <Text>{completed97156}</Text>
                      <br />
                      <Text style={{ fontSize: 10 }}>hours</Text>
                    </>
                  )}
                />
                <Text style={{ marginLeft: 5, fontSize: 12 }}>{BillingCode.CODE_97156}</Text>
              </Tooltip>
            </Row>
          )}
          {totalT1026UC > 0 && (
            <Row align="middle" style={{ marginBottom: 15 }}>
              <Tooltip
                placement="right"
                title={`${completedT1026UC} completed | ${totalT1026UC} scheduled | ${roundToNearestQuarter(
                  authWeeklyHours * 0.1
                )} authorized`}
              >
                <Progress
                  type="dashboard"
                  gapDegree={1}
                  width={50}
                  success={{
                    percent: (completedT1026UC / (authWeeklyHours * 0.1)) * 100,
                    strokeColor: getBillingCodeColor(BillingCode.CODE_T1026, [Modifier.UC]),
                  }}
                  percent={(totalT1026UC / (authWeeklyHours * 0.1)) * 100}
                  trailColor={COLORS.GRAY}
                  strokeColor={COLORS.BLUE}
                  format={() => (
                    <>
                      <Text>{completedT1026UC}</Text>
                      <br />
                      <Text style={{ fontSize: 10 }}>hours</Text>
                    </>
                  )}
                />
                <Text style={{ marginLeft: 5, fontSize: 12 }}>{`${BillingCode.CODE_T1026}UC`}</Text>
              </Tooltip>
            </Row>
          )}
          {totalT1026UD > 0 && (
            <Row align="middle">
              <Tooltip
                placement="right"
                title={`${completedT1026UD} completed | ${totalT1026UD} scheduled | ${roundToNearestQuarter(
                  authWeeklyHours * 0.1
                )} authorized`}
              >
                <Progress
                  type="dashboard"
                  gapDegree={1}
                  width={50}
                  success={{
                    percent: (completedT1026UD / (authWeeklyHours * 0.1)) * 100,
                    strokeColor: getBillingCodeColor(BillingCode.CODE_T1026, [Modifier.UD]),
                  }}
                  percent={(totalT1026UD / (authWeeklyHours * 0.1)) * 100}
                  trailColor={COLORS.GRAY}
                  strokeColor={COLORS.BLUE}
                  format={() => (
                    <>
                      <Text>{completedT1026UD}</Text>
                      <br />
                      <Text style={{ fontSize: 10 }}>hours</Text>
                    </>
                  )}
                />
                <Text style={{ marginLeft: 5, fontSize: 12 }}>{`${BillingCode.CODE_T1026}UD`}</Text>
              </Tooltip>
            </Row>
          )}
        </>
      </Card>
    </Popover>
  );
};
