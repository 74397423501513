import { RightOutlined } from "@ant-design/icons";
import { getAllPayersForState, IClient, IPayer } from "@finni-health/shared";
import { Button, Col, Collapse, Divider, Form, Row, Typography } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";

import { useUserClinics } from "../UserClinicsProvider";
import { EDIT_CLIENT_FILE_MODAL_TABLE_TABS } from "./EditClientFileModal";
import { PayersSectionForm } from "./PayersSectionForm";

const { Title, Text } = Typography;

interface IProps {
  client?: IClient;
  clientFileId: string;
  setSelectedTab: (value: EDIT_CLIENT_FILE_MODAL_TABLE_TABS) => void;
}

export const PayersForm = ({ client, clientFileId, setSelectedTab }: IProps) => {
  const { clinic } = useUserClinics();
  const formInstance = Form.useFormInstance();

  const [payers, setPayers] = useState<IPayer[]>([]);

  const fetchData = () => {
    const payers = getAllPayersForState(clinic.address.state);
    setPayers(payers);
  };

  useEffect(() => {
    fetchData();
  }, [clinic]);

  if (_.isEmpty(client)) {
    return (
      <>
        <Row gutter={24} justify="center">
          <Col>
            <Title level={5} style={{ textAlign: "center" }}>
              Guardians must first have an associated Client before adding Payer details
            </Title>
          </Col>
        </Row>
        <Row gutter={24} justify="center">
          <Button
            onClick={() => {
              setSelectedTab(EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT);
            }}
          >
            Add Client
          </Button>
        </Row>
      </>
    );
  }

  return (
    <>
      <Divider orientation="left">Primary Payer</Divider>
      <PayersSectionForm type="primary" clientFileId={clientFileId} payers={payers} />
      <Collapse
        ghost
        expandIcon={({ isActive }) => (
          <RightOutlined
            rotate={isActive ? 90 : 0}
            style={{ fontSize: 15, position: "relative", top: 3 }}
          />
        )}
        defaultActiveKey={formInstance.getFieldValue(["clientFile", "payers", "secondary"]) ? 1 : 0}
      >
        <Collapse.Panel
          header={<Text style={{ fontSize: 16, fontWeight: 500 }}>Secondary Payer</Text>}
          key={1}
        >
          <PayersSectionForm type="secondary" clientFileId={clientFileId} payers={payers} />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};
