import {
  CountryCode,
  ICreateInquiryEndpointRequest,
  InquiryStatus,
  IUser,
  USStateCode,
} from "@finni-health/shared";
import { Button, Col, Collapse, Form, Input, message, Modal, Row, Select } from "antd";
import { useState } from "react";

import {
  ERROR_MESSAGE,
  VALID_EMAIL_REGEX,
  VALID_PHONE_NUMBER_REGEX,
  VALID_ZIP_CODE_REGEX,
} from "../../consts";
import * as FirestoreService from "../../services/firestore";
import { PhoneNumberInput } from "../PhoneNumberInput";
import { IntakeStatusTag } from "./IntakeStatusTag";

const { TextArea } = Input;

interface ICreateInquiryFormValues {
  firstName?: string;
  lastName?: string;
  phoneNumberPrimary: string;
  phoneNumberHome?: string;
  phoneNumberWork?: string;
  phoneNumberCell?: string;
  email: string;
  line1?: string;
  line2?: string;
  city?: string;
  zipCode?: string;
  state?: USStateCode;
  status: InquiryStatus;
  statusNotes?: string;
}

interface Props {
  hideModal: () => void;
  refreshCallback: () => void;
  isVisible: boolean;
  user: IUser;
}

export const CreateInquiryModal = ({ hideModal, isVisible, user, refreshCallback }: Props) => {
  const [form] = Form.useForm<ICreateInquiryFormValues>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleFormOnFinish = async (values: ICreateInquiryFormValues) => {
    setIsSaving(true);
    const createInquiryRequest: ICreateInquiryEndpointRequest = {
      clinicId: user.clinicId,
      firstName: values.firstName || "",
      lastName: values.lastName || "",
      phoneNumber: {
        primary: values.phoneNumberPrimary,
        home: values.phoneNumberHome || "",
        work: values.phoneNumberWork || "",
        cell: values.phoneNumberCell || "",
      },
      email: values.email,
      address: {
        line1: values.line1 || "",
        line2: values.line2 || "",
        city: values.city || "",
        state: values.state as USStateCode,
        country: CountryCode.US,
        zipCode: values.zipCode || "",
      },
      intakeStatus: InquiryStatus[values.status],
      intakeStatusNotes: values.statusNotes || "",
    };
    try {
      await FirestoreService.createInquiry(createInquiryRequest);
      form.resetFields();
      refreshCallback();
      hideModal();
      void message.success("Inquiry created");
    } catch (err) {
      void message.error(ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  return (
    <Modal
      title={`Create Inquiry`}
      onCancel={hideModal}
      open={isVisible}
      footer={false}
      width={800}
      style={{ top: 20 }}
    >
      <Form form={form} layout="vertical" labelCol={{ span: 24 }} onFinish={handleFormOnFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "Please provide a Status",
                },
              ]}
            >
              <Select showSearch optionFilterProp="key">
                {Object.keys(InquiryStatus).map((status) => (
                  <Select.Option key={status} value={status}>
                    <IntakeStatusTag
                      status={status as InquiryStatus}
                      style={{ maxWidth: 200, position: "relative", top: 4 }}
                    />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status Notes" name="statusNotes">
              <TextArea autoSize={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="First Name" name="firstName">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name" name="lastName">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Address Line 1" name="line1">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Address Line 2" name="line2">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="City" name="city">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="State" name="state">
              <Select showSearch disabled={isSaving}>
                {Object.values(USStateCode).map((state) => (
                  <Select.Option value={state} key={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Country" name="country" initialValue={CountryCode.US}>
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Zip Code"
              name="zipCode"
              rules={[
                {
                  pattern: VALID_ZIP_CODE_REGEX,
                  message: "Please enter a valid zip code",
                },
              ]}
            >
              <Input maxLength={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  pattern: VALID_EMAIL_REGEX,
                  message: "Please provide a valid Email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Primary Phone Number"
              name="phoneNumberPrimary"
              rules={[
                {
                  required: true,
                  pattern: VALID_PHONE_NUMBER_REGEX,
                  message: "Please provide a valid Phone Number",
                },
              ]}
            >
              <PhoneNumberInput />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={24} style={{ marginTop: -15 }}>
          <Col span={12}>
            <Collapse ghost>
              <Collapse.Panel header={<>Additional Phone Numbers</>} key="1">
                <Form.Item
                  label="Home Phone Number"
                  name="phoneNumberHome"
                  rules={[
                    {
                      pattern: VALID_PHONE_NUMBER_REGEX,
                      message: "Please provide a valid Phone Number",
                    },
                  ]}
                >
                  <PhoneNumberInput />
                </Form.Item>
                <Form.Item
                  label="Work Phone Number"
                  name="phoneNumberWork"
                  rules={[
                    {
                      pattern: VALID_PHONE_NUMBER_REGEX,
                      message: "Please provide a valid Phone Number",
                    },
                  ]}
                >
                  <PhoneNumberInput />
                </Form.Item>
                <Form.Item
                  label="Cell Phone Number"
                  name="phoneNumberCell"
                  rules={[
                    {
                      pattern: VALID_PHONE_NUMBER_REGEX,
                      message: "Please provide a valid Phone Number",
                    },
                  ]}
                >
                  <PhoneNumberInput />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
        <Row justify="end">
          <Button loading={isSaving} type="primary" htmlType="submit">
            Create Inquiry
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
