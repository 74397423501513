import {
  IClientDocument,
  IClientFile,
  IClientPayer,
  isAuthComplete,
  isAuthEmpty,
  PolicyHolderRelationship,
  Sex,
} from "@finni-health/shared";
import _ from "lodash";

const CLIENT_FILE_VALIDATION_RULES: {
  dataIndex: string;
  rule: (value: any) => boolean;
  errorMessage: string;
}[] = [
  {
    dataIndex: "payers.primary.auth.authNumber",
    rule: (authNumber: string) => !_.isEmpty(authNumber),
    errorMessage: "Auth number is missing or blank",
  },
  {
    dataIndex: "payers.primary.auth.dx",
    rule: (dx: string) => _.isEmpty(dx) || dx === "F84.0",
    errorMessage: "DX is missing or blank",
  },
  {
    dataIndex: "address.line1",
    rule: (line1: string) => !_.isEmpty(line1),
    errorMessage: "Address line 1 is missing or blank",
  },
  {
    dataIndex: "address.city",
    rule: (city: string) => !_.isEmpty(city),
    errorMessage: "Address city is missing or blank",
  },
  {
    dataIndex: "address.state",
    rule: (state: string) => !_.isEmpty(state),
    errorMessage: "Address state is missing or blank",
  },
  {
    dataIndex: "address.zipCode",
    rule: (zipCode: string) => !_.isEmpty(zipCode),
    errorMessage: "Address zipcode is missing or blank",
  },
  {
    dataIndex: "payers.primary.policyHolderRelationship",
    rule: (policyHolderRelationship: PolicyHolderRelationship) =>
      !_.isEmpty(policyHolderRelationship),
    errorMessage: "Primary payer policy holder relationship is missing or blank",
  },
  {
    dataIndex: "payers.primary.memberNum",
    rule: (memberNum: string) => !_.isEmpty(memberNum),
    errorMessage: "Primary payer member number is missing or blank",
  },
  {
    dataIndex: "payers.primary.groupNum",
    rule: (groupNum: string) => !_.isEmpty(groupNum),
    errorMessage: "Primary payer group number is missing or blank",
  },
  {
    dataIndex: "payers.primary.policyHolder.firstName",
    rule: (firstName: string) => !_.isEmpty(firstName),
    errorMessage: "Primary payer policy holder first name is missing or blank",
  },
  {
    dataIndex: "payers.primary.policyHolder.lastName",
    rule: (lastName: string) => !_.isEmpty(lastName),
    errorMessage: "Primary payer policy holder last name is missing or blank",
  },
  {
    dataIndex: "payers.primary.policyHolder.dateOfBirth",
    rule: (dateOfBirth: string) => !_.isEmpty(dateOfBirth),
    errorMessage: "Primary payer policy holder DOB is missing or blank",
  },
  {
    dataIndex: "payers.primary.policyHolder.sex",
    rule: (sex: Sex) => !_.isEmpty(sex),
    errorMessage: "Primary payer policy holder sex is missing or blank",
  },
  {
    dataIndex: "",
    rule: (clientFile: IClientFile) =>
      isDeepEmpty(clientFile?.payers?.secondary) ||
      !_.isEmpty(clientFile?.payers?.secondary?.policyHolderRelationship),
    errorMessage: "Secondary payer policy holder relationship is missing or blank",
  },
  {
    dataIndex: "",
    rule: (clientFile: IClientFile) =>
      isDeepEmpty(clientFile?.payers?.secondary) ||
      !_.isEmpty(clientFile?.payers?.secondary?.policyHolder?.firstName),
    errorMessage: "Secondary payer policy holder first name is missing or blank",
  },
  {
    dataIndex: "",
    rule: (clientFile: IClientFile) =>
      isDeepEmpty(clientFile?.payers?.secondary) ||
      !_.isEmpty(clientFile?.payers?.secondary?.policyHolder?.lastName),
    errorMessage: "Secondary payer policy holder last name is missing or blank",
  },
  {
    dataIndex: "",
    rule: (clientFile: IClientFile) =>
      isDeepEmpty(clientFile?.payers?.secondary) ||
      !_.isEmpty(clientFile?.payers?.secondary?.memberNum),
    errorMessage: "Secondary payer member number last name is missing or blank",
  },
];

const isDeepEmpty = (obj: any): boolean => {
  if (_.isEmpty(obj)) {
    return true;
  }

  if (_.isObject(obj)) {
    if (Object.keys(obj).length === 0) return true;
    return _.every(_.map(obj, (v) => isDeepEmpty(v)));
  } else if (_.isString(obj)) {
    return !obj.length;
  }

  return false;
};

export const validateClientFileField = (clientFile: IClientFile, dataIndex: string) => {
  const validationRule = CLIENT_FILE_VALIDATION_RULES.find((rule) => rule.dataIndex === dataIndex);

  if (!validationRule) {
    return true;
  } else {
    const fieldValue = _.get(clientFile, dataIndex);
    return validationRule.rule(fieldValue);
  }
};

export const validateAllClientFileFields = (clientFile: IClientFile) => {
  const errorsMessages: string[] = [];
  for (const validationRule of CLIENT_FILE_VALIDATION_RULES) {
    const fieldValue = !_.isEmpty(validationRule.dataIndex)
      ? _.get(clientFile, validationRule.dataIndex)
      : clientFile;
    if (!validationRule.rule(fieldValue)) {
      errorsMessages.push(validationRule.errorMessage);
    }
  }
  return errorsMessages;
};

export const isIntakeComplete = (clientFile: IClientFile) => {
  return (
    isClientDetailsComplete(clientFile) &&
    isDocumentsComplete(clientFile?.documents) &&
    isPayersComplete(clientFile)
  );
};

export const isIntakeInProgress = (clientFile: IClientFile) => {
  return (
    isClientDetailsComplete(clientFile) ||
    isDocumentsComplete(clientFile?.documents) ||
    isPayersComplete(clientFile)
  );
};

export const isClientDetailsComplete = (clientFile: any) => {
  return !!clientFile?.clientId;
};

/**
 * @param documents
 * @returns True if clientFile's documents list is not empty, false otherwise
 */
export const isDocumentsComplete = (documents: IClientDocument[]) => {
  return !_.isEmpty(documents);
};

export const isPayersComplete = (clientFile: IClientFile) => {
  return !_.isEmpty(clientFile?.payers);
};

export const hasAuthFormError = (payer: IClientPayer | null) => {
  if (payer === null || _.isNil(payer.auth)) {
    return false;
  }
  return !isAuthEmpty(payer.auth) && !isAuthComplete(payer.auth);
};
