import { isAuthComplete, isAuthValid } from "@finni-health/shared";
import { Button, Card, Col, DatePicker, Form, Input, Row, Tag } from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";

import { DISPLAY_DATE_FORMAT } from "../../consts";
import { AddPayerAuthCodeButton } from "./AddPayerAuthButton";
import { PayerAuthCodesRow } from "./PayerAuthCodesRow";

interface IProps {
  hideOnLoad?: boolean;
  type: "primary" | "secondary";
}

enum AuthStatus {
  ACTIVE,
  EMPTY,
  ERROR,
  EXPIRED,
  INCOMPLETE,
  NOT_STARTED,
}

export const PayerAuthSection = ({ hideOnLoad, type }: IProps) => {
  const formInstance = Form.useFormInstance();
  const auth = Form.useWatch(["clientFile", "payers", type, "auth"], formInstance);

  const [isNew, setNew] = useState<boolean>(hideOnLoad ? true : false);

  useEffect(() => {
    if (auth?.startDate) {
      formInstance.setFieldValue(
        ["clientFile", "payers", type, "auth", "endDate"],
        auth.startDate.add(6, "months")
      );
    }
  }, [auth?.startDate]);

  const getAuthStatus = () => {
    if (_.isEmpty(auth)) {
      return AuthStatus.EMPTY;
    }

    if (
      _.isEmpty(auth.authNumber) &&
      _.isEmpty(auth.startDate) &&
      _.isEmpty(auth.endDate) &&
      _.isEmpty(auth.auths)
    ) {
      return AuthStatus.EMPTY;
    }

    if (!isAuthComplete(auth)) {
      return AuthStatus.INCOMPLETE;
    }

    if (isAuthValid(auth, moment().valueOf())) {
      return AuthStatus.ACTIVE;
    }

    if (moment(auth.startDate) > moment().endOf("day")) {
      return AuthStatus.NOT_STARTED;
    }

    return AuthStatus.EXPIRED;
  };

  const status = getAuthStatus();

  if (isNew && status === AuthStatus.EMPTY) {
    return (
      <Card
        style={{ minHeight: "230px", display: "flex", flexDirection: "column" }}
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Button type="dashed" onClick={() => setNew(false)}>
          Add Additional Authorization
        </Button>
      </Card>
    );
  }

  return (
    <Card size="small">
      <Row gutter={24}>
        <Col span={16}>
          <Form.Item
            label="Authorization Number"
            name={["clientFile", "payers", type, "auth", "authNumber"]}
            rules={[
              {
                required: status === AuthStatus.INCOMPLETE,
                message: "Authorization Number is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Row justify="end">
            {status === AuthStatus.ACTIVE && <Tag color="green">Active</Tag>}
            {status === AuthStatus.INCOMPLETE && <Tag color="orange">Incomplete</Tag>}
            {status === AuthStatus.EXPIRED && <Tag color="red">Expired</Tag>}
            {status === AuthStatus.NOT_STARTED && <Tag color="cyan">Not Started</Tag>}
          </Row>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Start Date"
            name={["clientFile", "payers", type, "auth", "startDate"]}
            rules={[
              {
                required: status === AuthStatus.INCOMPLETE,
                message: "Auth Start Date is required",
              },
            ]}
          >
            <DatePicker format={DISPLAY_DATE_FORMAT} autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="End Date"
            name={["clientFile", "payers", type, "auth", "endDate"]}
            rules={[
              {
                required: status === AuthStatus.INCOMPLETE,
                message: "Auth End Date is required",
              },
            ]}
          >
            <DatePicker format={DISPLAY_DATE_FORMAT} autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: "12px" }}>
        <PayerAuthCodesRow type={type} />
      </Row>
      <Row>
        <Form.Item
          name={["clientFile", "payers", type, "auth", "auths"]}
          rules={[
            {
              required: status === AuthStatus.INCOMPLETE,
              validator: (rules, value) => {
                if (!rules.required) {
                  return Promise.resolve();
                }
                return !_.isEmpty(value) ? Promise.resolve() : Promise.reject();
              },
              message: "At least one auth code is required",
            },
          ]}
        >
          <AddPayerAuthCodeButton type={type} />
        </Form.Item>
      </Row>
    </Card>
  );
};
