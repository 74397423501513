import {
  AppointmentLocation,
  BillingCode,
  BillingLevel,
  getAllPayersForState,
  IAddress,
  Modifier,
  PayerCode,
} from "@finni-health/shared";
import { Table } from "antd";

import { renderAddress } from "../../helpers/address";
import { getAppointmentLocationText } from "../../helpers/appointments";
import { useUserClinics } from "../UserClinicsProvider";

export const PayerRatesTable: React.FC = () => {
  const { clinic } = useUserClinics();

  const payers = getAllPayersForState(clinic.address.state);

  const getTableColumns = () => {
    const tableColumns = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Payer ID",
        dataIndex: "payerId",
      },
      {
        title: "Contract Address",
        dataIndex: "contractAddress",
        render: (contractAddress: IAddress) => renderAddress(contractAddress),
      },
      {
        title: "Billing Address",
        dataIndex: "billingAddress",
        render: (billingAddress: IAddress) => renderAddress(billingAddress),
      },
      {
        title: "Provider Type Modifiers",
        dataIndex: "modifiers",
        render: (modifiers: {
          [billingLevel in BillingLevel]?: string;
        }) => <pre>{JSON.stringify(modifiers, null, 4)}</pre>,
      },
      {
        title: "Location Modifiers",
        dataIndex: "locationModifiers",
        render: (locationModifiers: {
          [location in AppointmentLocation]?: string;
        }) => {
          const result = {};
          for (const key in locationModifiers) {
            result[getAppointmentLocationText(key as AppointmentLocation)] = locationModifiers[key];
          }

          return <pre>{JSON.stringify(result, null, 4)}</pre>;
        },
      },
      {
        title: "Billing Codes",
        dataIndex: "codes",
        render: (codes: {
          [code in BillingCode]?:
            | PayerCode
            | {
                [modifier in Modifier | ""]?: PayerCode;
              };
        }) => <pre>{JSON.stringify(codes, null, 2)}</pre>,
      },
    ];

    return tableColumns;
  };

  return (
    <>
      <Table columns={getTableColumns()} dataSource={payers} />
    </>
  );
};
