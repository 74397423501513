import { ICreateInviteRequest, IUser, UserPermission } from "@finni-health/shared";
import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import { useState } from "react";

import { ERROR_MESSAGE, VALID_EMAIL_REGEX } from "../../consts";
import * as FirestoreService from "../../services/firestore";

interface ICreateInviteFormValues {
  firstName: string;
  lastName: string;
  email: string;
  role: UserPermission;
}

interface Props {
  hideModal: () => void;
  refreshCallback: () => void;
  isVisible: boolean;
  user: IUser;
}

export const CreateInviteModal = ({ hideModal, isVisible, user, refreshCallback }: Props) => {
  const [form] = Form.useForm<ICreateInviteFormValues>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleFormOnFinish = async (values: ICreateInviteFormValues) => {
    setIsSaving(true);
    const createInquiryRequest: ICreateInviteRequest = {
      clinicId: user.clinicId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      role: values.role,
      directRateCents: 0,
      indirectRateCents: 0,
      salaryRateCents: 0,
    };
    try {
      await FirestoreService.createInvite(createInquiryRequest);
      await FirestoreService.getAllUsersForClinic(user.clinicId, true);
      form.resetFields();
      refreshCallback();
      hideModal();
      void message.success("Invite created");
    } catch (err: any) {
      void message.error((err as string) || ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  return (
    <Modal
      title={`Create Invite`}
      onCancel={hideModal}
      open={isVisible}
      footer={false}
      width={800}
      style={{ top: 20 }}
    >
      <Form form={form} layout="vertical" labelCol={{ span: 24 }} onFinish={handleFormOnFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please provide a First Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please provide a Last Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  pattern: VALID_EMAIL_REGEX,
                  message: "Please provide a valid email address",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please choose a Role",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {Object.keys(UserPermission).map(
                  (role) =>
                    ![UserPermission.SUPERADMIN, UserPermission.ADMIN].includes(
                      role as UserPermission
                    ) && (
                      <Select.Option key={role} value={role}>
                        {role}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button loading={isSaving} type="primary" htmlType="submit">
            Create Invite
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
