import { getAuth } from "firebase/auth";

import app from "./firebase";
import { handleRequest } from "./middleware";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const endpoint = (uri: string, method: string) => {
  const configuredEndpoint = async (body: any) => {
    const authToken = await getAuth(app).currentUser?.getIdToken();
    const response = await fetch(BACKEND_BASE_URL + uri, {
      method: method,
      mode: (process.env.CORS || "cors") as RequestMode,
      body: JSON.stringify(handleRequest(body, uri)),
      headers: {
        "content-type": "application/json",
        authorization: authToken || "",
      },
    });

    const result = await response.json();

    if (response.ok) {
      return result;
    } else {
      return Promise.reject(result);
    }
  };

  return configuredEndpoint;
};
