import { green, red, yellow } from "@ant-design/colors";
import {
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  AttendeeStatus,
  ICalendarEvent,
  IIndirect,
  IndirectStatus,
  UserPermission,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import {
  Badge,
  Button,
  Card,
  Popconfirm,
  Popover,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { useUserClinics } from "../../components/UserClinicsProvider";
import { DISPLAY_TIME_FORMAT } from "../../consts";
import { getReasonText } from "../../helpers/appointments";
import { getRejectionReasonText } from "../../helpers/rejectionReason";
import { userHasPermission } from "../../helpers/userPermissions";
import { useHover } from "../../hooks/useHover";
import * as FirestoreService from "../../services/firestore";
import { EditIndirectModal } from "./EditIndirectModal";
import { RejectIndirectModal } from "./RejectIndirectModal";
const { Text, Title } = Typography;

interface IProps {
  indirect: IIndirect;
  forceOpen: boolean;
  setSelectedEvent: (event: IIndirect) => void;
  refreshCallback: () => Promise<void>;
  isCalendarProcessing: boolean;
  scheduleConflicts?: ICalendarEvent[];
  style?: React.CSSProperties;
  onActionsChange?: (actions: any) => void;
}

export const IndirectCard: React.FC<IProps> = ({
  indirect,
  forceOpen,
  setSelectedEvent,
  refreshCallback,
  isCalendarProcessing,
  scheduleConflicts,
  style,
  onActionsChange,
}: IProps) => {
  const { user } = useUserClinics();
  const [open, setOpen] = useState<boolean>(false);
  const [ref, setRef] = useState<any>();
  const [sentRefreshCallback, setSentRefreshCallback] = useState<boolean>(false);

  //Schedule conflicts
  const [scheduleConflictMessage, setScheduleConflictMessage] =
    useState<string>("Schedule conflict");

  useEffect(() => {
    if (scheduleConflicts && scheduleConflicts.length > 0) {
      setScheduleConflictMessage("Therapist scheduling conflict");
    }
  }, [scheduleConflicts]);

  const [isEditIndirectModalOpen, setIsEditIndirectModalOpen] = useState<boolean>(false);
  const hideEditIndirectModal = () => {
    setIsEditIndirectModalOpen(false);
  };

  const [isRejectIndirectModalOpen, setIsRejectIndirectModalOpen] = useState<boolean>(false);
  const hideRejectIndirectModal = () => {
    setIsRejectIndirectModalOpen(false);
  };

  const {
    isHover: approveHover,
    onMouseEnter: handleApproveMouseEnter,
    onMouseLeave: handleApproveMouseLeave,
  } = useHover();
  const {
    isHover: rejectHover,
    onMouseEnter: handleRejectMouseEnter,
    onMouseLeave: handleRejectMouseLeave,
  } = useHover();

  const hidePopover = () => {
    setSelectedEvent({} as IIndirect);
    setOpen(false);
  };

  const handlePopoverOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      setSelectedEvent(indirect);
      setOpen(true);
    } else {
      setSelectedEvent({} as IIndirect);
      setOpen(false);
    }
  };

  const handleAcceptIndirect = async () => {
    setSentRefreshCallback(true);
    await FirestoreService.updateIndirect({
      id: indirect.id,
      attendees: indirect.attendees.map((attendee) => ({
        email: attendee.email,
        status: AttendeeStatus.ACCEPTED,
      })),
      status: IndirectStatus.APPROVED,
      rejectionReason: null,
      rejectionNotes: null,
    });
    await refreshCallback();
  };

  const handleDeleteIndirect = async () => {
    setSentRefreshCallback(true);
    await FirestoreService.deleteIndirect({ id: indirect.id });
    await refreshCallback();
  };

  //Scroll to ref on forceOpen page load
  useEffect(() => {
    if (forceOpen && ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [forceOpen, ref]);

  useEffect(() => {
    if (forceOpen) {
      handlePopoverOpenChange(true);
    }
  }, [forceOpen]);

  //Loading on cards
  useEffect(() => {
    if (sentRefreshCallback && !isCalendarProcessing) {
      setSentRefreshCallback(false);
    }
  }, [isCalendarProcessing]);
  const handleSubRefresh = () => {
    setSentRefreshCallback(true);
    return refreshCallback();
  };

  useEffect(() => {
    setRef(React.createRef());
    if (onActionsChange) {
      onActionsChange({
        acceptIndirect: handleAcceptIndirect,
        rejectIndirect: () => {
          setIsRejectIndirectModalOpen(true);
        },
      });
    }
  }, []);

  const popoverContent = () => {
    return (
      <div style={{ width: 300 }}>
        <Row justify="end" style={{ marginBottom: 5 }}>
          <Space size={17}>
            <Space size={0}>
              <Tooltip placement="bottom" title="Edit indirect">
                <Button
                  type="text"
                  size="small"
                  onClick={() => {
                    hidePopover();
                    setIsEditIndirectModalOpen(true);
                  }}
                  style={{ paddingTop: 1 }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title="Delete indirect">
                <Popconfirm
                  onConfirm={handleDeleteIndirect}
                  title={"Are you sure you want to permanently delete this indirect?"}
                  okText="Delete"
                >
                  <Button type="text" size="small">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Space>
            <Tooltip placement="bottom" title="Close">
              <Button type="text" size="small" onClick={hidePopover}>
                <CloseOutlined />
              </Button>
            </Tooltip>
          </Space>
        </Row>
        <Row>
          <Title level={4} style={{ marginBottom: 0 }}>
            {indirect.summary}
          </Title>
        </Row>
        <Row>
          <Text>{`${moment(indirect.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
            indirect.endMs
          ).format(DISPLAY_TIME_FORMAT)}`}</Text>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Text strong>Guests</Text>
        </Row>
        {indirect.attendees.map((attendee) => (
          <Tag key={attendee.email} style={{ marginBottom: 2, fontSize: 12, maxWidth: 200 }}>
            <Badge
              status={
                attendee.status === AttendeeStatus.ACCEPTED
                  ? "success"
                  : attendee.status === AttendeeStatus.DECLINED
                  ? "error"
                  : attendee.status === AttendeeStatus.TENTATIVE
                  ? "warning"
                  : "default"
              }
              style={{ marginRight: 3 }}
            />
            {attendee.email}
          </Tag>
        ))}
        {indirect.indirectReason && (
          <>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Indirect Reason</Text>
            </Row>
            <Row>
              <Text>{`${getReasonText(indirect.indirectReason)}`}</Text>
            </Row>
          </>
        )}
        {indirect.description && (
          <>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Description</Text>
            </Row>
            <Row>
              <Text>{indirect.description}</Text>
            </Row>
          </>
        )}
        {indirect.rejectionReason && (
          <>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Rejection Notes</Text>
            </Row>
            <Row>
              <Text>
                {getRejectionReasonText(indirect.rejectionReason)}. {indirect.rejectionNotes}
              </Text>
            </Row>
          </>
        )}
        {!userHasPermission(user, UserPermission.RBT) && (
          <Row
            justify="end"
            style={{
              marginTop: 20,
            }}
          >
            <Button
              type="default"
              onClick={() => {
                hidePopover();
                setIsRejectIndirectModalOpen(true);
              }}
              style={
                indirect.status === IndirectStatus.REJECTED
                  ? {
                      borderColor: red.primary,
                      color: "white",
                      backgroundColor: red.primary,
                    }
                  : {
                      borderColor: rejectHover ? red.primary : "",
                      color: rejectHover ? red.primary : "",
                    }
              }
              onMouseEnter={handleRejectMouseEnter}
              onMouseLeave={handleRejectMouseLeave}
            >
              <CloseOutlined />
              {indirect.status === IndirectStatus.REJECTED ? "Rejected" : "Reject"}
            </Button>
            <Button
              type="default"
              onClick={() => {
                hidePopover();
                return handleAcceptIndirect();
              }}
              style={
                indirect.status === IndirectStatus.APPROVED
                  ? {
                      marginLeft: 10,
                      borderColor: green.primary,
                      color: "white",
                      backgroundColor: green.primary,
                    }
                  : {
                      marginLeft: 10,
                      borderColor: approveHover ? green.primary : "",
                      color: approveHover ? green.primary : "",
                    }
              }
              onMouseEnter={handleApproveMouseEnter}
              onMouseLeave={handleApproveMouseLeave}
            >
              <CheckOutlined />
              {indirect.status === IndirectStatus.APPROVED ? "Approved" : "Approve"}
            </Button>
          </Row>
        )}
      </div>
    );
  };

  return (
    <>
      <Popover
        trigger="click"
        placement="left"
        open={open}
        onOpenChange={handlePopoverOpenChange}
        content={popoverContent}
        zIndex={500}
        showArrow={false}
        getPopupContainer={(trigger) => trigger.parentElement!}
      >
        <Card
          ref={ref}
          hoverable
          loading={sentRefreshCallback}
          style={{
            marginBottom: 5,
            boxShadow: open
              ? "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)"
              : undefined,
            borderColor:
              scheduleConflicts && scheduleConflicts.length > 0 ? yellow.primary : undefined,
            ...style,
          }}
          bodyStyle={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 3,
          }}
        >
          <Row justify="space-between" style={{ width: "100%", height: 24 }}>
            <Text strong>{indirect.summary}</Text>
            <Row align="middle">
              {scheduleConflicts && scheduleConflicts.length > 0 && (
                <Tooltip title={scheduleConflictMessage}>
                  <WarningFilled
                    style={{
                      marginRight: 4,
                      marginBottom: 1,
                      fontSize: 17,
                      color: yellow.primary,
                    }}
                  />
                </Tooltip>
              )}
              {indirect.status === IndirectStatus.APPROVED && (
                <Tooltip title="Approved" className="status-icon">
                  <CheckCircleOutlined style={{ color: green.primary, marginRight: 5 }} />
                </Tooltip>
              )}
              {indirect.status === IndirectStatus.REJECTED && (
                <Tooltip
                  title={`Rejected 
                            (${getRejectionReasonText(indirect.rejectionReason)})`}
                  className="status-icon"
                >
                  <CloseCircleOutlined style={{ color: red.primary, marginRight: 5 }} />
                </Tooltip>
              )}
              {indirect.status === IndirectStatus.PENDING && (
                <Tooltip title="Pending BCBA review" className="status-icon">
                  <ClockCircleOutlined style={{ color: COLORS.DARK_GRAY, marginRight: 5 }} />
                </Tooltip>
              )}
            </Row>
          </Row>

          <br />

          <Text>{`${moment(indirect.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
            indirect.endMs
          ).format(DISPLAY_TIME_FORMAT)}`}</Text>
          <br />
          {indirect.attendees.slice(0, 2).map((attendee) => (
            <Tag key={attendee.email} style={{ marginBottom: 2, fontSize: 11, maxWidth: 130 }}>
              <Badge
                status={
                  attendee.status === AttendeeStatus.ACCEPTED
                    ? "success"
                    : attendee.status === AttendeeStatus.DECLINED
                    ? "error"
                    : attendee.status === AttendeeStatus.TENTATIVE
                    ? "warning"
                    : "default"
                }
                style={{ marginRight: 3 }}
              />
              {attendee.email}
            </Tag>
          ))}
          <br />
          {indirect.attendees.slice(2).length > 0 && (
            <Text style={{ fontSize: 12 }}>
              {`And ${indirect.attendees.slice(2).length} other guest${
                indirect.attendees.slice(2).length > 1 ? "s" : ""
              }`}
            </Text>
          )}
        </Card>
      </Popover>
      <EditIndirectModal
        indirect={indirect}
        refreshCallback={handleSubRefresh}
        hideModal={hideEditIndirectModal}
        isOpen={isEditIndirectModalOpen}
      />
      <RejectIndirectModal
        indirect={indirect}
        refreshCallback={handleSubRefresh}
        hideModal={hideRejectIndirectModal}
        isOpen={isRejectIndirectModalOpen}
      />
    </>
  );
};
