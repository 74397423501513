import {
  CountryCode,
  Credential,
  IAvailability,
  ICredential,
  IUserAvailability,
  Sex,
  UserPermission,
  USStateCode,
} from "@finni-health/shared";
import { EditAvailabilityTable } from "@finni-health/ui";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";

import { CredentialInput } from "../components/ClinicSettings/CredentialInput";
import { PhoneNumberInput } from "../components/PhoneNumberInput";
import { useUserClinics } from "../components/UserClinicsProvider";
import { PreferredTransportSelector } from "../components/UserProfile/PreferredTransport";
import { DB_DATE_FORMAT, VALID_FINNI_EMAIL_REGEX, VALID_ZIP_CODE_REGEX } from "../consts";
import { updatePreferredTransport } from "../helpers/profiles";
import { userHasPermission } from "../helpers/userPermissions";
import * as FirestoreService from "../services/firestore";
import { Loading } from "./Loading";

const { Panel } = Collapse;
const { TextArea } = Input;

interface IUserProfileFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: Moment;
  line1: string;
  line2: string;
  city: string;
  state: USStateCode;
  zipCode: string;
  country: CountryCode;
  addressNotes: string;
  npi: string;
  credentials: ICredential[];
}

export const UserProfile: React.FC = () => {
  const { user, refresh } = useUserClinics();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [userAvailability, setUserAvailability] = useState<IUserAvailability>(
    {} as IUserAvailability
  );
  const [tempAvailability, setTempAvailability] = useState<IAvailability>(
    userAvailability.availability
  );

  const [userForm] = Form.useForm<IUserProfileFormValues>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const getUserAvailability = async () => {
    setIsLoading(true);
    if (user) {
      const availability = await FirestoreService.getUserAvailabilityByUserId({
        userId: user.id,
        clinicId: user.clinicId,
      });
      setUserAvailability(availability);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserAvailability().catch(() => {});
  }, [user]);

  const saveUser = async () => {
    setIsSaving(true);

    const values = userForm.getFieldsValue();
    const credentials = userForm.getFieldValue("credentials"); //arrays don't work properly with getFieldsValue

    try {
      await FirestoreService.updateUser({
        id: user.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        sex: Sex.UNKNOWN,
        dateOfBirth: moment(values.dateOfBirth).format(DB_DATE_FORMAT),
        address: {
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          country: values.country,
        },
        addressNotes: values.addressNotes,
        npi: values.npi,
        credentials,
      });
      await FirestoreService.updateUserAvailability({
        id: userAvailability.id,
        userId: user.id,
        availability: tempAvailability,
        clinicId: user.clinicId,
      });
      void message.success(`Profile successfully updated`);
      await refresh();
    } catch (error) {
      console.error(error);
      void message.error(
        "Oops! Something went wrong when saving your profile or availability, please check that all required fields are filled and correct"
      );
    }

    setIsSaving(false);
  };

  const handleUpdateCredential = (credential: ICredential) => {
    if (credential) {
      const currCredentials = userForm.getFieldValue("credentials") || [];
      const credIndex = currCredentials?.findIndex(
        (cred: ICredential) => cred.type === credential.type
      );
      if (credIndex === -1) {
        currCredentials.push(credential);
      } else {
        currCredentials[credIndex] = credential;
      }
      userForm.setFieldValue("credentials", currCredentials);
    }
  };

  const ProfileInformationSection = () => {
    return (
      <>
        <Divider orientation="left">Profile Information</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="First Name" name="firstName" initialValue={user.firstName}>
              <Input disabled={isSaving} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name" name="lastName" initialValue={user.lastName}>
              <Input disabled={isSaving} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              initialValue={user.email}
              rules={[
                {
                  required: true,
                  pattern: VALID_FINNI_EMAIL_REGEX,
                  message: "Please enter a valid @finnihealth.com email",
                },
              ]}
            >
              <Input disabled={isSaving} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone Number" name="phoneNumber" initialValue={user.phoneNumber}>
              <PhoneNumberInput disabled={isSaving} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Date of Birth"
              name="dateOfBirth"
              initialValue={moment(user.dateOfBirth)}
            >
              <DatePicker
                placeholder="YYYY-MM-DD"
                allowClear={false}
                autoComplete="off"
                disabledDate={(curr) => curr && curr > moment().startOf("day")}
                disabled={isSaving}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Preferred Transport" name="preferredTransport">
              <PreferredTransportSelector
                user={user}
                onChange={(e) => updatePreferredTransport(e.target.value, user.id, () => {})}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  const AddressSection = () => {
    return (
      <>
        <Divider orientation="left">Address</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Address Line 1" name="line1" initialValue={user.address?.line1}>
              <Input disabled={isSaving} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Address Line 2" name="line2" initialValue={user.address?.line2}>
              <Input disabled={isSaving} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="City" name="city" initialValue={user.address?.city}>
              <Input disabled={isSaving} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="State" name="state" initialValue={user.address?.state}>
              <Select showSearch disabled={isSaving}>
                {Object.values(USStateCode).map((state) => (
                  <Select.Option value={state} key={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Zip Code"
              name="zipCode"
              initialValue={user.address?.zipCode}
              rules={[
                {
                  pattern: VALID_ZIP_CODE_REGEX,
                  message: "Please enter a valid zip code",
                },
              ]}
            >
              <Input disabled={isSaving} maxLength={10} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Country" name="country" initialValue={CountryCode.US}>
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Address Notes" name="addressNotes" initialValue={user.addressNotes}>
              <TextArea autoSize={true} disabled={isSaving} />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  const CredentialsSection = () => {
    return (
      <>
        <Divider orientation="left">Credentials</Divider>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="NPI" name="npi" initialValue={user.npi}>
              <TextArea autoSize={true} disabled={isSaving} />
            </Form.Item>
            {Object.values(Credential)
              .filter((cred) =>
                userHasPermission(user, UserPermission.RBT)
                  ? cred != Credential.BCBA_CERTIFICATION
                  : cred != Credential.RBT_CERTIFICATION
              )
              .map((credentialType) => (
                <Form.Item
                  key={credentialType}
                  label={credentialType}
                  name={credentialType}
                  initialValue={user?.credentials?.find((cred) => cred.type === credentialType)}
                >
                  <CredentialInput
                    autoSize={true}
                    disabled={isSaving}
                    type={credentialType}
                    credential={user?.credentials?.find((cred) => cred.type === credentialType)}
                    updateCredential={handleUpdateCredential}
                  />
                </Form.Item>
              ))}
          </Col>
        </Row>
      </>
    );
  };

  // TODO: Add name and email validation
  return isLoading ? (
    <Loading />
  ) : (
    <Row style={{ margin: "auto", maxWidth: 800 }}>
      <Col style={{ width: "100%" }}>
        <Form layout="vertical" labelCol={{ span: 24 }} form={userForm} onFinish={saveUser}>
          <Row justify="end" style={{ marginBottom: 20 }}>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={isSaving}
              style={{ float: "right" }}
            >
              Save Changes
            </Button>
          </Row>
          <ProfileInformationSection />
          <AddressSection />
          <CredentialsSection />
          <div style={{ marginBottom: 25 }}>
            <Collapse>
              <Panel header="Availability" key="1">
                <Row justify="center">
                  <EditAvailabilityTable
                    isEditingDisabled={false}
                    onAvailabilityChange={setTempAvailability}
                    initialAvailability={userAvailability.availability}
                  />
                </Row>
              </Panel>
            </Collapse>
          </div>
        </Form>
      </Col>
    </Row>
  );
};
