import { InquiryStatus, IntakeStatus } from "@finni-health/shared";
import { Tag } from "antd";

interface Props {
  status: InquiryStatus | IntakeStatus;
  style?: React.CSSProperties;
}

export enum StatusTagColors {
  UNDISCOVERED = "cyan",
  REFERRAL = "cyan",

  IN_PROCESS = "geekblue",

  AWAITING_RESPONSE = "gold",
  AWAITING_DIAGNOSIS = "gold",
  AWAITING_INSURANCE = "gold",
  CONFIRMING_INSURANCE = "gold",
  PENDING_AUTHORIZATION = "gold",

  INTAKE_ASSESSMENT = "purple",
  IN_PERSON_ASSESSMENT = "magenta",
  ISP_IN_PROCESS = "orange",
  ISP_SUBMITTED = "cyan",

  ACTIVE = "green",
  ON_HOLD = "default",

  UNQUALIFIED = "default",
  NO_RESPONSE = "default",
  CHURNED = "default",
}

export enum StatusTagText {
  UNDISCOVERED = "UNDISCOVERED",
  REFERRAL = "REFERRAL",

  IN_PROCESS = "IN PROCESS",

  AWAITING_RESPONSE = "AWAITING RESPONSE",
  AWAITING_DIAGNOSIS = "AWAITING DIAGNOSIS",
  AWAITING_INSURANCE = "AWAITING INSURANCE",
  CONFIRMING_INSURANCE = "CONFIRMING INSURANCE",
  PENDING_AUTHORIZATION = "PENDING AUTHORIZATION",

  INTAKE_ASSESSMENT = "INTAKE ASSESSMENT",
  IN_PERSON_ASSESSMENT = "IN PERSON ASSESSMENT",
  ISP_IN_PROCESS = "ISP IN PROCESS",
  ISP_SUBMITTED = "ISP SUBMITTED",

  ACTIVE = "ACTIVE",
  ON_HOLD = "ON HOLD",

  UNQUALIFIED = "UNQUALIFIED",
  NO_RESPONSE = "NO RESPONSE",
  CHURNED = "CHURNED",
}

export const IntakeStatusTag = ({ status, style }: Props) => {
  return (
    <Tag color={StatusTagColors[status]} style={{ ...style, marginRight: 0 }}>
      {StatusTagText[status]}
    </Tag>
  );
};
