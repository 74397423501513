import {
  getCurrentActiveAuth,
  IBilledAppointment,
  isPayerAuthRequired,
  Modifier,
  PolicyHolderRelationship,
  UserPermission,
} from "@finni-health/shared";
import { isEmpty } from "lodash";
import moment from "moment";

import { DISPLAY_DATE_FORMAT } from "../../consts";
import { ICsvTranslatorField } from "../../helpers/csv";

export const ADONIS_BILLING_CSV_EXPORT_TEMPLATE: ICsvTranslatorField[] = [
  {
    title: "external_encounter_id",
    dataIndex: "id",
  },
  {
    title: "external_patient_id",
    dataIndex: "client.id",
  },
  {
    title: "patient_first_name",
    dataIndex: "client.firstName",
  },
  {
    title: "patient_last_name",
    dataIndex: "client.lastName",
  },
  {
    title: "patient_date_of_birth",
    dataIndex: "client.dateOfBirth",
  },
  {
    title: "patient_gender",
    dataIndex: "client.sex",
  },
  {
    title: "patient_address_1",
    dataIndex: "clientFile.address.line1",
  },
  {
    title: "patient_address_2",
    dataIndex: "clientFile.address.line2",
  },
  {
    title: "patient_city",
    dataIndex: "clientFile.address.city",
  },
  {
    title: "patient_state",
    dataIndex: "clientFile.address.state",
  },
  {
    title: "patient_zip_code",
    dataIndex: "clientFile.address.zipCode",
  },
  {
    title: "primary_payer_name",
    dataIndex: "payer.name",
  },
  {
    title: "primary_payer_type",
    dataIndex: "",
    render: () => "CI",
  },
  {
    title: "primary_payer_plan",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "primary_payer_id",
    dataIndex: "payer.payerId",
  },
  {
    title: "primary_payer_member_id",
    dataIndex: "clientFile.payers.primary.memberNum",
  },
  {
    title: "patient_relationship_to_subscriber_code",
    dataIndex: "clientFile.payers.primary.policyHolderRelationship",
    render: (relationship: PolicyHolderRelationship) => {
      switch (relationship) {
        case PolicyHolderRelationship.CHILD:
          return "19";
        default:
          return "18";
      }
    },
  },
  {
    title: "primary_payer_group_number",
    dataIndex: "clientFile.payers.primary.groupNum",
  },
  {
    title: "subscriber_first_name",
    dataIndex: "clientFile.payers.primary.policyHolder.firstName",
  },
  {
    title: "subscriber_last_name",
    dataIndex: "clientFile.payers.primary.policyHolder.lastName",
  },
  {
    title: "subscriber_middle_name",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "subscriber_suffix",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "subscriber_telephone_number",
    dataIndex: "guardian.phoneNumber.primary",
  },
  {
    title: "subscriber_date_of_birth",
    dataIndex: "clientFile.payers.primary.policyHolder.dateOfBirth",
  },
  {
    title: "subscriber_gender",
    dataIndex: "clientFile.payers.primary.policyHolder.sex",
  },
  {
    title: "subscriber_address_1",
    dataIndex: "clientFile.address.line1",
  },
  {
    title: "subscriber_address_2",
    dataIndex: "clientFile.address.line2",
  },
  {
    title: "subscriber_city",
    dataIndex: "clientFile.address.city",
  },
  {
    title: "subscriber_state",
    dataIndex: "clientFile.address.state",
  },
  {
    title: "subscriber_zip_code",
    dataIndex: "clientFile.address.zipCode",
  },
  {
    title: "secondary_payer_name",
    dataIndex: "secondaryPayer.name",
  },
  {
    title: "secondary_payer_type",
    dataIndex: "",
    render: (appt: IBilledAppointment) => (!isEmpty(appt.secondaryPayer) ? "CI" : ""),
  },
  {
    title: "secondary_payer_plan",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "secondary_payer_id",
    dataIndex: "secondaryPayer.payerId",
  },
  {
    title: "secondary_payer_member_id",
    dataIndex: "clientFile.payers.secondary.memberNum",
  },
  {
    title: "secondary_patient_relationship_to_subscriber_code",
    dataIndex: "clientFile.payers.secondary.policyHolderRelationship",
    render: (relationship: PolicyHolderRelationship) => {
      switch (relationship) {
        case PolicyHolderRelationship.CHILD:
          return "19";
        default:
          return "18";
      }
    },
  },
  {
    title: "secondary_payer_group_number",
    dataIndex: "clientFile.payers.secondary.groupNum",
  },
  {
    title: "rendering_provider_first_name",
    dataIndex: "renderingUser.firstName",
  },
  {
    title: "rendering_provider_last_name",
    dataIndex: "renderingUser.lastName",
  },
  {
    title: "rendering_provider_taxonomy_code",
    dataIndex: "renderingUser.permissions",
    render: (userPermissions: UserPermission[]) =>
      userPermissions.includes(UserPermission.BCBA) ? "103K00000X" : "106S00000X",
  },
  {
    title: "rendering_provider_npi",
    dataIndex: "renderingUser.npi",
  },
  {
    title: "rendering_provider_address_1",
    dataIndex: "renderingUser.address.line1",
  },
  {
    title: "rendering_provider_address_2",
    dataIndex: "renderingUser.address.line2",
  },
  {
    title: "rendering_provider_city",
    dataIndex: "renderingUser.address.city",
  },
  {
    title: "rendering_provider_state",
    dataIndex: "renderingUser.address.state",
  },
  {
    title: "rendering_provider_zip_code",
    dataIndex: "renderingUser.address.zipCode",
    render: (zipCode: string) => zipCode.slice(0, 5),
  },
  {
    title: "rendering_provider_zip_plus_four_code",
    dataIndex: "renderingUser.address.zipCode",
    render: (zipCode: string) => zipCode.slice(6) || "",
  },
  {
    title: "billing_provider_organization_name",
    dataIndex: "",
    render: () => "JOURNEY HEALTH TECHNOLOGIES U.S. INC.",
  },
  {
    title: "billing_provider_first_name",
    dataIndex: "billingUser.firstName",
  },
  {
    title: "billing_provider_last_name",
    dataIndex: "billingUser.lastName",
  },
  {
    title: "billing_provider_taxonomy_code",
    dataIndex: "billingUser.permissions",
    render: (userPermissions: UserPermission[]) =>
      userPermissions.includes(UserPermission.BCBA) ? "103K00000X" : "106S00000X",
  },
  {
    title: "billing_provider_npi",
    dataIndex: "billingUser.npi",
  },
  {
    title: "billing_provider_tax_id",
    dataIndex: "",
    render: () => "882173251",
  },
  {
    title: "billing_provider_address_1",
    dataIndex: "billingUser.address.line1",
  },
  {
    title: "billing_provider_address_2",
    dataIndex: "billingUser.address.line2",
  },
  {
    title: "billing_provider_city",
    dataIndex: "billingUser.address.city",
  },
  {
    title: "billing_provider_state",
    dataIndex: "billingUser.address.state",
  },
  {
    title: "billing_provider_zip_code",
    dataIndex: "billingUser.address.zipCode",
    render: (zipCode: string) => zipCode.slice(0, 5),
  },
  {
    title: "billing_provider_zip_plus_four_code",
    dataIndex: "billingUser.address.zipCode",
    render: (zipCode: string) => zipCode.slice(6),
  },
  {
    title: "billing_provider_telephone_number",
    dataIndex: "billingUser.phoneNumber",
  },
  {
    title: "service_facility_organization_name",
    dataIndex: "",
    render: () => "JOURNEY HEALTH TECHNOLOGIES U.S. INC.",
  },
  {
    title: "service_facility_npi",
    dataIndex: "",
    render: () => "1528704236",
  },
  {
    title: "service_facility_address_1",
    dataIndex: "payer.billingAddress.line1",
  },
  {
    title: "service_facility_address_2",
    dataIndex: "payer.billingAddress.line2",
  },
  {
    title: "service_facility_city",
    dataIndex: "payer.billingAddress.city",
  },
  {
    title: "service_facility_state",
    dataIndex: "payer.billingAddress.state",
  },
  {
    title: "service_facility_zip_code",
    dataIndex: "payer.billingAddress.zipCode",
    render: (zipCode: string) => zipCode.slice(0, 5),
  },
  {
    title: "service_facility_zip_plus_four_code",
    dataIndex: "payer.billingAddress.zipCode",
    render: (zipCode: string) => zipCode.slice(6) || "",
  },
  {
    title: "prior_authorization_number",
    dataIndex: "",
    render: (appt: IBilledAppointment) => {
      const authRequired = isPayerAuthRequired(
        appt.clinic.address.state,
        appt.payer.payerId,
        appt.billingCode,
        appt.modifiers
      );
      if (!authRequired) {
        return "No Authorization Required";
      }

      const currentAuth = getCurrentActiveAuth(
        appt.clientFile.payers?.primary ?? null,
        appt.startMs
      );
      return currentAuth?.authNumber || "Auth Number Missing";
    },
  },
  {
    title: "service_line_1_date_of_service_start",
    dataIndex: "startMs",
    render: (startMs: number) => moment(startMs).format(DISPLAY_DATE_FORMAT),
  },
  {
    title: "service_line_1_place_of_service_code",
    dataIndex: "location",
  },
  {
    title: "service_line_1_cpt_hcpcs_code",
    dataIndex: "billingCode",
  },
  {
    title: "service_line_1_quantity",
    dataIndex: "units",
  },
  {
    title: "service_line_1_quantity_type",
    dataIndex: "",
    render: () => "U",
  },
  {
    title: "service_line_1_icd_10_dx_code",
    dataIndex: "",
    render: () => "F84.0",
  },
  {
    title: "service_line_1_modifier_1",
    dataIndex: "modifiers",
    render: (modifiers: Modifier[]) => modifiers[0] || "",
  },
  {
    title: "service_line_1_modifier_2",
    dataIndex: "modifiers",
    render: (modifiers: Modifier[]) => modifiers[1] || "",
  },
  {
    title: "service_line_1_modifier_3",
    dataIndex: "modifiers",
    render: (modifiers: Modifier[]) => modifiers[2] || "",
  },
  {
    title: "service_line_1_modifier_4",
    dataIndex: "modifiers",
    render: (modifiers: Modifier[]) => modifiers[3] || "",
  },
  {
    title: "service_line_1_charge_amount_cents",
    dataIndex: "chargeCents",
  },
];
