import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  ActiveStatus,
  AppointmentLocation,
  AppointmentType,
  getAppointmentsByPayer,
  IAppointmentType,
  ICalendarCreateAppointmentEndpointRequest,
  IClient,
  IClientGuardianDetails,
  IntakeStatus,
  IUser,
  UserPermission,
} from "@finni-health/shared";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import * as momentTz from "moment-timezone";
import { useEffect, useState } from "react";

import {
  AM_HOURS,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  ERROR_MESSAGE_APPOINTMENTS,
} from "../../consts";
import { getAppointmentLocationText } from "../../helpers/appointments";
import { getAppointmentSummary as getRecurringApptSummary } from "../../helpers/appointments";
import { userHasPermission } from "../../helpers/userPermissions";
import * as FirestoreService from "../../services/firestore";
import { StatusTagText } from "../Intake/IntakeStatusTag";
import { useUserClinics } from "../UserClinicsProvider";
import { RecurrenceByDayPicker } from "./RecurrenceByDayPicker";

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  refreshCallback: () => void;
  hideModal: () => void;
  isVisible: boolean;
  users: IUser[];
  client?: IClient;
  start?: Moment;
  end?: Moment;
  date?: Moment;
  defaultByDay?: string;
  selectedUsers?: IUser[];
  onSaveAppointment?: (appt: ICalendarCreateAppointmentEndpointRequest) => void;
}

interface ICreateAppointmentFormValues {
  clientId: string;
  apptName: string;
  date: Moment;
  start: Moment;
  end: Moment;
  location: AppointmentLocation;
  hasMeets: boolean;
  attendeeEmails: string[];
  description: string;
}

export const CreateAppointmentModal = ({
  refreshCallback,
  hideModal,
  isVisible,
  users,
  client,
  start: apptStart,
  end: apptEnd,
  date: apptDate,
  defaultByDay,
  onSaveAppointment,
  selectedUsers,
}: Props) => {
  const { user, clinic } = useUserClinics();

  const [byDay, setByDay] = useState<string>("");

  const [form] = Form.useForm<ICreateAppointmentFormValues>();
  const clientId = Form.useWatch("clientId", form);
  const apptName = Form.useWatch("apptName", form);
  const start = Form.useWatch("start", form);
  const end = Form.useWatch("end", form);
  const location = Form.useWatch("location", form);
  const hasMeets = Form.useWatch("hasMeets", form);

  const [selectedClientGuardianDetails, setSelectedClientGuardianDetails] =
    useState<IClientGuardianDetails | null>(null);
  const [availableAppointments, setAvailableAppointments] = useState<IAppointmentType>(
    {} as IAppointmentType
  );
  const [clientGuardianDetails, setClientGuardianDetails] = useState<
    Record<string, IClientGuardianDetails>
  >({});
  const [isLoadingAvailableAppointments, setIsLoadingAvailableAppointments] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setByDay(defaultByDay || "");
  }, [isVisible]);

  useEffect(() => {
    fetchData()
      .then(() => {
        if (userHasPermission(user, UserPermission.BCBA)) {
          form.setFieldValue("attendeeEmails", [user.email]);
        }
      })
      .catch(() => {});
  }, [user]);

  useEffect(() => {
    const cgd = clientGuardianDetails[clientId];
    if (cgd) {
      selectClient(cgd);
    }
  }, [clientId]);

  useEffect(() => {
    form.setFieldValue("clientId", client?.id);
    form.setFieldValue("start", apptStart);
    form.setFieldValue("end", apptEnd);
    form.setFieldValue("date", apptDate);
    selectedUsers &&
      form.setFieldValue(
        "attendeeEmails",
        selectedUsers.map((u) => u.email)
      );

    const cgd = clientGuardianDetails[client?.id || ""];
    if (cgd) {
      selectClient(cgd);
    }
  }, [client, apptStart, apptEnd, apptDate]);

  useEffect(() => {
    if (location === AppointmentLocation.TELEHEALTH) {
      form.setFieldValue("hasMeets", true);
    }
  }, [location]);

  const fetchData = async () => {
    const clientsGuardiansDetails = await FirestoreService.getClientsGuardiansDetailsByClinicId(
      user.clinicId
    );
    const filtered = clientsGuardiansDetails.filter(
      (cgd) =>
        !Object.values([IntakeStatus.CHURNED, IntakeStatus.UNQUALIFIED]).includes(
          cgd.clientFile.intakeStatus
        )
    );
    const clientGuardianDetailsMap = _.keyBy(filtered, (cgd) => cgd.client.id);
    setClientGuardianDetails(clientGuardianDetailsMap);
  };

  const selectClient = (clientGuardianDetails: IClientGuardianDetails) => {
    setIsLoadingAvailableAppointments(true);

    try {
      const availableAppointments = getAppointmentsByPayer(
        clinic.address.state,
        clientGuardianDetails.clientFile.payers.primary?.payerId || ""
      );
      setAvailableAppointments(availableAppointments);
    } catch (err) {
      console.error(
        `Could not find payer with ID ${clientGuardianDetails.clientFile.payers.primary?.payerId}`
      );
      form.setFieldValue("apptName", undefined);
      setAvailableAppointments({} as IAppointmentType);
    }

    setSelectedClientGuardianDetails(clientGuardianDetails);
    setIsLoadingAvailableAppointments(false);
  };

  const getDisabledEndTimeHours = () => {
    let hours: number[] = [];
    if (start.hour() >= 12) {
      hours = [...AM_HOURS];
    }
    for (let i = hours.length; i < start.hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledEndTimeMinutes = (selectedHour: number) => {
    const minutes: number[] = [];
    if (selectedHour === start.hour()) {
      for (let i = 0; i <= start.minute(); i += 15) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const handleSetByDay = (byDay: string) => {
    setByDay(byDay);
  };

  const saveRecurringAppointment = async () => {
    const values = form.getFieldsValue();

    if (!values.start || !values.end) {
      void message.error("Please enter start and end times");
      return;
    }

    const date = {
      year: values.date.year(),
      month: values.date.month(),
      date: values.date.date(),
    };

    const startDatetime = moment(values.start).set(date);
    const endDatetime = moment(values.end).set(date);

    // End time rolled over to the next day in UTC
    if (endDatetime.isBefore(startDatetime)) {
      endDatetime.add(1, "days");
    }
    const startMs = startDatetime.valueOf();
    const endMs = endDatetime.valueOf();

    if (startMs >= endMs) {
      void message.error("Appointment start time must be before end time");
      return;
    }

    const { client } = clientGuardianDetails[values.clientId];

    setIsSaving(true);

    const createAppointmentRequest: ICalendarCreateAppointmentEndpointRequest = {
      clinicId: user.clinicId,
      clientId: values.clientId,
      attendeeEmails: values.attendeeEmails.map((email) => email.trim()).filter((email) => email),
      billingCode: availableAppointments[values.apptName].billingCode,
      modifiers: availableAppointments[values.apptName].modifiers,
      location: values.location,
      noteId: null,
      summary: getRecurringApptSummary(
        clinic,
        client,
        availableAppointments[values.apptName].billingCode,
        values.location
      ),
      description: values.description,
      startMs,
      endMs,
      timeZone: moment.tz.guess(),
      hasMeets: values.hasMeets,
      rrule: `RRULE:FREQ=WEEKLY;BYDAY=${byDay};`,
    };

    try {
      if (onSaveAppointment) {
        onSaveAppointment(createAppointmentRequest);
      } else {
        await FirestoreService.createAppointment(createAppointmentRequest);
        void message.success("Appointment created");
      }
      form.resetFields();
      refreshCallback();
      hideModal();
    } catch (err) {
      void message.error(ERROR_MESSAGE_APPOINTMENTS);
    }
    setIsSaving(false);
  };

  const saveAppointment = async () => {
    setIsSaving(true);

    try {
      const values = form.getFieldsValue();

      if (byDay !== "") return saveRecurringAppointment();

      if (!values.start || !values.end) {
        void message.error("Please enter start and end times");
        return;
      }

      const date = {
        year: values.date.year(),
        month: values.date.month(),
        date: values.date.date(),
      };

      const startMs = values.start.set(date).valueOf();
      const endMs = values.end.set(date).valueOf();

      if (startMs >= endMs) {
        void message.error("Appointment start time must be before end time");
        return;
      }

      const createAppointmentRequest: ICalendarCreateAppointmentEndpointRequest = {
        clinicId: user.clinicId,
        clientId: values.clientId,
        attendeeEmails: values.attendeeEmails.map((email) => email.trim()).filter((email) => email),
        billingCode: availableAppointments[values.apptName].billingCode,
        modifiers: availableAppointments[values.apptName].modifiers,
        location: values.location,
        summary: getAppointmentSummary(),
        description: values.description,
        timeZone: momentTz.tz.guess(),
        startMs,
        endMs,
        hasMeets: values.hasMeets,
        noteId: null,
      };

      if (onSaveAppointment) {
        onSaveAppointment(createAppointmentRequest);
      } else {
        await FirestoreService.createAppointment(createAppointmentRequest);
        void message.success("Appointment created");
      }

      form.resetFields();
      refreshCallback();
      hideModal();
    } catch (err) {
      void message.error(ERROR_MESSAGE_APPOINTMENTS);
      console.error(err);
    }
    setIsSaving(false);
  };

  const hideModalIfNotSaving = () => {
    if (!isSaving) {
      hideModal();
    }
  };

  const getAppointmentSummary = () => {
    return `${
      selectedClientGuardianDetails ? `${selectedClientGuardianDetails.client.alias} ` : ""
    }${!_.isEmpty(apptName) ? apptName : "appointment"} ${
      location === AppointmentLocation.TELEHEALTH
        ? "(remote)"
        : location === AppointmentLocation.OFFICE
        ? "(office)"
        : "(in-person)"
    }`;
  };

  return (
    <Modal
      title={`New ${getAppointmentSummary()}`}
      closable={!isSaving}
      footer={null}
      destroyOnClose={true}
      onCancel={hideModalIfNotSaving}
      open={isVisible}
      width={500}
      bodyStyle={{
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 50,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        labelWrap={false}
        labelCol={{ span: 24 }}
        onFinish={saveAppointment}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="clientId"
              rules={[{ required: true, message: "Please select a client" }]}
            >
              <Select
                placeholder="Client"
                showSearch
                allowClear
                optionFilterProp="key"
                defaultValue={client?.id}
              >
                {Object.values(clientGuardianDetails)
                  .sort((a, b) =>
                    (Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                      Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus)) ||
                    (!Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                      !Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus))
                      ? a.client.firstName.localeCompare(b.client.firstName)
                      : Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                        !Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus)
                      ? -1
                      : 1
                  )
                  .map((cwcf) => (
                    <Select.Option
                      key={`${StatusTagText[cwcf.clientFile.intakeStatus]} ${cwcf.client.alias}${
                        cwcf.client.firstName
                      }${cwcf.client.lastName}`}
                      value={cwcf.client.id}
                    >
                      <Row>
                        <Text type="secondary" style={{ marginRight: 5 }}>
                          {cwcf.client.alias}
                        </Text>
                        <Text strong>{`${cwcf.client.firstName} ${cwcf.client.lastName}`}</Text>
                      </Row>
                      <Row style={{ marginTop: -7 }}>
                        <Text type="secondary" style={{ fontSize: 9 }}>
                          {StatusTagText[cwcf.clientFile.intakeStatus]}
                        </Text>
                      </Row>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="attendeeEmails"
              rules={[{ required: true, message: "Please add guests" }]}
            >
              <Select placeholder="Guests" mode="tags" optionFilterProp="key" allowClear>
                {users
                  .sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map((user) => (
                    <Select.Option
                      key={`${
                        user.permissions.includes(UserPermission.BCBA)
                          ? UserPermission.BCBA
                          : UserPermission.RBT
                      } ${user.firstName}${user.lastName}${user.email}`}
                      value={user.email}
                    >
                      <Row>
                        <Text type="secondary" style={{ marginRight: 5 }}>
                          {user.permissions.includes(UserPermission.BCBA)
                            ? UserPermission.BCBA
                            : UserPermission.RBT}
                        </Text>
                        <Text strong>{`${user.firstName} ${user.lastName}`}</Text>
                      </Row>
                      <Row style={{ marginTop: -7 }}>
                        <Text type="secondary" style={{ fontSize: 10 }}>{`${user.email}`}</Text>
                      </Row>
                    </Select.Option>
                  ))}
                {Object.values(clientGuardianDetails)
                  .sort((a, b) => a.guardian.firstName.localeCompare(b.guardian.firstName))
                  .map((cgd) => (
                    <Select.Option
                      key={`Guardian ${cgd.client.alias || "No client"}${cgd.guardian.firstName}${
                        cgd.guardian.lastName
                      }${cgd.guardian.email}`}
                      value={cgd.guardian.email}
                    >
                      <Row>
                        <Text type="secondary" style={{ marginRight: 5 }}>
                          {`Guardian - ${cgd.client.alias || "No client"}`}
                        </Text>
                        <Text strong>{`${cgd.guardian.firstName} ${cgd.guardian.lastName}`}</Text>
                      </Row>
                      <Row style={{ marginTop: -7 }}>
                        <Text
                          type="secondary"
                          style={{ fontSize: 10 }}
                        >{`${cgd.guardian.email}`}</Text>
                      </Row>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="apptName"
              rules={[{ required: true, message: "Please select a service" }]}
            >
              <Select
                loading={isLoadingAvailableAppointments}
                disabled={isLoadingAvailableAppointments || _.isEmpty(availableAppointments)}
                placeholder={
                  selectedClientGuardianDetails && _.isEmpty(availableAppointments)
                    ? "No available services"
                    : "Service"
                }
                showSearch
                allowClear
                optionFilterProp="key"
              >
                {Object.keys(availableAppointments).map((apptName) => (
                  <Select.Option
                    key={`${availableAppointments[apptName].billingCode}${Object.values(
                      availableAppointments[apptName].modifiers
                    ).join("")} ${apptName}`}
                    value={apptName}
                  >
                    <Text type="secondary" style={{ marginRight: 5 }}>
                      {availableAppointments[apptName as AppointmentType]?.billingCode}
                      {availableAppointments[apptName as AppointmentType]?.modifiers.join(" ")}
                    </Text>
                    <Text strong>{apptName}</Text>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="location"
              rules={[{ required: true, message: "Please select a location" }]}
            >
              <Select placeholder="Location" showSearch allowClear optionFilterProp="key">
                {Object.values(AppointmentLocation).map((location) => (
                  <Select.Option key={`${getAppointmentLocationText(location)}`} value={location}>
                    {getAppointmentLocationText(location)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row>
              <Form.Item name="hasMeets">
                <Switch
                  checked={hasMeets}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
              <Text style={{ marginTop: 6, marginLeft: 8 }}>Google Meet</Text>
            </Row>
          </Col>
        </Row>
        <Row gutter={5}>
          <Col span={8}>
            <Form.Item
              name="date"
              initialValue={apptStart}
              rules={[{ required: true, message: "Please enter a session date" }]}
            >
              <DatePicker
                placeholder="Date"
                style={{ padding: 0 }}
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="start"
              initialValue={apptStart}
              rules={[{ required: true, message: "Please enter a start time" }]}
            >
              <TimePicker
                placeholder="Start"
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_TIME_FORMAT}
                minuteStep={15}
                onSelect={(time) => {
                  form.setFieldValue("start", time);
                  if (_.isEmpty(end) || end.isBefore(time)) {
                    form.setFieldValue("end", time.clone().add(30, "minutes"));
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="end"
              initialValue={apptEnd}
              rules={[{ required: true, message: "Please enter an end time" }]}
            >
              <TimePicker
                disabled={_.isEmpty(start)}
                placeholder="End"
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_TIME_FORMAT}
                minuteStep={15}
                disabledTime={() => ({
                  disabledHours: getDisabledEndTimeHours,
                  disabledMinutes: getDisabledEndTimeMinutes,
                })}
                onSelect={(time) => {
                  form.setFieldValue("end", time);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "100%", marginTop: -15 }}>
          <Form.Item style={{ width: "100%" }}>
            <div style={{ marginBottom: 10 }}>
              <Text type="secondary">Repeat every</Text>
            </div>
            <RecurrenceByDayPicker byDay={byDay} setByDay={handleSetByDay} />
          </Form.Item>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="description">
              <TextArea placeholder="Description" allowClear autoSize={{ minRows: 2 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={isSaving}
              style={{ float: "right" }}
            >
              Book
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
