import { message } from "antd";
import _ from "lodash";

import { ERROR_MESSAGE } from "../consts";

/** Columns for a csv export */
export interface ICsvTranslatorField {
  /** Title of the column in the CSV */
  title: string;
  /** The index on the data (i.e. the billedAppointment) of the data to add to the csv. If nested: use a dot like client.id */
  dataIndex: string;
  /** Optional function to render the data. If ommitted, will display the raw data */
  render?: (value: any) => string;
}

/**
 * Exports a CSV
 * @param fileName Name of the file to export
 * @param data Array of data to populate the csv
 * @param dataTranslatorArray Array making up the columns of the csv. See ICsvTranslatorField for more details.
 */
export const exportCsv = (
  fileName: string,
  data: any[],
  dataTranslatorArray: ICsvTranslatorField[]
) => {
  try {
    const csvContent = generateCsvContent(data, dataTranslatorArray);
    exportFile(`${fileName}.csv`, csvContent, "csv");
  } catch (err) {
    console.error(err);
    void message.error(ERROR_MESSAGE).then(() => {});
  }
};

export const exportFile = (fileName: string, contents: any, fileType: string) => {
  const blob = new Blob([contents], { type: fileType });
  const blobURL = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobURL;
  link.setAttribute("target", "_blank");
  link.download = fileName;
  link.click();
};

/**
 * Generates a string formatted for csvs. Columns are seperated by ',' and rows are seperated by '\n'.
 * @param data Array of objects to populate the csv
 * @param dataTranslatorArray Array making up the columns of the csv. See ICsvTranslatorField for more details.
 */
export const generateCsvContent = (data: any[], dataTranslatorArray: ICsvTranslatorField[]) => {
  const lineSep = "\n",
    columnSep = ",";
  let csvContent = data
    .map((row) => {
      // For each row, return the row as a string with columns seperated by columnSep
      return dataTranslatorArray
        .map(({ dataIndex, render }) => {
          let value;
          if (dataIndex && !_.isEmpty(dataIndex)) {
            value = _.get(row, dataIndex);
          } else {
            value = row;
          }

          if (render && value !== undefined) {
            try {
              value = render(value);
            } catch (err) {
              void message.error(ERROR_MESSAGE);
              console.error(err);
              return "#ERROR";
            }
            // since a csv is separated by commas, values that contain a comma must be wrapped in a double-quote
            // https://stackoverflow.com/questions/4617935/is-there-a-way-to-include-commas-in-csv-columns-without-breaking-the-formatting
            if (value.indexOf(",") !== -1) {
              value = `"${value}"`;
            }
          }
          return value || "";
        })
        .join(columnSep);
    })
    .join(lineSep);
  // Add the header
  csvContent = dataTranslatorArray.map(({ title }) => title).join(columnSep) + lineSep + csvContent;
  return csvContent;
};
