import "../css/schedule-playground.css";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ActiveStatus,
  AttendeeStatus,
  EventType,
  getCurrentActiveAuth,
  IAddressDistanceEndpointResponse,
  IAppointment,
  ICalendarCreateAppointmentEndpointRequest,
  ICalendarDeleteAppointmentEndpointRequest,
  ICalendarUpdateAppointmentEndpointRequest,
  IClientGuardianDetails,
  IClinic,
  isPayerAuthRequired,
  IUser,
  stringToColor,
  TransportMode,
  UserPermission,
  Weekday,
} from "@finni-health/shared";
import {
  Badge,
  Button,
  Col,
  Divider,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import moment, { Moment } from "moment";
import { useContext, useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { IoBicycleSharp, IoCarOutline } from "react-icons/io5";
import { MdDirectionsWalk, MdOutlineDirectionsTransitFilled } from "react-icons/md";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { AppointmentsContext } from "../components/AppointmentsProvider";
import { CreateAppointmentModal } from "../components/Calendar/CreateAppointmentModal";
import { EditAppointmentPopoverContent } from "../components/Calendar/EditAppointmentPopoverContent";
import { StatusTagText } from "../components/Intake/IntakeStatusTag";
import { useUserClinics } from "../components/UserClinicsProvider";
import { ANTD_COLORS_TO_HEX, ERROR_MESSAGE } from "../consts";
import { addUntilRrule, getByDayRrule } from "../helpers/appointments";
import { formatAddress } from "../helpers/profiles";
import { formatDurationTextFromMaps, mapsDurationComparator } from "../helpers/schedules";
import * as FirestoreService from "../services/firestore";
import { getDistanceInfoBetween } from "../services/google-maps";

const { Text } = Typography;

export interface CalendarEvent {
  selectedClientFile?: IClientGuardianDetails;
  type: string;
  summary: string;
  id: string;
  startMs: number;
  endMs: number;
  apptId: string;
  appointment: IAppointment;
  isPopoverOpen?: boolean;
  delete?: boolean;
  clinic: IClinic;
  onDeleteAppointment: (appt: IAppointment) => void;
  onEditAppointment: (
    appt: IAppointment,
    request: ICalendarUpdateAppointmentEndpointRequest
  ) => void;
}

const localizer = momentLocalizer(moment);
const DnD = withDragAndDrop<CalendarEvent>(Calendar);

export type ILocalAppointment = IAppointment & {
  request:
    | ICalendarCreateAppointmentEndpointRequest
    | ICalendarUpdateAppointmentEndpointRequest
    | ICalendarDeleteAppointmentEndpointRequest;
  recurring?: boolean;
  delete?: boolean;
};

export const SchedulePlayground = () => {
  const { clinic } = useUserClinics();
  const {
    appointments: savedAppoitments,
    clinicUsers: therapists,
    clientsGuardiansDetails: clientGuardianDetails,
    refresh,
    isLoading,
    setCalWeek,
    calWeek,
  } = useContext(AppointmentsContext);
  const [locallyCreatedAppointments, setLocallyCreatedAppointments] = useState<ILocalAppointment[]>(
    []
  );
  const [selectedClientGuardianDetails, setSelectedClientGuardianDetails] =
    useState<IClientGuardianDetails>();
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [selectedApptStart, setSelectedApptStart] = useState<Moment>();
  const [selectedApptEnd, setSelectedApptEnd] = useState<Moment>();
  const [selectedApptDate, setSelectedApptDate] = useState<Moment>();
  const [clickedAppt, setClickedAppt] = useState<IAppointment>();
  const [selectedTherapists, setSelectedTherapists] = useState<IUser[]>([]);
  const [therapistDistances, setTherapistDistances] = useState<
    Record<string, IAddressDistanceEndpointResponse>
  >({});
  const [isTherapistDistanceLoading, setIsTherapistDistanceLoading] = useState<boolean>(false);

  const isAppointmentEqual = (a: IAppointment, b: IAppointment) => {
    return a.id.split("_")?.[0] === b.id.split("_")?.[0];
  };

  const appointments = [
    ...savedAppoitments.filter(
      (a) => !locallyCreatedAppointments.find((appt) => isAppointmentEqual(appt, a))
    ),
    ...(locallyCreatedAppointments.map((appt) => {
      const currentAuth = getCurrentActiveAuth(
        selectedClientGuardianDetails?.clientFile?.payers?.primary ?? null
      );
      return {
        ...appt,
        startMs:
          appt.recurring &&
          moment(appt.startMs).set("week", calWeek.week()).valueOf() >
            moment(appt.startMs).valueOf() &&
          moment(appt.startMs).set("week", calWeek.week()).valueOf() <=
            moment(currentAuth?.endDate).valueOf()
            ? moment(appt.startMs).set("week", calWeek.week())
            : appt.startMs,
        endMs: appt.recurring ? moment(appt.endMs).set("week", calWeek.week()) : appt.endMs,
      };
    }) as IAppointment[]),
  ];

  const selectedClient = selectedClientGuardianDetails?.client;
  const backgroundEvents = selectedTherapists
    .flatMap(
      (therapist) =>
        therapist &&
        therapist.hasSetSchedule &&
        Object.values(Weekday)
          .map((day) => therapist.schedule?.[day].intervals)
          .reduce<any[]>((prev, intervals, i) => {
            return [
              ...prev,
              ...intervals.map((int) => ({
                type: "schedule",
                summary: therapist.firstName + " " + therapist.lastName,
                id: therapist.id,
                startMs: moment(int.startMs.seconds * 1000)
                  .set("date", calWeek.startOf("week").add(i, "days").date())
                  .set("month", calWeek.startOf("week").add(i, "days").month())
                  .set("year", calWeek.startOf("week").add(i, "days").year())
                  .valueOf(),
                endMs: moment(int.endMs.seconds * 1000)
                  .set("date", calWeek.startOf("week").add(i, "days").date())
                  .set("month", calWeek.startOf("week").add(i, "days").month())
                  .set("year", calWeek.startOf("week").add(i, "days").year())
                  .valueOf(),
              })),
            ];
          }, [])
    )
    .filter((i) => !!i);

  useEffect(() => setCalWeek(moment()), []);

  const onCancelChanges = () => {
    setLocallyCreatedAppointments([]);
  };

  const onSaveDeleteAppointment = async (localAppointment: ILocalAppointment) => {
    const originalAppt = savedAppoitments.find((appt) => appt.id === localAppointment.id);
    if (originalAppt?.id.includes("_")) {
      const mainAppointment = await FirestoreService.getAppointmentById({
        id: localAppointment.id.split("_")[0],
      });
      // If this is the first instance, just delete it and replace
      if (mainAppointment.startMs === originalAppt?.startMs) {
        await FirestoreService.deleteAppointment({
          id: mainAppointment.id,
        });
      } else {
        const appointmentToEnd = addUntilRrule(mainAppointment, originalAppt);
        await FirestoreService.updateAppointment(appointmentToEnd);
      }
    } else {
      await FirestoreService.deleteAppointment(
        localAppointment.request as ICalendarDeleteAppointmentEndpointRequest
      );
    }
  };

  const onSaveUpdateAppointment = async (localAppointment: ILocalAppointment) => {
    let rrule: string;
    const originalAppt = savedAppoitments.find((appt) => appt.id === localAppointment.id);
    if (localAppointment.recurring) {
      // existing reccurance
      if (originalAppt?.id.includes("_")) {
        const mainAppointment = await FirestoreService.getAppointmentById({
          id: localAppointment.id.split("_")[0],
        });
        // If this is the first instance, just delete it and replace
        if (mainAppointment.startMs === originalAppt?.startMs) {
          await FirestoreService.deleteAppointment({
            id: mainAppointment.id,
          });
        } else {
          const appointmentToEnd = addUntilRrule(mainAppointment, originalAppt);
          await FirestoreService.updateAppointment(appointmentToEnd);
        }
        rrule = getByDayRrule(
          mainAppointment,
          Object.values(Weekday)[moment(localAppointment.startMs).day()]
        );
      } else {
        rrule = `RRULE:FREQ=WEEKLY;BYDAY=${Object.values(Weekday)
          [moment(localAppointment.startMs).day()].slice(0, 2)
          .toUpperCase()};`;
        await FirestoreService.deleteAppointment({
          id: localAppointment.id,
        });
      }
      const { id, attendees, ...request } =
        localAppointment.request as ICalendarUpdateAppointmentEndpointRequest;
      const createAppointmentRequest: ICalendarCreateAppointmentEndpointRequest = {
        ...(request as ICalendarCreateAppointmentEndpointRequest),
        attendeeEmails:
          (request as ICalendarCreateAppointmentEndpointRequest).attendeeEmails ||
          attendees?.map(({ email }) => email) ||
          [],
        rrule,
      };
      await FirestoreService.createAppointment(createAppointmentRequest);
    } else {
      await FirestoreService.updateAppointment(
        localAppointment.request as ICalendarUpdateAppointmentEndpointRequest
      );
    }
  };

  const onSaveLocalAppointmentChanges = async (localAppointment: ILocalAppointment) => {
    if (localAppointment.delete) {
      await onSaveDeleteAppointment(localAppointment);
    }
    // New appointment case
    else if (localAppointment.id.includes("create")) {
      await FirestoreService.createAppointment(
        localAppointment.request as ICalendarCreateAppointmentEndpointRequest
      );
    } else {
      await onSaveUpdateAppointment(localAppointment);
    }
  };

  const onSaveChanges = async () => {
    try {
      setIsSavingChanges(true);
      await Promise.all(
        locallyCreatedAppointments.map((localAppoinment) =>
          onSaveLocalAppointmentChanges(localAppoinment)
        )
      );
      setLocallyCreatedAppointments([]);
      setIsSavingChanges(false);
      refresh();
      void message.success("Changes saved");
    } catch (e) {
      setIsSavingChanges(false);
      console.error("unable to save changes from schedule playground", e);
      void message.error(ERROR_MESSAGE);
    }
  };

  const viewEditAppointmentModal = (e: CalendarEvent) => {
    setClickedAppt(appointments.find((appt) => appt.id === e.apptId));
  };

  const loadTherapistDistancesForClient = () => {
    setIsTherapistDistanceLoading(true);
    const therapistDistancesResponse = therapists?.reduce(async (prev, therapist) => {
      if (selectedClientGuardianDetails) {
        const distancesForTherapist = await getDistanceInfoBetween(
          formatAddress(selectedClientGuardianDetails.clientFile.address),
          formatAddress(therapist?.address)
        );
        return {
          ...prev,
          [therapist.id]: distancesForTherapist,
        };
      } else {
        return prev;
      }
    }, {});
    setTherapistDistances(therapistDistancesResponse);
    setIsTherapistDistanceLoading(false);
  };

  useEffect(() => {
    loadTherapistDistancesForClient();
  }, [selectedClient]);

  const renderSelectedClientInfo = () => {
    const clientFile = selectedClientGuardianDetails?.clientFile;
    const guardian = selectedClientGuardianDetails?.guardian;
    const currentAuth = getCurrentActiveAuth(
      selectedClientGuardianDetails?.clientFile?.payers?.primary ?? null
    );
    return !clientFile ? null : (
      <>
        <div style={{ marginTop: "15px" }}>
          <b>Client Address: </b>
          {formatAddress(clientFile?.address)}
        </div>
        <div>
          <b>Guardian Phone: </b>
          {guardian?.phoneNumber?.primary}
        </div>
        <div>
          <b>Guardian Email: </b>
          {guardian?.email}
        </div>

        {currentAuth?.endDate && (
          <Typography.Text>
            Note: All appointments created for {selectedClient?.alias} will be recurring until{" "}
            {moment(currentAuth.endDate).format("ddd MMM DD YYYY")}
          </Typography.Text>
        )}
      </>
    );
  };

  const renderLoadingDistancesSkeleton = () => (
    <Row justify="space-around" align="middle" style={{ padding: "5px" }}>
      <Col>
        <Row align="middle" justify="center">
          <Skeleton.Avatar active style={{ height: "20px", width: "20px" }} />
        </Row>
        <Row align="middle" justify="center">
          <Skeleton.Button
            active
            style={{
              height: "12px",
              marginTop: "4px",
            }}
            size="small"
          />
        </Row>
      </Col>
      <Col>
        <Row align="middle" justify="center">
          <Skeleton.Avatar active style={{ height: "20px", width: "20px" }} />
        </Row>
        <Row align="middle" justify="center">
          <Skeleton.Button
            active
            style={{
              height: "12px",
              marginTop: "4px",
            }}
            size="small"
          />
        </Row>
      </Col>
      <Col>
        <Row align="middle" justify="center">
          <Skeleton.Avatar active style={{ height: "20px", width: "20px" }} />
        </Row>
        <Row align="middle" justify="center">
          <Skeleton.Button
            active
            style={{
              height: "12px",
              marginTop: "4px",
            }}
            size="small"
          />
        </Row>
      </Col>
      <Col>
        <Row align="middle" justify="center">
          <Skeleton.Avatar active style={{ height: "20px", width: "20px" }} />
        </Row>
        <Row align="middle" justify="center">
          <Skeleton.Button
            active
            style={{
              height: "12px",
              marginTop: "4px",
            }}
            size="small"
          />
        </Row>
      </Col>
    </Row>
  );

  const renderSelectedTherapistInfo = () => (
    <div style={{ marginTop: "15px" }}>
      {selectedTherapists.map((therapist) => {
        const { driving, bicycling, transit, walking } = therapistDistances[therapist.id] || {};
        return (
          <>
            <div>
              <b>
                <u>{therapist.firstName}</u>
                {!isEmpty(therapistDistances[therapist.id]?.driving?.distance?.text)
                  ? ` (${therapistDistances[therapist.id]?.driving?.distance?.text} away)`
                  : ""}
              </b>
            </div>
            {isTherapistDistanceLoading
              ? renderLoadingDistancesSkeleton()
              : therapistDistances[therapist.id] && (
                  <Row justify="space-around" align="middle" style={{ padding: "5px" }}>
                    {!isEmpty(driving?.duration?.text) && (
                      <Col>
                        <Row align="middle" justify="center">
                          <IoCarOutline size="20px" />
                        </Row>
                        <Row align="middle" justify="center">
                          <>{formatDurationTextFromMaps(driving?.duration?.text)}</>
                        </Row>
                      </Col>
                    )}
                    {!isEmpty(transit?.duration?.text) && (
                      <Col>
                        <Row align="middle" justify="center">
                          <MdOutlineDirectionsTransitFilled size="20px" />
                        </Row>
                        <Row align="middle" justify="center">
                          <>{formatDurationTextFromMaps(transit?.duration?.text)}</>
                        </Row>
                      </Col>
                    )}
                    {!isEmpty(walking?.duration?.text) && (
                      <Col>
                        <Row align="middle" justify="center">
                          <MdDirectionsWalk size="20px" />
                        </Row>
                        <Row align="middle" justify="center">
                          <>{formatDurationTextFromMaps(walking?.duration?.text)}</>
                        </Row>
                      </Col>
                    )}
                    {!isEmpty(bicycling?.duration?.text) && (
                      <Col>
                        <Row align="middle" justify="center">
                          <IoBicycleSharp size="20px" />
                        </Row>
                        <Row align="middle" justify="center">
                          <>{formatDurationTextFromMaps(bicycling?.duration?.text)}</>
                        </Row>
                      </Col>
                    )}
                  </Row>
                )}
            <div>
              <b>Address: </b>
              {formatAddress(therapist?.address)}
            </div>
            <div>
              <b>Phone number: </b>
              {therapist.phoneNumber}
            </div>
            <Divider />
          </>
        );
      })}
    </div>
  );

  const handleMoveAppointment = (e: any) => {
    const appointment: IAppointment = e.event.appointment;
    const newStart = moment(e.start);
    const newEnd = moment(e.end);
    const request = {
      id: appointment.id,
      clinicId: appointment.clinicId,
      clientId: appointment.clientId,
      attendeeEmails: appointment.attendees.map(({ email }) => email) || [],
      billingCode: appointment.billingCode,
      modifiers: appointment.modifiers,
      location: appointment.location,
      summary: appointment.summary,
      description: appointment.description,
      startMs: newStart.valueOf(),
      endMs: newEnd.valueOf(),
      timeZone: moment.tz.guess(),
    };
    if (locallyCreatedAppointments.find((appt) => isAppointmentEqual(appointment, appt))) {
      setLocallyCreatedAppointments(
        locallyCreatedAppointments.map((appt) =>
          isAppointmentEqual(appointment, appt)
            ? {
                ...appt,
                startMs: newStart.valueOf(),
                endMs: newEnd.valueOf(),
                request,
              }
            : appt
        )
      );
    } else {
      setLocallyCreatedAppointments([
        ...locallyCreatedAppointments,
        {
          ...appointment,
          startMs: newStart.valueOf(),
          endMs: newEnd.valueOf(),
          request,
          recurring:
            appointment.id.includes("_") ||
            !isEmpty(
              (
                (appointment as ILocalAppointment)
                  .request as ICalendarCreateAppointmentEndpointRequest
              )?.rrule
            ),
        },
      ]);
    }
  };

  const getIconForDurationMode = (mode: TransportMode) => {
    switch (mode) {
      case TransportMode.DRIVING:
        return (
          <IoCarOutline
            style={{
              marginBottom: "-3px",
              fontSize: "10pt",
            }}
          />
        );
      case TransportMode.TRANSIT:
        return (
          <MdOutlineDirectionsTransitFilled
            style={{
              marginBottom: "-3px",
              fontSize: "10pt",
            }}
          />
        );
      case TransportMode.WALKING:
        return (
          <MdDirectionsWalk
            style={{
              marginBottom: "-3px",
              fontSize: "10pt",
            }}
          />
        );
      case TransportMode.BICYCLING:
        return (
          <IoBicycleSharp
            style={{
              marginBottom: "-3px",
              fontSize: "10pt",
            }}
          />
        );
    }
  };

  const onCreateLocalAppointment = (request: ICalendarCreateAppointmentEndpointRequest) => {
    const appt: IAppointment = {
      ...request,
      id: `create-${uuidv4()}`,
      eventType: EventType.APPOINTMENT,
      attendees: request.attendeeEmails.map((email) => ({
        email,
        status: AttendeeStatus.NEEDS_ACTION,
      })),
    };
    setLocallyCreatedAppointments([
      ...locallyCreatedAppointments,
      { ...appt, request, recurring: !isEmpty(request?.rrule) },
    ]);
  };

  const onEditLocalAppointment = (
    appt: IAppointment,
    request: ICalendarUpdateAppointmentEndpointRequest
  ) => {
    if (locallyCreatedAppointments.find((a) => isAppointmentEqual(a, appt))) {
      // update locally created appointment
      setLocallyCreatedAppointments(
        locallyCreatedAppointments.map((a) =>
          isAppointmentEqual(a, appt)
            ? {
                ...a,
                ...request,
                request: {
                  ...a.request,
                  ...request,
                  recurring: !isEmpty(request?.rrule) || a.id.includes("_"),
                },
                delete: false,
              }
            : a
        )
      );
      return;
    }
    setLocallyCreatedAppointments([
      ...locallyCreatedAppointments,
      {
        ...appt,
        ...request,
        request,
        recurring: !isEmpty(request?.rrule) || appt.id.includes("_"),
        delete: false,
      },
    ]);
  };

  const onDeleteAppointment = (appt: IAppointment) => {
    const localAppointmentIndex = locallyCreatedAppointments.findIndex(
      (local) => local.id === appt.id
    );
    if (localAppointmentIndex > 0 && appt.id.includes("create")) {
      // locally created new appt deletion
      const localAppointments = [...locallyCreatedAppointments];
      localAppointments.splice(localAppointmentIndex, 1);
      setLocallyCreatedAppointments(localAppointments);
    } else if (localAppointmentIndex > 0) {
      // some existing appointment that has been updated
      setLocallyCreatedAppointments(
        locallyCreatedAppointments.map((local) =>
          local.id === appt.id ? { ...local, delete: true } : local
        )
      );
    } else {
      // existing unupdated appointment
      setLocallyCreatedAppointments([
        ...locallyCreatedAppointments,
        {
          ...appt,
          recurring:
            appt.id.includes("_") ||
            !isEmpty(
              ((appt as ILocalAppointment).request as ICalendarCreateAppointmentEndpointRequest)
                ?.rrule
            ),
          delete: true,
          request: {
            id: appt.id,
          },
        },
      ]);
    }
  };

  return (
    <>
      <Spin spinning={isLoading || isSavingChanges} delay={500}>
        <Row>
          <Col
            span={6}
            style={{
              paddingRight: "20px",
              overflow: "scroll",
              maxHeight: "90vh",
            }}
          >
            <Row align="middle" justify="center">
              <Col span={12}>
                <Link to="../schedules">
                  <Button
                    type="ghost"
                    onClick={() => setCreateModal(true)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Back
                  </Button>
                </Link>
              </Col>
              <Col span={12}>
                <Button
                  shape="round"
                  size="large"
                  type="primary"
                  style={{
                    float: "right",
                  }}
                  onClick={() => setCreateModal(true)}
                  disabled={!selectedClient || selectedTherapists.length === 0}
                >
                  <PlusOutlined style={{ marginLeft: -3 }} />
                  Create
                </Button>
              </Col>
            </Row>
            <br />
            <Typography.Text style={{ width: "80%", fontWeight: "bold" }}>
              Schedule For...
            </Typography.Text>
            <Select
              allowClear
              showSearch
              placeholder="Client"
              optionFilterProp="key"
              style={{ width: "100%" }}
              value={selectedClient?.id}
              onChange={(clientId) => {
                if (locallyCreatedAppointments.length > 0) {
                  Modal.confirm({
                    title: "Unsaved changes!",
                    content:
                      "Switching clients will get rid of your saved changes. Are you sure you wish to discard all your changes?",
                    onOk: () => {
                      setLocallyCreatedAppointments([]);
                      setSelectedClientGuardianDetails(
                        clientGuardianDetails.find((c) => c.client.id === clientId)
                      );
                    },
                    cancelText: "No, Stay",
                    okText: "Yes",
                  });
                } else {
                  setSelectedClientGuardianDetails(
                    clientGuardianDetails.find((c) => c.client.id === clientId)
                  );
                }
              }}
            >
              {clientGuardianDetails
                .sort((a, b) => {
                  if (a.clientFile && b.clientFile) {
                    return (Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                      Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus)) ||
                      (!Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                        !Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus))
                      ? a.client.firstName.localeCompare(b.client.firstName)
                      : Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                        !Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus)
                      ? -1
                      : 1;
                  }
                  return 0;
                })
                .map((clientGuardianDetails) => (
                  <Select.Option
                    key={`${StatusTagText[clientGuardianDetails.clientFile.intakeStatus]} ${
                      clientGuardianDetails.client.alias
                    }${clientGuardianDetails.client.firstName}${
                      clientGuardianDetails.client.lastName
                    }${clientGuardianDetails.client.id}`}
                    value={clientGuardianDetails.client.id}
                  >
                    <Row>
                      <Badge
                        color={stringToColor(clientGuardianDetails.client.id || "")}
                        style={{ marginRight: 5 }}
                      />
                      <Text type="secondary" style={{ marginRight: 5 }}>
                        {clientGuardianDetails.client.alias}
                      </Text>
                      <Text
                        strong
                      >{`${clientGuardianDetails.client.firstName} ${clientGuardianDetails.client.lastName}`}</Text>
                    </Row>
                    <Row style={{ marginTop: -7 }}>
                      <Text type="secondary" style={{ fontSize: 9 }}>
                        {StatusTagText[clientGuardianDetails.clientFile.intakeStatus]}
                      </Text>
                    </Row>
                  </Select.Option>
                ))}
            </Select>
            {selectedClient && renderSelectedClientInfo()}
            <Divider style={{ marginTop: "15px", marginBottom: "10px" }} />
            <Typography.Text style={{ width: "80%", fontWeight: "bold" }}>
              View Availability For...
            </Typography.Text>
            <Select
              allowClear
              showSearch
              placeholder="Therapists"
              mode="multiple"
              value={selectedTherapists.map((therapist) => therapist.id)}
              onChange={(values) =>
                setSelectedTherapists(
                  values.map((value) => therapists.find((therapist) => therapist.id === value)!)
                )
              }
              style={{ width: "100%" }}
              optionFilterProp="key"
            >
              {therapists
                .sort((a, b) => mapsDurationComparator(a, b, therapistDistances))
                .map((t) => (
                  <Select.Option
                    key={`${
                      t.permissions.includes(UserPermission.BCBA)
                        ? UserPermission.BCBA
                        : UserPermission.RBT
                    } ${t.firstName}${t.lastName}${t.email}`}
                    value={t.id}
                  >
                    <Badge
                      color={stringToColor(t.id || "")}
                      text={
                        <>
                          <Text type="secondary" style={{ marginRight: 5 }}>
                            {t.permissions.includes(UserPermission.BCBA)
                              ? UserPermission.BCBA
                              : UserPermission.RBT}
                          </Text>
                          <Typography.Text strong>{`${t.firstName} ${t.lastName}`}</Typography.Text>{" "}
                          <Typography.Text type="secondary" style={{ fontSize: "8pt" }}>
                            {isTherapistDistanceLoading ? (
                              <LoadingOutlined spin />
                            ) : !isEmpty(
                                therapistDistances[t.id]?.[t.preferredTransport]?.duration?.text
                              ) ? (
                              <>
                                {getIconForDurationMode(t.preferredTransport)}
                                {` ${
                                  therapistDistances[t.id]?.[
                                    t.preferredTransport || TransportMode.DRIVING
                                  ]?.duration?.text
                                }`}
                              </>
                            ) : (
                              ""
                            )}
                          </Typography.Text>
                        </>
                      }
                    />
                  </Select.Option>
                ))}
            </Select>
            {selectedClient && renderSelectedTherapistInfo()}
          </Col>
          <Col span={18}>
            <DnD
              components={{
                eventWrapper: ({ children, event }: any) => {
                  // const [isOpen, setIsOpen]
                  return (
                    <Popover
                      showArrow={false}
                      destroyTooltipOnHide={true}
                      style={{ width: 700 }}
                      // getTooltipContainer={(trigger) => trigger.parentElement!}
                      content={
                        <>
                          <EditAppointmentPopoverContent
                            key={event?.appointment?.id}
                            appointment={event.appointment}
                            users={[...(event.therapists || [])]}
                            clientGuardianDetails={[...(event.clientGuardianDetails || [])]}
                            hideModal={() => {
                              message.destroy();
                              setClickedAppt(undefined);
                            }}
                            recurring={
                              event.appointment?.id.includes("_") ||
                              !isEmpty(
                                (
                                  event.appointment
                                    ?.request as ICalendarCreateAppointmentEndpointRequest
                                )?.rrule
                              )
                            }
                            onSaveAppointment={event.onEditAppointment}
                            onDeleteAppointment={event.onDeleteAppointment}
                          />
                        </>
                      }
                      open={event.isPopoverOpen}
                      placement="left"
                    >
                      {children}
                    </Popover>
                  );
                },
                event: ({ event }) => {
                  const isAppointmentAuthorized = () => {
                    const requiresAuth = isPayerAuthRequired(
                      clinic.address.state,
                      event.selectedClientFile?.clientFile.payers.primary?.payerId || "",
                      event.appointment.billingCode,
                      event.appointment.modifiers
                    );

                    if (!requiresAuth) {
                      return true;
                    }

                    const currentAuth = getCurrentActiveAuth(
                      event.selectedClientFile?.clientFile.payers?.primary ?? null
                    );

                    if (currentAuth === null) {
                      return false;
                    }

                    return event.appointment.startMs <= moment(currentAuth.endDate).valueOf();
                  };

                  return (
                    <div>
                      <div
                        style={{
                          marginBottom:
                            event.appointment?.id.includes("_") ||
                            !isEmpty(event.appointment?.rrule)
                              ? 5
                              : 0,
                          fontSize: "8pt",
                        }}
                      >
                        {(event.appointment?.id.includes("_") ||
                          !isEmpty(event.appointment?.rrule)) &&
                          `Every ${moment(event.appointment.startMs).format("ddd").toUpperCase()} ${
                            !isAppointmentAuthorized ? "(Auth expired)" : ""
                          }`}
                      </div>
                      <div style={{ width: "100%" }}>{event.summary}</div>
                    </div>
                  );
                },
              }}
              eventPropGetter={(e: CalendarEvent) => {
                const color = ANTD_COLORS_TO_HEX[stringToColor(e.id || "")] || "";
                return {
                  style:
                    e.type === "schedule"
                      ? {
                          backgroundColor: color + "80",
                          borderRadius: 0,
                          border: "dashed 2px " + color,
                          color: "black",
                        }
                      : {
                          background: color + (e.type === "client" ? "" : "80"),
                          border: "solid black 2px",
                          color: e.type === "client" ? "white" : "black",
                        },
                };
              }}
              onNavigate={(e) => {
                setCalWeek(moment(e));
              }}
              onEventDrop={(e) => handleMoveAppointment(e)}
              localizer={localizer}
              backgroundEvents={backgroundEvents}
              events={appointments
                .filter(
                  (appt) =>
                    (selectedTherapists.some((therapist) =>
                      appt.attendees.some(({ email }) => therapist.email === email)
                    ) ||
                      appt.clientId === selectedClient?.id) &&
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                    !(appt as ILocalAppointment).delete
                )
                .map((appt) => {
                  return {
                    ...appt,
                    appointment: appt,
                    apptId: appt.id,
                    id:
                      appt.clientId === selectedClient?.id
                        ? selectedClient.id
                        : therapists.find(
                            (t) =>
                              t.email ===
                              (
                                appt.attendees.find((attendee) =>
                                  selectedTherapists
                                    .find((t) => t.email === attendee.email)
                                    ?.permissions.includes(UserPermission.BCBA)
                                ) ||
                                appt.attendees.find((attendee) =>
                                  selectedTherapists
                                    .find((t) => t.email === attendee.email)
                                    ?.permissions.includes(UserPermission.RBT)
                                )
                              )?.email
                          )?.id || "",
                    type: appt.clientId === selectedClient?.id ? "client" : "therapist",
                    startMs: moment(appt.startMs).valueOf(),
                    endMs: moment(appt.endMs).valueOf(),
                    // The state variables need to be passed through the event array to be accessed by the eventWrapper compnent.
                    // Since the state is handled by react big calendar, the local state update from this page will not
                    // reflect in the popup directly.
                    isPopoverOpen: appt.id === clickedAppt?.id,
                    clientGuardianDetails: clientGuardianDetails,
                    selectedClientFile: clientGuardianDetails.find(
                      (cf) => cf.client.id === appt.clientId
                    ),
                    therapists,
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                    delete: (appt as ILocalAppointment).delete,
                    clinic,
                    onEditAppointment: onEditLocalAppointment,
                    onDeleteAppointment,
                  };
                })}
              startAccessor={(e: CalendarEvent) => moment(e.startMs).toDate()}
              endAccessor={(e: CalendarEvent) => moment(e.endMs).toDate()}
              draggableAccessor={(e: CalendarEvent) => !e.delete}
              titleAccessor={(e: CalendarEvent) =>
                `${e.summary} ${
                  e.appointment?.id.includes("_") || !isEmpty(e.appointment?.rrule)
                    ? "(Recurring)"
                    : ""
                }`
              }
              style={{ width: "100%", height: "90vh" }}
              defaultView={Views.WEEK}
              resizable
              onSelectEvent={(e) => viewEditAppointmentModal(e)}
              onSelectSlot={({ start, end }) => {
                if (clickedAppt) {
                  setClickedAppt(undefined);
                } else {
                  if (!selectedClient || selectedTherapists.length === 0) {
                    void message.info(
                      "Please select a client and therapist to schedule appointments"
                    );
                  }
                  setSelectedApptStart(moment(start));
                  setSelectedApptEnd(moment(end));
                  setSelectedApptDate(moment(start));
                  setCreateModal(true);
                }
              }}
              selectable
              views={["week", "day"]}
              formats={{
                dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
                  localizer?.format(start, "MMM D", culture || "") +
                  " - " +
                  localizer?.format(end, "MMM D", culture || "") +
                  " " +
                  localizer?.format(end, "YYYY", culture || ""),
                dayFormat: (date) => date.toLocaleString("en-us", { weekday: "long" }),
              }}
              messages={{
                previous: "Previous",
              }}
            />
            <div style={{ position: "absolute", top: 0, right: 150 }}>
              <Space>
                <Button
                  type="primary"
                  onClick={onSaveChanges}
                  disabled={locallyCreatedAppointments.length === 0}
                  loading={isSavingChanges}
                >
                  Save Changes
                </Button>
                <Button
                  type="ghost"
                  onClick={onCancelChanges}
                  disabled={locallyCreatedAppointments.length === 0 || isSavingChanges}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
        <CreateAppointmentModal
          refreshCallback={() => {}}
          hideModal={() => {
            setCreateModal(false);
            setSelectedApptDate(undefined);
            setSelectedApptEnd(undefined);
            setSelectedApptStart(undefined);
          }}
          isVisible={createModal}
          users={[...therapists]}
          selectedUsers={[...selectedTherapists]}
          client={selectedClient}
          start={selectedApptStart}
          end={selectedApptEnd}
          date={selectedApptDate}
          onSaveAppointment={onCreateLocalAppointment}
          defaultByDay={selectedApptDate?.format("dddd").slice(0, 2).toUpperCase()}
        />
      </Spin>
    </>
  );
};
