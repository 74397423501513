import { CopyOutlined } from "@ant-design/icons";
import { Button, Input, Row, Tooltip } from "antd";
import { useState } from "react";

interface Props {
  link: string;
  title: string;
  message: string;
}

export const CopyLinkToClipboard = ({ link, title, message }: Props) => {
  const [scheduleButtonText, setScheduleButtonText] = useState<string>("Copy to clipboard");

  const onClick = async () => {
    await navigator.clipboard.writeText(link);
    setScheduleButtonText("Copied!");
    setTimeout(() => {
      setScheduleButtonText("Copy to clipboard");
    }, 2000);
  };

  return (
    <Row
      style={{
        marginBottom: 20,
        position: "relative",
      }}
    >
      <Tooltip
        title={message}
        overlayInnerStyle={{
          textAlign: "center",
        }}
      >
        <Input
          value={link}
          style={{ cursor: "default", maxWidth: 650, marginRight: -10 }}
          readOnly
        />
      </Tooltip>
      <Tooltip title={scheduleButtonText}>
        <Button type="primary" icon={<CopyOutlined />} onClick={onClick} />
      </Tooltip>
      <div
        style={{
          float: "left",
          marginLeft: 20,
          marginTop: -7,
          position: "absolute",
          background: "white",
          fontSize: 10,
          padding: "0 4",
        }}
      >
        {title}
      </div>
    </Row>
  );
};
