import {
  CountryCode,
  IConvertInquiryEndpointRequest,
  IInquiry,
  IntakeStatus,
  USStateCode,
} from "@finni-health/shared";
import {
  Alert,
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";

import {
  ADDRESS_PLACEHOLDER,
  CITY_PLACEHOLDER,
  EMAIL_PLACEHOLDER,
  ERROR_MESSAGE,
  FIRST_NAME_PLACEHOLDER,
  LAST_NAME_PLACEHOLDER,
  PHONE_NUMBER_PLACEHOLDER,
  VALID_EMAIL_REGEX,
  VALID_PHONE_NUMBER_REGEX,
  VALID_ZIP_CODE_REGEX,
  ZIP_CODE_PLACEHOLDER,
} from "../../consts";
import * as FirestoreService from "../../services/firestore";
import { PhoneNumberInput } from "../PhoneNumberInput";
import { useUserClinics } from "../UserClinicsProvider";

const { TextArea } = Input;

interface IConvertInquiryFormValues {
  firstName: string;
  lastName: string;
  phoneNumberPrimary: string;
  phoneNumberHome: string;
  phoneNumberWork: string;
  phoneNumberCell: string;
  email: string;
  line1: string;
  line2: string;
  city: string;
  zipCode: string;
  state: USStateCode;
  status: IntakeStatus;
  statusNotes?: string;
}

interface Props {
  refreshCallback: () => Promise<void>;
  hideModal: () => void;
  isVisible: boolean;
  inquiry: IInquiry;
}

export const ConvertInquiryModal = ({ refreshCallback, hideModal, isVisible, inquiry }: Props) => {
  const { user } = useUserClinics();

  const [form] = Form.useForm<IConvertInquiryFormValues>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!_.isEmpty(inquiry)) {
      setFormDefaults();
    }
  }, [inquiry]);

  const setFormDefaults = () => {
    form.setFieldsValue({
      firstName: inquiry.firstName,
      lastName: inquiry.lastName,
      email: inquiry.email,
      statusNotes: inquiry.intakeStatusNotes,
      line1: inquiry.address.line1,
      line2: inquiry.address.line2,
      city: inquiry.address.city,
      state: inquiry.address.state,
      zipCode: inquiry.address.zipCode,
      phoneNumberPrimary: inquiry.phoneNumber.primary,
      phoneNumberCell: inquiry.phoneNumber.cell,
      phoneNumberHome: inquiry.phoneNumber.home,
      phoneNumberWork: inquiry.phoneNumber.work,
    });
  };

  const convertGuardian = async (values: IConvertInquiryFormValues) => {
    setIsSaving(true);

    const isEmailAvailable = await FirestoreService.isGuardianEmailAvailable(values.email);

    if (!isEmailAvailable) {
      void message.error(
        "Email is already in use! Try adding a unique identifier with a + sign (i.e. email+<client name>@email.com)"
      );
      setIsSaving(false);
      return;
    }

    const convertInquiryRequest: IConvertInquiryEndpointRequest = {
      inquiryId: inquiry.id,
      clinicId: user.clinicId,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: {
        primary: values.phoneNumberPrimary,
        home: values.phoneNumberHome,
        work: values.phoneNumberWork,
        cell: values.phoneNumberCell,
      },
      email: values.email,
      address: {
        line1: values.line1,
        ...(values.line2 && { line2: values.line2 }),
        city: values.city,
        state: values.state,
        country: CountryCode.US,
        zipCode: values.zipCode,
      },
      intakeStatus: IntakeStatus.IN_PROCESS,
      intakeStatusNotes: values.statusNotes || "",
    };

    try {
      await FirestoreService.convertToPrequalifiedGuardian(convertInquiryRequest);
      await FirestoreService.deleteInquiry(inquiry.id);

      await refreshCallback();
      hideModal();
    } catch (err) {
      void message.error(ERROR_MESSAGE);
    }

    setIsSaving(false);
  };

  return (
    <Modal
      title="Convert Inquiry to Pre-Qualified Guardian"
      onCancel={hideModal}
      open={isVisible}
      footer={false}
      width={800}
      style={{ top: 20 }}
    >
      <Form form={form} layout="vertical" labelCol={{ span: 24 }} onFinish={convertGuardian}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Status Notes" name="statusNotes">
              <TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Guardian First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please provide a First Name",
                },
              ]}
            >
              <Input tabIndex={1} placeholder={FIRST_NAME_PLACEHOLDER} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Guardian Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please provide a Last Name",
                },
              ]}
            >
              <Input tabIndex={2} placeholder={LAST_NAME_PLACEHOLDER} />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Guardian Address</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Address Line 1" name="line1">
              <Input tabIndex={6} placeholder={ADDRESS_PLACEHOLDER} autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Address Line 2" name="line2">
              <Input tabIndex={7} placeholder={ADDRESS_PLACEHOLDER} autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="City" name="city">
              <Input tabIndex={8} placeholder={CITY_PLACEHOLDER} autoComplete="off" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="State" name="state">
              <Select showSearch disabled={isSaving}>
                {Object.values(USStateCode).map((state) => (
                  <Select.Option value={state} key={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Country" name="country" initialValue={CountryCode.US}>
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Zip Code"
              name="zipCode"
              rules={[
                {
                  pattern: VALID_ZIP_CODE_REGEX,
                  message: "Please enter a valid zip code",
                },
              ]}
            >
              <Input tabIndex={9} maxLength={10} placeholder={ZIP_CODE_PLACEHOLDER} />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Contact Information</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  pattern: VALID_EMAIL_REGEX,
                  message: "Please provide a valid Email",
                },
              ]}
            >
              <Input tabIndex={13} type="email" placeholder={EMAIL_PLACEHOLDER} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Primary Phone Numbers"
              name="phoneNumberPrimary"
              rules={[
                {
                  required: true,
                  pattern: VALID_PHONE_NUMBER_REGEX,
                  message: "Please provide a valid Phone Number",
                },
              ]}
            >
              <PhoneNumberInput placeholder={PHONE_NUMBER_PLACEHOLDER} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={24} style={{ marginTop: -15 }}>
          <Col span={12}>
            <Collapse ghost>
              <Collapse.Panel header="Additional Phone Numbers" key="1">
                <Form.Item
                  label="Home Phone Number"
                  name="phoneNumberHome"
                  rules={[
                    {
                      pattern: VALID_PHONE_NUMBER_REGEX,
                      message: "Please provide a valid Phone Number",
                    },
                  ]}
                >
                  <PhoneNumberInput placeholder={PHONE_NUMBER_PLACEHOLDER} />
                </Form.Item>
                <Form.Item
                  label="Work Phone Number"
                  name="phoneNumberWork"
                  rules={[
                    {
                      pattern: VALID_PHONE_NUMBER_REGEX,
                      message: "Please provide a valid Phone Number",
                    },
                  ]}
                >
                  <PhoneNumberInput placeholder={PHONE_NUMBER_PLACEHOLDER} />
                </Form.Item>
                <Form.Item
                  label="Cell Phone Number"
                  name="phoneNumberCell"
                  rules={[
                    {
                      pattern: VALID_PHONE_NUMBER_REGEX,
                      message: "Please provide a valid Phone Number",
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <PhoneNumberInput placeholder={PHONE_NUMBER_PLACEHOLDER} />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
        <Alert
          message="Clicking 'Pre-qualify' will create an account with a temporary password and send an email notification to this guardian's email address to begin onboarding."
          type="warning"
          showIcon
        />
        <Row justify="end">
          <Form.Item style={{ marginTop: 10, marginBottom: 3 }}>
            <Button loading={isSaving} type="primary" htmlType="submit">
              Pre-qualify
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
