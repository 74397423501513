import { capitalizeFirstLetter, WeekDays } from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Col, Row, Skeleton, Typography } from "antd";
import React from "react";

const { Link: LinkText, Text } = Typography;

interface IProps {
  byDay: string;
  setByDay: (val: string) => void;
  loading?: boolean;
  size?: string;
}

export const RecurrenceByDayPicker: React.FC<IProps> = ({
  byDay,
  setByDay,
  size = "medium",
  loading = false,
}: IProps) => {
  const handleClickRecurrence = (weekday: string) => {
    let newRecurrence;
    if (byDay.includes(getRecurrenceText(weekday))) {
      newRecurrence = byDay
        .replace(`${getRecurrenceText(weekday)},`, "")
        .replace(`,${getRecurrenceText(weekday)}`, "")
        .replace(getRecurrenceText(weekday), "");
    } else {
      newRecurrence =
        byDay !== "" ? [byDay, getRecurrenceText(weekday)].join(",") : getRecurrenceText(weekday);
    }
    setByDay(newRecurrence);
  };

  const getRecurrenceText = (weekday: string) => {
    return weekday.slice(0, 2).toUpperCase();
  };

  return (
    <Row justify="space-evenly" style={{ width: "100%" }}>
      {loading ? (
        <Skeleton paragraph={false} />
      ) : (
        Object.values(WeekDays).map((weekday) => (
          <Col key={weekday}>
            <LinkText
              onClick={() => {
                handleClickRecurrence(weekday);
              }}
              style={{ userSelect: "none" }}
            >
              <span
                style={{
                  display: "block",
                  width: size === "small" ? 17 : 30,
                  height: size === "small" ? 17 : 30,
                  borderRadius: "50%",
                  background: byDay.includes(getRecurrenceText(weekday))
                    ? COLORS.PRIMARY
                    : COLORS.DARK_GRAY,
                  transition: "all 0.1s ease",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    color: "#FFF",
                    fontSize: size === "small" ? 10 : 14,
                    fontWeight: 600,
                    position: "relative",
                    top: size === "small" ? -4 : 4,
                  }}
                >
                  {size === "small"
                    ? getRecurrenceText(weekday)[0]
                    : capitalizeFirstLetter(getRecurrenceText(weekday))}
                </Text>
              </span>
            </LinkText>
          </Col>
        ))
      )}
    </Row>
  );
};
