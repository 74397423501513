import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  BillingCode,
  getCurrentActiveAuth,
  IAppointment,
  IClientGuardianDetails,
  IntakeStatus,
  UserPermission,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Button, Col, DatePicker, Input, Row, Space, Statistic } from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import * as momentTz from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ScheduleCard } from "../components/Schedule/ScheduleCard";
import { useUserClinics } from "../components/UserClinicsProvider";
import { getUtilizationColor } from "../helpers/colors";
import {
  getDurationFromAppointments,
  getWeeklyHoursFromAuth,
  isClientStatusSchedulable,
} from "../helpers/schedules";
import { userHasPermission } from "../helpers/userPermissions";
import * as FirestoreService from "../services/firestore";
import { Loading } from "./Loading";

const { Search } = Input;

export const Schedules: React.FC = () => {
  const { user } = useUserClinics();

  // URL params
  const { selectedWeek } = useParams<{
    selectedWeek?: string;
  }>();

  // Component data
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [clientsDetails, setClientsDetails] = useState<IClientGuardianDetails[]>([]);

  // Week picker
  const [calWeek, setCalWeek] = useState<Moment>(
    selectedWeek
      ? moment()
          .year(parseInt(selectedWeek.split("-")[0]))
          .week(parseInt(selectedWeek.split("-")[1]))
      : moment()
  );

  // Search
  const [filteredClientsDetails, setFilteredClientsDetails] = useState<IClientGuardianDetails[]>(
    []
  );
  const [searchString, setSearchString] = useState<string>("");

  // Statistics and alerts
  const [totalHours, setTotalHours] = useState<number>(0);
  const [authHours, setAuthHours] = useState<number>(0);
  const [isAuthHoursLoading, setIsAuthHoursLoading] = useState<boolean>(false);

  const [isNavigating, setIsNavigating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // First load
  useEffect(() => {
    fetchData().catch(() => {});
  }, [user]);

  // Refresh on auth update
  useEffect(() => {
    const totalHours = getDurationFromAppointments(appointments || []).asHours();
    setTotalHours(totalHours);
    getAuthHours().catch(() => {});
  }, [clientsDetails, appointments]);

  // Navigate weeks
  useEffect(() => {
    fetchAppointments().catch(() => {});
  }, [calWeek]);

  //URL manager
  useEffect(() => {
    const { pathname } = location;
    const idx = pathname.indexOf("schedules");
    const link =
      pathname.slice(0, idx + "schedules".length) + "/" + `${calWeek.year()}-${calWeek.week()}`;
    window.history.replaceState(null, "Mission Control - Finni Health", link);
  }, [calWeek]);

  const fetchClientsData = async (appointments: IAppointment[], force = true) => {
    const clientsDetails = await FirestoreService.getClientsGuardiansDetailsByClinicId(
      user.clinicId,
      force
    );
    const filteredClientsDetails = clientsDetails.filter((clientDetails) => {
      const client = clientDetails.client;
      const clientFile = clientDetails.clientFile;
      const clientAppointments: IAppointment[] = appointments.filter(
        (appt) => appt.clientId === client.id
      );
      const shouldKeep =
        isClientStatusSchedulable(clientFile?.intakeStatus) &&
        IntakeStatus.CHURNED !== clientFile?.intakeStatus &&
        (userHasPermission(user, UserPermission.RBT)
          ? !_.isEmpty(clientAppointments) &&
            clientAppointments.some(
              (appt) =>
                appt.clientId === client.id &&
                appt.attendees.find((attendee) => attendee.email === user.email)
            )
          : true);
      return shouldKeep;
    });
    setClientsDetails(filteredClientsDetails);
    setFilteredClientsDetails(filteredClientsDetails);
  };

  const fetchAppointments = async () => {
    setIsNavigating(true);
    const appointments = (
      await FirestoreService.getAppointmentsForClinicIdAndWeek({
        clinicId: user.clinicId,
        week: calWeek.week(),
        year: calWeek.year(),
        timeZone: momentTz.tz.guess(),
      })
    ).filter((appt) => appt.billingCode === BillingCode.CODE_97153);

    appointments.sort((a, b) => a.startMs - b.startMs);

    setAppointments(appointments);
    setIsNavigating(false);

    return appointments;
  };

  const fetchData = async () => {
    setIsLoading(true);
    const appointments = await fetchAppointments();
    await fetchClientsData(appointments, false);
    setIsLoading(false);
  };

  const getAuthHours = async () => {
    setIsAuthHoursLoading(true);
    const total = (
      await Promise.all(
        Object.values(clientsDetails).map((clientDetails) => {
          const currentAuth = getCurrentActiveAuth(
            clientDetails.clientFile.payers?.primary ?? null
          );
          const authHours = getWeeklyHoursFromAuth(currentAuth, BillingCode.CODE_97153);

          return authHours;
        })
      )
    ).reduce((partialSum, a) => partialSum + a, 0);

    setAuthHours(+total.toFixed(2));
    setIsAuthHoursLoading(false);
  };

  const updateHours = (authHoursChange: number) => {
    setAuthHours(+(authHours + authHoursChange).toFixed(2));
  };

  const handleSearch = (searchString: string) => {
    setSearchString(searchString);
    performSearch(searchString);
  };

  const performSearch = (searchString: string) => {
    // Split the search string by commas, remove empty results, and trim them
    const searchStrings = searchString
      .toLowerCase()
      .split(",")
      .map((s) => s.trim().toLowerCase())
      .filter((s) => s);

    if (!_.isEmpty(searchStrings)) {
      const filteredClientsDetails = clientsDetails.filter((clientDetails) =>
        searchStrings.some(
          (searchFor) =>
            clientDetails.client.firstName.toLowerCase().includes(searchFor) ||
            clientDetails.client.lastName.toLowerCase().includes(searchFor) ||
            clientDetails.client.alias.toLowerCase().includes(searchFor)
        )
      );
      setFilteredClientsDetails(filteredClientsDetails);
    } else {
      setFilteredClientsDetails(clientsDetails);
    }
  };

  const getUtilization = () => {
    return (totalHours / (authHours || 1) || 0) * 100;
  };

  const onCalendarChange = (date: Moment | null) => {
    if (date) {
      setCalWeek(date);
    }
  };

  const onPanelChange = (value: Moment) => {
    setCalWeek(value);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div style={{ minWidth: 1500 }}>
          {userHasPermission(user, UserPermission.ADMIN) && (
            <Row>
              <Col span={3}>
                <Statistic title="Active Clients" value={clientsDetails.length} />
              </Col>
              <Col span={3}>
                <Statistic
                  title="Authorized Hours"
                  value={authHours.toFixed(2)}
                  loading={isAuthHoursLoading}
                  valueStyle={{
                    color: COLORS.LIGHT_BLUE,
                  }}
                />
              </Col>
              <Col span={3}>
                <Statistic
                  title="Scheduled Hours"
                  value={totalHours.toFixed(2)}
                  valueStyle={{
                    color: COLORS.GREEN,
                  }}
                />
              </Col>
              <Col span={3}>
                <Statistic
                  title="Utilization"
                  value={getUtilization().toFixed(2)}
                  suffix="%"
                  valueStyle={{
                    color: getUtilizationColor(getUtilization()),
                  }}
                  loading={isAuthHoursLoading}
                />
              </Col>
            </Row>
          )}
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: 999,
              paddingTop: 15,
              paddingBottom: 15,
              background: "#fff",
            }}
          >
            <Row align="middle" style={{ width: "100%", marginBottom: 5 }}>
              <Col span={8}>
                <Space size={"large"}>
                  <Button
                    onClick={() => {
                      if (calWeek.week() !== moment().week()) {
                        setCalWeek(moment());
                      }
                    }}
                  >
                    This week
                  </Button>
                  <div>
                    <Button
                      size="small"
                      type="text"
                      onClick={() => {
                        const newCalWeek = calWeek.clone();
                        newCalWeek.subtract(1, "week");
                        setCalWeek(newCalWeek);
                      }}
                    >
                      <LeftOutlined />
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      onClick={() => {
                        const newCalWeek = calWeek.clone();
                        newCalWeek.add(1, "week");
                        setCalWeek(newCalWeek);
                      }}
                    >
                      <RightOutlined />
                    </Button>
                  </div>
                  <DatePicker
                    size="large"
                    allowClear={false}
                    style={{ cursor: "text" }}
                    format={() =>
                      `${calWeek.startOf("week").format("DD MMM")} ${calWeek.format(
                        "YYYY"
                      )} — ${calWeek.endOf("week").format("DD MMM")} ${calWeek.format("YYYY")}`
                    }
                    bordered={false}
                    value={calWeek}
                    onPanelChange={onPanelChange}
                    onChange={onCalendarChange}
                    picker="week"
                  />
                </Space>
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Search
                placeholder={`Search by First Name, Last Name, or Client Alias. Separate multiple with commas`}
                allowClear
                value={searchString}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Row>
          </div>
          <Row>
            {filteredClientsDetails.map((clientDetails) => (
              <Col span={24} key={clientDetails.client.id}>
                <ScheduleCard
                  clientDetails={clientDetails}
                  updateHours={updateHours}
                  appointments={appointments.filter(
                    (appt) => appt.clientId === clientDetails.client.id
                  )}
                  refreshCallback={fetchAppointments}
                  isNavigating={isNavigating}
                />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
};
