import { IClientGuardianDetails } from "@finni-health/shared";
import { Modal, Row, Space } from "antd";
import _ from "lodash";

import { ClientPayerCard } from "./ClientPayerCard";

interface IProps {
  clientGuardianDetails: IClientGuardianDetails;
  hideModal: () => void;
  isVisible: boolean;
}

export const InsuranceCardModal = ({ clientGuardianDetails, hideModal, isVisible }: IProps) => {
  return (
    <Modal
      title={`Insurance for ${clientGuardianDetails.guardian.firstName} ${clientGuardianDetails.guardian.lastName}`}
      onCancel={hideModal}
      open={isVisible}
      width={475}
      style={{ top: 20 }}
      footer={null}
    >
      <Row justify="center" style={{ width: "100%" }}>
        {!_.isEmpty(clientGuardianDetails.clientFile.payers) && (
          <Space direction="vertical" size={"middle"}>
            {clientGuardianDetails.clientFile.payers.primary && (
              <ClientPayerCard
                client={clientGuardianDetails.client}
                clientPayer={clientGuardianDetails.clientFile.payers.primary}
              />
            )}
            {clientGuardianDetails.clientFile.payers.secondary && (
              <ClientPayerCard
                client={clientGuardianDetails.client}
                clientPayer={clientGuardianDetails.clientFile.payers.secondary}
              />
            )}
          </Space>
        )}
      </Row>
    </Modal>
  );
};
