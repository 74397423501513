import { IClientFile, IGuardianClientDetails } from "@finni-health/shared";
import { Breadcrumb, Button, Col, Row, Space, Tabs, Tag, Typography } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { IoHeartCircleOutline } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ClientIntakeForm } from "../components/ClientIntakeForm/ClientIntakeForm";
import { ClientPayerCard } from "../components/ClientPayerCard";
import { GuardianDetailsSection } from "../components/GuardianProfile/GuardianDetailsSection";
import * as FirestoreService from "../services/firestore";
import { Loading } from "./Loading";

const { Title, Link: LinkText } = Typography;

export enum TABLE_TABS {
  OVERVIEW = "Overview",
  INTAKE = "Intake Form",
}

export const GuardianProfile: React.FC = () => {
  const { guardianId } = useParams<{ guardianId: string }>();
  const navigate = useNavigate();
  const [guardianClientDetails, setGuardianClientDetails] = useState<IGuardianClientDetails | null>(
    null
  );
  const [selectedTab, setSelectedTab] = useState<TABLE_TABS>(TABLE_TABS.OVERVIEW);

  const fetchData = async () => {
    if (guardianId) {
      const guardianClientDetails = await FirestoreService.getGuardianClientDetailsByGuardianId(
        guardianId
      );
      setGuardianClientDetails(guardianClientDetails);
    }
  };

  useEffect(() => {
    fetchData().catch(() => {});
  }, [guardianId]);

  return guardianClientDetails === null ? (
    <Loading />
  ) : (
    <>
      <Row align="middle">
        <Space size="middle">
          <Button onClick={() => navigate(-1)}>
            <LinkText>Back</LinkText>
          </Button>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="../intake">Intake</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{`${guardianClientDetails.guardian.firstName} ${guardianClientDetails.guardian.lastName}`}</Breadcrumb.Item>
          </Breadcrumb>
        </Space>
      </Row>
      <Row style={{ marginTop: 25 }} align="middle">
        <Title
          level={2}
        >{`${guardianClientDetails.guardian.firstName} ${guardianClientDetails.guardian.lastName}`}</Title>
        <Tag
          color={"purple"}
          style={{
            position: "relative",
            left: 10,
            bottom: 9,
            fontSize: 15,
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          <IoHeartCircleOutline style={{ fontSize: 17, marginRight: 2, marginBottom: -3 }} />
          Guardian
        </Tag>
      </Row>
      <Tabs
        type="card"
        defaultActiveKey={selectedTab.valueOf()}
        onChange={(key) => {
          setSelectedTab(key as TABLE_TABS);
        }}
        items={Object.values(TABLE_TABS).map((tab) => ({
          key: tab,
          label: tab,
        }))}
      ></Tabs>
      {selectedTab === TABLE_TABS.OVERVIEW && (
        <>
          <GuardianDetailsSection
            guardianClientDetails={guardianClientDetails}
            refreshCallback={fetchData}
          />
          {guardianClientDetails.clientFile &&
            !_.isEmpty(guardianClientDetails.clientFile.payers) && (
              <>
                <Title level={4}>Payers</Title>
                <Row
                  justify={
                    guardianClientDetails.clientFile.payers.secondary ? "space-around" : "start"
                  }
                >
                  {guardianClientDetails.clientFile.payers.primary && (
                    <Col>
                      <ClientPayerCard
                        client={guardianClientDetails.client!}
                        clientPayer={guardianClientDetails.clientFile.payers.primary}
                      />
                    </Col>
                  )}
                  {guardianClientDetails.clientFile.payers.secondary && (
                    <Col>
                      <ClientPayerCard
                        client={guardianClientDetails.client!}
                        clientPayer={guardianClientDetails.clientFile.payers.secondary}
                      />
                    </Col>
                  )}
                </Row>
              </>
            )}
        </>
      )}
      {selectedTab === TABLE_TABS.INTAKE && (
        <ClientIntakeForm
          clientFile={guardianClientDetails.clientFile || ({} as IClientFile)}
          refreshCallback={fetchData}
        />
      )}
    </>
  );
};
