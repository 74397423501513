import { IAddressDistanceEndpointResponse } from "@finni-health/shared";

import * as backend from "./backend";

export const ENDPOINTS = {
  getDistanceBetweenEndpoint: () => backend.endpoint("/google-maps/get-distances-between", "post"),
};
export const getDistanceInfoBetween = async (
  addressA: string,
  addressB: string
): Promise<IAddressDistanceEndpointResponse> => {
  try {
    const updateClient = ENDPOINTS.getDistanceBetweenEndpoint();
    const result = await updateClient({ addressA, addressB });

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};
