import { Tabs } from "antd";
import React, { useState } from "react";

import { BilledAppointmentsTable } from "../components/Billing/BilledAppointmentsTable";
import { CompletedAppointmentsTable } from "../components/Billing/CompletedAppointmentsTable";
import { PayerRatesTable } from "../components/Billing/PayerRatesTable";

export const enum BILLING_TABS {
  PENDING = "Pending",
  FINALIZED = "Finalized",
  RATES = "Rates",
}

export const Billing: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<BILLING_TABS>(BILLING_TABS.PENDING);
  const [pendingCount, setPendingCount] = useState<number | null>(null);

  const handleTabChange = (value: string) => {
    setSelectedTab(value as BILLING_TABS);
  };

  const handlePendingCount = (value: number) => {
    setPendingCount(value);
  };

  return (
    <>
      <Tabs
        defaultActiveKey="completed"
        activeKey={selectedTab}
        onChange={handleTabChange}
        items={[
          {
            label: `${BILLING_TABS.PENDING} ${pendingCount !== null ? `(${pendingCount})` : ""}`,
            key: BILLING_TABS.PENDING,
            children: (
              <CompletedAppointmentsTable
                handleTabChange={handleTabChange}
                handleCount={handlePendingCount}
              />
            ),
          },
          {
            label: BILLING_TABS.FINALIZED,
            key: BILLING_TABS.FINALIZED,
            children: <BilledAppointmentsTable />,
          },
          {
            label: BILLING_TABS.RATES,
            key: BILLING_TABS.RATES,
            children: <PayerRatesTable />,
          },
        ]}
      />
    </>
  );
};
