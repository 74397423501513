import {
  BillingCode,
  BillingLevel,
  IAppointment,
  IAppointmentType,
  ICompletedAppointment,
  IPayer,
  IStatePayersConfig,
  USStateCode,
} from "../../types";
import { generateAddress, getBaseBillingModifiers } from "./helpers";

export const ILLINOIS_APPOINTMENTS: IAppointmentType = {};

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  billingLevel: BillingLevel
) => {
  return getBaseBillingModifiers(appointment, payer, billingLevel);
};

export const ILLINOIS_PAYERS: IStatePayersConfig = [
  {
    name: "Blue Cross Blue Shield IL Commercial",
    payerId: "BCBSIL",
    contractAddress: generateAddress(
      "634 Nantucket Way",
      "",
      "Island Lake",
      "60042-9413",
      USStateCode.IL
    ),
    billingAddress: generateAddress(
      "634 Nantucket Way",
      "",
      "Island Lake",
      "60042-9413",
      USStateCode.IL
    ),
    modifiers: {
      RBT: "",
      BCBA: "",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 1042,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1934,
          BCBA: 1934,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2423,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2310,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
  {
    name: "United Healthcare / Optum",
    payerId: "87726",
    contractAddress: generateAddress(
      "634 Nantucket Way",
      "",
      "Island Lake",
      "60042-9413",
      USStateCode.IL
    ),
    billingAddress: generateAddress(
      "3621 Marion Ln",
      "",
      "Las Cruces",
      "88012-7579",
      USStateCode.NM
    ),
    modifiers: {
      RBT: "HM",
      BCBA: "HO",
    },
    locationModifiers: {},
    codes: {
      [BillingCode.CODE_97151]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 2540,
        },
      },
      [BillingCode.CODE_97153]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          RBT: 1251,
          BCBA: 1418,
        },
      },
      [BillingCode.CODE_97155]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 1642,
        },
      },
      [BillingCode.CODE_97156]: {
        authRequired: true,
        unitSize: 15,
        rates: {
          BCBA: 1488,
        },
      },
    },
    getBillingModifiers: getStateBillingModifiers,
  },
];
