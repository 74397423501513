/**
 * A map of channel names and their respective webhook URL. To add a new URL, do the following:
 *
 * 1. Go to https://api.slack.com/apps/A04UM5ZLSPL/incoming-webhooks? and adda new webhook for your channel
 *
 * 2. Add the channel to the .env.prod file as an environment variable
 *
 * 3. Add the channel name <> webhook URL mapping below
 */
const SLACK_CHANNEL_WEBHOOKS: {
  [channelName: string]: string | undefined;
} = {
  "billing-credentialing": process.env.REACT_APP_SLACK_BILLING_CREDENTIALING_WEBHOOK_URL,
};

export const sendSlackMessage = (channel: string, message: string) => {
  const webhookUrl = SLACK_CHANNEL_WEBHOOKS[channel];

  webhookUrl &&
    fetch(webhookUrl, {
      method: "post",
      body: '{"text":' + message + "}",
    }).catch(() => {});
};
