import "../components/css/Calendar.css";

import {
  ExclamationCircleOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  ActiveStatus,
  CalendarType,
  capitalizeFirstLetter,
  EventType,
  IAppointment,
  ICalendarEvent,
  ICalendarRow,
  IClient,
  IClientDetails,
  ICompletedAppointment,
  IIndirect,
  INote,
  IUser,
  UserPermission,
  WeekDays,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import {
  Button,
  DatePicker,
  Input,
  message,
  notification,
  Row,
  Segmented,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import _ from "lodash";
import moment, { Moment } from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AppointmentCard } from "../components/Calendar/AppointmentCard";
import { ClientSummary } from "../components/Calendar/ClientSummary";
import { CompletedAppointmentCard } from "../components/Calendar/CompletedAppointmentCard";
import { CreateAppointmentModal } from "../components/Calendar/CreateAppointmentModal";
import { CreateIndirectModal } from "../components/Calendar/CreateIndirectModal";
import { IndirectCard } from "../components/Calendar/IndirectCard";
import { ListView } from "../components/Calendar/ListView";
import { MatchNotesDrawer } from "../components/Calendar/MatchNotesDrawer";
import { TherapistSummary } from "../components/Calendar/TherapistSummary";
import { useUserClinics } from "../components/UserClinicsProvider";
import { DEBOUNCE_TIME } from "../consts";
import { datePickerFormat, findEventInConflicts, getCalendarDayHeader } from "../helpers/calendar";
import { findSchedulingConflicts } from "../helpers/calendar";
import { userHasPermission } from "../helpers/userPermissions";
import * as FirestoreService from "../services/firestore";

const { Text, Link: LinkText } = Typography;
const { Search } = Input;

export const isMatchingContext = React.createContext({} as IAppointment);

const CalendarNavigationButtons = ({
  calWeek,
  setCalWeek,
}: {
  calWeek: Moment;
  setCalWeek: (calWeek: Moment) => void;
}) => {
  const onCalendarChange = (date: Moment | null) => {
    if (date) {
      setCalWeek(date);
    }
  };

  const onPanelChange = (value: Moment) => {
    setCalWeek(value);
  };

  return (
    <>
      <Button
        onClick={() => {
          if (calWeek.week() !== moment().week()) {
            setCalWeek(moment());
          }
        }}
      >
        Today
      </Button>
      <Button
        size="small"
        type="text"
        onClick={() => {
          const newCalWeek = calWeek.clone();
          newCalWeek.subtract(1, "week");
          setCalWeek(newCalWeek);
        }}
      >
        <LeftOutlined />
      </Button>
      <Button
        size="small"
        type="text"
        onClick={() => {
          const newCalWeek = calWeek.clone();
          newCalWeek.add(1, "week");
          setCalWeek(newCalWeek);
        }}
      >
        <RightOutlined />
      </Button>
      <DatePicker
        size="large"
        allowClear={false}
        style={{ cursor: "text" }}
        format={() => datePickerFormat(calWeek)}
        bordered={false}
        value={calWeek}
        onPanelChange={onPanelChange}
        onChange={onCalendarChange}
        picker="week"
      />
    </>
  );
};

const CalendarCompletedAppointmentsFilter = ({
  showIncompleteAppointments,
  setShowIncompleteAppointments,
}: {
  showIncompleteAppointments: boolean;
  setShowIncompleteAppointments: (showIncompleteAppointments: boolean) => void;
}) => {
  return (
    <Switch
      checkedChildren={<Text style={{ color: "white", padding: 4 }}>{`Clear filter`}</Text>}
      unCheckedChildren={
        <Text
          style={{
            color: "#565656",
            padding: 4,
          }}
        >{`Hide Completed Appts.`}</Text>
      }
      checked={showIncompleteAppointments}
      onChange={(checked) => {
        setShowIncompleteAppointments(checked);
      }}
      style={{
        marginLeft: 5,
        backgroundColor: showIncompleteAppointments ? COLORS.PRIMARY : "#F5F5F5",
        borderRadius: 12,
      }}
      defaultChecked
    />
  );
};

const CalendarUnmatchedNotes = ({
  unmatchedNotes,
  onClick,
}: {
  unmatchedNotes: INote[];
  onClick: () => void;
}) => {
  if (unmatchedNotes.length === 0) {
    return null;
  }

  return (
    <LinkText onClick={onClick}>
      <ExclamationCircleOutlined
        style={{
          marginLeft: 10,
          marginRight: 6,
          fontSize: 12.5,
        }}
      />
      {`${unmatchedNotes.length} Unmatched Note${unmatchedNotes.length > 1 ? "s" : ""}`}
    </LinkText>
  );
};

const CalendarPrimaryActionButton = ({
  onIndirectClick,
  onCreateClick,
}: {
  onIndirectClick: () => void;
  onCreateClick: () => void;
}) => {
  const { user } = useUserClinics();

  if (userHasPermission(user, UserPermission.RBT)) {
    return (
      <Button shape="round" size="large" type="primary" onClick={onIndirectClick}>
        <PlusOutlined style={{ marginLeft: -3 }} />
        Log Indirect
      </Button>
    );
  }
  return (
    <Button shape="round" size="large" type="primary" onClick={onCreateClick}>
      <PlusOutlined style={{ marginLeft: -3 }} />
      Create
    </Button>
  );
};

const CalendarTypeToggle = ({
  selectedCalendarType,
  setSelectedCalendarType,
}: {
  selectedCalendarType: CalendarType;
  setSelectedCalendarType: (calendarType: CalendarType) => void;
}) => {
  const { user } = useUserClinics();

  if (userHasPermission(user, UserPermission.RBT)) {
    return null;
  }

  return (
    <Segmented
      options={Object.values(CalendarType).map((val) => ({
        value: val,
        label: capitalizeFirstLetter(val),
      }))}
      value={selectedCalendarType}
      onChange={(value) => {
        setSelectedCalendarType(value as CalendarType);
      }}
      style={{ width: 210 }}
    />
  );
};

const CalendarSearchBar = ({
  selectedCalendarType,
  searchString,
  handleSearch,
}: {
  selectedCalendarType: CalendarType;
  searchString: string;
  handleSearch: (searchString: string) => void;
}) => {
  const { user } = useUserClinics();

  const placeholderText = `Search calendars by First ${
    selectedCalendarType === CalendarType.THERAPISTS ? "or" : "Name,"
  } Last Name${
    selectedCalendarType === CalendarType.THERAPISTS ? "" : ", or Client Alias"
  } (separate multiple with commas), or paste the link to a Motivity note here`;

  return (
    <Search
      placeholder={placeholderText}
      allowClear
      value={searchString}
      onChange={(e) => handleSearch(e.target.value)}
      style={{
        paddingLeft: !userHasPermission(user, UserPermission.RBT) ? 10 : 0,
        width: !userHasPermission(user, UserPermission.RBT) ? "calc(100% - 220px)" : "100%",
      }}
    />
  );
};

export const Calendar: React.FC = () => {
  const { user, clinic } = useUserClinics();

  // Params
  const { calendarType, selectedWeek, eventId } = useParams<{
    calendarType?: CalendarType;
    selectedWeek?: string;
    eventId?: string;
  }>();

  const [selectedFilters, setSelectedFilters] = useState<CheckboxValueType[]>(
    //parse filters from URL
    document.URL.includes("?")
      ? document.URL.split("?")[1]
          .split("=")[1]
          .split(",")
          .map((filter) => {
            return filter;
          })
      : []
  );

  // General state
  const [tableColumnsObject, setTableColumnsObject] = useState<any>();
  const [eventCardOpenedOnce, setEventCardOpenedOnce] = useState<boolean>(false);
  const [matchNotesDrawerOpenedOnce, setMatchNotesDrawerOpenedOnce] = useState<boolean>(false);
  const [weeksNotified, setWeeksNotified] = useState<Set<number>>(new Set()); //warn about missing notes only once per week per session
  const [showIncompleteAppointments, setShowIncompleteAppointments] = useState<boolean>(false);

  // Loading states
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isNavigating, setIsNavigating] = useState<boolean>(false);
  const [isCalendarProcessing, setIsCalendarProcessing] = useState<boolean>(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState<boolean>(false);

  // Calendar
  const [users, setUsers] = useState<IUser[]>([]);
  const [clinicUsers, setClinicUsers] = useState<IUser[]>([]);
  const [clientsDetails, setClientsDetails] = useState<Record<string, IClientDetails>>({});
  const [notes, setNotes] = useState<INote[]>([]);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [appointmentsByClient, setAppointmentsByClient] = useState<ICalendarRow[]>([]);
  const [appointmentsByTherapist, setAppointmentsByTherapist] = useState<ICalendarRow[]>([]);
  const [completedAppointments, setCompletedAppointments] = useState<ICompletedAppointment[]>([]);
  const [conflictingAppointments, setConflictingAppointments] = useState<ICalendarEvent[]>([]);
  const [dataSource, setDataSource] = useState<ICalendarRow[]>([]);
  const [searchString, setSearchString] = useState<string>("");

  const [calWeek, setCalWeek] = useState<Moment>(
    selectedWeek
      ? moment()
          .year(parseInt(selectedWeek.split("-")[0]))
          .week(parseInt(selectedWeek.split("-")[1]))
      : moment()
  );
  const [selectedCalendarType, setSelectedCalendarType] = useState<CalendarType>(
    calendarType
      ? calendarType
      : userHasPermission(user, UserPermission.RBT)
      ? CalendarType.THERAPISTS
      : CalendarType.CLIENTS
  );

  // Create appointments modal
  const [isCreateAppointmentModalVisible, setIsCreateAppointmentModalVisible] =
    useState<boolean>(false);
  const hideCreateAppointmentModal = () => {
    setIsCreateAppointmentModalVisible(false);
  };

  // Create indirect modal
  const [isCreateIndirectModalVisible, setIsCreateIndirectModalVisible] = useState<boolean>(false);
  const hideCreateIndirectModal = () => {
    setIsCreateIndirectModalVisible(false);
  };

  // Event card
  const [selectedEvent, setSelectedEvent] = useState<ICalendarEvent>();
  const [selectedClient, setSelectedClient] = useState<IClient>();

  // Match notes drawer
  const [unmatchedNotes, setUnmatchedNotes] = useState<INote[]>([]);
  const [isMatchNotesDrawerOpen, setIsMatchNotesDrawerOpen] = useState<boolean>(false);
  const [matchNotesDrawerAppointment, setMatchNotesDrawerAppointment] = useState<IAppointment>(
    {} as IAppointment
  );
  const [matchNotesDrawerClient, setMatchNotesDrawerClient] = useState<IClient>({} as IClient);
  const [matchingAppointment, setMatchingAppointment] = useState<IAppointment>({} as IAppointment);

  // Debounce timeouts
  const [searchTimeout, setSearchTimeout] = useState<any>(0);
  const [fetchAppointmentsTimeout, setFetchAppointmentsTimeout] = useState<any>(0);

  const handleSelectedEvent = useCallback((event: ICalendarEvent | undefined) => {
    if (!isMatchNotesDrawerOpen) {
      setSelectedEvent(event);
    }
  }, []);
  const handleSelectedClient = useCallback((client: IClient | undefined) => {
    if (!isMatchNotesDrawerOpen) {
      setSelectedClient(client);
    }
  }, []);

  // First load
  useEffect(() => {
    setIsLoading(true);
    setHasLoadedOnce(false);
    fetchData().catch(() => {});
  }, [user]);

  // On navigate
  useEffect(() => {
    if (!isLoading) {
      notification.close("missingNotes");
      setIsNavigating(true);
      throttledFetchAppointments().catch(() => {});
    }
  }, [calWeek]);

  // On missing notes
  useEffect(() => {
    if (!isLoading && hasLoadedOnce) {
      notification.close("missingNotes");
      setIsNavigating(true);
      fetchAppointments().catch(() => {});
    }
  }, [showIncompleteAppointments, hasLoadedOnce]);

  // Calendar type selector
  useEffect(() => {
    if (!_.isEmpty(searchString)) {
      // performSearch sets the correct dataSource as a side effect
      performSearch(searchString, appointmentsByClient, appointmentsByTherapist);
    } else {
      switch (selectedCalendarType) {
        case CalendarType.CLIENTS:
          setDataSource(appointmentsByClient);
          break;
        case CalendarType.THERAPISTS:
          setDataSource(appointmentsByTherapist);
          break;
      }
    }
  }, [selectedCalendarType]);

  //open match drawer from URL
  useEffect(() => {
    if (
      eventCardOpenedOnce &&
      !matchNotesDrawerOpenedOnce &&
      !_.isEmpty(selectedFilters) &&
      !_.isEmpty(selectedEvent) &&
      !_.isEmpty(selectedClient)
    ) {
      setIsMatchNotesDrawerOpen(true);
    }
  }, [selectedClient]); //only depends on selectedClient to ensure that EventCard has loaded

  //URL manager
  useEffect(() => {
    const { pathname } = location;
    const idx = pathname.indexOf("calendar");
    let link = pathname.slice(0, idx + "calendar".length);

    //Add calendar type to url
    link += `/${selectedCalendarType}`;

    if (selectedEvent && !_.isEmpty(selectedEvent)) {
      //Add week and year to url
      link += `/${moment(selectedEvent.startMs).year()}-${moment(selectedEvent.startMs).week()}`;

      //Add appointmentId to URL
      link += "/" + selectedEvent.id;

      //Add filters to URL
      if (!_.isEmpty(selectedFilters) && isMatchNotesDrawerOpen) {
        link += "?filters=" + selectedFilters.join(",");
      }
    } else {
      link += `/${calWeek.year()}-${calWeek.week()}`;
    }

    window.history.replaceState(null, "Mission Control - Finni Health", link);
  }, [calWeek, selectedCalendarType, selectedEvent, selectedFilters, isMatchNotesDrawerOpen]);

  useEffect(() => {
    if (isMatchNotesDrawerOpen) {
      if (!matchNotesDrawerOpenedOnce) {
        setMatchNotesDrawerOpenedOnce(true);
      }
      setMatchNotesDrawerAppointment(selectedEvent as IAppointment);
      setMatchNotesDrawerClient(selectedClient as IClient);
    }
    setTableColumnsObject(getTableColumns());
  }, [isMatchNotesDrawerOpen]);

  //reducing re-renders
  useEffect(() => {
    setTableColumnsObject(getTableColumns());
  }, [
    isLoading,
    isNavigating,
    selectedCalendarType,
    isCalendarProcessing,
    dataSource,
    fetchAppointmentsTimeout,
  ]);

  const closeMissingNotesNotification = () => {
    weeksNotified.add(calWeek.week());
    setWeeksNotified(weeksNotified);
    notification.close("missingNotes");
  };

  // Warn about missing notes
  const warnMissingNotes = () => {
    notification.open({
      key: "missingNotes",
      duration: 0,
      type: "warning",
      message: "You are missing notes for some appointments this week!",
      description: `There are ${
        Object.values(appointmentsMissingNotes).length
      } appointments with no notes in Motivity. Please write notes to appointments regularly to ensure accurate billing.`,
      onClick: closeMissingNotesNotification,
      onClose: closeMissingNotesNotification,
    });
  };

  const appointmentsMissingNotes = {} as any; //states don't work well here, keeping it simple

  const addAppointmentMissingNote = (appointment: IAppointment) => {
    const renderingUserEmail: string =
      appointment?.attendees.find((attendee) => {
        const user = users.find((user) => user.email === attendee.email);
        return user?.permissions?.includes(UserPermission.BCBA);
      })?.email || appointment?.attendees?.[0]?.email;
    if (!weeksNotified.has(calWeek.week()) && renderingUserEmail === user.email) {
      appointmentsMissingNotes[appointment.id] = appointment;
      warnMissingNotes();
    }
  };

  const fetchData = useCallback(async () => {
    const [clinicUsers, clientDetails] = await Promise.all([
      fetchClinicUsers(),
      fetchClientFiles(),
      fetchUnmatchedNotes(),
    ]);

    await fetchAppointments(clinicUsers, clientDetails);

    setIsLoading(false);
    setHasLoadedOnce(true);
  }, [
    user,
    eventCardOpenedOnce,
    showIncompleteAppointments,
    selectedCalendarType,
    calWeek,
    searchString,
  ]);

  const fetchClinicUsers = async () => {
    const users = await FirestoreService.getAllUsersForClinic(user.clinicId);
    setUsers(users);

    const clinicUsers = users.filter((clinicUser) =>
      userHasPermission(user, UserPermission.RBT)
        ? user.id == clinicUser.id
        : [UserPermission.BCBA, UserPermission.RBT].some((permission) =>
            userHasPermission(clinicUser, permission)
          )
    );
    setClinicUsers(clinicUsers);
    return clinicUsers;
  };

  const fetchClientFiles = async () => {
    const clientDetails = await FirestoreService.getClientsDetailsByClinicId(user.clinicId);
    const clientDetailsMap = _.keyBy(clientDetails, (clientDetails) => clientDetails.client.id);

    setClientsDetails(clientDetailsMap);
    return clientDetailsMap;
  };

  const fetchUnmatchedNotes = async () => {
    let unmatchedNotes = await FirestoreService.getAllUnmatchedNotesByClinicId(user.clinicId);
    if (userHasPermission(user, UserPermission.RBT)) {
      unmatchedNotes = unmatchedNotes.filter((note) => note.userId === user.id);
    }
    setUnmatchedNotes(unmatchedNotes);
    return unmatchedNotes;
  };

  const throttledFetchAppointments = (...options: any) => {
    if (fetchAppointmentsTimeout) {
      clearTimeout(fetchAppointmentsTimeout);
    }
    return new Promise<void>((resolve, reject) => {
      setFetchAppointmentsTimeout(
        setTimeout(async () => {
          if (!_.isEmpty(options)) {
            await fetchAppointments(...options);
            resolve();
          } else {
            await fetchAppointments();
            reject();
          }
        }, DEBOUNCE_TIME)
      );
    });
  };

  const fetchAppointments = async (
    users?: IUser[],
    clientDetailsLocal?: Record<string, IClientDetails>
  ) => {
    setIsCalendarProcessing(true);
    // Fetch notes to seed the cache
    // eslint-disable-next-line prefer-const
    let [notes, appointments, indirects, completedAppointments] = await Promise.all([
      FirestoreService.getNotesForRangeAndClinic(
        moment().year(calWeek.year()).week(calWeek.week()).startOf("week").add(-1, "day").valueOf(),
        moment().year(calWeek.year()).week(calWeek.week()).endOf("week").add(1, "day").valueOf(),
        user.clinicId
      ),
      FirestoreService.getAppointmentsForClinicIdAndWeek({
        clinicId: user.clinicId,
        week: calWeek.week(),
        year: calWeek.year(),
        timeZone: moment().tz() || moment.tz.guess(),
      }),
      FirestoreService.getIndirectsForWeekAndClinicId({
        week: calWeek.week(),
        year: calWeek.year(),
        clinicId: user.clinicId,
        timeZone: moment().tz() || moment.tz.guess(),
      }),
      FirestoreService.getCompletedAppointmentByClinicAndRange({
        clinicId: user.clinicId,
        startMs: calWeek.startOf("week").valueOf(),
        endMs: calWeek.endOf("week").valueOf(),
      }),
    ]);

    appointments = appointments.filter((appointment) => !_.isEmpty(appointment.attendees));

    if (userHasPermission(user, UserPermission.RBT)) {
      appointments = appointments.filter((appointment) =>
        appointment.attendees.some((attendee) => attendee.email == user.email)
      );

      indirects = indirects.filter((indirect) =>
        indirect.attendees.some((attendee) => attendee.email == user.email)
      );

      completedAppointments = completedAppointments.filter((completedAppt) =>
        completedAppt.attendees.some((attendee) => attendee.email == user.email)
      );
    }

    if (showIncompleteAppointments) {
      appointments = appointments.filter(
        (appointment) =>
          !completedAppointments.find((completedAppt) => completedAppt.id === appointment.id)
      );
      completedAppointments = [];
      indirects = indirects.filter((indirect) => _.isEmpty(indirect.description.trim()));
    }

    // Only include active clients, or inactive clients who have appointments booked
    const filteredClients = Object.values(clientDetailsLocal || clientsDetails).reduce(
      (acc: IClient[], clientDetails: IClientDetails) => {
        const { client, clientFile } = clientDetails;
        const shouldKeep =
          appointments.find((appt) => appt.clientId === client.id) ||
          ((clientFile?.intakeStatus as string) === (ActiveStatus.ACTIVE as string) &&
            !showIncompleteAppointments);
        if (shouldKeep) {
          acc.push(client);
        }

        return acc;
      },
      [] as IClient[]
    );

    const clientEvents: Array<IAppointment | ICompletedAppointment> = [
      ...appointments,
      ...completedAppointments,
    ];

    const events: Array<ICalendarEvent> = [...indirects, ...clientEvents];

    const clientAppointments = groupAppointmentsByClient(filteredClients, clientEvents);

    const therapistAppointments = groupAppointmentsByTherapist(users || clinicUsers, events);

    const conflicts = findSchedulingConflicts([...events]);

    //Open event card if eventId is in URL
    if (!eventCardOpenedOnce) {
      handleSelectedEvent(events.find((appt) => appt.id === eventId));
      setEventCardOpenedOnce(true);
    } else {
      handleSelectedEvent(undefined);
      handleSelectedClient(undefined);
    }

    setNotes(notes);
    setAppointments(appointments);
    setCompletedAppointments(completedAppointments);
    setAppointmentsByClient(clientAppointments);
    setAppointmentsByTherapist(therapistAppointments);
    setConflictingAppointments(conflicts);

    switch (selectedCalendarType) {
      case CalendarType.THERAPISTS:
        setDataSource(therapistAppointments);
        break;
      default:
        setDataSource(clientAppointments);
        break;
    }

    // If searchString is present, apply it to the fetch results
    if (!_.isEmpty(searchString)) {
      performSearch(searchString, clientAppointments, therapistAppointments);
    }

    setIsCalendarProcessing(false);
    setIsNavigating(false);
  };

  const groupAppointmentsByClient = (
    clinicClients: IClient[],
    appointments: Array<IAppointment | ICompletedAppointment>
  ) => {
    const groupedByPerson = _(appointments)
      .groupBy((appointment) => appointment.clientId)
      .value();

    const result: ICalendarRow[] = [];
    for (const client of clinicClients) {
      const apptsForClient = groupedByPerson[client.id] || [];
      result.push({
        person: client,
        sunday: apptsForClient.filter((appt) => moment(appt.startMs).day() === 0),
        monday: apptsForClient.filter((appt) => moment(appt.startMs).day() === 1),
        tuesday: apptsForClient.filter((appt) => moment(appt.startMs).day() === 2),
        wednesday: apptsForClient.filter((appt) => moment(appt.startMs).day() === 3),
        thursday: apptsForClient.filter((appt) => moment(appt.startMs).day() === 4),
        friday: apptsForClient.filter((appt) => moment(appt.startMs).day() === 5),
        saturday: apptsForClient.filter((appt) => moment(appt.startMs).day() === 6),
      });
    }

    result.sort((a, b) => a.person.firstName.localeCompare(b.person.firstName));
    return result;
  };

  const groupAppointmentsByTherapist = (clinicUsers: IUser[], events: ICalendarEvent[]) => {
    let appointmentsPerPerson: ICalendarEvent[] = [];

    events.forEach((event) => {
      const apptSpread: ICalendarEvent[] = [];

      if (event.eventType === EventType.APPOINTMENT || event.eventType === EventType.INDIRECT) {
        event.attendees.forEach((attendee) => {
          if (clinicUsers.some((user) => user.email === attendee.email)) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            apptSpread.push({
              ...event,
              attendee: attendee.email,
            } as any);
          }
        });
      } else if (event.eventType === EventType.COMPLETED) {
        (event as ICompletedAppointment).userIds.forEach((userId) => {
          const foundUser = clinicUsers.find((user) => user.id === userId);
          if (foundUser && !_.isEmpty(foundUser)) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            apptSpread.push({
              ...event,
              attendee: foundUser.email,
            } as any);
          }
        });
      }

      appointmentsPerPerson = appointmentsPerPerson.concat(apptSpread);
    });

    const groupedByPerson = _(appointmentsPerPerson)
      .groupBy((appointment: any) => appointment.attendee)
      .value();

    const result: ICalendarRow[] = [];
    for (const user of clinicUsers) {
      const apptsForEmail = groupedByPerson[user.email] || [];
      result.push({
        person: user,
        sunday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 0),
        monday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 1),
        tuesday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 2),
        wednesday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 3),
        thursday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 4),
        friday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 5),
        saturday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 6),
      });
    }

    // Sort calendars by firstName, and then put yourself at the top
    result.sort((a, b) => a.person.firstName.localeCompare(b.person.firstName));
    const userIndex = result.findIndex((appt) => appt.person.id === user.id);
    userIndex !== -1 && result.splice(0, 0, result.splice(userIndex, 1)[0]);

    return result;
  };

  const hideMatchNotesDrawer = () => {
    setIsMatchNotesDrawerOpen(false);
  };

  const appointmentsTableRender = (events: ICalendarEvent[]) => {
    return events
      .sort((a, b) => a.startMs - b.startMs)
      .map((event) => {
        const forceOpen = event.id === selectedEvent?.id ? true : false;
        const clientDetails = clientsDetails[(event as any).clientId];
        const client = clientDetails?.client;
        const clientFile = clientDetails?.clientFile;
        if (client && event.eventType === EventType.COMPLETED) {
          return (
            <CompletedAppointmentCard
              key={event.id}
              users={clinicUsers}
              client={client}
              completedAppointment={event as ICompletedAppointment}
              forceOpen={forceOpen}
              setSelectedEvent={handleSelectedEvent}
              refreshAppointments={throttledFetchAppointments}
              appointment={
                appointments.find((appointment) => appointment.id === event.id) as IAppointment
              }
              isCalendarProcessing={isCalendarProcessing}
              // If completed appointment is in conflicts array, pass array to card
              scheduleConflicts={findEventInConflicts(event, conflictingAppointments)}
            />
          );
        } else if (client && event.eventType === EventType.APPOINTMENT) {
          //GCAL Appointment
          event = event as IAppointment;
          return (
            !completedAppointments.find((appt) => appt.id === (event as IAppointment).id) && (
              <AppointmentCard
                client={client}
                appointment={event}
                users={clinicUsers}
                calendarType={selectedCalendarType}
                refreshAppointments={throttledFetchAppointments}
                refreshAll={fetchData}
                forceOpen={forceOpen}
                setSelectedAppointment={handleSelectedEvent}
                setSelectedClient={handleSelectedClient}
                setIsMatchNotesDrawerOpen={setIsMatchNotesDrawerOpen}
                isCalendarProcessing={isCalendarProcessing}
                addAppointmentMissingNote={addAppointmentMissingNote}
                scheduleConflicts={findEventInConflicts(event, conflictingAppointments)}
                clientFile={clientFile}
              />
            )
          );
        } else if (event.eventType === EventType.INDIRECT) {
          //Indirect Appointment
          event = event as IIndirect;
          return (
            <IndirectCard
              key={event.id}
              indirect={event}
              forceOpen={forceOpen}
              setSelectedEvent={handleSelectedEvent}
              refreshCallback={throttledFetchAppointments}
              isCalendarProcessing={isCalendarProcessing}
              scheduleConflicts={findEventInConflicts(event, conflictingAppointments)}
            />
          );
        }
      });
  };

  const getTableColumns = () => {
    const tableColumns = [
      {
        title: (
          <Row style={{ height: 54, width: "100%" }} align="middle" justify="center">
            Calendars
          </Row>
        ),
        dataIndex: "person",
        sorter: (a: any, b: any) => a.person.firstName.localeCompare(b.person.firstName),

        render: (person: IClient | IUser, row: ICalendarRow) => {
          switch (selectedCalendarType) {
            case CalendarType.CLIENTS:
              return <ClientSummary data={row} clientFile={clientsDetails[person.id].clientFile} />;
            case CalendarType.THERAPISTS:
              return <TherapistSummary data={row} selectedWeek={calWeek} />;
          }
        },
        width: 145,
      },
      ...Object.values(WeekDays).map((day: WeekDays) => {
        return {
          title: getCalendarDayHeader(day, calWeek),
          dataIndex: day,
          render: appointmentsTableRender,
          width: 167,
        };
      }),
    ];
    return tableColumns;
  };

  const handleSearch = async (searchString: string) => {
    if (searchString.includes("/")) {
      await handleMotivityLinkSearch(searchString);
    } else {
      setSearchString(searchString);
      throttledSearch(searchString);
    }
  };

  const handleMotivityLinkSearch = async (searchString: string) => {
    if (!searchString || !searchString.includes("/")) return;

    const noteId = searchString.split("/").reverse()[0];

    //get note by id
    try {
      const note = await FirestoreService.getNoteById(noteId);

      if (note?.deletedAt) {
        void message.error(
          <div>
            <div>Note has been deleted. Would you like to recover it?</div>
            <div>
              <Button
                onClick={async () => {
                  message.destroy();
                  await FirestoreService.updateNote({
                    id: noteId,
                    deletedAt: null,
                  });
                  void message.success("Note recovered, attempting to navigate...");
                  await handleMotivityLinkSearch(searchString);
                }}
              >
                Recover Note
              </Button>
            </div>
          </div>,
          5
        );

        return;
      }

      //get appointment if attached to it
      if (note.appointmentId) {
        const appointment = await FirestoreService.getAppointmentById({
          id: note.appointmentId,
        });
        void message.success("Navigating to appointment");

        const startMoment = moment(appointment.startMs);

        //get week from appointment startMs
        const week = startMoment.week();

        //get year from appointment startMs
        const year = startMoment.year();

        if (year !== calWeek.year() || week !== calWeek.week()) {
          //navigate to /calendar/selectedCalendarType/year-week/appointmentId
          const redirectUrl = `${window.location.origin}/${clinic.name}/calendar/${selectedCalendarType}/${year}-${week}/${appointment.id}`;
          window.location.replace(redirectUrl);
        } else {
          //open selected appointment
          handleSelectedEvent(appointment);
          //refresh
          await fetchAppointments();
        }
      } else {
        void message.error("No appointment is matched to this note");
      }
    } catch (e) {
      void message.error("This note has not flowed to Mission Control yet");
    }
  };

  const throttledSearch = (searchString: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        performSearch(searchString, appointmentsByClient, appointmentsByTherapist);
      }, DEBOUNCE_TIME)
    );
  };

  const performSearch = (
    searchStringOverride: string,
    appointmentsByClient: ICalendarRow[],
    appointmentsByTherapist: ICalendarRow[]
  ) => {
    // Split the search string by commas, remove empty results, and trim them
    const searchStrings = searchStringOverride
      .toLowerCase()
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s);

    let unfilteredData: ICalendarRow[];
    switch (selectedCalendarType) {
      case CalendarType.CLIENTS:
        unfilteredData = appointmentsByClient;
        break;
      default:
        unfilteredData = appointmentsByTherapist;
        break;
    }

    if (!_.isEmpty(searchStrings)) {
      const filteredData = unfilteredData.filter((appt) =>
        searchStrings.some(
          (searchFor) =>
            appt.person.firstName.toLowerCase().includes(searchFor) ||
            appt.person.lastName.toLowerCase().includes(searchFor) ||
            (appt.person as IClient).alias?.toLowerCase().includes(searchFor)
        )
      );

      setDataSource(filteredData);
    } else {
      setDataSource(unfilteredData);
    }

    setTableColumnsObject(getTableColumns());
  };

  return (
    <isMatchingContext.Provider value={matchingAppointment}>
      <Row align="middle" justify="space-between">
        <Space size={"large"}>
          <CalendarNavigationButtons calWeek={calWeek} setCalWeek={setCalWeek} />
          <CalendarCompletedAppointmentsFilter
            showIncompleteAppointments={showIncompleteAppointments}
            setShowIncompleteAppointments={setShowIncompleteAppointments}
          />
          <CalendarUnmatchedNotes
            unmatchedNotes={unmatchedNotes}
            onClick={() => {
              if (!matchNotesDrawerOpenedOnce) {
                setMatchNotesDrawerOpenedOnce(true);
              }
              setMatchNotesDrawerAppointment({} as IAppointment);
              setMatchNotesDrawerClient({} as IClient);
              setIsMatchNotesDrawerOpen(true);
            }}
          />
        </Space>
        <CalendarPrimaryActionButton
          onIndirectClick={() => setIsCreateIndirectModalVisible(true)}
          onCreateClick={() => setIsCreateAppointmentModalVisible(true)}
        />
      </Row>
      <Row style={{ marginTop: 5, marginBottom: 10 }}>
        <CalendarTypeToggle
          selectedCalendarType={selectedCalendarType}
          setSelectedCalendarType={setSelectedCalendarType}
        />
        <CalendarSearchBar
          selectedCalendarType={selectedCalendarType}
          searchString={searchString}
          handleSearch={handleSearch}
        />
      </Row>
      <Row>
        {selectedCalendarType === CalendarType.LIST ? (
          <ListView
            clinicUsers={clinicUsers}
            clientsDetails={clientsDetails}
            notes={notes}
            calWeek={calWeek}
            searchString={searchString}
            showIncompleteAppointments={showIncompleteAppointments}
            setSelectedEvent={handleSelectedEvent}
            setSelectedClient={handleSelectedClient}
            setIsMatchNotesDrawerOpen={setIsMatchNotesDrawerOpen}
            refreshAll={fetchData}
          />
        ) : (
          <Table
            size="small"
            scroll={{ y: "calc(100vh - 232px)" }}
            style={{ width: "100%" }}
            columns={tableColumnsObject}
            dataSource={dataSource}
            loading={isNavigating || (isLoading && !hasLoadedOnce)}
            pagination={false}
            showSorterTooltip={false}
          />
        )}
      </Row>

      {isCreateIndirectModalVisible && (
        <CreateIndirectModal
          data={dataSource[0]}
          isVisible={isCreateIndirectModalVisible}
          hideModal={hideCreateIndirectModal}
          refreshCallback={throttledFetchAppointments}
        />
      )}
      {isCreateAppointmentModalVisible && (
        <CreateAppointmentModal
          users={clinicUsers}
          isVisible={isCreateAppointmentModalVisible}
          hideModal={hideCreateAppointmentModal}
          refreshCallback={throttledFetchAppointments}
        />
      )}

      <MatchNotesDrawer
        appointment={matchNotesDrawerAppointment}
        client={matchNotesDrawerClient}
        unmatchedNotes={unmatchedNotes}
        isOpen={isMatchNotesDrawerOpen}
        hideDrawer={hideMatchNotesDrawer}
        refreshCallback={fetchData}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setMatchingAppointment={setMatchingAppointment}
        setSelectedEvent={setSelectedEvent}
        setSelectedClient={setSelectedClient}
      />
    </isMatchingContext.Provider>
  );
};
