import { IAddress } from "@finni-health/shared";
import { Col, Row } from "antd";
import { isEmpty } from "lodash";

export const renderAddress = (address: IAddress) => {
  return !isEmpty(address) ? (
    <Col>
      <Row>{`${address?.line1 || ""}${address?.line2 || ""}`}</Row>
      <Row>{`${address?.city || ""}`}</Row>
      <Row>{`${address?.state || ""} ${address?.zipCode || ""}`}</Row>
      <Row>{`${address?.country || ""}`}</Row>
    </Col>
  ) : (
    ""
  );
};
