import _ from "lodash";
import moment from "moment-timezone";

import { IAuthorization, IClientPayer } from "../types";

export const isAuthComplete = (auth: IAuthorization) => {
  return (
    !_.isEmpty(auth) &&
    !_.isEmpty(auth.authNumber) &&
    !_.isEmpty(auth.startDate) &&
    !_.isEmpty(auth.endDate) &&
    !_.isEmpty(auth.auths)
  );
};

export const isAuthEmpty = (auth: IAuthorization) => {
  if (_.isEmpty(auth)) {
    return true;
  }
  return (
    _.isEmpty(auth.authNumber) &&
    _.isEmpty(auth.startDate) &&
    _.isEmpty(auth.endDate) &&
    _.isEmpty(auth.auths)
  );
};

export const getCurrentActiveAuth = (
  payer: IClientPayer | null,
  timeMs = moment().valueOf(),
  timeZone?: string
) => {
  if (!payer?.auth || !isAuthComplete(payer.auth)) {
    return null;
  }

  if (isAuthValid(payer.auth, timeMs, timeZone)) {
    return payer.auth;
  }
  return null;
};

export const isPayerAuthValid = (payer: IClientPayer | null, timeMs: number, timeZone?: string) => {
  if (!payer?.auth || !isAuthComplete(payer.auth)) {
    return false;
  }

  return getCurrentActiveAuth(payer, timeMs, timeZone) !== null;
};

export const getAuthExpiryDate = (payer: IClientPayer | null) => {
  if (!payer?.auth || !isAuthComplete(payer.auth)) {
    return null;
  }

  return moment(payer.auth.endDate);

  // const auth = payer.auth;
  // auth.sort((authA, authB) => {
  //   if (authA.startDate < authB.startDate) {
  //     return -1;
  //   }
  //   if (authA.startDate > authB.startDate) {
  //     return 1;
  //   }
  //   return 0;
  // });

  // let expiryDate: moment.Moment | null = null;
  // for (const auth of payer.auth) {
  //   if (expiryDate === null) {
  //     expiryDate = moment(auth.endDate);
  //     continue;
  //   }

  //   if (moment(auth.startDate) === expiryDate) {
  //     expiryDate = moment(auth.endDate);
  //   }
  // }
  // return expiryDate;
};

export const isAuthValid = (auth: IAuthorization, timeMs: number, timeZone?: string) => {
  return (
    timeMs >=
      moment
        .tz(auth.startDate, timeZone || moment.tz.guess())
        .startOf("day")
        .valueOf() &&
    timeMs <=
      moment
        .tz(auth.endDate, timeZone || moment.tz.guess())
        .endOf("day")
        .valueOf()
  );
};
