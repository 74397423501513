import { BillingCode } from "@finni-health/shared";
import { Form, Space } from "antd";
import _ from "lodash";

import { PayerAuthCodeCard } from "./PayerAuthCodeCard";

export const PayerAuthCodesRow = ({ type }: { type: "primary" | "secondary" }) => {
  const formInstance = Form.useFormInstance();
  const authCodes = Form.useWatch(["clientFile", "payers", type, "auth", "auths"], {
    form: formInstance,
    preserve: true,
  });

  const deleteAuthCode = (code: BillingCode) => {
    const auths = _.cloneDeep(
      formInstance.getFieldValue(["clientFile", "payers", type, "auth", "auths"])
    );
    delete auths[code];
    formInstance.setFieldValue(["clientFile", "payers", type, "auth", "auths"], auths);
  };

  return (
    <Space direction="horizontal">
      {Object.keys(authCodes || {}).map((code) => (
        <PayerAuthCodeCard
          key={code}
          code={code as BillingCode}
          units={authCodes[code].units}
          unitSize={authCodes[code].unitSize}
          deleteCode={deleteAuthCode}
        />
      ))}
    </Space>
  );
};
