import { BillingCode, Modifier, stringToColor } from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";

/**
 * Get the display color for a given utilization percentage
 *
 * @param utilization percentage from 0 to 100
 * @returns Color for displaying utilization
 */
export const getUtilizationColor = (utilization: number) => {
  if (utilization > 100) {
    return COLORS.RED;
  } else if (utilization >= 80) {
    return COLORS.GREEN;
  } else {
    return COLORS.YELLOW;
  }
};

export const getBillingCodeColor = (billingCode: BillingCode, modifiers?: Modifier[]) => {
  // Add a 'b' to make the colors look better, add a k if its UC to make UD look different
  return stringToColor(billingCode + "b" + (modifiers?.includes(Modifier.UC) ? "k" : ""));
};
