import { EditOutlined, ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import {
  BillingCode,
  getCurrentActiveAuth,
  IAppointment,
  IClientFile,
  IClientGuardianDetails,
  IUser,
  UserPermission,
  Weekday,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Progress,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoSchoolOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { ERROR_MESSAGE } from "../../consts";
import { getUtilizationColor } from "../../helpers/colors";
import { getDurationFromAppointments, getWeeklyHoursFromAuth } from "../../helpers/schedules";
import { userHasPermission } from "../../helpers/userPermissions";
import * as FirestoreService from "../../services/firestore";
import {
  EDIT_CLIENT_FILE_MODAL_TABLE_TABS,
  EditClientFileModal,
} from "../EditClientFileModal/EditClientFileModal";
import { IntakeStatusTag } from "../Intake/IntakeStatusTag";
import { useUserClinics } from "../UserClinicsProvider";
import { AddScheduleBlock } from "./AddScheduleBlock";
import { GhostBlock } from "./GhostBlock";
import { ScheduleBlock } from "./ScheduleBlock";
import { IGhostBlock } from "./types";

const { Text, Title } = Typography;
const { TextArea } = Input;

interface IProps {
  clientDetails: IClientGuardianDetails;
  appointments: IAppointment[];
  refreshCallback: () => Promise<IAppointment[]>;
  updateHours: (authHoursChange: number) => void;
  hideClientInitials?: boolean;
  isNavigating: boolean;
}

export const ScheduleCard: React.FC<IProps> = ({
  clientDetails,
  appointments,
  refreshCallback,
  updateHours,
  hideClientInitials,
  isNavigating,
}: IProps) => {
  const { user } = useUserClinics();
  const { client, clientFile } = clientDetails;

  const [city, setCity] = useState<string>("");
  const [tempClientFile, setTempClientFile] = useState<IClientFile>({} as IClientFile);
  const [therapists, setTherapists] = useState<IUser[]>([]);
  const [totalHours, setTotalHours] = useState<number>(0);

  const [isAddingBlock, setIsAddingBlock] = useState<Set<Weekday>>(new Set());
  const [ghostBlocks, setGhostBlocks] = useState<IGhostBlock[]>([]);
  const [isSavingNotes, setIsSavingNotes] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const currentAuth = getCurrentActiveAuth(clientFile.payers?.primary ?? null);
  const daysTillExpiry = currentAuth
    ? Math.round(moment.duration(moment(currentAuth.endDate).diff(moment())).asDays())
    : 0;

  const authHours = getWeeklyHoursFromAuth(currentAuth, BillingCode.CODE_97153);

  // Edit ClientFile Modal
  const [isEditClientFileModalVisible, setIsEditClientFileModalVisible] = useState<boolean>(false);
  const hideEditClientFileModal = () => {
    setIsEditClientFileModalVisible(false);
  };

  const fetchData = async (force = true) => {
    setIsLoading(true);
    setTempClientFile(_.cloneDeep(clientFile));
    setCity(clientFile.address.city);

    try {
      const users = await FirestoreService.getAllUsersForClinic(client.clinicId, force);
      const therapists = users.filter(
        (user: IUser) =>
          userHasPermission(user, UserPermission.RBT) ||
          userHasPermission(user, UserPermission.BCBA)
      );
      setTherapists(therapists);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(false).catch(() => {});
  }, [clientDetails]);

  useEffect(() => {
    const totalHours = getDurationFromAppointments(appointments || []).asHours();
    setTotalHours(totalHours);
  }, [appointments]);

  const handeSaveScheduleNotes = async (id: string, scheduleNotes: string) => {
    setIsSavingNotes(true);
    try {
      await FirestoreService.updateClientFile({ id, scheduleNotes });
      updateTempClientFile({ scheduleNotes });
      void message.success("Changes Saved");
    } catch (err) {
      void message.error(ERROR_MESSAGE);
    }
    setIsSavingNotes(false);
  };

  const updateTempClientFile = (source: Partial<IClientFile>) => {
    const merged = _.merge(_.cloneDeep(tempClientFile), source);
    setTempClientFile(merged);
  };

  const getTotalHoursColor = () => {
    return getUtilizationColor(Math.round((totalHours / Math.round(authHours || 1)) * 100));
  };

  const handleAddScheduleBlock = (weekday: Weekday) => {
    return () => {
      if (isAuthValid(clientFile)) {
        const newIsAddingBlock = _.cloneDeep(isAddingBlock);
        newIsAddingBlock.add(weekday);
        setIsAddingBlock(newIsAddingBlock);
      } else {
        void message.warn("Add auth start and end dates before creating a schedule");
      }
    };
  };

  const handleCancelAddScheduleBlock = (weekday: Weekday) => {
    return () => {
      const newIsAddingBlock = _.cloneDeep(isAddingBlock);
      newIsAddingBlock.delete(weekday);

      const newGhostBlocks = _.cloneDeep(ghostBlocks);
      const oldGhostBlockIndex = newGhostBlocks.findIndex(
        (ghostAppointment) => moment(ghostAppointment.startMs).format("dddd") === weekday
      );
      newGhostBlocks.splice(oldGhostBlockIndex, 1);

      setGhostBlocks(newGhostBlocks);
      setIsAddingBlock(newIsAddingBlock);
    };
  };

  const isAuthValid = (clientFile: IClientFile) =>
    getCurrentActiveAuth(clientFile.payers?.primary ?? null) !== null;

  return (
    <>
      <Card
        style={{
          background: "#fcfcfc",
          marginBottom: 20,
        }}
        bodyStyle={{ paddingTop: 10, paddingBottom: 10, paddingRight: 10 }}
      >
        {isLoading ? (
          <Row justify="center" align="middle" style={{ height: 280 }}>
            <Skeleton />
          </Row>
        ) : (
          <Row>
            <Col
              span={4}
              style={{
                paddingLeft: 0,
                paddingRight: 24,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              {!hideClientInitials && (
                <Row>
                  <Text style={{ fontSize: 18, fontWeight: 600 }}>
                    <Link
                      to={
                        userHasPermission(user, UserPermission.RBT)
                          ? "#"
                          : `/client-profile/${client.id}`
                      }
                      style={{
                        whiteSpace: "nowrap",
                        textDecoration: "none",
                      }}
                    >
                      <IoSchoolOutline
                        style={{
                          fontSize: 18,
                          marginBottom: -3,
                          marginRight: 5,
                        }}
                      />
                      {client && client.alias}
                    </Link>
                  </Text>
                </Row>
              )}
              <Row style={{ marginTop: 10 }} align="middle">
                {!isAuthValid(clientFile) ? (
                  !userHasPermission(user, UserPermission.RBT) && (
                    <Button
                      icon={
                        <ExclamationCircleFilled
                          style={{
                            fontSize: 14,
                            color: "orange",
                          }}
                        />
                      }
                      onClick={() => {
                        setIsEditClientFileModalVisible(true);
                      }}
                    >
                      Add Auth
                    </Button>
                  )
                ) : (
                  <>
                    <Col style={{ marginRight: 10 }}>
                      <Progress
                        type="dashboard"
                        gapDegree={1}
                        width={50}
                        percent={(totalHours / authHours) * 100}
                        trailColor={COLORS.GRAY}
                        strokeColor={getTotalHoursColor()}
                        format={() => (
                          <Text>
                            {`${((totalHours / Math.round(authHours || 1)) * 100).toFixed(0)}%`}
                          </Text>
                        )}
                      />
                    </Col>
                    <Col>
                      <Row>
                        <Text
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontSize: 14,
                          }}
                        >
                          {`${Math.round(totalHours * 100) / 100}/${Math.round(authHours)} hours`}
                        </Text>
                      </Row>
                      <Row>
                        {!userHasPermission(user, UserPermission.RBT) && (
                          <Button
                            size="small"
                            icon={
                              authHours === 0 ? (
                                <ExclamationCircleFilled
                                  style={{
                                    color: "orange",
                                  }}
                                />
                              ) : (
                                <EditOutlined />
                              )
                            }
                            onClick={() => {
                              setIsEditClientFileModalVisible(true);
                            }}
                          >
                            {authHours === 0 ? "Add Auth" : "Edit Auth"}
                          </Button>
                        )}
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
              {daysTillExpiry <= 30 && (
                <Row style={{ marginTop: 5 }}>
                  <Text
                    style={{
                      fontWeight: 500,
                      color: daysTillExpiry > 15 ? COLORS.LIGHT_ORANGE : COLORS.RED,
                    }}
                  >
                    {daysTillExpiry > 0
                      ? `Auth expires in ${daysTillExpiry.toFixed(0)} days`
                      : "Auth expired"}
                  </Text>
                </Row>
              )}
              <Row style={{ marginTop: 15 }}>
                <Space direction="vertical">
                  {city && (
                    <Text
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {city}
                    </Text>
                  )}
                  <IntakeStatusTag
                    style={{
                      maxWidth: 180,
                      whiteSpace: "pre-line",
                    }}
                    status={clientFile.intakeStatus}
                  />
                </Space>
              </Row>
              <Row>
                <Form.Item style={{ marginTop: 13, marginBottom: 0, width: "100%" }}>
                  <Form
                    layout="vertical"
                    onFinish={(values) =>
                      handeSaveScheduleNotes(tempClientFile.id, values.scheduleNotes)
                    }
                  >
                    <Row justify="space-between" align="middle">
                      <Text style={{ whiteSpace: "nowrap" }}>Notes:</Text>
                      <Form.Item style={{ marginBottom: 5 }} shouldUpdate>
                        {({ getFieldsValue }) => {
                          const { scheduleNotes } = getFieldsValue();
                          const formDidntChange =
                            !scheduleNotes || scheduleNotes === tempClientFile.scheduleNotes;

                          return (
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="small"
                              loading={isSavingNotes}
                              disabled={formDidntChange}
                            >
                              Save
                            </Button>
                          );
                        }}
                      </Form.Item>
                    </Row>
                    <Form.Item name="scheduleNotes" noStyle>
                      <TextArea
                        defaultValue={tempClientFile.scheduleNotes}
                        style={{ height: hideClientInitials ? 178 : 134 }}
                        disabled={!userHasPermission(user, UserPermission.ADMIN)}
                      />
                    </Form.Item>
                  </Form>
                </Form.Item>
              </Row>
            </Col>
            <Col span={20} style={{ padding: 0 }}>
              {Object.values(Weekday).map((weekday: string) => (
                <Card.Grid
                  key={weekday}
                  style={{
                    width: "14.28%",
                    height: 32,
                    textAlign: "center",
                    padding: 5,
                  }}
                  hoverable={false}
                >
                  <Title level={5}>{weekday}</Title>
                </Card.Grid>
              ))}
              {Object.values(Weekday).map((weekday) => (
                <Card.Grid
                  key={weekday}
                  style={{
                    width: "14.28%",
                    height: "calc(100% - 33px)",
                    minHeight: 248,
                    textAlign: "center",
                    padding: 5,
                  }}
                  hoverable={false}
                >
                  {appointments
                    .filter((appt) => moment(appt.startMs).format("dddd") === weekday)
                    .map((appt) => (
                      <ScheduleBlock
                        key={appt.id}
                        therapists={therapists}
                        appointment={appt}
                        clientFile={clientFile}
                        refreshCallback={refreshCallback}
                        isNavigating={isNavigating}
                        weekday={weekday}
                        appointments={appointments}
                      />
                    ))}
                  {ghostBlocks
                    .filter(
                      (ghostBlock) =>
                        moment(ghostBlock.startMs).format("dddd") !== weekday &&
                        ghostBlock.byDay.includes(weekday.slice(0, 2).toUpperCase())
                    )
                    .map((appt) => (
                      <GhostBlock
                        key={appt.userEmail}
                        therapists={therapists}
                        appointment={appt}
                        weekday={weekday}
                        appointments={appointments}
                      />
                    ))}
                  {!isAddingBlock.has(weekday)
                    ? !userHasPermission(user, UserPermission.RBT) && (
                        <Button
                          size="small"
                          style={{ width: "100%" }}
                          disabled={isNavigating}
                          onClick={handleAddScheduleBlock(weekday)}
                        >
                          <PlusOutlined />
                        </Button>
                      )
                    : !userHasPermission(user, UserPermission.RBT) && (
                        <AddScheduleBlock
                          therapists={therapists}
                          appointments={appointments}
                          clientDetails={clientDetails}
                          weekday={weekday}
                          ghostBlocks={ghostBlocks}
                          setGhostBlocks={setGhostBlocks}
                          refreshCallback={refreshCallback}
                          cancelAddScheduleBlock={handleCancelAddScheduleBlock(weekday)}
                        />
                      )}
                </Card.Grid>
              ))}
            </Col>
          </Row>
        )}
      </Card>
      {isEditClientFileModalVisible && (
        <EditClientFileModal
          hideModal={hideEditClientFileModal}
          clientGuardianDetails={
            {
              ...clientDetails,
              clientFile: tempClientFile,
            } as IClientGuardianDetails
          }
          refreshCallback={async () => {
            const newClientDetails = await FirestoreService.getClientDetailsByClientId(client.id);
            const authHoursChange =
              getWeeklyHoursFromAuth(
                getCurrentActiveAuth(newClientDetails.clientFile.payers?.primary ?? null),
                BillingCode.CODE_97153
              ) - authHours;
            await fetchData();
            updateHours(authHoursChange);
          }}
          defaultTab={EDIT_CLIENT_FILE_MODAL_TABLE_TABS.PAYERS}
        />
      )}
    </>
  );
};
