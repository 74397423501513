import { capitalizeName, IInquiry, InquiryStatus, USStateCode } from "@finni-health/shared";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";

import { ERROR_MESSAGE } from "../../consts";
import * as FirestoreService from "../../services/firestore";
import { PhoneNumberInput } from "../PhoneNumberInput";
import { IntakeStatusTag } from "./IntakeStatusTag";

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  hideModal: () => void;
  refreshCallback: () => void;
  isVisible: boolean;
  inquiry: IInquiry;
}

export const EditInquiryModal = ({ hideModal, refreshCallback, isVisible, inquiry }: Props) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [tempInquiry, setTempInquiry] = useState({} as IInquiry);

  useEffect(() => {
    setTempInquiry(inquiry);
  }, [inquiry]);

  const updateTempInquiry = (source: Partial<IInquiry>) => {
    const merged = _.merge(_.cloneDeep(tempInquiry), source);
    setTempInquiry(merged);
  };

  const handleFormOnFinish = async () => {
    setIsSaving(true);
    try {
      await FirestoreService.updateInquiry({ ...tempInquiry, id: inquiry.id });
      refreshCallback();
      hideModal();
      void message.success("Successfully updated inquiry!");
    } catch (err) {
      void message.error(ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  return (
    <Modal
      title="Update Inquiry"
      onCancel={hideModal}
      open={isVisible}
      footer={false}
      width={800}
      style={{ top: 20 }}
    >
      <Form layout="vertical" labelCol={{ span: 24 }} onFinish={handleFormOnFinish}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Status">
              <Select
                showSearch
                value={tempInquiry.intakeStatus}
                optionFilterProp="key"
                onChange={(e: InquiryStatus) =>
                  updateTempInquiry({
                    intakeStatus: InquiryStatus[e],
                  })
                }
              >
                {Object.keys(InquiryStatus).map((status) => (
                  <Select.Option key={status}>
                    <IntakeStatusTag
                      status={status as InquiryStatus}
                      style={{ maxWidth: 200, position: "relative", top: 4 }}
                    />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Hot Lead">
              <Switch
                checked={tempInquiry.isHot}
                onChange={(checked: boolean) =>
                  updateTempInquiry({
                    isHot: checked,
                  })
                }
              />
              <Text
                style={{
                  position: "relative",
                  top: 2,
                  fontSize: 16,
                  marginLeft: 5,
                }}
              >
                {tempInquiry.isHot && "🔥"}
              </Text>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status Notes">
              <TextArea
                value={tempInquiry.intakeStatusNotes}
                onChange={(e) =>
                  updateTempInquiry({
                    intakeStatusNotes: e.target.value,
                  })
                }
                autoSize={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="First Name">
              <Input
                value={tempInquiry.firstName}
                onChange={(e) =>
                  updateTempInquiry({
                    firstName: capitalizeName(e.target.value),
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name">
              <Input
                value={tempInquiry.lastName}
                onChange={(e) =>
                  updateTempInquiry({
                    lastName: capitalizeName(e.target.value),
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Address Line 1">
              <Input
                value={tempInquiry.address?.line1}
                onChange={(e) =>
                  updateTempInquiry({
                    address: {
                      ...tempInquiry.address,
                      line1: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Address Line 2">
              <Input
                value={tempInquiry.address?.line2}
                onChange={(e) =>
                  updateTempInquiry({
                    address: {
                      ...tempInquiry.address,
                      line2: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="City">
              <Input
                value={tempInquiry.address?.city}
                onChange={(e) =>
                  updateTempInquiry({
                    address: {
                      ...tempInquiry.address,
                      city: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="State">
              <Select
                value={tempInquiry.address?.state}
                onChange={(value) =>
                  updateTempInquiry({
                    address: {
                      ...tempInquiry.address,
                      state: value,
                    },
                  })
                }
              >
                {Object.values(USStateCode).map((state) => (
                  <Select.Option value={state} key={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Country">
              <Input disabled={true} value={tempInquiry.address?.country} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Zip Code">
              <Input
                value={tempInquiry.address?.zipCode}
                maxLength={10}
                onChange={(e) =>
                  updateTempInquiry({
                    address: {
                      ...tempInquiry.address,
                      zipCode: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Email">
              <Input
                value={tempInquiry.email}
                onChange={(e) =>
                  updateTempInquiry({
                    email: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Primary Phone Number">
              <PhoneNumberInput
                value={tempInquiry.phoneNumber?.primary}
                onChange={(e: any) =>
                  updateTempInquiry({
                    phoneNumber: {
                      ...tempInquiry.phoneNumber,
                      primary: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={24} style={{ marginTop: -15 }}>
          <Col span={12}>
            <Collapse ghost>
              <Collapse.Panel header="Additional Phone Numbers" key="1">
                <Form.Item label="Home Phone Number">
                  <PhoneNumberInput
                    value={tempInquiry.phoneNumber?.home}
                    onChange={(e: any) =>
                      updateTempInquiry({
                        phoneNumber: {
                          ...tempInquiry.phoneNumber,
                          home: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label="Work Phone Number">
                  <PhoneNumberInput
                    value={tempInquiry.phoneNumber?.work}
                    onChange={(e: any) =>
                      updateTempInquiry({
                        phoneNumber: {
                          ...tempInquiry.phoneNumber,
                          work: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label="Cell Phone Number">
                  <PhoneNumberInput
                    value={tempInquiry.phoneNumber?.cell}
                    onChange={(e: any) =>
                      updateTempInquiry({
                        phoneNumber: {
                          ...tempInquiry.phoneNumber,
                          cell: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
        <Row justify="end">
          <Button
            loading={isSaving}
            disabled={_.isEqual(inquiry, tempInquiry)}
            type="primary"
            htmlType="submit"
          >
            Update Inquiry
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
