import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  ActiveStatus,
  CountryCode,
  getPayerById,
  ICreateClientEndpointRequest,
  IGuardianClientDetails,
  IntakeStatus,
  PolicyHolderRelationship,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Badge, Button, Form, message, Modal, Row, Space, Tabs, Tooltip } from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { TbPlugConnected, TbPlugConnectedX } from "react-icons/tb";

import { ERROR_MESSAGE } from "../../consts";
import * as FirestoreService from "../../services/firestore";
import { sendSlackMessage } from "../../services/slack";
import { useUserClinics } from "../UserClinicsProvider";
import { BasicInfoForm } from "./BasicInfoForm";
import { ClientAvailabilityForm } from "./ClientAvailabilityForm";
import { ClientDocumentsFolders } from "./ClientDocumentsFolders";
import { ClientInfoForm } from "./ClientInfoForm";
import { PayersForm } from "./PayersForm";

const { confirm } = Modal;

export const enum EDIT_CLIENT_FILE_MODAL_TABLE_TABS {
  GUARDIAN = "Guardian",
  CLIENT = "Client",
  PAYERS = "Payers",
  DOCUMENTS = "Documents",
  AVAILABILITY = "Availability",
}

interface Props {
  clientGuardianDetails: IGuardianClientDetails;
  refreshCallback: () => void;
  hideModal: () => void;
  defaultTab?: EDIT_CLIENT_FILE_MODAL_TABLE_TABS;
}

const convertToJSON = (value: object) => {
  for (const key of Object.keys(value)) {
    if (moment.isMoment(value[key])) {
      value[key] = value[key].format("YYYY-MM-DD");
    } else if (value[key] !== null && typeof value[key] === "object") {
      value[key] = convertToJSON(value[key]);
    }
  }
  return value;
};

export const EditClientFileModal = ({
  clientGuardianDetails,
  refreshCallback,
  hideModal,
  defaultTab,
}: Props) => {
  const { clinic } = useUserClinics();
  const { client, guardian, clientFile, clientAvailability } = clientGuardianDetails;
  const [form] = Form.useForm();

  const [selectedTab, setSelectedTab] = useState<EDIT_CLIENT_FILE_MODAL_TABLE_TABS>(
    defaultTab || EDIT_CLIENT_FILE_MODAL_TABLE_TABS.GUARDIAN
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [guardianTabErrorCount, setGuardianTabErrorCount] = useState<number>(0);
  const [clientTabErrorCount, setClientTabErrorCount] = useState<number>(0);
  const [payersTabErrorCount, setPayersTabErrorCount] = useState<number>(0);

  const isSyncWithMotivityButtonDisabled =
    isSaving ||
    !client ||
    !client.alias ||
    !client.dateOfBirth ||
    !client.firstName ||
    !client.lastName ||
    !Object.keys(ActiveStatus).includes(clientFile.intakeStatus);

  useEffect(() => {
    defaultTab && setSelectedTab(defaultTab);
  }, [defaultTab]);

  const handleHideModal = () => {
    if (!_.isEqual(form.getFieldsValue(true), initialValues)) {
      return confirm({
        title: "You have unsaved changes",
        content: "Are you sure you want to continue without saving?",
        icon: <ExclamationCircleFilled />,
        okText: "Save Changes",
        cancelText: "Continue without saving",
        onOk: handleSave,
        onCancel: hideModal,
      });
    }
    hideModal();
  };

  const handleErrorSave = () => {
    return confirm({
      title: "There are incomplete fields",
      content: "Are you sure you want to continue saving?",
      icon: <ExclamationCircleFilled />,
      okText: "Save Changes",
      cancelText: "Go back",
      onOk: handleSave,
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    const values = _.cloneDeep(form.getFieldsValue(true));
    convertToJSON(values);

    try {
      if (_.isEmpty(client) && _.values(values.client).some((x) => !_.isEmpty(x))) {
        const createClientRequest: ICreateClientEndpointRequest = {
          ...values.client,
          clinicId: guardian.clinicId,
          guardianId: guardian.id,
        };
        const clientId = await FirestoreService.createClient(createClientRequest);
        await FirestoreService.updateClientFile({
          id: clientFile.id,
          clientId,
        });
      } else if (!_.isEmpty(client)) {
        await FirestoreService.updateClient({ ...values.client, id: client.id });
      }

      await FirestoreService.updateGuardian({
        ...values.guardian,
        id: guardian.id,
      });

      await FirestoreService.updateClientFile({
        ...values.clientFile,
        id: clientFile.id,
      });

      // If moving to confirming insurance, send notification in slack
      if (
        clientFile.intakeStatus !== IntakeStatus.CONFIRMING_INSURANCE &&
        values.clientFile.intakeStatus === IntakeStatus.CONFIRMING_INSURANCE
      ) {
        const slackMsg = `":party-finni: ${values.client.alias} from ${
          clinic.displayName
        } needs their insurance details verified :identification_card:\nInsurance:  ${
          values.clientFile.payers.primary?.payerId
            ? getPayerById(clinic.address.state, values.clientFile.payers.primary?.payerId || "")!
                .name
            : "undefined"
        }\nMember ID:  ${values.clientFile.payers.primary?.memberNum}"`;

        sendSlackMessage("billing-credentialing", slackMsg);
      }

      await FirestoreService.updateClientAvailability({
        ...values.clientAvailability,
        clientFileId: clientFile.id,
        clinicId: clientFile.clinicId,
        id: clientAvailability?.id,
      });

      refreshCallback();
      void message.success("Changes saved");
      hideModal();
    } catch (err) {
      console.log(err);
      void message.error(ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  const handleCreateMotivityClient = async () => {
    try {
      setIsSaving(true);
      const motivityAlias = client?.alias + " - " + client?.id;

      const fullName = [client?.firstName, client?.middleName, client?.lastName]
        .filter(Boolean)
        .join(" ");

      const clientMotivityUUID = await FirestoreService.createMotivityClient(
        motivityAlias,
        fullName,
        client?.dateOfBirth ?? ""
      );

      await FirestoreService.updateClient({
        motivityUUID: clientMotivityUUID,
        id: client!.id,
      });

      refreshCallback();
      void message.success("Synced successfully");
    } catch (err) {
      void message.error(ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  const handleFieldsChange = (fields) => {
    let guardianErrorCount = 0;
    let clientErrorCount = 0;
    let payersErrorCount = 0;

    for (const field of fields) {
      if (field.name[0] === "client") {
        if (field.errors?.length) {
          clientErrorCount++;
        }
      } else if (field.name[0] === "guardian") {
        if (field.errors?.length) {
          guardianErrorCount++;
        }
      } else if (field.name[0] === "clientFile" && field.name[1] === "payers") {
        if (field.errors?.length) {
          payersErrorCount++;
        }
      } else if (field.name[0] === "clientFile" && field.name[1] !== "payers") {
        if (field.errors?.length) {
          guardianErrorCount++;
        }
      }
    }

    setGuardianTabErrorCount(guardianErrorCount);
    setClientTabErrorCount(clientErrorCount);
    setPayersTabErrorCount(payersErrorCount);
  };

  const initialValues = {
    guardian: {
      firstName: guardian?.firstName ?? "",
      middleName: guardian?.middleName ?? "",
      lastName: guardian?.lastName ?? "",
      email: guardian?.email ?? "",
      phoneNumber: {
        primary: guardian?.phoneNumber?.primary ?? "",
        home: guardian?.phoneNumber?.home ?? "",
        work: guardian?.phoneNumber?.work ?? "",
        cell: guardian?.phoneNumber?.cell ?? "",
      },
    },
    client: {
      firstName: client?.firstName ?? "",
      middleName: client?.middleName ?? "",
      lastName: client?.lastName ?? "",
      preferredName: client?.preferredName ?? "",
      alias: client?.alias ?? "",
      dateOfBirth: client?.dateOfBirth ? moment(client.dateOfBirth) : null,
      sex: client?.sex ?? "",
    },
    clientFile: {
      address: {
        line1: clientFile?.address?.line1 ?? "",
        line2: clientFile?.address?.line2 ?? "",
        city: clientFile?.address?.city ?? "",
        state: clientFile?.address?.state ?? "",
        country: clientFile?.address?.country ?? CountryCode.US,
        zipCode: clientFile?.address?.zipCode ?? "",
      },
      addressNotes: clientFile?.addressNotes ?? "",
      intakeStatus: clientFile?.intakeStatus ?? "",
      intakeStatusNotes: clientFile?.intakeStatusNotes ?? "",
      isHot: clientFile?.isHot ?? false,
      payers: {
        primary: {
          payerId: clientFile?.payers?.primary?.payerId ?? "",
          memberNum: clientFile?.payers?.primary?.memberNum ?? "",
          groupNum: clientFile?.payers?.primary?.groupNum ?? "",
          policyHolder: {
            firstName: clientFile?.payers?.primary?.policyHolder?.firstName ?? "",
            middleName: clientFile?.payers?.primary?.policyHolder?.middleName ?? "",
            lastName: clientFile?.payers?.primary?.policyHolder?.lastName ?? "",
            dateOfBirth: clientFile?.payers?.primary?.policyHolder?.dateOfBirth
              ? moment(clientFile?.payers?.primary?.policyHolder?.dateOfBirth)
              : null,
            sex: clientFile?.payers?.primary?.policyHolder?.sex ?? "",
          },
          policyHolderRelationship:
            clientFile?.payers?.primary?.policyHolderRelationship ?? PolicyHolderRelationship.SELF,
          deductible: clientFile?.payers?.primary?.deductible ?? 0,
          copay: clientFile?.payers?.primary?.copay ?? 0,
          photoUrls: clientFile?.payers?.primary?.photoUrls ?? ["", ""],
          auth: {
            authNumber: clientFile?.payers?.primary?.auth?.authNumber ?? "",
            startDate: clientFile?.payers?.primary?.auth?.startDate
              ? moment(clientFile?.payers?.primary?.auth?.startDate)
              : null,
            endDate: clientFile?.payers?.primary?.auth?.endDate
              ? moment(clientFile?.payers?.primary?.auth?.endDate)
              : null,
            auths: clientFile?.payers?.primary?.auth?.auths ?? {},
          },
        },
        secondary: {
          payerId: clientFile?.payers?.secondary?.payerId ?? "",
          memberNum: clientFile?.payers?.secondary?.memberNum ?? "",
          groupNum: clientFile?.payers?.secondary?.groupNum ?? "",
          policyHolder: {
            firstName: clientFile?.payers?.secondary?.policyHolder?.firstName ?? "",
            middleName: clientFile?.payers?.secondary?.policyHolder?.middleName ?? "",
            lastName: clientFile?.payers?.secondary?.policyHolder?.lastName ?? "",
            dateOfBirth: clientFile?.payers?.secondary?.policyHolder?.dateOfBirth
              ? moment(clientFile?.payers?.secondary?.policyHolder?.dateOfBirth)
              : null,
            sex: clientFile?.payers?.secondary?.policyHolder?.sex ?? "",
          },
          policyHolderRelationship:
            clientFile?.payers?.secondary?.policyHolderRelationship ??
            PolicyHolderRelationship.SELF,
          deductible: clientFile?.payers?.secondary?.deductible ?? 0,
          copay: clientFile?.payers?.secondary?.copay ?? 0,
          photoUrls: clientFile?.payers?.secondary?.photoUrls ?? ["", ""],
          auth: {
            authNumber: clientFile?.payers?.secondary?.auth?.authNumber ?? "",
            startDate: clientFile?.payers?.secondary?.auth?.startDate
              ? moment(clientFile?.payers?.secondary?.auth?.startDate)
              : null,
            endDate: clientFile?.payers?.secondary?.auth?.endDate
              ? moment(clientFile?.payers?.secondary?.auth?.endDate)
              : null,
            auths: clientFile?.payers?.secondary?.auth?.auths ?? {},
          },
        },
      },
      documents: clientFile?.documents ?? [],
    },
    clientAvailability: {
      minimumHours: clientAvailability?.minimumHours ?? 0,
      recommendedHours: clientAvailability?.recommendedHours ?? 0,
      availability: clientAvailability?.availability ?? {},
    },
  };

  return (
    <Modal
      title={client && !_.isEmpty(client) ? `Edit File for ${client.alias}` : "Edit Client File"}
      onCancel={handleHideModal}
      open={true}
      footer={false}
      width={1000}
      style={{ top: 20 }}
    >
      <Form
        form={form}
        layout="vertical"
        labelCol={{ span: 24 }}
        onFieldsChange={(_, allFields) => handleFieldsChange(allFields)}
        onFinish={handleSave}
        onFinishFailed={({ errorFields }) => {
          handleFieldsChange(errorFields);
          handleErrorSave();
        }}
        initialValues={initialValues}
      >
        <Tabs
          style={{ marginTop: -15 }}
          activeKey={selectedTab}
          onChange={(key) => {
            setSelectedTab(key as EDIT_CLIENT_FILE_MODAL_TABLE_TABS);
          }}
          items={[
            {
              key: EDIT_CLIENT_FILE_MODAL_TABLE_TABS.GUARDIAN,
              label: (
                <Badge count={guardianTabErrorCount} size="small" offset={[7, 0]}>
                  {EDIT_CLIENT_FILE_MODAL_TABLE_TABS.GUARDIAN}
                </Badge>
              ),
              children: <BasicInfoForm clientFile={clientFile} />,
              forceRender: true,
            },
            {
              key: EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT,
              label: (
                <Badge count={clientTabErrorCount} size="small" offset={[7, 0]}>
                  {client?.motivityUUID ? (
                    <Tooltip title="Synced With Motivity">
                      {EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT}
                      <TbPlugConnected
                        style={{
                          fontSize: 16,
                          marginBottom: -4,
                          marginLeft: 5,
                          color: COLORS.GREEN,
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Not Synced With Motivity">
                      {EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT}
                      <TbPlugConnectedX
                        style={{
                          fontSize: 16,
                          marginBottom: -4,
                          marginLeft: 5,
                          color: COLORS.RED,
                        }}
                      />
                    </Tooltip>
                  )}
                </Badge>
              ),
              children: <ClientInfoForm />,
              forceRender: true,
            },
            {
              key: EDIT_CLIENT_FILE_MODAL_TABLE_TABS.PAYERS,
              label: (
                <Badge count={payersTabErrorCount} size="small" offset={[7, 0]}>
                  {EDIT_CLIENT_FILE_MODAL_TABLE_TABS.PAYERS}
                </Badge>
              ),
              children: (
                <PayersForm
                  client={client}
                  clientFileId={clientFile.id}
                  setSelectedTab={setSelectedTab}
                />
              ),
              forceRender: true,
            },

            {
              key: EDIT_CLIENT_FILE_MODAL_TABLE_TABS.DOCUMENTS,
              label: EDIT_CLIENT_FILE_MODAL_TABLE_TABS.DOCUMENTS,
              children: (
                <div style={{ marginBottom: 25 }}>
                  <ClientDocumentsFolders
                    isEditingDisabled={false}
                    clientFileId={clientFile.id}
                    documents={Form.useWatch(["clientFile", "documents"], { form, preserve: true })}
                    onDocumentsChange={(documents) => {
                      form.setFieldValue(["clientFile", "documents"], documents);
                    }}
                  />
                </div>
              ),
              forceRender: true,
            },
            {
              key: EDIT_CLIENT_FILE_MODAL_TABLE_TABS.AVAILABILITY,
              label: EDIT_CLIENT_FILE_MODAL_TABLE_TABS.AVAILABILITY,
              children: <ClientAvailabilityForm />,
              forceRender: true,
            },
          ]}
        />
        <Row
          style={{
            marginTop: 15,
            position: "relative",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Space>
            {!client?.motivityUUID && selectedTab === EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT && (
              <Tooltip
                title={
                  isSyncWithMotivityButtonDisabled
                    ? "Please convert this client to Active before syncing with Motivity."
                    : ""
                }
              >
                <Button
                  disabled={isSyncWithMotivityButtonDisabled}
                  onClick={handleCreateMotivityClient}
                  style={{ position: "absolute", left: 0, top: 0 }}
                  type="primary"
                >
                  {`Sync with Motivity `}
                  <TbPlugConnected style={{ marginLeft: 5, marginBottom: -2 }} />
                </Button>
              </Tooltip>
            )}
            <Button disabled={isSaving} onClick={hideModal}>
              Cancel
            </Button>
            <Button loading={isSaving} type="primary" onClick={() => form.submit()}>
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};
