import { Sex } from "@finni-health/shared";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";

import { DISPLAY_DATE_FORMAT } from "../../consts";

export const ClientInfoForm = () => (
  <>
    <Row gutter={24}>
      <Col span={3}>
        <Form.Item label="Alias" name={["client", "alias"]}>
          <Input tabIndex={1} />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item
          label="First Name"
          name={["client", "firstName"]}
          rules={[{ required: true, message: "First Name is required" }]}
        >
          <Input tabIndex={1} />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item label="Middle Name" name={["client", "middleName"]}>
          <Input tabIndex={1} />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item
          label="Last Name"
          name={["client", "lastName"]}
          rules={[{ required: true, message: "Last Name is required" }]}
        >
          <Input tabIndex={2} />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item label="Preferred Name" name={["client", "preferredName"]}>
          <Input tabIndex={1} />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          label="Sex at Birth"
          name={["client", "sex"]}
          rules={[{ required: true, message: "Sex is required" }]}
        >
          <Select tabIndex={3}>
            <Select.Option value={Sex.FEMALE}>{Sex.FEMALE}</Select.Option>;
            <Select.Option value={Sex.MALE}>{Sex.MALE}</Select.Option>;
            <Select.Option value={Sex.UNKNOWN}>{Sex.UNKNOWN}</Select.Option>;
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Date of Birth"
          name={["client", "dateOfBirth"]}
          rules={[{ required: true, message: "Date of Birth is required" }]}
        >
          <DatePicker
            tabIndex={4}
            format={DISPLAY_DATE_FORMAT}
            autoComplete="off"
            disabledDate={(curr) => curr && curr > moment().startOf("day")}
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);
