import { z } from "zod";

import { IClient, Sex } from "../types";

export const createClientEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
  guardianId: z.string().min(1),
  firstName: z.string().min(1),
  middleName: z.string(),
  lastName: z.string().min(1),
  alias: z.string(),
  dateOfBirth: z.string().pipe(z.coerce.date()),
  sex: z.nativeEnum(Sex),
});

export type ICreateClientEndpointRequest = z.input<typeof createClientEndpointRequestSchema>;

export interface IDeleteClientEndpointRequest {
  id: string;
}

export type IUpdateClientEndpointRequest = Partial<IClient> & Pick<IClient, "id">;
