import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  ActiveStatus,
  AppointmentLocation,
  AttendeeStatus,
  getAppointmentNameFromBilling,
  getAppointmentsByPayer,
  IAppointment,
  ICalendarCreateAppointmentEndpointRequest,
  ICalendarUpdateAppointmentEndpointRequest,
  IClientGuardianDetails,
  IntakeStatus,
  IUser,
  UserPermission,
} from "@finni-health/shared";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";

import {
  AM_HOURS,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  ERROR_MESSAGE_APPOINTMENTS,
} from "../../consts";
import {
  addUntilRrule,
  getAppointmentLocationText,
  IAppointmentOptions,
  parseRRule,
} from "../../helpers/appointments";
import * as FirestoreService from "../../services/firestore";
import { StatusTagText } from "../Intake/IntakeStatusTag";
import { useUserClinics } from "../UserClinicsProvider";
import { RecurrenceByDayPicker } from "./RecurrenceByDayPicker";

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  appointment: IAppointment;
  users: IUser[];
  isOpen: boolean;
  refreshCallback: () => void;
  hideModal: () => void;
}

export interface IEditAppointmentFormValues {
  clientId: string;
  apptName: string;
  date: Moment;
  start: Moment;
  end: Moment;
  location: AppointmentLocation;
  attendeeEmails: string[];
  description: string;
}

export const EditAppointmentModal = ({
  appointment,
  users,
  isOpen,
  refreshCallback,
  hideModal,
}: Props) => {
  const { clinic, user } = useUserClinics();

  const [byDay, setByDay] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [form] = Form.useForm<IEditAppointmentFormValues>();
  const clientId = Form.useWatch("clientId", form);
  const apptName = Form.useWatch("apptName", form);
  const start = Form.useWatch("start", form);
  const end = Form.useWatch("end", form);
  const location = Form.useWatch("location", form);
  const createMeet = Form.useWatch<boolean | undefined>("createMeet", form);

  const [selectedClientGuardianDetails, setSelectedClientGuardianDetails] =
    useState<IClientGuardianDetails | null>(null);
  const [availableAppointments, setAvailableAppointments] = useState<IAppointmentOptions>(
    {} as IAppointmentOptions
  );
  const [clientGuardianDetails, setClientGuardianDetails] = useState<
    Record<string, IClientGuardianDetails>
  >({});
  const [isLoadingAvailableAppointments, setIsLoadingAvailableAppointments] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!_.isEmpty(user) && isOpen) {
      fetchData()
        .then(() => {
          setApptNameFromAppointment();
        })
        .catch(() => {});
    }
  }, [user, appointment, isOpen]);

  useEffect(() => {
    const cgd = clientGuardianDetails[clientId];
    if (cgd && !_.isEmpty(cgd)) {
      selectClientGuardianDetails(cgd);
    }
  }, [clientId, clientGuardianDetails]);

  useEffect(() => {
    if (location === AppointmentLocation.TELEHEALTH) {
      form.setFieldValue(
        "createMeet",
        !!appointment.meetsLink || appointment.location !== AppointmentLocation.TELEHEALTH
      );
    }
  }, [location]);

  const fetchData = async () => {
    setIsLoading(true);

    const clientGuardianDetails = (
      await FirestoreService.getClientsGuardiansDetailsByClinicId(user.clinicId)
    ).filter(
      (clientGuardianDetails) =>
        !Object.values([IntakeStatus.CHURNED, IntakeStatus.UNQUALIFIED]).includes(
          clientGuardianDetails.clientFile.intakeStatus
        )
    );

    if (appointment.id.includes("_")) {
      const mainAppointment = await FirestoreService.getAppointmentById({
        id: appointment.id.split("_")[0],
      });

      const byDay =
        parseRRule(mainAppointment.rrule || "").find((rule) => rule.key === "BYDAY")?.value || "";

      setByDay(byDay);
    }

    const clientGuardianDetailsMap = _.keyBy(clientGuardianDetails, (cgd) => cgd.client.id);
    setClientGuardianDetails(clientGuardianDetailsMap);
    setIsLoading(false);
  };

  const setApptNameFromAppointment = () => {
    if (_.isEmpty(appointment)) {
      return;
    }

    form.setFieldValue(
      "apptName",
      getAppointmentNameFromBilling(
        clinic.address.state,
        appointment.billingCode,
        appointment.modifiers
      )
    );
  };

  const selectClientGuardianDetails = (clientGuardianDetails: IClientGuardianDetails) => {
    setIsLoadingAvailableAppointments(true);

    try {
      const availableAppointments = getAppointmentsByPayer(
        clinic.address.state,
        clientGuardianDetails.clientFile.payers.primary?.payerId || ""
      );

      setApptNameFromAppointment();
      setAvailableAppointments(availableAppointments);
    } catch (err) {
      console.error(
        `Could not find payer with ID ${clientGuardianDetails.clientFile.payers.primary?.payerId}`
      );
      form.setFieldValue("apptName", undefined);
      setAvailableAppointments({} as IAppointmentOptions);
    }

    setSelectedClientGuardianDetails(clientGuardianDetails);
    setIsLoadingAvailableAppointments(false);
  };

  const getDisabledEndTimeHours = () => {
    let hours: number[] = [];
    if (start.hour() >= 12) {
      hours = [...AM_HOURS];
    }
    for (let i = hours.length; i < start.hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledEndTimeMinutes = (selectedHour: number) => {
    const minutes: number[] = [];
    if (selectedHour === start.hour()) {
      for (let i = 0; i <= start.minute(); i += 15) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const handleUpdateRecurringAppointment = async () => {
    try {
      const values = form.getFieldsValue();

      if (!values.start || !values.end) {
        return void message.error("Please enter start and end times");
      }

      setIsSaving(true);
      // Get the main instance containing RRULE and let it end at this instance
      const mainAppointment = await FirestoreService.getAppointmentById({
        id: appointment.id.split("_")[0],
      });
      // If this is the first instance, just delete it and replace
      if (mainAppointment.startMs === appointment.startMs) {
        await FirestoreService.deleteAppointment({ id: mainAppointment.id });
      } else {
        const appointmentToEnd = addUntilRrule(mainAppointment, appointment);
        await FirestoreService.updateAppointment(appointmentToEnd);
      }

      // Create new appointment containing the changes to start today
      const originalStart = moment(appointment.startMs);
      const startDatetime = moment(values.start)
        .set({
          year: originalStart.year(),
          month: originalStart.month(),
          date: originalStart.date(),
        })
        .day(originalStart.format("dddd"));
      const endDatetime = moment(values.end)
        .set({
          year: originalStart.year(),
          month: originalStart.month(),
          date: originalStart.date(),
        })
        .day(originalStart.format("dddd"));

      // End time rolled over to the next day in UTC
      if (endDatetime.isBefore(startDatetime)) {
        endDatetime.add(1, "days");
      }

      const startMs = startDatetime.valueOf();
      const endMs = endDatetime.valueOf();

      if (startMs >= endMs) {
        return void message.error("Start time must be before end time");
      }

      const createAppointmentRequest: ICalendarCreateAppointmentEndpointRequest = {
        clinicId: appointment.clinicId,
        clientId: appointment.clientId,
        attendeeEmails: values.attendeeEmails || [],
        billingCode: availableAppointments[values.apptName].billingCode,
        modifiers: availableAppointments[values.apptName].modifiers,
        location: values.location,
        summary: getAppointmentSummary(),
        description: values.description,
        startMs,
        endMs,
        timeZone: moment.tz.guess(),
        hasMeets: createMeet || !!appointment.meetsLink,
        noteId: appointment.noteId,
        rrule: `RRULE:FREQ=WEEKLY;BYDAY=${byDay};`,
      };

      await FirestoreService.createAppointment(createAppointmentRequest);

      form.resetFields();
      void message.success("Changes saved");
      refreshCallback();
      hideModal();
    } catch (err) {
      void message.error(ERROR_MESSAGE_APPOINTMENTS);
      console.error(err);
    }

    setIsSaving(false);
  };

  const handleUpdateAppointment = async (appointmentId: string) => {
    try {
      const values = form.getFieldsValue();

      if (!values.start || !values.end) {
        return void message.error("Please enter start and end times");
      }

      if (byDay !== "") return handleUpdateRecurringAppointment();

      let date;
      if (appointment.id.includes("_") && !appointmentId.includes("_")) {
        const originalAppt = await FirestoreService.getAppointmentById({
          id: appointmentId,
        });
        date = {
          year: moment(originalAppt.startMs).year(),
          month: moment(originalAppt.startMs).month(),
          date: moment(originalAppt.startMs).date(),
        };
      } else {
        date = {
          year: values.date.year(),
          month: values.date.month(),
          date: values.date.date(),
        };
      }

      const startMs = values.start.set(date).valueOf();
      const endMs = values.end.set(date).valueOf();

      if (startMs >= endMs) {
        return void message.error("Start time must be before end time");
      }

      setIsSaving(true);

      const updateAppointmentRequest: ICalendarUpdateAppointmentEndpointRequest = {
        id: appointmentId,
        clinicId: user.clinicId,
        clientId: values.clientId,
        attendees: values.attendeeEmails
          .map((email) => email.trim())
          .filter((email) => email)
          .map((email) => ({
            email,
            status:
              appointment.attendees.find((attendee) => attendee.email === email)?.status ||
              AttendeeStatus.NEEDS_ACTION,
          })),
        billingCode: availableAppointments[values.apptName].billingCode,
        modifiers: availableAppointments[values.apptName].modifiers,
        location: values.location,
        summary: getAppointmentSummary(),
        description: values.description,
        startMs,
        endMs,
        meetsLink: appointment.meetsLink,
        createMeet,
      };

      await FirestoreService.updateAppointment(updateAppointmentRequest);

      form.resetFields();
      void message.success("Changes saved");
      refreshCallback();
      hideModal();
    } catch (err) {
      void message.error(ERROR_MESSAGE_APPOINTMENTS);
      console.error(err);
    }

    setIsSaving(false);
  };

  const hideModalIfNotSaving = () => {
    if (!isSaving) {
      hideModal();
    }
  };

  const handleSetByDay = (byDay: string) => {
    setByDay(byDay);
  };

  const getAppointmentSummary = () => {
    return `${
      selectedClientGuardianDetails ? `${selectedClientGuardianDetails.client.alias} ` : ""
    }${!_.isEmpty(apptName) ? apptName : "appointment"} ${
      location === AppointmentLocation.TELEHEALTH
        ? "(remote)"
        : location === AppointmentLocation.OFFICE
        ? "(office)"
        : "(in-person)"
    }`;
  };

  return (
    <>
      <Modal
        title={`Edit ${getAppointmentSummary()}`}
        closable={!isSaving}
        footer={null}
        destroyOnClose={true}
        onCancel={hideModalIfNotSaving}
        open={isOpen}
        width={500}
        bodyStyle={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Form
          form={form}
          layout="vertical"
          labelWrap={false}
          labelCol={{ span: 24 }}
          onFinish={() => handleUpdateAppointment(appointment.id)}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="clientId"
                initialValue={appointment.clientId}
                rules={[{ required: true, message: "Please select a client" }]}
              >
                <Select
                  placeholder="Client"
                  showSearch
                  allowClear
                  optionFilterProp="key"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {Object.values(clientGuardianDetails)
                    .sort((a, b) =>
                      (Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                        Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus)) ||
                      (!Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                        !Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus))
                        ? a.client.firstName.localeCompare(b.client.firstName)
                        : Object.keys(ActiveStatus).includes(a.clientFile.intakeStatus) &&
                          !Object.keys(ActiveStatus).includes(b.clientFile.intakeStatus)
                        ? -1
                        : 1
                    )
                    .map((cgd) => (
                      <Select.Option
                        key={`${StatusTagText[cgd.clientFile.intakeStatus]} ${cgd.client.alias}${
                          cgd.client.firstName
                        }${cgd.client.lastName}`}
                        value={cgd.client.id}
                      >
                        <Row>
                          <Text type="secondary" style={{ marginRight: 5 }}>
                            {cgd.client.alias}
                          </Text>
                          <Text strong>{`${cgd.client.firstName} ${cgd.client.lastName}`}</Text>
                        </Row>
                        <Row style={{ marginTop: -7 }}>
                          <Text type="secondary" style={{ fontSize: 9 }}>
                            {StatusTagText[cgd.clientFile.intakeStatus]}
                          </Text>
                        </Row>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="attendeeEmails"
                initialValue={appointment.attendees.map((attendee) => attendee.email)}
                rules={[{ required: true, message: "Please add guests" }]}
              >
                <Select
                  placeholder="Guests"
                  mode="tags"
                  optionFilterProp="key"
                  allowClear
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {users
                    .sort((a, b) => a.firstName.localeCompare(b.firstName))
                    .map((user) => (
                      <Select.Option
                        key={`${
                          user.permissions.includes(UserPermission.BCBA)
                            ? UserPermission.BCBA
                            : UserPermission.RBT
                        } ${user.firstName}${user.lastName}${user.email}`}
                        value={user.email}
                      >
                        <Row>
                          <Text type="secondary" style={{ marginRight: 5 }}>
                            {user.permissions.includes(UserPermission.BCBA)
                              ? UserPermission.BCBA
                              : UserPermission.RBT}
                          </Text>
                          <Text strong>{`${user.firstName} ${user.lastName}`}</Text>
                        </Row>
                        <Row style={{ marginTop: -7 }}>
                          <Text type="secondary" style={{ fontSize: 10 }}>{`${user.email}`}</Text>
                        </Row>
                      </Select.Option>
                    ))}
                  {Object.values(clientGuardianDetails)
                    .sort((a, b) => a.guardian.firstName.localeCompare(b.guardian.firstName))
                    .map((cgd) => (
                      <Select.Option
                        key={`Guardian ${cgd.client.alias || "No client"}${cgd.guardian.firstName}${
                          cgd.guardian.lastName
                        }${cgd.guardian.email}`}
                        value={cgd.guardian.email}
                      >
                        <Row>
                          <Text type="secondary" style={{ marginRight: 5 }}>
                            {`Guardian - ${cgd.client.alias || "No client"}`}
                          </Text>
                          <Text strong>{`${cgd.guardian.email}`}</Text>
                        </Row>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                name="apptName"
                rules={[{ required: true, message: "Please select a service" }]}
              >
                <Select
                  loading={isLoadingAvailableAppointments || isLoading}
                  disabled={
                    isLoadingAvailableAppointments || _.isEmpty(availableAppointments) || isLoading
                  }
                  placeholder={
                    selectedClientGuardianDetails && _.isEmpty(availableAppointments)
                      ? "No available services"
                      : "Service"
                  }
                  showSearch
                  allowClear
                  optionFilterProp="key"
                >
                  {Object.keys(availableAppointments).map((apptName) => (
                    <Select.Option
                      key={`${availableAppointments[apptName].billingCode}${availableAppointments[
                        apptName
                      ].modifiers.join("")} ${apptName}`}
                      value={apptName}
                    >
                      <Text type="secondary" style={{ marginRight: 5 }}>
                        {availableAppointments[apptName].billingCode}
                        {availableAppointments[apptName].modifiers.join(" ")}
                      </Text>
                      <Text strong>{apptName}</Text>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="location"
                initialValue={appointment.location}
                rules={[{ required: true, message: "Please select a location" }]}
              >
                <Select
                  placeholder="Location"
                  showSearch
                  allowClear
                  optionFilterProp="key"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {Object.values(AppointmentLocation).map((location) => (
                    <Select.Option key={`${getAppointmentLocationText(location)}`} value={location}>
                      {getAppointmentLocationText(location)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* allow creation of links only when no prior link exists. 
            We also do not support removing links, so once created, 
            the checkbox will be disabled and set to true. */}
            {location === AppointmentLocation.TELEHEALTH && (
              <Col span={12}>
                <Row>
                  <Form.Item name="createMeet">
                    <Switch
                      defaultChecked={
                        !!appointment.meetsLink ||
                        appointment.location !== AppointmentLocation.TELEHEALTH
                      }
                      disabled={!!appointment.meetsLink}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      loading={isLoading}
                    />
                  </Form.Item>
                  <Text style={{ marginTop: 6, marginLeft: 8 }}>Google Meet</Text>
                </Row>
              </Col>
            )}
          </Row>
          <Row gutter={5}>
            <Col span={8}>
              <Form.Item
                name="date"
                initialValue={moment(appointment.startMs)}
                rules={[{ required: true, message: "Please enter a session date" }]}
              >
                <DatePicker
                  placeholder="Date"
                  style={{ padding: 0 }}
                  autoComplete="off"
                  allowClear={false}
                  bordered={false}
                  format={DISPLAY_DATE_FORMAT}
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="start"
                initialValue={moment(appointment.startMs)}
                rules={[{ required: true, message: "Please enter a start time" }]}
              >
                <TimePicker
                  placeholder="Start"
                  autoComplete="off"
                  allowClear={false}
                  bordered={false}
                  format={DISPLAY_TIME_FORMAT}
                  minuteStep={15}
                  onSelect={(time) => {
                    form.setFieldValue("start", time);
                    if (_.isEmpty(end) || end.isBefore(time)) {
                      form.setFieldValue("end", time.clone().add(30, "minutes"));
                    }
                  }}
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="end"
                initialValue={moment(appointment.endMs)}
                rules={[{ required: true, message: "Please enter an end time" }]}
              >
                <TimePicker
                  disabled={_.isEmpty(start) || isLoading}
                  placeholder="End"
                  autoComplete="off"
                  allowClear={false}
                  bordered={false}
                  format={DISPLAY_TIME_FORMAT}
                  minuteStep={15}
                  disabledTime={() => ({
                    disabledHours: getDisabledEndTimeHours,
                    disabledMinutes: getDisabledEndTimeMinutes,
                  })}
                  onSelect={(time) => {
                    form.setFieldValue("end", time);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ width: "100%", marginTop: -15 }}>
            <Form.Item style={{ width: "100%" }}>
              <div style={{ marginBottom: 10 }}>
                <Text type="secondary">Repeat every</Text>
              </div>
              <RecurrenceByDayPicker byDay={byDay} setByDay={handleSetByDay} loading={isLoading} />
            </Form.Item>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="description" initialValue={appointment.description}>
                <TextArea
                  placeholder="Description"
                  allowClear
                  autoSize={{ minRows: 2 }}
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={isSaving}
                style={{ float: "right" }}
                disabled={isLoading}
              >
                {`Save${byDay !== "" ? " (this and following)" : ""}`}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
