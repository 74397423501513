import { red } from "@ant-design/colors";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { IInvite } from "@finni-health/shared";
import {
  Button,
  message,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";

import * as FirestoreService from "../../services/firestore";
import { useUserClinics } from "../UserClinicsProvider";
import { CreateInviteModal } from "./CreateInviteModal";

const { Link: LinkText } = Typography;

export const InviteTable: React.FC = () => {
  const { user, refresh } = useUserClinics();

  const [invites, setInvites] = useState<IInvite[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //Create invite modal
  const [isCreateInviteModalVisible, setIsCreateInviteModalVisible] = useState<boolean>(false);
  const hideCreateInviteModal = () => {
    setIsCreateInviteModalVisible(false);
  };

  useEffect(() => {
    fetchInvites().catch(() => {});
  }, [user]);

  const fetchInvites = async () => {
    setIsLoading(true);
    let invites = await FirestoreService.getAllInvitesForClinic(user.clinicId);

    invites = invites.map((invite) => {
      const name = `${invite.firstName} ${invite.lastName}`;
      return {
        ...invite,
        name: name,
        key: invite.id,
      };
    });

    setInvites(invites);
    setIsLoading(false);
  };

  const deleteInvite = async (invite: IInvite) => {
    setIsLoading(true);
    try {
      await FirestoreService.deleteInvite({
        id: invite.id,
      });
      void message.success(`Deleted invite ${invite.firstName} ${invite.lastName}`);
      await fetchInvites();
      await refresh();
    } catch (err) {
      void message.error("Couldn't delete invite, please try again");
      console.error(err);
    }
    setIsLoading(false);
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Invite Code",
      dataIndex: "id",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Email Address",
      dataIndex: "email",
    },
    {
      width: 15,
      align: "center" as any,
      render: (_text: string, row: IInvite) => (
        <Row wrap={false} justify="center" align="middle">
          <Space size="large">
            <Popconfirm
              title={`Are you sure you want to delete invite for ${row.firstName} ${row.lastName}?`}
              placement="bottomRight"
              onConfirm={() => deleteInvite(row)}
              okText="Yes"
              cancelText="No"
            >
              <div>
                <Tooltip title="Delete Invite">
                  <LinkText>
                    <DeleteOutlined
                      style={{
                        color: red.primary,
                        fontSize: 16,
                      }}
                    />
                  </LinkText>
                </Tooltip>
              </div>
            </Popconfirm>
          </Space>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Space
          size="large"
          style={{
            marginBottom: "15px",
          }}
        >
          <Statistic loading={isLoading} title="Total Pending Invites" value={invites.length} />
        </Space>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={() => {
            setIsCreateInviteModalVisible(true);
          }}
        >
          <PlusOutlined style={{ marginLeft: -3 }} />
          Invite
        </Button>
      </Row>
      <Table columns={tableColumns} dataSource={invites} loading={isLoading} />
      <CreateInviteModal
        hideModal={hideCreateInviteModal}
        isVisible={isCreateInviteModalVisible}
        user={user}
        refreshCallback={async () => {
          await fetchInvites();
          await refresh();
        }}
      />
    </>
  );
};
