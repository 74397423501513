import { intersection, isEqual } from "lodash";

import {
  BillingCode,
  BillingLevel,
  IAppointmentConfig,
  IAppointmentType,
  IPayer,
  Modifier,
  USStateCode,
} from "../types";
import { BASE_APPOINTMENTS, STATE_APPOINTMENTS } from "./configs/appointments";
import { MAIN_BILLING_CONFIG } from "./configs/billingConfig";

/**
 * Gets all IPayers for the given USStateCode
 *
 * @param state the USStateCode to get IPayers for
 * @returns All IPayers for the given USStateCode
 */
export const getAllPayersForState = (state: USStateCode) => {
  return MAIN_BILLING_CONFIG[state] || [];
};

export const getPayer = (state: USStateCode, payerId: string) => {
  const payers = getAllPayersForState(state);
  for (const payer of payers) {
    if (payer.payerId === payerId) {
      return payer;
    }
  }
  return null;
};

/**
 * Gets all appointments for a given payer
 *
 * @param state the USStateCode to get appointments for
 * @param payerId the payerID of the payer
 * @returns all appointments that are supported by the payer
 */
export const getAppointmentsByPayer = (state: USStateCode, payerId: string) => {
  const result: IAppointmentType = {};

  const appointments = Object.assign({}, BASE_APPOINTMENTS, STATE_APPOINTMENTS[state]);

  const payer = getPayerById(state, payerId);
  if (payer === null) {
    return result;
  }

  const billingCodes = payer.codes;
  for (const name of Object.keys(appointments)) {
    const apptBillingCode = appointments[name as keyof typeof appointments]?.billingCode;
    if (apptBillingCode && Object.prototype.hasOwnProperty.call(billingCodes, apptBillingCode)) {
      result[name as keyof IAppointmentType] = appointments[name as keyof IAppointmentType];
    }
  }
  return result;
};

export const getAppointmentNameFromBilling = (
  state: USStateCode,
  billingCode: BillingCode,
  modifiers: Modifier[] = []
) => {
  const isMatched = (
    appointment: IAppointmentConfig,
    billingCode: BillingCode,
    modifiers: Modifier[]
  ) => {
    if (
      appointment.billingCode === billingCode &&
      isEqual(intersection(appointment.modifiers, modifiers), appointment.modifiers)
    ) {
      return true;
    }
    return false;
  };

  for (const name of Object.keys(BASE_APPOINTMENTS)) {
    if (isMatched(BASE_APPOINTMENTS[name as keyof IAppointmentType]!, billingCode, modifiers)) {
      return name;
    }
  }

  for (const name of Object.keys(STATE_APPOINTMENTS[state] || [])) {
    if (
      isMatched(STATE_APPOINTMENTS[state]![name as keyof IAppointmentType]!, billingCode, modifiers)
    ) {
      return name;
    }
  }
  return "";
};

/**
 * Get the IPayer with the given payerId
 *
 * @param state the state of the payer
 * @param payerId the federal payerId to find
 * @returns The IPayer with the given payerId
 */
export const getPayerById = (state: USStateCode, payerId: string | null) => {
  if (!payerId) {
    return null;
  }

  for (const payer of MAIN_BILLING_CONFIG[state] || []) {
    if (payer.payerId === payerId) {
      return payer;
    }
  }
  return null;
};

export const getPayerCode = (payer: IPayer, billingCode: BillingCode, modifiers: Modifier[]) => {
  const codes = payer.codes[billingCode];
  if (!codes) {
    return null;
  }
  // it's a payer code
  if ("rates" in codes) {
    return codes;
  }

  // it's a map of modifiers to payer code
  for (const modifier of modifiers) {
    if (codes[modifier] !== undefined) {
      return codes[modifier];
    }
  }

  // retreiving the payer code with no modifiers
  return codes[""];
};

export const isPayerAuthRequired = (
  state: USStateCode,
  payerId: string,
  billingCode: BillingCode,
  modifiers: Modifier[]
) => {
  const payer = getPayer(state, payerId);
  if (payer === null) {
    return null;
  }
  const payerCode = getPayerCode(payer, billingCode, modifiers);
  if (!payerCode) {
    return null;
  }
  return payerCode.authRequired;
};

/**
 * Calculates session units rounded to the nearest .25
 *
 * @param startMs the start of session in millis
 * @param endMs the end of session in millis
 * @param billingCode the BillingCode to use
 * @param billingLevel the BillingLevel to use
 * @param payer The IPayer
 * @returns Session units rounded to the nearest .25
 */
export const calculateUnits = (
  startMs: number,
  endMs: number,
  billingCode: BillingCode,
  billingLevel: BillingLevel,
  payer: IPayer,
  modifiers: Modifier[]
): number => {
  const payerCode = getPayerCode(payer, billingCode, modifiers);
  if (payerCode === null) {
    throw new Error(
      `getPayerCode(payer=${payer}, billingCode=${billingCode}, modifiers=${modifiers}) returned null`
    );
  }
  const unitSize = getPayerCode(payer, billingCode, modifiers)?.unitSize;

  if (unitSize === undefined) {
    throw new Error(
      `Failed to get unit size for ${billingCode} ${billingLevel}, modifiers: ${modifiers} with payer ${payer.payerId}`
    );
  }

  if (unitSize === null) {
    return 1;
  }

  const duration = endMs - startMs;
  const durationInMinutes = duration / 1000 / 60;
  const units = durationInMinutes / unitSize;

  // round to the nearest .25
  return units + 0.25 / 2 - ((units + 0.25 / 2) % 0.25);
};

/**
 * Calculates ession units rounded to the nearest whole number
 *
 * @param startMs the start of session in millis
 * @param endMs the end of session in millis
 * @param billingCode the BillingCode to use
 * @param billingLevel the BillingLevel to use
 * @param payer The IPayer
 * @returns Session units rounded to the nearest whole number
 */
export const calculateRoundedUnits = (
  startMs: number,
  endMs: number,
  billingCode: BillingCode,
  billingLevel: BillingLevel,
  payer: IPayer,
  modifiers: Modifier[]
): number => {
  const units = calculateUnits(startMs, endMs, billingCode, billingLevel, payer, modifiers);

  if (billingCode === BillingCode.CODE_T1026) {
    return Math.ceil(units);
  } else {
    return Math.round(units);
  }
};

/**
 * Calculates session charge in cents
 *
 * @param startMs the start of session in millis
 * @param endMs the end of session in millis
 * @param billingCode the BillingCode to use
 * @param billingLevel the BillingLevel to use
 * @param payer The IPayer
 * @returns Session charge in cents
 */
export const calculateChargeCents = (
  startMs: number,
  endMs: number,
  billingCode: BillingCode,
  billingLevel: BillingLevel,
  payer: IPayer,
  modifiers: Modifier[]
): number => {
  //get units
  let units: number;

  // We bill T1026 using codes in increments of .25 cus NM is special 😎
  if (billingCode === BillingCode.CODE_T1026) {
    units = calculateUnits(startMs, endMs, billingCode, billingLevel, payer, modifiers);
  } else {
    units = calculateRoundedUnits(startMs, endMs, billingCode, billingLevel, payer, modifiers);
  }

  return calculateChargeCentsFromUnits(units, billingCode, billingLevel, payer, modifiers);
};

/**
 * Calculates session charge in cents using units
 *
 * @param units the units to use
 * @param billingCode the BillingCode to use
 * @param billingLevel the BillingLevel to use
 * @param payer the IPayer to use
 * @returns Session charge in cents
 */
export const calculateChargeCentsFromUnits = (
  units: number,
  billingCode: BillingCode,
  billingLevel: BillingLevel,
  payer: IPayer,
  modifiers: Modifier[]
) => {
  const payerCode = getPayerCode(payer, billingCode, modifiers);
  if (!payerCode) {
    throw new Error(
      `getPayerCode(payer=${payer}, billingCode=${billingCode}, modifiers=${modifiers}) returned null`
    );
  }
  // get rate
  const rateCents = payerCode.rates[billingLevel];

  if (rateCents === undefined || rateCents === 0) {
    throw new Error(
      `Failed to get rates for ${billingCode} ${billingLevel} with payer ${payer.payerId}`
    );
  }

  return units * rateCents;
};
