import "./css/Header.css";

import { DownOutlined, LogoutOutlined, UserOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { IUser, UserPermission } from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Layout,
  List,
  message,
  Popconfirm,
  Popover,
  Row,
  Select,
  Switch,
  Typography,
} from "antd";
import { getAuth, signOut } from "firebase/auth";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { US_TIMEZONES } from "../consts";
import { userHasPermission } from "../helpers/userPermissions";
import app from "../services/firebase";
import * as FirestoreService from "../services/firestore";
import { disablePII, enablePII, setSpectatorMode, spectatorMode } from "../services/middleware";
import { ImpactCard } from "./ImpactCard";
import { useUserClinics } from "./UserClinicsProvider";

const { Text, Link: LinkText } = Typography;

export const Header: React.FC = () => {
  const { user, clinic, availableClinics, refresh } = useUserClinics();
  const navigate = useNavigate();

  const { clinicName } = useParams();

  const [users, setUsers] = useState<IUser[]>([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const [isSwitchingClinic, setIsSwitchingClinic] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedSpectator, setSelectedSpectator] = useState("");
  const defaultTimezone = moment.tz.zone(moment.tz.guess())?.abbr(moment().utcOffset());
  const [currentTimezone, setTimezone] = useState(defaultTimezone);

  useEffect(() => {
    fetchClinics().catch(() => {});
  }, [user, clinic]);

  const fetchClinics = async () => {
    setIsLoading(true);
    if (userHasPermission(user, UserPermission.SUPERADMIN)) {
      const allUsers = await FirestoreService.getAllUsersForClinic(user.clinicId, true);
      setUsers(allUsers);
      setIsLoading(false);
    }
  };

  const handleSwitchClinic = (newClinicId: string) => {
    setIsSwitchingClinic(true);

    const clinic = availableClinics.find((clinic) => clinic.id === newClinicId);
    const pathName = window.location.pathname;
    const queryParams = window.location.search;

    const link =
      pathName.replace(clinicName!, clinic!.name) + (queryParams !== "" ? "?" + queryParams : "");

    navigate(link);
    setIsSwitchingClinic(false);
  };

  const hidePopover = () => {
    setIsPopoverVisible(false);
  };

  const redirectToHome = () => {
    navigate("/");
  };

  const handleLogout = () => {
    signOut(getAuth(app))
      .then(() => {
        void message.info("Successfully logged out");
        navigate("/login");
      })
      .catch(() => {
        void message.info("Failed to log out");
      });
  };

  const profilePopover = () => {
    return (
      <div style={{ width: 150 }}>
        <List style={{ marginTop: -12, marginBottom: -12 }}>
          <List.Item>
            <div
              style={{
                fontSize: "6pt",
                position: "absolute",
                zIndex: 200,
                left: 8,
                marginTop: -30,
                background: "white",
                padding: "0 3px",
              }}
            >
              Timezone
            </div>
            <Select
              style={{ width: "100%" }}
              dropdownMatchSelectWidth={false}
              value={currentTimezone}
              placeholder="Timezone"
              onChange={(value) => {
                moment.tz.setDefault(US_TIMEZONES[value]);
                setTimezone(value);
                return refresh();
              }}
            >
              {Object.entries(US_TIMEZONES).map(([label]) => (
                <Select.Option key={label} value={label}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </List.Item>
          <List.Item>
            <Col>
              {(userHasPermission(user, UserPermission.SUPERADMIN) || spectatorMode) && (
                <Switch
                  defaultChecked={false}
                  onChange={(checked) => {
                    checked ? disablePII() : enablePII();
                    return refresh();
                  }}
                  style={{ width: "100%" }}
                  unCheckedChildren="Hide PII"
                  checkedChildren="Unhide PII"
                />
              )}
              {userHasPermission(user, UserPermission.SUPERADMIN) && !isLoading && (
                <Popconfirm
                  title={
                    <Select
                      placeholder="Select RBT"
                      onChange={(value) => setSelectedSpectator(value)}
                      style={{ width: 200 }}
                    >
                      {Object.values(users).map(
                        (user) =>
                          userHasPermission(user, UserPermission.RBT) && (
                            <Select.Option value={user.id} key={user.id}>
                              {user.firstName + " " + user.lastName}
                            </Select.Option>
                          )
                      )}
                    </Select>
                  }
                  onConfirm={() => {
                    setSpectatorMode(selectedSpectator);
                    navigate("../schedules", { replace: true });
                    return refresh();
                  }}
                  okText="Spectate"
                  cancelText="Cancel"
                  placement="bottomLeft"
                >
                  <Button
                    style={{
                      width: "100%",
                      marginTop: 10,
                      padding: 2,
                      borderColor: COLORS.PRIMARY,
                      color: COLORS.PRIMARY,
                    }}
                  >
                    <UserSwitchOutlined /> Spectate
                  </Button>
                </Popconfirm>
              )}
              {spectatorMode && (
                <Button
                  style={{
                    width: "100%",
                    marginTop: 10,
                    padding: 4,
                    borderColor: COLORS.PRIMARY,
                    color: COLORS.PRIMARY,
                  }}
                  onClick={() => {
                    setSpectatorMode("");
                    navigate("clients", { replace: true });
                    return refresh();
                  }}
                >
                  End Spectate
                </Button>
              )}
            </Col>
          </List.Item>
          <List.Item>
            <Link to="profile" onClick={hidePopover}>
              <UserOutlined /> Profile
            </Link>
          </List.Item>
          <List.Item>
            <Popconfirm
              title={`Are you sure you want to logout?`}
              onConfirm={handleLogout}
              okText="Yes"
              cancelText="No"
              placement="bottomLeft"
            >
              <LinkText type="danger">
                <LogoutOutlined /> Logout
              </LinkText>
            </Popconfirm>
          </List.Item>
        </List>
      </div>
    );
  };

  return (
    <Layout.Header className="header">
      <Row align="middle" justify="space-between" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img src="/logo.svg" height={15} onClick={redirectToHome} />
          {!_.isEmpty(user) && (
            <>
              <Divider
                type="vertical"
                style={{
                  borderLeft: "1px solid rgba(0, 0, 0)",
                  height: "22px",
                }}
              />
              {!_.isEmpty(availableClinics) ? (
                <Select
                  loading={isSwitchingClinic}
                  defaultValue={user.clinicId}
                  bordered={false}
                  style={{ width: 200, position: "relative", left: -12 }}
                  onSelect={(clinicId) => {
                    return handleSwitchClinic(clinicId);
                  }}
                >
                  {availableClinics.map((clinic) => (
                    <Select.Option value={clinic.id} key={clinic.id}>
                      {clinic.displayName}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Text>{clinic.displayName}</Text>
              )}
            </>
          )}
        </div>
        <div>
          {!_.isEmpty(user) && (
            <Row align="middle">
              {userHasPermission(user, UserPermission.RBT) && <ImpactCard user={user} />}
              <Popover
                placement="bottomRight"
                content={profilePopover()}
                trigger="click"
                open={isPopoverVisible}
                onOpenChange={setIsPopoverVisible}
              >
                <Button type="text">
                  {spectatorMode && (
                    <UserSwitchOutlined
                      style={{
                        color: COLORS.RED,
                      }}
                    />
                  )}
                  <Text
                    style={{
                      color: COLORS.RED,
                      marginRight: 10,
                    }}
                  >{`${spectatorMode ? "Spectating: " : ""}`}</Text>
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: COLORS.PRIMARY,
                    }}
                  >
                    {!_.isEmpty(user) && user.firstName[0] + user.lastName[0]}
                  </Avatar>
                  <Text
                    style={{
                      fontSize: 12,
                      marginLeft: 5,
                    }}
                  >
                    {`${user.firstName} ${user.lastName} | ${currentTimezone}`}
                  </Text>
                  <DownOutlined
                    style={{
                      color: "#afafaf",
                      position: "relative",
                      top: isPopoverVisible ? -2 : 0,
                      rotate: isPopoverVisible ? "180deg" : "0deg",
                      transition: "all 0.3s ease",
                    }}
                  />
                </Button>
              </Popover>
            </Row>
          )}
        </div>
      </Row>
    </Layout.Header>
  );
};
