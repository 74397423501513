import { getAuth, signOut } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import app from "../services/firebase";
import { Loading } from "./Loading";

export const Logout = () => {
  const navigate = useNavigate();

  const logout = () => {
    signOut(getAuth(app))
      .then(() => {
        console.log("Logged out");
      })
      .catch((error) => {
        console.log(error);
      });
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    logout();
  }, []);

  return <Loading />;
};
