import { AttendeeStatus } from "@finni-health/shared";
import { Badge, Tag } from "antd";
import React from "react";

interface IProps {
  email: string;
  status: AttendeeStatus;
  maxWidth?: number;
}

export const AttendeeTag: React.FC<IProps> = ({ email, status, maxWidth = 135 }: IProps) => {
  return (
    <Tag
      key={email}
      style={{
        marginBottom: 2,
        fontSize: 11,
        maxWidth,
        overflowX: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Badge
        status={
          status === AttendeeStatus.ACCEPTED
            ? "success"
            : status === AttendeeStatus.TENTATIVE
            ? "warning"
            : status === AttendeeStatus.DECLINED
            ? "error"
            : "default"
        }
        style={{ marginRight: 3 }}
      />
      {email}
    </Tag>
  );
};
