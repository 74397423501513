import { encodeHash, IClientGuardianDetails } from "@finni-health/shared";
import { Breadcrumb, Button, Col, Row, Space, Tabs, Tag, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoSchoolOutline } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ClientIntakeForm } from "../components/ClientIntakeForm/ClientIntakeForm";
import { ClientPayerCard } from "../components/ClientPayerCard";
import { ClientAvailabilityTab } from "../components/ClientProfile/ClientAvailabilityTab/ClientAvailabilityTab";
import { ClientDetailsSection } from "../components/ClientProfile/ClientDetailsSection";
import * as FirestoreService from "../services/firestore";
import { Loading } from "./Loading";

const { Title, Link: LinkText } = Typography;

export enum TABLE_TABS {
  OVERVIEW = "Overview",
  INTAKE = "Intake Form",
  AVAILABILITY = "Availability",
}

export const ClientProfile: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [clientGuardianDetails, setClientGuardianDetails] = useState<IClientGuardianDetails | null>(
    null
  );
  const [selectedTab, setSelectedTab] = useState<TABLE_TABS>(TABLE_TABS.OVERVIEW);

  const [parentPortalLink, setParentPortalLink] = useState<string>("");

  const fetchClientDetails = async (clientId: string) => {
    setIsLoading(true);
    const clientDetails = await FirestoreService.getClientGuardianDetailsByClientId(clientId);
    setClientGuardianDetails(clientDetails);
    setParentPortalLink(
      `${process.env.REACT_APP_PARENT_PORTAL_BASE_URL}/schedule/${encodeHash(
        clientDetails.clientFile.id
      )}`
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (!clientId) {
      return;
    }
    fetchClientDetails(clientId).catch(() => {});
  }, [clientId]);

  if (isLoading || !clientGuardianDetails) {
    return <Loading />;
  }

  return (
    <>
      <Row align="middle">
        <Space size="middle">
          <Button onClick={() => navigate(-1)}>
            <LinkText>Back</LinkText>
          </Button>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"../clients"}>Clients</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{`${clientGuardianDetails.client.firstName} ${clientGuardianDetails.client.lastName}`}</Breadcrumb.Item>
          </Breadcrumb>
        </Space>
      </Row>
      <Row style={{ marginTop: 25 }} align="middle">
        <Title level={2}>{`${clientGuardianDetails.client.firstName} ${
          clientGuardianDetails.client.lastName
        }, ${moment().diff(clientGuardianDetails.client.dateOfBirth, "years")}, ${
          clientGuardianDetails.client.sex
        }`}</Title>
        <Tag
          color={"orange"}
          style={{
            position: "relative",
            left: 10,
            bottom: 9,
            fontSize: 15,
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          <IoSchoolOutline style={{ fontSize: 17, marginRight: 3, marginBottom: -3 }} />
          Client
        </Tag>
      </Row>
      <Tabs
        type="card"
        defaultActiveKey={selectedTab.valueOf()}
        onChange={(key) => {
          setSelectedTab(key as TABLE_TABS);
        }}
        items={Object.values(TABLE_TABS).map((tab) => ({
          key: tab,
          label: tab,
        }))}
      />
      {selectedTab === TABLE_TABS.OVERVIEW && (
        <>
          <ClientDetailsSection
            clientGuardianDetails={clientGuardianDetails}
            clientRefreshCallback={() => fetchClientDetails(clientGuardianDetails.client.id)}
          />
          {!_.isEmpty(clientGuardianDetails.clientFile?.payers) && (
            <>
              <Title level={4}>Payers</Title>
              <Row
                justify={
                  clientGuardianDetails.clientFile?.payers.secondary ? "space-around" : "start"
                }
              >
                {clientGuardianDetails.clientFile?.payers.primary && (
                  <Col>
                    <ClientPayerCard
                      client={clientGuardianDetails.client}
                      clientPayer={clientGuardianDetails.clientFile?.payers.primary}
                    />
                  </Col>
                )}
                {clientGuardianDetails.clientFile?.payers.secondary && (
                  <Col>
                    <ClientPayerCard
                      client={clientGuardianDetails.client}
                      clientPayer={clientGuardianDetails.clientFile?.payers.secondary}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
        </>
      )}
      {selectedTab === TABLE_TABS.INTAKE && (
        <ClientIntakeForm
          clientFile={clientGuardianDetails.clientFile}
          refreshCallback={() => fetchClientDetails(clientGuardianDetails.clientFile.clientId)}
        />
      )}
      {selectedTab === TABLE_TABS.AVAILABILITY && (
        <ClientAvailabilityTab
          client={clientGuardianDetails.client}
          clientFile={clientGuardianDetails.clientFile}
          clientAvailability={clientGuardianDetails.clientAvailability}
          parentPortalLink={parentPortalLink}
          refreshCallback={() => fetchClientDetails(clientGuardianDetails.client.id)}
        />
      )}
    </>
  );
};
