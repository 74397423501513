import { ArrowRightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  calculateImpactBasisPointsFromStats,
  EventType,
  IAppointment,
  ICalendarRow,
  ICompletedAppointment,
  ICreateIndirectEndpointRequest,
  IIndirect,
  IndirectReason,
  IndirectStatus,
  UserPermission,
  WeekDays,
} from "@finni-health/shared";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import { Moment } from "moment";
import * as momentTz from "moment-timezone";
import { useEffect, useState } from "react";

import { AM_HOURS, DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, ERROR_MESSAGE } from "../../consts";
import { getIndirectPromptText, getReasonText } from "../../helpers/appointments";
import { getUserHoursForWeek } from "../../helpers/schedules";
import { userHasPermission } from "../../helpers/userPermissions";
import * as FirestoreService from "../../services/firestore";
import { ImpactCard } from "../ImpactCard";
import { useUserClinics } from "../UserClinicsProvider";

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  refreshCallback: () => void;
  hideModal: () => void;
  isVisible: boolean;
  data: ICalendarRow;
}

interface ICreateIndirectFormValues {
  date: Moment;
  start: Moment;
  end: Moment;
  attendeeEmails: string[];
  indirectReason: IndirectReason;
  description: string;
}

export const CreateIndirectModal = ({ refreshCallback, hideModal, isVisible, data }: Props) => {
  const { user } = useUserClinics();

  const [directScheduled, setDirectScheduled] = useState<number>(0);
  const [indirectScheduled, setIndirectScheduled] = useState<number>(0);

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = () => {
    const allAppts = Object.values(WeekDays).flatMap((day) => data[day]);

    const appointments = allAppts.filter(
      (appt) => appt.eventType === EventType.APPOINTMENT
    ) as IAppointment[];
    const completedAppointments = allAppts.filter(
      (appt) => appt.eventType === EventType.COMPLETED
    ) as ICompletedAppointment[];
    const indirects = allAppts.filter(
      (appt) => appt.eventType === EventType.INDIRECT
    ) as IIndirect[];

    const { directScheduledHours, indirectScheduledHours } = getUserHoursForWeek(
      user,
      appointments,
      completedAppointments,
      indirects
    );

    setDirectScheduled(directScheduledHours);
    setIndirectScheduled(indirectScheduledHours);
  };

  const [form] = Form.useForm<ICreateIndirectFormValues>();
  const start = Form.useWatch("start", form);
  const end = Form.useWatch("end", form);
  const indirectReason = Form.useWatch("indirectReason", form);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  //impact visualization
  const [updatedUser, setUpdatedUser] = useState(user);
  const [displayImpact, setDisplayImpact] = useState<boolean>(false);

  const getDisabledEndTimeHours = () => {
    let hours: number[] = [];
    if (start.hour() >= 12) {
      hours = [...AM_HOURS];
    }
    for (let i = hours.length; i < start.hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledEndTimeMinutes = (selectedHour: number) => {
    const minutes: number[] = [];
    if (selectedHour === start.hour()) {
      for (let i = 0; i <= start.minute(); i += 15) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const saveIndirect = async () => {
    setIsSaving(true);
    try {
      const values = form.getFieldsValue();

      const date = {
        year: values.date.year(),
        month: values.date.month(),
        date: values.date.date(),
      };

      const percentOfIndirectHours =
        (indirectScheduled / (indirectScheduled + directScheduled)) * 100;

      const request: ICreateIndirectEndpointRequest = {
        clinicId: user.clinicId,
        attendeeEmails: values.attendeeEmails.map((email) => email.trim()),
        summary: getSummary(),
        indirectReason: values.indirectReason,
        description: values.description,
        timeZone: momentTz.tz.guess(),
        startMs: values.start.set(date).valueOf(),
        endMs: values.end.set(date).valueOf(),
        status:
          percentOfIndirectHours < 20 || (user.stats && user.stats.indirectMinutes < 40 * 60)
            ? IndirectStatus.APPROVED
            : IndirectStatus.PENDING,
      };

      if (request.endMs <= request.startMs) {
        throw new Error("End time must be after start time");
      }

      await FirestoreService.createIndirect(request);

      form.resetFields();
      void message.success("Indirect logged");
      refreshCallback();
      hideModal();
    } catch (err) {
      void message.error(ERROR_MESSAGE);
      console.error(err);
    }
    setIsSaving(false);
  };

  const getSummary = () => {
    return `Indirect - ${user.firstName} ${user.lastName[0]}.`;
  };

  const hideModalIfNotSaving = () => {
    if (!isSaving) {
      hideModal();
    }
  };

  useEffect(() => {
    const updatedUser = _.cloneDeep(user);
    if (start && end && updatedUser.stats && end > start) {
      updatedUser.stats.indirectMinutes += (end.valueOf() - start.valueOf()) / 60000;
      updatedUser.stats.impactBasisPoints = calculateImpactBasisPointsFromStats(updatedUser.stats);
      setUpdatedUser(updatedUser);
      setDisplayImpact(true);
    } else {
      setDisplayImpact(false);
    }
  }, [start, end]);

  return (
    <Modal
      title={`New ${getSummary()}`}
      closable={!isSaving}
      footer={null}
      destroyOnClose={true}
      onCancel={hideModalIfNotSaving}
      open={isVisible}
      width={500}
      bodyStyle={{ paddingLeft: 40, paddingRight: 40 }}
    >
      <Form
        form={form}
        layout="vertical"
        labelWrap={false}
        labelCol={{ span: 24 }}
        onFinish={saveIndirect}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="attendeeEmails" initialValue={[user.email]}>
              <Select placeholder="Guests" mode="tags" disabled>
                <Select.Option key={user.email} value={user.email}>
                  <Text strong>{`${user.firstName} ${user.lastName}`}</Text>
                  <br />
                  <Text>{`${user.email}`}</Text>
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name="date" rules={[{ required: true, message: "Please enter a date" }]}>
              <DatePicker
                placeholder="Date"
                style={{ padding: 0 }}
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="start"
              rules={[{ required: true, message: "Please enter a start time" }]}
            >
              <TimePicker
                placeholder="Start"
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_TIME_FORMAT}
                minuteStep={15}
                onSelect={(time) => {
                  form.setFieldValue("start", time);
                  if (_.isEmpty(end) || end.isBefore(time)) {
                    form.setFieldValue("end", time.clone().add(30, "minutes"));
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="end" rules={[{ required: true, message: "Please enter an end time" }]}>
              <TimePicker
                disabled={_.isEmpty(start)}
                placeholder="End"
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_TIME_FORMAT}
                minuteStep={15}
                disabledTime={() => ({
                  disabledHours: getDisabledEndTimeHours,
                  disabledMinutes: getDisabledEndTimeMinutes,
                })}
                onSelect={(time) => {
                  form.setFieldValue("end", time);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="indirectReason"
              rules={[
                {
                  required: true,
                  message: "Please select an indirect reason",
                },
              ]}
            >
              <Select
                placeholder="Select a reason"
                style={{ width: "100%" }}
                onChange={(value) => {
                  form.setFieldValue("indirectReason", value);
                }}
              >
                {Object.entries(IndirectReason).map(([key, value]) => (
                  <Select.Option key={key} value={key}>{`${getReasonText(value)}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="description"
              rules={[
                {
                  validator: (_, value) =>
                    value && value.length >= 50
                      ? Promise.resolve()
                      : Promise.reject(new Error("Description must be at least 50 characters")),
                },
              ]}
            >
              <TextArea
                placeholder={getIndirectPromptText(indirectReason)}
                allowClear
                autoSize={{ minRows: 2 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Row style={{ display: "flex", marginTop: 15 }}>
              {userHasPermission(user, UserPermission.RBT) && displayImpact && (
                <>
                  <ImpactCard user={user} disablePopover={true} disableCountUp={true} />
                  <ArrowRightOutlined style={{ marginLeft: 8, marginTop: 5, marginRight: 4 }} />
                  <ImpactCard
                    user={updatedUser}
                    disablePopover={true}
                    disableCountUp={true}
                    disableTitle={true}
                  />
                  <Tooltip
                    placement="topRight"
                    title={`Research has shown that direct appointments yield better outcomes for your learners. Replacing direct appointments with indirect appointments may have an adverse effect on the learning outcomes of your learners.`}
                  >
                    <InfoCircleOutlined style={{ marginLeft: 8, marginTop: 0, marginRight: 4 }} />
                  </Tooltip>
                </>
              )}
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={isSaving}
                style={{ marginRight: "0", marginLeft: "auto" }}
              >
                Create
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
