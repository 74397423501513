import { Button, Form, Row, Typography } from "antd";
import React from "react";

import { SignUpStates } from "../../consts";
import { PreferredTransportSelector } from "../UserProfile/PreferredTransport";

const { Title, Text } = Typography;

type IProps = {
  setInquiryStatus: (value: any) => void;
  updateInquiry: (source: any) => void;
  invite: any;
};

export const SelectPreferredTransport: React.FC<IProps> = ({
  setInquiryStatus,
  updateInquiry,
}: IProps) => {
  const submitPreferredTransport = () => {
    setInquiryStatus(SignUpStates.COMPLETE);
  };

  return (
    <>
      <Title
        style={{ fontFamily: "Reckless Neue" }}
      >{`What is your preferred mode of transport?`}</Title>
      <Text
        style={{
          display: "block",
          marginBottom: 30,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        {`We'll use this information to schedule clients that are closer to you.`}
      </Text>
      <Form onFinish={() => submitPreferredTransport()}>
        <Row justify="center">
          <PreferredTransportSelector
            style={{ width: "300px", marginBottom: 25 }}
            onChange={(e) => updateInquiry({ preferredTransport: e.target.value })}
          />
        </Row>
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          style={{
            height: 44,
          }}
        >
          Continue
        </Button>
      </Form>
    </>
  );
};
