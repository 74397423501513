import { IPayerConfig, USStateCode } from "../../types";
import { COLORADO_PAYERS } from "./colorado";
import { CONNECTICUT_PAYERS } from "./connecticut";
import { GEORGIA_PAYERS } from "./georgia";
import { ILLINOIS_PAYERS } from "./illinois";
import { NEW_MEXICO_PAYERS } from "./newMexico";
import { NEW_YORK_PAYERS } from "./newYork";
import { VIRGINIA_PAYERS } from "./virginia";

export const MAIN_BILLING_CONFIG: IPayerConfig = {
  [USStateCode.CO]: COLORADO_PAYERS,
  [USStateCode.CT]: CONNECTICUT_PAYERS,
  [USStateCode.GA]: GEORGIA_PAYERS,
  [USStateCode.IL]: ILLINOIS_PAYERS,
  [USStateCode.NM]: NEW_MEXICO_PAYERS,
  [USStateCode.NY]: NEW_YORK_PAYERS,
  [USStateCode.VA]: VIRGINIA_PAYERS,
};
