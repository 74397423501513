import { Button, Form, Input, Typography } from "antd";
import React, { useState } from "react";

import { SignUpStates } from "../../consts";

const { Title, Text } = Typography;

type IProps = {
  setInquiryStatus: (value: any) => void;
  setInviteSelectedIndex: (value: any) => void;
  invites: any;
};

export const EnterInviteCode: React.FC<IProps> = ({
  setInquiryStatus,
  setInviteSelectedIndex,
  invites,
}: IProps) => {
  const [inviteCode, setInviteCode] = useState("");
  const [error, setError] = useState<boolean>(false);

  const submitInviteCode = () => {
    const index = invites.findIndex((invite: any) => invite.id === inviteCode);
    if (index !== -1) {
      setInviteSelectedIndex(index);
      setInquiryStatus(SignUpStates.INVITE_FOUND);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Title style={{ fontFamily: "Reckless Neue" }}>{`Please enter your invite code`}</Title>
      <Text
        style={{
          display: "block",
          marginBottom: 30,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        If you have an invite code, please enter it here. Otherwise ask your practice owner to send
        you an invite code.
      </Text>
      <Form onFinish={() => submitInviteCode()}>
        <Form.Item
          name="inviteCode"
          rules={[
            {
              required: true,
              message: `Please enter your invite code`,
            },
          ]}
          style={{ display: "inline-block", width: 200, margin: "auto" }}
        >
          <Input
            name="inviteCode"
            onChange={(value) => {
              setInviteCode(value.target.value);
              setError(false);
            }}
            style={{
              textAlign: "center",
              borderRadius: 50,
              padding: 10,
            }}
            placeholder="7XThA9P3bAAPZDsk8h3h"
          />
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          style={{
            marginLeft: 20,
            height: 44,
          }}
        >
          Validate Invite
        </Button>
        <Text type="danger" style={{ display: "block", marginTop: 10 }}>
          {error ? `Invalid invite code. Please try another invite code` : ""}
        </Text>
      </Form>
    </>
  );
};
