import { StopOutlined } from "@ant-design/icons";
import {
  AppointmentLocation,
  AttendeeStatus,
  BillingCode,
  IAppointment,
  ICalendarCreateAppointmentEndpointRequest,
  IClientDetails,
  Weekday,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Badge, Button, Card, message, Row, Select, Skeleton, TimePicker, Tooltip } from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { RiSaveFill } from "react-icons/ri";

import { DISPLAY_TIME_FORMAT, ERROR_MESSAGE } from "../../consts";
import { getAppointmentLocationText, getAppointmentSummary } from "../../helpers/appointments";
import {
  availabilityToColor,
  getTherapistsByAvailability,
  IUserWithAvailability,
} from "../../helpers/schedules";
import * as FirestoreService from "../../services/firestore";
import { RecurrenceByDayPicker } from "../Calendar/RecurrenceByDayPicker";
import { useUserClinics } from "../UserClinicsProvider";
import { IGhostBlock } from "./types";

interface IProps {
  therapists: IUserWithAvailability[];
  clientDetails: IClientDetails;
  appointments: IAppointment[];
  weekday: Weekday;
  ghostBlocks: IGhostBlock[];
  setGhostBlocks: (val: IGhostBlock[]) => void;
  refreshCallback: () => Promise<IAppointment[]>;
  cancelAddScheduleBlock: () => void;
}

const DEFAULT_TIME = moment("8:00am", DISPLAY_TIME_FORMAT);

export const AddScheduleBlock: React.FC<IProps> = ({
  therapists,
  clientDetails,
  weekday,
  appointments,
  ghostBlocks,
  setGhostBlocks,
  refreshCallback,
  cancelAddScheduleBlock,
}: IProps) => {
  const { clinic } = useUserClinics();
  const [tempAppointment, setTempAppointment] = useState<IAppointment>({
    location: AppointmentLocation.HOME,
  } as IAppointment);

  const weekdayByDay = weekday.slice(0, 2).toUpperCase();

  const [byDay, setByDay] = useState<string>(weekdayByDay);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const yearWeek = document.URL.split("/").slice(-1)[0];
  const year = +yearWeek.split("-")[0];
  const week = +yearWeek.split("-")[1];

  const sortedTherapists = getTherapistsByAvailability(
    therapists,
    appointments,
    {
      ...tempAppointment,
      endMs: moment(tempAppointment.endMs).year(year).week(week).day(weekday).valueOf(),
      startMs: moment(tempAppointment.startMs).year(year).week(week).day(weekday).valueOf(),
    },
    weekday
  );

  useEffect(() => {
    handleUpdateGhostBlocks();
  }, [tempAppointment, byDay]);

  const handleDiscardChanges = () => {
    setTempAppointment({} as IAppointment);
    cancelAddScheduleBlock();
  };

  const handleSetByDay = (byDay: string) => {
    let newByDay = byDay;
    if (!byDay.includes(weekdayByDay)) {
      newByDay = byDay.concat(weekdayByDay);
    }
    setByDay(newByDay);
  };

  const handleUpdateGhostBlocks = () => {
    const startDatetime = moment(tempAppointment.startMs).year(year).week(week).day(weekday);
    const endDatetime = moment(tempAppointment.endMs).year(year).week(week).day(weekday);

    const ghostBlock: IGhostBlock = {
      userEmail: !_.isEmpty(tempAppointment.attendees) ? tempAppointment.attendees[0].email : "",
      startMs: startDatetime.valueOf(),
      endMs: endDatetime.valueOf(),
      location: tempAppointment.location,
      byDay,
    };

    const newGhostBlocks = _.cloneDeep(ghostBlocks);
    const oldGhostBlockIndex = newGhostBlocks.findIndex((ghostAppointment) =>
      moment(ghostAppointment.startMs).isSame(startDatetime, "day")
    );

    if (byDay === "") {
      newGhostBlocks.splice(oldGhostBlockIndex, 1);
    } else if (oldGhostBlockIndex !== -1) {
      newGhostBlocks[oldGhostBlockIndex] = ghostBlock;
    } else {
      newGhostBlocks.push(ghostBlock);
    }

    setGhostBlocks(newGhostBlocks);
  };

  const handleSave = async () => {
    if (!tempAppointment.startMs || !tempAppointment.endMs) {
      void message.error("Please enter start and end times");
      return;
    }

    if (tempAppointment.startMs > tempAppointment.endMs) {
      void message.error("Start time must be before end time");
      return;
    }

    setIsLoading(true);

    for (const dayCode of byDay.split(",")) {
      // Get the day of the week from the day code
      const weekday = moment().day(dayCode).format("dddd");

      const startDatetime = moment(tempAppointment.startMs).year(year).week(week).day(weekday);
      const endDatetime = moment(tempAppointment.endMs).year(year).week(week).day(weekday);

      // End time rolled over to the next day in UTC
      if (endDatetime.isBefore(startDatetime)) {
        endDatetime.add(1, "days");
      }

      const startMs = startDatetime.valueOf();
      const endMs = endDatetime.valueOf();

      const createAppointmentRequest: ICalendarCreateAppointmentEndpointRequest = {
        clinicId: clientDetails.client.clinicId,
        clientId: clientDetails.clientFile.clientId,
        attendeeEmails: _.isEmpty(tempAppointment.attendees)
          ? []
          : [tempAppointment.attendees[0].email],
        billingCode: BillingCode.CODE_97153,
        modifiers: [],
        location: tempAppointment.location,
        summary: getAppointmentSummary(
          clinic,
          clientDetails.client,
          BillingCode.CODE_97153,
          tempAppointment.location
        ),
        description: "Ongoing session created by Mission Control.",
        startMs,
        endMs,
        timeZone: moment.tz.guess(),
        noteId: null,
        rrule: `RRULE:FREQ=WEEKLY;BYDAY=${weekday.slice(0, 2).toUpperCase()};`,
      };

      try {
        await FirestoreService.createAppointment(createAppointmentRequest);
      } catch (err) {
        void message.error(ERROR_MESSAGE);
        break;
      }
    }

    await refreshCallback();
    cancelAddScheduleBlock();
    void message.success("Changes saved");
    setIsLoading(false);
  };

  const handleSelectTherapist = (email: string) => {
    const newAppointment = _.cloneDeep(tempAppointment);
    newAppointment.attendees = [{ email, status: AttendeeStatus.ACCEPTED }];
    setTempAppointment(newAppointment);
  };

  const handleTimeSelect = (key: string) => {
    return (datetime: Moment | null) => {
      if (
        key === "startMs" &&
        datetime &&
        tempAppointment.endMs &&
        datetime?.valueOf() >= tempAppointment.endMs
      ) {
        return message.warn("Start time can't be after end");
      }
      if (key === "endMs" && datetime && tempAppointment.startMs >= datetime?.valueOf()) {
        return message.warn("End time can't be before start");
      }

      if (datetime) {
        const newAppointment = _.cloneDeep(tempAppointment);
        _.set(newAppointment, key, datetime.valueOf());
        setTempAppointment(newAppointment);
      }
    };
  };

  const handleSelectLocation = (location: AppointmentLocation) => {
    const newAppointment = _.cloneDeep(tempAppointment);
    setTempAppointment({ ...newAppointment, location });
  };

  return (
    <Card
      size="small"
      bodyStyle={{ paddingTop: 5, paddingBottom: 7 }}
      style={{
        marginBottom: 3,
        borderColor: COLORS.PRIMARY,
      }}
    >
      {isLoading ? (
        <Row justify="center" align="middle" style={{ height: 135 }}>
          <Skeleton />
        </Row>
      ) : (
        <>
          <Row justify="space-between">
            <Tooltip title="Cancel">
              <Button
                icon={<StopOutlined style={{ fontSize: 12 }} />}
                size="small"
                type="text"
                onClick={handleDiscardChanges}
              />
            </Tooltip>
            <Tooltip title="Save">
              <Button
                icon={
                  <RiSaveFill
                    style={{
                      position: "relative",
                      top: 3,
                      fontSize: 16,
                      color: COLORS.PRIMARY,
                    }}
                  />
                }
                size="small"
                type="text"
                onClick={handleSave}
              />
            </Tooltip>
          </Row>
          <Row style={{ width: "100%", marginBottom: 5 }}>
            <Select
              allowClear
              showSearch
              placeholder="Therapist"
              optionFilterProp="key"
              style={{ width: "100%" }}
              suffixIcon={null}
              onSelect={handleSelectTherapist}
            >
              {sortedTherapists.map((therapist) => (
                <Select.Option
                  key={`${therapist.firstName} ${therapist.lastName}`}
                  value={therapist.email}
                >
                  <Badge
                    color={availabilityToColor(therapist.available)}
                    text={`${therapist.firstName} ${therapist.lastName}`}
                  />
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Row style={{ width: "100%", marginBottom: 5 }}>
            <TimePicker
              hideDisabledOptions
              placeholder="Start"
              format={DISPLAY_TIME_FORMAT}
              minuteStep={15}
              use12Hours
              showNow={false}
              defaultOpenValue={DEFAULT_TIME}
              value={tempAppointment.startMs ? moment(tempAppointment.startMs) : undefined}
              style={{ width: "100%" }}
              onSelect={handleTimeSelect("startMs")}
              onChange={handleTimeSelect("startMs")}
            />
          </Row>
          <Row style={{ width: "100%", marginBottom: 5 }}>
            <TimePicker
              hideDisabledOptions
              placeholder="End"
              format={DISPLAY_TIME_FORMAT}
              minuteStep={15}
              use12Hours
              showNow={false}
              defaultOpenValue={
                tempAppointment.startMs
                  ? moment(tempAppointment.startMs).add(1, "hour")
                  : DEFAULT_TIME
              }
              value={tempAppointment.endMs ? moment(tempAppointment.endMs) : undefined}
              style={{ width: "100%" }}
              onSelect={handleTimeSelect("endMs")}
              onChange={handleTimeSelect("endMs")}
            />
          </Row>
          <Row style={{ width: "100%", marginBottom: 5 }}>
            <Select
              placeholder="Location"
              style={{ width: "100%", textAlign: "left" }}
              suffixIcon={null}
              value={tempAppointment.location}
              onSelect={handleSelectLocation}
            >
              {Object.values(AppointmentLocation).map((location) => (
                <Select.Option key={location} value={location}>
                  {getAppointmentLocationText(location)}
                </Select.Option>
              ))}
            </Select>
          </Row>
          {tempAppointment.startMs && tempAppointment.endMs && (
            <Row style={{ width: "100%", marginTop: 10, marginBottom: 5 }}>
              <RecurrenceByDayPicker byDay={byDay} setByDay={handleSetByDay} size="small" />
            </Row>
          )}
        </>
      )}
    </Card>
  );
};
