import { green, red, yellow } from "@ant-design/colors";
import {
  CheckOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  ExclamationCircleFilled,
  InfoCircleFilled,
  UndoOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  AttendeeStatus,
  CANCELLED_APPOINTMENT_TEXT,
  CancelledCompletedAppointmentStatus,
  getAppointmentNameFromBilling,
  IAppointment,
  IApproveCancelledCompletedAppointmentEndpointRequest,
  ICalendarEvent,
  IClient,
  ICompletedAppointment,
  IDeleteCompletedAppointmentEndpointRequest,
  INote,
  IUser,
  UserPermission,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import {
  Button,
  Card,
  Col,
  message,
  Modal,
  Popover,
  Row,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import * as momentTz from "moment-timezone";
import React, { useEffect, useState } from "react";
import { FaStamp } from "react-icons/fa";
import { IoSchoolOutline } from "react-icons/io5";
import { RiRocket2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";

import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, MOTIVITY_FORMS_URL } from "../../consts";
import { getAppointmentLocationText, getReasonText } from "../../helpers/appointments";
import { isCancelledAppointment } from "../../helpers/appointments";
import { isClientOverlapping, isTherapistOverlapping } from "../../helpers/calendar";
import { getBillingCodeColor } from "../../helpers/colors";
import { getRejectionReasonText } from "../../helpers/rejectionReason";
import { userHasPermission } from "../../helpers/userPermissions";
import { useHover } from "../../hooks/useHover";
import * as FirestoreService from "../../services/firestore";
import { useUserClinics } from "../UserClinicsProvider";
import { AttendeeTag } from "./AttendeeTag";
import { RejectCancellationModal } from "./RejectCancellationModal";

const { confirm } = Modal;
const { Text, Title } = Typography;

interface IProps {
  users: IUser[];
  client: IClient;
  completedAppointment: ICompletedAppointment;
  forceOpen: boolean;
  setSelectedEvent: (event: ICompletedAppointment) => void;
  refreshAppointments: () => Promise<void>;
  appointment: IAppointment;
  isCalendarProcessing: boolean;
  scheduleConflicts?: ICalendarEvent[];
  style?: React.CSSProperties;
  onActionsChange?: (actions: any) => void;
}

export const CompletedAppointmentCard: React.FC<IProps> = ({
  users,
  client,
  completedAppointment,
  forceOpen,
  setSelectedEvent,
  refreshAppointments,
  appointment,
  isCalendarProcessing,
  scheduleConflicts,
  style,
  onActionsChange,
}: IProps) => {
  const { user, clinic } = useUserClinics();
  const [ref, setRef] = useState<any>();

  const [open, setOpen] = useState<boolean>(false);
  const [notes, setNotes] = useState<any[]>([]);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);

  //Schedule conflicts
  const [scheduleConflictMessage, setScheduleConflictMessage] =
    useState<string>("Schedule conflict");

  //Cancellation rejection modal
  const [isRejectCancellationModalOpen, setIsRejectCancellationModalOpen] =
    useState<boolean>(false);
  const hideRejectCancellationModal = () => {
    setIsRejectCancellationModalOpen(false);
  };

  useEffect(() => {
    if (scheduleConflicts && scheduleConflicts.length > 0) {
      const therapistOverlapping = isTherapistOverlapping(appointment, scheduleConflicts);
      const clientOverlapping = isClientOverlapping(appointment, scheduleConflicts);
      if (therapistOverlapping && clientOverlapping) {
        setScheduleConflictMessage("Client & therapist schedule conflict");
      } else if (therapistOverlapping) {
        setScheduleConflictMessage("Therapist schedule conflict");
      } else if (clientOverlapping) {
        setScheduleConflictMessage("Client schedule conflict");
      }
    }
  }, [scheduleConflicts]);

  const hidePopover = () => {
    setSelectedEvent({} as ICompletedAppointment);
    setOpen(false);
  };

  const handlePopoverOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      setSelectedEvent(completedAppointment);
      setOpen(true);
    } else {
      setSelectedEvent({} as ICompletedAppointment);
      setOpen(false);
    }
  };

  useEffect(() => {
    setRef(React.createRef());
  }, []);

  //Scroll to ref on forceOpen page load
  useEffect(() => {
    if (forceOpen == true && ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [forceOpen, ref]);

  useEffect(() => {
    if (forceOpen) {
      handlePopoverOpenChange(true);
    }
  }, [forceOpen]);

  useEffect(() => {
    if (!_.isEmpty(client) && !_.isEmpty(completedAppointment)) {
      fetchNotes().catch(() => {});
    }
  }, [user, client, completedAppointment]);

  //Loading on cards
  const [sentRefreshCallback, setSentRefreshCallback] = useState<boolean>(false);
  useEffect(() => {
    if (sentRefreshCallback && !isCalendarProcessing) {
      setSentRefreshCallback(false);
    }
  }, [isCalendarProcessing]);

  const handleSubRefresh = () => {
    setSentRefreshCallback(true);
    return refreshAppointments();
  };

  const confirmDelete = () => {
    confirm({
      title: "Do you want to un-complete this appointment?",
      content: "This will remove this session from billing and it will need to be completed again.",
      icon: <ExclamationCircleFilled />,
      okText: "Un-complete",
      onOk: async () => {
        setSentRefreshCallback(true);
        await FirestoreService.deleteCompletedAppointment({
          id: completedAppointment.id,
        });
        await refreshAppointments();
      },
    });
  };

  const handleUnCancelAppointment = async () => {
    setIsCancelling(true);
    setSentRefreshCallback(true);
    //GCal
    try {
      await FirestoreService.updateAppointment({
        id: appointment.id,
        summary: `${appointment.summary.replaceAll(CANCELLED_APPOINTMENT_TEXT, "")}`,
        attendees: appointment.attendees.map((attendee) => ({
          email: attendee.email,
          status: AttendeeStatus.ACCEPTED,
        })),
      });
    } catch (err) {
      //Pass
      //This is incase GCal ever messes up and deletes appointments
    }
    try {
      const request: IDeleteCompletedAppointmentEndpointRequest = {
        id: completedAppointment.id,
      };
      await FirestoreService.deleteCompletedAppointment(request);
      void message.success("Appointment un-cancelled");
      await refreshAppointments();
      hidePopover();
    } catch (err) {
      void message.error("Failed to un-cancel appointment");
      console.error(err);
    }
    setIsCancelling(false);
  };

  const handleApproveCancellation = async () => {
    setSentRefreshCallback(true);

    const request: IApproveCancelledCompletedAppointmentEndpointRequest = {
      appointmentId: completedAppointment.id,
    };

    await FirestoreService.approveCancelledCompletedAppointment(request);

    await refreshAppointments();
  };

  const {
    isHover: approveHover,
    onMouseEnter: handleApproveMouseEnter,
    onMouseLeave: handleApproveMouseLeave,
  } = useHover();
  const {
    isHover: rejectHover,
    onMouseEnter: handleRejectMouseEnter,
    onMouseLeave: handleRejectMouseLeave,
  } = useHover();

  const fetchNotes = async () => {
    const notes = await FirestoreService.getAllNotesByAppointmentId(
      completedAppointment.id,
      completedAppointment.clinicId
    );
    const tabify = notes.map((note: INote) => {
      if (user.permissions?.includes(UserPermission.ADMIN) || user.id == note.userId) {
        const filterNote = JSON.parse(JSON.stringify(note));
        filterNote.startTime = momentTz
          .utc(note.startMs)
          .tz(momentTz.tz.guess())
          .format(DISPLAY_TIME_FORMAT);
        filterNote.endTime = momentTz
          .utc(note.endMs)
          .tz(momentTz.tz.guess())
          .format(DISPLAY_TIME_FORMAT);
        filterNote.date = momentTz
          .utc(note.startMs)
          .tz(momentTz.tz.guess())
          .format(DISPLAY_DATE_FORMAT);
        filterNote.clientFullName = client.alias;
        filterNote.providerSigned = note.providerSignedMs ? (
          <CheckSquareOutlined style={{ color: COLORS.GREEN }} />
        ) : (
          <CloseSquareOutlined style={{ color: COLORS.RED }} />
        );
        filterNote.clientSigned = note.clientSignedMs ? (
          <CheckSquareOutlined style={{ color: COLORS.GREEN }} />
        ) : (
          ""
        ); //Optional field
        filterNote.location = getAppointmentLocationText(note.location);
        const desiredOrdering = new Map();
        desiredOrdering.set("noteType", "");
        desiredOrdering.set("narrative", "");
        return {
          label: null,
          key: filterNote.id,
          children: (
            <Col>
              <Row style={{ maxHeight: 400, overflowY: "auto" }}>
                {Array.from(desiredOrdering, ([key, value]) => {
                  return (
                    filterNote[key].length !== 0 && (
                      <Row
                        style={{ marginBottom: 5, width: "100%" }}
                        align="bottom"
                        justify="space-between"
                      >
                        <div>
                          {value.length !== 0 && (
                            <Text strong style={{ marginRight: 5 }}>
                              {value}
                            </Text>
                          )}
                          {key === "noteType" && (
                            <Link
                              to={`${MOTIVITY_FORMS_URL + note.id}`}
                              target="_blank"
                              style={{ fontWeight: "bold" }}
                            >
                              {filterNote[key]}
                            </Link>
                          )}
                          {key !== "noteType" && (
                            <Text
                              style={{
                                borderBottom: 2,
                              }}
                            >
                              {filterNote[key]}
                            </Text>
                          )}
                        </div>
                      </Row>
                    )
                  );
                })}
              </Row>
            </Col>
          ),
        };
      }
    });
    setNotes(tabify.filter((e) => e != undefined));
  };

  useEffect(() => {
    setRef(React.createRef());
    if (onActionsChange) {
      onActionsChange({
        acceptCancellation: handleApproveCancellation,
        rejectCancellation: () => {
          setIsRejectCancellationModalOpen(true);
        },
      });
    }
  }, []);

  const popoverContent = () => {
    return (
      <div style={{ width: 300 }}>
        <Row justify="end" style={{ marginBottom: 5 }}>
          <>
            {!userHasPermission(user, UserPermission.RBT) &&
              (isCancelledAppointment(completedAppointment) ? (
                <Tooltip placement="bottom" title="Un-cancel appointment">
                  <Button
                    type="text"
                    size="small"
                    style={{ marginLeft: 5 }}
                    onClick={handleUnCancelAppointment}
                    loading={isCancelling}
                  >
                    <UndoOutlined style={{ margin: 0 }} />
                  </Button>
                </Tooltip>
              ) : (
                !completedAppointment.isBilled && (
                  <Tooltip placement="bottom" title="Un-complete appointment">
                    <Button
                      type="text"
                      size="small"
                      style={{ marginLeft: 5 }}
                      onClick={confirmDelete}
                    >
                      <UndoOutlined style={{ margin: 0 }} />
                    </Button>
                  </Tooltip>
                )
              ))}

            <Tooltip placement="bottom" title="Close">
              <Button type="text" size="small" style={{ marginLeft: 12 }} onClick={hidePopover}>
                <CloseOutlined />
              </Button>
            </Tooltip>
          </>
        </Row>
        <Row>
          <Title level={4} style={{ marginBottom: 0 }}>
            {client && !_.isEmpty(client) ? (
              <Link to={`../client-profile/${client.id}`}>
                <IoSchoolOutline style={{ fontSize: 18, marginBottom: -1, marginRight: 3 }} />
                {client?.alias}
              </Link>
            ) : (
              completedAppointment.clientId
            )}
          </Title>
        </Row>
        <Row>
          <Text>
            {getAppointmentNameFromBilling(
              clinic.address.state,
              completedAppointment.billingCode,
              completedAppointment.modifiers
            )}
          </Text>
          <Text type="secondary" style={{ marginLeft: 7 }}>{`${
            completedAppointment.billingCode
          } ${completedAppointment.modifiers.join(" ")}`}</Text>
        </Row>
        <Row>
          <Text>{`${moment(completedAppointment.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
            completedAppointment.endMs
          ).format(DISPLAY_TIME_FORMAT)}`}</Text>
        </Row>
        <Row align="middle">
          <Text>{getAppointmentLocationText(completedAppointment.location)}</Text>
        </Row>
        <Row align="middle">
          {!isCancelledAppointment(completedAppointment) && (
            <Text>{completedAppointment.isBilled ? "Billed" : "Not Billed"}</Text>
          )}
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Text strong>Guests</Text>
        </Row>
        {completedAppointment.attendees.map((attendee) => (
          <AttendeeTag
            key={attendee.email}
            email={attendee.email}
            status={attendee.status}
            maxWidth={200}
          />
        ))}
        {isCancelledAppointment(completedAppointment) ? (
          <Col>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Cancellation reason</Text>
            </Row>
            <Row>
              <Text>{getReasonText(completedAppointment.cancellationReason!)}</Text>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Cancellation description</Text>
            </Row>
            <Row>
              <Text>{completedAppointment.cancellationNotes!}</Text>
            </Row>
            {completedAppointment.cancellationRejectionReason && (
              <>
                <Row style={{ marginTop: 20 }}>
                  <Text strong>Rejection Notes</Text>
                </Row>
                <Row>
                  <Text>
                    {getRejectionReasonText(completedAppointment.cancellationRejectionReason)}.{" "}
                    {completedAppointment.cancellationRejectionNotes}
                  </Text>
                </Row>
              </>
            )}
            {!userHasPermission(user, UserPermission.RBT) &&
              completedAppointment.cancellationStatus ===
                CancelledCompletedAppointmentStatus.PENDING && (
                <Row
                  justify="end"
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Button
                    type="default"
                    onClick={() => {
                      hidePopover();
                      setIsRejectCancellationModalOpen(true);
                    }}
                    style={{
                      borderColor: rejectHover ? red.primary : "",
                      color: rejectHover ? red.primary : "",
                    }}
                    onMouseEnter={handleRejectMouseEnter}
                    onMouseLeave={handleRejectMouseLeave}
                  >
                    <CloseOutlined />
                    {"Reject"}
                  </Button>
                  <Button
                    type="default"
                    onClick={() => {
                      hidePopover();
                      return handleApproveCancellation();
                    }}
                    style={{
                      marginLeft: 10,
                      borderColor: approveHover ? green.primary : "",
                      color: approveHover ? green.primary : "",
                    }}
                    onMouseEnter={handleApproveMouseEnter}
                    onMouseLeave={handleApproveMouseLeave}
                  >
                    <CheckOutlined />
                    {"Approve"}
                  </Button>
                </Row>
              )}
          </Col>
        ) : (
          <Tabs size="small" style={{ marginTop: 15 }} items={notes} />
        )}
      </div>
    );
  };

  return (
    <>
      <Popover
        trigger="click"
        placement="left"
        open={open}
        onOpenChange={handlePopoverOpenChange}
        content={popoverContent}
        zIndex={500}
        showArrow={false}
        getPopupContainer={(trigger) => trigger.parentElement!}
      >
        <Card
          ref={ref}
          hoverable
          loading={sentRefreshCallback}
          style={{
            marginBottom: 5,
            boxShadow: open
              ? "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)"
              : undefined,
            borderColor:
              scheduleConflicts && scheduleConflicts.length > 0 ? yellow.primary : undefined,
            ...style,
          }}
          bodyStyle={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 3,
          }}
        >
          <Row justify="space-between" style={{ width: "100%", height: 24 }}>
            <Text
              strong
              style={{
                textDecoration:
                  isCancelledAppointment(completedAppointment) &&
                  completedAppointment.cancellationStatus ===
                    CancelledCompletedAppointmentStatus.APPROVED
                    ? "line-through"
                    : undefined,
                opacity: isCancelledAppointment(completedAppointment) ? 0.5 : 1,
              }}
            >
              {`${
                completedAppointment.cancellationStatus ===
                CancelledCompletedAppointmentStatus.APPROVED
                  ? CANCELLED_APPOINTMENT_TEXT
                  : completedAppointment.cancellationStatus ===
                    CancelledCompletedAppointmentStatus.PENDING
                  ? "(CANCELLING) "
                  : ""
              }${client && !_.isEmpty(client) ? client.alias : completedAppointment?.summary}`}
            </Text>
            <Row align="middle">
              {scheduleConflicts && scheduleConflicts.length > 0 && (
                <Tooltip title={scheduleConflictMessage}>
                  <WarningFilled
                    style={{
                      marginRight: 4,
                      marginBottom: 1,
                      fontSize: 17,
                      color: yellow.primary,
                    }}
                  />
                </Tooltip>
              )}
              {!userHasPermission(user, UserPermission.RBT) &&
                (completedAppointment.isBilled ? (
                  <Tooltip title={`Billed`} className="status-icon">
                    <RiRocket2Fill
                      style={{
                        transform: "rotate(45deg)",
                        marginRight: 4,
                        fontSize: 20,
                        color: green.primary,
                      }}
                    />
                  </Tooltip>
                ) : (
                  !isCancelledAppointment(completedAppointment) && (
                    <Tooltip title={`Completed`} className="status-icon">
                      <FaStamp
                        style={{
                          position: "relative",
                          left: 14,
                          bottom: 2,
                          color: "white",
                          fontSize: 10,
                          marginLeft: -10,
                        }}
                      />
                      <InfoCircleFilled
                        style={{
                          marginRight: 4,
                          fontSize: 18,
                          color: green.primary,
                        }}
                      />
                    </Tooltip>
                  )
                ))}
              {completedAppointment.cancellationStatus ===
                CancelledCompletedAppointmentStatus.REJECTED && (
                <Tooltip
                  title={`Rejected 
                              (${getRejectionReasonText(
                                completedAppointment.cancellationRejectionReason
                              )})`}
                  className="status-icon"
                >
                  <CloseCircleOutlined style={{ color: red.primary, marginRight: 5 }} />
                </Tooltip>
              )}
              {completedAppointment.cancellationStatus ===
                CancelledCompletedAppointmentStatus.PENDING && (
                <Tooltip title="Pending BCBA review" className="status-icon">
                  <ClockCircleOutlined style={{ color: COLORS.DARK_GRAY, marginRight: 5 }} />
                </Tooltip>
              )}
            </Row>
          </Row>
          <Tag
            color={getBillingCodeColor(
              completedAppointment.billingCode,
              completedAppointment.modifiers
            )}
            style={{ marginTop: 2, marginBottom: 2 }}
          >
            {getAppointmentNameFromBilling(
              clinic.address.state,
              completedAppointment.billingCode,
              completedAppointment.modifiers
            )}
          </Tag>
          <br />
          <Text>{getAppointmentLocationText(completedAppointment.location)}</Text>
          <br />
          <Text>{`${moment(completedAppointment.startMs).format(DISPLAY_TIME_FORMAT)} — ${moment(
            completedAppointment.endMs
          ).format(DISPLAY_TIME_FORMAT)}`}</Text>
          <br />
          {completedAppointment.attendees.map((attendee) => (
            <AttendeeTag key={attendee.email} email={attendee.email} status={attendee.status} />
          ))}
        </Card>
      </Popover>
      <RejectCancellationModal
        completedAppointment={completedAppointment}
        refreshCallback={handleSubRefresh}
        hideModal={hideRejectCancellationModal}
        isOpen={isRejectCancellationModalOpen}
        users={users}
      />
    </>
  );
};
