import _ from "lodash";

import { IAddress, IClientPayer } from "../types";

export const isAddressComplete = (address: IAddress) => {
  return (
    !_.isEmpty(address.line1) &&
    !_.isEmpty(address.city) &&
    !_.isEmpty(address.state) &&
    !_.isEmpty(address.zipCode) &&
    !_.isEmpty(address.country)
  );
};

export const isClientPayerWithoutAuthComplete = (payer: IClientPayer) => {
  return (
    !_.isEmpty(payer) &&
    !_.isEmpty(payer.payerId) &&
    !_.isEmpty(payer.memberNum) &&
    !_.isEmpty(payer.policyHolder) &&
    !_.isEmpty(payer.policyHolder.firstName) &&
    !_.isEmpty(payer.policyHolder.lastName) &&
    !_.isEmpty(payer.policyHolder.dateOfBirth) &&
    !_.isEmpty(payer.policyHolderRelationship)
  );
};
