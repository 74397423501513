import { EditOutlined, IdcardOutlined, RightOutlined } from "@ant-design/icons";
import {
  ActiveStatus,
  capitalizeFirstLetter,
  getAllPayersForState,
  getAuthExpiryDate,
  getPayerById,
  IClientFile,
  IClientGuardianDetails,
  IGuardian,
  IntakeStatus,
  IUser,
  PrequalifiedStatus,
  stringToColor,
  UserPermission,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import {
  Collapse,
  Input,
  message,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoHeartCircleOutline, IoSchoolOutline } from "react-icons/io5";
import { TbPlugConnected, TbPlugConnectedX } from "react-icons/tb";
import { Link, useLocation, useParams } from "react-router-dom";

import {
  EDIT_CLIENT_FILE_MODAL_TABLE_TABS,
  EditClientFileModal,
} from "../components/EditClientFileModal/EditClientFileModal";
import { InsuranceCardModal } from "../components/InsuranceCardModal";
import { IntakeStatusTag } from "../components/Intake/IntakeStatusTag";
import { IntakeTableIntakeStatus } from "../components/Intake/IntakeTableIntakeStatus";
import { useUserClinics } from "../components/UserClinicsProvider";
import { DISPLAY_DATE_FORMAT } from "../consts";
import * as FirestoreService from "../services/firestore";

const { Link: LinkText, Text } = Typography;
const { Search } = Input;

export enum TABLE_TABS {
  ACTIVE = "active",
  PREQUALIFIED = "pre-qualified",
}

export const Clients: React.FC = () => {
  const { clinic, user } = useUserClinics();
  const location = useLocation();

  const { tab } = useParams<{ tab: TABLE_TABS }>();

  // Client Table
  const [users, setUsers] = useState<IUser[]>([]);
  const [allClients, setAllClients] = useState<IClientGuardianDetails[]>([]);

  // const [allClients, setAllClients] = useState<IClientGuardianDetails[]>([]);
  const [activeClients, setActiveClients] = useState<IClientGuardianDetails[]>([]);
  const [prequalifiedClients, setPrequalifiedClients] = useState<IClientGuardianDetails[]>([]);
  const [dataSource, setDataSource] = useState<IClientGuardianDetails[]>([]);
  const [hiddenDataSource, setHiddenDataSource] = useState<IClientGuardianDetails[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<TABLE_TABS>(TABLE_TABS.ACTIVE);

  // Edit ClientFile Modal
  const [isEditClientFileModalVisible, setIsEditClientFileModalVisible] = useState<boolean>(false);
  const [editClientFileModalData, setEditClientFileModalData] =
    useState<IClientGuardianDetails | null>(null);
  const [editClientFileModalDefaultTab, setEditClientFileModalDefaultTab] = useState<
    EDIT_CLIENT_FILE_MODAL_TABLE_TABS | undefined
  >(undefined);
  const hideEditClientFileModal = () => {
    setEditClientFileModalData(null);
    setIsEditClientFileModalVisible(false);
  };

  // View Insurance Modal
  const [isViewInsuranceModalVisible, setIsViewInsuranceModalVisible] = useState<boolean>(false);
  const [viewInsuranceModalData, setViewInsuranceModalData] =
    useState<IClientGuardianDetails | null>(null);

  const hideViewInsuranceModalGuardian = () => {
    setViewInsuranceModalData(null);
    setIsViewInsuranceModalVisible(false);
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      fetchData(true, false).catch(() => {});
    }
  }, [user]);

  useEffect(() => {
    if (tab && !_.isEmpty(tab)) {
      setSelectedTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    const { pathname } = location;
    const idx = pathname.indexOf("clients");
    let link = idx === -1 ? pathname + "clients" : pathname.slice(0, idx + "clients".length);
    link += "/" + selectedTab.toLowerCase();
    window.history.replaceState(null, "Mission Control - Finni Health", link);
  }, [selectedTab]);

  useEffect(() => {
    switch (selectedTab) {
      case TABLE_TABS.ACTIVE:
        setDataSource(activeClients);
        break;
      case TABLE_TABS.PREQUALIFIED:
        setDataSource(prequalifiedClients);
        break;
    }

    if (searchString) {
      handleSearch(searchString);
    }
  }, [prequalifiedClients, activeClients, allClients, selectedTab]);

  const fetchData = async (showLoading = true, force = true) => {
    setIsTableLoading(showLoading);

    const [clientGuardianDetails, users] = await Promise.all([
      FirestoreService.getClientsGuardiansDetailsByClinicId(user.clinicId, force),
      FirestoreService.getAllUsersForClinic(user.clinicId),
    ]);

    const activeClients = clientGuardianDetails.filter((client) =>
      Object.keys(ActiveStatus).includes(client.clientFile?.intakeStatus)
    );
    const prequalifiedClients = clientGuardianDetails.filter(
      (client) =>
        Object.keys(PrequalifiedStatus).includes(client.clientFile?.intakeStatus) &&
        ![IntakeStatus.UNQUALIFIED, IntakeStatus.CHURNED].includes(client.clientFile?.intakeStatus)
    );

    const hiddenClients = clientGuardianDetails.filter((client) =>
      [IntakeStatus.UNQUALIFIED, IntakeStatus.CHURNED].includes(client.clientFile?.intakeStatus)
    );

    setAllClients(clientGuardianDetails);
    setActiveClients(activeClients);
    setPrequalifiedClients(prequalifiedClients);
    setHiddenDataSource(hiddenClients);
    setUsers(users);
    setIsTableLoading(false);
  };

  const handleUpdateAssignedBcba = async (id: string, assignedBcbaId: string) => {
    await FirestoreService.updateClientFile({
      id,
      assignedBcbaId,
    });

    await fetchData(false);
    void message.success("Changes saved");
  };

  const handleUpdateAssignedRbt = async (id: string, assignedRbtId: string) => {
    await FirestoreService.updateClientFile({
      id,
      assignedRbtId,
    });

    await fetchData(false);
    void message.success("Changes saved");
  };

  const clientsColumns = () => {
    const columnsTemplate = [
      {
        title: "Client",
        sorter: (a: IClientGuardianDetails, b: IClientGuardianDetails) =>
          a.client.lastName.localeCompare(b.client.lastName),
        render: (row: IClientGuardianDetails) => (
          <>
            <Tooltip title="View Client">
              <Link to={`../client-profile/${row.client.id}`}>
                <IoSchoolOutline style={{ fontSize: 18, marginBottom: -3, marginRight: 5 }} />
                {`(${row.client.alias}) ${row.client.firstName} ${row.client.lastName}`}
              </Link>
            </Tooltip>
            {row.client.preferredName && (
              <>
                <br />
                <Text>{`Preferred: ${row.client.preferredName}`}</Text>
              </>
            )}
            {row.client.motivityUUID ? (
              <Tooltip title="Synced With Motivity">
                <TbPlugConnected
                  style={{
                    fontSize: 16,
                    marginBottom: -4,
                    marginLeft: 5,
                    color: COLORS.GREEN,
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Not Synced With Motivity">
                <TbPlugConnectedX
                  style={{
                    fontSize: 16,
                    marginBottom: -4,
                    marginLeft: 5,
                    color: COLORS.RED,
                  }}
                />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: "Guardian",
        dataIndex: "guardian",
        render: (guardian: IGuardian) =>
          !_.isEmpty(guardian) && (
            <Tooltip title="View Guardian">
              <Link to={`../guardian-profile/${guardian.id}`}>
                <IoHeartCircleOutline style={{ fontSize: 18, marginBottom: -3, marginRight: 3 }} />
                {`${guardian.lastName}, ${guardian.firstName}`}
              </Link>
            </Tooltip>
          ),
      },
      {
        title: "Primary Payer",
        render: (_, row: IClientGuardianDetails) => {
          const payer = getPayerById(
            clinic.address.state,
            row.clientFile.payers.primary?.payerId ?? null
          );
          return (
            payer && (
              <>
                <Tooltip title="Quick View Insurance">
                  <LinkText
                    onClick={() => {
                      setViewInsuranceModalData(row);
                      setIsViewInsuranceModalVisible(true);
                    }}
                  >
                    <Tag color={stringToColor(payer.name)}>
                      <IdcardOutlined style={{ marginRight: 5 }} />
                      {payer.name}
                    </Tag>
                  </LinkText>
                </Tooltip>
              </>
            )
          );
        },
        filters: getAllPayersForState(clinic.address.state)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((payer) => ({
            text: (
              <Tag color={stringToColor(payer.name)}>
                <IdcardOutlined style={{ marginRight: 5 }} />
                {payer.name}
              </Tag>
            ),
            value: payer.payerId,
          })),
        onFilter: (value: any, row: IClientGuardianDetails) =>
          value === row.clientFile?.payers.primary?.payerId,
      },
      {
        title: "Status",
        dataIndex: ["clientFile", "intakeStatus"],
        width: 1,
        sorter: (a: IClientGuardianDetails, b: IClientGuardianDetails) =>
          a.clientFile?.intakeStatus.localeCompare(b.clientFile?.intakeStatus || ""),
        render: (value: string, intakeClient: IClientGuardianDetails) => (
          <IntakeTableIntakeStatus
            clientGuardianDetails={intakeClient}
            setEditClientFileModalData={setEditClientFileModalData}
            setEditClientFileModalDefaultTab={setEditClientFileModalDefaultTab}
            setIsEditClientFileModalVisible={setIsEditClientFileModalVisible}
          />
        ),
        filters: [{ text: "Hot Lead 🔥" as any, value: "isHot" }].concat(
          Object.values(
            selectedTab === TABLE_TABS.PREQUALIFIED ? PrequalifiedStatus : ActiveStatus
          ).map((intakeStatus) => ({
            text: <IntakeStatusTag status={intakeStatus as IntakeStatus} />,
            value: intakeStatus,
          }))
        ),
        onFilter: (value: any, row: IClientGuardianDetails) => {
          if (value === "isHot") {
            return row.clientFile?.isHot;
          } else {
            return row.clientFile?.intakeStatus === value;
          }
        },
      },
      {
        title: "Auth Expiry",
        dataIndex: "clientFile",
        sorter: (a: IClientGuardianDetails, b: IClientGuardianDetails) => {
          const authExpiryDateA =
            getAuthExpiryDate(a.clientFile.payers.primary ?? null) || moment();
          const authExpiryDateB =
            getAuthExpiryDate(b.clientFile.payers.primary ?? null) || moment();

          return authExpiryDateA.diff(authExpiryDateB);
        },
        render: (clientFile: IClientFile) => {
          const authExpiryDate = getAuthExpiryDate(clientFile.payers.primary ?? null);
          if (authExpiryDate === null) {
            return null;
          }
          const daysTillExpiry = Math.round(
            moment.duration(authExpiryDate.diff(moment())).asDays()
          );
          return daysTillExpiry > 0 ? `${daysTillExpiry} days` : "Expired";
        },
      },
      {
        width: 150,
        title: "BCBA",
        dataIndex: ["clientFile", "assignedBcbaId"],
        render: (assignedBcbaId: string, row: IClientGuardianDetails) => (
          <Select
            key={row.client.id}
            showSearch
            allowClear
            optionFilterProp="children"
            defaultValue={assignedBcbaId}
            style={{ width: 150 }}
            onChange={(value) => handleUpdateAssignedBcba(row.clientFile.id, value || "")}
          >
            {users
              .filter((user) =>
                user.permissions.some((permission) => [UserPermission.BCBA].includes(permission))
              )
              .map((user) => (
                <Select.Option
                  key={user.id}
                  value={user.id}
                >{`${user.firstName} ${user.lastName[0]}.`}</Select.Option>
              ))}
          </Select>
        ),
        filters: users
          .filter((user) =>
            user.permissions.some((permission) => [UserPermission.BCBA].includes(permission))
          )
          .sort((a: IUser, b: IUser) => a.firstName.localeCompare(b.firstName))
          .map((user: IUser) => ({
            text: `${user.firstName} ${user.lastName[0]}.`,
            value: user.id,
          })),
        onFilter: (value: any, row: IClientGuardianDetails) =>
          value === row.clientFile?.assignedBcbaId,
      },
      {
        width: 150,
        title: "RBT",
        dataIndex: ["clientFile", "assignedRbtId"],
        render: (assignedRbtId: string, row: IClientGuardianDetails) => (
          <Select
            key={`${row.client.id}+1`}
            showSearch
            allowClear
            optionFilterProp="children"
            defaultValue={assignedRbtId}
            style={{ width: 150 }}
            onChange={(value) => handleUpdateAssignedRbt(row.clientFile.id, value || "")}
          >
            {users
              .filter((user) =>
                user.permissions.some((permission) => [UserPermission.RBT].includes(permission))
              )
              .map((user) => (
                <Select.Option
                  key={user.id}
                  value={user.id}
                >{`${user.firstName} ${user.lastName[0]}.`}</Select.Option>
              ))}
          </Select>
        ),
        filters: users
          .filter((user) =>
            user.permissions.some((permission) => [UserPermission.RBT].includes(permission))
          )
          .sort((a: IUser, b: IUser) => a.firstName.localeCompare(b.firstName))
          .map((user: IUser) => ({
            text: `${user.firstName} ${user.lastName[0]}.`,
            value: user.id,
          })),
        onFilter: (value: any, row: IClientGuardianDetails) =>
          value === row.clientFile?.assignedRbtId,
      },
      {
        title: "First Contact",
        dataIndex: ["client", "createdAt"],
        render: (createdAt: number) => <p>{`${moment(createdAt).format(DISPLAY_DATE_FORMAT)}`}</p>,
        sorter: (a: IClientGuardianDetails, b: IClientGuardianDetails) =>
          (a.client.createdAt as any) - (b.client.createdAt as any),
      },
      {
        width: 15,
        align: "center" as any,
        render: (_text: string, row: IClientGuardianDetails) => (
          <Row wrap={false} justify="center" align="middle">
            <Space size="large">
              <div
                onClick={() => {
                  setEditClientFileModalData(row);
                  setIsEditClientFileModalVisible(true);
                }}
              >
                <Tooltip title="Edit">
                  <LinkText>
                    <EditOutlined style={{ fontSize: 16 }} />
                  </LinkText>
                </Tooltip>
              </div>
            </Space>
          </Row>
        ),
      },
    ];

    return columnsTemplate;
  };

  const handleSearch = (searchString: string) => {
    setSearchString(searchString);

    let unfilteredData;
    switch (selectedTab) {
      case TABLE_TABS.ACTIVE:
        unfilteredData = activeClients;
        break;
      case TABLE_TABS.PREQUALIFIED:
        unfilteredData = prequalifiedClients;
        break;
      default:
        unfilteredData = allClients;
        break;
    }

    const filteredData = unfilteredData.filter(
      (clientGuardianDetails: IClientGuardianDetails) =>
        clientGuardianDetails.client.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
        clientGuardianDetails.client.lastName.toLowerCase().includes(searchString.toLowerCase()) ||
        clientGuardianDetails.client.alias.toLowerCase().includes(searchString.toLowerCase()) ||
        clientGuardianDetails.guardian.firstName
          .toLowerCase()
          .includes(searchString.toLowerCase()) ||
        clientGuardianDetails.guardian.lastName
          .toLowerCase()
          .includes(searchString.toLowerCase()) ||
        users
          .find((user) => user.id === clientGuardianDetails.clientFile.assignedUserId)
          ?.firstName.toLowerCase()
          .includes(searchString.toLowerCase()) ||
        users
          .find((user) => user.id === clientGuardianDetails.clientFile.assignedUserId)
          ?.lastName.toLowerCase()
          .includes(searchString.toLowerCase())
    );
    setDataSource(filteredData);
  };

  const tabPaneValues = {
    [TABLE_TABS.ACTIVE]: activeClients.length,
    [TABLE_TABS.PREQUALIFIED]: prequalifiedClients.length,
  };

  return (
    <>
      <Tabs
        defaultActiveKey={tab ? tab : selectedTab.valueOf()}
        onChange={(key) => {
          setSelectedTab(key as TABLE_TABS);
        }}
        items={Object.values(TABLE_TABS).map((tab) => ({
          key: tab,
          label: (
            <Statistic
              title={capitalizeFirstLetter(tab)}
              value={tabPaneValues[tab]}
              loading={isTableLoading}
            />
          ),
        }))}
      ></Tabs>
      <Search
        placeholder="Search by Client / User First or Last Name"
        allowClear
        value={searchString}
        onChange={(e) => handleSearch(e.target.value)}
        style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
      />
      <Table columns={clientsColumns()} dataSource={dataSource} loading={isTableLoading} />
      {!_.isEmpty(hiddenDataSource) && selectedTab !== TABLE_TABS.ACTIVE && (
        <Collapse
          ghost
          expandIcon={({ isActive }) => (
            <RightOutlined
              rotate={isActive ? 90 : 0}
              style={{ fontSize: 15, position: "relative", top: 3 }}
            />
          )}
        >
          <Collapse.Panel
            header={
              <Text
                style={{ fontSize: 17, fontWeight: 500 }}
              >{`${hiddenDataSource.length} hidden`}</Text>
            }
            key={1}
          >
            <Table
              columns={clientsColumns()}
              dataSource={hiddenDataSource}
              loading={isTableLoading}
            />
          </Collapse.Panel>
        </Collapse>
      )}
      {isEditClientFileModalVisible && (
        <EditClientFileModal
          hideModal={hideEditClientFileModal}
          clientGuardianDetails={editClientFileModalData!}
          refreshCallback={() => fetchData(false)}
          defaultTab={editClientFileModalDefaultTab}
        />
      )}
      {isViewInsuranceModalVisible && (
        <InsuranceCardModal
          clientGuardianDetails={viewInsuranceModalData!}
          hideModal={hideViewInsuranceModalGuardian}
          isVisible={isViewInsuranceModalVisible}
        />
      )}
    </>
  );
};
