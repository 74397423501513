import { Col } from "antd";
import React from "react";

import { useUserClinics } from "../components/UserClinicsProvider";

//URL for Looker report
const LOOKER_BASE_URL = "https://lookerstudio.google.com/embed";
const LOOKER_PERMISSIONS_PARAMETER =
  "/page/VgD?s=hSkxKxFnmOY&embed_domain=https://mission-control.finnihealth.com/";

export const Dashboard: React.FC = () => {
  const { clinic } = useUserClinics();

  return (
    <div>
      <Col style={{ width: "100vw", height: "180vh" }}>
        <iframe
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          id="looker"
          src={`${LOOKER_BASE_URL}/u/1/reporting/${clinic.lookerReportURL}${LOOKER_PERMISSIONS_PARAMETER}`}
          // eslint-disable-next-line react/no-unknown-property
          cross-origin="anonymous"
          style={{
            border: 0,
            zoom: 0.65,
            width: 2000,
            height: "100%",
          }}
        />
      </Col>
    </div>
  );
};
