import { IUserStats } from "../types";

export const calculateImpactBasisPointsFromStats = (stats: IUserStats): number => {
  //allow 40 hours of indirects per lifetime
  const indirectMinutesAfterAllowance = Math.max(stats.indirectMinutes - 40 * 60, 0);

  //allow 2 hours of therapist cancelled per 40 hours of completed
  const accruedHours = Math.floor(stats.completedMinutes / 60 / 40) * 2;
  const thrapistCancelledMinutesAfterAllowance = Math.max(
    stats.therapistCancelledMinutes - accruedHours * 60,
    0
  );

  //total minutes is completed + indirect + therapist cancelled
  const total =
    stats.completedMinutes + indirectMinutesAfterAllowance + thrapistCancelledMinutesAfterAllowance;

  //calculate impact as completed / total
  let impactBasisPoints = stats.completedMinutes / total;

  //if impact is NaN or completed is 0 (new providers), set impact to 1
  if (isNaN(impactBasisPoints) || stats.completedMinutes === 0) impactBasisPoints = 1;

  //turn into basis points (10000 basis points = 100%)
  impactBasisPoints = Math.round(impactBasisPoints * 10000);
  return impactBasisPoints;
};
