import {
  IAppointment,
  IClientGuardianDetails,
  ICompletedAppointment,
  IntakeStatus,
  IUser,
  UserPermission,
} from "@finni-health/shared";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { userHasPermission } from "../helpers/userPermissions";
import * as FirestoreService from "../services/firestore";
import { useUserClinics } from "./UserClinicsProvider";

export const AppointmentsContext = React.createContext({} as Context);

interface Context {
  appointments: IAppointment[];
  completedAppointments: ICompletedAppointment[];
  clientsGuardiansDetails: IClientGuardianDetails[];
  clinicUsers: IUser[];
  calWeek: Moment;
  isLoading: boolean;
  setCalWeek: (m: Moment) => void;
  refresh: () => void;
  updateAppointments: (e: IAppointment[]) => void;
}

export const AppointmentsProvider = ({ children }: any) => {
  const [key, setKey] = useState<string>("");
  const { user } = useUserClinics();
  const location = useLocation();
  const [context, setContext] = useState({
    appointments: [],
    completedAppointments: [],
    clinicUsers: [],
    clientsGuardiansDetails: [],
    calWeek: moment(),
    isLoading: false,
    refresh: () => {},
    setCalWeek: () => {},
    updateAppointments: () => {},
  } as Context);

  const setCalWeek = (m: Moment) => setContext({ ...context, calWeek: m });

  const refresh = () => {
    setKey(uuidv4());
  };

  const setIsLoading = (loading: boolean) => {
    setContext({
      ...context,
      isLoading: loading,
    });
  };

  const updateAppointments = (appts: IAppointment[]) => {
    setContext({ ...context, appointments: appts });
  };

  const fetchData = async () => {
    if (!_.isEmpty(user)) {
      setIsLoading(true);
      // eslint-disable-next-line prefer-const
      let [clinicUsers, clientGuardianDetails] = await Promise.all([
        FirestoreService.getAllUsersForClinic(user.clinicId),
        FirestoreService.getClientsGuardiansDetailsByClinicId(user.clinicId),
      ]);

      clinicUsers = clinicUsers.filter((clinicUser) =>
        userHasPermission(user, UserPermission.RBT)
          ? user.id == clinicUser.id
          : [UserPermission.BCBA, UserPermission.RBT].some((permission) =>
              userHasPermission(clinicUser, permission)
            )
      );

      const { appointments, completedAppointments } = await fetchAppointments();

      const newContext = {
        ...context,
        clinicUsers,
        clientGuardianDetails: clientGuardianDetails.filter(
          (cwcf) =>
            !Object.values([IntakeStatus.CHURNED, IntakeStatus.UNQUALIFIED]).includes(
              cwcf.clientFile.intakeStatus
            )
        ),
        appointments,
        completedAppointments,
        refresh,
        setCalWeek,
        isLoading: false,
      };

      setContext({
        ...newContext,
        updateAppointments,
      });
    }
  };

  const fetchAppointments = async () => {
    if (!user.clinicId) {
      return {
        appointments: [],
        completedAppointments: [],
      };
    }
    // eslint-disable-next-line prefer-const
    let [appointments, completedAppointments] = await Promise.all([
      FirestoreService.getAppointmentsForClinicIdAndWeek({
        clinicId: user.clinicId,
        week: context.calWeek.week(),
        year: context.calWeek.year(),
        timeZone: moment.tz.guess(),
      }),
      FirestoreService.getCompletedAppointmentByClinicAndRange({
        clinicId: user.clinicId,
        startMs: context.calWeek.startOf("week").valueOf(),
        endMs: context.calWeek.endOf("week").valueOf(),
      }),
    ]);

    if (userHasPermission(user, UserPermission.RBT)) {
      appointments = appointments.filter((appointment) =>
        appointment.attendees.some((attendee) => attendee.email == user.email)
      );

      completedAppointments = completedAppointments.filter((completedAppt) =>
        appointments.find((appointment) => appointment.id === completedAppt.id)
      );
    }

    return {
      appointments,
      completedAppointments,
    };
  };

  useEffect(() => {
    if (window.location.pathname.includes("playground")) fetchData().catch(() => {});
  }, [key, user, context.calWeek, location]);

  return <AppointmentsContext.Provider value={context}>{children}</AppointmentsContext.Provider>;
};
