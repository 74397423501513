import { PlusOutlined } from "@ant-design/icons";
import { BillingCode } from "@finni-health/shared";
import { Button, Col, Form, Input, Popover, Row, Select } from "antd";
import { useState } from "react";

interface ICreateAuthFormValues {
  code: BillingCode;
  unitSize: number;
  units: number;
}

export const AddPayerAuthCodeButton = ({ type }: { type: "primary" | "secondary" }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const mainFormInstance = Form.useFormInstance();
  const { status } = Form.Item.useStatus();
  const authCodes = Form.useWatch(
    ["clientFile", "payers", type, "auth", "auths"],
    mainFormInstance
  );

  const [form] = Form.useForm<ICreateAuthFormValues>();

  const hidePopover = () => {
    form.resetFields();
    setIsPopoverOpen(false);
  };

  const saveAuth = () => {
    setIsSaving(true);
    const values = form.getFieldsValue();

    mainFormInstance.setFieldValue(["clientFile", "payers", type, "auth", "auths"], {
      ...mainFormInstance.getFieldValue(["clientFile", "payers", type, "auth", "auths"]),
      [values.code]: {
        unitSize: values.unitSize,
        units: values.units,
      },
    });
    hidePopover();
    setIsSaving(false);
  };

  return (
    <Popover
      trigger="click"
      open={isPopoverOpen}
      onOpenChange={(visible) => setIsPopoverOpen(visible)}
      placement="right"
      content={
        <div style={{ width: 200 }}>
          <Form
            form={form}
            layout="vertical"
            labelWrap={false}
            labelCol={{ span: 24 }}
            onFinish={saveAuth}
          >
            <Form.Item
              name="code"
              rules={[{ required: true, message: "Please select a billing code" }]}
            >
              <Select placeholder="Billing Code" showSearch allowClear optionFilterProp="children">
                {Object.values(BillingCode)
                  .filter((code) => !Object.keys(authCodes || {}).includes(code))
                  .map((code) => (
                    <Select.Option key={code} value={code}>
                      {code}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="unitSize"
              rules={[{ required: true, message: "Please select a unit size" }]}
            >
              <Select placeholder="Unit Size" showSearch allowClear optionFilterProp="children">
                <Select.Option key={15} value={15}>
                  15 Minutes
                </Select.Option>
                <Select.Option key={30} value={30}>
                  30 Minutes
                </Select.Option>
                <Select.Option key={60} value={60}>
                  60 Minutes
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="units" rules={[{ required: true, message: "Please enter the units" }]}>
              <Input type="number" placeholder="Authorizated units" />
            </Form.Item>
            <Row gutter={12} justify="end">
              <Col>
                <Button disabled={isSaving} onClick={hidePopover}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button key="submit" type="primary" htmlType="submit" loading={isSaving}>
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      }
      getPopupContainer={(trigger) => trigger.parentElement || document.body}
    >
      <Button
        icon={<PlusOutlined />}
        onClick={() => setIsPopoverOpen(true)}
        danger={status === "error"}
      >
        Add Code
      </Button>
    </Popover>
  );
};
