import {
  AppointmentType,
  BillingCode,
  IAppointmentType,
  IAppointmentTypeConfig,
  USStateCode,
} from "../../types";
import { COLORADO_APPOINTMENTS } from "./colorado";
import { CONNECTICUT_APPOINTMENTS } from "./connecticut";
import { GEORGIA_APPOINTMENTS } from "./georgia";
import { ILLINOIS_APPOINTMENTS } from "./illinois";
import { NEW_MEXICO_APPOINTMENTS } from "./newMexico";
import { NEW_YORK_APPOINTMENTS } from "./newYork";
import { VIRGINIA_APPOINTMENTS } from "./virginia";

export const BASE_APPOINTMENTS: IAppointmentType = {
  [AppointmentType.INITIAL_ASSESSMENT]: {
    billingCode: BillingCode.CODE_97151,
    modifiers: [],
  },
  [AppointmentType.ADDITIONAL_ASSESSMENT]: {
    billingCode: BillingCode.CODE_97152,
    modifiers: [],
  },
  [AppointmentType.DIRECT_SERVICES]: {
    billingCode: BillingCode.CODE_97153,
    modifiers: [],
  },
  [AppointmentType.DIRECT_SUPERVISION]: {
    billingCode: BillingCode.CODE_97155,
    modifiers: [],
  },
  [AppointmentType.PARENT_TRAINING]: {
    billingCode: BillingCode.CODE_97156,
    modifiers: [],
  },
};

export const STATE_APPOINTMENTS: IAppointmentTypeConfig = {
  [USStateCode.CO]: COLORADO_APPOINTMENTS,
  [USStateCode.CT]: CONNECTICUT_APPOINTMENTS,
  [USStateCode.GA]: GEORGIA_APPOINTMENTS,
  [USStateCode.IL]: ILLINOIS_APPOINTMENTS,
  [USStateCode.NM]: NEW_MEXICO_APPOINTMENTS,
  [USStateCode.NY]: NEW_YORK_APPOINTMENTS,
  [USStateCode.VA]: VIRGINIA_APPOINTMENTS,
};
