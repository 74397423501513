import {
  capitalizeFirstLetter,
  CountryCode,
  getCurrentActiveAuth,
  IBilledAppointment,
  isPayerAuthRequired,
  Modifier,
  PolicyHolderRelationship,
  Sex,
  UserPermission,
} from "@finni-health/shared";
import _ from "lodash";
import moment from "moment";

import { DB_DATE_FORMAT, DISPLAY_DATE_FORMAT } from "../../consts";
import { ICsvTranslatorField } from "../../helpers/csv";

export const JUNIPER_BILLING_CSV_EXPORT_TEMPLATE: ICsvTranslatorField[] = [
  {
    title: "JuniperBiller",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "SessionControlNumber",
    dataIndex: "id",
  },
  {
    title: "patientControlNumber",
    dataIndex: "client.id",
  },
  {
    title: "ClaimType",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "ClaimFilingCode",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "ClaimFrequencyCode",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "PriorAuthorization",
    dataIndex: "",
    render: (appt: IBilledAppointment) => {
      const authRequired = isPayerAuthRequired(
        appt.clinic.address.state,
        appt.payer.payerId,
        appt.billingCode,
        appt.modifiers
      );
      if (!authRequired) {
        return "No Authorization Required";
      }

      const currentAuth = getCurrentActiveAuth(
        appt.clientFile.payers?.primary ?? null,
        appt.startMs
      );
      return currentAuth?.authNumber || "Auth Number Missing";
    },
  },
  {
    title: "ReleaseConsentByClient",
    dataIndex: "",
    render: () => "Yes",
  },
  {
    title: "DateReleaseConsentByClient",
    dataIndex: "startMs",
    render: (startMs: number) => moment(startMs).format(DISPLAY_DATE_FORMAT),
  },
  {
    title: "PaymentAuthorizedByClient",
    dataIndex: "note.clientSignedMs",
    render: () => "Yes",
  },
  {
    title: "SignedByProvider",
    dataIndex: "note.providerSignedMs",
    render: () => "Yes",
  },
  {
    title: "DateSignedByProvider",
    dataIndex: "startMs",
    render: (startMs: number) => moment(startMs).format(DISPLAY_DATE_FORMAT),
  },
  {
    title: "ServiceLocationCode",
    dataIndex: "location",
  },
  {
    title: "ServiceStartDate",
    dataIndex: "startMs",
    render: (startMs: number) => moment(startMs).format(DISPLAY_DATE_FORMAT),
  },
  {
    title: "ServiceEndDate",
    dataIndex: "endMs",
    render: (endMs: number) => moment(endMs).format(DISPLAY_DATE_FORMAT),
  },
  {
    title: "ServiceStartTime",
    dataIndex: "startMs",
    render: (startMs: number) => moment(startMs).format("HH:mmZ"),
  },
  {
    title: "ServiceEndTime",
    dataIndex: "endMs",
    render: (endMs: number) => moment(endMs).format("HH:mmZ"),
  },
  {
    title: "DiagnosisCode",
    dataIndex: "",
    render: () => "F84.0",
  },
  {
    title: "ProcedureCode",
    dataIndex: "billingCode",
  },
  {
    title: "ServiceCharge",
    dataIndex: "chargeCents",
    render: (chargeCents: number) => (chargeCents / 100).toFixed(2),
  },
  {
    title: "ServiceQty",
    dataIndex: "units",
    render: (units: number) => units.toFixed(0),
  },
  {
    title: "Modifier",
    dataIndex: "modifiers",
    render: (modifiers: Modifier[]) => modifiers.join(","),
  },
  {
    title: "ProviderNPI",
    dataIndex: "billingUser.npi",
  },
  {
    title: "ProviderAssignment",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "ProviderSpecialtyCode",
    dataIndex: "billingUser.permissions",
    render: (userPermissions: UserPermission[]) =>
      userPermissions.includes(UserPermission.BCBA) ? "103K00000X" : "106S00000X",
  },
  {
    title: "ProviderFirstName",
    dataIndex: "billingUser.firstName",
  },
  {
    title: "ProviderLastName",
    dataIndex: "billingUser.lastName",
  },
  {
    title: "ProviderMiddleInitial",
    dataIndex: "billingUser.middleName",
    render: (middleName: string) => {
      if (middleName) {
        return middleName[0];
      } else {
        return "";
      }
    },
  },
  {
    title: "ProviderLocationAddressLine1",
    dataIndex: "clinic.address.line1",
  },
  {
    title: "ProviderLocationAddressLine2",
    dataIndex: "clinic.address.line2",
  },
  {
    title: "ProviderLocationAddressCity",
    dataIndex: "clinic.address.city",
  },
  {
    title: "ProviderLocationStateProvince",
    dataIndex: "clinic.address.state",
  },
  {
    title: "ProviderLocationZipPostalCode",
    dataIndex: "clinic.address.zipCode",
  },
  {
    title: "ProviderLocationCountry",
    dataIndex: "",
    render: () => CountryCode.US,
  },
  {
    title: "ProviderPhone",
    dataIndex: "clinic.phoneNumber",
    render: (phoneNumber: string) => phoneNumber.replaceAll(/[^0-9]/g, ""),
  },
  {
    title: "ProviderEIN",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "SessionProviderNPI",
    dataIndex: "renderingUser.npi",
  },
  {
    title: "SessionProviderAssignment",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "SessionProviderSpecialtyCode",
    dataIndex: "renderingUser.permissions",
    render: (userPermissions: UserPermission[]) =>
      userPermissions.includes(UserPermission.BCBA) ? "103K00000X" : "106S00000X",
  },
  {
    title: "SessionProviderFirstName",
    dataIndex: "renderingUser.firstName",
  },
  {
    title: "SessionProviderLastName",
    dataIndex: "renderingUser.lastName",
  },
  {
    title: "SessionProviderMiddleInitial",
    dataIndex: "renderingUser.middleName",
    render: (middleName: string) => {
      if (middleName) {
        return middleName[0];
      } else {
        return "";
      }
    },
  },
  {
    title: "SessionProviderLocationAddressLine1",
    dataIndex: "clinic.address.line1",
  },
  {
    title: "SessionProviderLocationAddressLine2",
    dataIndex: "clinic.address.line2",
  },
  {
    title: "SessionProviderLocationAddressCity",
    dataIndex: "clinic.address.city",
  },
  {
    title: "SessionProviderLocationStateProvince",
    dataIndex: "clinic.address.state",
  },
  {
    title: "SessionProviderLocationZipPostalCode",
    dataIndex: "clinic.address.zipCode",
  },
  {
    title: "SessionProviderLocationCountry",
    dataIndex: "",
    render: () => CountryCode.US,
  },
  {
    title: "SessionProviderPhone",
    dataIndex: "clinic.phoneNumber",
    render: (phoneNumber: string) => phoneNumber.replaceAll(/[^0-9]/g, ""),
  },
  {
    title: "SessionProviderEIN",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "ClientFirstName",
    dataIndex: "client.firstName",
  },
  {
    title: "ClientLastName",
    dataIndex: "client.lastName",
  },
  {
    title: "ClientMiddleInitial",
    dataIndex: "client.middleName",
    render: (middleName: string) => {
      if (middleName) {
        return middleName[0];
      } else {
        return "";
      }
    },
  },
  {
    title: "ClientLocationAddressLine1",
    dataIndex: "clientFile.address.line1",
  },
  {
    title: "ClientLocationAddressLine2",
    dataIndex: "clientFile.address.line2",
  },
  {
    title: "ClientLocationAddressCity",
    dataIndex: "clientFile.address.city",
  },
  {
    title: "ClientLocationStateProvince",
    dataIndex: "clientFile.address.state",
  },
  {
    title: "ClientLocationZipPostalCode",
    dataIndex: "clientFile.address.zipCode",
  },
  {
    title: "ClientLocationCountry",
    dataIndex: "",
    render: () => CountryCode.US,
  },
  {
    title: "ClientDateOfBirth",
    dataIndex: "client.dateOfBirth",
    render: (dateOfBirth: string) =>
      moment(dateOfBirth, DB_DATE_FORMAT).format(DISPLAY_DATE_FORMAT),
  },
  {
    title: "ClientGender",
    dataIndex: "client.sex",
    render: (sex: Sex) => capitalizeFirstLetter(sex),
  },
  {
    title: "patientRelationship",
    dataIndex: "clientFile.payers.primary.policyHolderRelationship",
    render: (relationship: PolicyHolderRelationship) =>
      capitalizeFirstLetter(relationship || "Self"),
  },
  {
    title: "PatientInsuranceType",
    dataIndex: "",
    render: () => "P-Primary",
  },
  {
    title: "PayorName",
    dataIndex: "payer.name",
  },
  {
    title: "ChangePayorID",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "SubscriberID",
    dataIndex: "clientFile.payers.primary.memberNum",
  },
  {
    title: "SubscriberGroupNumber",
    dataIndex: "clientFile.payers.primary.groupNum",
  },
  {
    title: "SubscriberFirstName",
    dataIndex: "clientFile.payers.primary.policyHolder.firstName",
  },
  {
    title: "SubscriberLastName",
    dataIndex: "clientFile.payers.primary.policyHolder.lastName",
  },
  {
    title: "SubscriberMiddleInitial",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "SubscriberDateOfBirth",
    dataIndex: "clientFile.payers.primary.policyHolder.dateOfBirth",
    render: (dateOfBirth: string) =>
      moment(dateOfBirth, DB_DATE_FORMAT).format(DISPLAY_DATE_FORMAT),
  },
  {
    title: "SubscriberGender",
    dataIndex: "clientFile.payers.primary.policyHolder.sex",
    render: (sex: Sex) => capitalizeFirstLetter(sex),
  },
  {
    title: "SubscriberLocationAddressLine1",
    dataIndex: "clientFile.address.line1",
  },
  {
    title: "SubscriberLocationAddressLine2",
    dataIndex: "clientFile.address.line2",
  },
  {
    title: "SubscriberLocationAddressCity",
    dataIndex: "clientFile.address.city",
  },
  {
    title: "SubscriberLocationStateProvince",
    dataIndex: "clientFile.address.state",
  },
  {
    title: "SubscriberLocationZipPostalCode",
    dataIndex: "clientFile.address.zipCode",
  },
  {
    title: "SubscriberLocationCountry",
    dataIndex: "",
    render: () => CountryCode.US,
  },
  {
    title: "OtherPayorSubscriberInsuranceType",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "OtherPayorSubscriberRelationship",
    dataIndex: "clientFile.payers.secondary.policyHolderRelationship",
    render: (relationship: PolicyHolderRelationship) =>
      relationship ? capitalizeFirstLetter(relationship || "Self") : "",
  },
  {
    title: "OtherPayorClaimFilingCode",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "OtherPayorIndentifierType",
    dataIndex: "",
    render: (appt: IBilledAppointment) => (!_.isEmpty(appt.secondaryPayer) ? "S-Secondary" : ""),
  },
  {
    title: "OtherPayerIdentifier",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "OtherPayorName",
    dataIndex: "secondaryPayer.name",
  },
  {
    title: "OtherPayorSubscriberFirstName",
    dataIndex: "clientFile.payers.secondary.policyHolder.firstName",
  },
  {
    title: "OtherPayorSubscriberLastName",
    dataIndex: "clientFile.payers.secondary.policyHolder.lastName",
  },
  {
    title: "OtherPayorSubscriberMiddleInitial",
    dataIndex: "",
    render: () => "",
  },
  {
    title: "OtherPayorPolicyNumber",
    dataIndex: "clientFile.payers.secondary.memberNum",
  },
  {
    title: "ClinicName",
    dataIndex: "",
    render: () => "JOURNEY HEALTH TECHNOLOGIES U.S. INC.",
  },
  {
    title: "ClinicPhoneNumber",
    dataIndex: "",
    render: () => "6478284133",
  },
  {
    title: "ClinicNPI",
    dataIndex: "",
    render: () => "1528704236",
  },
  {
    title: "ClinicTaxID",
    dataIndex: "",
    render: () => "882173251",
  },
  {
    title: "ClinicTaxIDType",
    dataIndex: "",
    render: () => "EIN",
  },
  {
    title: "ClinicTaxonomyCode",
    dataIndex: "",
    render: () => "103K00000X",
  },
  {
    title: "ClinicTaxonomyCodeDescription",
    dataIndex: "",
    render: () => "Behavioral Health & Social Service Providers - Behavioral Analyst",
  },
  {
    title: "ClinicBillingLocationAddressLine1",
    dataIndex: "payer.billingAddress.line1",
  },
  {
    title: "ClinicBillingLocationAddressLine2",
    dataIndex: "payer.billingAddress.line2",
  },
  {
    title: "ClinicBillingLocationAddressCity",
    dataIndex: "payer.billingAddress.city",
  },
  {
    title: "ClinicBillingLocationStateProvince",
    dataIndex: "payer.billingAddress.state",
  },
  {
    title: "ClinicBillingLocationZipPostalCode",
    dataIndex: "payer.billingAddress.zipCode",
  },
  {
    title: "ClinicBillingLocationCountry",
    dataIndex: "payer.billingAddress.country",
  },
  {
    title: "ClinicPhysicalLocationAddressLine1",
    dataIndex: "payer.contractAddress.line1",
  },
  {
    title: "ClinicPhysicalLocationAddressLine2",
    dataIndex: "payer.contractAddress.line2",
  },
  {
    title: "ClinicPhysicalLocationAddressCity",
    dataIndex: "payer.contractAddress.city",
  },
  {
    title: "ClinicPhysicalLocationStateProvince",
    dataIndex: "payer.contractAddress.state",
  },
  {
    title: "ClinicPhysicalLocationZipPostalCode",
    dataIndex: "payer.contractAddress.zipCode",
  },
  {
    title: "ClinicPhysicalLocationCountry",
    dataIndex: "payer.contractAddress.country",
  },
  {
    title: "ClinicBillingContactName",
    dataIndex: "",
    render: () => "YILUN CHEN",
  },
  {
    title: "ClinicBillingContactPhone",
    dataIndex: "",
    render: () => "6478284133",
  },
];
