import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import {
  allCapsSnakeCaseToTitleCase,
  ClientDocumentType,
  IClientDocument,
} from "@finni-health/shared";
import { Button, Col, Divider, message, Row, Skeleton, Typography, Upload } from "antd";
import { getDownloadURL, getMetadata, getStorage, ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";

import app from "../../services/firebase";

const { Link: LinkText } = Typography;

interface Props {
  isEditingDisabled: boolean;
  clientFileId: string;
  documents: IClientDocument[];
  onDocumentsChange: (documents: IClientDocument[]) => void;
}

const storage = getStorage(app);

export const ClientDocumentsFolders = ({
  documents,
  clientFileId,
  isEditingDisabled,
  onDocumentsChange,
}: Props) => {
  const [isUploading, setIsUploading] = useState<ClientDocumentType | undefined>();
  const [urlToMetadata, setUrlToMetadata] = useState<{
    [key: string]: { name: string; url: string };
  }>({});
  const [fileList, setFileList] = useState<File[]>([]);

  useEffect(() => {
    getFileNames().catch(() => {});
  }, [documents]);

  const getFileNames = async () => {
    let urlToMetadata: { [key: string]: { name: string; url: string } } = {};
    for (const doc of documents) {
      if (!urlToMetadata[doc.path]) {
        const name = await getFirebaseStoragePathFileName(doc.path);
        const url = await getDownloadURL(ref(storage, doc.path));
        urlToMetadata = {
          ...urlToMetadata,
          [doc.path]: { name, url },
        };
      }
    }
    setUrlToMetadata(urlToMetadata);
  };

  const getFirebaseStoragePathFileName = async (path: string) => {
    const fileRef = ref(storage, path);
    const metadata = await getMetadata(fileRef);

    return metadata.name;
  };

  const handleDeleteDocument = (document: IClientDocument) => {
    const tempDocuments = [...documents];
    tempDocuments.splice(documents.indexOf(document), 1);
    onDocumentsChange(tempDocuments);
    void message.success(`Successfully deleted ${urlToMetadata[document.path].name}`);
  };

  const getUploadProps = (type: ClientDocumentType) => {
    const uploadProps = {
      name: "file",
      multiple: false,
      showUploadList: false,
      beforeUpload: async (file: File) => {
        //file size larger than 30MB (30,000,000 Bytes)
        if (file.size > 30 * 1000000) {
          void message.error(
            `Your file ${file.name} is too large. Please upload files under 30MB.`
          );
          return false;
        }

        setIsUploading(type);

        await uploadBytes(
          ref(storage, `clientFiles/${clientFileId}/documents/${file.name}`),
          file
        ).then((results) => {
          setFileList([...fileList, file]);
          const path = results.ref.fullPath;
          const newDocument = {
            type,
            path,
          };
          onDocumentsChange([...documents, newDocument]);
          void message.success(`Successfully Uploaded ${file.name}`);

          setIsUploading(undefined);
        });

        // Antd has built in upload logic, override it with firebase storage upload
        return false;
      },
    };

    return uploadProps;
  };

  return (
    <>
      {Object.values(ClientDocumentType).map((type) => (
        <>
          <Divider orientation="left" style={{ marginBottom: 10 }}>
            {allCapsSnakeCaseToTitleCase(type)}
          </Divider>
          <Row justify="space-between">
            <Col span={20}>
              {documents
                ?.filter((document) => document.type === type)
                .map((document) =>
                  !urlToMetadata[document.path] ? (
                    <Skeleton key={document.path} paragraph={false} style={{ width: 300 }} />
                  ) : (
                    <Row key={document.path} justify="start" align="middle">
                      <LinkText
                        style={{
                          maxWidth: 500,
                          overflowX: "hidden",
                          whiteSpace: "nowrap",
                          overflow: "ellipsis",
                        }}
                      >
                        <a
                          target="_blank"
                          href={urlToMetadata[document.path].url}
                          rel="noreferrer"
                          style={{
                            cursor: "pointer",
                            marginBottom: 10,
                            marginLeft: 20,
                          }}
                        >
                          {urlToMetadata[document.path].name}
                        </a>
                      </LinkText>
                      {!isEditingDisabled && (
                        <Button
                          type="text"
                          icon={<DeleteOutlined style={{ fontSize: 12, color: "red" }} />}
                          onClick={() => handleDeleteDocument(document)}
                        />
                      )}
                    </Row>
                  )
                )}
            </Col>
            <Upload {...getUploadProps(type)} disabled={isEditingDisabled}>
              <Button
                icon={<UploadOutlined />}
                loading={isUploading === type}
                disabled={isEditingDisabled || (!!isUploading && isUploading !== type)}
              >
                Upload
              </Button>
            </Upload>
          </Row>
        </>
      ))}
    </>
  );
};
