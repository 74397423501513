import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {
  AppointmentLocation,
  BillingCode,
  IBilledAppointment,
  IClient,
  IUser,
  Modifier,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Button, DatePicker, message, Row, Space, Statistic, Table, Tag, Typography } from "antd";
import { Timestamp } from "firebase/firestore";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { IoSchoolOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, ERROR_MESSAGE } from "../../consts";
import { getAppointmentLocationText } from "../../helpers/appointments";
import { exportCsv } from "../../helpers/csv";
import * as FirestoreService from "../../services/firestore";
import { useUserClinics } from "../UserClinicsProvider";
import { ADONIS_BILLING_CSV_EXPORT_TEMPLATE } from "./adonisCsvTemplate";
import { JUNIPER_BILLING_CSV_EXPORT_TEMPLATE } from "./juniperCsvTemplate";

const { Text } = Typography;

const EXPORT_DATE_FORMAT = "MMMDD";

export const BilledAppointmentsTable: React.FC = () => {
  const { clinic } = useUserClinics();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isExportingCsv, setIsExportingCsv] = useState<boolean>(false);

  const [dateRange, setDateRange] = useState<[Moment, Moment]>([
    moment().add(-7, "d").startOf("week"),
    moment().add(-7, "d").endOf("week"),
  ]);
  const [appointments, setAppointments] = useState<IBilledAppointment[]>([]);
  const [clientFilters, setClientFilters] = useState<string[]>();
  const [createdAtFilters, setCreatedAtFilters] = useState<string[]>();
  const [exportedAtFilters, setExportedAtFilters] = useState<string[]>();

  useEffect(() => {
    fetchData().catch(() => {});
  }, [clinic, dateRange]);

  const fetchData = async () => {
    setLoading(true);
    const appointments = await FirestoreService.getAllBilledAppointmentsByClinicIdAndRange({
      clinicId: clinic.id,
      startMs: dateRange[0].startOf("day").valueOf(),
      endMs: dateRange[1].endOf("day").valueOf(),
    });
    setAppointments(appointments);
    setLoading(false);
  };

  const handleSetDateRange = (dates: any) => {
    if (!_.isEmpty(dates)) {
      setDateRange(dates);
    }
  };

  const printCSV = async () => {
    setIsExportingCsv(true);
    try {
      const addedQCodes = appointments
        .map((appt: IBilledAppointment) => {
          if (appt.location === AppointmentLocation.TELEHEALTH) {
            const apptCopy = _.cloneDeep(appt);
            apptCopy.id = apptCopy.id + "q";
            apptCopy.billingCode = "Q3014" as BillingCode;
            apptCopy.chargeCents = 2483;
            apptCopy.units = 1;
            apptCopy.modifiers = [Modifier.MOD_95];

            return [appt, apptCopy];
          } else {
            return appt;
          }
        })
        .flat();

      const csvName = `billing-${dateRange[0].format(EXPORT_DATE_FORMAT)}-${dateRange[1].format(
        EXPORT_DATE_FORMAT
      )}`;

      exportCsv(`juniper-${csvName}`, addedQCodes, JUNIPER_BILLING_CSV_EXPORT_TEMPLATE);
      exportCsv(`adonis-${csvName}`, addedQCodes, ADONIS_BILLING_CSV_EXPORT_TEMPLATE);

      await Promise.all(
        appointments.map(
          async (billedAppointment) =>
            !billedAppointment.exportedAt &&
            FirestoreService.markExportedBilledAppointment({
              id: billedAppointment.id,
            })
        )
      );

      await fetchData();
    } catch (err) {
      void message.error(ERROR_MESSAGE);
    }
    setIsExportingCsv(false);
  };

  const getTotalAmountFromBilledAppointments = (modifiers?: Modifier) => {
    return (
      appointments.reduce((acc, curr) => {
        if (
          !modifiers ||
          _.isEmpty(modifiers) ||
          curr.modifiers.some((mod) => modifiers.includes(mod))
        ) {
          return acc + curr.chargeCents;
        } else {
          return acc;
        }
      }, 0) / 100
    );
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: { currentDataSource: IBilledAppointment[] }
  ) => {
    setClientFilters(filters.client);
    setCreatedAtFilters(filters.createdAt);
    setExportedAtFilters(filters.exportedAt);
    setAppointments(extra.currentDataSource);
  };

  const getTableColumns = () => {
    const tableColumns = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "Session",
        dataIndex: "startMs",
        sorter: (a: IBilledAppointment, b: IBilledAppointment) => a.startMs - b.startMs,
        render: (_: number, row: IBilledAppointment) => {
          const start = moment(row.startMs);
          const end = moment(row.endMs);
          const duration = moment.duration(end.diff(start));

          return (
            <>
              <Text strong style={{ marginRight: 10 }}>{`${start.format(
                DISPLAY_DATE_FORMAT
              )}`}</Text>
              <br />
              <Text style={{ marginRight: 10 }}>
                {`${start.format(DISPLAY_TIME_FORMAT)} — ${end.format(DISPLAY_TIME_FORMAT)}`}
              </Text>
              <br />
              <Text>
                {`${Math.floor(duration.asHours())} hours ${duration.asMinutes() % 60} mins`}
              </Text>
            </>
          );
        },
      },
      {
        title: "Client",
        dataIndex: "client",
        width: 100,
        filters: appointments
          .filter((appt: IBilledAppointment, index) => {
            return (
              appointments.findIndex(
                (duplicateAppt) => duplicateAppt.client.id === appt.client.id
              ) == index
            );
          })
          .sort((a: IBilledAppointment, b: IBilledAppointment) =>
            a.client.alias.localeCompare(b.client.alias)
          )
          .map((appt: IBilledAppointment) => ({
            text: `${appt.client.alias} - ${appt.client.firstName} ${appt.client.lastName}`,
            value: appt.client.id,
          })),
        filteredValue: clientFilters,
        onFilter: (value: any, row: IBilledAppointment) => row.client.id === value,
        render: (client: IClient, row: IBilledAppointment) => {
          return (
            <>
              <Row style={{ marginBottom: 10 }}>
                <Link to={`../client-profile/${client.id}`}>
                  <IoSchoolOutline style={{ fontSize: 18, marginBottom: -3, marginRight: 3 }} />
                  {client.alias}
                </Link>
              </Row>
              <Row>
                <Tag>{row.payer.name}</Tag>
              </Row>
            </>
          );
        },
      },
      {
        title: "Rendering Provider",
        dataIndex: "renderingUser",
        width: 180,
        render: (renderingUser: IUser) => {
          return <>{`${renderingUser.firstName[0]}. ${renderingUser.lastName}`}</>;
        },
      },
      {
        title: "Billing Provider",
        dataIndex: "billingUser",
        width: 180,
        render: (billingUser: IUser) => {
          return <>{`${billingUser.firstName[0]}. ${billingUser.lastName}`}</>;
        },
      },
      {
        title: "Code",
        dataIndex: "billingCode",
      },
      {
        title: "Modifiers",
        dataIndex: "modifiers",
        render: (modifiers: string[]) => modifiers.join(", "),
      },
      {
        title: "Location",
        dataIndex: "location",
        render: (location: AppointmentLocation) => getAppointmentLocationText(location),
      },
      {
        title: "Units",
        dataIndex: "units",
        width: 100,
        render: (units: number) => units.toFixed(0),
      },
      {
        title: "Charge",
        dataIndex: "chargeCents",
        render: (chargeCents: number) => `$${(chargeCents / 100).toFixed(2)}`,
      },
      {
        title: "Note",
        dataIndex: "note",
        render: () => <CheckCircleTwoTone twoToneColor={COLORS.GREEN} />,
      },
      {
        title: "Finalized At",
        dataIndex: "createdAt",
        render: (createdAt: Timestamp) => moment(createdAt.toDate()).format(DISPLAY_DATE_FORMAT),
        filters: appointments
          .filter((appt: IBilledAppointment, index) => {
            return (
              appointments.findIndex(
                (duplicateAppt) =>
                  moment((duplicateAppt.createdAt as Timestamp).toDate()).format(
                    DISPLAY_DATE_FORMAT
                  ) === moment((appt.createdAt as Timestamp).toDate()).format(DISPLAY_DATE_FORMAT)
              ) == index
            );
          })
          .sort((a: IBilledAppointment, b: IBilledAppointment) =>
            moment((a.createdAt as Timestamp).toDate()).isBefore(
              moment((b.createdAt as Timestamp).toDate())
            )
              ? -1
              : 1
          )
          .map((appt: IBilledAppointment) => ({
            text: moment((appt.createdAt as Timestamp).toDate()).format(DISPLAY_DATE_FORMAT),
            value: moment((appt.createdAt as Timestamp).toDate()).format(DISPLAY_DATE_FORMAT),
          })),
        filteredValue: createdAtFilters,
        onFilter: (value: any, row: IBilledAppointment) =>
          moment((row.createdAt as Timestamp).toDate()).format(DISPLAY_DATE_FORMAT) === value,
      },
      {
        title: "Exported",
        dataIndex: "exportedAt",
        render: (exportedAt: Timestamp) =>
          !_.isUndefined(exportedAt) ? (
            <CheckCircleTwoTone twoToneColor={COLORS.GREEN} />
          ) : (
            <CloseCircleTwoTone twoToneColor={COLORS.RED} />
          ),
        filters: appointments
          .filter((appt: IBilledAppointment, index) => {
            return (
              appointments.findIndex(
                (duplicateAppt) =>
                  !_.isUndefined(duplicateAppt.exportedAt) === !_.isUndefined(appt.exportedAt)
              ) == index
            );
          })
          .sort((a: IBilledAppointment, b: IBilledAppointment) =>
            !_.isUndefined(a.exportedAt) && _.isUndefined(b.exportedAt) ? 1 : -1
          )
          .map((appt: IBilledAppointment) => ({
            text: !_.isUndefined(appt.exportedAt) ? "Yes" : "No",
            value: !_.isUndefined(appt.exportedAt),
          })),
        filteredValue: exportedAtFilters,
        onFilter: (value: any, row: IBilledAppointment) => !_.isUndefined(row.exportedAt) === value,
      },
    ];

    return tableColumns;
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <Space size="large">
          <Statistic
            loading={isLoading}
            title={"Total"}
            prefix="$"
            value={getTotalAmountFromBilledAppointments().toFixed(2)}
          />
          <Statistic
            loading={isLoading}
            title={"RBT (U1)"}
            prefix="$"
            value={getTotalAmountFromBilledAppointments(Modifier.U1).toFixed(2)}
          />
          <Statistic
            loading={isLoading}
            title={"BCBA (U3)"}
            prefix="$"
            value={getTotalAmountFromBilledAppointments(Modifier.U3).toFixed(2)}
          />
        </Space>
      </Row>
      <Row>
        <Space size="large" style={{ marginBottom: 20 }}>
          <DatePicker.RangePicker
            value={dateRange}
            onChange={handleSetDateRange}
            format={DISPLAY_DATE_FORMAT}
            ranges={{
              "This week": [moment().startOf("week"), moment().endOf("week")],
              "Last week": [
                moment().add(-7, "d").startOf("week"),
                moment().add(-7, "d").endOf("week"),
              ],
              "Last 2 weeks": [
                moment().add(-14, "d").startOf("week"),
                moment().add(-7, "d").endOf("week"),
              ],
            }}
          />
          <Button
            type="primary"
            onClick={printCSV}
            loading={isExportingCsv}
            disabled={_.isEmpty(appointments)}
          >
            {`Export CSV (${appointments.length})`}
          </Button>
        </Space>
      </Row>
      <Table
        columns={getTableColumns()}
        dataSource={appointments}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </>
  );
};
