import { Credential, ICredential } from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { DatePicker, Input, Row, Select } from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { AiOutlineCalendar, AiOutlineDown, AiOutlineNumber } from "react-icons/ai";
import { HiOutlineBellAlert } from "react-icons/hi2";

import { DISPLAY_DATE_FORMAT } from "../../consts";

const EXPIRY_REMINDER_OPTIONS = [
  { label: "7 days before expiry", value: 7 },
  { label: "15 days before expiry", value: 15 },
  { label: "1 month before expiry", value: 30 },
  { label: "3 months before expiry", value: 90 },
];

const MS_IN_DAY = 86400000;

interface IProps {
  type: Credential;
  credential: ICredential | undefined;
  updateCredential: (value: ICredential) => void;
  [props: string]: any;
}

export const CredentialInput: React.FC<IProps> = ({ ...params }) => {
  //input params
  const { type, credential, updateCredential, ...props } = params;

  //form
  const [selectedIdentifier, setSelectedIdentifier] = useState<string>("");
  const [selectedExpiryDate, setSelectedExpiryDate] = useState<Moment | undefined>(undefined);
  const [selectedExpiryReminder, setSelectedExpiryReminder] = useState<any>({});

  //expiry visualization
  const [daysToExpiry, setDaysToExpiry] = useState<number>(0);
  const [isExpiring, setIsExpiring] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const currentTime = moment().valueOf();

  useEffect(() => {
    setSelectedIdentifier(credential?.identifier || "");
    setSelectedExpiryDate(credential?.expiryMs ? moment(credential?.expiryMs) : undefined);
    setSelectedExpiryReminder(
      EXPIRY_REMINDER_OPTIONS.find(
        (option: any) =>
          option.value * MS_IN_DAY >=
          (credential?.expiryMs || 0) - (credential?.expiryWarningOffsetMs || 0)
      ) || EXPIRY_REMINDER_OPTIONS[0]
    );
  }, [credential]);

  const refreshComponent = () => {
    const tempCredential = {} as ICredential;
    tempCredential.type = credential?.type || type;
    tempCredential.expiryMs = selectedExpiryDate?.valueOf() || 0;
    tempCredential.expiryWarningOffsetMs =
      tempCredential?.expiryMs - selectedExpiryReminder.value * MS_IN_DAY;
    tempCredential.identifier = selectedIdentifier;
    if (!_.isEmpty(tempCredential?.identifier) && !_.isEqual(tempCredential, credential)) {
      updateCredential(tempCredential);
    }
    if (currentTime > tempCredential.expiryWarningOffsetMs && tempCredential?.expiryMs > 0) {
      setDaysToExpiry(Math.floor((tempCredential?.expiryMs - currentTime) / MS_IN_DAY));
      setIsExpiring(true);
    } else {
      setIsExpiring(false);
    }
  };

  useEffect(() => {
    refreshComponent();
  }, [selectedExpiryDate, selectedExpiryReminder, selectedIdentifier]);

  return (
    <div {...props}>
      <Input
        prefix={<AiOutlineNumber style={{ marginLeft: -5, marginRight: 5 }} />}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        suffix={
          !isFocused && isExpiring ? (
            <text style={{ color: COLORS.RED }}>
              {daysToExpiry > 0
                ? `EXPIRING IN ${daysToExpiry} DAY${daysToExpiry === 1 ? "" : "S"}`
                : `CREDENTIAL EXPIRED ${Math.abs(daysToExpiry)} DAY${
                    Math.abs(daysToExpiry) === 1 ? "" : "S"
                  } AGO`}
            </text>
          ) : null
        }
        placeholder={`${type} Number`}
        style={{
          color: isExpiring ? COLORS.RED : undefined,
          borderColor: isExpiring ? COLORS.RED : undefined,
        }}
        value={selectedIdentifier}
        onChange={(e) => {
          setSelectedIdentifier(e.target.value);
        }}
      />
      <Row style={{ marginTop: 2 }}>
        <AiOutlineCalendar style={{ color: "gray", marginTop: 8, marginRight: 5, marginLeft: 5 }} />
        <text style={{ marginTop: 4, color: "gray" }}>Expiry Date:</text>
        <DatePicker
          bordered={false}
          size="middle"
          suffixIcon={null}
          placeholder={DISPLAY_DATE_FORMAT}
          value={selectedExpiryDate}
          onSelect={(date) => {
            setSelectedExpiryDate(date);
          }}
        />
        <HiOutlineBellAlert style={{ color: "gray", marginTop: 8, marginRight: 5 }} />
        <text style={{ marginTop: 4, color: "gray" }}>Alert:</text>
        <Select
          bordered={false}
          style={{ width: 220, marginTop: -1, marginLeft: -2 }}
          value={selectedExpiryReminder.value}
          onChange={(value) => {
            setSelectedExpiryReminder(
              EXPIRY_REMINDER_OPTIONS.find((option) => option.value === value)!
            );
          }}
          suffixIcon={<AiOutlineDown style={{ marginLeft: -50 }} />}
        >
          {Object.values(EXPIRY_REMINDER_OPTIONS).map((expiry_reminder) => (
            <Select.Option value={expiry_reminder.value} key={expiry_reminder.value}>
              {expiry_reminder.label}
            </Select.Option>
          ))}
        </Select>
      </Row>
    </div>
  );
};
