import { FileTextTwoTone, IdcardTwoTone, SafetyCertificateTwoTone } from "@ant-design/icons";
import {
  BillingCode,
  getCurrentActiveAuth,
  IClientGuardianDetails,
  isPayerAuthRequired,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Row, Space, Tooltip, Typography } from "antd";
import _ from "lodash";
import { useMemo } from "react";

import { isDocumentsComplete } from "../../helpers/clientFiles";
import { EDIT_CLIENT_FILE_MODAL_TABLE_TABS } from "../EditClientFileModal/EditClientFileModal";
import { useUserClinics } from "../UserClinicsProvider";
import { IntakeStatusTag } from "./IntakeStatusTag";

const { Link: Linktext } = Typography;

interface Props {
  clientGuardianDetails: IClientGuardianDetails;
  setEditClientFileModalData: (value: IClientGuardianDetails) => void;
  setEditClientFileModalDefaultTab: (value: EDIT_CLIENT_FILE_MODAL_TABLE_TABS) => void;
  setIsEditClientFileModalVisible: (value: boolean) => void;
}

export const IntakeTableIntakeStatus = ({
  clientGuardianDetails,
  setEditClientFileModalData,
  setEditClientFileModalDefaultTab,
  setIsEditClientFileModalVisible,
}: Props) => {
  const { clientFile } = clientGuardianDetails;
  const { clinic } = useUserClinics();

  // Payer Info
  const photoUrls = clientFile?.payers?.primary?.photoUrls || [];
  const isPayerPhotosUploaded = photoUrls && photoUrls.length > 1 && photoUrls.indexOf("") === -1;
  const isPayerInfoComplete = useMemo(() => {
    const { payerId, groupNum, memberNum, policyHolder, policyHolderRelationship } =
      clientFile?.payers?.primary || {};
    return (
      payerId && groupNum && memberNum && !_.isEmpty(policyHolder) && !!policyHolderRelationship
    );
  }, [clientFile]);
  const isInsuranceComplete = isPayerPhotosUploaded || isPayerInfoComplete || false;

  // Diagnosis Docs
  const isDiagnosisDocsUploaded = isDocumentsComplete(clientFile?.documents);

  const isAssessmentAuthRequired =
    isPayerAuthRequired(
      clinic.address.state,
      clientFile?.payers.primary?.payerId || "",
      BillingCode.CODE_97151,
      []
    ) || true;

  const hasAuth = getCurrentActiveAuth(clientFile?.payers?.primary ?? null) !== null;

  const renderStatusIcon = (
    tooltip: string,
    Icon: React.FC<any>,
    color: string,
    editClientFileTab: EDIT_CLIENT_FILE_MODAL_TABLE_TABS
  ) => {
    return (
      <Tooltip title={tooltip}>
        <Linktext
          onClick={() => {
            setEditClientFileModalData(clientGuardianDetails);
            setEditClientFileModalDefaultTab(editClientFileTab);
            setIsEditClientFileModalVisible(true);
          }}
        >
          <span
            style={{
              cursor: "pointer",
              display: "block",
              width: 30,
              height: 30,
              borderRadius: "50%",
              background: "#fff",
              border: `1px solid ${color}`,
              transition: "all 0.1s ease",
              textAlign: "center",
            }}
          >
            <Icon
              twoToneColor={color}
              style={{
                position: "relative",
                top: 4,
              }}
            />
          </span>
        </Linktext>
      </Tooltip>
    );
  };

  return (
    <Row justify="center">
      <Row style={{ width: "100%", position: "relative", top: -5 }}>
        <Space size="small">
          <IntakeStatusTag status={clientFile?.intakeStatus} />
          {clientFile?.isHot && "🔥"}
          {clientFile?.intakeStatusNotes?.toLowerCase().includes("spanish") && "ES"}
        </Space>
      </Row>
      <Row justify="space-around" style={{ width: 170, position: "relative", top: 5 }}>
        {renderStatusIcon(
          isInsuranceComplete ? "Insurance Info Complete" : "Click to add Insurance Info",
          IdcardTwoTone,
          isInsuranceComplete ? COLORS.GREEN : "red",
          EDIT_CLIENT_FILE_MODAL_TABLE_TABS.PAYERS
        )}
        {renderStatusIcon(
          isDiagnosisDocsUploaded ? "Diagnosis Docs Uploaded" : "Click to upload Diagnosis Docs",
          FileTextTwoTone,
          isDiagnosisDocsUploaded ? COLORS.GREEN : "red",
          EDIT_CLIENT_FILE_MODAL_TABLE_TABS.DOCUMENTS
        )}
        {renderStatusIcon(
          !isAssessmentAuthRequired && !hasAuth
            ? "No auth required for 97151"
            : hasAuth
            ? "Auth added"
            : "Click to add Auth",
          SafetyCertificateTwoTone,
          !isAssessmentAuthRequired && !hasAuth ? COLORS.YELLOW : hasAuth ? COLORS.GREEN : "red",
          EDIT_CLIENT_FILE_MODAL_TABLE_TABS.PAYERS
        )}
      </Row>
    </Row>
  );
};
