import { IClientFile, MedicalServices } from "@finni-health/shared";
import { Col, Form, Input, Row, Select, Switch, Typography } from "antd";

const { Title } = Typography;
const { TextArea } = Input;

interface Props {
  isEditingDisabled: boolean;
  tempClientFile: IClientFile;
  updateTempClientFile: (source: Partial<IClientFile>) => void;
  setTempClientFile: (source: IClientFile) => void;
}

export const ClientMedicalHistorySection = ({
  isEditingDisabled,
  tempClientFile,
  updateTempClientFile,
  setTempClientFile,
}: Props) => {
  return (
    <>
      <Title id={"medicalHistory"} level={2} style={{ marginTop: 20 }}>
        Medical History
      </Title>
      <Title id={"diagnosesAndMedications"} level={3}>
        Diagnoses and Medications
      </Title>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Current and Past Diagnoses">
            <TextArea
              autoSize={true}
              disabled={isEditingDisabled}
              value={tempClientFile?.medicalHistory?.existingDiagnosis}
              onChange={(e) => {
                updateTempClientFile({
                  medicalHistory: {
                    ...tempClientFile?.medicalHistory,
                    existingDiagnosis: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Current and Past Medications">
            <TextArea
              autoSize={true}
              disabled={isEditingDisabled}
              value={tempClientFile?.medicalHistory?.medication}
              onChange={(e) => {
                updateTempClientFile({
                  medicalHistory: {
                    ...tempClientFile?.medicalHistory,
                    medication: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={"family"} level={3}>
        Family
      </Title>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Conditions in Family History">
            <TextArea
              autoSize={true}
              disabled={isEditingDisabled}
              value={tempClientFile?.medicalHistory?.mentalIllness}
              onChange={(e) => {
                updateTempClientFile({
                  medicalHistory: {
                    ...tempClientFile?.medicalHistory,
                    mentalIllness: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Living Situation">
            <TextArea
              autoSize={true}
              disabled={isEditingDisabled}
              value={tempClientFile?.medicalHistory?.livingSituation}
              onChange={(e) => {
                updateTempClientFile({
                  medicalHistory: {
                    ...tempClientFile?.medicalHistory,
                    livingSituation: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={"services"} level={3}>
        Services
      </Title>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Current Services">
            <Select
              showSearch
              disabled={isEditingDisabled}
              mode="tags"
              value={tempClientFile?.medicalHistory?.currentServices}
              filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e: string[]) => {
                setTempClientFile({
                  ...tempClientFile,
                  medicalHistory: {
                    ...tempClientFile?.medicalHistory,
                    currentServices: e,
                  },
                });
              }}
            >
              {Object.keys(MedicalServices).map((MedicalService) => (
                <Select.Option key={MedicalService}>{MedicalService}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Past Services">
            <Select
              showSearch
              disabled={isEditingDisabled}
              mode="tags"
              value={tempClientFile?.medicalHistory?.pastServices}
              filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e: string[]) => {
                setTempClientFile({
                  ...tempClientFile,
                  medicalHistory: {
                    ...tempClientFile?.medicalHistory,
                    pastServices: e,
                  },
                });
              }}
            >
              {Object.keys(MedicalServices).map((MedicalService) => (
                <Select.Option key={MedicalService}>{MedicalService}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Title id={"impairments"} level={3}>
        Impairments
      </Title>
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item label="Vision or Hearing Impairments" style={{ alignItems: "center" }}>
            <Switch
              disabled={isEditingDisabled}
              checked={tempClientFile?.medicalHistory?.visionHearingImpairment}
              onChange={(e: boolean) => {
                updateTempClientFile({
                  medicalHistory: {
                    ...tempClientFile?.medicalHistory,
                    visionHearingImpairment: e,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Other Impairments">
            <Select
              showSearch
              disabled={isEditingDisabled}
              mode="tags"
              value={tempClientFile?.medicalHistory?.treatmentImpairments}
              filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e: string[]) => {
                setTempClientFile({
                  ...tempClientFile,
                  medicalHistory: {
                    ...tempClientFile?.medicalHistory,
                    treatmentImpairments: e,
                  },
                });
              }}
            >
              {Object.keys(MedicalServices).map((MedicalService) => (
                <Select.Option key={MedicalService}>{MedicalService}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
