import { CloseOutlined } from "@ant-design/icons";
import { IUser } from "@finni-health/shared";
import { Button, Col, DatePicker, Divider, Progress, Row, Skeleton, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";

import { DISPLAY_DATE_FORMAT } from "../../consts";
import { getUserHoursForWeek } from "../../helpers/schedules";
import * as FirestoreService from "../../services/firestore";

const { Text } = Typography;

export interface ITherapistBillingSummaryProps {
  closePopup: () => void;
  therapist: IUser;
  selectedWeek: moment.Moment;
}

const RANGE_PRESETS: Record<string, [moment.Moment, moment.Moment]> = {
  "This Week": [moment().startOf("week"), moment().endOf("week")],
  "Last 2 weeks": [
    moment().subtract(2, "weeks").startOf("week"),
    moment().subtract(1, "week").endOf("week"),
  ],
  "This month": [moment().startOf("month"), moment().endOf("month")],
  "Last month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
};

export const TherapistBillingSummary = ({
  closePopup,
  therapist,
}: ITherapistBillingSummaryProps) => {
  const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment]>([
    moment().startOf("week"),
    moment().endOf("week"),
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    [directCompleted, directScheduled, indirectCompleted, indirectScheduled],
    setTherapistSummary,
  ] = useState<number[]>([0, 0, 0, 0]);

  useEffect(() => {
    getHoursForRange().catch(() => {});
  }, [dateRange]);

  const getHoursForRange = async () => {
    setIsLoading(true);
    const [startDate, endDate] = dateRange;
    const [calendarAppts, indirects, completedAppointments] = await Promise.all([
      FirestoreService.getAppointmentsForUserAndRange({
        clinicId: therapist.clinicId,
        startMs: startDate.startOf("day").valueOf(),
        endMs: endDate.endOf("day").valueOf(),
        email: therapist.email,
        timeZone: moment.tz.guess(),
      }),
      FirestoreService.getIndirectsByClinicUserAndRange(
        therapist.email,
        therapist.clinicId,
        startDate.startOf("day").valueOf(),
        endDate.endOf("day").valueOf()
      ),
      FirestoreService.getCompletedAppointmentByClinicUserAndRange(
        therapist.id,
        therapist.clinicId,
        startDate.startOf("day").valueOf(),
        endDate.endOf("day").valueOf()
      ),
    ]);

    const {
      directCompletedHours,
      directScheduledHours,
      indirectCompletedHours,
      indirectScheduledHours,
    } = getUserHoursForWeek(therapist, calendarAppts, completedAppointments, indirects);

    setTherapistSummary([
      directCompletedHours,
      directScheduledHours,
      indirectCompletedHours,
      indirectScheduledHours,
    ]);
    setIsLoading(false);
  };

  const getDateRangeHeaderContent = () => {
    const [start, end] = dateRange;
    for (const [content, [rangeStart, rangeEnd]] of Object.entries(RANGE_PRESETS)) {
      if (start.isSame(rangeStart, "day") && end.isSame(rangeEnd, "day")) {
        return content;
      }
    }
  };

  const renderProgress = (title: string, scheduled: number, completed: number) => {
    return (
      <>
        <Row>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "600",
              position: "relative",
            }}
          >
            {title}
          </Text>
          <Progress percent={(completed / scheduled) * 100} showInfo={false} />
        </Row>
        <Row justify="space-evenly">
          <Col style={{ margin: "0 5px", textAlign: "center" }}>
            <Text style={{ fontSize: 12 }}>Completed</Text>
            <div style={{ position: "relative", top: -5 }}>
              <Text style={{ fontSize: 12, fontWeight: "bold" }}>{completed}</Text>
              <Text style={{ fontSize: 9, fontWeight: "bold" }}> hours</Text>
            </div>
          </Col>
          <Col style={{ margin: "0 5px", textAlign: "center" }}>
            <Text style={{ fontSize: 12 }}>Scheduled</Text>
            <div style={{ position: "relative", top: -5 }}>
              <Text style={{ fontSize: 12, fontWeight: "bold" }}>{scheduled}</Text>
              <Text style={{ fontSize: 9, fontWeight: "bold" }}> hours</Text>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div style={{ width: 400 }}>
      <Row justify="space-between" style={{ marginBottom: 10 }} align="middle">
        <DatePicker.RangePicker
          value={dateRange}
          onChange={(range) =>
            setDateRange([range?.[0] || dateRange[0], range?.[1] || dateRange[1]])
          }
          format={DISPLAY_DATE_FORMAT}
          ranges={RANGE_PRESETS}
        />
        <div
          style={{
            float: "left",
            marginLeft: "20px",
            marginTop: "-32px",
            position: "absolute",
            background: "white",
            fontSize: "10px",
            padding: "0px 4px",
          }}
        >
          {getDateRangeHeaderContent() ||
            `Date Range (${dateRange[1].diff(dateRange[0], "days") + 1} days)`}
        </div>
        <Button type="text" size="small" onClick={closePopup}>
          <CloseOutlined />
        </Button>
      </Row>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row gutter={15} align="middle">
          <Col span={11}>{renderProgress("Direct", directScheduled, directCompleted)}</Col>
          <Divider type="vertical" style={{ height: "60px" }} />
          <Col span={11}>{renderProgress("Indirect", indirectScheduled, indirectCompleted)}</Col>
        </Row>
      )}
    </div>
  );
};
