import { IUser } from "@finni-health/shared";
import { Modal, Tabs } from "antd";
import { useState } from "react";

import { UserInfoForm } from "./UserInfoForm";
import { UserPayRatesForm } from "./UserPayRatesForm";

export const enum EDIT_USER_MODAL_TABLE_TABS {
  USER = "User",
  COMPENSATION = "Compensation",
}

interface Props {
  user: IUser;
  isVisible: boolean;
  hideModal: () => void;
  refreshCallback: () => Promise<void>;
  defaultTab?: EDIT_USER_MODAL_TABLE_TABS;
}

export const EditUserModal = ({
  user,
  isVisible,
  hideModal,
  refreshCallback,
  defaultTab,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState<EDIT_USER_MODAL_TABLE_TABS>(
    defaultTab || EDIT_USER_MODAL_TABLE_TABS.USER
  );

  const handleHideModal = () => {
    hideModal();
  };

  return (
    <Modal
      title={`Edit staff profile for ${user.firstName} ${user.lastName}`}
      onCancel={handleHideModal}
      open={isVisible}
      footer={false}
      width={800}
      style={{ top: 20 }}
    >
      <Tabs
        style={{ marginTop: -15 }}
        activeKey={selectedTab}
        onChange={(key) => {
          setSelectedTab(key as EDIT_USER_MODAL_TABLE_TABS);
        }}
        items={[
          {
            key: EDIT_USER_MODAL_TABLE_TABS.USER,
            label: EDIT_USER_MODAL_TABLE_TABS.USER,
            children: [
              <UserInfoForm
                key={user.id}
                user={user}
                isVisible={isVisible}
                hideModal={hideModal}
                refreshCallback={refreshCallback}
              />,
            ],
          },
          {
            key: EDIT_USER_MODAL_TABLE_TABS.COMPENSATION,
            label: EDIT_USER_MODAL_TABLE_TABS.COMPENSATION,
            children: [<UserPayRatesForm key={user.id} user={user} />],
          },
        ]}
      ></Tabs>
    </Modal>
  );
};
