import { red } from "@ant-design/colors";
import { DeleteOutlined } from "@ant-design/icons";
import {
  ICreateUserPayRateEndpointRequest,
  IDeleteUserPayRateEndpointRequest,
  IUser,
  IUserPayRate,
} from "@finni-health/shared";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { DISPLAY_DATE_FORMAT } from "../../consts";
import * as FirestoreService from "../../services/firestore";

const { Link: LinkText } = Typography;

interface IProps {
  user: IUser;
}

export const UserPayRatesForm: React.FC<IProps> = ({ user }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [tempPayRates, setTempPayRates] = useState<IUserPayRate[]>([]);
  const [payRates, setPayRates] = useState<IUserPayRate[]>([]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      fetchPayRates().catch(() => {});
    }
  }, [user]);

  const fetchPayRates = async () => {
    setIsLoading(true);
    const fetchedPayRates = await FirestoreService.getUserPayRatesByUserIdAndClinicId(
      user.id,
      user.clinicId
    );

    fetchedPayRates.sort((a: IUserPayRate, b: IUserPayRate) => (a.startMs < b.startMs ? -1 : 1));
    setPayRates(_.cloneDeep(fetchedPayRates));
    setTempPayRates(_.cloneDeep(fetchedPayRates));

    setIsLoading(false);
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      for (const payRate of tempPayRates) {
        // create new payRate
        if (payRate.id === "") {
          const createPayRateRequest: ICreateUserPayRateEndpointRequest = {
            clinicId: payRate.clinicId,
            userId: payRate.userId,
            startMs: payRate.startMs,
            directRateCents: payRate.directRateCents,
            indirectRateCents: payRate.indirectRateCents,
            salaryRateCents: payRate.salaryRateCents,
          };
          await FirestoreService.createUserPayRate(createPayRateRequest);
        } else if (
          // update existing payRate
          !_.isEqual(
            payRate,
            payRates.find((pR) => pR.id === payRate.id)
          )
        ) {
          await FirestoreService.updateUserPayRate(payRate);
        }
      }

      //refresh pay rates
      await fetchPayRates();
      setIsSaving(false);
    } catch (error: any) {
      void message.error(
        _.isEmpty(error?.message) ? "Error creating compensation" : error?.message
      );
    }
  };

  const handleAdd = () => {
    //create new payRate in local state
    const newPayRate: IUserPayRate = {
      id: "",
      clinicId: user.clinicId,
      userId: user.id,
      startMs: moment().valueOf(),
      directRateCents: 0,
      indirectRateCents: 0,
      salaryRateCents: 0,
      createdAt: 0 as any,
      updatedAt: 0 as any,
    };

    //update local state
    const updatedPayRates = [...tempPayRates, newPayRate];
    updatedPayRates.sort((a, b) => (a.startMs < b.startMs ? -1 : 1));
    setTempPayRates(updatedPayRates);
  };

  const handleUpdate = (updatePayRate: IUserPayRate) => {
    //update selected payRate in local state
    const updatedPayRates = tempPayRates.map((payRate) => {
      if (payRate.id === updatePayRate.id) {
        return updatePayRate;
      }
      return payRate;
    });

    setTempPayRates(updatedPayRates);
  };

  const handleDelete = async (payRate: IUserPayRate) => {
    try {
      const deletePayRateRequest: IDeleteUserPayRateEndpointRequest = {
        id: payRate.id,
        userId: payRate.userId,
      };
      await FirestoreService.deleteUserPayRate(deletePayRateRequest);

      await fetchPayRates();
    } catch (error: any) {
      void message.error(
        _.isEmpty(error?.message) ? "Error deleting compensation" : error?.message
      );
    }
  };

  const handleCancel = () => {
    setTempPayRates(_.cloneDeep(payRates));
  };

  const defaultColumns: any[] = [
    {
      title: "Salaried",
      dataIndex: "salaried",
      width: "10%",
      render: (_: any, record: IUserPayRate) => (
        <Row>
          <Checkbox
            style={{ marginLeft: "auto", marginRight: "auto" }}
            checked={record.salaryRateCents > 0}
            onChange={(e) => {
              if (e.target.checked) {
                record.directRateCents = record.indirectRateCents = 0;
                record.salaryRateCents = 4000000;
              } else {
                record.salaryRateCents = 0;
              }
              handleUpdate(record);
            }}
          />
        </Row>
      ),
    },
    {
      title: "Start",
      dataIndex: "startMs",
      sorter: {
        compare: (a: IUserPayRate, b: IUserPayRate) => a.startMs - b.startMs,
        multiple: 1,
      },
      sortOrder: "descend",
      render: (_: any, record: IUserPayRate) => (
        <DatePicker
          placeholder={DISPLAY_DATE_FORMAT}
          format={DISPLAY_DATE_FORMAT}
          allowClear={false}
          autoComplete="off"
          disabledDate={(d) =>
            d.isSameOrBefore(
              moment(tempPayRates[tempPayRates.indexOf(record) - 1]?.startMs || 0)
            ) ||
            d.isAfter(moment(tempPayRates[tempPayRates.indexOf(record) + 1]?.startMs || Infinity))
          }
          defaultPickerValue={moment(record.startMs).add(1, "days")}
          value={moment(record.startMs)}
          onChange={(value) => {
            if (value) {
              record.startMs = value.valueOf();
              handleUpdate(record);
            }
          }}
        />
      ),
    },
    {
      title: "Direct Rate",
      dataIndex: "directRateCents",
      render: (_: any, record: IUserPayRate) =>
        record.salaryRateCents > 0 ? null : (
          <InputNumber
            min={0}
            max={1000}
            prefix="$"
            style={{ width: "80px" }}
            value={record.directRateCents / 100}
            step="0.01"
            controls={false}
            onChange={(value) => {
              record.directRateCents = (value || 0) * 100;
              handleUpdate(record);
            }}
          />
        ),
    },
    {
      title: "Indirect Rate",
      dataIndex: "indirectRateCents",
      render: (_: any, record: IUserPayRate) =>
        record.salaryRateCents > 0 ? null : (
          <InputNumber
            min={0}
            max={1000}
            prefix="$"
            style={{ width: "80px" }}
            value={record.indirectRateCents / 100}
            step="0.01"
            controls={false}
            onChange={(value) => {
              record.indirectRateCents = (value || 0) * 100;
              handleUpdate(record);
            }}
          />
        ),
    },
    {
      title: "Salary",
      dataIndex: "salaryRateCents",
      render: (_: any, record: IUserPayRate) =>
        record.salaryRateCents > 0 ? (
          <InputNumber
            min={0}
            prefix="$"
            style={{ width: "100px" }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            value={record.salaryRateCents / 100}
            step="0.01"
            controls={false}
            onBlur={(e) => {
              record.salaryRateCents = (Number(e.target.value.replaceAll(",", "")) || 0) * 100;
              handleUpdate(record);
            }}
          />
        ) : null,
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: IUserPayRate) =>
        record.id !== "" && (
          <Row
            wrap={false}
            justify="center"
            align="middle"
            style={{ width: "100%", height: "100%" }}
          >
            <Space size="large">
              <Popconfirm
                title={`Are you sure you want to delete this Pay Rate?`}
                placement="bottomRight"
                onConfirm={async () => {
                  handleUpdate(record);
                  await handleDelete(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <div>
                  <Tooltip title="Delete">
                    <LinkText>
                      <DeleteOutlined
                        style={{
                          color: red.primary,
                          fontSize: 16,
                        }}
                      />
                    </LinkText>
                  </Tooltip>
                </div>
              </Popconfirm>
            </Space>
          </Row>
        ),
    },
  ].filter(
    (col) =>
      (col.dataIndex === "salaryRateCents" &&
        tempPayRates.some((payRate) => payRate.salaryRateCents > 0)) ||
      col.dataIndex !== "salaryRateCents"
  );

  return (
    <>
      <Col>
        <Button onClick={handleAdd} type="primary" style={{ marginBottom: 14, float: "right" }}>
          Add Compensation
        </Button>

        <Table
          style={{ width: "100%" }}
          bordered
          dataSource={tempPayRates}
          rowKey={(record) => record.id}
          columns={defaultColumns}
          pagination={false}
          loading={isLoading || isSaving}
        />
        <Row justify="end">
          <Space>
            <Button
              onClick={handleCancel}
              disabled={_(payRates).xorWith(tempPayRates, _.isEqual).isEmpty()}
              style={{ marginTop: 14, float: "right" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              disabled={_(payRates).xorWith(tempPayRates, _.isEqual).isEmpty()}
              type="primary"
              style={{ marginTop: 14, float: "right" }}
            >
              Save
            </Button>
          </Space>
        </Row>
      </Col>
    </>
  );
};
