import { Button, Col, Divider, Form, Row, Typography } from "antd";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React from "react";
import Lottie from "react-lottie-player";
import { Navigate, useNavigate } from "react-router-dom";

import astronautAnimation from "../lottie/astronaut.json";
import app from "../services/firebase";
import * as FirestoreService from "../services/firestore";

const { Text } = Typography;

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = Boolean(getAuth(app).currentUser);

  const signIn = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    auth.languageCode = "en";
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        FirestoreService.getUserById(user?.uid)
          .then((user) => {
            if (user.hasSignedUp) {
              navigate("/");
            }
          })
          .catch(() => {});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <Row style={{ height: "100vh", background: "#fbf7f0" }} justify="center" align="middle">
      <Col span={20} style={{ maxWidth: 400, marginTop: -75 }}>
        <Col style={{ marginBottom: 20 }}>
          <Lottie
            play
            animationData={astronautAnimation}
            style={{
              position: "relative",
              width: 125,
              height: 125,
              margin: "auto",
              bottom: -30,
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src="/logo.svg" height={50} />
          </div>
          <Row justify="center">
            <Text
              style={{
                fontFamily: "Reckless Neue",
                fontSize: 17,
                fontWeight: 700,
              }}
            >
              Mission Control
            </Text>
          </Row>
        </Col>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={signIn}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={false}>
              Continue with Google
            </Button>
          </Form.Item>
        </Form>

        <Divider>or</Divider>

        <Row justify="center">
          <Button onClick={signIn}>Create Account</Button>
        </Row>
      </Col>
    </Row>
  );
};
