import { red } from "@ant-design/colors";
import {
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
  MailOutlined,
  RightOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  ActiveStatus,
  capitalizeFirstLetter,
  getAllPayersForState,
  getPayerById,
  IAddress,
  IClientGuardianDetails,
  IGuardianClientDetails,
  IInquiry,
  InquiryStatus,
  IntakeStatus,
  IUser,
  PrequalifiedStatus,
  stringToColor,
  UserPermission,
} from "@finni-health/shared";
import {
  Badge,
  Button,
  Col,
  Collapse,
  Form,
  Input,
  List,
  message,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoHeartCircleOutline, IoSchoolOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";

import { EditClientFileModal } from "../components/EditClientFileModal/EditClientFileModal";
import { EDIT_CLIENT_FILE_MODAL_TABLE_TABS } from "../components/EditClientFileModal/EditClientFileModal";
import { InsuranceCardModal } from "../components/InsuranceCardModal";
import { ConvertInquiryModal } from "../components/Intake/ConvertInquiryModal";
import { CreateInquiryModal } from "../components/Intake/CreateInquiryModal";
import { EditInquiryModal } from "../components/Intake/EditInquiryModal";
import { IntakeStatusTag } from "../components/Intake/IntakeStatusTag";
import { IntakeTableIntakeStatus } from "../components/Intake/IntakeTableIntakeStatus";
import { useUserClinics } from "../components/UserClinicsProvider";
import { DISPLAY_DATE_FORMAT, ERROR_MESSAGE } from "../consts";
import { renderAddress } from "../helpers/address";
import { validateAllClientFileFields } from "../helpers/clientFiles";
import * as FirestoreService from "../services/firestore";

const { TextArea } = Input;
const { Title, Text, Link: LinkText } = Typography;
const { Search } = Input;

export enum TABLE_TABS {
  INQUIRIES = "inquires",
  PREQUALIFIED = "pre-qualified",
  ACTIVE = "active",
}

type IntakeRow = IGuardianClientDetails | IInquiry;

export const Intake: React.FC = () => {
  const { user, clinic } = useUserClinics();

  const { tab } = useParams<{ tab: TABLE_TABS }>();

  const [users, setUsers] = useState<IUser[]>([]);
  const [inquiries, setInquiries] = useState<IInquiry[]>([]);
  const [prequalifiedGuardians, setPrequalifiedGuardians] = useState<IGuardianClientDetails[]>([]);
  const [activeGuardians, setActiveGuardians] = useState<IGuardianClientDetails[]>([]);
  const [hiddenInquiries, setHiddenInquiries] = useState<IInquiry[]>([]);
  const [hiddenPrequalifiedGuardians, setHiddenPrequalifiedGuardians] = useState<
    IGuardianClientDetails[]
  >([]);
  const [hiddenActiveGuardians, setHiddenActiveGuardians] = useState<IGuardianClientDetails[]>([]);
  const [dataSource, setDataSource] = useState<IntakeRow[]>([]);
  const [hiddenDataSource, setHiddenDataSource] = useState<IntakeRow[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<TABLE_TABS>(TABLE_TABS.INQUIRIES);

  // Create Inquiry Modal
  const [isCreateInquiryModalVisible, setIsCreateInquiryModalVisible] = useState<boolean>(false);
  const hideCreateInquiryModal = () => {
    setIsCreateInquiryModalVisible(false);
  };

  // Edit Inquiry Modal
  const [isEditInquiryModalVisible, setIsEditInquiryModalVisible] = useState<boolean>(false);
  const [editInquiryModalInquiry, setEditInquiryModalInquiry] = useState<IInquiry>({} as IInquiry);
  const hideEditInquiryModal = () => {
    setIsEditInquiryModalVisible(false);
  };

  // Convert Inquiry Modal
  const [isConvertInquiryModalVisible, setIsConvertInquiryModalVisible] = useState<boolean>(false);
  const [convertInquiryModalInquiry, setConvertInquiryModalInquiry] = useState<IInquiry | null>(
    null
  );
  const hideConvertInquiryModal = () => {
    setConvertInquiryModalInquiry(null);
    setIsConvertInquiryModalVisible(false);
  };

  // Edit ClientFile Modal
  const [isEditClientFileModalVisible, setIsEditClientFileModalVisible] = useState<boolean>(false);
  const [editClientFileModalClientData, setEditClientFileModalClientData] =
    useState<IClientGuardianDetails | null>(null);
  const [editClientFileModalDefaultTab, setEditClientFileModalDefaultTab] = useState<
    EDIT_CLIENT_FILE_MODAL_TABLE_TABS | undefined
  >(undefined);
  const hideEditClientFileModal = () => {
    setEditClientFileModalClientData(null);
    setIsEditClientFileModalVisible(false);
  };

  // View Insurance Modal
  const [isViewInsuranceModalVisible, setIsViewInsuranceModalVisible] = useState<boolean>(false);
  const [viewInsuranceModalGuardian, setViewInsuranceModalGuardian] =
    useState<IClientGuardianDetails | null>(null);
  const hideViewInsuranceModalGuardian = () => {
    setViewInsuranceModalGuardian(null);
    setIsViewInsuranceModalVisible(false);
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      fetchData(true, false).catch(() => {});
    }
  }, [user]);

  useEffect(() => {
    if (tab && !_.isEmpty(tab)) {
      setSelectedTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    const { pathname } = location;
    const idx = pathname.indexOf("intake");
    const link = pathname.slice(0, idx + "intake".length) + "/" + selectedTab.toLowerCase();
    window.history.replaceState(null, "Mission Control - Finni Health", link);
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === TABLE_TABS.INQUIRIES) {
      setDataSource(inquiries);
      setHiddenDataSource(hiddenInquiries);
    } else if (selectedTab === TABLE_TABS.PREQUALIFIED) {
      setDataSource(prequalifiedGuardians);
      setHiddenDataSource(hiddenPrequalifiedGuardians);
    } else if (selectedTab === TABLE_TABS.ACTIVE) {
      setDataSource(activeGuardians);
      setHiddenDataSource(hiddenActiveGuardians);
    }

    if (searchString) {
      handleSearch({ target: { value: searchString } });
    }
  }, [prequalifiedGuardians, inquiries, activeGuardians, selectedTab]);

  const fetchData = async (showLoading = true, force = true) => {
    setIsTableLoading(showLoading);

    const [guardiansClientDetails, users, inquiries] = await Promise.all([
      FirestoreService.getGuardianClientDetailsByClinicId(user.clinicId),
      FirestoreService.getAllUsersForClinic(user.clinicId, force),
      FirestoreService.getAllInquiriesForClinic(user.clinicId, clinic.address?.state),
    ]);

    setUsers(users);
    setInquiries(sortInquiries(inquiries));
    setHiddenInquiries(sortInquiries(inquiries, true));
    setPrequalifiedGuardians(getPrequalifiedGuardians(guardiansClientDetails));
    setHiddenPrequalifiedGuardians(getPrequalifiedGuardians(guardiansClientDetails, true));
    setActiveGuardians(getActiveGuardians(guardiansClientDetails));
    setHiddenActiveGuardians(getActiveGuardians(guardiansClientDetails, true));

    setIsTableLoading(false);
  };

  const sortInquiries = (inquiries: IInquiry[], hidden = false) => {
    return inquiries
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      .filter((inquiry) =>
        hidden
          ? [InquiryStatus.UNQUALIFIED, InquiryStatus.NO_RESPONSE].includes(inquiry.intakeStatus)
          : ![InquiryStatus.UNQUALIFIED, InquiryStatus.NO_RESPONSE].includes(inquiry.intakeStatus)
      );
  };

  const getPrequalifiedGuardians = (
    guardiansClientsDetails: IGuardianClientDetails[],
    hidden = false
  ) => {
    return guardiansClientsDetails
      .filter(
        (guardianClientDetails) =>
          Object.values(PrequalifiedStatus).includes(
            guardianClientDetails.clientFile?.intakeStatus as unknown as any
          ) &&
          (hidden
            ? [IntakeStatus.UNQUALIFIED, IntakeStatus.CHURNED].includes(
                guardianClientDetails.clientFile?.intakeStatus
              )
            : ![IntakeStatus.UNQUALIFIED, IntakeStatus.CHURNED].includes(
                guardianClientDetails.clientFile?.intakeStatus
              ))
      )
      .sort((a, b) => (a.guardian.createdAt < b.guardian.createdAt ? 1 : -1));
  };

  const getActiveGuardians = (
    guardiansClientsDetails: IGuardianClientDetails[],
    hidden = false
  ) => {
    return guardiansClientsDetails
      .filter(
        (guardianClientDetails) =>
          Object.values(ActiveStatus).includes(
            guardianClientDetails.clientFile?.intakeStatus as unknown as any
          ) &&
          (hidden
            ? [IntakeStatus.UNQUALIFIED, IntakeStatus.CHURNED].includes(
                guardianClientDetails.clientFile?.intakeStatus
              )
            : ![IntakeStatus.UNQUALIFIED, IntakeStatus.CHURNED].includes(
                guardianClientDetails.clientFile?.intakeStatus
              ))
      )
      .sort((a, b) => (a.guardian.createdAt < b.guardian.createdAt ? 1 : -1));
  };

  const handleSearch = (e: any) => {
    const searchString = e.target.value;
    setSearchString(searchString);

    let unfilteredData: any[] = [];
    let unfilteredHiddenData: any[] = [];
    switch (selectedTab) {
      case TABLE_TABS.INQUIRIES:
        unfilteredData = inquiries;
        unfilteredHiddenData = hiddenInquiries;
        break;
      case TABLE_TABS.PREQUALIFIED:
        unfilteredData = prequalifiedGuardians;
        unfilteredHiddenData = hiddenPrequalifiedGuardians;
        break;
      case TABLE_TABS.ACTIVE:
        unfilteredData = activeGuardians;
        unfilteredHiddenData = hiddenActiveGuardians;
        break;
    }

    const filterFunction =
      selectedTab === TABLE_TABS.INQUIRIES
        ? (row: IInquiry) =>
            row.firstName?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.lastName?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.email?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.address?.city?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.intakeStatus?.toLowerCase().includes(searchString.toLowerCase())
        : (row: IGuardianClientDetails) =>
            row.client?.alias?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.client?.firstName?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.client?.lastName?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.client?.preferredName?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.guardian.firstName?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.guardian.lastName?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.guardian.email?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.clientFile?.address?.city?.toLowerCase().includes(searchString.toLowerCase()) ||
            row.clientFile?.intakeStatus?.toLowerCase().includes(searchString.toLowerCase());

    const filteredData = unfilteredData.filter(filterFunction);
    const filteredHiddenData = unfilteredHiddenData.filter(filterFunction);
    setDataSource(filteredData);
    setHiddenDataSource(filteredHiddenData);
  };

  const handleSaveQuickEditStatusNotes = async (rowId: string, intakeStatusNotes: string) => {
    try {
      if (selectedTab === TABLE_TABS.INQUIRIES) {
        await FirestoreService.updateInquiry({ id: rowId, intakeStatusNotes });
      } else {
        const clientDetails = await FirestoreService.getClientDetailsByGuardianId(rowId);

        await FirestoreService.updateClientFile({
          id: clientDetails.clientFile.id,
          intakeStatusNotes,
        });
      }
      await fetchData(false, true);
      void message.success("Notes saved");
    } catch (err) {
      void message.error(ERROR_MESSAGE);
    }
  };

  const handleUpdateAssignedBcba = async (id: string, assignedBcbaId: string) => {
    await FirestoreService.updateClientFile({
      id,
      assignedBcbaId,
    });

    await fetchData(false, true);
    void message.success("Changes saved");
  };

  const handleUpdateAssignedRbt = async (id: string, assignedRbtId: string) => {
    await FirestoreService.updateClientFile({
      id,
      assignedRbtId,
    });

    await fetchData(false, true);
    void message.success("Changes saved");
  };

  const handleConvertToActive = async (row: IGuardianClientDetails) => {
    await FirestoreService.updateClientFile({
      id: row.clientFile.id,
      intakeStatus: IntakeStatus.INTAKE_ASSESSMENT,
    });
    await fetchData(false, true);
  };

  const intakeColumns = () => {
    let columnsTemplate = [
      Table.EXPAND_COLUMN,
      {
        title: "Family",
        sorter: (a: IntakeRow, b: IntakeRow) => {
          const aLastName = "lastName" in a ? a.lastName : a.guardian.lastName;
          const bLastName = "lastName" in b ? b.lastName : b.guardian.lastName;
          return aLastName.localeCompare(bLastName);
        },
        render: (_: any, row: IntakeRow) => {
          return "id" in row ? (
            <>
              <IoHeartCircleOutline style={{ fontSize: 18, marginBottom: -3, marginRight: 3 }} />
              {`${row.firstName} ${row.lastName}`}
            </>
          ) : (
            <Space direction="vertical">
              <Tooltip title="View Guardian">
                <Link to={`../guardian-profile/${row.guardian.id}`}>
                  <IoHeartCircleOutline
                    style={{ fontSize: 18, marginBottom: -3, marginRight: 3 }}
                  />
                  {`${row.guardian.firstName} ${row.guardian.lastName}`}
                </Link>
              </Tooltip>
              {row.clientFile && row.clientFile.clientId && (
                <Tooltip title="View Client">
                  <Link
                    to={`/client-profile/${row.clientFile.clientId}`}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <IoSchoolOutline
                      style={{
                        fontSize: 18,
                        marginBottom: -3,
                        marginRight: 5,
                      }}
                    />
                    {row.client?.alias}
                  </Link>
                </Tooltip>
              )}
            </Space>
          );
        },
      },
      {
        title: "Contact",
        dataIndex: ["phoneNumber", "primary"],
        render: (phoneNumber: string, row: IntakeRow) => (
          <Space direction="vertical">
            <Text style={{ whiteSpace: "nowrap" }}>{phoneNumber}</Text>
            <Tooltip title="Email guardian">
              <LinkText
                style={{ whiteSpace: "nowrap" }}
                href={`mailto:${
                  "email" in row ? row.email : row.guardian.email
                }?subject=Reaching out from Finni 🦊`}
              >
                <MailOutlined /> {"email" in row ? row.email : row.guardian.email}
              </LinkText>
            </Tooltip>
          </Space>
        ),
      },
      selectedTab === TABLE_TABS.INQUIRIES
        ? {
            title: "Zip Code",
            render: (_: any, row: IInquiry) => {
              return (
                <>
                  {row.clinicId !== clinic.id && (
                    <Tooltip
                      title={
                        row.address?.state === clinic.address?.state
                          ? "Out of service areas"
                          : "Out of State"
                      }
                    >
                      <EnvironmentOutlined
                        style={{
                          color: row.address?.state === clinic.address?.state ? "orange" : "red",
                        }}
                      />
                    </Tooltip>
                  )}
                  {` ${row.address?.zipCode}`}
                </>
              );
            },
            sorter: (a: IntakeRow, b: IntakeRow) =>
              (a as IInquiry).address?.zipCode?.localeCompare((b as IInquiry).address.zipCode),
          }
        : {
            title: "Address",
            dataIndex: ["clientFile", "address"],
            render: (address: IAddress) => (
              <Row>
                <Col style={{ marginRight: 5 }}>
                  <LinkText>
                    <Popover content={renderAddress(address)}>
                      <EnvironmentOutlined
                        style={{
                          position: "relative",
                          top: 1,
                        }}
                      />
                    </Popover>
                  </LinkText>
                </Col>
                <Col>
                  <Text>{address?.city ? address?.city : "—"}</Text>
                  <br />
                  <Text>{address?.zipCode}</Text>
                </Col>
              </Row>
            ),
          },
      {
        title: "Primary Payer",
        render: (_, row: IGuardianClientDetails) => {
          const payer = getPayerById(
            clinic.address.state,
            row.clientFile?.payers?.primary?.payerId ?? null
          );

          return (
            payer && (
              <Row style={{ width: 150 }}>
                <Tooltip title="View Insurance">
                  <LinkText
                    onClick={() => {
                      setViewInsuranceModalGuardian(row as IClientGuardianDetails);
                      setIsViewInsuranceModalVisible(true);
                    }}
                  >
                    <Tag color={stringToColor(payer.name)} style={{ whiteSpace: "pre-line" }}>
                      <IdcardOutlined style={{ marginRight: 5 }} />
                      {payer.name}
                    </Tag>
                  </LinkText>
                </Tooltip>
              </Row>
            )
          );
        },
        filters: getAllPayersForState(clinic.address.state)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((payer) => ({
            text: (
              <Tag color={stringToColor(payer.name)}>
                <IdcardOutlined style={{ marginRight: 5 }} />
                {payer.name}
              </Tag>
            ),
            value: payer.payerId,
          })),
        onFilter: (value: any, row: IClientGuardianDetails) =>
          value === row.clientFile?.payers.primary?.payerId,
      },
      selectedTab === TABLE_TABS.INQUIRIES
        ? {
            title: "Status",
            dataIndex: "intakeStatus",
            sorter: {
              compare: (a: IInquiry, b: IInquiry) => a.intakeStatus.localeCompare(b.intakeStatus),
              multiple: 1,
            },
            render: (status: IntakeStatus, row: IInquiry) => (
              <Space size="small">
                <IntakeStatusTag
                  status={status}
                  style={{
                    maxWidth: 160,
                    whiteSpace: "pre-line",
                  }}
                />
                {row.isHot && "🔥"}
                {row.intakeStatusNotes?.toLowerCase().includes("spanish") && "ES"}
              </Space>
            ),
          }
        : {
            title: "Status",
            width: 1,
            sorter: {
              compare: (a: IGuardianClientDetails, b: IGuardianClientDetails) =>
                a.clientFile?.intakeStatus.localeCompare(b.clientFile?.intakeStatus),
              multiple: 1,
            },
            render: (clientGuardianDetails: IGuardianClientDetails) => (
              <IntakeTableIntakeStatus
                clientGuardianDetails={clientGuardianDetails as IClientGuardianDetails}
                setEditClientFileModalData={setEditClientFileModalClientData}
                setEditClientFileModalDefaultTab={setEditClientFileModalDefaultTab}
                setIsEditClientFileModalVisible={setIsEditClientFileModalVisible}
              />
            ),
            filters: [{ text: "Hot Lead 🔥" as any, value: "isHot" }].concat(
              Object.values(
                selectedTab === TABLE_TABS.PREQUALIFIED ? PrequalifiedStatus : ActiveStatus
              ).map((intakeStatus) => ({
                text: <IntakeStatusTag status={intakeStatus as IntakeStatus} />,
                value: intakeStatus,
              }))
            ),
            onFilter: (value: any, row: IGuardianClientDetails) => {
              if (value === "isHot") {
                return row.clientFile?.isHot;
              } else {
                return row.clientFile?.intakeStatus === value;
              }
            },
          },
      {
        width: 150,
        title: "BCBA",
        dataIndex: ["clientFile", "assignedBcbaId"],
        render: (assignedBcbaId: string, row: IGuardianClientDetails) => {
          const id = "id" in row ? row.id : row.guardian.id;
          return (
            <Select
              key={id as string}
              showSearch
              allowClear
              optionFilterProp="children"
              defaultValue={assignedBcbaId}
              style={{ width: 150 }}
              onChange={(value) => handleUpdateAssignedBcba(row.clientFile.id, value || "")}
            >
              {users
                .filter((user) =>
                  user.permissions.some((permission) => [UserPermission.BCBA].includes(permission))
                )
                .map((user) => (
                  <Select.Option
                    key={user.id}
                    value={user.id}
                  >{`${user.firstName} ${user.lastName[0]}.`}</Select.Option>
                ))}
            </Select>
          );
        },
        filters: users
          .filter((user) =>
            user.permissions.some((permission) => [UserPermission.BCBA].includes(permission))
          )
          .sort((a: IUser, b: IUser) => a.firstName.localeCompare(b.firstName))
          .map((user: IUser) => ({
            text: `${user.firstName} ${user.lastName[0]}.`,
            value: user.id,
          })),
        onFilter: (value: any, row: IGuardianClientDetails) =>
          value === row.clientFile?.assignedBcbaId,
      },
      {
        width: 150,
        title: "RBT",
        dataIndex: ["clientFile", "assignedRbtId"],
        render: (assignedRbtId: string, row: IGuardianClientDetails) => {
          const id = "id" in row ? row.id : row.guardian.id;
          return (
            <Select
              key={`${id}+1`}
              showSearch
              allowClear
              optionFilterProp="children"
              defaultValue={assignedRbtId}
              style={{ width: 150 }}
              onChange={(value) => handleUpdateAssignedRbt(row.clientFile.id, value || "")}
            >
              {users
                .filter((user) =>
                  user.permissions.some((permission) => [UserPermission.RBT].includes(permission))
                )
                .map((user) => (
                  <Select.Option
                    key={user.id}
                    value={user.id}
                  >{`${user.firstName} ${user.lastName[0]}.`}</Select.Option>
                ))}
            </Select>
          );
        },
        filters: users
          .filter((user) =>
            user.permissions.some((permission) => [UserPermission.RBT].includes(permission))
          )
          .sort((a: IUser, b: IUser) => a.firstName.localeCompare(b.firstName))
          .map((user: IUser) => ({
            text: `${user.firstName} ${user.lastName[0]}.`,
            value: user.id,
          })),
        onFilter: (value: any, row: IGuardianClientDetails) =>
          value === row.clientFile?.assignedRbtId,
      },
      {
        title: "First Contact",
        dataIndex: selectedTab === TABLE_TABS.INQUIRIES ? "createdAt" : ["clientFile", "createdAt"],
        render: (createdAt) =>
          selectedTab === TABLE_TABS.INQUIRIES
            ? moment(createdAt?.toDate()).format(DISPLAY_DATE_FORMAT)
            : moment(createdAt).format(DISPLAY_DATE_FORMAT),
        sorter: (a: any, b: any) => a.createdAt - b.createdAt,
      },
      {
        width: 15,
        align: "center",
        render: (_text: string, row: IntakeRow) => {
          if (selectedTab === TABLE_TABS.INQUIRIES) {
            const inquiryRow = row as IInquiry;
            return (
              <Row justify="center">
                <Space size="large">
                  <Tooltip title="Convert to Pre-Qualified">
                    <LinkText
                      onClick={() => {
                        setConvertInquiryModalInquiry(inquiryRow);
                        setIsConvertInquiryModalVisible(true);
                      }}
                    >
                      <SyncOutlined style={{ fontSize: 16 }} />
                    </LinkText>
                  </Tooltip>
                  <Tooltip title="Edit Inquiry">
                    <LinkText
                      onClick={() => {
                        setEditInquiryModalInquiry(inquiryRow);
                        setIsEditInquiryModalVisible(true);
                      }}
                    >
                      <EditOutlined style={{ fontSize: 16 }} />
                    </LinkText>
                  </Tooltip>
                  <Tooltip title="Delete Inquiry">
                    <Popconfirm
                      title="Are you sure you want to delete this inquiry?"
                      placement="right"
                      onConfirm={async () => {
                        await FirestoreService.deleteInquiry(inquiryRow.id);
                        await fetchData(true, false);
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <LinkText>
                        <DeleteOutlined style={{ color: red.primary, fontSize: 16 }} />
                      </LinkText>
                    </Popconfirm>
                  </Tooltip>
                </Space>
              </Row>
            );
          } else {
            const clientRow = row as IGuardianClientDetails;
            return (
              <Row justify="center">
                <Space size="large">
                  {selectedTab !== TABLE_TABS.ACTIVE && (
                    <Tooltip title="Convert to Active">
                      <Popconfirm
                        title={
                          clientRow.client?.alias ? (
                            <>
                              {`Clicking confirm will set status to
                            "INTAKE_ASSESSMENT_IN_PROCESS".`}
                              <br />
                              {`Please only
                            proceed for families that are ready to begin the
                            assessment process.`}
                            </>
                          ) : (
                            <>
                              Guardians must have an associated client in order to be converted to
                              active. <br /> Please add client details before proceeding.
                            </>
                          )
                        }
                        placement="right"
                        onConfirm={async () => {
                          if (clientRow.client?.alias) {
                            await handleConvertToActive(clientRow);
                          } else {
                            setEditClientFileModalClientData(clientRow as IClientGuardianDetails);
                            setIsEditClientFileModalVisible(true);
                          }
                        }}
                        okText="Confirm"
                        cancelText="Cancel"
                      >
                        <LinkText>
                          <SyncOutlined style={{ fontSize: 16 }} />
                        </LinkText>
                      </Popconfirm>
                    </Tooltip>
                  )}
                  <Badge
                    dot
                    count={
                      selectedTab === TABLE_TABS.ACTIVE
                        ? validateAllClientFileFields(clientRow.clientFile).length
                        : 0
                    }
                  >
                    <Tooltip
                      placement={
                        _.isEmpty(validateAllClientFileFields(clientRow.clientFile))
                          ? "top"
                          : "topLeft"
                      }
                      title={
                        _.isEmpty(validateAllClientFileFields(clientRow.clientFile)) ||
                        selectedTab !== TABLE_TABS.ACTIVE ? (
                          "Edit File"
                        ) : (
                          <Row>
                            <Text style={{ color: "#FFF" }}>Client file has errors</Text>
                            <List size="small">
                              {validateAllClientFileFields(clientRow.clientFile).map(
                                (errorMessage) => (
                                  <List.Item key={errorMessage} style={{ color: "#FFF" }}>
                                    {errorMessage}
                                  </List.Item>
                                )
                              )}
                            </List>
                          </Row>
                        )
                      }
                    >
                      <LinkText
                        onClick={() => {
                          setEditClientFileModalClientData(clientRow as IClientGuardianDetails);
                          setIsEditClientFileModalVisible(true);
                        }}
                      >
                        <EditOutlined style={{ fontSize: 16 }} />
                      </LinkText>
                    </Tooltip>
                  </Badge>
                </Space>
              </Row>
            );
          }
        },
      },
    ];

    if (selectedTab === TABLE_TABS.INQUIRIES) {
      columnsTemplate = columnsTemplate.filter(
        (col: any) =>
          !["clientAlias"].includes(col.dataIndex) &&
          !["BCBA", "RBT", "Primary Payer"].includes(col.title)
      );
    } else if (selectedTab === TABLE_TABS.ACTIVE) {
      columnsTemplate = columnsTemplate.filter((col: any) => !["convert"].includes(col.dataIndex));
    }

    return columnsTemplate;
  };

  const expandedRowRender = (row: IntakeRow) => {
    const intakeStatusNotes =
      "intakeStatusNotes" in row
        ? row.intakeStatusNotes
        : (row as IGuardianClientDetails).clientFile.intakeStatusNotes;
    return (
      <Form
        layout="vertical"
        onFinish={(values) =>
          handleSaveQuickEditStatusNotes("id" in row ? row.id : row.guardian.id, values.statusNotes)
        }
      >
        <Row justify="space-between">
          <Title level={5}>Notes</Title>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const { statusNotes } = getFieldsValue();
              const formDidntChange = !statusNotes || statusNotes === intakeStatusNotes;

              return (
                <Button type="primary" htmlType="submit" size="small" disabled={formDidntChange}>
                  Save
                </Button>
              );
            }}
          </Form.Item>
        </Row>
        <Form.Item name="statusNotes">
          <TextArea defaultValue={intakeStatusNotes} autoSize />
        </Form.Item>
      </Form>
    );
  };

  const tabPaneValues = {
    [TABLE_TABS.INQUIRIES]: inquiries.length,
    [TABLE_TABS.PREQUALIFIED]: prequalifiedGuardians.length,
    [TABLE_TABS.ACTIVE]: activeGuardians.length,
  };

  return (
    <>
      <Tabs
        tabBarExtraContent={
          selectedTab === TABLE_TABS.INQUIRIES && (
            <Button type="primary" onClick={() => setIsCreateInquiryModalVisible(true)}>
              Create Inquiry
            </Button>
          )
        }
        defaultActiveKey={tab ? tab : selectedTab.valueOf()}
        onChange={(key) => {
          setSelectedTab(key as TABLE_TABS);
        }}
        items={Object.values(TABLE_TABS).map((tab) => ({
          key: tab,
          label: (
            <Statistic
              title={capitalizeFirstLetter(tab)}
              value={tabPaneValues[tab]}
              loading={isTableLoading}
            />
          ),
        }))}
      ></Tabs>
      <Search
        placeholder={
          selectedTab === TABLE_TABS.INQUIRIES
            ? "Search by First Name, Last Name, Email, or Status"
            : "Search by First Name, Last Name, Email, Status, or City"
        }
        allowClear
        value={searchString}
        onChange={handleSearch}
        style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
      />
      <Table
        rowKey={(row) => ("id" in row ? row.id : row.guardian.id)}
        columns={intakeColumns()}
        dataSource={dataSource}
        loading={isTableLoading}
        expandable={{
          expandedRowRender,
        }}
      />
      {!_.isEmpty(hiddenDataSource) && (
        <Collapse
          ghost
          expandIcon={({ isActive }) => (
            <RightOutlined
              rotate={isActive ? 90 : 0}
              style={{ fontSize: 15, position: "relative", top: 3 }}
            />
          )}
        >
          <Collapse.Panel
            header={
              <Text
                style={{ fontSize: 17, fontWeight: 500 }}
              >{`${hiddenDataSource.length} hidden`}</Text>
            }
            key={1}
          >
            <Table
              rowKey={(row) => ("id" in row ? row.id : row.guardian.id)}
              columns={intakeColumns()}
              dataSource={hiddenDataSource}
              loading={isTableLoading}
              expandable={{
                expandedRowRender,
                rowExpandable: (row: IInquiry | IGuardianClientDetails) =>
                  !_.isEmpty(
                    "intakeStatusNotes" in row
                      ? row.intakeStatusNotes
                      : (row as IGuardianClientDetails).clientFile.intakeStatusNotes
                  ),
              }}
            />
          </Collapse.Panel>
        </Collapse>
      )}
      <CreateInquiryModal
        hideModal={hideCreateInquiryModal}
        isVisible={isCreateInquiryModalVisible}
        user={user}
        refreshCallback={() => fetchData(true, true)}
      />
      <EditInquiryModal
        hideModal={hideEditInquiryModal}
        isVisible={isEditInquiryModalVisible}
        inquiry={editInquiryModalInquiry}
        refreshCallback={() => fetchData(false, true)}
      />
      <ConvertInquiryModal
        hideModal={hideConvertInquiryModal}
        isVisible={isConvertInquiryModalVisible}
        inquiry={convertInquiryModalInquiry!}
        refreshCallback={() => fetchData(true, true)}
      />
      {isEditClientFileModalVisible && (
        <EditClientFileModal
          hideModal={hideEditClientFileModal}
          clientGuardianDetails={editClientFileModalClientData!}
          defaultTab={editClientFileModalDefaultTab}
          refreshCallback={() => fetchData(false, true)}
        />
      )}
      {isViewInsuranceModalVisible && (
        <InsuranceCardModal
          hideModal={hideViewInsuranceModalGuardian}
          isVisible={isViewInsuranceModalVisible}
          clientGuardianDetails={viewInsuranceModalGuardian!}
        />
      )}
    </>
  );
};
