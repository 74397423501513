import "./css/Sidebar.css";

import {
  AlertOutlined,
  AuditOutlined,
  CalendarOutlined,
  ContainerOutlined,
  DashboardOutlined,
  FileUnknownOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { UserPermission } from "@finni-health/shared";
import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { IoFlaskOutline } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";

import { shouldShowDashboard } from "../helpers/userPermissions";
import { useUserClinics } from "./UserClinicsProvider";

const { Sider } = Layout;

interface Props {
  onSidebarCollapse: () => void;
  isSidebarCollapsed: boolean;
}

enum SIDEBAR_TABS {
  DASHBOARD = "dashboard",
  CLIENTS = "clients",
  INTAKE = "intake",
  SCHEDULES = "schedules",
  CALENDAR = "calendar",
  ALERTS = "alerts",
  APPROVALS = "approvals",
  BILLING = "billing",
  SETTINGS = "settings",
  DEVELOPMENT = "development",
  NULL = "null",
}

export const Sidebar: React.FC<Props> = ({ isSidebarCollapsed, onSidebarCollapse }) => {
  const { user, clinic } = useUserClinics();
  const location = useLocation();

  // If user is superadmin, set default
  const [selectedTab, setSelectedTab] = useState<SIDEBAR_TABS>(
    shouldShowDashboard(user, clinic) ? SIDEBAR_TABS.DASHBOARD : SIDEBAR_TABS.CLIENTS
  );

  useEffect(() => {
    handleSelectedTab();
  }, [location]);

  const handleSelectedTab = () => {
    const pathname = location.pathname;

    if (pathname.includes("/dev")) {
      setSelectedTab(SIDEBAR_TABS.DEVELOPMENT);
    } else if (pathname.includes("/settings")) {
      setSelectedTab(SIDEBAR_TABS.SETTINGS);
    } else if (pathname.includes("/billing")) {
      setSelectedTab(SIDEBAR_TABS.BILLING);
    } else if (pathname.includes("/alerts")) {
      setSelectedTab(SIDEBAR_TABS.ALERTS);
    } else if (pathname.includes("/approvals")) {
      setSelectedTab(SIDEBAR_TABS.APPROVALS);
    } else if (pathname.includes("/calendar")) {
      setSelectedTab(SIDEBAR_TABS.CALENDAR);
    } else if (pathname.includes("/schedules") || pathname.includes("/scheduling")) {
      setSelectedTab(SIDEBAR_TABS.SCHEDULES);
    } else if (pathname.includes("/intake")) {
      setSelectedTab(SIDEBAR_TABS.INTAKE);
    } else if (/^\/client-profile\/[a-zA-Z0-9]+$/.test(pathname)) {
      setSelectedTab(SIDEBAR_TABS.CLIENTS);
    } else if (pathname.includes("/clients")) {
      setSelectedTab(SIDEBAR_TABS.CLIENTS);
    } else if (pathname.includes("/dashboard")) {
      setSelectedTab(SIDEBAR_TABS.DASHBOARD);
    } else if (pathname === "/") {
      setSelectedTab(
        shouldShowDashboard(user, clinic) ? SIDEBAR_TABS.DASHBOARD : SIDEBAR_TABS.CLIENTS
      );
    } else {
      setSelectedTab(SIDEBAR_TABS.NULL);
    }
  };

  return (
    <Sider
      theme="light"
      collapsible
      onCollapse={onSidebarCollapse}
      collapsed={isSidebarCollapsed}
      collapsedWidth={60}
      className="sidebar"
    >
      <Menu
        mode="inline"
        selectedKeys={[selectedTab.toString()]}
        style={{
          height: "100%",
          borderRight: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {user.permissions?.includes(UserPermission.ADMIN) && (
          <>
            {shouldShowDashboard(user, clinic) && (
              <Menu.Item key={SIDEBAR_TABS.DASHBOARD} icon={<DashboardOutlined />}>
                <Link to="dashboard">Dashboard</Link>
              </Menu.Item>
            )}
            <Menu.Item key={SIDEBAR_TABS.CLIENTS} icon={<TeamOutlined />}>
              <Link to="clients">Clients</Link>
            </Menu.Item>
            <Menu.Item key={SIDEBAR_TABS.INTAKE} icon={<ContainerOutlined />}>
              <Link to="intake">Intake</Link>
            </Menu.Item>
            <Menu.Item key={SIDEBAR_TABS.SCHEDULES} icon={<ScheduleOutlined />}>
              <Link to="schedules">Schedules</Link>
            </Menu.Item>
            <Menu.Item key={SIDEBAR_TABS.CALENDAR} icon={<CalendarOutlined />}>
              <Link to="calendar">Calendar</Link>
            </Menu.Item>
            <Menu.Item key={SIDEBAR_TABS.APPROVALS} icon={<FileUnknownOutlined />}>
              <Link to="approvals">Approvals</Link>
            </Menu.Item>
            <Menu.Item key={SIDEBAR_TABS.ALERTS} icon={<AlertOutlined />}>
              <Link to="alerts">Alerts</Link>
            </Menu.Item>

            {user.permissions?.includes(UserPermission.SUPERADMIN) && (
              <Menu.Item key={SIDEBAR_TABS.BILLING} icon={<AuditOutlined />}>
                <Link to="billing">Billing</Link>
              </Menu.Item>
            )}
            <Menu.Item
              key={SIDEBAR_TABS.SETTINGS}
              icon={<SettingOutlined />}
              style={{ marginTop: "auto" }}
            >
              <Link to="settings">Settings</Link>
            </Menu.Item>
            {user.permissions?.includes(UserPermission.SUPERADMIN) && (
              <Menu.Item key={SIDEBAR_TABS.DEVELOPMENT} icon={<IoFlaskOutline />}>
                <Link to="dev">Development</Link>
              </Menu.Item>
            )}
          </>
        )}
        {user.permissions?.includes(UserPermission.RBT) && (
          <>
            <Menu.Item key={SIDEBAR_TABS.SCHEDULES} icon={<ScheduleOutlined />}>
              <Link to="schedules">Schedule</Link>
            </Menu.Item>
            <Menu.Item key={SIDEBAR_TABS.CALENDAR} icon={<CalendarOutlined />}>
              <Link to="calendar">Calendar</Link>
            </Menu.Item>
          </>
        )}
      </Menu>
    </Sider>
  );
};
