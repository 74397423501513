import { EllipsisOutlined } from "@ant-design/icons";
import {
  EventType,
  IAppointment,
  ICalendarRow,
  ICompletedAppointment,
  IIndirect,
  IUser,
  stringToColor,
  UserPermission,
  WeekDays,
} from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Card, Col, Popover, Progress, Row, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { getUserHoursForWeek } from "../../helpers/schedules";
import { userHasPermission } from "../../helpers/userPermissions";
import { TherapistBillingSummary } from "./TherapistBillingSummary";

const { Text } = Typography;

interface IProps {
  data: ICalendarRow;
  selectedWeek: moment.Moment;
}

export const TherapistSummary: React.FC<IProps> = ({ data, selectedWeek }: IProps) => {
  const therapist = data.person as IUser;

  const [directCompleted, setDirectCompleted] = React.useState<number>(0);
  const [directScheduled, setDirectScheduled] = React.useState<number>(0);
  const [indirectCompleted, setIndirectCompleted] = React.useState<number>(0);
  const [indirectScheduled, setIndirectScheduled] = React.useState<number>(0);
  const [isBillingSummaryOpen, setIsBillingSummaryOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = () => {
    const allAppts = Object.values(WeekDays).flatMap((day) => data[day]);

    const appointments = allAppts.filter(
      (appt) => appt.eventType === EventType.APPOINTMENT
    ) as IAppointment[];
    const completedAppointments = allAppts.filter(
      (appt) =>
        appt.eventType === EventType.COMPLETED &&
        (appt as ICompletedAppointment).renderingUserId === therapist.id
    ) as ICompletedAppointment[];
    const indirects = allAppts.filter(
      (appt) => appt.eventType === EventType.INDIRECT
    ) as IIndirect[];

    const {
      directCompletedHours,
      directScheduledHours,
      indirectCompletedHours,
      indirectScheduledHours,
    } = getUserHoursForWeek(therapist, appointments, completedAppointments, indirects);

    setDirectCompleted(directCompletedHours);
    setDirectScheduled(directScheduledHours);
    setIndirectCompleted(indirectCompletedHours);
    setIndirectScheduled(indirectScheduledHours);
  };

  return (
    <Popover
      trigger="click"
      placement="right"
      open={isBillingSummaryOpen}
      onOpenChange={(open) => setIsBillingSummaryOpen(open)}
      content={() => (
        <TherapistBillingSummary
          closePopup={() => setIsBillingSummaryOpen(false)}
          therapist={data.person as IUser}
          selectedWeek={selectedWeek}
        />
      )}
      zIndex={500}
      showArrow={false}
      getPopupContainer={(trigger) => trigger.parentElement!}
      destroyTooltipOnHide={true}
    >
      <Card size="small" bordered={false} style={{ cursor: "pointer" }} hoverable>
        <Row style={{ marginBottom: 15 }} justify="space-between">
          <Col span={23} style={{ overflow: "hidden" }}>
            <Tag color={stringToColor(data.person.id)}>
              {`${data.person.firstName} ${data.person.lastName}`}
            </Tag>
          </Col>
          <Col span={1}>
            <EllipsisOutlined style={{ rotate: "90deg", position: "relative", top: -10 }} />
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: 15 }}>
          <Tooltip
            placement="right"
            title={`${directCompleted} completed | ${directScheduled} scheduled`}
            style={{ whiteSpace: "pre" }}
          >
            <Progress
              type="dashboard"
              gapDegree={1}
              width={50}
              percent={(directCompleted * 100) / directScheduled}
              trailColor={COLORS.GRAY}
              strokeColor={COLORS.GREEN}
              format={() => (
                <>
                  <Text>{directCompleted}</Text>
                  <br />
                  <Text style={{ fontSize: 10 }}>hours</Text>
                </>
              )}
            />
            <Text style={{ marginLeft: 5, fontSize: 12 }}>Direct</Text>
          </Tooltip>
        </Row>
        {userHasPermission(therapist, UserPermission.RBT) && (
          <Row align="middle" style={{ marginBottom: 15 }}>
            <Tooltip
              placement="right"
              title={`${indirectCompleted} completed | ${indirectScheduled} scheduled`}
              style={{ whiteSpace: "pre" }}
            >
              <Progress
                type="dashboard"
                gapDegree={1}
                width={50}
                percent={(indirectCompleted * 100) / indirectScheduled}
                trailColor={COLORS.GRAY}
                strokeColor={COLORS.LIGHT_BLUE}
                format={() => (
                  <>
                    <Text>{indirectCompleted}</Text>
                    <br />
                    <Text style={{ fontSize: 10 }}>hours</Text>
                  </>
                )}
              />
              <Text style={{ marginLeft: 5, fontSize: 12 }}>Indirect</Text>
            </Tooltip>
          </Row>
        )}
      </Card>
    </Popover>
  );
};
