/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import "../css/AlertPanel.css";

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  capitalizeFirstLetter,
  getCurrentActiveAuth,
  IClient,
  IClientFile,
  ICredential,
  IGuardian,
  IUser,
  IUserStats,
} from "@finni-health/shared";
import { Button, Col, Collapse, Form, Input, Row, Tooltip, Typography } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import { useState } from "react";
import { IoHeartCircleOutline, IoSchoolOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { DISPLAY_DATE_FORMAT } from "../../consts";
import * as FirestoreService from "../../services/firestore";
import { CredentialInput } from "../ClinicSettings/CredentialInput";
import { useUserClinics } from "../UserClinicsProvider";

const { Panel } = Collapse;
const { Text, Title } = Typography;

export enum SEVERITY {
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
  SUCCESS = "Success",
}

export enum ALERT_MESSAGE_TYPE {
  CREDENTIAL_WARNING,
  CREDENTIAL_EXPIRED,
  MISSING_DATA,
  AUTHORIZATION_WARNING,
  AUTHORIZATION_EXPIRED,
  IMPACT_WARNING,
}

export enum OWNER_TYPES {
  USER = "User",
  CLIENT = "Client",
  GUARDIAN = "Guardian",
}

export const SEVERITY_STYLES = {
  [SEVERITY.HIGH]: {
    styles: {
      color: "#E21C3D",
    },
    primaryColor: "#E21C3D",
    secondaryColor: "#F9DEDE",
    icon: (
      <WarningOutlined
        style={{
          color: "#E21C3D",
          marginTop: 2,
          fontSize: 20,
        }}
      />
    ),
  },
  [SEVERITY.MEDIUM]: {
    styles: {
      color: "#FDB81E",
    },
    primaryColor: "#FDB81E",
    secondaryColor: "#FFF1D2",
    icon: (
      <ExclamationCircleOutlined
        style={{
          color: "#FDB81E",
          marginTop: 2,
          fontSize: 20,
        }}
      />
    ),
  },
  [SEVERITY.LOW]: {
    styles: {
      color: "#00BFE7",
    },
    primaryColor: "#00BFE7",
    secondaryColor: "#E1F3F8",
    icon: (
      <QuestionCircleOutlined
        style={{
          color: "#00BFE7",
          marginTop: 2,
          fontSize: 20,
        }}
      />
    ),
  },
  [SEVERITY.SUCCESS]: {
    styles: {
      color: "#48A463",
    },
    primaryColor: "#48A463",
    secondaryColor: "#E7F4E4",
    icon: (
      <CheckCircleOutlined
        style={{
          color: "#48A463",
          marginTop: 2,
          fontSize: 20,
        }}
      />
    ),
  },
};

const COLLECTION_LINKS = {
  [OWNER_TYPES.GUARDIAN]: "../guardian-profile/",
  [OWNER_TYPES.CLIENT]: "../client-profile/",
  [OWNER_TYPES.USER]: "../settings/staff/",
};

const COLLECTION_ICONS = {
  [OWNER_TYPES.GUARDIAN]: <IoHeartCircleOutline style={{ marginRight: 2, marginBottom: -2 }} />,
  [OWNER_TYPES.CLIENT]: <IoSchoolOutline style={{ marginRight: 2, marginBottom: -2 }} />,
  [OWNER_TYPES.USER]: <UserOutlined style={{ marginRight: 2 }} />,
};

const alertMessage = {
  [ALERT_MESSAGE_TYPE.CREDENTIAL_EXPIRED]: (
    ownerType: OWNER_TYPES,
    user: IUser,
    cred: ICredential
  ) => {
    return (
      <>
        <Link to={COLLECTION_LINKS[ownerType] + user.id}>
          {COLLECTION_ICONS[ownerType]}
          {`${user.firstName} ${user.lastName}'s`}
        </Link>
        <Text>
          {cred?.expiryMs
            ? ` ${cred.type} expired on ${moment(cred?.expiryMs).format(DISPLAY_DATE_FORMAT)}!`
            : ` ${cred.type} is missing data!`}
        </Text>
      </>
    );
  },
  [ALERT_MESSAGE_TYPE.CREDENTIAL_WARNING]: (
    ownerType: OWNER_TYPES,
    user: IUser,
    cred: ICredential
  ) => {
    return (
      <>
        <Link to={COLLECTION_LINKS[ownerType] + user.id}>
          {COLLECTION_ICONS[ownerType]}
          {`${user.firstName} ${user.lastName}'s`}
        </Link>
        <Text>{` ${cred.type} is about to expire on ${moment(cred?.expiryMs).format(
          DISPLAY_DATE_FORMAT
        )}.`}</Text>
      </>
    );
  },
  [ALERT_MESSAGE_TYPE.AUTHORIZATION_EXPIRED]: (
    ownerType: OWNER_TYPES,
    client: IClient,
    data: any
  ) => {
    const clientFile: IClientFile = data.record;
    const currentAuth = getCurrentActiveAuth(clientFile.payers?.primary ?? null);
    if (currentAuth === null) {
      return null;
    }

    return (
      <>
        <Link to={COLLECTION_LINKS[ownerType] + client.id}>
          {COLLECTION_ICONS[ownerType]}
          {`${client.firstName} ${client.lastName}'s `}
        </Link>
        <Text>{` authorization expired on ${moment(currentAuth.endDate).format(
          DISPLAY_DATE_FORMAT
        )}!`}</Text>
      </>
    );
  },
  [ALERT_MESSAGE_TYPE.AUTHORIZATION_WARNING]: (
    ownerType: OWNER_TYPES,
    client: IClient,
    data: any
  ) => {
    const clientFile: IClientFile = data.record;
    const currentAuth = getCurrentActiveAuth(clientFile.payers?.primary ?? null);
    if (currentAuth === null) {
      return null;
    }

    return (
      <>
        <Link to={COLLECTION_LINKS[ownerType] + client.id}>
          {COLLECTION_ICONS[ownerType]}
          {`${client.firstName} ${client.lastName}'s`}
        </Link>
        <Text>{` authorization is about to expire on ${moment(currentAuth.endDate).format(
          DISPLAY_DATE_FORMAT
        )}.`}</Text>
      </>
    );
  },
  [ALERT_MESSAGE_TYPE.MISSING_DATA]: (
    ownerType: OWNER_TYPES,
    owner: IClient | IUser | IGuardian,
    data: any
  ) => {
    return (
      <>
        <Text>{`${ownerType} `}</Text>
        <Link to={COLLECTION_LINKS[ownerType] + owner.id}>
          {COLLECTION_ICONS[ownerType]}
          {`${owner.firstName} ${owner.lastName} `}
        </Link>
        <Text>{` is missing data for ${capitalizeFirstLetter(data.key)}`}</Text>
      </>
    );
  },
  [ALERT_MESSAGE_TYPE.IMPACT_WARNING]: (
    ownerType: OWNER_TYPES,
    owner: IClient | IUser | IGuardian,
    data: any
  ) => {
    const stats = data as IUserStats;
    return (
      <>
        <Link to={COLLECTION_LINKS[ownerType] + owner.id}>
          {COLLECTION_ICONS[ownerType]}
          {`${owner.firstName} ${owner.lastName} `}
        </Link>
        <Text>{` has ${(stats.completedMinutes / 60).toFixed(0)} completed hours, ${(
          stats.indirectMinutes / 60
        ).toFixed(0)} indirect hours and ${(stats.therapistCancelledMinutes / 60).toFixed(
          0
        )} cancelled hours, their clients have cancelled ${(
          stats.clientCancelledMinutes / 60
        ).toFixed(0)} hours, other cancellations have totalled ${(
          stats.otherCancelledMinutes / 60
        ).toFixed(0)} hours`}</Text>
      </>
    );
  },
};

const countdownMessage = {
  [ALERT_MESSAGE_TYPE.CREDENTIAL_WARNING]: ({ data }: any) => {
    const credential = data as ICredential;
    return (
      <>
        <Row>
          <Title level={5}>
            {`${Math.max(calculateDays(credential?.expiryMs), 0)} Day${pluralize(
              Math.max(calculateDays(credential?.expiryMs), 0)
            )} to Expiry`}
          </Title>
        </Row>
      </>
    );
  },
  [ALERT_MESSAGE_TYPE.CREDENTIAL_EXPIRED]: () => {
    return null;
  },
  [ALERT_MESSAGE_TYPE.AUTHORIZATION_WARNING]: ({ data }: any) => {
    const clientFile: IClientFile = data.record;
    const currentAuth = getCurrentActiveAuth(clientFile.payers?.primary ?? null);
    if (currentAuth === null) {
      return null;
    }

    return (
      <>
        <Row>
          <Title level={5}>
            {`${Math.max(calculateDays(currentAuth.endDate), 0)} Day${pluralize(
              Math.max(calculateDays(currentAuth.endDate), 0)
            )} to Expiry`}
          </Title>
        </Row>
      </>
    );
  },
  [ALERT_MESSAGE_TYPE.AUTHORIZATION_EXPIRED]: () => {
    return null;
  },
  [ALERT_MESSAGE_TYPE.MISSING_DATA]: () => {
    return null;
  },
  [ALERT_MESSAGE_TYPE.IMPACT_WARNING]: ({ data }: any) => {
    const stats = data as IUserStats;
    const completedHours = parseInt((stats.completedMinutes / 60).toFixed(0));
    const indirectHours = parseInt((Math.max(stats.indirectMinutes - 40 * 60, 0) / 60).toFixed(0));
    const accruedHours = Math.floor(stats.completedMinutes / 60 / 40) * 2;
    const cancelledHours = parseInt(
      Math.max(stats.therapistCancelledMinutes / 60 - accruedHours, 0).toFixed(0)
    );
    const totalHours = parseInt((completedHours + indirectHours + cancelledHours).toFixed(0));
    const impactPercentage = parseFloat(((completedHours / totalHours) * 100).toFixed(2));
    //tooltip to explain impact calculation
    const tooltip = (
      <div>
        <p>
          <b>Completed:</b>
          {` ${completedHours} hour${pluralize(completedHours)}`}
        </p>
        <p>
          <b>{`Indirects (>40 hour):`}</b>
          {` ${indirectHours} hour${pluralize(indirectHours)}`}
        </p>
        <p>
          <b>{`Cancellations (> expected):`}</b>
          {` ${cancelledHours} hour${pluralize(cancelledHours)}`}
        </p>
        <p>
          <b style={{ marginRight: "auto", marginLeft: "auto" }}>Impact</b>
          <br />
          {` ${completedHours} completed / ${totalHours} total = ${impactPercentage}%`}
        </p>
      </div>
    );
    return (
      <>
        <Row>
          <Tooltip placement="top" title={tooltip}>
            <Title level={5}>{`${(stats.impactBasisPoints / 100).toFixed(2)}% Impact`}</Title>
          </Tooltip>
        </Row>
      </>
    );
  },
};

const now = moment().valueOf();

const calculateDays = (dateMS: any) => {
  if (!dateMS) return 0;
  const days = moment.duration(moment(dateMS).diff(moment(now))).asDays();
  return Math.round(days);
};

const pluralize = (count: number) => {
  return count === 1 ? "" : "s";
};

interface IProps {
  key: string;
  severity: SEVERITY;
  alertMessageType: ALERT_MESSAGE_TYPE;
  countdownEnabled?: boolean;
  ownerType: OWNER_TYPES;
  owner: IUser | IGuardian | IClient;
  data: any;
  allowUpdate: boolean;
  refresh: () => Promise<void>;
  [props: string]: any;
}

export const AlertPanel: React.FC<IProps> = ({ ...params }) => {
  const { user, refresh: refreshContext } = useUserClinics();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //input params
  const {
    key,
    severity,
    alertMessageType,
    countdownEnabled,
    ownerType,
    owner,
    data,
    allowUpdate,
    refresh,
    ...props
  } = params;

  const [dataPayload, setDataPayload] = useState<any>({});

  const handleSave = async (
    collection: "users" | "guardians" | "clients" | "clientFiles" | "clinics",
    record: any
  ) => {
    setIsLoading(true);
    await FirestoreService.updateCollectionRecord(collection, record);
    await refresh();
    if (record.id === user?.id) {
      await refreshContext();
    }
    setIsLoading(false);
  };

  return (
    <Panel
      {...props}
      key={key}
      style={{
        marginBottom: 20,
        backgroundColor: SEVERITY_STYLES[severity].secondaryColor,
        borderBottom: "0px solid white",
        boxSizing: "border-box",
        borderLeft: `12px solid ${SEVERITY_STYLES[severity].primaryColor}`,
        marginLeft: 10,
        marginRight: 20,
        transition: "all 0.2s",
      }}
      showArrow={allowUpdate}
      header={
        <Row align="middle">
          <Col span={1}>{SEVERITY_STYLES[severity].icon}</Col>
          <Col span={23}>
            <Row
              style={{
                justifyContent: "start",
                marginBottom: "auto",
                marginTop: "auto",
                width: "100%",
              }}
            >
              {countdownEnabled !== false &&
                countdownMessage[alertMessageType]({
                  ownerType,
                  owner,
                  data,
                })}
            </Row>
            <Row>
              <Col
                style={{
                  justifyContent: "start",
                  marginTop: "auto",
                  marginBottom: "auto",
                  width: "100%",
                }}
              >
                {alertMessage[alertMessageType](ownerType as any, owner as any, data)}
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <Col
        span={10}
        style={{
          margin: 0,
          padding: 0,
          borderRadius: 0,
        }}
        hidden={!allowUpdate}
      >
        <Row>
          {(alertMessageType === ALERT_MESSAGE_TYPE.CREDENTIAL_EXPIRED ||
            alertMessageType === ALERT_MESSAGE_TYPE.CREDENTIAL_WARNING) && (
            <CredentialInput
              autoSize={true}
              disabled={isLoading}
              type={data?.type}
              credential={data}
              updateCredential={(updatedCredential) => {
                const ownerClone = _.cloneDeep(owner) as IUser;
                ownerClone.credentials = ownerClone.credentials.some(
                  (cred) => cred.type === updatedCredential.type
                )
                  ? ownerClone.credentials.map((credential: any) => {
                      if (credential.type === updatedCredential.type) {
                        return updatedCredential;
                      }
                      return credential;
                    })
                  : [...ownerClone.credentials, updatedCredential];
                setDataPayload({ collection: "users", record: ownerClone });
              }}
            />
          )}
          {alertMessageType === ALERT_MESSAGE_TYPE.MISSING_DATA && (
            <Form.Item
              label={capitalizeFirstLetter(data.key)}
              name="data"
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={data?.record[data?.key]}
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <Input
                disabled={isLoading || !allowUpdate}
                onChange={(e) => {
                  const recordClone = _.cloneDeep(data.record);
                  recordClone[data.key] = e.target.value;
                  setDataPayload({
                    collection: data.collection,
                    record: recordClone,
                  });
                }}
              />
            </Form.Item>
          )}
          <Button
            style={{
              marginLeft: 10,
            }}
            loading={isLoading}
            type="primary"
            htmlType="submit"
            onClick={() => handleSave(dataPayload.collection, dataPayload.record)}
            hidden={dataPayload.collection === undefined || !allowUpdate}
          >
            Save changes
          </Button>
        </Row>
      </Col>
    </Panel>
  );
};
