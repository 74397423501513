import { EditOutlined, InboxOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "@finni-health/shared";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  message,
  Row,
  Spin,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useState } from "react";

import app from "../../services/firebase";

const { Text } = Typography;

interface IProps {
  clientFileId: string;
  onPhotoUrlChange: (photoUrl: string) => void;
  payerType: "primary" | "secondary";
  photoType: "front" | "back";
  photoUrl?: string;
}

export const PayersSectionPhoto = ({
  clientFileId,
  onPhotoUrlChange,
  payerType,
  photoType,
  photoUrl,
}: IProps) => {
  const [isUploading, setUploading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    listType: "picture",
    beforeUpload: async (file) => {
      //file size larger than 10MB (10000000 Bytes)
      if (file.size > 10000000) {
        void message.error("Your file is too large. Please select a file under 10MB");
        setFileList([]);
        return false;
      }

      setFileList([file]);
      setUploading(true);

      const results = await uploadBytes(
        ref(getStorage(app), `clientFiles/${clientFileId}/insurance/${payerType}/${photoType}`),
        file
      );
      const tempURL = await getDownloadURL(results.ref);

      onPhotoUrlChange(tempURL);
      setUploading(false);
      void message.success("Successfully Uploaded");

      // Prevent remote upload
      return false;
    },
    fileList,
  };

  const renderPhotoCard = (
    photoUrl: string,
    uploadProps: Partial<UploadProps>,
    isUploading: boolean
  ) => (
    <Card
      style={{
        marginTop: 5,
        marginBottom: 5,
        width: "100%",
      }}
      bodyStyle={{ padding: "14px 8px" }}
    >
      <Row style={{ height: 70 }} justify="space-between" align="middle">
        <Col style={{ marginRight: 15 }}>
          <Image height={70} style={{ maxWidth: 280 }} src={photoUrl} />
        </Col>
        <Col>
          <Upload {...uploadProps}>
            <Button type="text" loading={isUploading}>
              <EditOutlined />
            </Button>
          </Upload>
        </Col>
      </Row>
    </Card>
  );

  return (
    <Form.Item label={capitalizeFirstLetter(photoType)}>
      {photoUrl ? (
        renderPhotoCard(photoUrl, uploadProps, isUploading)
      ) : (
        <Dragger
          {...uploadProps}
          style={{
            marginTop: 5,
            marginBottom: 5,
            width: "100%",
          }}
        >
          {isUploading ? (
            <Spin />
          ) : (
            <>
              <Text className="ant-upload-drag-icon">
                <InboxOutlined />
              </Text>
              <Text>Upload</Text>
            </>
          )}
        </Dragger>
      )}
    </Form.Item>
  );
};
