import {
  ActiveStatus,
  Credential,
  getCurrentActiveAuth,
  IClient,
  IClientFile,
  IGuardian,
  IUser,
  UserPermission,
} from "@finni-health/shared";
import { Badge, Collapse, Divider, Row, Segmented, Space, Spin, Statistic, Typography } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import {
  ALERT_MESSAGE_TYPE,
  AlertPanel,
  OWNER_TYPES,
  SEVERITY,
  SEVERITY_STYLES,
} from "../components/Alerts/AlertPanel";
import { useUserClinics } from "../components/UserClinicsProvider";
import * as FirestoreService from "../services/firestore";

const { Text } = Typography;

export enum ALERT_TABS {
  CREDENTIALS = "Credentials",
  AUTHORIZATIONS = "Authorizations",
  MISSING_DATA = "Missing Data",
  IMPACT = "Impact",
}

const MISSING_DATA_FIELDS = {
  USERS: [
    { key: "npi", severity: SEVERITY.HIGH, allowUpdate: true },
    { key: "schedule", severity: SEVERITY.MEDIUM, allowUpdate: false },
    { key: "phoneNumber", severity: SEVERITY.LOW, allowUpdate: true },
  ],
  GUARDIANS: [{ key: "phoneNumber", severity: SEVERITY.LOW, allowUpdate: true }],
  CLIENTS: [
    { key: "alias", severity: SEVERITY.HIGH, allowUpdate: true },
    { key: "guardianId", severity: SEVERITY.HIGH, allowUpdate: false },
  ],
  CLIENTFILES: [
    { key: "payers", severity: SEVERITY.HIGH, allowUpdate: false },
    { key: "address", severity: SEVERITY.LOW, allowUpdate: false },
  ],
};

const now = moment().valueOf();

export const Alerts: React.FC = () => {
  const { user } = useUserClinics();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedAlertTab, setSelectedAlertTab] = useState<ALERT_TABS>(ALERT_TABS.CREDENTIALS);

  //credentials
  const [expiredCredentialsAlerts, setExpiredCredentialsAlerts] = useState<any[]>([]);
  const [warningCredentialsAlerts, setWarningCredentialsAlerts] = useState<any[]>([]);

  //authorizations
  const [expiredAuthorizationsAlerts, setExpiredAuthorizationsAlerts] = useState<any[]>([]);
  const [warningAuthorizationsAlerts, setWarningAuthorizationsAlerts] = useState<any[]>([]);

  //missing data
  const [missingDataAlerts, setMissingDataAlerts] = useState<any[]>([]);

  //performance data
  const [impactAlerts, setImpactAlerts] = useState<any[]>([]);

  //counts of alert severities by type
  const [alertCountsByType, setAlertCountsByType] = useState(
    Object.fromEntries(
      Object.values(ALERT_TABS).map((alertTab) => [
        alertTab,
        Object.fromEntries(Object.values(SEVERITY).map((severity) => [severity, 0])),
      ])
    ) as {
      [key in ALERT_TABS]: {
        [key in SEVERITY]: number;
      };
    }
  );

  useEffect(() => {
    fetchData().catch(() => {});
  }, [user]);

  const refresh = async () => {
    await fetchData(false, true);
  };

  const isClientFileActive = (clientFile: IClientFile) => {
    return Object.keys(ActiveStatus).includes(clientFile?.intakeStatus);
  };

  const fetchData = async (showLoading = true, force = false) => {
    if (showLoading) {
      setIsLoading(true);
    }

    //counts of alert severities by type
    const alertCountsByType = Object.fromEntries(
      Object.values(ALERT_TABS).map((alertTab) => [
        alertTab,
        Object.fromEntries(Object.values(SEVERITY).map((severity) => [severity, 0])),
      ])
    ) as {
      [key in ALERT_TABS]: {
        [key in SEVERITY]: number;
      };
    };

    //get data
    const [clientsGuardiansDetails, users, usersExpiredCredentials, usersWarningCredentials] =
      await Promise.all([
        FirestoreService.getClientsGuardiansDetailsByClinicId(user.clinicId, force),
        FirestoreService.getAllUsersForClinic(user.clinicId, force),
        FirestoreService.getUsersWithExpiredCredentialsForClinic(user.clinicId, force),
        FirestoreService.getUsersWithWarningCredentialsForClinic(user.clinicId, force),
      ]);

    //missing data
    const missingDataAlerts: any[] = [];

    //users
    users.forEach((user) =>
      MISSING_DATA_FIELDS.USERS.forEach((dataField) => {
        const value = user[dataField.key as keyof IUser];
        if (
          _.isEmpty(value) &&
          _.intersection([UserPermission.BCBA, UserPermission.RBT], user.permissions).length > 0
        ) {
          //increment count
          alertCountsByType[ALERT_TABS.MISSING_DATA][dataField.severity]++;
          //add alert
          missingDataAlerts.push(
            <AlertPanel
              key={user.id + dataField.key + "missing"}
              severity={dataField.severity}
              alertMessageType={ALERT_MESSAGE_TYPE.MISSING_DATA}
              countdownEnabled={false}
              ownerType={OWNER_TYPES.USER}
              owner={user}
              data={{ collection: "users", key: dataField.key, record: user }}
              allowUpdate={dataField.allowUpdate}
              refresh={refresh}
            />
          );
        }
      })
    );

    //guardians
    clientsGuardiansDetails.forEach((clientGuardianDetails) =>
      MISSING_DATA_FIELDS.GUARDIANS.forEach((dataField) => {
        const clientFile = clientGuardianDetails?.clientFile;
        const guardian = clientGuardianDetails?.guardian;
        const value = guardian?.[dataField.key as keyof IGuardian];
        if (guardian && _.isEmpty(value) && isClientFileActive(clientFile)) {
          //increment count
          alertCountsByType[ALERT_TABS.MISSING_DATA][dataField.severity]++;
          //add alert
          missingDataAlerts.push(
            <AlertPanel
              key={guardian.id + dataField.key + "missing"}
              severity={dataField.severity}
              alertMessageType={ALERT_MESSAGE_TYPE.MISSING_DATA}
              countdownEnabled={false}
              ownerType={OWNER_TYPES.GUARDIAN}
              owner={guardian}
              data={{
                collection: "guardians",
                key: dataField.key,
                record: guardian,
              }}
              allowUpdate={dataField.allowUpdate}
              refresh={refresh}
            />
          );
        }
      })
    );

    //clients
    clientsGuardiansDetails.forEach((clientGuardianDetails) =>
      MISSING_DATA_FIELDS.CLIENTS.forEach((dataField) => {
        const client = clientGuardianDetails?.client;
        const clientFile = clientGuardianDetails?.clientFile;
        const value = client?.[dataField.key as keyof IClient];
        if (client && _.isEmpty(value) && isClientFileActive(clientFile)) {
          //increment count
          alertCountsByType[ALERT_TABS.MISSING_DATA][dataField.severity]++;
          //add alert
          missingDataAlerts.push(
            <AlertPanel
              key={client.id + dataField.key + "missing"}
              severity={dataField.severity}
              alertMessageType={ALERT_MESSAGE_TYPE.MISSING_DATA}
              countdownEnabled={false}
              ownerType={OWNER_TYPES.CLIENT}
              owner={client}
              data={{
                collection: "clients",
                key: dataField.key,
                record: client,
              }}
              allowUpdate={dataField.allowUpdate}
              refresh={refresh}
            />
          );
        }
      })
    );

    //client files
    clientsGuardiansDetails.forEach((clientGuardianDetails) =>
      MISSING_DATA_FIELDS.CLIENTFILES.forEach((dataField) => {
        const clientFile = clientGuardianDetails?.clientFile;
        const value = clientGuardianDetails?.clientFile?.[dataField.key as keyof IClientFile];
        if (clientFile && _.isEmpty(value) && isClientFileActive(clientFile)) {
          //increment count
          alertCountsByType[ALERT_TABS.MISSING_DATA][dataField.severity]++;
          //add alert
          missingDataAlerts.push(
            <AlertPanel
              key={clientGuardianDetails.clientFile.id + dataField.key + "missing"}
              severity={dataField.severity}
              alertMessageType={ALERT_MESSAGE_TYPE.MISSING_DATA}
              countdownEnabled={false}
              ownerType={OWNER_TYPES.CLIENT}
              owner={clientGuardianDetails.client}
              data={{
                collection: "clientFiles",
                key: dataField.key,
                record: clientGuardianDetails.clientFile,
              }}
              allowUpdate={dataField.allowUpdate}
              refresh={refresh}
            />
          );
        }
      })
    );

    //sort missing data alerts by severity
    missingDataAlerts.sort(
      (a, b) =>
        Object.values(SEVERITY).indexOf(a.props.severity) -
        Object.values(SEVERITY).indexOf(b.props.severity)
    );

    //set missing data alerts
    setMissingDataAlerts(missingDataAlerts);

    //expired credentials alerts
    const expiredCredentialsAlertsWithDates = usersExpiredCredentials
      .flatMap((user) =>
        _.uniqBy(
          [
            ...user.credentials,
            ...Object.values(Credential)
              .map((credentialType) => {
                return {
                  type: credentialType,
                  expiryWarningOffsetMs: 0,
                  identifier: "",
                  expiryMs: 0,
                };
              })
              .filter(
                (credential) =>
                  user.permissions.some((userPermission) =>
                    credential.type.includes(userPermission)
                  ) || credential.type === Credential.CPR_TRAINING
              ),
          ],
          "type"
        ).reduce((acc, credential) => {
          if (now >= credential?.expiryMs) {
            acc.push({
              date: credential?.expiryMs,
              element: (
                <AlertPanel
                  key={user.id + credential.type + "expired"}
                  severity={SEVERITY.HIGH}
                  alertMessageType={ALERT_MESSAGE_TYPE.CREDENTIAL_EXPIRED}
                  countdownEnabled={false}
                  ownerType={OWNER_TYPES.USER}
                  owner={user}
                  data={credential}
                  allowUpdate={true}
                  refresh={refresh}
                />
              ),
            });
          }
          return acc;
        }, [] as any[])
      )
      .sort((a, b) => a.date - b.date);
    setExpiredCredentialsAlerts(expiredCredentialsAlertsWithDates.map((alert) => alert.element));
    alertCountsByType[ALERT_TABS.CREDENTIALS][SEVERITY.HIGH] +=
      expiredCredentialsAlertsWithDates.length;

    //warning credentials alerts
    const warningCredentialsAlertsWithDates = usersWarningCredentials
      .flatMap((user) =>
        user.credentials.reduce((acc, credential) => {
          if (
            credential?.expiryMs > 0 &&
            now >= credential?.expiryWarningOffsetMs &&
            now < credential?.expiryMs
          ) {
            acc.push({
              date: credential?.expiryMs,
              element: (
                <AlertPanel
                  key={user.id + credential.type + "warning"}
                  severity={SEVERITY.MEDIUM}
                  alertMessageType={ALERT_MESSAGE_TYPE.CREDENTIAL_WARNING}
                  countdownEnabled={true}
                  ownerType={OWNER_TYPES.USER}
                  owner={user}
                  data={credential}
                  allowUpdate={true}
                  refresh={refresh}
                />
              ),
            });
          }
          return acc;
        }, [] as any[])
      )
      .sort((a, b) => a.date - b.date);
    setWarningCredentialsAlerts(warningCredentialsAlertsWithDates.map((alert) => alert.element));
    alertCountsByType[ALERT_TABS.CREDENTIALS][SEVERITY.MEDIUM] +=
      warningCredentialsAlertsWithDates.length;

    //authorization alerts
    const expiredAuthorizationsAlerts: any[] = [];
    const warningAuthorizationsAlerts: any[] = [];

    clientsGuardiansDetails.forEach((clientGuardianDetails) => {
      if (!isClientFileActive(clientGuardianDetails.clientFile)) {
        return;
      }

      const currentAuth = getCurrentActiveAuth(
        clientGuardianDetails.clientFile.payers?.primary ?? null
      );

      if (currentAuth === null) {
        return;
      }

      const daysTillExpiry = Math.round(
        moment.duration(moment(currentAuth.endDate).diff(moment())).asDays()
      );
      if (daysTillExpiry <= 0) {
        //increment count
        alertCountsByType[ALERT_TABS.AUTHORIZATIONS][SEVERITY.HIGH]++;
        //add alert
        expiredAuthorizationsAlerts.push(
          <AlertPanel
            key={clientGuardianDetails.clientFile.id + "auth" + "expired"}
            severity={SEVERITY.HIGH}
            alertMessageType={ALERT_MESSAGE_TYPE.AUTHORIZATION_EXPIRED}
            countdownEnabled={false}
            ownerType={OWNER_TYPES.CLIENT}
            owner={clientGuardianDetails.client}
            data={{
              collection: "clientFiles",
              key: "auth",
              record: clientGuardianDetails.clientFile,
              daysTillExpiry,
            }}
            allowUpdate={false}
            refresh={refresh}
          ></AlertPanel>
        );
      } else if (daysTillExpiry <= 60) {
        //increment count
        alertCountsByType[ALERT_TABS.AUTHORIZATIONS][SEVERITY.MEDIUM]++;
        //add alert
        warningAuthorizationsAlerts.push(
          <AlertPanel
            key={clientGuardianDetails.clientFile.id + "auth" + "warning"}
            severity={SEVERITY.MEDIUM}
            alertMessageType={ALERT_MESSAGE_TYPE.AUTHORIZATION_WARNING}
            countdownEnabled={true}
            ownerType={OWNER_TYPES.CLIENT}
            owner={clientGuardianDetails.client}
            data={{
              collection: "clientFiles",
              key: "auth",
              record: clientGuardianDetails.clientFile,
              daysTillExpiry,
            }}
            allowUpdate={false}
            refresh={refresh}
          ></AlertPanel>
        );
      }
    });

    //sort alerts
    expiredAuthorizationsAlerts.sort(
      (a, b) => a.props.data.daysTillExpiry - b.props.data.daysTillExpiry
    );
    warningAuthorizationsAlerts.sort(
      (a, b) => a.props.data.daysTillExpiry - b.props.data.daysTillExpiry
    );

    //set authorization alerts
    setExpiredAuthorizationsAlerts(expiredAuthorizationsAlerts);
    setWarningAuthorizationsAlerts(warningAuthorizationsAlerts);

    //performance alerts
    const impactAlerts: any[] = [];

    users.forEach((user) => {
      //return if not RBT and has stats
      if (!user.permissions.includes(UserPermission.RBT) || _.isEmpty(user?.stats)) return;

      //bucket severity
      const severity =
        user?.stats?.impactBasisPoints > 9000
          ? SEVERITY.SUCCESS
          : user?.stats?.impactBasisPoints > 8000
          ? SEVERITY.MEDIUM
          : SEVERITY.HIGH;

      //increment count
      alertCountsByType[ALERT_TABS.IMPACT][severity]++;

      //add alert
      impactAlerts.push(
        <AlertPanel
          key={user.id + "performance"}
          severity={severity}
          alertMessageType={ALERT_MESSAGE_TYPE.IMPACT_WARNING}
          countdownEnabled={true}
          ownerType={OWNER_TYPES.USER}
          owner={user}
          data={user?.stats}
          allowUpdate={false}
          refresh={refresh}
        ></AlertPanel>
      );
    });

    //sort performance alert by impactBasisPoints and then by impactScore
    impactAlerts.sort((a, b) =>
      b.props.data.impactBasisPoints == a.props.data.impactBasisPoints
        ? a.props.data.impactScore - b.props.data.impactScore
        : a.props.data.impactBasisPoints - b.props.data.impactBasisPoints
    );

    //set performance alerts
    setImpactAlerts(impactAlerts);

    //set alert counts
    setAlertCountsByType(alertCountsByType);

    setIsLoading(false);
  };

  return (
    <div style={{ marginTop: 5 }}>
      <Row>
        <Space
          size="large"
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          {Object.values(SEVERITY).map(
            (severity) =>
              severity !== SEVERITY.SUCCESS && (
                <Statistic
                  key={severity}
                  loading={isLoading}
                  title={
                    <div style={SEVERITY_STYLES[severity].styles}>
                      {SEVERITY_STYLES[severity].icon}
                      <Text
                        style={{
                          marginLeft: 4,
                          color: SEVERITY_STYLES[severity].primaryColor,
                        }}
                      >
                        {severity}
                      </Text>
                    </div>
                  }
                  value={Object.values(alertCountsByType).reduce(
                    (sum, tab) => (sum += tab[severity]),
                    0
                  )}
                />
              )
          )}
        </Space>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Segmented
          options={Object.values(ALERT_TABS).reduce((acc, tab) => {
            acc.push({
              disabled:
                Object.values(alertCountsByType[tab]).reduce(
                  (acc: number, count: number) => (acc += count),
                  0 as number
                ) === 0,
              value: tab,
              label: (
                <Badge dot={alertCountsByType[tab][SEVERITY.HIGH] > 0} offset={[5, 0]}>
                  {tab}
                </Badge>
              ),
            });
            return acc;
          }, [] as any)}
          block
          style={{ minWidth: 460 }}
          onChange={(e) => {
            setSelectedAlertTab(e as ALERT_TABS);
          }}
          selected={true}
        />
      </Row>
      <Divider />
      <Space direction="vertical" style={{ width: "100%" }}>
        {isLoading ? (
          <Row justify="center" align="middle" style={{ height: 400 }}>
            <Spin size="large" />
          </Row>
        ) : Object.values(alertCountsByType).reduce(
            (acc, type) => (acc += Object.values(type).reduce((sum, curr) => (sum += curr), 0)),
            0 as number
          ) !== 0 ? (
          <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            style={{ backgroundColor: "white" }}
            expandIconPosition={"end"}
          >
            {selectedAlertTab === ALERT_TABS.CREDENTIALS && expiredCredentialsAlerts}
            {selectedAlertTab === ALERT_TABS.CREDENTIALS && warningCredentialsAlerts}
            {selectedAlertTab === ALERT_TABS.AUTHORIZATIONS && expiredAuthorizationsAlerts}
            {selectedAlertTab === ALERT_TABS.AUTHORIZATIONS && warningAuthorizationsAlerts}
            {selectedAlertTab === ALERT_TABS.MISSING_DATA && missingDataAlerts}
            {selectedAlertTab === ALERT_TABS.IMPACT && impactAlerts}
          </Collapse>
        ) : (
          <>No alerts found!</>
        )}
      </Space>
    </div>
  );
};
