import {
  ActiveStatus,
  BillingCode,
  IAddressDistanceEndpointResponse,
  IAppointment,
  IAuthorization,
  ICompletedAppointment,
  IIndirect,
  IUser,
  IWeekdaySchedule,
  Weekday,
} from "@finni-health/shared";
import { sumBy } from "lodash";
import moment from "moment";

import { START_OF_COMPLETION_TRACKING } from "../consts";
import { getDirectAndIndirectIntervals, IInterval } from "./appointments";

export type IUserWithAvailability = IUser & { available?: boolean };

export const getDurationFromAppointments = (appointments: IAppointment[]) => {
  const totalMs = appointments.reduce(
    (partialSum, appt) => partialSum + (appt.endMs - appt.startMs),
    0
  );

  const duration = moment.duration(totalMs);

  return duration;
};

export const getWeeklyHoursFromAuth = (auth: IAuthorization | null, code: BillingCode) => {
  if (auth === null || !auth.auths[code]) {
    return 0;
  }
  const authDuration = moment.duration(moment(auth.endDate).diff(moment(auth.startDate)));
  const numWeeks = authDuration.asWeeks() + (authDuration.days() % 7) / 7;

  const totalHours = ((auth.auths[code]?.units || 0) * (auth.auths[code]?.unitSize || 0)) / 60;

  return +(totalHours / numWeeks).toFixed(2);
};

export const getHoursFromAuthByBillingCode = (
  auth: IAuthorization,
  range: [moment.Moment, moment.Moment]
) => {
  const hoursByCode: { [key in BillingCode]?: number } = {};
  const [authStart, authEnd] = [moment(auth.startDate), moment(auth.endDate)];
  Object.keys(auth.auths).map((code: string) => {
    const [start, end] = range;
    if (start > authEnd || end < authStart) {
      return 0;
    }
    const durationOverlapWithAuth = moment
      .duration(
        moment.min(authEnd, end).endOf("day").diff(moment.max(authStart, start).startOf("day"))
      )
      .asDays();
    const authDuration = moment
      .duration(authEnd.endOf("day").diff(authStart.startOf("day")))
      .asDays();
    const totalHours =
      ((auth.auths[code as BillingCode]?.units || 0) *
        (auth.auths[code as BillingCode]?.unitSize || 0)) /
      60;

    hoursByCode[code as BillingCode] = +(
      totalHours *
      (durationOverlapWithAuth / authDuration)
    ).toFixed(2);
  });
  return hoursByCode;
};

export const calculateExpectedAndAuthorizedHoursForRange = (
  completed: number,
  authorized: number,
  auth: IAuthorization | null,
  range: [moment.Moment, moment.Moment],
  day: moment.Moment
) => {
  if (!auth) return [0, 0, 0];
  const [authStart, authEnd] = [moment(auth.startDate), moment(auth.endDate)];
  const [start, end] = [moment.max(authStart, range[0]), moment.min(authEnd, range[1])];
  const lastMonthEnd = moment(start).add(-1, "month").endOf("month");
  const authorizedHoursBeforeRange =
    start > authStart
      ? (authorized * moment.duration(lastMonthEnd.diff(authStart)).asDays()) /
        moment.duration(authEnd.diff(authStart)).asDays()
      : 0;

  const authorizedHoursBeforeTracking =
    START_OF_COMPLETION_TRACKING > start
      ? (authorized * moment.duration(START_OF_COMPLETION_TRACKING.diff(start)).asDays()) /
        moment.duration(authEnd.diff(authStart)).asDays()
      : 0;

  const authorizedByRange = +authorized.toFixed(1);

  const expectedByRange = +(
    (authorizedByRange *
      moment.duration(moment.min(moment.max(start, day), end).diff(start)).asDays()) /
    moment.duration(end.diff(start)).asDays()
  ).toFixed(1);

  return [
    expectedByRange || 0,
    authorizedByRange || 0,
    Math.max(+(authorizedHoursBeforeRange - completed).toFixed(1), 0),
    authorizedHoursBeforeTracking || 0,
  ];
};

export const therapistAppointmentSchedules = (
  therapists: IUser[],
  appointments: IAppointment[],
  thisAppointment?: IAppointment
) => {
  const res: Record<string, number[][]> = {};
  therapists.map((therapist) => {
    appointments
      .filter(
        (a) =>
          a.attendees.some((at) => at.email === therapist.email) && a.id !== thisAppointment?.id
      )
      .map((appointment) => {
        if (!Object.keys(res).includes(therapist.email)) res[therapist.email] = [];
        res[therapist.email].push([appointment.startMs, appointment.endMs]);
      });
  });
  return res;
};

export const therapistAvailabilityOnDay = (
  appointmentSchedule: number[][],
  daySchedule: IWeekdaySchedule,
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const scheduleForDate =
    daySchedule?.intervals.map((interval) => [
      moment(interval.startMs.seconds * 1000)
        .set("date", startDate.date())
        .set("month", endDate.month())
        .set("year", endDate.year())
        .valueOf(),
      moment(interval.endMs.seconds * 1000)
        .set("date", endDate.date())
        .set("month", endDate.month())
        .set("year", endDate.year())
        .valueOf(),
    ]) || [];

  const remainingAvailability: number[][] = [];

  for (const availability of scheduleForDate) {
    let isOverlapped = false;
    for (const schedule of appointmentSchedule) {
      if (schedule[0] <= availability[1] && schedule[1] >= availability[0]) {
        if (availability[0] < schedule[0]) {
          remainingAvailability.push([availability[0], schedule[0]]);
        }
        if (availability[1] > schedule[1]) {
          remainingAvailability.push([schedule[1], availability[1]]);
        }
        isOverlapped = true;
        break;
      }
    }
    if (!isOverlapped) {
      remainingAvailability.push(availability);
    }
  }

  return remainingAvailability;
};

export const getTherapistsByAvailability = (
  therapists: IUserWithAvailability[],
  appointments: IAppointment[],
  thisAppointment: IAppointment,
  weekday: Weekday
) => {
  const appointmentHoursByTherapist = therapistAppointmentSchedules(
    therapists,
    appointments,
    thisAppointment
  );

  const sortedTherapists = therapists
    .map((t) => {
      const availabilities = therapistAvailabilityOnDay(
        appointmentHoursByTherapist[t.email] || [],
        t.schedule?.[weekday],
        moment(thisAppointment.startMs),
        moment(thisAppointment.endMs)
      );

      const isAvailableForThisAppt = availabilities?.some(
        ([start, end]) => thisAppointment.startMs >= start && thisAppointment.endMs <= end
      );
      if (t.schedule?.[weekday] && t.hasSetSchedule) {
        t.available = isAvailableForThisAppt;
      }
      return t;
    })
    .sort((a, b) => {
      const aVal = a.available ? 1 : a.available === undefined ? 2 : 3;
      const bVal = b.available ? 1 : b.available === undefined ? 2 : 3;
      return aVal - bVal;
    });

  return sortedTherapists;
};

export const availabilityToColor = (available?: boolean) => {
  return available === undefined ? "gray" : available ? "green" : "red";
};

export const formatDurationTextFromMaps = (durationText: string) => {
  return durationText
    ?.split(" ")
    .map((t, i) => (i % 2 === 0 ? "" : durationText?.split(" ")[i - 1] + t.charAt(0) + " "))
    .join("")
    .trim();
};

export const mapsDurationComparator = (
  a: IUser,
  b: IUser,
  therapistDistances: Record<string, IAddressDistanceEndpointResponse>
) => {
  if (therapistDistances[a.id]?.driving?.duration?.value === 0) return 1;
  if (therapistDistances[b.id]?.driving?.duration?.value === 0) return -1;
  return (
    therapistDistances[a.id]?.driving?.duration?.value -
    therapistDistances[b.id]?.driving?.duration?.value
  );
};

export const isClientStatusSchedulable = (status: string) => {
  return [ActiveStatus.ACTIVE, ActiveStatus.ISP_SUBMITTED, ActiveStatus.ISP_IN_PROCESS].includes(
    status as ActiveStatus
  );
};

export const getUserHoursForWeek = (
  user: IUser,
  appointments: IAppointment[],
  completedAppointments: ICompletedAppointment[],
  indirects: IIndirect[]
) => {
  const nowMs = moment().valueOf();

  // Completed Intervals
  const pastAppointments = [
    ...indirects.filter((indirect) => indirect.endMs < nowMs),
    ...completedAppointments,
  ];
  const {
    directIntervals: completedDirectIntervals,
    indirectIntervals: completedIndirectIntervals,
  } = getDirectAndIndirectIntervals(pastAppointments, user);

  // Scheduled Intervals
  const scheduledAppointments = [...indirects, ...appointments, ...completedAppointments];
  const {
    directIntervals: scheduledDirectIntervals,
    indirectIntervals: scheduledIndirectIntervals,
  } = getDirectAndIndirectIntervals(scheduledAppointments, user, true);

  const directCompletedHours = _getRoundedHoursFromIntervals(completedDirectIntervals);
  const indirectCompletedHours = _getRoundedHoursFromIntervals(completedIndirectIntervals);
  const directScheduledHours = _getRoundedHoursFromIntervals(scheduledDirectIntervals);
  const indirectScheduledHours = _getRoundedHoursFromIntervals(scheduledIndirectIntervals);

  return {
    directCompletedHours,
    directScheduledHours,
    indirectCompletedHours,
    indirectScheduledHours,
  };
};

const _getRoundedHoursFromIntervals = (intervals: IInterval[]) => {
  return Number(
    sumBy(intervals, (interval) => (interval.endMs - interval.startMs) / (1000 * 60 * 60)).toFixed(
      2
    )
  );
};
