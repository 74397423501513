import { faker } from "@faker-js/faker";

//cache manager
const cacheEnabled = true;
const cacheLoggingEnabled = false; //try me to identify race conditions/extra calls
const cache: { [functionName: string]: any } = {};
/**
 * [cacheRequest use this to cache an async function call, since it's async there is a chance some calls will be made more than once but reduces number of calls overall]
 * @param  {String} functionName [name of the function]
 * @param  {Array} argList [arguments of the function]
 * @param  {Function} func [the function being called]
 * @return {Any} [returns results]
 */
export const cacheManager = async (
  functionName: string,
  argumentsList: any[] = [],
  cacheInvalidation: number,
  func: () => Promise<any>
) => {
  // Try to get from cache
  const res = cacheRequest(functionName, argumentsList, cacheInvalidation)();
  //if function is not cached
  if (res === undefined) {
    let promiseResolver: any;
    const promise = new Promise<void>((resolve) => {
      promiseResolver = resolve;
    });
    //store empty promise in cache early to prevent multiple calls
    cacheRequest(functionName, argumentsList, cacheInvalidation, promise)();
    //call function
    const resp = await func();
    //resolve the empty promise stored earlier
    promiseResolver(resp);
    //store function results in cache
    cacheRequest(functionName, argumentsList, cacheInvalidation, resp)();
    //return results
    return resp;
  } else {
    //return value from cache
    return await res;
  }
};
const cacheRequest = (
  func: string,
  argList: any[] = [],
  cacheInvalidation: number,
  results: any = undefined
) => {
  return () => {
    if (!cacheEnabled) return undefined;
    //convert arguments list to string
    const args = JSON.stringify(argList);
    //attempt to return from cache
    if (results === undefined) {
      if (cache[func] && cache[func][args]) {
        const [timestamp, storedResults] = cache[func][args];
        if (timestamp + cacheInvalidation > Date.now()) {
          //cache is valid, return results
          if (cacheLoggingEnabled) console.log("Cache Hit", func, args);
          return storedResults;
        } else {
          //cache is invalid, delete it
          delete cache[func][args];
        }
      }
      if (cacheLoggingEnabled) console.log("Cache Miss", func, args);
      //return undefined if not in cache
      return undefined;
    } else {
      //store results in cache
      if (cache[func] === undefined) cache[func] = {};
      cache[func][args] = [Date.now(), results];
      return true;
    }
  };
};

//Flags
export let spectatorMode = "";
let hidePII = false;
let disableWrite = false;
const logRequests = false; //set to true to log all requests/responses

export const enablePII = () => {
  hidePII = false;
  disableWrite = false;
};

export const disablePII = () => {
  hidePII = true;
  disableWrite = true;
};

export const setSpectatorMode = (uid: string) => {
  spectatorMode = uid;
  if (uid) {
    disableWrite = true;
  } else {
    disableWrite = false;
  }
};

const PIIMapping: { [key: string]: { [originalValue: string]: string } } = {};

//Hide PII for incoming responses in PII mode
const dePII = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item: any) => {
      return dePII(item);
    });
  } else {
    if (typeof obj === "object") {
      const newObj: { [key: string]: any } = {};

      for (const key in obj) {
        let storeInPIIMapping = true;
        if (key === "phoneNumber") {
          newObj[key] = PIIMapping?.[key]?.[obj[key]] || faker.phone.number();
        } else if (key === "firstName") {
          newObj[key] = PIIMapping?.[key]?.[obj[key]] || faker.name.firstName();
        } else if (key === "middleName") {
          newObj[key] = PIIMapping?.[key]?.[obj[key]] || faker.name.firstName();
        } else if (key === "lastName") {
          newObj[key] = PIIMapping?.[key]?.[obj[key]] || faker.name.lastName();
        } else if (key === "email") {
          newObj[key] = PIIMapping?.[key]?.[obj[key]] || faker.internet.email();
        } else if (key === "attendees") {
          newObj[key] = obj[key].map((attendee: any) => {
            if (PIIMapping?.["email"]?.[attendee.email]) {
              attendee.email = PIIMapping?.["email"]?.[attendee.email];
            }
            return attendee;
          });
        } else {
          storeInPIIMapping = false;
          newObj[key] = obj[key];
        }
        if (storeInPIIMapping) {
          if (PIIMapping[key] === undefined) PIIMapping[key] = {};
          PIIMapping[key][obj[key]] = newObj[key];
        }
      }
      if (newObj?.alias) {
        newObj.alias = newObj.firstName.slice(0, 2) + newObj.lastName.slice(0, 2);
        if (PIIMapping["alias"] === undefined) PIIMapping["alias"] = {};
        PIIMapping["alias"][obj["alias"]] = newObj.alias;
      }

      return newObj;
    }
  }
  return obj;
};

//TODO: work out edge cases
//Refill PII for outgoing requests in PII mode
const rePII = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item: any) => {
      return rePII(item);
    });
  } else {
    if (typeof obj === "object") {
      const newObj: { [key: string]: any } = {};

      for (const key in obj) {
        if (PIIMapping?.[key]?.[obj[key]]) newObj[key] = PIIMapping[key][obj[key]] || obj[key];
        else newObj[key] = obj[key];
      }
    }
  }
  return obj;
};

export const handleResponse = <T>(res: T): T => {
  if (logRequests) console.log(`Response: ${res}`);

  if (hidePII) {
    return dePII(res);
  } else {
    return res;
  }
};

const disableWriteExceptions = [
  "/appointments/get",
  "/appointments/get-for-week",
  "/clinics/get-all",
  "/clinics/getByAccessCode",
  "/billed-appointments/get-for-clinic-and-range",
  "/indirects/get",
  "/indirects/get-for-week-and-clinic-id",
  "/completed-appointments/get-for-clinic-and-range",
  "/completed-appointments/get-for-clinic-client-and-range",
  "/client-details",
  "/client-guardian-details",
  "/client-availabilities/get-latest-by-client-file-id",
  "/user-availabilities/get-latest-by-user-id",
];

export const handleRequest = <T>(req: T, uri = ""): T => {
  if (logRequests) console.log(`Request: ${req}`);

  if (disableWrite !== true || disableWriteExceptions.includes(uri)) {
    if (hidePII) {
      return rePII(req);
    } else {
      return req;
    }
  } else {
    console.log("Write disabled");
    throw new Error("Cannot write during spectator mode or when PII is hidden");
  }
};
